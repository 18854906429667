import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";

import UserForSpace from "./UserForSpace";
import {
  useGetAllCompaniesQuery,
  useGetAllSpaceUsersQuery,
  useGetFloorPlansQuery,
  useGetIndividualsQuery,
} from "../../Slices/spaceApi";
import { Pagination } from "react-bootstrap";
import Select from "react-select";

import { colorStyles } from "../../utils/constant";
import UpdateUser from "./UpdateUser";
import DeleteUser from "./DeleteUser";
import ViewUser from "./ViewUser";
import Users from "./Users";
import Loader from "../Loader/Loader";

const UserTab = ({ FetchPackageData, FetchMeetingPackageData, SpaceTypes }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(true);
  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const [filter, setFilter] = useState("all");
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { data: FetchUsers, isLoading: individualLoading } =
    useGetIndividualsQuery({
      spaceId: spaceId || currentSpaceId,
      page_size: itemsPerPage.value,
      page: activePage,
      filter: filter,
    });

  const { data: FetchCompanies, isLoading: CompaniesLoading } =
    useGetAllCompaniesQuery({
      spaceId: spaceId || currentSpaceId,
    });

  const companiesArray = Array.isArray(FetchCompanies?.users)
    ? FetchCompanies?.users
    : [FetchCompanies];

  const { items, requestSort, sortConfig } = useSortableData(
    FetchUsers?.users || []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePage = (e) => {
    setitemsPerPage(e);
    setActivePage(1);
    setLoading(true);
  };
  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];

  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };
  const handleFilter = (e) => {
    setFilter(e?.id);
    setLoading(true);
  };
  useEffect(() => {
    setLoading(false);
  }, [FetchUsers]);
  const handle = (e) => {
    if (e.target.checked) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };
  return (
    <section className="app-user-list1">
      <div className="card">
        <div className="col-12 mt-4">
          <div className="d-flex justify-content-between ">
            <div className="col-12  col-md-4 col-lg-6">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Member"
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
              <UserForSpace
                FetchPackageData={FetchPackageData}
                FetchMeetingPackageData={FetchMeetingPackageData}
                // FloorPlans={fetchFloorPlans}
                FloorPlans={[]}
                SpaceTypes={SpaceTypes}
              />
            </div>
          </div>
        </div>

        <hr />

        <div className="col-12 d-flex flex-column flex-md-row justify-content-between ">
          <div className="col-12 col-md-6 my-3 my-lg-0 col-lg-4 d-flex">
            <span className="mt-2">Filter By &nbsp;</span>
            {FetchCompanies ? (
              <Select
                options={[
                  { id: "all", name: "All Categories" },
                  { id: "0", name: "Individuals" },
                  ...companiesArray,
                ]}
                defaultValue={{ id: "all", name: "Category" }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                styles={colorStyles}
                value={companiesArray?.find((obj) => obj.id === filter)}
                onChange={handleFilter}
                id="package-facilities"
                className="filter-select"
              />
            ) : (
              "Loading..."
            )}
          </div>
          <div className="col-12 col-md-2 mb-3">
            <div className="">
              <div className="col-12 d-flex justify-content-end ">
                <label
                  style={{
                    display: "flex",
                    whiteSpace: "nowrap",
                    fontWeight: "normal",
                  }}
                >
                  <small className="mr-2 mt-2 title"> Show</small>
                  <Select
                    options={options}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    onChange={handlePage}
                    value={itemsPerPage}
                    id="pages-select"
                    styles={colorStyles}
                  />
                  <small className="ml-2 mt-2"> entries</small>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Name</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("userDetails:name")}
                        className={getClassNamesFor("userDetails:name")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("userDetails:name")}
                        className={getClassNamesFor("userDetails:name")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Email</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("userDetails:email")}
                        className={getClassNamesFor("userDetails:email")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("userDetails:email")}
                        className={getClassNamesFor("userDetails:email")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Company</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("userDetails:email")}
                        className={getClassNamesFor("userDetails:email")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("userDetails:email")}
                        className={getClassNamesFor("userDetails:email")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                {/* <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Package Id</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("userDetails:packageId")}
                        className={getClassNamesFor("userDetails:packageId")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("userDetails:packageId")}
                        className={getClassNamesFor("userDetails:packageId")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th> */}
                <th>
                  Actions
                  {/* <div className="d-flex align-items-center justify-content-between">
                    {status ? <div className="">Actions</div> : null}
                    <div className="form-check form-switch p-0">
                      <input
                        className="form-check-input btn-lg"
                        onChange={handle}
                        type="checkbox"
                        role="switch"
                        defaultChecked
                        // size={4}
                        style={{
                          fontSize: "12px",
                          marginLeft: "5px",
                          marginTop: "5.8px",
                        }}
                      />
                    </div>
                    <span>{status ? "Activity" : "History"}</span>
                  </div> */}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading || individualLoading ? (
                <tr>
                  <td colSpan={5} className="fs-5">
                    <Loader />
                  </td>
                </tr>
              ) : (
                ""
              )}
              {!loading && !individualLoading && items.length > 0 ? (
                items
                  ?.slice()
                  // .reverse()
                  ?.filter((val) => {
                    if (search == "") {
                      return val;
                    } else if (
                      val?.name
                        .toLowerCase()
                        .includes(search.toLocaleLowerCase())
                    ) {
                      return val;
                    }
                  })
                  ?.map((item, idx) => {
                    let originalIndex = items.length - idx - 1;
                    return (
                      <Users
                        key={idx}
                        items={item}
                        idx={originalIndex}
                        // status={status}
                      />
                    );
                  })
              ) : //     <tr key={idx}>
              //       <td>
              //         <div className="media">
              //           <div className="media-aside mr-2">
              //             <img
              //               src={item?.userDetails?.[0]?.profileImg}
              //               alt=""
              //               className="rounded-circle"
              //               style={{ width: "32px", height: "32px" }}
              //             />
              //           </div>
              //           <div className="media-body my-auto">
              //             <span className="mb-0 table-avatar">
              //               {item?.userDetails?.[0]?.name}
              //             </span>
              //           </div>
              //         </div>
              //       </td>
              //       <td>{item?.userDetails?.[0]?.email}</td>
              //       <td colSpan={2}>
              //         {status && (
              //           <div className="d-flex align-items-center">
              //             <ViewUser
              //               userName={item?.userDetails?.[0]?.name}
              //               userEmail={item?.userDetails?.[0]?.email}
              //               phoneNo={item?.userDetails?.[0]?.phoneNo}
              //               address={item?.userDetails?.[0]?.address}
              //             />
              //             <UpdateUser
              //               userId={item?.userDetails?.[0]?.name}
              //               userName={item?.userDetails?.[0]?.name}
              //               userEmail={item?.userDetails?.[0]?.email}
              //               phoneNo={item?.userDetails?.[0]?.phoneNo}
              //               address={item?.userDetails?.[0]?.address}
              //               profileImg={item?.userDetails?.[0]?.profileImg}
              //             />
              //             <DeleteUser
              //               packageAssignId={item?.packageAssignId}
              //             />
              //           </div>
              //         )}
              //       </td>
              //     </tr>
              //   );
              // })
              !individualLoading && items?.length == 0 ? (
                <tr>
                  <td colSpan={5} className="text-left fs-5">
                    No Record !
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>

        {!loading && FetchUsers?.totalPages > 1 && (
          <>
            <div className="m-0 border-bottom" />
            <div className="pagination-custom flex-column flex-lg-row">
              <p className="text-muted mb-0">
                Showing {FetchUsers?.users.length} of {FetchUsers?.totalCount}{" "}
                entires
              </p>
              <Pagination className="mt-lg-0 mt-2">
                <Pagination.Prev
                  className="previous"
                  onClick={() => {
                    setActivePage(activePage - 1);
                    setLoading(true);
                  }}
                  disabled={activePage === 1}
                >
                  Previous
                </Pagination.Prev>
                {Array.from({ length: FetchUsers?.totalPages }).map((_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i + 1 === activePage}
                    onClick={
                      i + 1 === activePage
                        ? null
                        : () => handlePageSelect(i + 1)
                    }
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  className="next"
                  onClick={() => {
                    setActivePage(activePage + 1);
                    setLoading(true);
                  }}
                  disabled={activePage === FetchUsers?.totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default UserTab;
