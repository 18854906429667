import { configureStore } from "@reduxjs/toolkit";
import { constantApis } from "../Slices/constantApi";
import { spaceApi } from "../Slices/spaceApi";
import spaceReducer from "../Slices/spaceSlice";
const store = configureStore({
  reducer: {
    spaceReducer,
    [spaceApi.reducerPath]:spaceApi.reducer,
    [constantApis.reducerPath]:constantApis.reducer,
  },
  middleware: (middleware) => middleware().concat(spaceApi.middleware , constantApis.middleware),
});

export default store;
