import React, { useState } from "react";
import stripe from "../../Assets/stripe.png";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../Context/GlobalContext";
import {
  useRevokeStripeKeyMutation,
  useUpdateSpaceMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  confirmationStripeAlert,
  requiredAlert,
} from "../../utils/Alerts";
import toast from "react-hot-toast";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import IntegryWidget from "./IntegryWidget";

function Integrations({ oldStripeKey }) {
  const { settingsPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();
  const [revokeStripeKey, resultStats1] = useRevokeStripeKeyMutation();

  const [isAdding, setIsAdding] = useState(false);
  const [stripeKey, setStripeKey] = useState(oldStripeKey);
  const [isStripeChanged, setIsStripeChanged] = useState(false);

  const [error, setError] = useState("");
  const toggleAdding = () => {
    setIsAdding(!isAdding);
  };
  useEffect(() => {
    setStripeKey(oldStripeKey);
  }, [oldStripeKey]);
  const handleStripeKey = (e) => {
    setStripeKey(e.target.value);
    setIsStripeChanged(true);
  };

  const updateStripeKey = () => {
    if (stripeKey.length < 12) {
      setError("*Stripe Key must be at least 12 characters.");
      return;
    }
    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: { stripeKey: stripeKey },
    });
  };
  const openSwalWithInput = async () => {
    const { value: inputText } = await Swal.fire({
      title: "Enter Stripe's Secret key",
      text: "e.g: sk_test_4eC39HqLyjWDarjtT1zdp7dc",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "OK",
      inputValidator: (value) => {
        if (!value) {
          return "You need to enter a key";
        } else if (value.length < 13) {
          return "*Stripe Key must be at least 12 characters long.";
        }
      },
    });

    if (inputText) {
      // setStripeKey(inputText);
      updateGeneral({
        spaceId: spaceId || currentSpaceId,
        data: { stripeKey: inputText },
      });
    }
  };
  const handleRevokeStripeKey = (e) => {
    e.preventDefault();
    confirmationStripeAlert("Want To Revoke Stripe Key?").then((willDelete) => {
      if (willDelete) {
        revokeStripeKey({
          spaceId: spaceId || currentSpaceId,
        });
      }
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.error);
    }
    if (resultStats?.isSuccess) {
      toast.success("Stripe key Added Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      // setStripeKey(resultStats?.data?.stripeKey);
      setIsStripeChanged(false);
    }
  }, [resultStats]);
  useEffect(() => {
    if (resultStats1?.isError) {
      requiredAlert(resultStats1?.error?.data?.message);
    }
    if (resultStats1?.isSuccess) {
      toast.success("Stripe key Revoked Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      // setStripeKey(resultStats?.data?.stripeKey);
      // setIsStripeChanged(false);
    }
  }, [resultStats1]);

  return (
    <>
      <h3>Integration</h3>
      <div className="">
        <div className="card p-3">
          <IntegryWidget />
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-6">
            <div className="card text-center">
              <img
                src={stripe}
                alt="Logo 1"
                className="card-img-top w-50 mx-auto"
              />
              <div className="card-body">
                <h6 className="card-title">
                  Integrating with Stripe enables you to collect payments
                  online.
                </h6>
                <p className="card-text card p-3">{stripeKey}</p>
                <button
                  className="btn btn-primary"
                  disabled={!settingsPermissions[0]?.update}
                  onClick={
                    settingsPermissions[0]?.update ? openSwalWithInput : null
                  }
                >
                  {stripeKey ? (
                    settingsPermissions[0]?.update ? (
                      "Update"
                    ) : (
                      <span>Update{Lock2}</span>
                    )
                  ) : settingsPermissions[0]?.update ? (
                    "Add"
                  ) : (
                    <span>Add{Lock2}</span>
                  )}
                </button>

                {stripeKey ? (
                  <button
                    className="btn bg-primary text-white ml-3"
                    disabled={!settingsPermissions[0]?.update}
                    onClick={
                      settingsPermissions[0]?.update
                        ? handleRevokeStripeKey
                        : null
                    }
                    // style={{ backgroundColor: "red !important" }}
                  >
                    {settingsPermissions[0]?.update ? (
                      "Revoke Key"
                    ) : (
                      <span>Revoke Key{Lock2}</span>
                    )}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Integrations;
