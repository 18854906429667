import React from "react";
import { useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import { useDeletevisitsMutation } from "../../Slices/spaceApi";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useState } from "react";

function DeleteVisit({ id }) {
  const { visitsPermissions } = useGlobalContext();

  const navigate = useNavigate();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [deleteVisit, resultStats] = useDeletevisitsMutation();
  const [apiSpinner, setApiSpinner] = useState(false);
  const handleDeleteVisit = (e) => {
    e.preventDefault();
    confirmationAlert("Do You Want to Delete Visit?").then((willDelete) => {
      if (willDelete) {
        setApiSpinner(true);
        deleteVisit({
          spaceId: spaceId || currentSpaceId,
          id: id,
        });
      }
    });
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Visit Deleted Successfully.");
      setTimeout(() => {
        setApiSpinner(false);
      }, 1000);
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
      setTimeout(() => {
        setApiSpinner(false);
      }, 500);
    }
  }, [resultStats]);

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {visitsPermissions[0].delete ? (
              "Delete Visit"
            ) : (
              <span>
                Delete
                <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          onClick={visitsPermissions[0].delete ? handleDeleteVisit : null}
          disabled={apiSpinner}
        >
          <DeleteIcon />
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export default DeleteVisit;
