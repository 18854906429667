import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import "./index.css";

import { useState } from "react";
import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import { useGetQuotationsQuery } from "../../Slices/spaceApi";
import DeleteQuotationPopup from "./DeleteQuotationPopup";
import { useSelector } from "react-redux";
import { Badge, Pagination } from "react-bootstrap";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import Loader from "../Loader/Loader";

import UpdateQuotations from "./UpdateQuotations";
import ViewQuotations from "./ViewQuotations";
import { useGlobalContext } from "../../Context/GlobalContext";

const QuotationList = () => {
  const { quotationsPermission, currency } = useGlobalContext();
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [activePage, setActivePage] = useState(1);

  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const [status, setStatus] = useState({
    label: "Category",
    value: 0,
  });
  const [loading, setLoading] = useState(false);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: quotationData, isLoading: fetchingData } =
    useGetQuotationsQuery({
      spaceId: spaceId || currentSpaceId,
      customer_name: searchQuery,
      page_size: itemsPerPage.value,
      page: activePage,
      isCompany:
        status.value === 2 ? "True" : status.value == 0 ? null : "False",
    });
  useEffect(() => {
    let timer;
    const debounceSearch = () => {
      setLoading(true);
      setSearchQuery(search);
      // setActivePage(1);
    };
    clearTimeout(timer);
    if (search.length > 0) {
      timer = setTimeout(debounceSearch, 1000);
    } else {
      setSearchQuery("");
      setLoading(false);
    }
    return () => clearTimeout(timer);
  }, [search]);
  useEffect(() => {
    setLoading(false);
  }, [quotationData]);
  const { items, requestSort, sortConfig } = useSortableData(
    quotationData?.quotations || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };

  const handlePage = (e) => {
    setitemsPerPage(e);
    setActivePage(1);

    setLoading(true);
  };

  const handleStatus = (e) => {
    setStatus(e);
    setLoading(true);
  };

  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate}`;
  }

  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];

  const optionsStatus = [
    {
      label: "All Members",
      value: 0,
    },
    {
      label: "Individual",
      value: 1,
    },
    {
      label: "Company",
      value: 2,
    },
  ];

  if (fetchingData) {
    return <Loader height={6.6} />;
  }

  return (
    <>
      <section
        className="container-fluid invoice-list-wrapper with-bottom-padding"
        style={{ padding: "0px 16px" }}
      >
        <div className="card">
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-between ">
              <div className="col-12  col-md-4 col-lg-6">
                <input
                  type="search"
                  className="form-control"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    // setLoading(true);
                  }}
                  placeholder="Search Quotation"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
                {quotationsPermission[0]?.create ? (
                  <Link
                    to="/quotation/add"
                    className="btn btn-primary"
                    tabIndex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                  >
                    <span className="invoice">
                      <Plus /> Add Quotation
                    </span>
                  </Link>
                ) : (
                  <div
                    className="btn btn-primary  mr-2"
                    tabIndex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                  >
                    <span className="invoice">
                      <Plus /> Add
                      <Lock />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="col-12 d-flex flex-column flex-md-row justify-content-between ">
            <div className="col-12 col-md-6 my-3 my-lg-0 col-lg-4 d-flex">
              <span className="mt-2">Filter By &nbsp;</span>
              <Select
                options={optionsStatus}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={handleStatus}
                value={status}
                placeholder={"All Members"}
                styles={colorStyles}
              />
            </div>

            <div className="col-12 col-md-2 mb-3">
              <div className="">
                <div className="col-12 d-flex justify-content-end ">
                  <label
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      fontWeight: "normal",
                    }}
                  >
                    <small className="mr-2 mt-2 title"> Show</small>
                    <Select
                      options={options}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={handlePage}
                      value={itemsPerPage}
                      id="pages-select"
                      styles={colorStyles}
                    />
                    <small className="ml-2 mt-2"> entries</small>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>#</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Client</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("customer:name")}
                          className={getClassNamesFor("customer:name")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("customer:name")}
                          className={getClassNamesFor("customer:name")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Sub-Total</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("subTotal")}
                          className={getClassNamesFor("subTotal")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("subTotal")}
                          className={getClassNamesFor("subTotal")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Member</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("dueDate")}
                          className={getClassNamesFor("dueDate")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("dueDate")}
                          className={getClassNamesFor("dueDate")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Total</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("total")}
                          className={getClassNamesFor("total")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("total")}
                          className={getClassNamesFor("total")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Created At</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      ></div>
                    </div>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={8} className="fs-5">
                      <Loader />
                    </td>
                  </tr>
                )}
                {!loading &&
                  (items?.length > 0 ? (
                    items?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ color: "#7367f0", fontWeight: "bold" }}>
                            #{item.quotationId || item.id}
                          </td>
                          {/* <td>{companyName || userName}</td> */}
                          <td>
                            <div className="media">
                              {/* <div className="media-aside mr-2">
                                <img
                                  src={
                                    companyImage?.[0] ||
                                    userImage?.[0] ||
                                    images[
                                      Math.floor(Math.random() * images.length)
                                    ]
                                  }
                                  onError={(e) =>
                                    (e.target.src =
                                      images[
                                        Math.floor(
                                          Math.random() * images.length
                                        )
                                      ])
                                  }
                                  alt=""
                                  className="rounded-circle"
                                  style={{ width: "32px", height: "32px" }}
                                />
                              </div> */}
                              <div className="media-body my-auto d-flex flex-column">
                                <span className="mb-0 table-avatar">
                                  <b> {item.customer.name}</b>
                                </span>
                                <small className="mb-0 text-muted">
                                  {item.customer.email ||
                                    "demo" + (index + 1) + "@gmail.com"}
                                </small>
                              </div>
                            </div>
                          </td>
                          {/* <td>{price.toFixed(2)}</td> */}
                          <td>
                            {/* {price && discountPercentage
                              ? (
                                  price -
                                  (discountPercentage / 100) * price
                                ).toFixed(2)
                              : price.toFixed(2)} */}
                            {item.subTotal} {currency}
                          </td>
                          <td>
                            <Badge
                              bg={
                                item.customer.isCompany === true
                                  ? "badge bg-label-primary"
                                  : "badge bg-label-success"
                              }
                            >
                              {item.customer.isCompany === true
                                ? "Company"
                                : "Individual"}
                            </Badge>
                          </td>
                          <td>
                            {item.total} {currency}
                          </td>
                          <td>{formatDateTime(item.createdAt)}</td>
                          <td className="d-flex flex-nowrap">
                            <ViewQuotations {...item} />
                            <UpdateQuotations {...item} />
                            <DeleteQuotationPopup {...item} />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-left fs-5">
                        {searchQuery == ""
                          ? "No Record !"
                          : "No Quotation found"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {!loading && quotationData?.totalPages > 1 && (
            <>
              <div className="m-0 border-bottom" />
              <div className="pagination-custom flex-column flex-lg-row">
                <p className="text-muted mb-0">
                  Showing {quotationData?.quotations?.length} of{" "}
                  {quotationData?.totalCount} entires
                </p>
                <Pagination className="mt-lg-0 mt-2">
                  <Pagination.Prev
                    className="previous"
                    onClick={() => {
                      setActivePage(activePage - 1);
                      setLoading(true);
                    }}
                    disabled={activePage === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: quotationData?.totalPages }).map(
                    (_, i) => (
                      <Pagination.Item
                        key={i}
                        active={i + 1 === activePage}
                        onClick={
                          i + 1 === activePage
                            ? ""
                            : () => handlePageSelect(i + 1)
                        }
                      >
                        {i + 1}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    className="next"
                    onClick={() => {
                      setActivePage(activePage + 1);
                      setLoading(true);
                    }}
                    disabled={activePage === quotationData?.totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default QuotationList;
