import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useSelector } from "react-redux";
import { useUpdateSpaceMutation } from "../../Slices/spaceApi";
import toast from "react-hot-toast";
import { confirmationStripeAlert, requiredAlert } from "../../utils/Alerts";

function BrandEmail({ spaceData }) {
  const { settingsPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();
  const [email, setEmail] = useState(spaceData?.brandEmail);
  const [error, setError] = useState("");
  const [brandEmailChanged, setBrandEmailChanged] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setBrandEmailChanged(true);
    setError(""); // Clear error when the user types in the email field
  };
  useEffect(() => {
    setEmail(spaceData?.brandEmail);
  }, [spaceData]);
  const handleSubmit = (e) => {
    e.preventDefault();

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      setError("*Please enter a valid email address.");
      return;
    }

    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: { brandEmail: email },
    });
  };

  const handleRevoke = (e) => {
    e.preventDefault();
    confirmationStripeAlert("Want To Revoke Brand Email?").then((revoke) => {
      if (revoke) {
        updateGeneral({
          spaceId: spaceId || currentSpaceId,
          data: { brandEmail: "" },
        });
      }
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Email Configured Successfully!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 1000);
      setBrandEmailChanged(false);
    }
  }, [resultStats]);

  return (
    <div className="card p-4">
      <h4 className="mb-2">Brand Email Configuration</h4>

      <h6>
        Would you like to set up custom brand e-mail for space. This will lead
        to send future e-mails to members using this e-mail address.
      </h6>
      {/* <form onSubmit={handleSubmit}> */}
      <div className="mb-3 col-md-6">
        <label htmlFor="email" className="form-label">
          Email:
        </label>
        <input
          type="email"
          value={email}
          className="form-control"
          id="email"
          onChange={handleEmailChange}
        />
        {error && <p style={{ color: "red", marginTop: "1rem" }}>{error}</p>}
      </div>
      <div>
        <button
          className="btn ml-3 btn-primary"
          disabled={!brandEmailChanged}
          onClick={handleSubmit}
        >
          Submit
        </button>
        <button
          className="btn ml-3 btn-outline-secondary"
          //   disabled={!brandEmailChanged}
          onClick={handleRevoke}
        >
          Revoke
        </button>
      </div>
      {/* </form> */}
    </div>
  );
}

export default BrandEmail;
