import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";

function ViewVisitor({ id }) {
  return (
    <div>
      <Button
        variant="white"
        className="p-0"
        as={Link}
        to={`/visits/${id}/view`}
      >
        <Preview />
      </Button>
    </div>
  );
}

export default ViewVisitor;
