import React, { useEffect } from "react";
import AddSpacePackage from "../Components/Packages/AddSpacePackage";
import { useGlobalContext } from "../Context/GlobalContext";
export const SpacePackage = () => {
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Packages");
  }, []);
  return (
    <div>
      <AddSpacePackage />
    </div>
  );
};
