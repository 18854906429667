import React, { useEffect } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useAccountGetQuery } from '../../Slices/spaceApi'
import Loader from '../Loader/Loader'

const AccountView = ({ handleClose, operateId, data }) => {
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        className='w-10 '
      >
        <Modal.Header closeButton className=''>
          <Modal.Title>
            <h5>Account View</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data ? (
            <>
              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Account Name:</h6>
                  <p className='mb-0' id='AssettName' name='name'>
                    {data.name}
                  </p>
                </div>
              </Row>

              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Account Code:</h6>
                  <p className='mb-0' id='AssettName' name='name'>
                    {data.accountCode}
                  </p>
                </div>
              </Row>
              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Account Type:</h6>
                  <p className='mb-0' id='AssettName' name='name'>
                    {data.accountType}
                  </p>
                </div>
              </Row>

              <Row className='mb-3 mt-3'>
                <Form.Group controlId='Account'>
                  <Form.Label>
                    <h6> Description</h6>
                  </Form.Label>
                  <Form.Control
                    className='viewonly'
                    required
                    disabled
                    as='textarea'
                    rows={3} // Set the number of rows to 3
                    placeholder='Description'
                    value={data.description}
                    name='description'
                    // onChange={handleInput}
                    style={{ overflowY: 'scroll' }} // Add scrollbar style
                  ></Form.Control>
                </Form.Group>
              </Row>
            </>
          ) : (
            <Loader />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AccountView
