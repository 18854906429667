import React, { useEffect, useState } from "react";
import BookingMeetingModal from "./BookingMeetingModal";
import ViewBookingPopup from "./ViewBookingPopup";
import UpdateBookingPopup from "./UpdateBookingPopup";
import DeleteBookingPopup from "./DeleteBookingPopup";
import {
  useBookingSlotsMutation,
  useDeleteMeetingBookingMutation,
  useGetAllUpComingBookingQuery,
  useUpdateMeetingBookingMutation,
} from "../../Slices/spaceApi";
import * as Icon from "react-bootstrap-icons";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
// import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
// import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";
import { formatDate } from "@fullcalendar/core";
import Loader from "../Loader/Loader";
import {
  NewconfirmationAlert,
  bookingConfirmationAlert,
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import { Badge, Button, Modal } from "react-bootstrap";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { useGlobalContext } from "../../Context/GlobalContext";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import Select from "react-select";
import DatePicker from "react-flatpickr";
// import { convertUTCTimestampToLocal } from "../../utils/convertUTCTimestampToLocal";
// import { convertLocalTimestampToUTC } from "../../utils/convertLocalTimestampToUTC";

const BookingMeetingTable = ({
  companies,
  meetingData,
  FetchUpComingBooking,
  Users,
  // selectedDate,
}) => {
  const { bookingsPermissions, billingOption, openDays } = useGlobalContext();
  const [deleteMeetingBooking, resultStats] = useDeleteMeetingBookingMutation();
  const [updateBooking, resultStats2] = useUpdateMeetingBookingMutation();
  const [bookingSlots, resultStats1] = useBookingSlotsMutation();
  const [meetingSlots, setMeetingSlots] = useState([]);
  const [finalSlots, setFinalSlots] = useState([]);
  const [events, setEvents] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [updateBookingButton, setUpdateBookingButton] = useState(false);
  const [preSlots, setPreSlots] = useState([]);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [member, setMember] = useState(null);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [filteredResource, setFilteredResource] = useState(
    meetingData?.filter((item) => item.spaceType !== 19 && item.bookable)
  );
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [view, setView] = useState("dayGridMonth");
  const [meetingRoomId, setMeetingRoomId] = useState(
    FetchUpComingBooking?.[0]?.meetingRoom?.id
  );
  useEffect(() => {
    if (meetingData?.length > 0) {
      setFilteredResource(
        meetingData?.filter((item) => item.spaceType !== 19 && item.bookable)
      );
    }
  }, [meetingData]);
  const handleApproveBooking = (id, status) => {
    if (status !== 1) {
      bookingConfirmationAlert("Do You Want to Approve This Booking?").then(
        (approve) => {
          if (approve) {
            updateBooking({
              spaceId: spaceId || currentSpaceId,
              id: id,
              updateData: { approved: 1 },
            });
          } else {
            updateBooking({
              spaceId: spaceId || currentSpaceId,
              id: id,
              updateData: { approved: 2 },
            });
          }
        }
      );
    } else {
      NewconfirmationAlert(
        "Do You Want to Un-Approve This Booking?",
        "",
        "Yes",
        "No"
      ).then((approve) => {
        if (approve) {
          updateBooking({
            spaceId: spaceId || currentSpaceId,
            id: id,
            updateData: { approved: 2 },
          });
        }
      });
    }

    //  bookingConfirmationAlert("Do You Want to Approve This Booking?").then(
    //         (approve) => {
    //           if (approve) {
    //             updateBooking({
    //               spaceId: spaceId || currentSpaceId,
    //               id: id,
    //               updateData: { approved: 1 },
    //             });
    //           } else if (approve == false) {
    //             updateBooking({
    //               spaceId: spaceId || currentSpaceId,
    //               id: id,
    //               updateData: { approved: 2 },
    //             });
    //           }
    //         }
    //       );
  };

  const handleMeetingRoomId = (e) => {
    setMeetingRoomId(e.id);
    getBookingSlots(date, e.id);
  };
  useEffect(() => {
    const fetchEvents = async () => {
      const formattedEvents = FetchUpComingBooking?.map((event) => ({
        id: event?.id,
        title: event.meetingRoom?.title,
        start: new Date(event?.startTime * 1000),
        end: new Date(event?.endTime * 1000),
        capacity: event?.capacity,
        paidByCard: event.paidByCard,
        price: event?.meetingRoom?.price,
        member: event?.member?.id,
        userName: event?.member?.name,
        meetingRoom: event?.meetingRoom?.id,
        cancel: event?.cancel,
        approved: event?.approved,
      }));

      setEvents(formattedEvents || []);
    };
    fetchEvents();
  }, [FetchUpComingBooking]);
  function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  const eventContent = (eventInfo) => {
    const start = formatDate(eventInfo.event.start, {
      hour: "numeric",
      minute: "2-digit",
      omitZeroMinute: true,
    });
    const end = formatDate(eventInfo?.event?.end, {
      hour: "numeric",
      minute: "2-digit",
      omitZeroMinute: true,
    });
    const timeRange = `${start} - ${end}`;

    return (
      <div className="fc-event-dot event-container">
        <div className="fc-event-title event-title">
          {eventInfo?.event?.extendedProps?.userName}
        </div>
        <div className="fc-event-title event-title">
          {eventInfo?.event?.title}
        </div>
        <div className="fc-event-time event-time">{timeRange}</div>
        <div className="">
          <Badge
            onClick={(e) => {
              e.stopPropagation();
              handleApproveBooking(
                eventInfo.event.id,
                eventInfo.event.extendedProps.approved
              );
            }}
            bg={
              eventInfo.event.extendedProps.approved == 1
                ? "badge bg-label-success"
                : eventInfo.event.extendedProps.approved == 2
                ? "badge bg-label-danger"
                : "badge bg-label-primary"
            }
            style={{ maxWidth: "100px" }}
          >
            {eventInfo.event.extendedProps.approved == 1
              ? "Approved"
              : eventInfo.event.extendedProps.approved == 2
              ? "Not Approved"
              : "Pending"}
          </Badge>
        </div>
      </div>
    );
  };

  const handleBookingButtonClick = (event) => {
    setCalenderSelect(false);
    setShowBookingModal(true);
  };

  const customButtons = {
    bookingButton: bookingsPermissions[0]?.create
      ? {
          text: "+ Add Booking",
          click: handleBookingButtonClick,
        }
      : {
          text: "Add Booking(Locked)",
          click: null,
        },
  };

  const handleCloseBookingModal = () => {
    setShowBookingModal(false);
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      setApiSpinner(false);
      userDeleteAlert("Booking deleted successfully.");
    }
    if (resultStats?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);

  const handleEventClick = (event) => {
    const startDate = new Date(event.event.start);
    const endDate = new Date(event?.event?.end);

    const utcStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 16);
    const utcEndDate = new Date(
      endDate.getTime() - endDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 16);

    setSelectedBooking({
      start: utcStartDate,
      end: utcEndDate,
      id: event.event.id,
      title: event.event.title,
      capacity: event.event.extendedProps.capacity,
      price: event.event.extendedProps.price,
      paidByCard: event.event.extendedProps.paidByCard,
      // companyId: event.event.extendedProps.companyId,
      member: event.event.extendedProps.member,

      userName: event.event.extendedProps.userName,
      meetingRoom: event.event.extendedProps.meetingRoom,
    });
  };
  useEffect(() => {
    setMeetingRoomId(selectedBooking?.meetingRoom);
  }, [selectedBooking]);

  const [date, setDate] = useState(getCurrentDate);
  const [dateCheck, setDateCheck] = useState();
  useEffect(() => {
    const date = selectedBooking?.start?.split("T")[0];
    setDate(date);
    setDateCheck(date);
    const startTime = selectedBooking?.start?.split("T")[1].slice(0, 5); // Get HH:mm
    const endTime = selectedBooking?.end?.split("T")[1].slice(0, 5); // Get HH:mm

    const startDate = new Date(date + "T" + startTime);
    const endDate = new Date(date + "T" + endTime);

    const timeSlots = [];

    while (startDate < endDate) {
      const currentStartTime = startDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      startDate.setMinutes(startDate.getMinutes() + 30);

      const currentEndTime = startDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      timeSlots.push({ start: currentStartTime, end: currentEndTime });
    }

    setPreSlots(timeSlots);
  }, [selectedBooking]);

  const handleEditBooking = (event) => {
    setUpdateBookingButton(true);
  };

  const handleDeleteBooking = (booking) => {
    confirmationAlert("Want to Delete This Booking?").then((willDelete) => {
      if (willDelete) {
        setApiSpinner(true);

        deleteMeetingBooking({
          spaceId: spaceId || currentSpaceId,
          id: booking.id,
        });
        setSelectedBooking(null);
      }
    });
  };
  useEffect(() => {
    if (resultStats2?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats2?.error?.data?.message);
    }

    if (resultStats2?.isSuccess) {
      setApiSpinner(false);

      toast.success("Booking updated successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setUpdateBookingButton(false);
      setSelectedBooking(null);
    }
  }, [resultStats2]);

  const [minDatetime, setMinDatetime] = useState("");

  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, "0");
    const day = `${now.getDate()}`.padStart(2, "0");
    const hours = `${now.getHours()}`.padStart(2, "0");
    const minutes = `${Math.floor(now.getMinutes() / 30) * 30}`.padStart(
      2,
      "0"
    );
    const minDatetimeValue = `${year}-${month}-${day}T${hours}:${minutes}`;
    setMinDatetime(minDatetimeValue);
  }, []);

  function handleEventDropResize(event) {
    const updatedEvents = events?.map((e) => {
      if (e.id === event.event.id) {
        return {
          ...e,
          startTime: toTimestamp(event.event.start),
          endTime: toTimestamp(event.event.end),
        };
      }
      return e;
    });

    setEvents(updatedEvents);

    const id = event.event.id;
    const updateData = {
      startTime: toTimestamp(event.event.start),
      endTime: toTimestamp(event.event.end),
      paidByCard: event.event.extendedProps.paidByCard,
      capacity: event.event.extendedProps.capacity,
      meetingRoom: meetingRoomId,
      member: event.member,
    };

    // updateBooking({ spaceId: spaceId || currentSpaceId, id, updateData });
  }

  const handle = (e) => {
    const { name, value } = e.target;

    setSelectedBooking((prevBooking) => ({
      ...prevBooking,

      [name]: value,
    }));
  };

  function roundToNearest30Minutes(date) {
    // convert UTC time to local time
    const localTime = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );

    const minutes = localTime.getMinutes();
    if (minutes <= 29) {
      localTime.setMinutes(0);
    } else {
      localTime.setMinutes(30);
    }
    return localTime.toISOString().slice(0, 16);
  }

  function handleTime(e) {
    const name = e.target.name;
    const value = e.target.value;
    const year = value.slice(0, 4);
    const month = value.slice(5, 7);
    const day = value.slice(8, 10);
    const hour = value.slice(11, 13);
    const minute = value.slice(14, 16);
    const date = new Date(year, month - 1, day, hour, minute);
    setSelectedBooking((prev) => ({
      ...prev,
      [name]: roundToNearest30Minutes(date),
    }));
  }
  /////////////////////////

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  const [preSlotsIndexes, setPreSlotsIndexes] = useState([]);

  const formatTime = (timeString) => {
    const date = new Date(`2023-10-29T${timeString}`);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${amOrPm}`;
  };
  function handleDate(e) {
    const name = e.target.name;
    const value = e.target.value;
    // setData((prev) => ({ ...prev, [name]: value }));
    setDate(value);
    getBookingSlots(value, meetingRoomId);
  }

  async function getBookingSlots(selectedDate, meetingRoomId) {
    const slots = await bookingSlots({
      spaceId: spaceId || currentSpaceId,
      data: {
        meetingRoom: meetingRoomId,
        date: selectedDate,
      },
    });
    setMeetingSlots(slots?.data || []);
  }

  useEffect(() => {
    const newTimeSlots = meetingSlots?.map((slot) => ({
      start: new Date(date + "T" + slot.start)?.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
      end: new Date(date + "T" + slot?.end)?.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
    }));

    let insertionIndex = 0;

    // Find a match for the end of the last preSlot with the start of a meeting slot
    const matchIndex = newTimeSlots.findIndex(
      (slot) => slot.start === preSlots[preSlots?.length - 1]?.end
    );

    if (matchIndex !== -1) {
      // Case 1: Match found, place preSlots before that meeting slot
      insertionIndex = matchIndex;
    } else {
      // Case 2: No match found in step 1, try to match the start of the first preSlot with the end of a meeting slot
      const firstPreSlotMatchIndex = newTimeSlots.findIndex(
        (slot) => slot?.end === preSlots[0]?.start
      );

      if (firstPreSlotMatchIndex !== -1) {
        insertionIndex = firstPreSlotMatchIndex + 1;
      } else {
        // Case 3: Both steps failed, place preSlots at the start of finalSlots
        insertionIndex = 0;
      }
    }

    // Set preSlotIndexes to track the indexes occupied by preSlots in finalSlots
    const preSlotIndexes = Array.from(
      { length: preSlots.length },
      (_, index) => insertionIndex + index
    );

    const finalSlotsWithIndexes = [
      ...newTimeSlots.slice(0, insertionIndex),
      ...preSlots.map((slot, index) => ({ ...slot, preSlotIndex: index })),
      ...newTimeSlots.slice(insertionIndex),
    ];

    if (date == dateCheck) {
      setFinalSlots(finalSlotsWithIndexes);
    } else {
      setFinalSlots([...newTimeSlots]);
    }

    setPreSlotsIndexes(preSlotIndexes);

    // Set selectedStart and selectedEnd after preSlotsIndexes are populated
    setSelectedStart(preSlotIndexes[0]);
    setSelectedEnd(preSlotIndexes[preSlotIndexes.length - 1]);
  }, [meetingSlots]);

  const findInsertionIndex = (newTimeSlots, preSlots) => {
    const lastPreSlotEnd = preSlots[preSlots.length - 1]?.end;
    let insertionIndex = 0;
    const preSlotsIndexes = [];

    for (let i = 0; i < newTimeSlots.length; i++) {
      if (newTimeSlots[i].start === lastPreSlotEnd) {
        insertionIndex = i;
      }
    }
    if (insertionIndex === 0) {
      for (let i = 0; i < preSlots.length; i++) {
        preSlotsIndexes.push(i);
      }
    } else {
      for (let i = insertionIndex; i < insertionIndex + preSlots.length; i++) {
        preSlotsIndexes.push(i);
      }
    }

    return { insertionIndex, preSlotsIndexes };
  };

  const [selectedStart, setSelectedStart] = useState(preSlotsIndexes[0]);
  const [selectedEnd, setSelectedEnd] = useState(
    preSlotsIndexes[preSlotsIndexes?.length - 1]
  );

  const handleSlotClick = (index) => {
    if (selectedStart === null) {
      setSelectedStart(index);
      setSelectedEnd(index);
    } else {
      const previousSlot = finalSlots[selectedEnd];
      const currentSlot = finalSlots[index];

      if (
        selectedEnd === index - 1 &&
        previousSlot?.end === currentSlot?.start
      ) {
        setSelectedEnd(index);
      } else {
        setSelectedStart(index);
        setSelectedEnd(index);
      }
    }
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    if (updateBookingButton) {
      getBookingSlots(date, meetingRoomId);
    }
  }, [updateBookingButton]);

  const postBooking = (event) => {
    const updatedEvents = events?.map((e) => {
      if (e.id === event.id) {
        return {
          ...e,
          startTime: toTimestamp(event.start),
          endTime: toTimestamp(event.end),
        };
      }
      return e;
    });
    setEvents(updatedEvents);

    const selectedDate = date;
    const initialTime = finalSlots[selectedStart]?.start;
    const lastTime = finalSlots[selectedEnd]?.end;
    // Parse selectedDate
    const [year, month, day] = selectedDate.split("-");

    // Parse initialTime and preserve AM/PM status
    const initialTimeParts = initialTime.match(/(\d+):(\d+) (AM|PM)/);
    let startHours = parseInt(initialTimeParts[1]);
    const startMinutes = parseInt(initialTimeParts[2]);
    const startAMPM = initialTimeParts[3];

    // if (startAMPM === "PM" && startHours !== 12) {
    //   startHours += 12;
    // }
    if (startAMPM === "PM" && startHours !== 12) {
      startHours += 12;
    } else if (startAMPM === "AM" && startHours === 12) {
      startHours = 0;
    }

    const lastTimeParts = lastTime.match(/(\d+):(\d+) (AM|PM)/);
    let endHours = parseInt(lastTimeParts[1]);
    const endMinutes = parseInt(lastTimeParts[2]);
    const endAMPM = lastTimeParts[3];

    // if (endAMPM === "PM" && endHours !== 12) {
    //   endHours += 12;
    // }
    if (endAMPM === "PM" && endHours !== 12) {
      endHours += 12;
    } else if (endAMPM === "AM" && endHours === 12) {
      endHours = 0;
    }

    const startDateTime = new Date(
      year,
      month - 1,
      day,
      startHours,
      startMinutes
    );
    const endDateTime = new Date(year, month - 1, day, endHours, endMinutes);

    // Convert to epoch timestamps (in milliseconds)
    const startTimestamp = startDateTime.getTime();
    const endTimestamp = endDateTime.getTime();

    const startIsPM = startDateTime.getHours() >= 12;
    const endIsAM = endDateTime.getHours() < 12;

    let adjustedEndTimestamp = endTimestamp;

    if (startIsPM && endIsAM) {
      const endDate = new Date(endTimestamp);
      endDate.setDate(endDate.getDate() + 1);
      adjustedEndTimestamp = endDate.getTime();
    }

    const startEpochTimestamp = Math.floor(startTimestamp / 1000);
    const endEpochTimestamp = Math.floor(adjustedEndTimestamp / 1000);
    // const startEpochTimestamp = Math.floor(startTimestamp / 1000);
    // const endEpochTimestamp = Math.floor(endTimestamp / 1000);

    // const id = event.id;
    const id = selectedBooking?.id;
    const updateData = {
      // startTime: toTimestamp(event.start),
      startTime: startEpochTimestamp,
      // endTime: toTimestamp(event.end),
      endTime: endEpochTimestamp,
      paidByCard: event.paidByCard, // get paidByCard from the extendedProps
      capacity: +event.capacity,
      meetingRoom: meetingRoomId,
      member: event.member,
    };

    setApiSpinner(true);

    updateBooking({ spaceId: spaceId || currentSpaceId, id, updateData });
  };
  ///////////////////////////////**************************888///////////////////////////////// */

  const businessHours = openDays?.map((day) => {
    const startTime = new Date(day.start_time * 1000);
    const endTime = new Date(day.end_time * 1000);

    const formatTime = (date) => {
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      return `${hours}:${minutes}`;
    };

    // Check if both start and end times are '00:00'
    const isFullDay =
      formatTime(startTime) === "00:00" && formatTime(endTime) === "00:00";

    return {
      daysOfWeek: [day.day], // Map the "day" value to the corresponding day of the week
      startTime: isFullDay ? "" : formatTime(startTime),
      endTime: isFullDay ? "" : formatTime(endTime),
    };
  });

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  const [bookingDate, setBookingDate] = useState(getCurrentDate());
  const [calenderSelect, setCalenderSelect] = useState(false);
  const [selectedTime, setSelectedTime] = useState([]);
  /////////////////
  const allowDateSelection = (selectInfo) => {
    const selectedStart = selectInfo.start;
    const selectedEnd = selectInfo.end;

    const BookingDate = formatSlotDate(selectedStart);

    const businessHoursForDay = businessHours.find((hours) =>
      hours.daysOfWeek.includes(selectedStart.getDay())
    );
    if (businessHoursForDay) {
      const businessStartTime = timeToMinutes(businessHoursForDay.startTime);

      const businessEndTime = timeToMinutes(businessHoursForDay.endTime);

      const selectedStartTime = timeToMinutes(formatSlotTime(selectedStart));
      const selectedEndTime = timeToMinutes(formatSlotTime(selectedEnd));
      const formattedTimes = {
        start: convertToTimeString(selectedStart),
        end: convertToTimeString(selectedEnd),
      };

      const isRangeValid =
        selectedStartTime >= businessStartTime &&
        selectedEndTime <= businessEndTime;

      if (
        isRangeValid ||
        (isNaN(businessStartTime) && isNaN(businessEndTime))
      ) {
        setBookingDate(BookingDate);
        setSelectedTime(formattedTimes);
        setShowBookingModal(true);
        setCalenderSelect(true);

        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const convertToTimeString = (date) => {
    const timeString = date.toLocaleTimeString("en-US", { hour12: false });
    return timeString;
  };

  useEffect(() => {}, [bookingDate]);
  const formatSlotTime = (date) => {
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    return `${hours}:${minutes}:${seconds}`;
  };
  const formatSlotDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":");
    return parseInt(hours) * 60 + parseInt(minutes);
  };

  const handleViewRender = (info) => {
    const parsedDate = new Date(info.view.title);
    const formattedDate = `${parsedDate.getFullYear()}-${(
      parsedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${parsedDate.getDate().toString().padStart(2, "0")}`;
    setBookingDate(formattedDate);
  };

  return (
    <>
      <div className="">
        {events && (
          <div className="calendar-container">
            <FullCalendar
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
                // resourceTimelinePlugin,
              ]}
              resources={[
                { id: 1, title: "Resource A" },
                { id: 2, title: "Resource B" },
              ]}
              initialView="timeGridDay"
              // initialView="resourceDayGridDay"
              // initialView="resourceTimelineDay"
              events={events.filter((event) => !event.cancel)}
              eventContent={eventContent}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right:
                  "dayGridMonth,timeGridWeek,timeGridDay,listWeek bookingButton",
              }}
              views={{
                dayGridMonth: {
                  buttonText: "Month",
                },
                timeGridWeek: {
                  buttonText: "Week",
                },
                timeGridDay: {
                  buttonText: "Day",
                },
                listWeek: {
                  buttonText: "List",
                },
              }}
              customButtons={customButtons}
              selectable={true}
              eventDrop={handleEventDropResize}
              eventResize={handleEventDropResize}
              eventClick={handleEventClick}
              editable
              // style={{ height: "600px", overflowY: "auto" }}
              aspectRatio={1.5}
              height="auto"
              datesSet={handleViewRender}
              slotLabelInterval={{ hours: 0.5 }}
              slotDuration="00:30:00"
              contentHeight="auto"
              // initialDate={selectedDate}

              selectAllow={(selectInfo) => allowDateSelection(selectInfo)}
              slotLabelContent={(slotInfo) => (
                <div
                  style={{
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {slotInfo.text}
                </div>
              )}
              businessHours={businessHours}
            />
          </div>
        )}

        <Modal
          show={selectedBooking !== null}
          onHide={() => setSelectedBooking(null)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedBooking?.userName || "Booking Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedBooking && (
              <>
                <h5>
                  Resource:
                  <small> {selectedBooking?.title}</small>
                </h5>
                <h5>
                  Time Range:&nbsp;
                  <small>
                    {formatDate(selectedBooking?.start, {
                      hour: "numeric",
                      minute: "2-digit",
                      omitZeroMinute: true,
                    })}
                    &nbsp; - &nbsp;
                    {formatDate(selectedBooking?.end, {
                      hour: "numeric",
                      minute: "2-digit",
                      omitZeroMinute: true,
                    })}
                  </small>
                </h5>
                <h5>
                  {billingOption == 1 ? "Price:" : "Credit"}
                  <small>
                    {" "}
                    {billingOption == 1
                      ? selectedBooking?.price
                      : selectedBooking?.price}
                  </small>
                </h5>
                <h5>
                  Capacity:
                  <small> {selectedBooking?.capacity}</small>
                </h5>
              </>
            )}

            <div className="mt-5">
              <Button
                variant="primary"
                disabled={apiSpinner}
                onClick={
                  bookingsPermissions[0]?.update ? handleEditBooking : null
                }
              >
                {bookingsPermissions[0]?.update ? (
                  "Update Booking"
                ) : (
                  <span>
                    Edit
                    <Lock />
                  </span>
                )}
              </Button>
              <Button
                variant="danger"
                disabled={apiSpinner}
                className="ml-3 bg-danger"
                onClick={
                  bookingsPermissions[0]?.delete
                    ? () => handleDeleteBooking(selectedBooking)
                    : null
                }
              >
                {bookingsPermissions[0]?.delete ? (
                  <>
                    {" "}
                    Delete{" "}
                    {apiSpinner ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "5px",
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <span>
                    Delete <Lock />
                  </span>
                )}
              </Button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button
              variant="primary"
              disabled={apiSpinner}
              onClick={
                bookingsPermissions[0]?.update ? handleEditBooking : null
              }
            >
              {bookingsPermissions[0]?.update ? (
                "Update Booking"
              ) : (
                <span>
                  Edit
                  <Lock />
                </span>
              )}
            </Button>
            <Button
              variant="primary"
              disabled={apiSpinner}
              onClick={
                bookingsPermissions[0]?.delete
                  ? () => handleDeleteBooking(selectedBooking)
                  : null
              }
            >
              {bookingsPermissions[0]?.delete ? (
                <>
                  {" "}
                  Delete{" "}
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <span>
                  Delete <Lock />
                </span>
              )}
            </Button>
          </Modal.Footer> */}
        </Modal>

        {showBookingModal && (
          <BookingMeetingModal
            handleClose={handleCloseBookingModal}
            companies={companies}
            meetingData={meetingData}
            Users={Users}
            bookingDate={bookingDate}
            calenderSelect={calenderSelect}
            selectedTime={selectedTime}
          />
        )}

        {selectedBooking && updateBookingButton && (
          <Modal
            show={updateBookingButton}
            onHide={() => setUpdateBookingButton(false)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Booking</Modal.Title>
            </Modal.Header>
            <form>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="capacity">Capacity</label>
                  <input
                    type="number"
                    name="capacity"
                    id="capacity"
                    value={selectedBooking?.capacity}
                    onChange={handle}
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="meetingId" className="mb-1">
                    Resource
                  </label>
                  <Select
                    options={filteredResource}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    // styles={colorStyles}
                    value={filteredResource?.filter(
                      (obj) => obj.id == meetingRoomId
                    )}
                    onChange={handleMeetingRoomId}
                    id="meetingId"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="startTime">Select Date:</label>
                  <div className="input-group ">
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        style={{
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                        }}
                      >
                        <Icon.Calendar2Check />
                      </span>
                    </div>
                    <input
                      type="date"
                      name="startTime"
                      id="startTime"
                      className="form-control"
                      placeholder=""
                      value={date}
                      min={getCurrentDate()}
                      onChange={handleDate}
                    />
                  </div>
                </div>
                <div
                  className="meeting-slots-container h-25"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {finalSlots.length > 0
                    ? finalSlots?.map((slot, index) => {
                        return (
                          <div
                            key={index}
                            className={`meeting-slot ${
                              index >= selectedStart && index <= selectedEnd
                                ? "selected"
                                : ""
                            }`}
                            style={{
                              border: "1px solid #358EF1",
                              cursor: "pointer",
                              margin: "5px",
                              padding: "5px",
                              borderRadius: "25px",
                              justifyContent: "center",
                              alignItems: "center",
                              maxHeight: "40px",
                              backgroundColor:
                                index >= selectedStart && index <= selectedEnd
                                  ? "#007bff"
                                  : "transparent",
                              color:
                                index >= selectedStart && index <= selectedEnd
                                  ? "#fff"
                                  : "initial",
                            }}
                            onClick={() => handleSlotClick(index)}
                          >
                            {/* {`${slot.start} - ${slot.end}`}
                             */}
                            <small>
                              {" "}
                              {slot?.start}-{slot?.end}
                            </small>
                          </div>
                        );
                      })
                    : "No Slots Available For This Date"}
                </div>
              </Modal.Body>
              <div className="mt-3 mb-3 ml-3">
                <Button
                  variant="primary"
                  disabled={apiSpinner}
                  onClick={() => postBooking(selectedBooking)}
                >
                  Save Changes{" "}
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>

                <button
                  className="ml-3 btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setUpdateBookingButton(false);
                  }}
                >
                  Cancel
                </button>
                <Toaster position="top-right" />
              </div>
            </form>
          </Modal>
        )}
      </div>
    </>
  );
};

export default BookingMeetingTable;
