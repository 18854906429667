import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";

import { useUpdateVisitorMutation } from "../../Slices/spaceApi";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";

import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context/GlobalContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
const UpdateVisitor = ({ id, name, email, phone_number }) => {
  const { visitsPermissions } = useGlobalContext();
  const navigate = useNavigate();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateVisitorData, resultStats] = useUpdateVisitorMutation();
  const [apiSpinner, setApiSpinner] = useState(false);

  const [data, setData] = useState({
    spaceId: spaceId || currentSpaceId,
    name: name,
    email: email,

    phone_number: phone_number,
  });

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [phone, setPhone] = useState(data.phone_number);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneStatus, setPhoneStatus] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const handlePhone = (e) => {
    const numericValue = e;
    setPhone(numericValue);
    setData({ ...data, phone_number: numericValue });
    // if (
    //   !numericValue ||
    //   (numericValue.length > 10 && numericValue.length < 16)
    // ) {
    //   setData({ ...data, [e.target.name]: numericValue });
    //   setErrorMessage(""); // Clear the error message
    //   setPhoneStatus(false);
    // } else {
    //   setPhoneStatus(true);
    //   setErrorMessage("Invalid Phone");
    // }
  };
  const handle = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setEmailError("");
    setNameError("");
  };

  const updateCompany = (e) => {
    e.preventDefault();
    const { spaceId, name, email, phone_number } = data;
    let updateSpaceId = +spaceId;

    let updateData = {
      // spaceId: parseInt(spaceId || currentSpaceId),
      name,
      email,
      phone_number,
    };
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email && !emailPattern.test(email)) {
      setEmailError("*Please enter a valid email address.");
      return;
    }
    if (!name?.length > 0) {
      setNameError("*Please Enter Visitor's Name");
      return;
    }
    setApiSpinner(true);

    updateVisitorData({
      spaceId: spaceId || currentSpaceId,
      id,
      data: updateData,
    });
  };

  useEffect(() => {
    if (resultStats?.error) {
      setApiSpinner(false);
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }

    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Visitor Updated Successfully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      handleClose();
    }
  }, [resultStats]);

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip" style={{ transition: "none" }}>
            {visitsPermissions[0]?.update ? (
              "Update"
            ) : (
              <span>
                Update <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="mx-2 p-0"
          onClick={visitsPermissions[0]?.update ? handleShow : null}
          style={{ transition: "none" }}
        >
          {" "}
          <EditIcon />
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Visitor</Modal.Title>
        </Modal.Header>
        <form onSubmit={updateCompany}>
          <Modal.Body>
            <form>
              {/* <div className={`${companyResponse ? "d-none" : ""}`}> */}
              <div>
                <div className="form-group">
                  <label htmlFor="name">Guest</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    placeholder="Enter Guest's Name"
                    className="form-control"
                    value={data?.name}
                    onChange={handle}
                  />
                  {nameError && <p style={{ color: "red" }}>{nameError}</p>}
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Guest's Email"
                    id="email"
                    required
                    value={data?.email}
                    className="form-control"
                    onChange={handle}
                  />
                  {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                </div>

                <div className="form-group">
                  <label htmlFor="email">Phone No.</label>
                  <PhoneInput
                    placeholder="Enter 11-15 Digit Phone Number"
                    // name="phoneNo"
                    value={data?.phone_number}
                    // onChange={handleInfo}
                    // min={0}
                    required
                    // pattern="\d{11,15}"
                    // onChange={(e) => {
                    //   setVisitorData({
                    //     ...visitorData,
                    //     phone_number: e,
                    //   });
                    // }}
                    onChange={handlePhone}
                  />
                  {/* <input
                    type="text"
                    name="phone_number"
                    placeholder="Enter 11-15 Digit Phone Number"
                    id="phone_number"
                    required
                    value={data?.phone_number}
                    className="form-control"
                    onChange={handlePhone}
                  /> */}
                  {errorMessage === "Invalid Phone" && (
                    <p style={{ color: "red" }}>
                      *Invalid Phone Number Length(11-15 Digits)
                    </p>
                  )}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button
              variant="primary"
              type="submit"
              disabled={phone.length < 11 || phone.length > 15}
              disabled={isUploading}
            >
              
              Save Changes
            </Button> */}
            {/* {!companyResponse && ( */}
            <>
              <Button
                variant="primary"
                disabled={apiSpinner}
                onClick={handleClose}
              >
                Close
              </Button>
              <Button variant="primary" disabled={apiSpinner} type="submit">
                Update Visitor{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
            </>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default UpdateVisitor;
