import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetCompanyUsersQuery,
  useGetSpecificCompanyQuery,
} from "../../Slices/spaceApi";
import { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import UserForCompany from "./UserForCompany";
import Loader from "../Loader/Loader";
import { useSortableData } from "../../utils/SortingFunction";
import UpdateCompanyDetails from "./UpdateCompanyDetails";
import ViewCompany from "./ViewCompany";
import DeleteCompany from "./DeleteCompany";
import ViewComapanyUser from "./ViewComapanyUser";
import EditCompanyUser from "./EditCompanyUser";
import DeleteCompanyUser from "./DeleteCompanyUser";
import CompanyDetailsView from "./CompanyDetailsView";
import { useEffect } from "react";
import { getRandomColorDark } from "../../utils/getRandomColorDark";
import { getInitials } from "../../utils/getInitials";
import ViewCompanyEmployeeMiddlWare from "./ViewCompanyEmployeeMiddlwWare";

function CompanyAndUsers({ id, companyData }) {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(true);

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: companyUsers, isLoading: usersLoading } =
    useGetCompanyUsersQuery({
      spaceId: spaceId || currentSpaceId,
      companyId: id,
    });

  const { items, requestSort, sortConfig } = useSortableData(
    companyUsers?.users || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const filteredItems = items
    ?.slice()
    .reverse()
    ?.filter((val) => {
      if (search == "") {
        return val;
      } else if (val.name.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
    });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (usersLoading) {
    <Loader height={6.6} />;
  }

  return (
    <>
      {/* <section className="app-user-list"> */}
      <div className="card">
        <div className="mt-3 ml-4">
          <h4>Members</h4>
        </div>
        <div className="row table-header align-items-center justify-content-between">
          <div className="col-xl-6 col-12">
            <div className="media">
              <div className="media-aside logo-list mr-2">
                {companyData?.profileImg ? (
                  <img
                    style={{
                      marginRight: "8px",
                      borderRadius: "50%",
                      width: "38px",
                      height: "38px",
                      objectFit: "cover",
                    }}
                    src={
                      companyData?.profileImg ||
                      "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                    }
                    alt={companyData?.name}
                  />
                ) : (
                  <div
                    className="rounded-circle"
                    style={{
                      width: "38px",
                      height: "38px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: getRandomColorDark(),
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    {getInitials(companyData?.name)}
                  </div>
                )}
              </div>
              <div className="media-body my-auto d-flex flex-column">
                <span className="mb-0 table-avatar">
                  <b>{companyData?.name}</b>
                </span>
                <small className="mb-0 text-muted">{companyData?.email}</small>
              </div>
            </div>
          </div>

          <div className="col-xl-2 col-12 mt-2 offset-xl-1 mt-xl-0 d-flex align-items-center justify-content-end">
            <div className="ms-3">
              <UserForCompany
                companyId={id}
                companyName={companyData?.name}
                memberCount={companyData?.memberCount}
                packageCount={companyData?.activePackageCount}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mr-4 col-12 mt-2 offset-xl-1 mt-xl-0 d-flex align-items-center justify-content-end ml-auto">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Here"
              className="form-control"
            />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Name</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("userDetails:name")}
                        className={getClassNamesFor("userDetails:name")}
                        style={{ fontSize: "10px" }}
                      />
                      <Icon.ChevronDown
                        onClick={() => requestSort("userDetails:name")}
                        className={getClassNamesFor("userDetails:name")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Email</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("userDetails:email")}
                        className={getClassNamesFor("userDetails:email")}
                        style={{ fontSize: "10px" }}
                      />
                      <Icon.ChevronDown
                        onClick={() => requestSort("userDetails:email")}
                        className={getClassNamesFor("userDetails:email")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {/* {loading ? (
                  <tr>
                    <td colSpan={3} className="fs-5">
                      Loading...
                    </td>
                  </tr>
                ) : */}
              {filteredItems?.length > 0 ? (
                filteredItems
                  ?.slice()
                  .reverse()
                  ?.filter((val) => {
                    if (search == "") {
                      return val;
                    } else if (
                      val?.name
                        .toLowerCase()
                        .includes(search.toLocaleLowerCase())
                    ) {
                      return val;
                    }
                  })
                  ?.map((item, idx) => (
                    <tr key={idx}>
                      <td>
                        <div className="media">
                          <div className="media-aside mr-2">
                            {/* <img
                                src={item?.profileImg}
                                alt=""
                                className="rounded-circle"
                                style={{ width: "38px", height: "38px" }}
                              /> */}
                            {item?.profileImg ? (
                              <img
                                style={{
                                  marginRight: "8px",
                                  borderRadius: "50%",
                                  width: "38px",
                                  height: "38px",
                                  objectFit: "cover",
                                }}
                                src={
                                  item?.profileImg ||
                                  "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                }
                                alt={item?.name}
                              />
                            ) : (
                              <div
                                className="rounded-circle"
                                style={{
                                  width: "38px",
                                  height: "38px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: getRandomColorDark(),
                                  color: "#fff",
                                  fontSize: "12px",
                                }}
                              >
                                {getInitials(item?.name)}
                              </div>
                            )}
                          </div>
                          <div className="media-body my-auto">
                            <span className="mb-0 table-avatar">
                              <b> {item?.name}</b>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>{item?.email}</td>

                      <td>
                        <div className="d-flex align-items-center">
                          {/* <ViewComapanyUser {...item} /> */}
                          {/* <EditCompanyUser
                              {...item}
                              companyName={companyData?.name}
                            /> */}
                          <ViewCompanyEmployeeMiddlWare id={item.id} />
                          <DeleteCompanyUser
                            {...item}
                            //  companyId={id}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
              ) : filteredItems?.length == 0 ? (
                <tr>
                  <td colSpan={5} className="text-left fs-5">
                    No Record !
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
      {/* </section> */}
    </>
  );
}

export default CompanyAndUsers;
