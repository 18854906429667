import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Stepper from "react-stepper-horizontal";

const Dashboard = ({ dashboardData, currentStep }) => {
  const navigate = useNavigate();
  const [steps, setSteps] = useState([
    { title: "Settings" },
    { title: "Meeting Room" },
    { title: "Packages" },
    { title: "Members" },
    // { title: "Step 5" },
  ]);

  const [activeStep, setActiveStep] = useState(0);
  const [isStep1Complete, setStep1Complete] = useState(false);
  const [isStep2Complete, setStep2Complete] = useState(false);
  const [isStep3Complete, setStep3Complete] = useState(false);
  const [isStep4Complete, setStep4Complete] = useState(false);
  //   const [isStep5Complete, setStep5Complete] = useState(false);
  useEffect(() => {
    if (dashboardData?.amenities > 0) {
      setStep1Complete(true);
    } else {
      setStep1Complete(false);
    }
    if (dashboardData?.meetingRooms > 0) {
      setStep2Complete(true);
    } else {
      setStep2Complete(false);
    }
    if (dashboardData?.spacePackages >= 0) {
      setStep3Complete(true);
    } else {
      setStep3Complete(false);
    }
    if (dashboardData?.users > 0) {
      setStep4Complete(true);
    } else {
      setStep4Complete(false);
    }
  }, [dashboardData]);
  // Use useEffect to automatically progress to the next step when a specific state becomes true
  useEffect(() => {
    const isStepComplete = [
      isStep1Complete,
      isStep2Complete,
      isStep3Complete,
      isStep4Complete,
      //   isStep5Complete,
    ];

    // Check if the current step is complete
    if (isStepComplete[activeStep]) {
      // Move to the next step if it's not the last step
      if (activeStep < steps.length) {
        setActiveStep((prevStep) => prevStep + 1);
      }
    }
  }, [
    isStep1Complete,
    isStep2Complete,
    isStep3Complete,
    isStep4Complete,
    // isStep5Complete,
    activeStep,
    steps.length,
  ]);

  return (
    <div>
      <h4 className=" mt-4 text-center">
        {currentStep == 0
          ? "Please Complete the following steps first."
          : currentStep == 1
          ? "Welcome to Settings Page."
          : currentStep == 2
          ? "Welcome to Meeting Rooms Add Section."
          : currentStep == 3
          ? "Welcome to Packages Add Section."
          : currentStep == 4
          ? "Welcome to Members' Page."
          : "Please Complete the following steps first."}
      </h4>
      <Stepper
        steps={steps?.map((step, index) => ({
          ...step,
          circleFontColor: index === 3 && isStep4Complete ? "green" : "blue",
        }))}
        activeStep={activeStep}
        activeColor="#007BFF"
        completeColor="#28A745"
        circleFontSize={20}
        size={30}
      />

      {currentStep == 0 && (
        <div className="mt-5">
          {activeStep === 0 && (
            <div className="text-center">
              <h6 className="">Settings</h6>
              <p>
                Please add necessary credentials in settings first in order to
                work smoothly. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/setting/accountsetting");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to set settings
                </span>
              </p>
            </div>
          )}
          {activeStep === 1 && (
            <div className="text-center">
              <h6 className="">Meeting Rooms</h6>
              <p>
                Please add Meeting Rooms. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/floorPlans");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Meeting Rooms
                </span>
              </p>
            </div>
          )}
          {activeStep === 2 && (
            <div className="text-center">
              <h6 className="">Packages</h6>
              <p>
                Please Add Packages for your space. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/package/addspace");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Packages
                </span>
              </p>
            </div>
          )}
          {activeStep === 3 && (
            <div className="text-center">
              <h6 className="">Members</h6>
              <p>
                Please Add Members for your space. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/member/list");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Members
                </span>
              </p>
            </div>
          )}
          {/* {activeStep === 4 && <p>Step 5 Content</p>} */}
        </div>
      )}
      {currentStep == 1 && (
        <div className="mt-5">
          {activeStep === 0 && (
            <div className="text-center">
              <h6 className="">
                Please add necessary credentials in settings first in order to
                work smoothly.
              </h6>
              {/* <p>
                Please add necessary credentials in settings first in order to
                work smoothly. <br />
                <span
                className="text-primary"
                onClick={() => {
                  navigate("/setting/accountsetting");
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                Click to set settings
              </span>
              </p> */}
            </div>
          )}
          {activeStep === 1 && (
            <div className="text-center">
              <h6 className="">Meeting Rooms</h6>
              <p>
                Please add Meeting Rooms. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/floorPlans");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Meeting Rooms
                </span>
              </p>
            </div>
          )}
          {activeStep === 2 && (
            <div className="text-center">
              <h6 className="">Packages</h6>
              <p>
                Please Add Packages for your space. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/package/addspace");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Packages
                </span>
              </p>
            </div>
          )}
          {activeStep === 3 && (
            <div className="text-center">
              <h6 className="">Members</h6>
              <p>
                Please Add Members for your space. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/member/list");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Members
                </span>
              </p>
            </div>
          )}
          {/* {activeStep === 4 && <p>Step 5 Content</p>} */}
        </div>
      )}
      {currentStep == 2 && (
        <div className="mt-5">
          {activeStep === 0 && (
            <div className="text-center">
              <h6 className="">Settings</h6>
              <p>
                Please add necessary credentials in settings first in order to
                work smoothly. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/setting/accountsetting");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to set settings
                </span>
              </p>
            </div>
          )}
          {activeStep === 1 && (
            <div className="text-center">
              <h6 className="">Please add Meeting Rooms.</h6>
              {/* <p>
                Please add Meeting Rooms. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/floorPlans");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Meeting Rooms
                </span>
              </p> */}
            </div>
          )}
          {activeStep === 2 && (
            <div className="text-center">
              <h6 className="">Packages</h6>
              <p>
                Please Add Packages for your space. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/package/addspace");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Packages
                </span>
              </p>
            </div>
          )}
          {activeStep === 3 && (
            <div className="text-center">
              <h6 className="">Members</h6>
              <p>
                Please Add Members for your space. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/member/list");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Members
                </span>
              </p>
            </div>
          )}
          {/* {activeStep === 4 && <p>Step 5 Content</p>} */}
        </div>
      )}
      {currentStep == 3 && (
        <div className="mt-5">
          {activeStep === 0 && (
            <div className="text-center">
              <h6 className="">Settings</h6>
              <p>
                Please add necessary credentials in settings first in order to
                work smoothly. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/setting/accountsetting");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to set settings
                </span>
              </p>
            </div>
          )}
          {activeStep === 1 && (
            <div className="text-center">
              <h6 className="">Meeting Rooms</h6>
              <p>
                Please add Meeting Rooms. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/floorPlans");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Meeting Rooms
                </span>
              </p>
            </div>
          )}
          {activeStep === 2 && (
            <div className="text-center">
              <h6 className="">Please Add Packages for your space.</h6>
              {/* <p>
                Please Add Packages for your space. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/package/addspace");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Packages
                </span>
              </p> */}
            </div>
          )}
          {activeStep === 3 && (
            <div className="text-center">
              <h6 className="">Members</h6>
              <p>
                Please Add Members for your space. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/member/list");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Members
                </span>
              </p>
            </div>
          )}
          {/* {activeStep === 4 && <p>Step 5 Content</p>} */}
        </div>
      )}
      {currentStep == 4 && (
        <div className="mt-5">
          {activeStep === 0 && (
            <div className="text-center">
              <h6 className="">Settings</h6>
              <p>
                Please add necessary credentials in settings first in order to
                work smoothly. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/setting/accountsetting");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to set settings
                </span>
              </p>
            </div>
          )}
          {activeStep === 1 && (
            <div className="text-center">
              <h6 className="">Meeting Rooms</h6>
              <p>
                Please add Meeting Rooms. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/floorPlans");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Meeting Rooms
                </span>
              </p>
            </div>
          )}
          {activeStep === 2 && (
            <div className="text-center">
              <h6 className="">Packages</h6>
              <p>
                Please Add Packages for your space. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/package/addspace");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Packages
                </span>
              </p>
            </div>
          )}
          {activeStep === 3 && (
            <div className="text-center">
              <h6 className="mb-3">
                You are almost done!. Please Add Members for your space.
              </h6>
              {/* <p>
                Please Add Members for your space. <br />
                <span
                  className="text-primary"
                  onClick={() => {
                    navigate("/member/list");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Click to Add Members
                </span>
              </p> */}
            </div>
          )}
          {/* {activeStep === 4 && <p>Step 5 Content</p>} */}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
