import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
  useExpenseCategoryCreateMutation,
  useExpenseCategoryUpdateMutation,
} from "../../Slices/spaceApi";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { isFirstCharacterDigit } from "../../utils/validation";

const ExpenseCategoryAdd = ({ handleClose, operateId, operateObject }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [input, setInput] = useState({
    name: "",
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [addExpenseCategory, result] = useExpenseCategoryCreateMutation();
  const [updateExpenseCategory, updated] = useExpenseCategoryUpdateMutation();
  const handleInput = (e) => {
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    if (operateId && operateObject) {
      setInput(operateObject);
    }
  }, [operateId, operateObject]);
  useEffect(() => {
    if (result?.isSuccess) {
      handleClose();
      toast.success("Expense Category Added Successfully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
    if (updated?.isSuccess) {
      handleClose();
      toast.success("Expense Category Updated Successfully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [result, updated]);
  const handleAdd = (event) => {
    setIsSubmit(true);
    const form = event.currentTarget;
    //.log(form.checkValidity())
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      !operateId
        ? addExpenseCategory({
            spaceId: spaceId || currentSpaceId,
            input,
          })
        : updateExpenseCategory({
            spaceId: spaceId || currentSpaceId,
            id: operateId,
            input,
          });
    }
  };
  useEffect(() => {
    if (result.isError) {
      const firstError = result.error.data.error;
      Swal.fire({
        title: "Required!",
        icon: "warning",
        html: firstError,
        confirmButtonText: "OK",
      });
    }
    if (updated.isError) {
      const firstError = updated.error.data.error;
      Swal.fire({
        title: "Required!",
        icon: "warning",
        html: firstError,
        confirmButtonText: "OK",
      });
    }
  }, [result, updated]);
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="w-10"
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">
            {!operateId ? "Add Expense Category" : "Update Expense Category"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAdd}>
            <Row className="mb-3 mt-3">
              <Form.Group controlId="Expensecategory">
                <Form.Label className="mb-2">
                  <h6>Category Name</h6>
                </Form.Label>
                <Form.Control
                  className="viewonly"
                  required
                  pattern="[A-Za-z].*"
                  type="text"
                  placeholder="Expense Category"
                  value={input?.name}
                  name="name"
                  onChange={handleInput}
                ></Form.Control>

                {validated && (
                  <Form.Control.Feedback type="invalid">
                    {!input.name ? (
                      "This field is Required"
                    ) : isFirstCharacterDigit(input?.name) ? (
                      "Must start with an alphabet"
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>

            {result.isLoading || updated.isLoading ? (
              <>
                <Button className="btn btn-primary">
                  Loading...{" "}
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="primary"
                  />
                </Button>
              </>
            ) : (
              <Button className="btn btn-primary " type="submit">
                {!operateId ? "Add Category" : "Update Category"}
              </Button>
            )}
            <Button className="btn btn-secondary ml-3" onClick={handleClose}>
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpenseCategoryAdd;
