import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";

function FloorView({ id }) {
  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">View Floor Plan</Tooltip>}
      >
        <Button
          variant="white"
          className="p-0"
          as={Link}
          to={`/floorPlans/${id}/view`}
        >
          <Preview title="View Quotation" />
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export default FloorView;
