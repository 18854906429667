import React from "react";
import { useEffect } from "react";
import { Button, Modal } from ".";
import { useGlobalContext } from "../../Context/GlobalContext";

const AddCustomer = () => {
  const { customer, handleCloseCustomer, setSelectedValue } =
    useGlobalContext();

  useEffect(() => {
    if (!customer) {
      setSelectedValue(3);
    }
  }, [customer]);

  return (
    <>
      <Modal
        show={customer}
        onHide={handleCloseCustomer}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title>Add Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="customer-name" className="form-label">
                Customer Name
              </label>
              <input
                type="text"
                className="form-control"
                id="customer-name"
                placeholder="Enter Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="customer-email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="customer-email"
                placeholder="Enter Email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="customer-address" className="form-label">
                Customer Address
              </label>
              <textarea
                className="form-control"
                id="customer-address"
                cols="2"
                rows="2"
                placeholder="Enter Customer Address"
              ></textarea>
            </div>
            <div className="form-group position-relative">
              <label htmlFor="customer-country" className="form-label">
                Country
              </label>
              <select
                className="form-control select2-hidden-accessible"
                id="customer-country"
                name="customer-country"
                data-select2-id="customer-country"
                placeholder="Enter Country"
                tabIndex="-1"
                aria-hidden="true"
              >
                <option value="Australia">Australia</option>
                <option value="Canada">Canada</option>
                <option value="Russia">Russia</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Singapore">Singapore</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United Arab Emirates">
                  United Arab Emirates
                </option>
                <option value="United States of America">
                  United States of America
                </option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="customer-contact" className="form-label">
                Contact
              </label>
              <input
                type="number"
                className="form-control"
                id="customer-contact"
                placeholder="Enter Phone"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={handleCloseCustomer}
          >
            Close
          </button>
          <Button variant="primary">Add Customer</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCustomer;
