// components
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import * as Icon from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import Loader from "../Components/Loader/Loader";

import {
  useCreateCommentMutation,
  useCreatePostMutation,
  useDeleteCommentMutation,
  useDeletePostMutation,
  useGetBlocksUsersQuery,
  useGetLoggedInAdminQuery,
  useGetPostsQuery,
  useUpdateCommentMutation,
  useUpdatePostMutation,
} from "../Slices/spaceApi";
import {
  ConfirmComponent,
  CustomAlert,
  DeleteComponent,
  NewconfirmationAlert,
  confirmationAlert,
  errorAlertImage,
  requiredAlert,
  userDeleteAlert,
} from "../utils/Alerts";
import { maxFileSize } from "../utils/maxFileSize";

import { TimeAgo } from "../utils/timeAgo";
import EditPost from "./EditPost";

import { DELETECHECK } from "../Slices/spaceSlice";
import {
  Circle,
  CircleFill,
  Heart,
  HeartArrow,
  HeartFill,
  Icon1Circle,
  Icon1CircleFill,
  Icon2Circle,
  Trash,
} from "react-bootstrap-icons";
import { Edit } from "../Assets/FloorPlanSvgs/Icon";
import { useId } from "react";
import { Badge, Tab, Tabs } from "react-bootstrap";
import toast from "react-hot-toast";
import { useGlobalContext } from "../Context/GlobalContext";
import { getRandomColorDark } from "../utils/getRandomColorDark";
import { getInitials } from "../utils/getInitials";
import { WhatsappSend } from "../Assets/DashboardSvgs/Icon";

// ----------------------------------------------------------------------

function Comment({
  comment,
  replyComment,
  handlePopoverCommentOpen,
  handleEditComment,
  handleDeleteComment,
  selectedCommentEdit,
  selectedCommentReply,
  postUpdateComment,
  postReplyComment,
  data,
  user,
  setSelectedCommentReply,
  isMediumScreen,
  selectedComment,
  anchorEl1,
  openComment,
  handlePopoverCommentClose,
  setSelectedCommentEdit,
  resultStats4,
  level = 0, // Add a level property to track nesting level
}) {
  const { currentUserId, userId, spaceId, currentSpaceId } = useSelector(
    (item) => item.spaceReducer
  );
  const [updateComment, resultStats] = useUpdateCommentMutation();
  const { communityPermissions } = useGlobalContext();
  const handleHideComment = (comment) => {
    NewconfirmationAlert(
      "Want to Hide This Comment?",
      "You can revert this action.",
      "Yes,Hide!",
      "No!"
    ).then((hide) => {
      if (hide) {
        updateComment({
          spaceId: spaceId || currentSpaceId,
          postId: comment?.post,
          commentId: comment.id,
          data: { hidden: true },
        });
      }
    });
  };

  const handleUnHideComment = (comment) => {
    NewconfirmationAlert(
      "Want to Un-Hide This Comment?",
      "",
      "Yes,Un-Hide!",
      "No!"
    ).then((hide) => {
      if (hide) {
        updateComment({
          spaceId: spaceId || currentSpaceId,
          postId: comment?.post,
          commentId: comment.id,
          data: { hidden: false },
        });
      }
    });
  };
  // Calculate the width based on the nesting level
  const width = level === 0 ? "100%" : `calc(100% - ${level * 10}px)`; // Adjust the subtraction factor as needed
  useEffect(() => {
    if (resultStats?.isSuccess) {
      toast.success("Comment Status Updated Successfully.");
    } else if (resultStats?.isError) {
      const errorMessage = resultStats?.error?.data?.message;
      requiredAlert(errorMessage || "An Error Occured");
    }
  }, [resultStats]);

  return (
    <div>
      {selectedCommentEdit?.id === comment.id ? (
        <div style={{ marginTop: "10px", paddingLeft: `${level * 16}px` }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            {/* <img
              src={data?.profileImage}
              alt="Avatar"
              style={{
                marginRight: "8px",
                borderRadius: "50%",
                width: "32px",
                height: "32px",
              }}
            /> */}
            {data?.profileImage ? (
              <img
                className="rounded-circle"
                style={{
                  width: "32px",
                  height: "32px",
                  objectFit: "cover",
                }}
                src={data?.profileImage}
                alt={data?.name}
              />
            ) : (
              <div
                className="rounded-circle"
                style={{
                  width: "32px",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: getRandomColorDark(),
                  color: "#fff",
                  fontSize: "16px",
                }}
              >
                {getInitials(data?.name)}
              </div>
            )}
            <input
              type="text"
              className="form-control ml-1"
              placeholder="Write a comment..."
              value={selectedCommentEdit?.text}
              onChange={(e) =>
                setSelectedCommentEdit({
                  ...selectedCommentEdit,
                  text: e.target.value,
                })
              }
              onKeyPress={(e) => {
                if (e.key === "Enter" && !resultStats4.isLoading) {
                  e.preventDefault();
                  postUpdateComment(comment);
                }
              }}
              style={{ width: "80%" }}
            />
            {/* <button
              className="btn btn-primary"
              disabled={resultStats4?.isLoading}
              style={{ marginLeft: "8px" }}
              onClick={() => postUpdateComment(comment)}
            > */}
            {/* <i className="ri-checkbox-circle-fill" /> */}
            <div onClick={() => postUpdateComment(comment)}>{WhatsappSend}</div>
            {/* <Icon.Send
              onClick={() => postUpdateComment(comment)}
              style={{
                width: "25px",
                height: "25px",
                color: "blue",
                marginLeft: "5px",
              }}
            /> */}
            {/* </button> */}
          </div>
        </div>
      ) : (
        <div>
          <div
            key={comment.id}
            className="list-group-item mt-2"
            style={{
              marginLeft: comment.parent_comment ? "auto" : "",
              backgroundColor: comment.parent_comment
                ? "rgb(147, 189, 247,0.1)"
                : "rgb(91, 153, 240,0.2)",
              borderRadius: "0.375rem",
              cursor: "pointer",
              overflow: "hidden",
              position: "relative",
              width: width,
              minHeight: "140px",
            }}
          >
            <div className="d-flex align-items-start" style={{ width: "100%" }}>
              {/* <img
                src={comment.user.image}
                alt="Avatar"
                className="me-2"
                style={{ borderRadius: "50%", width: "40px", height: "40px" }}
              /> */}
              {comment?.user?.image ? (
                <img
                  className="rounded-circle"
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "cover",
                  }}
                  src={comment?.user?.image}
                  alt={comment?.user?.name}
                />
              ) : (
                <div
                  className="rounded-circle"
                  style={{
                    width: "32px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: getRandomColorDark(),
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  {getInitials(comment?.user?.name)}
                </div>
              )}
              <div style={{ flex: "1", opacity: comment.hidden ? 0.4 : 1 }}>
                <h6 className="mb-0 ml-2">
                  {comment?.user?.name}
                  {comment.hidden && (
                    <Badge bg="badge bg-label-danger">Hidden</Badge>
                  )}
                </h6>

                <p className="mb-0 ml-2" style={{ width: "80%" }}>
                  {comment.text}
                </p>
                <p
                  className="mb-0 text-primary w-25"
                  style={{
                    cursor: "pointer",
                    // textDecoration: "underline",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                  onClick={
                    communityPermissions[0]?.update
                      ? () => replyComment(comment)
                      : null
                  }
                >
                  <Icon.ReplyFill style={{ marginBottom: "5px" }} />
                  {communityPermissions[0]?.update ? (
                    "Reply"
                  ) : (
                    <span>
                      Reply <Icon.Lock />
                    </span>
                  )}
                </p>

                <span style={{ color: "teal" }}>
                  {" "}
                  <TimeAgo date={comment.created_at} />
                </span>
              </div>
              <div>
                <Icon.ThreeDotsVertical
                  onClick={(e) => handlePopoverCommentOpen(e, comment.id)}
                  style={{
                    // fontSize: "30px",
                    // backgroundColor: "transparent",
                    // borderRadius: "50%",
                    fontSize: "30px",
                    border:
                      openComment && selectedComment === comment.id
                        ? "1px solid rgba(47, 43, 61, 0.68)"
                        : "",
                    // backgroundColor: "rgb(178, 206, 239)",
                    borderRadius: "2px",
                    marginRight: "10px",
                    cursor: "pointer",
                    padding: "0 5px 0 5px",
                  }}
                  // onMouseOver={(e) =>
                  //   (e.currentTarget.style.backgroundColor =
                  //     "rgb(178, 206, 239)")
                  // }
                  // onMouseOut={(e) =>
                  //   (e.currentTarget.style.backgroundColor =

                  //     "transparent")
                  // }
                />
                {comment.owner ? (
                  <div
                    // className="popover"
                    // id={`comment-actions-${comment.id}`}
                    // tabIndex="-1"
                    // aria-labelledby={`comment-actions-${comment.id}-label`}
                    style={{
                      display:
                        openComment && selectedComment === comment.id
                          ? "block"
                          : "none",

                      // marginTop: "25px",
                      right: "30px",
                      borderRadius: "0.75rem",
                      position: "absolute",
                    }}
                  >
                    <ul className="list-group">
                      <>
                        <li
                          className="list-group-item"
                          onClick={
                            communityPermissions[0]?.update
                              ? () => handleEditComment(comment)
                              : null
                          }
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              "rgb(91, 153, 240,0.2)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor = "")
                          }
                        >
                          <i className="ri-edit-fill me-2" />
                          {communityPermissions[0]?.update ? (
                            "Edit"
                          ) : (
                            <span>
                              Edit{" "}
                              <Icon.LockFill style={{ marginBottom: "5px" }} />
                            </span>
                          )}
                        </li>
                        <li
                          className="list-group-item text-danger"
                          onClick={
                            communityPermissions[0]?.delete
                              ? () => handleDeleteComment(comment)
                              : null
                          }
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              "rgb(91, 153, 240,0.2)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor = "")
                          }
                        >
                          <i className="ri-trash-2-fill me-2" />
                          {communityPermissions[0]?.delete ? (
                            "Delete"
                          ) : (
                            <span>
                              Delete <Icon.LockFill />
                            </span>
                          )}
                        </li>
                      </>
                    </ul>
                  </div>
                ) : (
                  <div
                    // className="popover"
                    style={{
                      display:
                        openComment && selectedComment === comment.id
                          ? "block"
                          : "none",
                      right: "30px",
                      borderRadius: "0.75rem",
                      position: "absolute",
                    }}
                  >
                    <ul className="list-group">
                      <>
                        {comment?.hidden ? (
                          <li
                            className="list-group-item"
                            onClick={
                              communityPermissions[0]?.update
                                ? () => handleUnHideComment(comment)
                                : null
                            }
                            onMouseOver={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "rgb(91, 153, 240,0.2)")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.backgroundColor = "")
                            }
                          >
                            <i className="ri-edit-fill me-2" />
                            {communityPermissions[0]?.update ? (
                              "Un-Hide"
                            ) : (
                              <span>
                                Un-Hide
                                <Icon.LockFill />
                              </span>
                            )}{" "}
                          </li>
                        ) : (
                          <li
                            className="list-group-item"
                            onClick={
                              communityPermissions[0]?.update
                                ? () => handleHideComment(comment)
                                : null
                            }
                            onMouseOver={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "rgb(91, 153, 240,0.2)")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.backgroundColor = "")
                            }
                          >
                            <i className="ri-edit-fill me-2" />
                            {communityPermissions[0]?.update ? (
                              "Hide"
                            ) : (
                              <span>
                                Hide <Icon.LockFill />
                              </span>
                            )}
                          </li>
                        )}

                        <li
                          className="list-group-item text-danger"
                          onClick={
                            communityPermissions[0]?.delete
                              ? () => handleDeleteComment(comment)
                              : null
                          }
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              "rgb(91, 153, 240,0.2)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor = "")
                          }
                        >
                          <i className="ri-trash-2-fill me-2" />
                          {communityPermissions[0]?.delete ? (
                            "Delete"
                          ) : (
                            <span>
                              Delete{" "}
                              <Icon.Lock style={{ marginBottom: "5px" }} />
                            </span>
                          )}
                        </li>
                      </>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          {selectedCommentReply?.id === comment.id && (
            <div
              style={{
                marginBottom: "16px",
                width: "90%",
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              {/* <img
                src={data?.profileImage}
                alt="Avatar"
                style={{
                  marginRight: "8px",
                  borderRadius: "50%",
                  width: "32px",
                  height: "32px",
                }}
              /> */}
              {data?.profileImage ? (
                <img
                  className="rounded-circle"
                  style={{
                    width: "30px",
                    height: "30px",
                    objectFit: "cover",
                  }}
                  src={data?.profileImage}
                  alt={data?.name}
                />
              ) : (
                <div
                  className="rounded-circle"
                  style={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: getRandomColorDark(),
                    color: "#fff",
                    fontSize: "12px",
                  }}
                >
                  {getInitials(data?.name)}
                </div>
              )}
              <input
                type="text"
                className="form-control ml-2"
                placeholder="Write a reply..."
                defaultValue={""}
                // value={selectedCommentReply?.text}
                onChange={(e) =>
                  setSelectedCommentReply({
                    ...selectedCommentReply,
                    text: e.target.value,
                  })
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    postReplyComment();
                  }
                }}
              />
              <div onClick={postReplyComment}>{WhatsappSend}</div>
              {/* <Icon.Send
                onClick={postReplyComment}
                style={{
                  width: "25px",
                  height: "25px",
                  color: "blue",
                  marginLeft: "5px",
                }}
              /> */}
            </div>
          )}

          {comment?.replies?.map((reply) => (
            <Comment
              key={reply.id}
              comment={reply}
              replyComment={replyComment}
              handlePopoverCommentOpen={handlePopoverCommentOpen}
              handleEditComment={handleEditComment}
              handleDeleteComment={handleDeleteComment}
              selectedCommentEdit={selectedCommentEdit}
              selectedCommentReply={selectedCommentReply}
              setSelectedCommentReply={setSelectedCommentReply}
              postUpdateComment={postUpdateComment}
              postReplyComment={postReplyComment}
              selectedComment={selectedComment}
              setSelectedCommentEdit={setSelectedCommentEdit}
              data={data}
              anchorEl1={anchorEl1}
              openComment={openComment}
              handlePopoverCommentClose={handlePopoverCommentClose}
              isMediumScreen={isMediumScreen}
              level={level + 1} // Increment the nesting level
            />
          ))}
        </div>
      )}
    </div>
  );
}

const CommentList = ({ comments, ...props }) => {
  return (
    <div>
      {comments?.map((comment) => (
        <div key={comment.id} className="mb-4 mt-1">
          <Comment comment={comment} {...props} />
        </div>
      ))}
    </div>
  );
};

export default function DashboardAppPage() {
  const { currentUserId, userId, spaceId, currentSpaceId } = useSelector(
    (item) => item.spaceReducer
  );
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Community Dashboard");
  }, []);
  const { communityPermissions } = useGlobalContext();
  const userToCheck = spaceId || currentSpaceId;

  // Get the current date
  const currentDate = new Date();

  // Calculate the first day of the current month
  const firstDayOfCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const { data: adminData, isLoading: adminLoading } = useGetLoggedInAdminQuery(
    spaceId || currentSpaceId
  );

  const [createPost, resultStats] = useCreatePostMutation();
  const [updatePost, resultStats5] = useUpdatePostMutation();

  const [createComment, resultStats1] = useCreateCommentMutation();
  const [deletePost, resultStats2] = useDeletePostMutation();
  const [deleteComment, resultStats3] = useDeleteCommentMutation();
  const [updateComment, resultStats4] = useUpdateCommentMutation();

  const [imageMessage, setImageMessage] = useState("");

  const {
    data: postsData,
    isLoading: postsLoading,
    refetch,
  } = useGetPostsQuery({ spaceId: spaceId || currentSpaceId });
  const {
    data: blockUsersData,
    isLoading: blockUsersLoading,
    refetch: blockRefetch,
  } = useGetBlocksUsersQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const [typeAdd, setTypeAdd] = useState("");
  const [messageAdd, setMessageAdd] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setTypeAdd(true);
      setLoading(false);
      setOpenAlert(true);
      setMessageAdd("Successfully Created!");
      setPostData({ text: "", image: "" });
      setSelectedImage(null);
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );
      setMessageAdd(errorMessage);
      setLoading(false);
      setOpenAlert(true);
      setTypeAdd(false);
    }
  }, [resultStats]);

  const handleLikeClick = (postId) => {
    axios
      .get(`/space/${spaceId || currentSpaceId}/post/${postId}/like/`)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          refetch();
        } else {
          console.log("Request failed with status:", response.status);
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const blockPost = (userId) => {
    axios
      .get(`/space/${spaceId || currentSpaceId}/block/${userId}/block/`)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          refetch();
          blockRefetch();
        } else {
          console.log("Request failed with status:", response.status);
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const handleRemoveLike = (postId) => {
    axios
      .get(`/space/${spaceId || currentSpaceId}/post/${postId}/remove-like/`)
      .then((response) => {
        if (response.status === 200) {
          refetch();
        } else {
          console.log("Request failed with status:", response.status);
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const handleSpam = (postId) => {
    axios
      .get(`/space/${spaceId || currentSpaceId}/post/1/spam/`)
      .then((response) => {
        if (response.status === 200) {
          refetch();
        } else {
          console.log("Request failed with status:", response.status);
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const handleInappropriate = (postId) => {
    axios
      .get(`/space/${spaceId || currentSpaceId}/post/1/spam/`)
      .then((response) => {
        if (response.status === 200) {
          refetch();
        } else {
          console.log("Request failed with status:", response.status);
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const [postData, setPostData] = useState({
    image: "",
    text: "",
  });

  const [loading, setLoading] = useState(false);

  const [typeImage, setTypeImage] = useState("");
  const [openImage, setOpenImage] = useState(false);

  const [typePost, setTypePost] = useState("");
  const [openPost, setOpenPost] = useState(false);
  const [messagePost, setMessagePost] = useState("");

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setMessagePost("Successfully Added!");
      setTypePost(true);
      setLoading(false);
      setOpenPost(true);
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );
      setMessagePost(errorMessage);
      setLoading(false);
      setOpenPost(true);
      setTypePost(false);
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats2?.isSuccess) {
      setTypePost(true);
      setOpenPost(true);
      userDeleteAlert("Post Deleted Successfully.");
    } else if (resultStats2?.isError) {
      const errorMessage = resultStats2?.error?.data.message;
      requiredAlert(errorMessage);
      setOpenPost(true);
      setTypePost(false);
    }
  }, [resultStats2]);
  useEffect(() => {
    if (resultStats3?.isSuccess) {
      userDeleteAlert("Comment Deleted Successfully.");
    } else if (resultStats3?.isError) {
      const errorMessage = resultStats3?.error?.data?.message;
      requiredAlert(errorMessage || "An Error Occured");
    }
  }, [resultStats3]);
  useEffect(() => {
    if (resultStats5?.isSuccess) {
      toast.success("Post Updated Successfully.");
    } else if (resultStats5?.isError) {
      const errorMessage = resultStats5?.error?.data?.message;
      requiredAlert(errorMessage || "An Error Occured");
    }
  }, [resultStats5]);

  useEffect(() => {
    if (selectedImage) {
      if (
        selectedImage.type &&
        (selectedImage.type.includes("image/jpeg") ||
          selectedImage.type.includes("image/jpg") ||
          selectedImage.type.includes("image/png") ||
          selectedImage.type.includes("image/webp"))
      ) {
        const fd = new FormData();
        fd.append("image", selectedImage); // Append the selected file to the FormData
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setImageMessage("Successfully Upload!");

            setLoading(false);
            setOpenImage(true);
            setTypeImage(true);
            setPostData({ ...postData, image: resp.data?.[0] }); // Update the 'file' field value
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setImageMessage(
          "Invalid image format. Please upload a valid JPEG, JPG, PNG, or WebP file."
        );
        setSelectedImage("");
        setOpenImage(true);
        setLoading(false);
        setTypeImage(false);
      }
    }
  }, [selectedImage]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setLoading(true);
      if (selectedFile.size > maxFileSize) {
        setImageMessage("File size exceeds the limit (3.1 MB).");
      } else {
        setSelectedImage(selectedFile);
      }
    }
  };

  // Handle posting
  const handlePost = () => {
    createPost({
      spaceId: spaceId || currentSpaceId,
      data: { content: postData?.text, image: postData?.image },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [openPopover, setOpenPopover] = useState(null);
  const handlePopoverOpen = (event, postId) => {
    setSelectedComment(null);
    setOpenPopover((prev) => (prev === postId ? null : postId));
    if (postId == openPopover) {
      handlePopoverClose();
    } else {
      setAnchorEl(event.currentTarget);
      setSelectedPost(postId);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedPost(null);
  };
  const [openCommentPopover, setOpenCommentPopover] = useState(null);

  // console.log(selectedPost);

  const open = Boolean(anchorEl);

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);

  const handlePopoverCommentOpen = (event, commentId) => {
    setOpenPopover(null);

    setOpenCommentPopover((prev) => (prev === commentId ? null : commentId));
    if (commentId == selectedComment) {
      handlePopoverCommentClose();
    } else {
      setAnchorEl1(event.currentTarget);
      setSelectedComment(commentId);
    }
  };

  const handlePopoverCommentClose = () => {
    setAnchorEl1(null);
    setSelectedComment(null);
  };

  const openComment = Boolean(anchorEl1);

  const [commentTexts, setCommentTexts] = useState(
    Array(postsData?.posts.length).fill("")
  );
  const [resetIndex, setResetIndex] = useState("");

  const [editStates, setEditStates] = useState(
    postsData?.posts?.map(() => false)
  );
  const [openFilter, setOpenFilter] = useState(false);
  const [selectVisit, setSelectVisit] = useState();
  const [selectVisitIndex, setSelectedVisitIndex] = useState();
  const [selectedCommentEdit, setSelectedCommentEdit] = useState("");
  const [selectedCommentReply, setSelectedCommentReply] = useState("");

  const [openCheck, setOpenCheck] = useState(false);
  const [selectDelete, setSelectDelete] = useState(false);

  const [openCheckComment, setOpenCheckComment] = useState(false);
  const [selectDeleteComment, setSelectDeleteComment] = useState(null);

  const handleOpenFilter = (item, idx) => {
    console.log("Selected Index is", idx);
    console.log("Item Data is", item);
    setSelectedVisitIndex(idx);
    setSelectVisit(item);
    handlePopoverClose();
    setOpenPopover(null);
    setOpenFilter(true);
  };

  const handleApprove = (post) => {
    NewconfirmationAlert(
      "Want to Approve this Post?",
      "",
      "Yes,Approve!",
      "No! Cancel"
    ).then((approve) => {
      if (approve) {
        updatePost({
          spaceId: spaceId || currentSpaceId,
          postId: post.id,
          data: { status: 1 },
        });
      }
    });
  };
  const handleDisapprove = (post) => {
    NewconfirmationAlert(
      "Want to Disapprove this Post?",
      "",
      "Yes,DisApprove!",
      "No! Cancel"
    ).then((disapprove) => {
      if (disapprove) {
        updatePost({
          spaceId: spaceId || currentSpaceId,
          postId: post.id,
          data: { status: 2 },
        });
      }
    });
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleCommentTextChange = (event, index) => {
    const newCommentTexts = [...commentTexts];
    newCommentTexts[index] = event.target.value;
    setCommentTexts(newCommentTexts);
  };

  const postSubmit = (idx) => {
    setResetIndex(idx);
    createComment({
      spaceId: spaceId || currentSpaceId,
      postId: postsData?.posts?.[idx]?.id,
      data: { text: commentTexts[idx] },
    });
  };

  const hidepost = (post) => {
    NewconfirmationAlert(
      "Want to Hide This Post?",
      "You can revert this action.",
      "Yes,Hide!",
      "No!"
    ).then((hide) => {
      if (hide) {
        updatePost({
          spaceId: spaceId || currentSpaceId,
          postId: post.id,
          data: { hidden: true },
        });
      }
    });
  };
  const unHidePost = (post) => {
    NewconfirmationAlert(
      "Want to Un-Hide This Post?",
      "",
      "Yes,Un-Hide!",
      "No!"
    ).then((hide) => {
      if (hide) {
        updatePost({
          spaceId: spaceId || currentSpaceId,
          postId: post.id,
          data: { hidden: false },
        });
      }
    });
  };

  const handleDeletePost = (post) => {
    setOpenCheck(true);
    setSelectDelete(post);
    setSelectedPost(null);

    confirmationAlert("Want to Delete This Post?").then((willDelete) => {
      if (willDelete) {
        deletePost({
          spaceId: spaceId || currentSpaceId,
          postId: post.id,
        });
      }
    });
  };

  const handleDeleteComment = (comment) => {
    setOpenCheckComment(true);
    setSelectDeleteComment(comment);

    setSelectedComment(null);
    confirmationAlert("Want to Delete This Comment?").then((willDelete) => {
      if (willDelete) {
        deleteComment({
          spaceId: spaceId || currentSpaceId,
          postId: comment.post,
          commentId: comment.id,
        });
      }
    });
  };

  const handleEditComment = (comment) => {
    setSelectedCommentEdit(comment);
    setSelectedComment(null);
  };

  const postUpdateComment = (comment) => {
    updateComment({
      spaceId: spaceId || currentSpaceId,
      postId: comment?.post,
      commentId: comment.id,
      data: { text: selectedCommentEdit?.text },
    });
  };

  const replyComment = (comment) => {
    // if (selectedCommentReply) {
    setSelectedCommentReply(comment);
    // } else {
    // setSelectedCommentReply(comment);
    // }
  };

  const postReplyComment = () => {
    createComment({
      spaceId: spaceId || currentSpaceId,
      postId: selectedCommentReply?.post,
      data: {
        text: selectedCommentReply?.text,
        parent_comment: selectedCommentReply?.id,
      },
    });
  };

  useEffect(() => {
    if (resultStats1?.isSuccess) {
      const newCommentTexts = [...commentTexts];
      newCommentTexts[resetIndex] = "";
      setCommentTexts(newCommentTexts);
      if (selectedCommentReply) {
        setSelectedCommentReply("");
      }
    }
  }, [resultStats1]);

  function organizeComments(comments) {
    const organizedComments = [];
    const commentMap = new Map();

    // Create a map of comments using their IDs
    comments.forEach((comment) => {
      // eslint-disable-next-line camelcase
      const { id, parent_comment } = comment;
      if (!commentMap.has(id)) {
        commentMap.set(id, { ...comment, replies: [] });
      }

      // eslint-disable-next-line camelcase
      if (parent_comment !== null) {
        if (!commentMap.has(parent_comment)) {
          commentMap.set(parent_comment, { replies: [] });
        }
        commentMap.get(parent_comment).replies.push(commentMap.get(id));
      } else {
        organizedComments.push(commentMap.get(id));
      }
    });

    return organizedComments;
  }

  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  const handleCloseCheckComment = () => {
    setOpenCheckComment(false);
  };

  useEffect(() => {
    if (resultStats4?.isSuccess) {
      setSelectedCommentEdit(null);
    }
  }, [resultStats4]);
  const uploadFile = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate}, ${formattedTime}`;
  }
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const truncateText = (text, maxLength) => {
    if (!text || text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };
  if (postsLoading) {
    return <Loader height={6.6} />;
  }

  return (
    <>
      {/* <Tabs
        defaultActiveKey="Dashboard"
        id="justify-tab-example"
        className="mb-3 mt-3 ml-3"
        variant="underline"
      >
        <Tab
          style={{ marginRight: "5px" }}
          eventKey="Dashboard"
          title={
            <span className="d-flex align-items-center">
              Community Dashboard
            </span>
          }
        > */}

      <div
        className="container-fluid"
        style={{ padding: "0px 60px", maxWidth: "80%" }}
      >
        {/* <h4 className="mb-5">Community Dashboard</h4> */}

        <div className="row">
          <div className="col-12">
            <div className="card p-3">
              <div className="mb-5">
                <textarea
                  value={postData?.text}
                  onChange={(e) =>
                    setPostData({ ...postData, text: e.target.value })
                  }
                  className="form-control"
                  placeholder="Share what you are thinking here..."
                  rows={10}
                />
              </div>

              <div className="d-flex align-items-center justify-content-between ">
                <div className="media">
                  <div className="media-aside align-self-start">
                    <img
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : "https://placehold.jp/90x90.png"
                      }
                      style={{
                        width: "90px",
                        height: "90px",
                        objectFit: "cover",
                      }}
                      className="rounded"
                    />
                  </div>
                  <div>
                    <input
                      id="real-file"
                      name="file"
                      type="file"
                      hidden="hidden"
                      // required
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="media-body mt-2">
                    {/* {isUploading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Uploading...</span>
                        </div>
                      ) : (
                        <button
                          id="custom-button"
                          onClick={uploadFile}
                          className="btn btn-primary me-2 btn-sm"
                        >
                          Upload
                        </button>
                      )} */}
                    <button
                      id="custom-button"
                      onClick={uploadFile}
                      className="btn btn-secondary me-2 btn-sm"
                    >
                      Upload
                    </button>
                    <p className="card-text mt-2">
                      {/* Allowed Max size of 10Mb */}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">
                            Allowed Max size of 10Mb
                          </Tooltip>
                        }
                      >
                        <Button variant="white" className="mx-2 p-0">
                          <Icon.InfoCircle style={{ color: "currentcolor" }} />
                        </Button>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={
                    !communityPermissions[0]?.create ||
                    loading ||
                    postData?.text.length <= 0
                  }
                  onClick={communityPermissions[0]?.create ? handlePost : ""}
                >
                  {loading ? (
                    "Loading..."
                  ) : communityPermissions[0]?.create ? (
                    "Post"
                  ) : (
                    <span>
                      Post <Icon.LockFill />
                    </span>
                  )}
                </button>
              </div>
            </div>

            <div className="container-fluid" style={{ padding: "0px 16px" }}>
              {/* <h4 className="mb-5">Community Dashboard</h4> */}

              <div className="row">
                <div className="col-12">
                  {postsData?.posts?.map((post, idx) => {
                    if (post.status !== 1) {
                      return (
                        <div key={post.id} className="mt-3 px-1 pb-1">
                          <div className="card w-75 ml-auto mr-auto">
                            <div className="card-header d-flex justify-content-between align-items-center px-2 pt-2">
                              <div className="media">
                                <div className="media-aside mr-2 mt-2 ml-2">
                                  {post.user.image ? (
                                    <img
                                      className="rounded-circle"
                                      style={{
                                        width: "5rem",
                                        height: "5rem",
                                        objectFit: "cover",
                                      }}
                                      src={
                                        post.user.image ||
                                        "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                      }
                                      alt={post.user.name}
                                    />
                                  ) : (
                                    <div
                                      className="rounded-circle"
                                      style={{
                                        width: "5rem",
                                        height: "5rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: getRandomColorDark(),
                                        color: "#fff",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {getInitials(post.user.name)}
                                    </div>
                                  )}
                                </div>
                                <div className="media-body my-auto">
                                  <span className="mb-0 table-avatar">
                                    <b>{post.user.name}</b>
                                  </span>
                                  <p className="card-subtitle mb-2 text-muted">
                                    {formatDateTime(post.created_at)}
                                  </p>

                                  <Badge
                                    bg={
                                      post.status == 3
                                        ? "badge bg-label-warning"
                                        : post.status == 2
                                        ? "badge bg-label-danger"
                                        : "badge bg-label-success"
                                    }
                                  >
                                    {post.status == 3
                                      ? "Waiting Approval"
                                      : post.status == 2
                                      ? "Disapproved"
                                      : "Approved"}
                                  </Badge>
                                </div>
                              </div>

                              <div>
                                <Icon.ThreeDotsVertical
                                  onClick={(e) => handlePopoverOpen(e, post.id)}
                                  style={{
                                    // color: "#0d6efd",
                                    fontSize: "30px",
                                    border:
                                      openPopover === post.id
                                        ? "1px solid rgba(47, 43, 61, 0.68)"
                                        : "",
                                    // backgroundColor: "rgb(178, 206, 239)",
                                    borderRadius: "2px",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    padding: "0 5px 0 5px",
                                  }}
                                  // onMouseOver={(e) =>
                                  //   (e.currentTarget.style.backgroundColor =
                                  //     "rgb(124, 182, 249)")
                                  // }
                                  // onMouseOut={(e) =>
                                  //   (e.currentTarget.style.backgroundColor =
                                  //     "rgb(178, 206, 239)")
                                  // }
                                />

                                <div
                                  id={`post-actions-${post.id}`}
                                  tabIndex="-1"
                                  aria-labelledby={`post-actions-${post.id}-label`}
                                  style={{
                                    position: "absolute",

                                    right: "30px",

                                    borderRadius: "0.75rem",
                                    display:
                                      openPopover === post.id
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <div className="">
                                    <ul
                                      className="list-group"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {post.owner ? (
                                        <>
                                          <li
                                            className="list-group-item"
                                            onClick={
                                              communityPermissions[0]?.update
                                                ? () =>
                                                    handleOpenFilter(post, idx)
                                                : null
                                            }
                                            onMouseOver={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                "rgb(91, 153, 240,0.2)")
                                            }
                                            onMouseOut={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                "")
                                            }
                                          >
                                            <Icon.Pencil />
                                            {communityPermissions[0]?.update ? (
                                              "Edit"
                                            ) : (
                                              <span>
                                                Edit{" "}
                                                <Icon.LockFill
                                                  style={{
                                                    marginBottom: "5px",
                                                  }}
                                                />
                                              </span>
                                            )}
                                          </li>
                                          <li
                                            className="list-group-item text-danger"
                                            onClick={
                                              communityPermissions[0]?.delete
                                                ? () => handleDeletePost(post)
                                                : null
                                            }
                                            onMouseOver={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                "rgb(91, 153, 240,0.2)")
                                            }
                                            onMouseOut={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                "")
                                            }
                                          >
                                            <Icon.Trash />
                                            {communityPermissions[0]?.delete ? (
                                              "Delete"
                                            ) : (
                                              <span>
                                                Delete{" "}
                                                <Icon.LockFill
                                                  style={{
                                                    marginBottom: "5px",
                                                  }}
                                                />
                                              </span>
                                            )}
                                          </li>
                                        </>
                                      ) : (
                                        <>
                                          {post.hidden ? (
                                            <li
                                              className="list-group-item"
                                              onClick={
                                                communityPermissions[0]?.update
                                                  ? () => unHidePost(post)
                                                  : null
                                              }
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onMouseOver={(e) =>
                                                (e.currentTarget.style.backgroundColor =
                                                  "rgb(91, 153, 240,0.2)")
                                              }
                                              onMouseOut={(e) =>
                                                (e.currentTarget.style.backgroundColor =
                                                  "")
                                              }
                                            >
                                              {communityPermissions[0]
                                                ?.update ? (
                                                "Un-Hide"
                                              ) : (
                                                <span>
                                                  Un-Hide{" "}
                                                  <Icon.LockFill
                                                    style={{
                                                      marginBottom: "5px",
                                                    }}
                                                  />
                                                </span>
                                              )}
                                            </li>
                                          ) : (
                                            <li
                                              className="list-group-item"
                                              onClick={
                                                communityPermissions[0]?.update
                                                  ? () => hidepost(post)
                                                  : null
                                              }
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onMouseOver={(e) =>
                                                (e.currentTarget.style.backgroundColor =
                                                  "rgb(91, 153, 240,0.2)")
                                              }
                                              onMouseOut={(e) =>
                                                (e.currentTarget.style.backgroundColor =
                                                  "")
                                              }
                                            >
                                              {communityPermissions[0]
                                                ?.update ? (
                                                "Hide"
                                              ) : (
                                                <span>
                                                  Hide{" "}
                                                  <Icon.LockFill
                                                    style={{
                                                      marginBottom: "5px",
                                                    }}
                                                  />
                                                </span>
                                              )}
                                            </li>
                                          )}

                                          <li
                                            className="list-group-item text-danger"
                                            onClick={
                                              communityPermissions[0]?.delete
                                                ? () => handleDeletePost(post)
                                                : null
                                            }
                                            style={{ cursor: "pointer" }}
                                            onMouseOver={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                "rgb(91, 153, 240,0.2)")
                                            }
                                            onMouseOut={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                "")
                                            }
                                          >
                                            {communityPermissions[0]?.delete ? (
                                              "Delete"
                                            ) : (
                                              <span>
                                                Delete{" "}
                                                <Icon.LockFill
                                                  style={{
                                                    marginBottom: "5px",
                                                  }}
                                                />
                                              </span>
                                            )}{" "}
                                          </li>
                                        </>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="card-body">
                              <p className="card-text">{post.content}</p>

                              {post.image && (
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    paddingBottom: "56.25%",
                                  }}
                                >
                                  <img
                                    src={post.image}
                                    alt=""
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "12px",
                                    }}
                                  />
                                </div>
                              )}

                              <div className="d-flex align-items-center justify-content-between mt-3">
                                <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                    {post?.likesOwner?.includes(
                                      adminData?.id
                                    ) ? (
                                      <Icon.HeartFill
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                        onClick={
                                          // !communityPermissions[0]?.update
                                          //   ?
                                          () => {
                                            // const likedByUser =
                                            //   post?.likesOwner?.includes(adminData?.id);

                                            // if (
                                            post?.likesOwner?.includes(
                                              adminData?.id
                                            )
                                              ? // ) {
                                                handleRemoveLike(post?.id)
                                              : // } else {:
                                                handleLikeClick(post?.id);
                                            // }
                                          }
                                          // : null
                                        }
                                      />
                                    ) : (
                                      <Icon.Heart
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          const likedByUser =
                                            post?.likesOwner?.length > 0;

                                          if (
                                            post?.likesOwner?.includes(
                                              adminData?.id
                                            )
                                          ) {
                                            handleRemoveLike(post?.id);
                                          } else {
                                            handleLikeClick(post?.id);
                                          }
                                        }}
                                      />
                                    )}
                                    {/* </button> */}
                                    <p className="ms-1 mt-3">
                                      {post.likes.length >= 1 &&
                                        post?.likes?.length}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center ms-2">
                                    {post?.likes?.map((avatar, index) => (
                                      // <img
                                      //   key={index}
                                      //   src={avatar.image}
                                      //   alt=""
                                      //   className="rounded-circle ms-1"
                                      //   style={{ width: "32px", height: "32px" }}
                                      // />
                                      <>
                                        {avatar.image ? (
                                          <img
                                            style={{
                                              // marginRight: "8px",
                                              borderRadius: "50%",
                                              width: "32px",
                                              height: "32px",
                                              objectFit: "cover",
                                            }}
                                            src={
                                              avatar.image ||
                                              "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                            }
                                            alt={avatar.name}
                                          />
                                        ) : (
                                          <div
                                            className="rounded-circle"
                                            style={{
                                              width: "32px",
                                              height: "32px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              backgroundColor:
                                                getRandomColorDark(),
                                              color: "#fff",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {getInitials(avatar.name)}
                                          </div>
                                        )}
                                      </>
                                    ))}
                                    {post.likes.length > 3 && (
                                      <div
                                        className="avatar"
                                        style={{
                                          fontSize: "17px",
                                          color: "rgb(0, 120, 103)",
                                        }}
                                      >
                                        +{post.likes.length - 3}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="mt-2">
                                <ul className="list-group">
                                  <CommentList
                                    comments={organizeComments(post?.comments)}
                                    selectedComment={selectedComment}
                                    // data={postData?.comments}
                                    data={adminData}
                                    handleDeleteComment={handleDeleteComment}
                                    handleEditComment={handleEditComment}
                                    postUpdateComment={postUpdateComment}
                                    replyComment={replyComment}
                                    postReplyComment={postReplyComment}
                                    selectedCommentReply={selectedCommentReply}
                                    setSelectedCommentEdit={
                                      setSelectedCommentEdit
                                    }
                                    selectedCommentEdit={selectedCommentEdit}
                                    setSelectedCommentReply={
                                      setSelectedCommentReply
                                    }
                                    handlePopoverCommentOpen={
                                      handlePopoverCommentOpen
                                    }
                                    anchorEl1={anchorEl1}
                                    // isMediumScreen={isMediumScreen}
                                    openComment={openComment}
                                    handlePopoverCommentClose={
                                      handlePopoverCommentClose
                                    }
                                    idx={idx}
                                    resultStats4={resultStats4}
                                  />
                                </ul>
                              </div>

                              {/* {communityPermissions[0]?.create ? (
                              <div className="mb-2 px-2 d-flex align-items-center">
                                {adminData?.profileImage ? (
                                  <img
                                    style={{
                                      marginRight: "8px",
                                      borderRadius: "50%",
                                      width: "36px",
                                      height: "36px",
                                      objectFit: "cover",
                                    }}
                                    src={
                                      adminData?.profileImage ||
                                      "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                    }
                                    alt={adminData?.name}
                                  />
                                ) : (
                                  <div
                                    className="rounded-circle"
                                    style={{
                                      width: "36px",
                                      height: "36px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor: getRandomColorDark(),
                                      color: "#fff",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {getInitials(adminData?.name)}
                                  </div>
                                )}
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Write a comment..."
                                  value={commentTexts[idx]}
                                  onChange={(e) =>
                                    handleCommentTextChange(e, idx)
                                  }
                                  onKeyPress={(e) => {
                                    if (
                                      e.key === "Enter" &&
                                      !resultStats1?.isLoading
                                    ) {
                                      e.preventDefault();
                                      postSubmit(idx);
                                    }
                                  }}
                                />

                                <Icon.CheckCircle
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    color: "green",
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                  }}
                                  // onMouseOver={(e) =>
                                  //   (e.currentTarget.style.color = "#1d9251")
                                  // }
                                  // onMouseOut={(e) =>
                                  //   (e.currentTarget.style.color = "green")
                                  // }
                                  disabled={resultStats1?.isLoading}
                                  onClick={() => postSubmit(idx)}
                                />
                              </div>
                            ) : null} */}
                            </div>

                            <div className="d-flex mt-2 mb-4 justify-content-center">
                              <button
                                className="btn btn-primary mr-2"
                                onClick={() => handleApprove(post)}
                              >
                                Approve
                              </button>
                              <button
                                className="btn-danger rounded ml-1"
                                onClick={() => handleDisapprove(post)}
                              >
                                Disapprove
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {/* {postsData?.posts?.some(
                          (post) => post.status !== 2 || post.status !== 2
                        ) && (
                          <h3 className="mt-4 ml-4">
                            No Posts are available for approval
                          </h3>
                        )} */}

                  {openFilter && (
                    <div className="edit-post">
                      <div className="container mt-3">
                        <div className="row justify-content-center">
                          <div className="col-md-8">
                            <EditPost
                              editStates={editStates}
                              idx={selectVisitIndex}
                              {...selectVisit}
                              data={postData}
                              onCloseFilter={handleCloseFilter}
                              setEditStates={setEditStates}
                              openFilter={openFilter}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="posts-div justify-content-center"> */}
            {postsData?.posts?.map((post, idx) => {
              if (post.status === 1) {
                return (
                  <div key={post.id} className="mt-3 px-1 pb-1">
                    <div className="card w-75 ml-auto mr-auto">
                      <div className="card-header d-flex justify-content-between align-items-center px-2 pt-2">
                        <div className="media">
                          <div className="media-aside mr-2 mt-2 ml-2">
                            {post.user.image ? (
                              <img
                                className="rounded-circle"
                                style={{
                                  width: "3rem",
                                  height: "3rem",
                                  objectFit: "cover",
                                }}
                                src={
                                  post.user.image ||
                                  "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                }
                                alt={post.user.name}
                              />
                            ) : (
                              <div
                                className="rounded-circle"
                                style={{
                                  width: "5rem",
                                  height: "5rem",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: getRandomColorDark(),
                                  color: "#fff",
                                  fontSize: "12px",
                                }}
                              >
                                {getInitials(post.user.name)}
                              </div>
                            )}
                          </div>
                          <div className="media-body my-auto">
                            <span className="mb-0 table-avatar">
                              <b>{post.user.name}</b>
                            </span>
                            <p className="card-subtitle mb-2 text-muted">
                              {formatDateTime(post.created_at)}
                            </p>

                            <Badge
                              bg={
                                post.hidden
                                  ? "badge bg-label-danger"
                                  : "badge bg-label-success"
                              }
                            >
                              {post.hidden ? "Hidden" : "Active"}
                            </Badge>
                          </div>
                        </div>

                        <div>
                          <Icon.ThreeDotsVertical
                            onClick={(e) => handlePopoverOpen(e, post.id)}
                            style={{
                              fontSize: "30px",
                              border:
                                openPopover === post.id
                                  ? "1px solid rgba(47, 43, 61, 0.68)"
                                  : "",
                              // backgroundColor: "rgb(178, 206, 239)",
                              borderRadius: "2px",
                              marginRight: "10px",
                              cursor: "pointer",
                              padding: "0 5px 0 5px",
                              // fontSize: "30px",
                              // backgroundColor: "transparent",
                              // borderRadius: "50%",
                              // marginRight: "10px",
                              // cursor: "pointer",
                            }}
                            // onMouseOver={(e) =>
                            //   (e.currentTarget.style.backgroundColor =
                            //     "rgb(124, 182, 249)")
                            // }
                            // onMouseOut={(e) =>
                            //   (e.currentTarget.style.backgroundColor =
                            //     // "rgb(178, 206, 239)"
                            //     "transparent")
                            // }
                          />

                          <div
                            id={`post-actions-${post.id}`}
                            tabIndex="-1"
                            aria-labelledby={`post-actions-${post.id}-label`}
                            style={{
                              position: "absolute",

                              right: "30px",

                              borderRadius: "0.75rem",
                              display:
                                openPopover === post.id ? "block" : "none",
                            }}
                          >
                            <div className="">
                              <ul
                                className="list-group"
                                style={{ cursor: "pointer" }}
                              >
                                {post.owner ? (
                                  <>
                                    <li
                                      className="list-group-item"
                                      onClick={
                                        communityPermissions[0]?.update
                                          ? () => handleOpenFilter(post, idx)
                                          : null
                                      }
                                      onMouseOver={(e) =>
                                        (e.currentTarget.style.backgroundColor =
                                          "rgb(91, 153, 240,0.2)")
                                      }
                                      onMouseOut={(e) =>
                                        (e.currentTarget.style.backgroundColor =
                                          "")
                                      }
                                    >
                                      <Icon.Pencil />
                                      {communityPermissions[0]?.update ? (
                                        "Update"
                                      ) : (
                                        <span>
                                          Update{" "}
                                          <Icon.LockFill
                                            style={{
                                              marginBottom: "5px",
                                            }}
                                          />
                                        </span>
                                      )}
                                    </li>
                                    <li
                                      className="list-group-item text-danger"
                                      onClick={
                                        communityPermissions[0]?.delete
                                          ? () => handleDeletePost(post)
                                          : null
                                      }
                                      onMouseOver={(e) =>
                                        (e.currentTarget.style.backgroundColor =
                                          "rgb(91, 153, 240,0.2)")
                                      }
                                      onMouseOut={(e) =>
                                        (e.currentTarget.style.backgroundColor =
                                          "")
                                      }
                                    >
                                      <Icon.Trash />
                                      {communityPermissions[0]?.delete ? (
                                        "Delete"
                                      ) : (
                                        <span>
                                          Delete{" "}
                                          <Icon.LockFill
                                            style={{
                                              marginBottom: "5px",
                                            }}
                                          />
                                        </span>
                                      )}
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    {post.hidden ? (
                                      <li
                                        className="list-group-item"
                                        onClick={
                                          communityPermissions[0]?.update
                                            ? () => unHidePost(post)
                                            : null
                                        }
                                        style={{ cursor: "pointer" }}
                                        onMouseOver={(e) =>
                                          (e.currentTarget.style.backgroundColor =
                                            "rgb(91, 153, 240,0.2)")
                                        }
                                        onMouseOut={(e) =>
                                          (e.currentTarget.style.backgroundColor =
                                            "")
                                        }
                                      >
                                        {communityPermissions[0]?.update ? (
                                          "Un-Hide"
                                        ) : (
                                          <span>
                                            Un-Hide{" "}
                                            <Icon.LockFill
                                              style={{
                                                marginBottom: "5px",
                                              }}
                                            />
                                          </span>
                                        )}
                                      </li>
                                    ) : (
                                      <li
                                        className="list-group-item"
                                        onClick={
                                          communityPermissions[0]?.update
                                            ? () => hidepost(post)
                                            : null
                                        }
                                        style={{ cursor: "pointer" }}
                                        onMouseOver={(e) =>
                                          (e.currentTarget.style.backgroundColor =
                                            "rgb(91, 153, 240,0.2)")
                                        }
                                        onMouseOut={(e) =>
                                          (e.currentTarget.style.backgroundColor =
                                            "")
                                        }
                                      >
                                        {communityPermissions[0]?.update ? (
                                          "Hide"
                                        ) : (
                                          <span>
                                            Hide{" "}
                                            <Icon.LockFill
                                              style={{
                                                marginBottom: "5px",
                                              }}
                                            />
                                          </span>
                                        )}
                                      </li>
                                    )}

                                    <li
                                      className="list-group-item text-danger"
                                      onClick={
                                        communityPermissions[0]?.delete
                                          ? () => handleDeletePost(post)
                                          : null
                                      }
                                      style={{ cursor: "pointer" }}
                                      onMouseOver={(e) =>
                                        (e.currentTarget.style.backgroundColor =
                                          "rgb(91, 153, 240,0.2)")
                                      }
                                      onMouseOut={(e) =>
                                        (e.currentTarget.style.backgroundColor =
                                          "")
                                      }
                                    >
                                      {communityPermissions[0]?.delete ? (
                                        "Delete"
                                      ) : (
                                        <span>
                                          Delete{" "}
                                          <Icon.LockFill
                                            style={{
                                              marginBottom: "5px",
                                            }}
                                          />
                                        </span>
                                      )}{" "}
                                    </li>
                                  </>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        {/* <p className="card-text px-5 ml-2">
                              {post.content}
                            </p> */}
                        <p className="card-text px-5 ml-2">
                          {expanded
                            ? post.content
                            : truncateText(post.content, 250)}
                          {post.content.length > 250 && (
                            <button
                              className="btn btn-link"
                              onClick={toggleExpand}
                            >
                              {expanded ? "Show Less" : "Show More"}
                            </button>
                          )}
                        </p>

                        {post.image && (
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              paddingBottom: "56.25%",
                            }}
                          >
                            <img
                              src={post.image}
                              alt=""
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "12px",
                              }}
                            />
                          </div>
                        )}

                        <div className="d-flex align-items-center justify-content-between mt-3 ml-3">
                          <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                              {post?.likesOwner?.includes(adminData?.id) ? (
                                <Icon.HeartFill
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                  onClick={
                                    // !communityPermissions[0]?.update
                                    //   ?
                                    () => {
                                      // const likedByUser =
                                      //   post?.likesOwner?.includes(adminData?.id);

                                      // if (
                                      post?.likesOwner?.includes(adminData?.id)
                                        ? // ) {
                                          handleRemoveLike(post?.id)
                                        : // } else {:
                                          handleLikeClick(post?.id);
                                      // }
                                    }
                                    // : null
                                  }
                                />
                              ) : (
                                <Icon.Heart
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    const likedByUser =
                                      post?.likesOwner?.length > 0;

                                    if (
                                      post?.likesOwner?.includes(adminData?.id)
                                    ) {
                                      handleRemoveLike(post?.id);
                                    } else {
                                      handleLikeClick(post?.id);
                                    }
                                  }}
                                />
                              )}
                              {/* </button> */}
                              <p className="ms-1 mt-3">
                                {post.likes.length >= 1 && post?.likes?.length}
                              </p>
                            </div>
                            <div className="d-flex align-items-center ms-2">
                              {post?.likes?.map((avatar, index) => (
                                // <img
                                //   key={index}
                                //   src={avatar.image}
                                //   alt=""
                                //   className="rounded-circle ms-1"
                                //   style={{ width: "32px", height: "32px" }}
                                // />
                                <>
                                  {avatar.image ? (
                                    <img
                                      style={{
                                        // marginRight: "8px",
                                        borderRadius: "50%",
                                        width: "32px",
                                        height: "32px",
                                        objectFit: "cover",
                                      }}
                                      src={
                                        avatar.image ||
                                        "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                      }
                                      alt={avatar.name}
                                    />
                                  ) : (
                                    <div
                                      className="rounded-circle"
                                      style={{
                                        width: "32px",
                                        height: "32px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: getRandomColorDark(),
                                        color: "#fff",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {getInitials(avatar.name)}
                                    </div>
                                  )}
                                </>
                              ))}
                              {post.likes.length > 3 && (
                                <div
                                  className="avatar"
                                  style={{
                                    fontSize: "17px",
                                    color: "rgb(0, 120, 103)",
                                  }}
                                >
                                  +{post.likes.length - 3}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mt-2">
                          <ul className="list-group">
                            <CommentList
                              comments={organizeComments(post?.comments)}
                              selectedComment={selectedComment}
                              // data={postData?.comments}
                              data={adminData}
                              handleDeleteComment={handleDeleteComment}
                              handleEditComment={handleEditComment}
                              postUpdateComment={postUpdateComment}
                              replyComment={replyComment}
                              postReplyComment={postReplyComment}
                              selectedCommentReply={selectedCommentReply}
                              setSelectedCommentEdit={setSelectedCommentEdit}
                              selectedCommentEdit={selectedCommentEdit}
                              setSelectedCommentReply={setSelectedCommentReply}
                              handlePopoverCommentOpen={
                                handlePopoverCommentOpen
                              }
                              anchorEl1={anchorEl1}
                              // isMediumScreen={isMediumScreen}
                              openComment={openComment}
                              handlePopoverCommentClose={
                                handlePopoverCommentClose
                              }
                              idx={idx}
                              resultStats4={resultStats4}
                            />
                          </ul>
                        </div>

                        {communityPermissions[0]?.create ? (
                          <div className="mb-2 px-2 d-flex align-items-center">
                            {adminData?.profileImage ? (
                              <img
                                style={{
                                  marginRight: "8px",
                                  borderRadius: "50%",
                                  width: "36px",
                                  height: "36px",
                                  objectFit: "cover",
                                }}
                                src={
                                  adminData?.profileImage ||
                                  "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                }
                                alt={adminData?.name}
                              />
                            ) : (
                              <div
                                className="rounded-circle"
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: getRandomColorDark(),
                                  color: "#fff",
                                  fontSize: "12px",
                                }}
                              >
                                {getInitials(adminData?.name)}
                              </div>
                            )}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Write a comment..."
                              value={commentTexts[idx]}
                              onChange={(e) => handleCommentTextChange(e, idx)}
                              onKeyPress={(e) => {
                                if (
                                  e.key === "Enter" &&
                                  !resultStats1?.isLoading
                                ) {
                                  e.preventDefault();
                                  postSubmit(idx);
                                }
                              }}
                            />
                            <div
                              disabled={resultStats1?.isLoading}
                              onClick={() => postSubmit(idx)}
                            >
                              {WhatsappSend}
                            </div>
                            {/* <Icon.Send
                              style={{
                                width: "30px",
                                height: "30px",
                                color: "green",
                                marginLeft: "5px",
                                cursor: "pointer",
                              }}
                              disabled={resultStats1?.isLoading}
                              onClick={() => postSubmit(idx)}
                            /> */}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}

            {/* {openFilter && (
              <div className="edit-post">
                <div className="container mt-3">
                  <div className="row justify-content-center">
                    <div className="col-md-8">
                      <EditPost
                        editStates={editStates}
                        idx={selectVisitIndex}
                        {...selectVisit}
                        data={postData}
                        onCloseFilter={handleCloseFilter}
                        setEditStates={setEditStates}
                        openFilter={openFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>

      {/* </Tab> */}
      {/* <Tab
          eventKey="Social"
          title={
            <span className="d-flex align-items-center">Waiting Approval</span>
          }
        > */}
      {/* <div className="container-fluid" style={{ padding: "0px 16px" }}>
          

            <div className="row">
              <div className="col-12">
                {postsData?.posts?.map((post, idx) => {
                  if (post.status !== 1) {
                    return (
                      <div key={post.id} className="mt-3 px-1 pb-1">
                        <div className="card">
                          <div className="card-header d-flex justify-content-between align-items-center px-2 pt-2">
                            <div className="media">
                              <div className="media-aside mr-2 mt-2 ml-2">
                                {post.user.image ? (
                                  <img
                                    className="rounded-circle"
                                    style={{
                                      width: "5rem",
                                      height: "5rem",
                                      objectFit: "cover",
                                    }}
                                    src={
                                      post.user.image ||
                                      "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                    }
                                    alt={post.user.name}
                                  />
                                ) : (
                                  <div
                                    className="rounded-circle"
                                    style={{
                                      width: "5rem",
                                      height: "5rem",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor: getRandomColorDark(),
                                      color: "#fff",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {getInitials(post.user.name)}
                                  </div>
                                )}
                              </div>
                              <div className="media-body my-auto">
                                <span className="mb-0 table-avatar">
                                  <b>{post.user.name}</b>
                                </span>
                                <p className="card-subtitle mb-2 text-muted">
                                  {formatDateTime(post.created_at)}
                                </p>

                                <Badge
                                  bg={
                                    post.status == 3
                                      ? "badge bg-label-warning"
                                      : post.status == 2
                                      ? "badge bg-label-danger"
                                      : "badge bg-label-success"
                                  }
                                >
                                  {post.status == 3
                                    ? "Waiting Approval"
                                    : post.status == 2
                                    ? "Disapproved"
                                    : "Approved"}
                                </Badge>
                              </div>
                            </div>

                            <div>
                              <Icon.ThreeDotsVertical
                                onClick={(e) => handlePopoverOpen(e, post.id)}
                                style={{
                                  color: "#0d6efd",
                                  fontSize: "36px",
                                  backgroundColor: "rgb(178, 206, 239)",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                onMouseOver={(e) =>
                                  (e.currentTarget.style.backgroundColor =
                                    "rgb(124, 182, 249)")
                                }
                                onMouseOut={(e) =>
                                  (e.currentTarget.style.backgroundColor =
                                    "rgb(178, 206, 239)")
                                }
                              />

                              <div
                                id={`post-actions-${post.id}`}
                                tabIndex="-1"
                                aria-labelledby={`post-actions-${post.id}-label`}
                                style={{
                                  position: "absolute",

                                  right: "30px",

                                  borderRadius: "0.75rem",
                                  display:
                                    openPopover === post.id ? "block" : "none",
                                }}
                              >
                                <div className="">
                                  <ul
                                    className="list-group"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {post.owner ? (
                                      <>
                                        <li
                                          className="list-group-item"
                                          onClick={
                                            communityPermissions[0]?.update
                                              ? () =>
                                                  handleOpenFilter(post, idx)
                                              : null
                                          }
                                          onMouseOver={(e) =>
                                            (e.currentTarget.style.backgroundColor =
                                              "rgb(91, 153, 240,0.2)")
                                          }
                                          onMouseOut={(e) =>
                                            (e.currentTarget.style.backgroundColor =
                                              "")
                                          }
                                        >
                                          <Icon.Pencil />
                                          {communityPermissions[0]?.update ? (
                                            "Edit"
                                          ) : (
                                            <span>
                                              Edit{" "}
                                              <Icon.LockFill
                                                style={{ marginBottom: "5px" }}
                                              />
                                            </span>
                                          )}
                                        </li>
                                        <li
                                          className="list-group-item text-danger"
                                          onClick={
                                            communityPermissions[0]?.delete
                                              ? () => handleDeletePost(post)
                                              : null
                                          }
                                          onMouseOver={(e) =>
                                            (e.currentTarget.style.backgroundColor =
                                              "rgb(91, 153, 240,0.2)")
                                          }
                                          onMouseOut={(e) =>
                                            (e.currentTarget.style.backgroundColor =
                                              "")
                                          }
                                        >
                                          <Icon.Trash />
                                          {communityPermissions[0]?.delete ? (
                                            "Delete"
                                          ) : (
                                            <span>
                                              Delete{" "}
                                              <Icon.LockFill
                                                style={{ marginBottom: "5px" }}
                                              />
                                            </span>
                                          )}
                                        </li>
                                      </>
                                    ) : (
                                      <>
                                        {post.hidden ? (
                                          <li
                                            className="list-group-item"
                                            onClick={
                                              communityPermissions[0]?.update
                                                ? () => unHidePost(post)
                                                : null
                                            }
                                            style={{ cursor: "pointer" }}
                                            onMouseOver={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                "rgb(91, 153, 240,0.2)")
                                            }
                                            onMouseOut={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                "")
                                            }
                                          >
                                            {communityPermissions[0]?.update ? (
                                              "Un-Hide"
                                            ) : (
                                              <span>
                                                Un-Hide{" "}
                                                <Icon.LockFill
                                                  style={{
                                                    marginBottom: "5px",
                                                  }}
                                                />
                                              </span>
                                            )}
                                          </li>
                                        ) : (
                                          <li
                                            className="list-group-item"
                                            onClick={
                                              communityPermissions[0]?.update
                                                ? () => hidepost(post)
                                                : null
                                            }
                                            style={{ cursor: "pointer" }}
                                            onMouseOver={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                "rgb(91, 153, 240,0.2)")
                                            }
                                            onMouseOut={(e) =>
                                              (e.currentTarget.style.backgroundColor =
                                                "")
                                            }
                                          >
                                            {communityPermissions[0]?.update ? (
                                              "Hide"
                                            ) : (
                                              <span>
                                                Hide{" "}
                                                <Icon.LockFill
                                                  style={{
                                                    marginBottom: "5px",
                                                  }}
                                                />
                                              </span>
                                            )}
                                          </li>
                                        )}

                                        <li
                                          className="list-group-item text-danger"
                                          onClick={
                                            communityPermissions[0]?.delete
                                              ? () => handleDeletePost(post)
                                              : null
                                          }
                                          style={{ cursor: "pointer" }}
                                          onMouseOver={(e) =>
                                            (e.currentTarget.style.backgroundColor =
                                              "rgb(91, 153, 240,0.2)")
                                          }
                                          onMouseOut={(e) =>
                                            (e.currentTarget.style.backgroundColor =
                                              "")
                                          }
                                        >
                                          {communityPermissions[0]?.delete ? (
                                            "Delete"
                                          ) : (
                                            <span>
                                              Delete{" "}
                                              <Icon.LockFill
                                                style={{ marginBottom: "5px" }}
                                              />
                                            </span>
                                          )}{" "}
                                        </li>
                                      </>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body">
                            <p className="card-text">{post.content}</p>

                            {post.image && (
                              <div
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  paddingBottom: "56.25%",
                                }}
                              >
                                <img
                                  src={post.image}
                                  alt=""
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "12px",
                                  }}
                                />
                              </div>
                            )}

                            <div className="d-flex align-items-center justify-content-between mt-3">
                              <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                  {post?.likesOwner?.includes(adminData?.id) ? (
                                    <Icon.HeartFill
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        color: "green",
                                        cursor: "pointer",
                                      }}
                                      onClick={
                                      
                                        () => {
                                        
                                          post?.likesOwner?.includes(
                                            adminData?.id
                                          )
                                            ? 
                                              handleRemoveLike(post?.id)
                                            : 
                                              handleLikeClick(post?.id);
                                         
                                        }
                                       
                                      }
                                    />
                                  ) : (
                                    <Icon.Heart
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        color: "green",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        const likedByUser =
                                          post?.likesOwner?.length > 0;

                                        if (
                                          post?.likesOwner?.includes(
                                            adminData?.id
                                          )
                                        ) {
                                          handleRemoveLike(post?.id);
                                        } else {
                                          handleLikeClick(post?.id);
                                        }
                                      }}
                                    />
                                  )}
                                 
                                  <p className="ms-1 mt-3">
                                    {post.likes.length >= 1 &&
                                      post?.likes?.length}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center ms-2">
                                  {post?.likes?.map((avatar, index) => (
                                   
                                    <>
                                      {avatar.image ? (
                                        <img
                                          style={{
                                           
                                            borderRadius: "50%",
                                            width: "32px",
                                            height: "32px",
                                            objectFit: "cover",
                                          }}
                                          src={
                                            avatar.image ||
                                            "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                          }
                                          alt={avatar.name}
                                        />
                                      ) : (
                                        <div
                                          className="rounded-circle"
                                          style={{
                                            width: "32px",
                                            height: "32px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor:
                                              getRandomColorDark(),
                                            color: "#fff",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {getInitials(avatar.name)}
                                        </div>
                                      )}
                                    </>
                                  ))}
                                  {post.likes.length > 3 && (
                                    <div
                                      className="avatar"
                                      style={{
                                        fontSize: "17px",
                                        color: "rgb(0, 120, 103)",
                                      }}
                                    >
                                      +{post.likes.length - 3}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="mt-2">
                              <ul className="list-group">
                                <CommentList
                                  comments={organizeComments(post?.comments)}
                                  selectedComment={selectedComment}
                           
                                  data={adminData}
                                  handleDeleteComment={handleDeleteComment}
                                  handleEditComment={handleEditComment}
                                  postUpdateComment={postUpdateComment}
                                  replyComment={replyComment}
                                  postReplyComment={postReplyComment}
                                  selectedCommentReply={selectedCommentReply}
                                  setSelectedCommentEdit={
                                    setSelectedCommentEdit
                                  }
                                  selectedCommentEdit={selectedCommentEdit}
                                  setSelectedCommentReply={
                                    setSelectedCommentReply
                                  }
                                  handlePopoverCommentOpen={
                                    handlePopoverCommentOpen
                                  }
                                  anchorEl1={anchorEl1}
                                
                                  openComment={openComment}
                                  handlePopoverCommentClose={
                                    handlePopoverCommentClose
                                  }
                                  idx={idx}
                                  resultStats4={resultStats4}
                                />
                              </ul>
                            </div>

                           
                          </div>

                          <div className="d-flex mt-2 mb-4 justify-content-center">
                            <button
                              className="btn btn-primary mr-2"
                              onClick={() => handleApprove(post)}
                            >
                              Approve
                            </button>
                            <button
                              className="btn-danger rounded ml-1"
                              onClick={() => handleDisapprove(post)}
                            >
                              Disapprove
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
                {postsData?.posts?.some(
                  (post) => post.status !== 2 || post.status !== 2
                ) && (
                  <h3 className="mt-4 ml-4">
                    No Posts are available for approval
                  </h3>
                )}

                {openFilter && (
                  <div className="edit-post">
                    <div className="container mt-3">
                      <div className="row justify-content-center">
                        <div className="col-md-8">
                          <EditPost
                            editStates={editStates}
                            idx={selectVisitIndex}
                            {...selectVisit}
                            data={postData}
                            onCloseFilter={handleCloseFilter}
                            setEditStates={setEditStates}
                            openFilter={openFilter}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div> */}
      {/* </Tab>
      </Tabs> */}
    </>
  );
}
