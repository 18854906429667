import React from "react";
import BookingList from "./BookingList";
import BookingTableView from "./BookingTableView";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useGetAllUpComingBookingQuery,
  useGetCompaniesQuery,
  useGetIndividualsQuery,
  useGetMeetingRoomQuery,
} from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";

function Bookings() {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: FetchCompanies, isLoading: loading } = useGetCompaniesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: FetchUsers, isLoading: userLoading } = useGetIndividualsQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: FetchMeetingData, isLoading } = useGetMeetingRoomQuery({
    id: spaceId || currentSpaceId,
    room_type: 1,
  });

  const { isLoading: bookingLoading, data: FetchUpComingBooking } =
    useGetAllUpComingBookingQuery({
      spaceId: spaceId || currentSpaceId,
      past: "False",
    });
  if (bookingLoading || loading || isLoading || userLoading) {
    return <Loader height={6.6} />;
  }

  return (
    <div>
      <Tabs
        defaultActiveKey="Account"
        id="justify-tab-example"
        className="mb-3 mt-3 ml-3"
      >
        <Tab
          style={{ marginRight: "5px" }}
          eventKey="Account"
          title={<span className="d-flex align-items-center">Calender</span>}
        >
          <BookingList />
        </Tab>
        <Tab
          eventKey="Social"
          title={<span className="d-flex align-items-center">Table</span>}
        >
          <BookingTableView
            companies={FetchCompanies?.users}
            meetingData={FetchMeetingData}
            FetchUpComingBooking={FetchUpComingBooking?.bookingss}
            Users={FetchUsers?.users}
          />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Bookings;
