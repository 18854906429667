import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  useBookingSlotsMutation,
  useDeleteMeetingBookingMutation,
  useGetMeetingRoomQuery,
  useUpdateMeetingBookingMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import Select from "react-select";
// import { convertUTCTimestampToLocal } from "../../utils/convertUTCTimestampToLocal";
// import { convertLocalTimestampToUTC } from "../../utils/convertLocalTimestampToUTC";

function DashboardBookingModal({
  meetingRoom: { title: meetingRoomTitle },
  meetingRoom: { price },
  capacity: bookingCapacity,
  startTime,
  endTime,
  member: { name: member },
  id,
  meetingRoom: { id: MRId },
  member: { id: memberId },
  publicUser,
  showModal,
  setShowModal,
}) {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: meetingData, isLoading } = useGetMeetingRoomQuery({
    id: spaceId || currentSpaceId,
    room_type: 1,
  });
  function secondsToFormattedDate(epochTimestampInSeconds) {
    const date = new Date(epochTimestampInSeconds * 1000); // Convert seconds to milliseconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  // useEffect(() => {
  //   startTime = convertUTCTimestampToLocal(startTime);
  //   endTime = convertUTCTimestampToLocal(endTime);
  // }, [startTime, endTime]);
  const [capacity, setCapacity] = useState(bookingCapacity);
  const [deleteMeetingBooking, resultStats] = useDeleteMeetingBookingMutation();
  const [updateBooking, resultStats2] = useUpdateMeetingBookingMutation();
  const [bookingSlots, resultStats1] = useBookingSlotsMutation();
  const [meetingSlots, setMeetingSlots] = useState([]);
  const [finalSlots, setFinalSlots] = useState([]);
  const [apiSpinner, setApiSpinner] = useState(false);

  // const [showModal, setShowModal] = useState(false);
  const [updateBookingButton, setUpdateBookingButton] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [startStamp, setStartStamp] = useState(startTime);
  const [endStamp, setEndStamp] = useState(endTime);
  const [preSlots, setPreSlots] = useState([]);
  const [meetingRoomId, setMeetingRoomId] = useState(MRId);

  const handleMeetingRoomId = (e) => {
    setMeetingRoomId(e.id);
    getBookingSlots(date, e.id);
  };

  const [date, setDate] = useState(secondsToFormattedDate(startTime));
  const [dateCheck, setDateCheck] = useState(secondsToFormattedDate(startTime));

  const handle = (e) => {
    const { name, value } = e.target;

    setCapacity(value);
  };

  useEffect(() => {
    setDate(secondsToFormattedDate(startTime));
    const startDate = new Date(startTime * 1000); // Convert to milliseconds
    const endDate = new Date(endTime * 1000); // Convert to milliseconds

    const timeSlots = [];
    const incrementMinutes = 30;

    while (startDate < endDate) {
      const currentStartTime = startDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      startDate.setMinutes(startDate.getMinutes() + incrementMinutes); // Increment by 30 minutes

      const currentEndTime = startDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      timeSlots.push({ start: currentStartTime, end: currentEndTime });
    }

    setPreSlots(timeSlots);
  }, [showModal]);

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }

  const [preSlotsIndexes, setPreSlotsIndexes] = useState([]);
  const [selectedStart, setSelectedStart] = useState(preSlotsIndexes[0]);
  const [selectedEnd, setSelectedEnd] = useState(
    preSlotsIndexes[preSlotsIndexes?.length - 1]
  );

  const handleSlotClick = (index) => {
    if (selectedStart === null) {
      setSelectedStart(index);
      setSelectedEnd(index);
    } else {
      const previousSlot = finalSlots[selectedEnd];
      const currentSlot = finalSlots[index];

      if (
        selectedEnd === index - 1 &&
        previousSlot?.end === currentSlot?.start
      ) {
        setSelectedEnd(index);
      } else {
        setSelectedStart(index);
        setSelectedEnd(index);
      }
    }
  };

  const formatTime = (timeString) => {
    const date = new Date(`2023-10-29T${timeString}`);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${amOrPm}`;
  };
  function handleDate(e) {
    const name = e.target.name;
    const value = e.target.value;

    setDate(value);
    getBookingSlots(value, meetingRoomId);
  }

  async function getBookingSlots(selectedDate, meetingRoomId) {
    const slots = await bookingSlots({
      spaceId: spaceId || currentSpaceId,
      data: {
        meetingRoom: meetingRoomId,
        date: selectedDate,
      },
    });
    setMeetingSlots(slots?.data || []);
  }

  useEffect(() => {
    if (meetingSlots?.length < 1) {
      setFinalSlots([...preSlots]);
      setSelectedStart(0);
      setSelectedEnd(preSlots?.length - 1);

      return;
    }
    const newTimeSlots = meetingSlots?.map((slot) => ({
      start: new Date(date + "T" + slot.start)?.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
      end: new Date(date + "T" + slot?.end)?.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
    }));

    let insertionIndex = 0;

    // Find a match for the end of the last preSlot with the start of a meeting slot
    const matchIndex = newTimeSlots.findIndex(
      (slot) => slot.start === preSlots[preSlots.length - 1]?.end
    );

    if (matchIndex !== -1) {
      // Case 1: Match found, place preSlots before that meeting slot
      insertionIndex = matchIndex;
    } else {
      // Case 2: No match found in step 1, try to match the start of the first preSlot with the end of a meeting slot
      const firstPreSlotMatchIndex = newTimeSlots.findIndex(
        (slot) => slot?.end === preSlots[0]?.start
      );

      if (firstPreSlotMatchIndex !== -1) {
        insertionIndex = firstPreSlotMatchIndex + 1;
      } else {
        // Case 3: Both steps failed, place preSlots at the start of finalSlots
        insertionIndex = 0;
      }
    }

    // Set preSlotIndexes to track the indexes occupied by preSlots in finalSlots
    const preSlotIndexes = Array.from(
      { length: preSlots.length },
      (_, index) => insertionIndex + index
    );

    const finalSlotsWithIndexes = [
      ...newTimeSlots?.slice(0, insertionIndex),
      ...preSlots?.map((slot, index) => ({ ...slot, preSlotIndex: index })),
      ...newTimeSlots?.slice(insertionIndex),
    ];

    if (date == dateCheck) {
      setFinalSlots(finalSlotsWithIndexes);
    } else {
      setFinalSlots([...newTimeSlots]);
    }

    setPreSlotsIndexes(preSlotIndexes);

    // Set selectedStart and selectedEnd after preSlotsIndexes are populated
    setSelectedStart(preSlotIndexes[0]);
    setSelectedEnd(preSlotIndexes[preSlotIndexes.length - 1]);
  }, [meetingSlots]);

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    if (preSlots?.length > 0 && showModal == true) {
      // setDate(secondsToFormattedDate(startTime));
      getBookingSlots(date, meetingRoomId);
    }
  }, [preSlots]);

  const postBooking = () => {
    const selectedDate = date;
    const initialTime = finalSlots[selectedStart]?.start;
    const lastTime = finalSlots[selectedEnd]?.end;

    const [year, month, day] = selectedDate.split("-");

    const initialTimeParts = initialTime.match(/(\d+):(\d+) (AM|PM)/);
    let startHours = parseInt(initialTimeParts[1]);
    const startMinutes = parseInt(initialTimeParts[2]);
    const startAMPM = initialTimeParts[3];

    // if (startAMPM === "PM" && startHours !== 12) {
    //   startHours += 12;
    // }
    if (startAMPM === "PM" && startHours !== 12) {
      startHours += 12;
    } else if (startAMPM === "AM" && startHours === 12) {
      startHours = 0;
    }

    const lastTimeParts = lastTime.match(/(\d+):(\d+) (AM|PM)/);
    let endHours = parseInt(lastTimeParts[1]);
    const endMinutes = parseInt(lastTimeParts[2]);
    const endAMPM = lastTimeParts[3];

    // if (endAMPM === "PM" && endHours !== 12) {
    //   endHours += 12;
    // }
    if (endAMPM === "PM" && endHours !== 12) {
      endHours += 12;
    } else if (endAMPM === "AM" && endHours === 12) {
      endHours = 0;
    }
    const startDateTime = new Date(
      year,
      month - 1,
      day,
      startHours,
      startMinutes
    );
    const endDateTime = new Date(year, month - 1, day, endHours, endMinutes);

    const startTimestamp = startDateTime.getTime();
    const endTimestamp = endDateTime.getTime();

    const startIsPM = startDateTime.getHours() >= 12;
    const endIsAM = endDateTime.getHours() < 12;

    let adjustedEndTimestamp = endTimestamp;

    if (startIsPM && endIsAM) {
      const endDate = new Date(endTimestamp);
      endDate.setDate(endDate.getDate() + 1);
      adjustedEndTimestamp = endDate.getTime();
    }

    const startEpochTimestamp = Math.floor(startTimestamp / 1000);
    const endEpochTimestamp = Math.floor(adjustedEndTimestamp / 1000);

    // const startEpochTimestamp = Math.floor(startTimestamp / 1000);
    // const endEpochTimestamp = Math.floor(endTimestamp / 1000);

    // const id = id;
    const updateData = {
      startTime: startEpochTimestamp,

      endTime: endEpochTimestamp,

      capacity: capacity,
      meetingRoom: meetingRoomId,
      member: memberId,
    };

    setApiSpinner(true);

    updateBooking({ spaceId: spaceId || currentSpaceId, id, updateData });
  };

  const handleDeleteBooking = (id) => {
    confirmationAlert("Want to Cancel This Booking?").then((willDelete) => {
      if (willDelete) {
        setApiSpinner(true);
        deleteMeetingBooking({
          spaceId: spaceId || currentSpaceId,
          id: id,
        });
      }
    });
  };
  function timeStampToTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 || 12;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  useEffect(() => {
    if (resultStats?.isSuccess) {
      setApiSpinner(false);
      userDeleteAlert("Booking Cancelled successfully.");
    }
    if (resultStats?.isError) {
      setApiSpinner(false);
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);
  useEffect(() => {
    if (resultStats2?.isSuccess) {
      setApiSpinner(false);
      userDeleteAlert("Booking updated successfully.");
      setUpdateBookingButton(false);
      setShowModal(false);
      setPreSlotsIndexes(false);
      setSelectedStart(false);
      setSelectedEnd(false);
    }
    if (resultStats2?.isError) {
      setApiSpinner(false);
      requiredAlert(resultStats2?.error?.data?.message);
    }
  }, [resultStats2]);

  return (
    <>
      {/* <div className="media-body my-auto">
        <span
          className="mb-0 table-avatar"
          onClick={() => setShowModal(true)}
          style={{ cursor: "pointer" }}
        >
          {meetingRoomTitle}
        </span>
      </div> */}
      {!updateBookingButton && (
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
            // setPreSlotsIndexes([]);
            // setSelectedStart(null);
            // setSelectedEnd(null);
            setPreSlots([]);
            setFinalSlots([]);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{member || "Booking Details"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {meetingRoomTitle && (
              <>
                <h5>
                  Meeting Room:
                  <small> {meetingRoomTitle}</small>
                </h5>
                <h5>
                  Time Range:&nbsp;
                  <small>
                    {timeStampToTime(startTime)}-{timeStampToTime(endTime)}
                  </small>
                </h5>
                <h5>
                  Price:
                  <small> {price}</small>
                </h5>
                <h5>
                  Capacity:
                  <small> {bookingCapacity}</small>
                </h5>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              //   onClick={
              //     bookingsPermissions[0]?.update ? handleEditBooking : null
              //   }
              onClick={() => {
                setUpdateBookingButton(true);
              }}
              disabled={apiSpinner}
            >
              {/* {bookingsPermissions[0]?.update ? (
                "Edit"
              ) : (
                <span>
                  Edit
                  <Lock />
                </span>
              )} */}
              Edit
            </Button>
            <Button
              variant="primary"
              //   onClick={
              //     bookingsPermissions[0]?.delete
              //       ? () => handleDeleteBooking(selectedBooking)
              //       : null
              //   }
              disabled={apiSpinner}
              onClick={() => handleDeleteBooking(id)}
            >
              {/* {bookingsPermissions[0]?.delete ? (
                "Delete"
              ) : (
                <span>
                  Delete <Lock />
                </span>
              )} */}
              Cancel
              {apiSpinner ? (
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "5px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {updateBookingButton && (
        <Modal
          show={updateBookingButton}
          onHide={() => {
            setUpdateBookingButton(false);

            setPreSlots([]);
            setFinalSlots([]);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Booking</Modal.Title>
          </Modal.Header>
          <form>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="capacity">Capacity</label>
                <input
                  type="number"
                  name="capacity"
                  id="capacity"
                  value={capacity}
                  onChange={handle}
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label htmlFor="meetingId" className="mb-1">
                  Meeting Room
                </label>
                <Select
                  options={meetingData?.meetingRooms}
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option.id}
                  // styles={colorStyles}
                  value={meetingData?.meetingRooms?.find(
                    (obj) => obj.id == meetingRoomId
                  )}
                  onChange={handleMeetingRoomId}
                  id="meetingId"
                />
              </div>
              <div className="form-group">
                <label htmlFor="startTime">Select Date:</label>
                <input
                  type="date"
                  name="startTime"
                  id="startTime"
                  className="form-control"
                  placeholder=""
                  value={date}
                  min={getCurrentDate()}
                  onChange={handleDate}
                />
              </div>
              <div
                className="meeting-slots-container h-25"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {finalSlots?.length > 0
                  ? finalSlots?.map((slot, index) => {
                      return (
                        <div
                          key={index}
                          className={`meeting-slot ${
                            index >= selectedStart && index <= selectedEnd
                              ? "selected"
                              : ""
                          }`}
                          style={{
                            border: "1px solid #358EF1",
                            cursor: "pointer",
                            margin: "5px",
                            padding: "5px",
                            borderRadius: "25px",
                            justifyContent: "center",
                            alignItems: "center",
                            maxHeight: "40px",

                            backgroundColor:
                              index >= selectedStart && index <= selectedEnd
                                ? "#007bff"
                                : "transparent",
                            color:
                              index >= selectedStart && index <= selectedEnd
                                ? "#fff"
                                : "initial",
                          }}
                          onClick={() => handleSlotClick(index)}
                        >
                          <small>
                            {" "}
                            {slot.start}-{slot.end}
                          </small>
                        </div>
                      );
                    })
                  : "No Slots Available For This Date"}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => postBooking(selectedBooking)}
                disabled={apiSpinner}
              >
                Save Changes
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <Toaster position="top-right" />
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </>
  );
}

export default DashboardBookingModal;
