import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../Loader/Loader";
import {
  useAllSpacesQuery,
  useOwnerLocationQuery,
  useBookingResourceUsageReportQuery,
  useSpaceGetQuery,
  useBookingUserTypeReportQuery,
} from "../../../Slices/spaceApi";
import TabularReport from "../TabularReports";
import { useSelector } from "react-redux";
const UserType = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const tableRef = useRef(null);
  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId });
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [page_size, setPage_size] = useState(10);
  const [location, setLocation] = useState(mySpace?.spaceId || null);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const { data, isLoading, refetch, isFetching } =
    useBookingUserTypeReportQuery({
      spaceId: spaceId || currentSpaceId,
      start,
      end,
      page_size,
      location: location,

      page: page,
    });
  useEffect(() => {
    if ((data, !isLoading)) {
      setList(data);
    }
  }, [data]);
  useEffect(() => {}, [data, isLoading, list]);

  // useEffect(() => {
  //   refetch()
  // }, [selectedValue])
  const body = isFetching ? (
    <Loader />
  ) : (
    <div className=" table-responsive">
      {" "}
      <table ref={tableRef} className="table  dataTable">
        <thead>
          <tr>
            <th>#</th>
            <th>Month-Year</th>
            <th>Member Bookings</th>
            <th>Non-Member Bookings</th>
            <th>% Member Bookings</th>
            <th>% Non-Member Bookings</th>
          </tr>
        </thead>
        <tbody className="table-border-bottom-0">
          {!isLoading && data && list?.length > 0 ? (
            list?.map((room) =>
              room?.months?.map((month, index) => (
                <tr key={` {room.meeting_room}-${index}`}>
                  <td>{room.meeting_room}</td>

                  <td>{month.month_year}</td>
                  <td>{month.member_bookings}</td>
                  <td>{month.non_member_bookings}</td>
                  <td>{month["%age_member_bookings"]}%</td>
                  <td>{month["%age_non_member_bookings"]}%</td>
                </tr>
              ))
            )
          ) : list.length === 0 ? (
            <tr>
              <td>No Data</td>
            </tr>
          ) : (
            <tr>
              <td>Data is Loading</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
  return (
    <>
      {data && !isLoading && list ? (
        <TabularReport
          key={"bookingByWeekDays"}
          Child={() => body}
          data={data}
          isLoading={isLoading}
          mySpace={mySpace}
          allSpaces={allSpaces}
          tableRef={tableRef}
          list={list}
          setList={setList}
          ownerLocation={ownerLocation}
          page={page}
          setPage={setPage}
          page_size={page_size}
          setPage_size={setPage_size}
          location={location}
          setLocation={setLocation}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          refetch={refetch}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          options={options}
          setOptions={setOptions}
        />
      ) : (
        <Loader></Loader>
      )}
    </>
  );
};

export default UserType;
