import React from "react";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";
import { Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

const ViewAnnouncement = ({
  id,
  attachments,
  content,
  createdAt,
  creator,
  subject,
  to,
}) => {
  const { membersPermissions } = useGlobalContext();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate},${formattedTime}`;
  }

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {membersPermissions[0]?.read ? (
              "View User"
            ) : (
              <span>
                View <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          id="viewMeetingOpen"
          onClick={membersPermissions[0]?.read ? handleShow : null}
        >
          <Preview />
        </Button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Announcement View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-0">
            <label className="fw-bold">
              Created At: {createdAt ? formatDateTime(createdAt) : createdAt}
            </label>
          </div>
          <div className="mb-0">
            <label className="fw-bold">Created By: {creator?.name}</label>
          </div>

          <div className="mb-3">
            <h3>To:</h3>{" "}
            <div className="d-flex  flex-wrap">
              {to?.map((item) => {
                return (
                  <h6 className="fw-bold bg-light ml-2 rounded p-1">
                    {item.name || item}
                  </h6>
                );
              })}
            </div>
          </div>

          <div className="mb-3">
            <h3>Subject</h3>
            <h4 className="ml-3 ">{subject}</h4>
          </div>

          <div className="mb-3">
            <h3>Message</h3>

            <div>
              <h4
                className="ml-3"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>

          {attachments && attachments?.length > 0 && (
            <div>
              <h3>Attachments</h3>
              <ul>
                {attachments?.map((attachment, index) => (
                  <li key={index}>
                    <a href={attachment} download={`Attachment_${index + 1}`}>
                      {decodeURIComponent(
                        attachment?.split("/")?.pop()?.split("?")[0]
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewAnnouncement;
