import React, { useEffect, useState } from 'react'
import { ReactComponent as EditIcon } from '../../Assets/EditIcon.svg'
import {
  Button,
  Form,
  OverlayTrigger,
  Pagination,
  Row,
  Tooltip
} from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom'
import * as Icon from 'react-bootstrap-icons'
import { useProductListQuery } from '../../Slices/spaceApi'

import Loader from '../Loader/Loader'

import ProductUpdate from './ProductUpdate'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'

const ProductList = ({ account, taxRate }) => {
  //.log({ account, taxRate })
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)

  const [searchValue, setSearchValue] = useState('')
  const [sidePage, setSidePage] = useState(true)
  const [sideName, setName] = useState('')
  const [list, setList] = useState([])
  const [operateId, setOperateId] = useState(null)
  const [operateObject, setOperateObject] = useState(null)
  const { data, isLoading, isError, error, isFetching } = useProductListQuery({
    spaceId: spaceId || currentSpaceId,

    page_size: pageSize,
    page: pageNumber,
    name: searchValue
  })
  useEffect(() => {
    if (account?.data && taxRate?.data) {
      //.log({ account: account.data, taxRate: taxRate.data })
    }
  }, [account, taxRate])
  const handleClose = () => {
    setSidePage(state => !state)
  }
  const handleSearchInputChange = e => {
    setSearchValue(e.target.value)
  }

  const handlePageSize = e => {
    setPageSize(e.target.value)
    setPageNumber(1)
  }

  useEffect(() => {
    if (data) {
      setList(data?.products)
      //.log(data)
    }
  }, [data, isLoading, isError, error])

  const sort = (sortIn, sortTo) => {
    if (sortTo === 'id') {
      if (sortIn === 'asc') {
        setList(state => [...state].sort((a, b) => a.id - b.id))
      } else if (sortIn === 'dsc') {
        setList(state => [...state].sort((b, a) => a.id - b.id))
      }
    } else if (sortTo === 'space') {
      if (sortIn === 'asc') {
        setList(state => [...state].sort((a, b) => a.space - b.space))
      } else if (sortIn === 'dsc') {
        setList(state => [...state].sort((b, a) => a.space - b.space))
      }
    } else if (sortTo === 'name') {
      if (sortIn === 'asc') {
        setList(state =>
          [...state].sort((a, b) => a.name.localeCompare(b.name))
        )
      } else if (sortIn === 'dsc') {
        setList(state =>
          [...state].sort((b, a) => a.name.localeCompare(b.name))
        )
      }
    } else if (sortTo === 'rental') {
      if (sortIn === 'asc') {
        setList(state => [...state].sort((a, b) => a.rentalFee - b.rentalFee))
      } else if (sortIn === 'dsc') {
        setList(state => [...state].sort((b, a) => a.rentalFee - b.rentalFee))
      }
    } else if (sortTo === 'value') {
      if (sortIn === 'asc') {
        setList(state =>
          [...state].sort((a, b) => a.quantity * a.value - b.quantity * b.value)
        )
      } else if (sortIn === 'dsc') {
        setList(state =>
          [...state].sort((b, a) => a.quantity * a.value - b.quantity * b.value)
        )
      }
    } else if (sortTo === 'notes') {
      if (sortIn === 'asc') {
        setList(state =>
          [...state].sort((a, b) => a.notes.localeCompare(b.notes))
        )
      } else if (sortIn === 'dsc') {
        setList(state =>
          [...state].sort((b, a) => a.notes.localeCompare(b.notes))
        )
      }
    }
  }
  const handlePageChange = e => {
    //.log('e', e)
    setPageNumber(e)
  }
  const totalPages = data ? Math.ceil(data?.totalCount / pageSize) : 0
  const startIndex = (pageNumber - 1) * pageSize + 1
  const endIndex =
    pageNumber * pageSize > data?.totalCount
      ? data?.totalCount
      : pageNumber * pageSize

  // Generating dynamic pagination items
  let paginationItems = []
  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === pageNumber}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    )
  }
  return (
    <>
      <div className='container-fluid card  m-0 mt-3 p-0 w-100 col-12'>
        <div className='col-12'>
          <div className='row mt-4 '>
            {/* <div className='d-flex justify-content-between '> */}{' '}
            <div className='col-12  col-md-6'>
              <Form.Control
                type='text'
                placeholder='Search...'
                className='form-control '
                value={searchValue}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>

          {/* </div> */}
        </div>
        <hr></hr>
        <div className='col-12'>
          <div>
            <div className='col-12 d-flex justify-content-end'>
              <label
                style={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal'
                }}
              >
                <small className='mr-2 mt-2 title'> Show</small>
                <Form.Select
                  aria-label='Change Page size'
                  onChange={handlePageSize}
                  value={pageSize}
                  className=''
                >
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value='30'>30</option>
                  <option value='50'>50</option>
                </Form.Select>
                <small className='ml-2 mt-2'> entries</small>
              </label>
            </div>
          </div>
        </div>
        <div className='table-responsive'>
          {!isFetching ? (
            <table className='table mt-4'>
              <thead>
                <tr>
                  <th>
                    <div
                      className='d-flex align-items-center sort heading'
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div>#</div>
                      <div
                        className=' d-flex'
                        style={{ flexDirection: 'column' }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort('asc', 'id')}
                          style={{ fontSize: '10px' }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort('dsc', 'id')}
                          style={{ fontSize: '10px' }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className='d-flex align-items-center sort heading'
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div>Product </div>
                      <div
                        className=' d-flex'
                        style={{ flexDirection: 'column' }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort('asc', 'name')}
                          style={{ fontSize: '10px' }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort('dsc', 'name')}
                          style={{ fontSize: '10px' }}
                        />
                      </div>
                    </div>
                  </th>

                  <th>
                    <div
                      className='d-flex align-items-center sort heading'
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div>Account</div>
                      <div
                        className=' d-flex'
                        style={{ flexDirection: 'column' }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort('asc', 'value')}
                          style={{ fontSize: '10px' }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort('dsc', 'value')}
                          style={{ fontSize: '10px' }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className='d-flex align-items-center sort heading'
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div>Tax Rate</div>
                      <div
                        className=' d-flex'
                        style={{ flexDirection: 'column' }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort('asc', 'notes')}
                          style={{ fontSize: '10px' }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort('dsc', 'notes')}
                          style={{ fontSize: '10px' }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className='d-flex align-items-center sort heading'
                      style={{ justifyContent: 'space-between' }}
                    >
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <>
                    <Loader />
                  </>
                ) : isError ? (
                  <>
                    <tr>
                      <td>{error}</td>
                    </tr>
                  </>
                ) : (
                  <>
                    {list?.length === 0 ? (
                      <>
                        <tr>
                          <td>No Data</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {list?.map((data, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td className='serial bold'>#{data?.id}</td>
                              <td className='table-avatar bold'>
                                <div className='d-flex flex-column'>
                                  {' '}
                                  <h6 className=' table-avatar bold  text-nowrap mb-0'>
                                    {data?.name}
                                  </h6>
                                </div>
                              </td>

                              <td className='table-avatar'>
                                {data?.account && account?.data
                                  ? (
                                      account?.data?.accounts?.find(
                                        item => item.id === data?.account
                                      ) || {}
                                    )?.name || '-'
                                  : '-'}
                              </td>
                              <td className='table-avatar '>
                                {' '}
                                {data?.taxRate && taxRate?.data
                                  ? (
                                      taxRate?.data?.taxRates?.find(
                                        item => item.id === data?.taxRate
                                      ) || {}
                                    )?.name || '-'
                                  : '-'}
                              </td>
                              <td className='d-flex flex-nowrap'>
                                <div>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      <Tooltip id='tooltip'>
                                        Edit Product
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant='white'
                                      className='p-0 mx-2'
                                      onClick={() => {
                                        setName('update')
                                        setOperateId(data?.id)
                                        setOperateObject(data)
                                        setSidePage(true)
                                      }}
                                    >
                                      <EditIcon />
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          ) : (
            <Loader />
          )}
        </div>

        <div className='m-0 border-bottom'></div>
        <div className=' pagination-custom flex-column flex-lg-row'>
          <p className='text-muted mb-0'>
            {' '}
            Showing {data?.products?.length} of {data?.totalCount} entries
          </p>
          {data?.totalCount > pageSize && (
            <Pagination className='mt-lg-0 mt-2'>
              <Pagination.Prev
                className='previous'
                onClick={() => handlePageChange(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
                Prev.
              </Pagination.Prev>
              {paginationItems}
              <Pagination.Next
                className='next'
                onClick={() => handlePageChange(pageNumber + 1)}
                disabled={pageNumber === totalPages}
              >
                Next
              </Pagination.Next>
            </Pagination>
          )}
        </div>
      </div>
      {sidePage && sideName === 'update' && operateId && operateObject ? (
        <>
          <ProductUpdate
            handleClose={handleClose}
            operateId={operateId}
            account={account}
            taxRate={taxRate}
            ProductData={operateObject}
          ></ProductUpdate>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default ProductList
