import React from "react";
import SlotSize from "./SlotSize";
import BookingSlots from "./BookingSlots";
import BookingApproval from "./BookingApproval";
import CancelationPolicy from "./CancelationPolicy";

const BookingConfiguration = ({ spaceData, cancellationPolicies }) => {
  return (
    <div className="">
      <SlotSize spaceData={spaceData} />
      <BookingSlots spaceData={spaceData} />
      <BookingApproval spaceData={spaceData} />
      <CancelationPolicy cancellationPolicies={cancellationPolicies} type={1} />
      <CancelationPolicy cancellationPolicies={cancellationPolicies} type={2} />
    </div>
  );
};

export default BookingConfiguration;
