import React, { useContext, useEffect, useState } from 'react'
import Loader from '../../Loader/Loader'
import {
  useAllSpacesQuery,
  useBookingMonthReportQuery,
  useOwnerLocationQuery,
  useSpaceGetQuery
} from '../../../Slices/spaceApi'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BookingByMonth = () => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId
  })

  // const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
  //   useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId });
  // const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
  //   spaceId: spaceId || currentSpaceId,
  // });

  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [page, setPage] = useState(1)
  const [page_size, setPage_size] = useState(10)
  const [location, setLocation] = useState(mySpace?.spaceId || null)

  const handleLocation = event => {
    setLocation(event.target.value)
  }
  const { data, isLoading, isError, refetch } = useBookingMonthReportQuery({
    spaceId: spaceId || currentSpaceId,
    start,
    end,
    page_size,
    page
  })

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Bookings Count',
        data: [],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1
      }
    ]
  })
  // Chart data preparation
  useEffect(() => {
    if (data) {
      setChartData({
        labels: data?.bookings?.map(booking => booking?.month) || [],
        datasets: [
          {
            label: 'Bookings Count',
            data: data?.bookings?.map(booking => booking?.count) || [],
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }
        ]
      })
    }
  }, [data])

  // const options = {
  //   scales: {
  //     y: {
  //       beginAtZero: true,
  //     },
  //   },
  //   plugins: {
  //     legend: {
  //       display: true,
  //       position: "top",
  //     },
  //     title: {
  //       display: true,
  //       text: "Monthly Bookings",
  //     },
  //   },
  // };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Monthly Bookings'
      }
    }
  }

  if (isLoading) return <Loader />
  if (isError) return <p>Error loading data...</p>
  return (
    <>
      <div className='card card-datatable text-nowrap'>
        <div
          id='DataTables_Table_1_wrapper'
          className='dataTables_wrapper dt-bootstrap5 no-footer'
        >
          <div className='container-fluid'>
            {/* <div className='row'>
              <div className='col-12'> */}
            {/* {'.'} */}
            {!isLoading && <Bar data={chartData} options={options} />}
            {/* </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default BookingByMonth
