import React from "react";

import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useCreateFloorRoomsMutation,
  useDeleteFloorRoomMutation,
  useGetAllMembersQuery,
  useGetAllSpaceUsersQuery,
  useGetCompaniesQuery,
  useGetCompanyUsersQuery,
  useGetFloorPlansQuery,
  useGetFloorRoomsQuery,
  useGetIndividualsQuery,
  useGetSpaceDataQuery,
  useUpdateFloorRoomMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import EditDesk from "./EditDesk";
import AssignDesk from "./AssignDesk";
import Loader from "../Loader/Loader";

const ImageArea = ({
  floorImage,
  id,
  selectedShapeTyp,
  dragEnabled,
  zoomLevel,
  dragSelectMode,
}) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [status, setStatus] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const { data: FetchUsers, isLoading: loading } = useGetAllMembersQuery(
    spaceId || currentSpaceId
    // statusVal: status
    //   ? { company: false, status: 2 }
    //   : { company: false, status: 3 },
  );
  const { data: FetchIndividuals, isLoading: IndividualsLoading } =
    useGetIndividualsQuery({ spaceId: spaceId || currentSpaceId });

  const { data: fetchFloorPlans, isLoading } = useGetFloorPlansQuery(
    spaceId || currentSpaceId
  );
  const { data: fetchAmenities, isLoading: loadingAmenities } =
    useGetSpaceDataQuery({ spaceId: spaceId || currentSpaceId });
  const { data: FetchCompanies, isLoading: Companyloading } =
    useGetCompaniesQuery({ spaceId: spaceId || currentSpaceId });
  const { data: companyUsers, isLoading: usersLoading } =
    useGetCompanyUsersQuery(
      {
        spaceId: spaceId || currentSpaceId,
        companyId: selectedCompany,
      },
      {
        skip: selectedCompany === null,
        refetchOnMountOrArgChange: true,
        query: {
          selectedCompany: selectedCompany,
        },
      }
    );

  const [createFloorRooms, resultStats] = useCreateFloorRoomsMutation();
  const [updateFloorRooms, resultStats2] = useUpdateFloorRoomMutation();
  const [deleteFloorRoom, resultStats1] = useDeleteFloorRoomMutation();
  const [showRoomEdit, setShowRoomEdit] = useState(false);
  const [shapes, setShapes] = useState([]);
  const imageAreaRef = useRef(null);
  const [selectedShape, setSelectedShape] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [imageWidth, setImageWidth] = useState(750);
  const [imageHeight, setImageHeight] = useState(360);

  const [check, setCheck] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [cursorOffset, setCursorOffset] = useState({ x: 0, y: 0 });

  const [changedShape, setChangedShape] = useState(null);
  const [deskOpen, setDeskOpen] = useState(false);
  const [assignDeskOpen, setAssignDeskOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [activeTab, setActiveTab] = useState("Desk");
  const [isDraggingInProgress, setIsDraggingInProgress] = useState(false);
  const [isShapeDragged, setIsShapeDragged] = useState(false);

  const handleShapeTouchStart = (event, index) => {
    event.stopPropagation();
    if (deskOpen || assignDeskOpen) {
      return;
    }
    setSelectedShape(index);
    const touch = event.touches[0];
    const { left, top } = imageAreaRef.current.getBoundingClientRect();
    const x = (touch.clientX - left) / zoomLevel;
    const y = (touch.clientY - top) / zoomLevel;
    setCursorOffset({ x: x - shapes[index]?.x, y: y - shapes[index]?.y });
    setIsDragging(true);

    setIsDraggingInProgress(true);
    imageAreaRef.current.addEventListener("touchmove", disableTouchMove, {
      passive: false,
    });

    setIsShapeDragged(true);
  };

  const disableTouchMove = (event) => {
    if (isDraggingInProgress) {
      event.preventDefault();
    }
  };

  const handleShapeTouchMove = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    if (deskOpen || assignDeskOpen) {
      return;
    }
    if (selectedShape !== null) {
      const touch = event.touches[0];
      const { left, top } = imageAreaRef.current.getBoundingClientRect();
      const x = (touch.clientX - left) / zoomLevel;
      const y = (touch.clientY - top) / zoomLevel;
      setShapes((prevShapes) =>
        prevShapes?.map((shape, idx) =>
          idx === selectedShape
            ? {
                ...shape,
                x: x - cursorOffset?.x,
                y: y - cursorOffset?.y,
                isChanged: true,
              }
            : shape
        )
      );
      setChangedShape(shapes[selectedShape]);
    }
  };

  const handleShapeTouchEnd = (index) => {
    handleImageAreaClick();

    setIsDraggingInProgress(false);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const { data: roomsData, isLoading: spaceLoading } =
    useGetFloorRoomsQuery(id);

  useEffect(() => {
    if (roomsData) {
      const newShapes = roomsData.map((room) => ({
        ...room,
        type: room.room_type.id.toString(),
        x: room.x_axis,
        y: room.y_axis,
        isChanged: false,
      }));

      setShapes(newShapes);
    }
  }, [roomsData]);
  const floorRoomSubmit = (shape) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    const dateWithoutZ = formattedDate?.slice(0, -1);

    const newShapeData = {
      floor: parseInt(id),
      name:
        shape.type == 1
          ? "Dedicated Desk"
          : shape.type == 2
          ? "Hot Desk"
          : shape.type == 3
          ? "Private Office"
          : shape.type == 4
          ? "Meeting Room"
          : "",
      room_type: shape.type,
      x_axis: shape?.x,
      y_axis: shape?.y,
      availableFrom: dateWithoutZ,
    };

    createFloorRooms({
      floorId: id,
      updatedData: newShapeData,
    });
  };
  const handleUpdateRoom = (changedShape) => {
    const updatedData = {
      x_axis: changedShape?.x,
      y_axis: changedShape?.y,
    };
    updateFloorRooms({
      id: changedShape?.id,
      updateData: updatedData,
    });
  };

  const [selectedShapes, setSelectedShapes] = useState([]);
  const [selectionArea, setSelectionArea] = useState(null);

  const handleMouseDown = (event) => {
    if (!dragSelectMode) return;

    const containerRect = imageAreaRef.current.getBoundingClientRect();
    const startX = event.clientX - containerRect.left;
    const startY = event.clientY - containerRect.top;

    setSelectionArea({
      startX,
      startY,
      endX: startX,
      endY: startY,
    });
    setIsDragging(true);
  };

  const handleMouseMove = (event) => {
    if (!dragSelectMode || !selectionArea) return;

    const containerRect = imageAreaRef.current.getBoundingClientRect();
    const endX = event.clientX - containerRect.left;
    const endY = event.clientY - containerRect.top;

    setSelectionArea((prevSelection) => ({
      ...prevSelection,
      endX,
      endY,
    }));

    const selected = shapes.filter((shape) => {
      const { x, y } = shape;
      return (
        x >= Math.min(selectionArea?.startX, endX) &&
        x <= Math.max(selectionArea?.startX, endX) &&
        y >= Math.min(selectionArea?.startY, endY) &&
        y <= Math.max(selectionArea?.startY, endY)
      );
    });

    setSelectedShapes(selected);
  };

  const handleMouseUp = () => {
    if (!dragSelectMode) return;

    setSelectionArea(null);
    setIsDragging(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (imageAreaRef.current) {
        const { width, height } = imageAreaRef.current.getBoundingClientRect();
        setImageWidth(width);
        setImageHeight(height);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrop = (event) => {
    event.preventDefault();
    const shapeType = event.dataTransfer.getData("text/plain");
    if (
      shapeType === "1" ||
      shapeType === "2" ||
      shapeType === "3" ||
      shapeType === "4"
    ) {
      const { left, top } = imageAreaRef.current.getBoundingClientRect();
      const shapeSize = Math.min(imageWidth, imageHeight) * 0.1;
      const containerX = (event.clientX - left) / zoomLevel;
      const containerY = (event.clientY - top) / zoomLevel;
      const x = containerX - shapeSize / 2;
      const y = containerY - shapeSize / 2;

      const shape = { type: shapeType, x, y, isChanged: true };

      setShapes([...shapes, shape]);
      floorRoomSubmit(shape);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (deskOpen) {
      return;
    }
  };

  const handleShapeMouseDown = (event, index) => {
    event.stopPropagation();
    if (deskOpen || assignDeskOpen) {
      return;
    }
    setSelectedShape(index);
    const { left, top } = imageAreaRef.current.getBoundingClientRect();
    const x = (event.clientX - left) / zoomLevel;
    const y = (event.clientY - top) / zoomLevel;
    setCursorOffset({ x: x - shapes[index]?.x, y: y - shapes[index]?.y });
    setIsDragging(true);
  };

  const handleImageAreaClick = (event) => {
    if (deskOpen || assignDeskOpen) {
      return;
    }
    if (selectedShapeTyp !== null && selectedShape == null) {
      const { left, top } = imageAreaRef.current.getBoundingClientRect();
      const shapeSize = Math.min(imageWidth, imageHeight) * 0.1;
      const containerX = (event.clientX - left) / zoomLevel;
      const containerY = (event.clientY - top) / zoomLevel;
      const x = containerX - shapeSize / 2;
      const y = containerY - shapeSize / 2;

      const shape = { type: selectedShapeTyp, x, y, isChanged: false };
      setShapes([...shapes, shape]);
      floorRoomSubmit(shape);
    } else {
      setSelectedShape(null);
      if (changedShape?.isChanged == true) {
        handleUpdateRoom(changedShape);
      }
    }
  };

  const handleImageAreaMouseMove = (event) => {
    if (deskOpen || assignDeskOpen) {
      return;
    }
    if (selectedShape !== null) {
      const { left, top } = imageAreaRef.current.getBoundingClientRect();
      const x = (event.clientX - left) / zoomLevel;
      const y = (event.clientY - top) / zoomLevel;
      setShapes((prevShapes) =>
        prevShapes?.map((shape, index) =>
          index === selectedShape
            ? {
                ...shape,
                x: x - cursorOffset?.x,
                y: y - cursorOffset?.y,
                isChanged: true,
              }
            : shape
        )
      );
      setChangedShape(shapes[selectedShape]);
    } else if (!dragSelectMode || !selectionArea) return;

    const containerRect = imageAreaRef.current.getBoundingClientRect();
    const endX = event.clientX - containerRect.left;
    const endY = event.clientY - containerRect.top;

    setSelectionArea((prevSelection) => ({
      ...prevSelection,
      endX,
      endY,
    }));

    const selected = shapes.filter((shape) => {
      const { x, y } = shape;
      return (
        x >= Math.min(selectionArea?.startX, endX) &&
        x <= Math.max(selectionArea?.startX, endX) &&
        y >= Math.min(selectionArea?.startY, endY) &&
        y <= Math.max(selectionArea?.startY, endY)
      );
    });

    setSelectedShapes(selected);
  };

  const handleExistingShapeDelete = (event, id) => {
    event.preventDefault();
    setIsDragging(false);
    setSelectedShape(null);
    const shapeId = shapes[selectedShape]?.id;
    confirmationAlert("Want to delete this Room?").then((willDelete) => {
      if (willDelete) {
        deleteFloorRoom({ id: shapeId });
      }
    });
  };

  useEffect(() => {
    if (resultStats1?.isSuccess) {
      userDeleteAlert("The Room has been deleted");
      setShowDelete(false);
      setSelectedShape(null);
    }
    if (resultStats1?.isError) {
      requiredAlert(resultStats1?.error?.data?.message);
      setShowDelete(false);
      setSelectedShape(null);
    }
  }, [resultStats1]);
  useEffect(() => {
    if (resultStats?.isSuccess) {
      toast.success("The Room has been Added.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);
  useEffect(() => {
    if (resultStats2?.isSuccess) {
      toast.success("The Room has been Updated.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setChangedShape(null);
    }
    if (resultStats2?.isError) {
    }
  }, [resultStats2]);
  const handleEditButtonClick = (index) => {
    setDeskOpen(true);
  };
  const handleAssignClick = () => {
    setAssignDeskOpen(true);
  };

  const handleUnAssignClick = (event, shape) => {
    event.preventDefault();
    setIsDragging(false);
    setSelectedShape(null);
    updateFloorRooms({
      id: shape?.id,
      updateData: {
        assignedTo: null,
      },
    });
  };

  if (
    loading ||
    isLoading ||
    Companyloading ||
    usersLoading ||
    loadingAmenities ||
    spaceLoading ||
    IndividualsLoading
  ) {
    return <Loader />;
  }
  return (
    <div className="container-fluid card">
      <div className="row ">
        <div
          className="col-sm-12 col-md-9 "
          style={{
            touchAction: isDraggingInProgress ? "none" : "auto",
            overflow: isDraggingInProgress ? "hidden" : "auto",
          }}
        >
          <div
            onDragOver={handleDragOver}
            onMouseMove={handleImageAreaMouseMove}
            onTouchMove={handleImageAreaMouseMove}
            onMouseUp={handleMouseUp}
            onTouchEnd={handleMouseUp}
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
            ref={imageAreaRef}
            onClick={handleImageAreaClick}
            onDrop={(event) => {
              if (dragEnabled) {
                const shapeType = event.dataTransfer.getData("text/plain");
                const { left, top } =
                  imageAreaRef.current.getBoundingClientRect();
                const x =
                  event.clientX - left + imageAreaRef.current.scrollLeft;
                const y = event.clientY - top + imageAreaRef.current.scrollTop;
                const shape = { type: shapeType, x, y, isChanged: false };
                setShapes([...shapes, shape]);
              } else {
                handleDrop();
              }
            }}
            style={{
              width: `${imageWidth}px`,
              height: `${imageHeight}px`,
              marginTop: "20px",
              margin: "10px",
              position: "relative",
              overflow: "hidden",
              transform: `scale(${zoomLevel})`,
              transformOrigin: "top left",
              backgroundColor: "blue",
            }}
          >
            <img
              src={floorImage}
              alt="Floor Image"
              draggable="false"
              style={{
                width: "100%",
                height: "100%",
                userSelect: "none",
              }}
            />

            {shapes?.map((shape, index) => {
              const shapeSize =
                Math.min(imageWidth - 50, imageHeight - 50) * 0.1;

              return (
                <>
                  <div
                    key={index}
                    style={{
                      width: shapeSize,
                      height: shapeSize,
                      // backgroundColor: shape.isOccupied ? "red" : "green",
                      backgroundColor: shape.assignedTo
                        ? "red"
                        : shape.type === "1"
                        ? "rgba(31,213,235)"
                        : shape.type === "2"
                        ? "rgba(255,159,67)"
                        : shape.type === "3"
                        ? "rgba(115,103,240)"
                        : "rgba(40,199,111)",

                      border: "5px solid rgb(194, 231, 234)",
                      borderRadius:
                        shape.type === "1" || shape.type === "2"
                          ? "50%"
                          : shape.type === "3" || shape.type === "4"
                          ? "20%"
                          : "",
                      position: "absolute",
                      left: `${shape.x}px`,
                      top: `${shape.y}px`,
                      cursor: "move",
                      zIndex: shape.isChanged ? 1 : "auto",
                      touchAction: "none",
                    }}
                    title={
                      shape.type == 1
                        ? "Dedicated Desk"
                        : shape.type == 2
                        ? "Hot Desk"
                        : ""
                    }
                    onMouseDown={(event) => handleShapeMouseDown(event, index)}
                    onTouchStart={(event) =>
                      handleShapeTouchStart(event, index)
                    }
                    onTouchMove={(event) => handleShapeTouchMove(event, index)} // Add touchmove handler
                    onTouchEnd={() => handleShapeTouchEnd(index)}
                    onClick={() => {
                      if (showDelete == false) {
                        setShowDelete(true);
                        setShowRoomEdit(false);
                        setCheck(index);
                      } else {
                        setShowDelete(false);
                        setCheck(null);
                      }
                    }}
                  >
                    {shape.type === "4" ? (
                      <p
                        style={{
                          display: "inline-block",
                          color: "white",
                          backgroundColor: "rgb(24, 23, 23,0.8)",
                          marginTop: 35,
                          padding: "1px",
                          borderRadius: "5px",
                          whiteSpace: "nowrap",
                          marginLeft: "-50px",
                          userSelect: "none",
                        }}
                      >
                        Meeting Room
                      </p>
                    ) : shape.type === "3" ? (
                      <p
                        style={{
                          display: "inline-block",
                          color: "white",
                          backgroundColor: "rgb(24, 23, 23,0.8)",
                          marginTop: 35,
                          padding: "1px",
                          borderRadius: "5px",
                          whiteSpace: "nowrap",
                          marginLeft: "-40px",
                          userSelect: "none",
                        }}
                      >
                        Private Office
                      </p>
                    ) : (
                      ""
                    )}
                    {showDelete && check === index && (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className="d-flex"
                        style={{
                          position: "absolute",
                          bottom: 40,
                          right: -15,
                          width: "20px",
                          height: "20px",
                          backgroundColor: "white",
                          border: "1px solid black",
                          borderRadius: "50%",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 9999,
                        }}
                      >
                        <button
                          className="border-right border-dark pr-3 text-info rounded-start"
                          onClick={handleEditButtonClick}
                        >
                          Edit
                        </button>

                        <EditDesk
                          shape={shape}
                          deskOpen={deskOpen}
                          setDeskOpen={setDeskOpen}
                          setShowDelete={setShowDelete}
                          setSelectedShape={setSelectedShape}
                          FloorPlans={fetchFloorPlans}
                          AssignedUsers={FetchUsers?.users}
                          Amenities={fetchAmenities?.facilities}
                        />

                        {shape.type == 1 || shape.type == 3 ? (
                          <>
                            {shape.assignedTo == null ? (
                              <>
                                <button
                                  className="border-right border-dark pr-3 text-info rounded-start"
                                  onClick={handleAssignClick}
                                >
                                  Assign
                                </button>
                                <AssignDesk
                                  shape={shape}
                                  assignDeskOpen={assignDeskOpen}
                                  setAssignDeskOpen={setAssignDeskOpen}
                                  setShowDelete={setShowDelete}
                                  setSelectedShape={setSelectedShape}
                                  FetchUsers={FetchIndividuals?.users}
                                  FetchCompanies={FetchCompanies?.users}
                                  companyUsers={companyUsers}
                                  spaceId={spaceId || currentSpaceId}
                                />
                              </>
                            ) : (
                              <button
                                className="border-right border-dark pr-3 text-info rounded-start"
                                onClick={(event) =>
                                  handleUnAssignClick(event, shape)
                                }
                              >
                                UnAssign
                              </button>
                            )}
                          </>
                        ) : null}

                        <button
                          onClick={(event) =>
                            handleExistingShapeDelete(event, index)
                          }
                          className="text-danger rounded-end"
                        >
                          Remove
                        </button>
                      </div>
                    )}
                    {showDelete && check === index && (
                      <div
                        onClick={(e) => {
                          setIsDragging(false);
                          setSelectedShape(null);
                        }}
                        className="d-flex"
                        style={{
                          position: "absolute",
                          bottom: -7,
                          right: -7,
                          width: shapeSize + 6,
                          height: shapeSize + 6,
                          border: "2px solid green",
                          borderRadius: "10%",
                          cursor: "pointer",

                          zIndex: 9999,
                        }}
                      ></div>
                    )}
                  </div>
                </>
              );
            })}

            {selectionArea && (
              <div
                className="selection-area"
                style={{
                  left: Math.min(selectionArea?.startX, selectionArea?.endX),
                  top: Math.min(selectionArea?.startY, selectionArea?.endY),
                  width: Math.abs(selectionArea?.endX - selectionArea?.startX),
                  height: Math.abs(selectionArea?.endY - selectionArea?.startY),
                }}
              ></div>
            )}
          </div>
        </div>

        <div
          className="col-sm-12 col-md-3 floorsidebar mt-2"
          style={{ borderLeft: "1px solid black" }}
        >
          <div className="tabs d-flex justify-content-around border-bottom ">
            <div
              className={`tab ${activeTab === "Desk" ? "active" : ""}`}
              onClick={() => handleTabClick("Desk")}
              style={{
                cursor: "pointer",
                borderBottom: activeTab === "Desk" ? "2px solid blue" : "",
              }}
            >
              Desk
            </div>
            <div
              className={`tab ${activeTab === "Spaces" ? "active" : ""}`}
              onClick={() => handleTabClick("Spaces")}
              style={{
                cursor: "pointer",
                borderBottom: activeTab === "Desk" ? "" : "2px solid blue",
              }}
            >
              Spaces
            </div>
          </div>
          <div className="justify-content-center mt-2">
            {activeTab === "Desk" && (
              <div className="room-list form-control">
                {roomsData?.map((item, index) => {
                  if (item?.room_type?.id === 1 || item?.room_type?.id === 2) {
                    return (
                      <div
                        className="ml-3"
                        style={{
                          cursor: "pointer",
                          userSelect: "none",
                          padding: 5,
                          borderRadius: 10,
                          backgroundColor:
                            showDelete && check === index
                              ? "rgb(195, 222, 230)"
                              : "",
                        }}
                        key={index}
                        onClick={() => {
                          if (showDelete == false) {
                            setShowDelete(true);
                            setShowRoomEdit(false);
                            setCheck(index);
                          } else {
                            setShowDelete(false);
                            setCheck(null);
                          }
                        }}
                      >
                        <p>{item.name}</p>
                      </div>
                    );
                  } else return null;
                })}
              </div>
            )}
            {activeTab === "Spaces" && (
              <div className="room-list form-control">
                {roomsData?.map((item, index) => {
                  if (item?.room_type?.id === 3 || item?.room_type?.id === 4) {
                    return (
                      <div
                        className="ml-3"
                        style={{
                          cursor: "pointer",
                          userSelect: "none",
                          padding: 5,
                          borderRadius: 10,
                          backgroundColor:
                            showDelete && check === index
                              ? "rgb(195, 222, 230)"
                              : "",
                        }}
                        key={index}
                        onClick={() => {
                          if (showDelete == false) {
                            setShowDelete(true);
                            setShowRoomEdit(false);
                            setCheck(index);
                          } else {
                            setShowDelete(false);
                            setCheck(index);
                          }
                        }}
                      >
                        <p>{item.name}</p>
                      </div>
                    );
                  } else return null;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageArea;
