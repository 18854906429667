import React from "react";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import { Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useDeleteQuotationMutation,
  useDeleterCompanyUserMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useEffect } from "react";
function DeleteCompanyUser({ id }) {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [deleteCompanyMember, resultStats] = useDeleterCompanyUserMutation();

  const handleDelete = (e) => {
    e.preventDefault();

    confirmationAlert("want to delete User?").then((willDelete) => {
      if (willDelete) {
        deleteCompanyMember({
          spaceId: spaceId || currentSpaceId,
          memberId: id,
        });
      }
    });
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("User Deleted Successfully.");
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);
  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip" style={{ transition: "none" }}>
            Delete Member
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          id="viewMeetingOpen"
          onClick={handleDelete}
          style={{ transition: "none" }}
        >
          <DeleteIcon />
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export default DeleteCompanyUser;
