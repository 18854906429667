import React, { useEffect } from "react";
import {
  AddMemberEdit,
  AddMemberList,
  AddMemberView,
} from "../Components/AddMember";
import { useGlobalContext } from "../Context/GlobalContext";

export const AddMemberLists = () => {
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Members");
  }, []);
  return (
    <>
      {/* <div className="reports"> */}
      <AddMemberList />
      {/* </div> */}
    </>
  );
};

// export const AddMemberViews = () => {
//   return (
//     <>
//       <div className="reports">
//         <AddMemberView />
//       </div>
//     </>
//   );
// };

// export const AddMemberEdits = () => {
//   return (
//     <>
//       <div className="reports">
//         <AddMemberEdit />
//       </div>
//     </>
//   );
// };
