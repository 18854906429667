import React, { useState } from "react";
import PolicyModal from "./PolicyModal";
import { useSortableData } from "../../utils/SortingFunction";
import DeletePolicy from "./DeletePolicy";
import DurationModal from "./DurationModal";
import DeleteDuration from "./DeleteDuration";

const SpaceDurations = ({ spaceDurations }) => {
  const [showModal, setShowModal] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [search, setSearch] = useState("");
  console.log("Space duration is ", spaceDurations);
  const handleOpenUpdateModal = (policy) => {
    setSelectedPolicy(policy);
    setUpdateModalVisible(true);
  };
  const { items, requestSort, sortConfig } = useSortableData(
    spaceDurations?.spaceDurations || []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const filteredItems = items
    // ?.slice()
    // .reverse()
    ?.filter((val) => {
      if (search == "") {
        return val;
      } else if (val?.name?.toLowerCase()?.includes(search.toLowerCase())) {
        return val;
      }
    });
  console.log("Cancellation policies in child is", spaceDurations);
  return (
    <div className="card">
      <div className="col-12 d-flex p-4 justify-content-between">
        <h4>Packages Durations</h4>
        <DurationModal
          onCloseModal={() => setShowModal(false)} // Close modal from here
          onOpenModal={() => setShowModal(true)}
          showModal={showModal}
          title="Add Duration"
          submitButtonText="Add Duration"
        />
      </div>
      {/* {cancellationPolicies?.policies?.length == 0 && (
        <h6 className="ml-4 mb-4">No policies have been added yet.</h6>
      )} */}

      <div className="table-responsive mt-3">
        <table className="table">
          <thead>
            <tr>
              {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Name</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("employees:name")}
                      className={getClassNamesFor("employees:name")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("employees:name")}
                      className={getClassNamesFor("employees:name")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th> */}
              {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Hours</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("team")}
                      className={getClassNamesFor("team")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("team")}
                      className={getClassNamesFor("team")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th> */}
              {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Last Login</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("lastlogin")}
                      className={getClassNamesFor("lastlogin")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("lastlogin")}
                      className={getClassNamesFor("lastlogin")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th> */}
              {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Status</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("status")}
                      className={getClassNamesFor("status")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("status")}
                      className={getClassNamesFor("status")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th> */}
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Name</div>
                  <div
                    className="d-flex"
                    style={{ flexDirection: "column" }}
                  ></div>
                </div>
              </th>
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Day</div>
                  <div
                    className="d-flex"
                    style={{ flexDirection: "column" }}
                  ></div>
                </div>
              </th>
              {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Deduction Rate</div>
                  <div
                    className="d-flex"
                    style={{ flexDirection: "column" }}
                  ></div>
                </div>
              </th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems?.length > 0 ? (
              filteredItems?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="media-body my-auto d-flex flex-column">
                        {/* <span className="mb-0 table-avatar">{item.role}</span> */}
                        {item.spaceDuration}
                      </div>
                    </td>
                    <td>
                      <div className="media-body my-auto d-flex flex-column">
                        {/* <span className="mb-0 table-avatar">{item.role}</span> */}
                        {item.day}
                      </div>
                    </td>
                    {item.space ? (
                      <td className="d-flex flex-nowrap">
                        <DurationModal
                          policyData={{
                            id: selectedPolicy?.id,
                            spaceDuration: selectedPolicy?.spaceDuration,
                            day: selectedPolicy?.day,
                          }}
                          onCloseModal={() => setUpdateModalVisible(false)}
                          onOpenModal={() => handleOpenUpdateModal(item)}
                          showModal={updateModalVisible}
                          title="Update Duration"
                          submitButtonText="Update Duration"
                        />
                        <DeleteDuration {...item} />
                      </td>
                    ) : (
                      <td className="d-flex flex-nowrap">------</td>
                    )}
                  </tr>
                );
              })
            ) : filteredItems?.length == 0 ? (
              <tr>
                <td colSpan={6} className="text-left fs-5">
                  {search == "" ? "No Duration !" : "No Duration found"}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SpaceDurations;
