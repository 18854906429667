import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { Amenities, Select } from "./";
import { errorAlertImage } from "../../utils/Alerts";
import { DataOfTime } from "../../utils/constant";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { DashLg } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import {
  useGetCurrencyQuery,
  useUpdateSpaceMutation,
} from "../../Slices/spaceApi";
import TimeHours from "./timeHours.json";
import Logo1 from "../../Assets/dummyImages/Logo1.png";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../../Context/GlobalContext";
import ColorPicker from "./ColorPicker";
import OperatingHours from "./OperatingHours";

function TaxConfiguration({ spaceData }) {
  const { settingsPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();

  const [setOverallTax, setSetOverallTax] = useState(false);

  const [overAllTax, setOverAllTax] = useState(spaceData?.setOveralTax);

  const [rentalFee, setRentalFee] = useState(spaceData?.rentalFee);
  const [overallTaxAmmount, setOverAllTaxAmmount] = useState(
    spaceData?.serviceCharges
  );
  const [serviceCharges, setServiceCharges] = useState(
    spaceData?.serviceCharges
  );
  useEffect(() => {
    setOverAllTaxAmmount(spaceData?.serviceCharges);
    setServiceCharges(spaceData?.serviceCharges);
    setRentalFee(spaceData?.rentalFee);
    setOverAllTax(spaceData?.setOveralTax);
    setSetOverallTax(spaceData?.setOveralTax);
  }, [spaceData]);

  const [taxChanged, setTaxChanged] = useState(false);

  const handleSetOverallTaxChange = () => {
    setSetOverallTax(!setOverallTax);
    if (!setOverallTax) {
      setRentalFee(null);
      setServiceCharges(null);
      // setOverAllTax(false);
    }
  };

  const handleOverallTaxUpdate = (e) => {
    setOverAllTaxAmmount(e.target.value);
    setTaxChanged(true);
    setOverAllTax(true);
  };

  const handleRentalFee = (e) => {
    setRentalFee(e.target.value);
    setOverAllTaxAmmount(null);
    setTaxChanged(true);
  };
  const handleServiceCharges = (e) => {
    setServiceCharges(e.target.value);
    setOverAllTaxAmmount(null);
    setTaxChanged(true);
  };

  const updateTaxConfiguration = (e) => {
    e.preventDefault();

    if (setOverallTax) {
      const data = {
        setOveralTax: true,
        serviceCharges: parseFloat(overallTaxAmmount),
        rentalFee: 0.0,
      };

      updateGeneral({
        spaceId: spaceId || currentSpaceId,
        data: data,
      });
    } else {
      const data = {
        setOveralTax: false,
        serviceCharges: parseFloat(serviceCharges)?.toFixed(2),
        rentalFee: parseFloat(rentalFee)?.toFixed(2),
      };
      updateGeneral({
        spaceId: spaceId || currentSpaceId,
        data: data,
      });
    }
  };

  const handleClose = () => {
    setTaxChanged(false);
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Tax Configuration Updated Successfully!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 2000);

      handleClose();
    }
  }, [resultStats]);
  return (
    <div>
      <div className="card">
        <div className="row p-4">
          <h4>Tax Configuration</h4>
          <p>
            Our system allow you to split your taxes into two categories.
            <br /> 1) Rental Tax <br />
            2) Service Tax and define them explicitly. <br />
            You still have option to define a collective tax.
          </p>
          <div className="form-group">
            <>
              <label htmlFor="tax">Rental Fee (%)</label>
              <input
                type="number"
                className="form-control col-sm-12 col-md-6"
                placeholder="Please add rental fee"
                name="tax"
                id="tax"
                value={!overAllTax ? rentalFee : null}
                onChange={handleRentalFee}
                disabled={setOverallTax}
              />
              <label htmlFor="tax">Service Charges (%)</label>
              <input
                type="number"
                className="form-control col-sm-12 col-md-6"
                placeholder="Please add service charges"
                name="tax"
                id="tax"
                disabled={setOverallTax}
                value={!overAllTax ? serviceCharges : null}
                onChange={handleServiceCharges}
              />
            </>

            <div className="form-check mt-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="setOverallTax"
                checked={setOverallTax}
                onChange={handleSetOverallTaxChange}
              />
              <label className="form-check-label" htmlFor="setOverallTax">
                Set Overall Tax
              </label>
            </div>

            {setOverallTax && (
              <>
                <label htmlFor="overallTax">Overall Tax (%)</label>
                <input
                  type="number"
                  className="form-control col-sm-12 col-md-6"
                  placeholder="Please add overall tax"
                  name="overallTax"
                  id="overallTax"
                  value={overAllTax ? overallTaxAmmount : null}
                  onChange={handleOverallTaxUpdate}
                />
              </>
            )}
          </div>

          <div className="row ">
            <div className="col-md-12">
              <button
                className="btn btn-primary "
                disabled={!taxChanged || !settingsPermissions[0]?.update}
                onClick={
                  settingsPermissions[0]?.update ? updateTaxConfiguration : null
                }
              >
                {settingsPermissions[0]?.update ? (
                  "Save Changes"
                ) : (
                  <span>Save Changes{Lock2}</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaxConfiguration;
