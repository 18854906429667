import React, { useEffect } from "react";
import Dashboard from "../Components/Dashboard/Dashboard";
import { useSelector } from "react-redux";
import {
  useGetAllUpComingBookingQuery,
  useGetSpaceDashboardQuery,
} from "../Slices/spaceApi";
import Loader from "../Components/Loader/Loader";
import { useGlobalContext } from "../Context/GlobalContext";
const DashboardPage = () => {
  // const { setDashBoardTitle } = useGlobalContext();
  // useEffect(() => {
  //   setDashBoardTitle("Dashboard");
  // }, []);
  return <Dashboard />;
};

export default DashboardPage;
