import React, { useState } from 'react'
import {
  Button,
  Col,
  Dropdown,
  Form,
  Nav,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap'

import { Percent, Receipt } from 'react-bootstrap-icons'
import PaymentSummary from './PaymentSummary'
import PaymentByIncomeType from './PaymentByIncomeType'
import IncomeEarned from './IncomeEarned'
import * as Icon from 'react-bootstrap-icons'
const FinanceShared = () => {
  const [page, setPage] = useState(0)

  return (
    <>
      <section className='container-fluid  with-bottom-padding pt-0 p-3  mt-0'>
        <Tabs
          defaultActiveKey='incomeEarned'
          id='justify-tab-example'
          // className='mb-3'
          variant='underline'
          justify
        >
          <Tab
            // style={{ marginRight: '5px' }}
            eventKey='incomeEarned'
            title={
              <span className='d-flex align-items-center'>
                <Icon.Building /> &nbsp;Income Earned
              </span>
            }
          >
            {/* <CompanyTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <IncomeEarned />
          </Tab>
          <Tab
            eventKey='summary'
            title={
              <span className='d-flex align-items-center'>
                <Icon.Person /> &nbsp;Payment Summary
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <PaymentSummary />
          </Tab>
          <Tab
            eventKey='incomeType'
            title={
              <span className='d-flex align-items-center'>
                <Icon.Person /> &nbsp;Payment By Income Type
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <PaymentByIncomeType />
          </Tab>
        </Tabs>
      </section>
    </>
  )
}

export default FinanceShared
