import React from "react";
import "./index.css";
import { TabProfile, TabPassword, TabInformation, Tabs, Tab } from "./";
import { Info, Lock1, Lock2, Manage } from "../../Assets/MemberSvgs/Icon";
import { Card } from "../../Assets/SettingSvgs/Icon";
import { Bell } from "../../Assets/SettingSvgs/Icon";
import { Booking } from "../../Assets/SettingSvgs/Icon";

import { User } from "../../Assets/MemberSvgs/Icon";
import {
  useGetCancellationPoliciesQuery,
  useGetNotificationSettingsQuery,
  useGetSpaceDataQuery,
  useGetSpaceDurationsQuery,
} from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import {
  useGetCountiresQuery,
  useGetFacilitiesQuery,
  useGetNotificationTypesQuery,
} from "../../Slices/constantApi";
import { Toaster } from "react-hot-toast";
import Loader from "../Loader/Loader";
import ManageAccount from "./ManageAccount";
import { useGlobalContext } from "../../Context/GlobalContext";
import Integrations from "./Integrations";
import { useEffect } from "react";
import { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import NotificationsSettings from "./NotificationsSettings";
import BookingConfiguration from "./BookingConfiguration";
const AccountSetting = () => {
  const {
    settingsPermissions,
    settingsKey,
    setSettingsKey,
    setBillingOption,
    billingOption,
    setGeoLocation,
    // setOpenDays,
  } = useGlobalContext();
  const { currentSpaceId, spaceId } = useSelector((item) => item.spaceReducer);

  const { data: spaceData, isLoading } = useGetSpaceDataQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: cancellationPolicies, policiesLoading } =
    useGetCancellationPoliciesQuery({
      spaceId: spaceId || currentSpaceId,
    });
  const { data: spaceDurations, durationsLoading } = useGetSpaceDurationsQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: notificationSettingsData, notificationLoading } =
    useGetNotificationSettingsQuery({
      spaceId: spaceId || currentSpaceId,
    });
  const { data: notificationTypes, notificationTypeLoading } =
    useGetNotificationTypesQuery();

  useEffect(() => {
    setBillingOption(spaceData?.billing_option);
  }, [spaceData]);

  const { data: facilities, isLoading: loadingFacilities } =
    useGetFacilitiesQuery();

  const { data: countries, isLoading: loadingCountries } =
    useGetCountiresQuery();

  if (
    isLoading ||
    loadingFacilities ||
    loadingCountries ||
    policiesLoading ||
    durationsLoading
  ) {
    return <Loader height={6.6} />;
  }

  return (
    <>
      <section id="account-setting" className="container-fluid app-user-list">
        <div className="">
          <h2>Account Setting</h2>
          <div className="d-flex vertical flex-column flex-xl-row">
            <Tabs
              // defaultActiveKey="General"
              defaultActiveKey={settingsKey}
              id="justify-tab-example"
              className="mb-3 d-flex flex-column col-xl-3"
              justify
            >
              {settingsPermissions[0]?.update ? (
                <Tab
                  eventKey="General"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {User} General
                    </span>
                  }
                  onClick={() => setSettingsKey("General")}
                >
                  <TabProfile
                    spaceData={spaceData}
                    facilities={facilities}
                    spaceDurations={spaceDurations}
                  />
                </Tab>
              ) : (
                <Tab
                  eventKey="General"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {User} General{Lock2}
                    </span>
                  }
                  onClick={() => setSettingsKey("General")}
                >
                  <TabProfile
                    spaceData={spaceData}
                    facilities={facilities}
                    spaceDurations={spaceDurations}
                  />
                </Tab>
              )}

              {settingsPermissions[0]?.update ? (
                <Tab
                  eventKey="Change Password"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {Lock1} Change Password
                    </span>
                  }
                  onClick={() => setSettingsKey("Change Password")}
                >
                  <TabPassword />
                </Tab>
              ) : (
                <Tab
                  eventKey="Change Password"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {Lock1} Change Password{Lock2}
                    </span>
                  }
                  onClick={() => setSettingsKey("Change Password")}
                >
                  <TabPassword />
                </Tab>
              )}

              {settingsPermissions[0]?.update ? (
                <Tab
                  eventKey="Information"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {Info}
                      Banking Information
                    </span>
                  }
                  onClick={() => setSettingsKey("Information")}
                >
                  <TabInformation spaceData={spaceData} Country={countries} />
                </Tab>
              ) : (
                <Tab
                  eventKey="Information"
                  onClick={() => setSettingsKey("Information")}
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {Info}
                      Banking Information{Lock2}
                    </span>
                  }
                >
                  <TabInformation spaceData={spaceData} Country={countries} />
                </Tab>
              )}
              {settingsPermissions[0]?.update ? (
                <Tab
                  eventKey="Booking Configuration"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {Booking}
                      Booking Configuration
                    </span>
                  }
                  onClick={() => setSettingsKey("Booking Configuration")}
                >
                  <BookingConfiguration
                    spaceData={spaceData}
                    cancellationPolicies={cancellationPolicies}
                  />
                </Tab>
              ) : (
                <Tab
                  eventKey="Booking Configuration"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {Booking}
                      Booking Configuration{Lock2}
                    </span>
                  }
                  onClick={() => setSettingsKey("Booking Configuration")}
                >
                  {/* <ManageAccount /> */}
                </Tab>
              )}
              {settingsPermissions[0]?.update ? (
                <Tab
                  eventKey="Manage Account"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {Manage}
                      Manage Account
                    </span>
                  }
                  onClick={() => setSettingsKey("Manage Account")}
                >
                  <ManageAccount />
                </Tab>
              ) : (
                <Tab
                  eventKey="Manage Account"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {Manage}
                      Manage Account{Lock2}
                    </span>
                  }
                  onClick={() => setSettingsKey("Manage Account")}
                >
                  <ManageAccount />
                </Tab>
              )}
              {settingsPermissions[0]?.update ? (
                <Tab
                  eventKey="Integrations"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {Card}
                      Integrations
                    </span>
                  }
                  onClick={() => setSettingsKey("Integrations")}
                >
                  <Integrations oldStripeKey={spaceData?.stripeKey} />
                </Tab>
              ) : (
                <Tab
                  eventKey="Integrations"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {Card}
                      Integrations{Lock2}
                    </span>
                  }
                  onClick={() => setSettingsKey("Integrations")}
                >
                  <Integrations oldStripeKey={spaceData?.stripeKey} />
                </Tab>
              )}
              {settingsPermissions[0]?.update ? (
                <Tab
                  eventKey="Notifications"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      {Bell}
                      Notifications
                    </span>
                  }
                  onClick={() => setSettingsKey("Notifications")}
                >
                  <NotificationsSettings
                    notificationSettings={
                      notificationSettingsData?.notificationSettings
                    }
                    notificationTypes={notificationTypes}
                  />
                </Tab>
              ) : (
                <Tab
                  eventKey="Notifications"
                  title={
                    <span className="d-flex spanClass align-items-center">
                      <Icon.Bell />
                      Notifications{Lock2}
                    </span>
                  }
                  onClick={() => setSettingsKey("Notifications")}
                >
                  <Integrations oldStripeKey={spaceData?.stripeKey} />
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
      </section>

      <Toaster position="top-right" />
    </>
  );
};

export default AccountSetting;
