import React, { useContext, useReducer } from 'react'
import { useEffect } from 'react'
import { AuthReducer } from './AuthReducer'
import GetCookie from '../utils/getCookie'
import SetCookie, { SetSessionCookie } from '../utils/setCookie'
import { useNavigate } from 'react-router-dom'

const AppContext = React.createContext()

const AuthProvider = ({ children }) => {
    
    const Initialization = {
        // currentUserSessionStorage: JSON.parse(sessionStorage.getItem('token') || null),
    }

    const [state, dispatch] = useReducer(AuthReducer, Initialization)


    // useEffect(() => {
    //     sessionStorage.setItem('token', JSON.stringify(state.currentUserSessionStorage !== undefined ? state.currentUserSessionStorage : null))
    // }, [state.currentUserSessionStorage])

    return (
        <AppContext.Provider value={{
            // currentUserSessionStorage: state.currentUserSessionStorage,
            dispatch
        }}>{children}</AppContext.Provider>
    )
}

export const useGlobalAuthContext = () => {
    return useContext(AppContext)
}

export { AppContext, AuthProvider }

