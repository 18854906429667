import React from "react";
import EditMemberPackage from "./EditMemberPackage";
import CancelMemberPackage from "./CancelMemberPackage";
import { utcToZonedTime } from "date-fns-tz";
import { utcDateToLocalDate } from "../../utils/utcDateToLocalDate";
import { UtcToLocalDateTime } from "../../utils/UtcToLocalDateTime";
const MemberPackageTable = ({
  PackagesDetails,
  status,
  FetchPackageData,
  FetchMeetingPackageData,
  Editable,
  memberId,
}) => {
  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter?.format(date);
    const formattedTime = timeFormatter?.format(date);

    return `${formattedDate}`;
  }
  return (
    <>
      {PackagesDetails?.map((item, index) => {
        if (item.status == status) {
          const startDate = new Date(item?.start_date)?.toLocaleDateString();
          const renewDate = new Date(item?.renew_date)?.toLocaleDateString();
          return item?.meeting_packages?.map((mpkg, idx) => (
            <tr key={idx}>
              <td>
                <b>{mpkg?.package?.name}</b>
              </td>
              <td>{mpkg?.quantity}</td>

              <td>
                {startDate && formatDateTime(UtcToLocalDateTime(startDate))}
              </td>
              <td>
                {renewDate && formatDateTime(UtcToLocalDateTime(renewDate))}
              </td>

              {Editable && (
                <td>
                  {" "}
                  <div className="d-flex">
                    {/* <EditMemberPackage
                      itemId={item?.id}
                      id={mpkg?.id}
                      packageType={2}
                      packageName={mpkg?.package?.name}
                      FetchPackageData={FetchPackageData}
                      FetchMeetingPackageData={FetchMeetingPackageData}
                      memberId={memberId}
                      packageId={item?.meeting_packages[0]?.package?.id}
                      packageQuantity={item?.meeting_packages[0]?.quantity}
                      isRecurring={item?.meeting_packages[0]?.isRecurring}
                    /> */}
                    <CancelMemberPackage
                      id={mpkg?.id}
                      itemId={item?.id}
                      packageType={2}
                    />
                  </div>
                </td>
              )}
            </tr>
          ));
        }
      })}

      {PackagesDetails?.map((item, index) => {
        if (item.status == status) {
          const startDate = new Date(item.start_date)?.toLocaleDateString();
          const renewDate = new Date(item.renew_date)?.toLocaleDateString();
          return item?.packages?.map((mpkg, idx) => (
            <tr key={idx}>
              <td>
                <b>{mpkg?.package?.name}</b>
              </td>
              <td>{mpkg?.quantity}</td>
              <td>
                {startDate && formatDateTime(UtcToLocalDateTime(startDate))}
              </td>
              <td>
                {renewDate && formatDateTime(UtcToLocalDateTime(renewDate))}
              </td>

              {Editable && (
                <td>
                  <div className="d-flex">
                    {/* <EditMemberPackage
                      id={mpkg?.id}
                      itemId={item?.id}
                      packageType={1}
                      packageName={mpkg?.package?.name}
                      FetchPackageData={FetchPackageData}
                      FetchMeetingPackageData={FetchMeetingPackageData}
                      memberId={memberId}
                      packageId={item?.packages[0]?.package?.id}
                      packageQuantity={item?.packages[0]?.quantity}
                      isRecurring={item?.packages[0]?.isRecurring}
                    /> */}
                    <CancelMemberPackage
                      id={mpkg?.id}
                      itemId={item?.id}
                      packageType={1}
                    />
                  </div>
                </td>
              )}
            </tr>
          ));
        }
      })}
    </>
  );
};

export default MemberPackageTable;
