import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import ViewInvoicePopup from "../AddInvoices/ViewInvoicePopup";
import DeleteInvoicePopup from "../AddInvoices/DeleteInvoicePopup";
import UpdateInvoicePopup from "../AddInvoices/UpdateInvoicePopup";
// import { useState } from "react";
import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import {
  useGetAllInvoicesQuery,
  useGetAnnouncementsQuery,
} from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { Badge, Pagination } from "react-bootstrap";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";

import invoice1Png from "../../Assets/dummyImages/1.png";
import invoice2Png from "../../Assets/dummyImages/2.png";
import Loader from "../Loader/Loader";
import { useGlobalContext } from "../../Context/GlobalContext";
import DeleteAnnouncement from "./DeleteAnnouncement";
import Swal from "sweetalert2";
import AnnouncementModal from "./AnnouncementModal";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ViewAnnouncement from "./ViewAnnouncement";
const AnnouncementTable = () => {
  const { announcementPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Announcements");
  }, []);
  const [activePage, setActivePage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");

  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const [loading, setLoading] = useState(false);

  const { data: fetchingAnnouncements, isLoading: announcementsLoading } =
    useGetAnnouncementsQuery({
      spaceId: spaceId || currentSpaceId,
      page_size: itemsPerPage.value,
      page: activePage,
      name: searchQuery,
    });
  useEffect(() => {
    setLoading(false);
  }, [fetchingAnnouncements]);

  const { items, requestSort, sortConfig } = useSortableData(
    fetchingAnnouncements?.announcements || []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  useEffect(() => {
    let timer;
    const debounceSearch = () => {
      setLoading(true);
      setSearchQuery(search);
      setActivePage(1);
    };
    clearTimeout(timer);
    if (search.length > 0) {
      timer = setTimeout(debounceSearch, 1000);
    } else {
      setSearchQuery("");
      setActivePage(1);
      setLoading(false);
    }
    return () => clearTimeout(timer);
  }, [search]);

  function timeStampToDate(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);
    return date.toLocaleDateString();
  }

  const handlePage = (e) => {
    setitemsPerPage(e);
    setActivePage(1);

    setLoading(true);
  };

  const handleStatus = (e) => {
    setStatus(e);
    setLoading(true);
  };

  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];

  const optionsStatus = [
    {
      label: "Select All",
      value: 4,
    },
    {
      label: "Un Paid",
      value: 0,
    },
    {
      label: "Paid",
      value: 1,
    },
    {
      label: "Over Due",
      value: 2,
    },
    // {
    //   label: "Active",
    //   value: 3,
    // },
  ];
  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate},${formattedTime}`;
  }

  const handleViewAnnouncement = (item) => {
    Swal.fire({
      title: `Subject: ${item.subject}`,
      html: `<hr/>${item.content}`,
      showCloseButton: true,
      width: "50em",
      customClass: {
        popup: "custom-popup",
        title: "justify-item-start",
      },
    });
  };
  const images = [invoice1Png, invoice2Png];
  if (announcementsLoading) {
    return <Loader height={6.6} />;
  }
  return (
    <>
      <section
        className="container-fluid invoice-list-wrapper with-bottom-padding"
        style={{ padding: "0px 16px" }}
      >
        <div className="card">
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-between ">
              <div className="col-12  col-md-4 col-lg-6">
                <input
                  type="search"
                  className="form-control"
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search Announcement"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
                <AnnouncementModal />
              </div>
            </div>
          </div>

          <hr />
          <div className="col-12 ">
            <div className="">
              <div className="col-12 d-flex justify-content-end">
                <label
                  style={{
                    display: "flex",
                    whiteSpace: "nowrap",
                    fontWeight: "normal",
                  }}
                >
                  <small className="mr-2 mt-2 title"> Show</small>
                  <Select
                    options={options}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    onChange={handlePage}
                    value={itemsPerPage}
                    id="pages-select"
                    styles={colorStyles}
                  />
                  <small className="ml-2 mt-2"> entries</small>
                </label>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>#</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Creator</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("companyName")}
                          className={getClassNamesFor("companyName")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("companyName")}
                          className={getClassNamesFor("companyName")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Subject</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("companyName")}
                          className={getClassNamesFor("companyName")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("companyName")}
                          className={getClassNamesFor("companyName")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>

                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Created On</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("dueDate")}
                          className={getClassNamesFor("dueDate")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("dueDate")}
                          className={getClassNamesFor("dueDate")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={8} className="fs-5">
                      <Loader height={6.6} />
                    </td>
                  </tr>
                )}
                {!loading &&
                  (items?.length > 0 ? (
                    items?.map((item, index) => {
                      //   const {
                      //     id,

                      //     subject,
                      //     due_date,
                      //     issued_date,
                      //     paid_date,
                      //     member,
                      //     paid,
                      //   } = item;
                      //   const { profileImg, email, name } = item.member;

                      return (
                        <tr key={index}>
                          <td style={{ color: "#7367f0", fontWeight: "bold" }}>
                            #{item.announcementId || item.id}
                          </td>

                          <td>
                            <div className="media">
                              <div className="media-aside logo-list mr-2">
                                <img
                                  src={
                                    item?.creator?.profileImage ||
                                    "https://placehold.jp/90x90.png"
                                  }
                                  // onError={(e) => (e.target.src = packagePng)}
                                  alt=""
                                  // className="rounded-circle"
                                  style={{
                                    // marginRight: "8px",
                                    borderRadius: "50%",
                                    width: "38px",
                                    height: "38px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>

                              <div className="media-body my-auto d-flex flex-column">
                                <span className="mb-0 table-avatar">
                                  {item?.creator?.name ? (
                                    <b>{item.creator.name}</b>
                                  ) : (
                                    <Badge bg="badge bg-label-danger">
                                      No Details
                                    </Badge>
                                  )}
                                </span>
                                <small className="mb-0 text-muted">
                                  {item?.creator?.email}
                                </small>
                              </div>
                            </div>
                          </td>

                          <td>
                            <b>{item.subject}</b>
                          </td>

                          <td>
                            {formatDateTime(item?.createdAt) || "--/--/----"}
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {" "}
                                    {announcementPermissions[0]?.read ? (
                                      "View"
                                    ) : (
                                      <span>
                                        View <Lock />
                                      </span>
                                    )}
                                  </Tooltip>
                                }
                              >
                                {/* <Button
                                  variant="white"
                                  className="mx-2 p-0"
                                  onClick={() => handleViewAnnouncement(item)}
                                 
                                >
                                  <Preview />
                                </Button> */}
                                <ViewAnnouncement {...item} />
                              </OverlayTrigger>

                              <DeleteAnnouncement {...item} />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-left fs-5">
                        {search == "" ? "No Record !" : "No invoice found"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {!loading && fetchingAnnouncements?.totalPages > 1 && (
            <>
              <div className="m-0 border-bottom" />
              <div className="pagination-custom flex-column flex-lg-row">
                <p className="text-muted mb-0">
                  Showing {fetchingAnnouncements?.announcements.length} of{" "}
                  {fetchingAnnouncements?.totalCount} entires
                </p>
                <Pagination className="mt-lg-0 mt-2">
                  <Pagination.Prev
                    className="previous"
                    onClick={() => {
                      setActivePage(activePage - 1);
                      setLoading(true);
                    }}
                    disabled={activePage === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({
                    length: fetchingAnnouncements?.totalPages,
                  }).map((_, i) => (
                    <Pagination.Item
                      key={i}
                      active={i + 1 === activePage}
                      onClick={
                        i + 1 === activePage
                          ? ""
                          : () => handlePageSelect(i + 1)
                      }
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    className="next"
                    onClick={() => {
                      setActivePage(activePage + 1);
                      setLoading(true);
                    }}
                    disabled={activePage === fetchingAnnouncements?.totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default AnnouncementTable;
