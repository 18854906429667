import { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as Icon from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import Select from "react-select";
import { colorStyles } from "../../utils/constant";
import { useGetSkillsQuery } from "../../Slices/constantApi";
import {
  useAssignPackageToMemberMutation,
  useCreateUserForSpaceMutation,
  useGetCompanyUsersQuery,
  useGetFloorRoomsForMemberQuery,
  useGetUnassignedResourcesQuery,
  useMultiplePackagesCompanyMutation,
} from "../../Slices/spaceApi";
import { toast } from "react-hot-toast";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context/GlobalContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import AssignOccupancy from "./AssignOccupancy";

const UserForSpace = ({
  FetchPackageData,
  FetchMeetingPackageData,
  FloorPlans,
  SpaceTypes,
}) => {
  const { membersPermissions, currency } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [assignPackage, resultStats1] = useAssignPackageToMemberMutation();
  const [userResponse, setuserResponse] = useState("");
  const [apiSpinner, setApiSpinner] = useState(false);
  const { data: FetchResources, isLoading: resourceLoading } =
    useGetUnassignedResourcesQuery({ spaceId: spaceId || currentSpaceId });
  const [errorMessage, setErrorMessage] = useState("");
  const [roomType, setRoomType] = useState("");
  const handleRoomType = (e) => {
    setRoomType(e.target.value);
  };

  const skills = [
    {
      id: 1,
      name: "PHP",
    },
    {
      id: 2,
      name: "HTML",
    },
    {
      id: 3,
      name: "CSS",
    },
    {
      id: 4,
      name: "React.js",
    },
    {
      id: 5,
      name: "Vue.Js",
    },
    {
      id: "",
      name: "Other",
    },
  ];

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [floor, setFloor] = useState("");

  const [hasOtherSkill, setHasOtherSkill] = useState(false);
  const [isProfileImageUploading, setIsProfileImageUploading] = useState(false);
  const [isCnicUploading, setIsCnicUploading] = useState(false);
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  const handleSkillsChange = (selectedOptions) => {
    const newHasOtherSkill = selectedOptions.some((option) => option.id === "");

    if (newHasOtherSkill) {
      // If "Other" is selected, remove any other selected options
      setSelectedSkills([{ id: "", name: "Other" }]);
      setHasOtherSkill(true);
    } else {
      // If any other option is selected, remove "Other" if it was previously selected
      setSelectedSkills(selectedOptions.filter((option) => option.id !== ""));
      setHasOtherSkill(false);
    }
  };

  const handleOtherSkillChange = (event) => {
    if (hasOtherSkill) {
      setSelectedSkills([{ id: "", name: event.target.value }]);
    }
  };
  //*******************Following is for future use in order to assign floor room************************ */

  // const { data: floorRooms, isLoading: usersLoading } =
  //   useGetFloorRoomsForMemberQuery(
  //     {
  //       floorId: floor,
  //       room_type: roomType,
  //     },
  //     {
  //       skip: floor === null || roomType === null,
  //       refetchOnMountOrArgChange: true,

  //     }
  //   );
  const [error, setError] = useState(false);
  const [roomId, setRoomId] = useState("");
  const handleRoomChange = (e) => {
    setRoomId(e.target.value);
  };
  const handleFloorChange = (e) => {
    setFloor(e.target.value);
  };

  const [userData, setUserData] = useState({
    packageId: FetchPackageData?.[0]?.id,
    email: "",
    name: "",
    phoneNo: "",
    cnicNo: "",
    gender: "",
    age: "",
    description: "",
    profileImg: "",
    cnicImage: "",
    cnicBack: "",
    domicile: "",
    securityDeposit: 0,
    domicileImage: "",
    experienceYears: "",
    address: "",
    profileUrls: [
      {
        facebook: {
          url: "facebook",
          public: "",
        },

        upwork: {
          url: "",
          public: "",
        },

        fiverr: {
          url: "",
          public: "",
        },
      },
    ],
    educations: [
      {
        degree: "",
        institute: "",
        startYear: "",
        endYear: "",
        description: "",
      },
    ],
    skills: [],
    certifications: [
      {
        image: "",
        name: "",
        year: "",
      },
    ],
  });

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  const [data, setData] = useState({
    meetingPackageIds: [
      {
        packageId: 1,
        quantity: 1,
        isRecurring: false,
        resources: [],
      },
    ],
    spacePackageIds: [
      {
        packageId: 1,
        quantity: 1,
        isRecurring: false,
      },
    ],

    startTime: getCurrentDate(),
    meetingPackageIds: [],
    carryForward: "",
    companyShare: "",
    discount: "",
  });

  const [rows, setRows] = useState([
    {
      packageId: 0,
      quantity: 1,
      isRecurring: false,
      resources: [],
    },
  ]);
  const [meetingRows, setMeetingRows] = useState([
    {
      packageId: 0,
      quantity: 1,
      isRecurring: false,
    },
  ]);
  const handleAddRow = () => {
    const newRow = {
      packageId: 0,
      quantity: 1,
      isRecurring: false,
      resources: [],
    };
    setRows([...rows, newRow]);
  };

  const updateRowResources = (selectedResources, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].resources = selectedResources?.map(
      (resource) => resource.id
    );
    setRows(updatedRows);
  };
  const handleAddMeetingRow = () => {
    const newRow = {
      packageId: 0,
      quantity: 1,
      isRecurring: false,

      // price: FetchMeetingPackageData?.[0]?.price,
      // amount: FetchMeetingPackageData?.[0]?.price,
    };
    setMeetingRows([...meetingRows, newRow]);
  };
  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleRemoveMeetingRow = (index) => {
    const updatedRows = [...meetingRows];
    updatedRows.splice(index, 1);
    setMeetingRows(updatedRows);
  };

  // const handleInputChange = (event, index) => {
  //   let data = [...rows];
  //   data[index][event.target.name] = event.target.value;

  //   setRows(data);
  // };
  const handleInputChange = (event, index) => {
    let data = [...rows];
    data[index].packageId = event.id;
    setRows(data);
  };
  const handleRowQuantity = (event, index) => {
    let data = [...rows];
    data[index].quantity = event.target.value;
    setRows(data);
  };

  // const handleMeetingInputChange = (event, index) => {
  //   let data = [...meetingRows];
  //   data[index][event.target.name] = event.target.value;
  //   setMeetingRows(data);
  // };
  const handleMeetingInputChange = (event, index) => {
    let data = [...meetingRows];
    data[index].packageId = event.id;
    setMeetingRows(data);
  };
  const handlePackageRecurring = (event, index) => {
    let data = [...rows];
    data[index].isRecurring = !data[index].isRecurring;
    setRows(data);
  };
  const handleMeetingPackageRecurring = (event, index) => {
    let data = [...meetingRows];
    data[index].isRecurring = !data[index].isRecurring;
    setMeetingRows(data);
  };
  const handleMeetingQuantityChanged = (event, index) => {
    let data = [...meetingRows];
    data[index].quantity = event.target.value;
    setMeetingRows(data);
  };

  const handleChange = (event) => {
    setData({ ...data, startTime: event.target.value });
  };

  function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  const postAsignPackage = (e) => {
    e.preventDefault();
    const { spacePackageIds, meetingPackageIds, discount, startTime } = data;
    // let timeStampStartTime = toTimestamp(startTime);
    // const updateSpacePackageIds = rows?.map((item) => ({
    //   quantity: parseInt(item.quantity),
    //   package: parseInt(item.packageId),
    // }));

    // const updateMeetingPackageIds = meetingRows?.map((item) => ({
    //   quantity: parseInt(item.quantity),
    //   package: parseInt(item.packageId),
    // }));
    const updateSpacePackageIds = rows
      ?.map((item) => ({
        quantity: parseInt(item.quantity),
        package: parseInt(item.packageId),
        isRecurring: item.isRecurring,
        resources: item.resources,
      }))
      .filter((item) => item.quantity !== 0 && item.package !== 0);

    const updateMeetingPackageIds = meetingRows
      ?.map((item) => ({
        quantity: parseInt(item.quantity),
        package: parseInt(item.packageId),
        isRecurring: item.isRecurring,
      }))
      .filter((item) => item.quantity !== 0 && item.package !== 0);

    let updateData = {
      member: userResponse,
      space: spaceId || currentSpaceId,
      packages: updateSpacePackageIds,
      meeting_packages: updateMeetingPackageIds,
      start_date: startTime,
      discount: parseInt(discount),
    };
    setApiSpinner(true);

    assignPackage({
      spaceId: spaceId || currentSpaceId,
      data: updateData,
    });
  };

  const handlePackages = (e) => {
    setData({
      ...data,
      spacePackageIds: Array.isArray(e) ? e.map((x) => x.id) : [],
    });
  };

  const handleMeeting = (e) => {
    setData({
      ...data,
      meetingPackageIds: Array.isArray(e) ? e.map((x) => x.id) : [],
    });
  };

  const [show, setShow] = useState(false);
  const [allDone, setAllDone] = useState(false);

  const [validation, setValidation] = useState({
    name: false,
    email: false,
    phoneNo: false,
    // cnicNo: false,
    // experienceYears: false,
    // description: false,
    // address: false,
    // profileImg: false,
    // cnicImage: false,
  });

  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);

  const [certiFiles, setcertiFiles] = useState([]);

  const [previousForm, setPreviousForm] = useState(true);

  const handleClose = () => {
    setIsProfileImageUploading(false);
    setIsCnicUploading(false);
    setIsCnicBackUploading(false);
    setShow(false);
    resetForm();
    setFloor("");
    setRows([
      {
        packageId: 0,
        quantity: 1,
      },
    ]);
    setMeetingRows([
      {
        packageId: 0,
        quantity: 1,
      },
    ]);
  };
  const handleShow = () => setShow(true);
  const currentDateTime = new Date().toISOString().slice(0, 16);
  const handle = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const addItem = (e) => {
    e.preventDefault();
    setUserData({
      ...userData,
      educations: [
        ...userData.educations,
        {
          degree: "",
          institute: "",
          startYear: "",
          endYear: "",
          description: "",
        },
      ],
    });
  };

  const addCertificaions = (e) => {
    e.preventDefault();
    setUserData({
      ...userData,
      certifications: [
        ...userData.certifications,
        { image: "", name: "", year: "" },
      ],
    });
  };

  const handleFormChange = (index, event) => {
    let edu = [...userData.educations];
    edu[index][event.target.name] = event.target.value;
    setUserData({ ...userData, educations: edu });
  };

  const handleFormCertifications = (index, event) => {
    let cert = [...userData.certifications];
    cert[index][event.target.name] = event.target.value;
    setUserData({ ...userData, certifications: cert });
  };

  // const uploadImg = (index, e) => {
  //   let cert = [...userData.certifications];
  //   const files = e.target.files;

  //   if (index < certiFiles?.length) {
  //     certiFiles.splice(index, 1);
  //   }

  //   setcertiFiles([...certiFiles, URL.createObjectURL(files[0])]);

  //   const formData = new FormData();
  //   formData.append("img", files[0]);
  //   axios.post("/uploadImage/", formData).then((resp) => {
  //     cert[index].image = resp.data;
  //     setUserData({
  //       ...userData,
  //       certifications: cert,
  //     });
  //   });
  // };

  const removeItem = (index) => {
    const list = [...userData.educations];
    list.splice(index, 1);
    setUserData({ ...userData, educations: list });
  };

  const removeCertifications = (index) => {
    const list = [...userData.certifications];
    list.splice(index, 1);
    setUserData({ ...userData, certifications: list });
  };

  const profileUrls = (e) => {
    setUserData({
      ...userData,
      profileUrls: [
        {
          ...userData.profileUrls[0],
          [e.target.name]: {
            url: e.target.value,
            public: "",
          },
        },
      ],
    });
  };

  const ali = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  const ali2 = (e) => {
    e.preventDefault();
    document.getElementById("real-file2").click();
  };
  const cnicBack = (e) => {
    e.preventDefault();
    document.getElementById("cnic-back").click();
  };

  const ali3 = (e) => {
    e.preventDefault();
    document.getElementById("real-file3").click();
  };
  const [uploadedImageUrls, setUploadedImageUrls] = useState(null);
  const [uploadedImageUrls2, setUploadedImageUrls2] = useState(null);
  const [uploadedImageUrls3, setUploadedImageUrls3] = useState(null);
  const [isCnicBackUploading, setIsCnicBackUploading] = useState(false);

  const [fileCnicBack, setFileCnicBack] = useState("");
  useEffect(() => {
    if (file !== null && file !== "") {
      setIsProfileImageUploading(true);
      if (file.size >= 1e7) {
        errorAlertImage("File is too big!");
        setFile(null);
        setIsProfileImageUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();

        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUploadedImageUrls(resp.data?.[0]);
            setIsProfileImageUploading(false);
          })
          .catch((error) => {
            setIsProfileImageUploading(false);

            setFile(null);
          });
        setError(false);
      } else {
        setFile(null);
        setIsProfileImageUploading(false);

        requiredAlert("Please Select Image File");
      }
    }
  }, [file]);
  useEffect(() => {
    setUserData({ ...userData, profileImg: uploadedImageUrls });
  }, [uploadedImageUrls]);

  useEffect(() => {
    if (file2 !== null && file2 !== "") {
      setIsCnicUploading(true);
      if (file2.size >= 1e7) {
        errorAlertImage("File is too big!");
        setFile2(null);
        setIsCnicUploading(false);
      } else if (file2.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file2);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUploadedImageUrls2(resp.data?.[0]);
            setIsCnicUploading(false);
          })
          .catch((error) => {
            setIsCnicUploading(false);

            setFile2(null);
          });
        setError(false);
      } else {
        setFile2(null);
        setIsCnicUploading(false);

        requiredAlert("Please Select Image File");
      }
    }
  }, [file2]);
  useEffect(() => {
    setUserData({ ...userData, cnicImage: uploadedImageUrls2 });
  }, [uploadedImageUrls2]);

  useEffect(() => {
    if (fileCnicBack !== null && fileCnicBack !== "") {
      setIsCnicBackUploading(true);
      if (fileCnicBack.size >= 1e7) {
        errorAlertImage("File is too big!");
        setFileCnicBack(null);
        setIsCnicBackUploading(false);
      } else if (fileCnicBack.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", fileCnicBack);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUploadedImageUrls3(resp.data?.[0]);
            setIsCnicBackUploading(false);
          })
          .catch((error) => {
            setIsCnicBackUploading(false);

            setFileCnicBack(null);
          });
        setError(false);
      } else {
        setFileCnicBack(null);
        setIsCnicUploading(false);

        requiredAlert("Please Select Image File");
      }
    }
  }, [fileCnicBack]);
  useEffect(() => {
    setUserData({ ...userData, cnicBack: uploadedImageUrls3 });
  }, [uploadedImageUrls3]);

  // useEffect(() => {
  //   if (!file3 == "") {
  //     if (file3.size >= 1e7) {
  //       errorAlertImage("File is too big!");
  //       setFile3("");
  //     } else {
  //     }
  //   }
  //   const fd = new FormData();
  //   fd.append("image", file3);
  //   axios.post("/uploadImage/", fd).then((resp) => {
  //     setUserData({ ...userData, domicileImage: resp.data });
  //   });
  // }, [file3]);

  const handleSkills = (e) => {
    setUserData({
      ...userData,
      skills: Array.isArray(e) ? e.map((x) => x.id) : [],
    });
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      !userData?.name?.length ||
      !userData?.phoneNo?.length ||
      !userData?.email ||
      !emailRegex?.test(userData?.email)
      // ||
      // !userData?.experienceYears ||
      // !userData?.address
    ) {
      setAllDone(true);
    } else {
      setAllDone(false);
    }
  }, [userData]);

  const [postSpaceUser, resultStats] = useCreateUserForSpaceMutation();

  const submitForm = (e) => {
    e.preventDefault();
    // if (!file) {
    //   setError(true);
    // } else {
    const {
      email,
      name,
      phoneNo,
      cnicNo,
      description,
      profileImg,
      cnicImage,
      cnicBack,
      address,
      experienceYears,
      securityDeposit,
    } = userData;

    let data = {
      email,
      name,
      phoneNo,
      cnicNo,
      description,
      profileImg,
      securityDeposit,
      address,
      cnicImage,
      cnicBack,
      experienceYears: parseInt(experienceYears),
      room: parseInt(roomId),
      space: spaceId || currentSpaceId,
      company: false,
    };
    if (data?.phoneNo?.length < 11 || data?.phoneNo?.length > 15) {
      return;
    }

    setApiSpinner(true);

    postSpaceUser({ spaceId: spaceId || currentSpaceId, data: data });
    // }
  };
  // const navigate = useNavigate();
  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }

    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      if (resultStats?.data?.id) {
        setuserResponse(resultStats?.data?.id);
      } else {
        setuserResponse(resultStats?.data?.userId);
      }
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats1?.isError) {
      setApiSpinner(false);

      const errorMessage = Object?.values(resultStats1?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }

    if (resultStats1?.isSuccess) {
      setApiSpinner(false);

      handleClose();
      resetForm();
      // setTimeout(() => {
      //   setUserData({
      //     ...userData,
      //     email: "",
      //     name: "",
      //     address: "",
      //     phoneNo: "",
      //     cnicNo: "",
      //     description: "",
      //     profileImg: "",
      //     cnicImage: "",
      //     experienceYears: "",
      //   });
      //   file("");
      //   setuserResponse("");
      //   file2("");
      // }, 1500);

      // navigate("/member/list");
    }
  }, [resultStats1]);

  const handleForm = () => {
    setPreviousForm(false);
  };

  const upload = (index, e) => {
    e.preventDefault();
    document.getElementById(`file${index}`).click();
  };

  const handleFocus = (e) => {
    setValidation({ ...validation, [e.target.name]: true });
  };
  const handleDiscountChange = (event) => {
    setData({ ...data, discount: event.target.value });
  };
  const resetForm = () => {
    setUserData({
      packageId: FetchPackageData?.[0]?.id,
      email: "",
      name: "",
      phoneNo: "",
      cnicNo: "",
      gender: "",
      age: "",
      description: "",
      profileImg: "",
      cnicImage: "",
      cnicBack: "",
      domicile: "",
      securityDeposit: "",
      domicileImage: "",
      experienceYears: "",
      address: "",
      profileUrls: [
        {
          facebook: {
            url: "facebook",
            public: "",
          },
          upwork: {
            url: "",
            public: "",
          },
          fiverr: {
            url: "",
            public: "",
          },
        },
      ],
      educations: [
        {
          degree: "",
          institute: "",
          startYear: "",
          endYear: "",
          description: "",
        },
      ],
      skills: [],
      certifications: [
        {
          image: "",
          name: "",
          year: "",
        },
      ],
    });
    setSelectedSkills([]);
    setHasOtherSkill(false);
    setData({
      packageId: FetchPackageData?.[0]?.id,
      spacePackageIds: [],
      startTime: currentDate.toISOString().slice(0, 16),
      meetingPackageIds: [],
      carryForward: "",
      companyShare: "",
      discount: "",
    });
    setValidation({
      name: false,
      email: false,
      phoneNo: false,
      // address: false,
      // profileImg: false,
      // experienceYears: false,
    });
    setFile("");
    setFile2("");
    setFile3("");
    setFileCnicBack("");
    setcertiFiles([]);
    setPreviousForm(true);
    setuserResponse("");
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={membersPermissions[0]?.create ? handleShow : null}
        className="send-invoice-btn"
      >
        {membersPermissions[0]?.create ? (
          <span className="invoice">
            <Plus /> Add User
          </span>
        ) : (
          <span className="invoice">
            <Plus /> Add User
            <Lock />
          </span>
        )}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        className={previousForm ? "" : "largeForm"}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">Create a User</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            {!userResponse && (
              <section>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="John Doe"
                    name="name"
                    value={userData.name}
                    onChange={handle}
                    pattern="^[A-Za-z ][A-Za-z0-9!@#$%^&* ]*$"
                    required
                    onBlur={handleFocus}
                    focused={validation.name.toString()}
                  />
                  <span className="error">Name Must Have Alphabets!</span>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="abc@gmail.com"
                    name="email"
                    value={userData.email}
                    onChange={handle}
                    required
                    onBlur={handleFocus}
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    focused={validation.email.toString()}
                  />
                  <span className="error">Write a correct email!</span>
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    // name="phoneNo"
                    value={userData.phoneNo}
                    // onChange={handleInfo}
                    // min={0}
                    required
                    // pattern="\d{11,15}"
                    onChange={(e) => {
                      setUserData({ ...userData, phoneNo: e });
                    }}
                  />
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Enter 11-15 Digits Phone Number"
                    name="phoneNo"
                    value={userData.phoneNo}
                    onChange={handle}
                    required
                    min={0}
                    onBlur={handleFocus}
                    // pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
                    // focused={validation.phoneNo.toString()}
                    pattern="\d{11,15}"
                    onKeyDown={(e) => {
                      const allowedKeys = ["Backspace", "Delete"];
                      if (
                        !/[0-9]/.test(e.key) &&
                        !allowedKeys.includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    focused={validation.phoneNo.toString()}
                  /> */}

                  <span className="error">
                    Write 11-15 digits phone number!
                  </span>
                </div>

                <div className="form-group">
                  <label htmlFor="phoneNo">Security Deposit</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{currency}</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Amount"
                      name="securityDeposit"
                      value={userData.securityDeposit}
                      onChange={handle}
                      required
                      min={0}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="address">
                    Address <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="address"
                    className="form-control"
                    placeholder="ABC Street, XYZ Country"
                    name="address"
                    value={userData.address}
                    onChange={handle}
                    // required
                    // onBlur={handleFocus}
                    // focused={validation.phoneNo.toString()}
                  />
                  {/* <span className="error">Enter Your Address!</span> */}
                </div>

                <div className="form-group">
                  <label htmlFor="phone">
                    ID Card Number <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="xxxxx-xxxxxxx-x"
                    name="cnicNo"
                    value={userData.cnicNo}
                    onChange={handle}
                    // required
                    onBlur={handleFocus}
                    pattern="\d{13}"
                    onKeyDown={(e) => {
                      const allowedKeys = ["Backspace", "Delete"];
                      if (
                        !/[0-9]/.test(e.key) &&
                        !allowedKeys.includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    focused={validation?.cnicNo?.toString()}
                  />

                  <span className="error">
                    Enter 13 Digits ID Card Number Without Dashes!
                  </span>
                </div>
                <div className="form-group">
                  <label htmlFor="description">
                    About <span className="optional">(Optional)</span>
                  </label>
                  <textarea
                    rows={5}
                    className="form-control"
                    name="description"
                    value={userData?.description}
                    onChange={handle}
                    // onBlur={handleFocus}
                    // required
                    // focused={validation.description.toString()}
                  />
                  {/* <span className="error">Pleaae Enter Valid Input</span> */}
                </div>
                <div className="form-group">
                  <label htmlFor="experienceYears">
                    Expirence years <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="experienceYears"
                    value={userData?.experienceYears}
                    onChange={handle}
                    required
                    onBlur={handleFocus}
                    // pattern="^[0-9]{5}-[0-9]{7}-[0-9]$"
                    min="0"
                    onKeyDown={(e) => {
                      const allowedKeys = ["Backspace", "Delete"];
                      if (
                        !/[0-9]/.test(e.key) &&
                        !allowedKeys.includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    // focused={validation?.experienceYears.toString()}
                  />
                  {/* <span className="error">Pleaae Add Experience Years</span> */}
                </div>
                <div className="form-group">
                  <label className="fs-6 mb-2">
                    Profile Picture <span className="optional">(Optional)</span>
                  </label>
                  <div className="media">
                    <div className="media-aside align-self-start">
                      <img
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : "https://placehold.jp/90x90.png"
                        }
                        style={{
                          width: "90px",
                          height: "90px",
                          objectFit: "cover",
                        }}
                        className="rounded"
                        alt=""
                      />
                    </div>
                    <div>
                      <input
                        id="real-file"
                        name="file"
                        type="file"
                        hidden="hidden"
                        required
                        onChange={(event) => setFile(event.target.files[0])}
                      />
                    </div>
                    <div className="media-body mt-2">
                      {isProfileImageUploading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Uploading...</span>
                        </div>
                      ) : (
                        <button
                          id="custom-button"
                          onClick={ali}
                          className="btn btn-secondary me-2 btn-sm"
                        >
                          Upload
                        </button>
                      )}
                      <p className="card-text mt-2">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "}
                        &nbsp;Allowed Max size of 10Mb
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              Allowed Max size of 10Mb
                            </Tooltip>
                          }
                        >
                          <Button variant="white" className="mx-2 p-0">
                            <Icon.InfoCircle
                              style={{ color: "currentcolor" }}
                            />
                          </Button>
                        </OverlayTrigger> */}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="fs-6 mb-2">
                    Cnic Picture Front{" "}
                    <span className="optional">(Optional)</span>
                  </label>
                  <div className="media">
                    <div className="media-aside align-self-start">
                      <img
                        src={
                          file2
                            ? URL.createObjectURL(file2)
                            : "https://placehold.jp/90x90.png"
                        }
                        style={{
                          width: "90px",
                          height: "90px",
                          objectFit: "cover",
                        }}
                        className="rounded"
                        alt=""
                      />
                    </div>
                    <div>
                      <input
                        id="real-file2"
                        name="file"
                        type="file"
                        hidden="hidden"
                        onChange={(event) => setFile2(event.target.files[0])}
                      />
                    </div>
                    <div className="media-body mt-2">
                      {isCnicUploading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Uploading...</span>
                        </div>
                      ) : (
                        <button
                          id="custom-button"
                          onClick={ali2}
                          className="btn btn-secondary me-2 btn-sm"
                        >
                          Upload
                        </button>
                      )}
                      <p className="card-text mt-2">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "}
                        &nbsp; Allowed Max size of 10Mb
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              Allowed Max size of 10Mb
                            </Tooltip>
                          }
                        >
                          <Button variant="white" className="mx-2 p-0">
                            <Icon.InfoCircle
                              style={{ color: "currentcolor" }}
                            />
                          </Button>
                        </OverlayTrigger> */}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="fs-6 mb-2">
                    Cnic Picture Back{" "}
                    <span className="optional">(Optional)</span>
                  </label>
                  <div className="media">
                    <div className="media-aside align-self-start">
                      <img
                        src={
                          fileCnicBack
                            ? URL.createObjectURL(fileCnicBack)
                            : "https://placehold.jp/90x90.png"
                        }
                        style={{
                          width: "90px",
                          height: "90px",
                          objectFit: "cover",
                        }}
                        className="rounded"
                        alt=""
                      />
                    </div>
                    <div>
                      <input
                        id="cnic-back"
                        name="file"
                        type="file"
                        hidden="hidden"
                        onChange={(event) =>
                          setFileCnicBack(event.target.files[0])
                        }
                      />
                    </div>
                    <div className="media-body mt-2">
                      {isCnicBackUploading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Uploading...</span>
                        </div>
                      ) : (
                        <button
                          id="custom-button"
                          onClick={cnicBack}
                          className="btn btn-secondary me-2 btn-sm"
                        >
                          Upload
                        </button>
                      )}
                      <p className="card-text mt-2">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "}
                        &nbsp; Allowed Max size of 10Mb
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              Allowed Max size of 10Mb
                            </Tooltip>
                          }
                        >
                          <Button variant="white" className="mx-2 p-0">
                            <Icon.InfoCircle
                              style={{ color: "currentcolor" }}
                            />
                          </Button>
                        </OverlayTrigger> */}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {userResponse && (
              <>
                <div className="form-group">
                  <label htmlFor="startTime">Joining Date:</label>
                  <div className="input-group ">
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        style={{
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                        }}
                      >
                        <Icon.Calendar2Check />
                      </span>
                    </div>
                    <input
                      type="date"
                      name="startTime"
                      id="startTime"
                      className="form-control"
                      value={data?.startTime}
                      onChange={handleChange}
                      min={getCurrentDate()}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="package-facilities" className="mb-1">
                    Assign Space Package(s)
                  </label>

                  {FetchPackageData?.length > 0 &&
                    rows?.map((input, index) => (
                      <div key={index} className="repeater-wrapper my-2">
                        <div className="">
                          <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                            <div className="row w-100 pe-lg-0 pe-3">
                              <div className="col-12 mb-lg-0 mb-2 mt-lg-0">
                                <p className="card-text col-title mb-md-50 mb-0">
                                  Select Package to Assign
                                </p>
                                {/* <select
                                name="packageId"
                                value={input?.packageId}
                                onChange={(e) => handleInputChange(e, index)}
                                className="form-control"
                              >
                                {FetchPackageData?.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            <div className="col-12 my-lg-0 my-2">
                              <p className="card-text col-title mb-0 mt-2">
                                Number of Packages/Seats
                              </p>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="1"
                                min={1}
                                name="quantity"
                                value={input?.quantity}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </div> */}
                                <Select
                                  name="packageId"
                                  options={FetchPackageData}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  styles={colorStyles}
                                  value={FetchPackageData?.find(
                                    (obj) => obj.id === rows?.[index]?.packageId
                                  )}
                                  onChange={(selectedOption) =>
                                    handleInputChange(selectedOption, index)
                                  }
                                />
                              </div>

                              <div className="form-check mt-3">
                                <label
                                  className="form-check-label"
                                  htmlFor="recurringPackage"
                                >
                                  Is this Package Recurring!
                                </label>
                                <input
                                  type="checkbox"
                                  className="form-check-input ml-4"
                                  id="recurringPackage"
                                  checked={input?.isRecurring}
                                  onChange={(e) =>
                                    handlePackageRecurring(e, index)
                                  }
                                />
                              </div>

                              {/* <div className="col-12 my-lg-0 my-2">
                                <p className="card-text col-title mb-0 mt-2">
                                  Number of Packages/Seats
                                </p>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="1"
                                  min={1}
                                  name="quantity"
                                  value={input?.quantity}
                                  onChange={(e) => handleRowQuantity(e, index)}
                                />
                              </div>  */}
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                              <p
                                className="bg-white"
                                onClick={() => handleRemoveRow(index)}
                              >
                                <CancelIcon />
                              </p>
                            </div>
                          </div>
                        </div>
                        {input?.packageId ? (
                          <AssignOccupancy
                            packageId={input?.packageId}
                            packageQuantity={input?.quantity}
                            AllSpacePackages={FetchPackageData}
                            // Resources={Resources}
                            Resources={FetchResources}
                            SpaceTypes={SpaceTypes}
                            updateRowResources={(selectedResources) =>
                              updateRowResources(selectedResources, index)
                            }
                            // updateRowResources={updateResourcesCallback}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  {FetchPackageData?.length <= 0 && (
                    <p>No Package is Available</p>
                  )}
                  <div className="">
                    <div className="px-0 mt-3">
                      <button
                        type="button"
                        className="btn btn-link send-invoice-btn"
                        data-repeater-create=""
                        onClick={handleAddRow}
                      >
                        Associate More Packages
                      </button>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="meetingPackageIds" className="mb-1">
                    Assign Meeting Room Package(s)
                  </label>

                  {FetchMeetingPackageData?.length > 0 &&
                    meetingRows?.map((input, index) => (
                      <div key={index} className="repeater-wrapper my-2">
                        <div className="">
                          <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                            <div className="row w-100 pe-lg-0 pe-3">
                              <div className="col-12 mb-lg-0 mb-2 mt-lg-0">
                                <p className="card-text col-title mb-md-50 mb-0">
                                  Select Package to Assign
                                </p>
                                {/* <select
                                name="packageId"
                                value={input?.packageId}
                                onChange={(e) =>
                                  handleMeetingInputChange(e, index)
                                }
                                className="form-control"
                              >
                                {FetchMeetingPackageData?.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            <div className="col-12 my-lg-0 my-2">
                              <p className="card-text col-title mb-0 mt-2">
                                Number of Packages/Seats
                              </p>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="1"
                                min={1}
                                name="quantity"
                                value={input?.quantity}
                                onChange={(e) =>
                                  handleMeetingInputChange(e, index)
                                }
                              />
                            </div> */}
                                <Select
                                  name="packageId"
                                  options={FetchMeetingPackageData}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  styles={colorStyles}
                                  value={FetchMeetingPackageData?.find(
                                    (obj) =>
                                      obj.id === meetingRows?.[index]?.packageId
                                  )}
                                  onChange={(selectedOption) =>
                                    handleMeetingInputChange(
                                      selectedOption,
                                      index
                                    )
                                  }
                                />
                              </div>

                              <div className="form-check mt-3">
                                <label
                                  className="form-check-label"
                                  htmlFor="recurringPackage"
                                >
                                  Is this Package Recurring!
                                </label>
                                <input
                                  type="checkbox"
                                  className="form-check-input ml-4"
                                  id="recurringPackage"
                                  checked={input?.isRecurring}
                                  onChange={(e) =>
                                    handleMeetingPackageRecurring(e, index)
                                  }
                                />
                              </div>

                              {/* <div className="col-12 my-lg-0 my-2">
                                <p className="card-text col-title mb-0 mt-2">
                                  Number of Packages/Seats
                                </p>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="1"
                                  min={1}
                                  name="quantity"
                                  value={input?.quantity}
                                  onChange={(e) =>
                                    handleMeetingQuantityChanged(e, index)
                                  }
                                />
                              </div> */}
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                              <p
                                className="bg-white"
                                onClick={() => handleRemoveMeetingRow(index)}
                              >
                                <CancelIcon />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {FetchMeetingPackageData?.length <= 0 && (
                    <p>No Package is Available</p>
                  )}
                  <div className="">
                    <div className="px-0 mt-3">
                      <button
                        type="button"
                        className="btn btn-link send-invoice-btn"
                        data-repeater-create=""
                        onClick={handleAddMeetingRow}
                      >
                        Associate More Packages
                      </button>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="discount">Discount (%):</label>
                  <input
                    type="number"
                    name="discount"
                    id="discount"
                    placeholder="Discount %"
                    className="form-control"
                    value={data?.discount}
                    onChange={handleDiscountChange}
                    min={0}
                  />
                </div>
              </>
            )}

            {!userResponse && (
              <div className="mt-4">
                {/* <Button variant="primary" onClick={handleClose}>
                  Close
                </Button> */}
                <Button
                  variant="primary"
                  onClick={submitForm}
                  disabled={allDone || apiSpinner}
                >
                  Add Individual
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>

                <button
                  className="btn btn-secondary ml-3"
                  disabled={apiSpinner}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}

            {userResponse && (
              <Button
                variant="primary"
                disabled={apiSpinner}
                onClick={postAsignPackage}
              >
                Assign Package{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
            )}

            {/* <section style={{ display: previousForm ? "none" : null }}>
              <div className="form-group">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <label htmlFor="" className="mb-1">
                      Gender:
                    </label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="Male"
                        value="Male"
                        onChange={handle}
                      />
                      <label className="form-check-label" htmlFor="Male">
                        Male
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="feMale"
                        value="Female"
                        onChange={handle}
                      />
                      <label className="form-check-label" htmlFor="feMale">
                        Female
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="age">Age</label>
                    <input
                      type="number"
                      name="age"
                      id="age"
                      placeholder="15"
                      className="form-control"
                      value={userData.age}
                      onChange={handle}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="fs-6 mb-2">Domicilie Picture</label>
                <div className="media">
                  <div className="media-aside align-self-start">
                    <img
                      src={
                        file3
                          ? URL.createObjectURL(file3)
                          : "https://placehold.jp/90x90.png"
                      }
                      style={{
                        width: "90px",
                        height: "90px",
                        objectFit: "cover",
                      }}
                      className="rounded"
                      alt=""
                    />
                  </div>
                  <div>
                    <input
                      id="real-file3"
                      name="file"
                      type="file"
                      hidden="hidden"
                      onChange={(event) => setFile3(event.target.files[0])}
                    />
                  </div>
                  <div className="media-body mt-2">
                    <button
                      id="custom-button"
                      onClick={ali3}
                      className="btn btn-primary me-2 btn-sm"
                    >
                      Upload
                    </button>
                    <p className="card-text mt-2">Allowed Max size of 10Mb</p>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="domicile">Domicile</label>
                    <input
                      type="text"
                      name="domicile"
                      onChange={handle}
                      id="domicile"
                      placeholder="Punjab"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="domicile">Address</label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Lahore"
                      className="form-control"
                      value={userData.address}
                      onChange={handle}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="facebook">Facebook Link</label>
                    <input
                      type="url"
                      id="facebook"
                      placeholder="www.com"
                      className="form-control"
                      name="facebook"
                      value={userData.profileUrls[0].facebook.url}
                      onChange={profileUrls}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="upwork">Upwork Link</label>
                    <input
                      type="url"
                      name="upwork"
                      id="upwork"
                      placeholder="www.com"
                      className="form-control"
                      value={userData.profileUrls[0].upwork.url}
                      onChange={profileUrls}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="fiverr">Fiverr Link</label>
                    <input
                      type="url"
                      name="fiverr"
                      id="fiverr"
                      placeholder="www.com"
                      className="form-control"
                      value={userData.profileUrls[0].fiverr.url}
                      onChange={profileUrls}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                {userData?.educations.map((item, index) => {
                  return (
                    <div className="form-group" key={index}>
                      <h5>Education {index + 1}</h5>
                      <div className="row">
                        <div className="col-md-5">
                          <label htmlFor="degree">Degree</label>
                          <input
                            type="text"
                            name="degree"
                            id="degree"
                            placeholder="Matric"
                            className="form-control"
                            value={item.degree}
                            onChange={(event) => handleFormChange(index, event)}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="startYear">Start Year</label>
                          <input
                            type="number"
                            id="startYear"
                            placeholder="2016"
                            name="startYear"
                            value={item.startYear}
                            onChange={(event) => handleFormChange(index, event)}
                            className="form-control w-75"
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="endYear">End Year</label>
                          <input
                            type="number"
                            id="endYear"
                            name="endYear"
                            value={item.endYear}
                            onChange={(event) => handleFormChange(index, event)}
                            placeholder="2019"
                            className="form-control w-75"
                          />
                        </div>
                        <div className="col-md-1">
                          <CancelIcon index={() => removeItem(index)} />
                        </div>
                        <div className="col-md-12 mt-3">
                          <textarea
                            name="description"
                            value={item.description}
                            onChange={(event) => handleFormChange(index, event)}
                            id="description"
                            rows="4"
                            className="form-control"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <button className="btn btn-primary" onClick={addItem}>
                  Add
                </button>
              </div>

              <div className="form-group">
                <label htmlFor="package-facilities" className="mb-1">
                  Skills (Select Multiple)
                </label>
                <Select
                  options={skills}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  value={selectedSkills}
                  styles={colorStyles}
                  onChange={handleSkillsChange}
                  isMulti
                />
                {hasOtherSkill && (
                  <input
                    type="text"
                    ref={inputRef}
                    className="form-control mt-2"
                    value={selectedSkills[0]?.name}
                    onChange={handleOtherSkillChange}
                  />
                )}
              </div>

              <div className="form-group">
                {userData?.certifications.map((item, index) => {
                  return (
                    <div className="form-group" key={index}>
                      <h5>Certification {index + 1}</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="media">
                            <div className="media-aside align-self-start">
                              <img
                                src={
                                  userData?.certifications[index].image
                                    ? certiFiles[index]
                                    : "https://placehold.jp/90x90.png"
                                }
                                style={{
                                  width: "90px",
                                  height: "90px",
                                  objectFit: "cover",
                                }}
                                alt="certifiles"
                                className="rounded"
                              />
                            </div>
                            <div>
                              <input
                                id={`file${index}`}
                                name="file"
                                type="file"
                                hidden="hidden"
                                onChange={(e) => uploadImg(index, e)}
                              />
                            </div>
                            <div className="media-body mt-2">
                              <button
                                id="custom-button"
                                onClick={(e) => upload(index, e)}
                                className="btn btn-primary me-2 btn-sm"
                              >
                                Upload
                              </button>
                              <p className="card-text mt-2">
                                Allowed Max size of 10Mb
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <label htmlFor="certiName">Name</label>
                          <input
                            type="text"
                            name="name"
                            id="certiName"
                            placeholder="matric"
                            className="form-control"
                            value={item.name}
                            onChange={(event) =>
                              handleFormCertifications(index, event)
                            }
                          />
                        </div>
                        <div className="col-md-5">
                          <label htmlFor="certiYear">End Year</label>
                          <input
                            type="number"
                            id="certiYear"
                            name="year"
                            value={item.year}
                            onChange={(event) =>
                              handleFormCertifications(index, event)
                            }
                            placeholder="2019"
                            className="form-control w-75"
                          />
                        </div>
                        <div className="offset-md-1 col-md-1">
                          <CancelIcon
                            index={() => removeCertifications(index)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <button className="btn btn-primary" onClick={addCertificaions}>
                  Add
                </button>
              </div>
            </section> */}
          </Modal.Body>
          {/* <Modal.Footer> */}

          {/* )} */}
          {/* <Button variant="primary" onClick={handleForm}>
              {previousForm ? "Save & Continue" : "Add"}
            </Button> */}
          {/* </Modal.Footer> */}
        </form>
      </Modal>
    </>
  );
};

export default UserForSpace;
