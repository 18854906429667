import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { Amenities, Select } from "./";
import { errorAlertImage } from "../../utils/Alerts";
import { DataOfTime } from "../../utils/constant";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { DashLg } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import {
  useGetCurrencyQuery,
  useUpdateSpaceMutation,
} from "../../Slices/spaceApi";
import TimeHours from "./timeHours.json";
import Logo1 from "../../Assets/dummyImages/Logo1.png";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../../Context/GlobalContext";
import ColorPicker from "./ColorPicker";
import OperatingHours from "./OperatingHours";
import BillingConfiguration from "./BillingConfiguration";
import Currency from "./Currency";
import TaxConfiguration from "./TaxConfiguration";

function BookingApproval({ spaceData }) {
  const { settingsPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();

  const [bookingApprovalChanged, setBookingApprovalChanged] = useState(false);

  const [approveMemberBooking, setApproveMemberBooking] = useState(
    spaceData?.auto_approve_member_booking
  );
  const [approvePublicBooking, setApprovePublicBooking] = useState(
    spaceData?.auto_approve_public_booking
  );

  const handleApproveMemberBooking = () => {
    // setApproveMemberBooking(!approveMemberBooking);
    setApproveMemberBooking((prevValue) => {
      const newValue = !prevValue;
      return newValue;
    });
    setBookingApprovalChanged(true);
  };

  const handleApprovePublicBooking = () => {
    setApprovePublicBooking((prevValue) => {
      const newValue = !prevValue;
      return newValue;
    });
    setBookingApprovalChanged(true);
  };

  const handleBookingApprovalUpdate = () => {
    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: {
        auto_approve_member_booking: approveMemberBooking,
        auto_approve_public_booking: approvePublicBooking,
      },
    });
  };

  const handleClose = () => {
    setBookingApprovalChanged(false);
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Booking Approvals Configured SuccessFully!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 1000);

      handleClose();
    }
  }, [resultStats]);
  return (
    <div>
      <div className="card">
        <div className="row p-4">
          <h4>Booking Approval Configuration</h4>

          <div className="form-group">
            <div className="d-flex">
              <small className="">Auto Approve Members Bookings</small>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch1"
                  label=""
                  className="ml-4"
                  value={approveMemberBooking}
                  checked={approveMemberBooking}
                  onChange={handleApproveMemberBooking}
                />
              </Form>
            </div>

            <div className="d-flex mt-3">
              <small className="">Auto Approve Public Bookings</small>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch2"
                  label=""
                  className="ml-5"
                  value={approvePublicBooking}
                  checked={approvePublicBooking}
                  onChange={handleApprovePublicBooking}
                />
              </Form>
            </div>

            <div className="row mt-5">
              <div className="col-md-12">
                <button
                  className="btn btn-primary mt-2"
                  disabled={
                    !bookingApprovalChanged || !settingsPermissions[0]?.update
                  }
                  onClick={
                    settingsPermissions[0]?.update
                      ? handleBookingApprovalUpdate
                      : null
                  }
                >
                  {settingsPermissions[0]?.update ? (
                    "Save Changes"
                  ) : (
                    <span>Save Changes{Lock2}</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingApproval;
