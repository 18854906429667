import React, { useEffect, useState } from "react";
import { Select } from "../AddInvoices";
import { colorStyles } from "../../utils/constant";
import {
  useCreateRoleMutation,
  useGetModulesQuery,
} from "../../Slices/spaceApi";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context/GlobalContext";
function AddRole() {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: Modules, isLoading: loading } = useGetModulesQuery();
  const [createRole, resultStats] = useCreateRoleMutation();
  const { setKey } = useGlobalContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [activeBox, setActiveBox] = useState(1);
  const [apiSpinner, setApiSpinner] = useState(false);

  const [selectedPermissionsWPM, setSelectedPermissionsWPM] = useState({
    floors: [],
    locations: [],
    resources: [],
    community: [],
    announcements: [],
  });
  const [selectedPermissionsPM, setSelectedPermissionsPM] = useState({
    team: [],
    employees: [],
    roles: [],
    visit: [],
  });
  const [selectedPermissionsAF, setSelectedPermissionsAF] = useState({
    invoices: [],
    members: [],
    packages: [],
    quotations: [],
    partnersAndOffers: [],
    tours: [],
    tickets: [],
  });
  const [selectedPermissionsMR, setSelectedPermissionsMR] = useState({
    bookings: [],
  });
  const [selectedPermissionsSettings, setSelectedPermissionsSettings] =
    useState({
      update: 0,
      read: 1,
    });

  const handlePermissionSelectChange = (
    selectedOptions,
    moduleNumber,
    type
  ) => {
    switch (moduleNumber) {
      case 1:
        if (selectedOptions.some((option) => option.value === "none")) {
          setSelectedPermissionsWPM({
            ...selectedPermissionsWPM,
            [type]: selectedOptions.filter((option) => option.value === "none"),
          });
        } else if (selectedOptions.some((option) => option.value === "all")) {
          setSelectedPermissionsWPM({
            ...selectedPermissionsWPM,
            [type]: selectedOptions.filter((option) => option.value === "all"),
          });
        } else {
          setSelectedPermissionsWPM({
            ...selectedPermissionsWPM,
            [type]: selectedOptions,
          });
        }
        break;
      case 2:
        if (selectedOptions.some((option) => option.value === "none")) {
          setSelectedPermissionsPM({
            ...selectedPermissionsPM,
            [type]: selectedOptions.filter((option) => option.value === "none"),
          });
        } else if (selectedOptions.some((option) => option.value === "all")) {
          setSelectedPermissionsPM({
            ...selectedPermissionsPM,
            [type]: selectedOptions.filter((option) => option.value === "all"),
          });
        } else {
          setSelectedPermissionsPM({
            ...selectedPermissionsPM,
            [type]: selectedOptions,
          });
        }
        break;
      case 3:
        if (selectedOptions.some((option) => option.value === "none")) {
          setSelectedPermissionsAF({
            ...selectedPermissionsAF,
            [type]: selectedOptions.filter((option) => option.value === "none"),
          });
        } else if (selectedOptions.some((option) => option.value === "all")) {
          setSelectedPermissionsAF({
            ...selectedPermissionsAF,
            [type]: selectedOptions.filter((option) => option.value === "all"),
          });
        } else {
          setSelectedPermissionsAF({
            ...selectedPermissionsAF,
            [type]: selectedOptions,
          });
        }
        break;
      case 4:
        if (selectedOptions.some((option) => option.value === "none")) {
          setSelectedPermissionsMR({
            ...selectedPermissionsMR,
            [type]: selectedOptions.filter((option) => option.value === "none"),
          });
        } else if (selectedOptions.some((option) => option.value === "all")) {
          setSelectedPermissionsMR({
            ...selectedPermissionsMR,
            [type]: selectedOptions.filter((option) => option.value === "all"),
          });
        } else {
          setSelectedPermissionsMR({
            ...selectedPermissionsMR,
            [type]: selectedOptions,
          });
        }
        break;

      default:
        break;
    }
  };

  const handleBoxClick = (boxNumber) => {
    setActiveBox(boxNumber);
  };
  const permissionOptions = [
    { value: "none", label: "None" },
    { value: "all", label: "All" },
    { value: "read", label: "Read" },
    { value: "write", label: "Write" },
    { value: "update", label: "Update" },
    { value: "delete", label: "Delete" },
  ];

  const generatePermissionObject = (permissionsArray) => {
    let permissionsValue = {
      create: 0,
      read: 0,
      update: 0,
      delete: 0,
    };

    permissionsArray.forEach((permission) => {
      if (permission.value === "all") {
        permissionsValue.create = 1;
        permissionsValue.read = 1;
        permissionsValue.update = 1;
        permissionsValue.delete = 1;
      }
      if (permission.value === "read") {
        permissionsValue.read = 1;
      }
      if (permission.value === "write") {
        permissionsValue.create = 1;
        permissionsValue.read = 1;
      }
      if (permission.value === "update") {
        permissionsValue.update = 1;
        permissionsValue.read = 1;
      }
      if (permission.value === "delete") {
        permissionsValue.delete = 1;
        permissionsValue.read = 1;
      }
    });

    return permissionsValue;
  };

  const postRoles = (e) => {
    e.preventDefault();

    const permissions = [];

    permissions.push(
      {
        code: "WPM-F",
        ...generatePermissionObject(selectedPermissionsWPM.floors),
      },
      {
        code: "WPM-L",
        ...generatePermissionObject(selectedPermissionsWPM.locations),
      },
      {
        code: "WPM-R",
        ...generatePermissionObject(selectedPermissionsWPM.resources),
      },
      {
        code: "WPM-C",
        ...generatePermissionObject(selectedPermissionsWPM.community),
      },
      {
        code: "WPM-A",
        ...generatePermissionObject(selectedPermissionsWPM.announcements),
      },
      {
        code: "PM-T",
        ...generatePermissionObject(selectedPermissionsPM.team),
      },
      {
        code: "PM-E",
        ...generatePermissionObject(selectedPermissionsPM.employees),
      },
      {
        code: "PM-R",
        ...generatePermissionObject(selectedPermissionsPM.roles),
      },
      {
        code: "PM-V",
        ...generatePermissionObject(selectedPermissionsPM.visit),
      },
      {
        code: "AF-I",
        ...generatePermissionObject(selectedPermissionsAF.invoices),
      },
      {
        code: "AF-M",
        ...generatePermissionObject(selectedPermissionsAF.members),
      },
      {
        code: "AF-P",
        ...generatePermissionObject(selectedPermissionsAF.packages),
      },
      {
        code: "AF-Q",
        ...generatePermissionObject(selectedPermissionsAF.quotations),
      },
      {
        code: "AF-PO",
        ...generatePermissionObject(selectedPermissionsAF.partnersAndOffers),
      },
      {
        code: "AF-T",
        ...generatePermissionObject(selectedPermissionsAF.tours),
      },
      {
        code: "AF-TI",
        ...generatePermissionObject(selectedPermissionsAF.tickets),
      },
      {
        code: "MRM-B",
        ...generatePermissionObject(selectedPermissionsMR.bookings),
      },
      {
        code: "S-S",
        update: parseInt(selectedPermissionsSettings.update),
        read: 1,
      }
    );

    const updatedData = {
      name: name,
      description: description,
      permissions: permissions,
    };
    setApiSpinner(true);

    createRole({
      spaceId: spaceId || currentSpaceId,
      roleData: updatedData,
    });
  };
  const navigate = useNavigate();
  const backToRole = () => {
    setKey("Social");
    navigate("/workplace/Management");
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      if (resultStats?.error?.data?.error) {
        requiredAlert(`Role ${resultStats?.error?.data?.error}`);
      } else {
        requiredAlert(`Role Name Must Not Be Empty`);
      }
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Role Created SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setKey("Social");
      navigate("/workplace/Management");
    }
  }, [resultStats]);

  return (
    <div className="card">
      <div className="card-body">
        <form>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-wrap justify-content-center">
                <h4 className="mr-4 my-2 align-self-center">Modules: </h4>
                <div
                  className={`square-box btn btn-outline-primary mb-3 d-flex justify-content-center align-items-center mx-1 ${
                    activeBox === 1 ? "active" : ""
                  }`}
                  style={{
                    width: "130px",
                    height: "80px",
                    whiteSpace: "normal",

                    color: activeBox == 1 ? "Blue" : "currentColor",
                  }}
                  onClick={() => handleBoxClick(1)}
                >
                  WorkPlace Management
                </div>
                <div
                  className={`square-box custom-btn btn btn-outline-primary mb-3 d-flex justify-content-center align-items-center mx-1 ${
                    activeBox === 2 ? "active" : ""
                  }`}
                  style={{
                    width: "130px",
                    height: "80px",
                    whiteSpace: "normal",

                    color: activeBox == 2 ? "Blue" : "currentColor",
                  }}
                  onClick={() => handleBoxClick(2)}
                >
                  Peoples' Management
                </div>
                <div
                  className={`square-box btn btn-outline-primary mb-3 d-flex justify-content-center align-items-center mx-1 ${
                    activeBox === 3 ? "active" : ""
                  }`}
                  style={{
                    width: "130px",
                    height: "80px",
                    whiteSpace: "normal",

                    color: activeBox == 3 ? "Blue" : "currentColor",
                  }}
                  onClick={() => handleBoxClick(3)}
                >
                  Administrative Functions
                </div>
                <div
                  className={`square-box custom-btn btn btn-outline-primary mb-3 d-flex justify-content-center align-items-center mx-1 ${
                    activeBox === 4 ? "active" : ""
                  }`}
                  style={{
                    width: "130px",
                    height: "80px",
                    whiteSpace: "normal",
                    color: activeBox == 4 ? "Blue" : "currentColor",
                  }}
                  onClick={() => handleBoxClick(4)}
                >
                  Meeting Room Management
                </div>
                <div
                  className={`square-box btn btn-outline-primary mb-3 d-flex justify-content-center align-items-center mx-2 ${
                    activeBox === 5 ? "active" : ""
                  }`}
                  style={{
                    width: "130px",
                    height: "80px",
                    whiteSpace: "normal",
                    color: activeBox == 5 ? "Blue" : "currentColor",
                    backgroundColor: "red",
                  }}
                  onClick={() => handleBoxClick(5)}
                >
                  Settings
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mb-3 justify-content-center">
            <h6 className="col-md-6 col-lg-3 col-sm-12">Components</h6>
            <h6 className="col-md-6 col-lg-3 col-sm-12">Permissions</h6>
          </div>
          <hr />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className={`active-box-content ${
                          activeBox !== null ? "visible" : "hidden"
                        }`}
                      >
                        {activeBox === 1 && (
                          <div className="col-12">
                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Floors
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  options={
                                    selectedPermissionsWPM?.floors?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsWPM?.floors?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  value={
                                    activeBox === 1
                                      ? selectedPermissionsWPM.floors
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "floors"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>

                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Locations
                              </label>
                              <div className=" col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  options={
                                    selectedPermissionsWPM?.locations?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsWPM?.locations?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  value={
                                    activeBox === 1
                                      ? selectedPermissionsWPM.locations
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "locations"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>

                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Resources
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  options={
                                    selectedPermissionsWPM?.resources?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsWPM?.resources?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  value={
                                    activeBox === 1
                                      ? selectedPermissionsWPM.resources
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "resources"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>

                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Community
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  options={
                                    selectedPermissionsWPM?.community?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsWPM?.community?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  value={
                                    activeBox === 1
                                      ? selectedPermissionsWPM.community
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "community"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>

                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Announcements
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  options={
                                    selectedPermissionsWPM?.announcements?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsWPM?.announcements?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  value={
                                    activeBox === 1
                                      ? selectedPermissionsWPM.announcements
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "announcements"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {activeBox === 2 && (
                          <div className="col-12">
                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Team
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 2
                                      ? selectedPermissionsPM?.team
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsPM?.team?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsPM?.team?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "team"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>

                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3  col-sm-12 col-md-6">
                                Employees
                              </label>
                              <div className=" col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 2
                                      ? selectedPermissionsPM?.employees
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsPM?.employees?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsPM?.employees?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "employees"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Roles
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 2
                                      ? selectedPermissionsPM?.roles
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsPM?.roles?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsPM?.roles?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "roles"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Vist/Visitors
                              </label>
                              <div className=" col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 2
                                      ? selectedPermissionsPM?.visit
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsPM?.visit?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsPM?.visit?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "visit"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {activeBox === 3 && (
                          <div className="col-12">
                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Invoices
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 3
                                      ? selectedPermissionsAF.invoices
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsAF?.invoices?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsAF?.invoices?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "invoices"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>

                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Members
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 3
                                      ? selectedPermissionsAF.members
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsAF?.members?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsAF?.members?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "members"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Packages
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 3
                                      ? selectedPermissionsAF.packages
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsAF?.packages?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsAF?.packages?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "packages"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Quotations
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 3
                                      ? selectedPermissionsAF.quotations
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsAF?.quotations?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsAF?.quotations?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "quotations"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>

                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Partners & Offers
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 3
                                      ? selectedPermissionsAF.partnersAndOffers
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsAF?.partnersAndOffers?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsAF?.partnersAndOffers?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "partnersAndOffers"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Tours
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 3
                                      ? selectedPermissionsAF.tours
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsAF?.tours?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsAF?.tours?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "tours"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Tickets
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 3
                                      ? selectedPermissionsAF.tickets
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsAF?.tickets?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsAF?.tickets?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "tickets"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {activeBox === 4 && (
                          <div className="col-12">
                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Bookings
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <Select
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      maxWidth: "200px",
                                      width: "180px",
                                    }),
                                    multiValue: (provided, state) => {
                                      const color =
                                        state.data.value === "all"
                                          ? "rgba(54, 179, 126, 0.2)"
                                          : state.data.value === "none"
                                          ? "rgba(255, 86, 48, 0.2)"
                                          : "rgba(37, 56, 88, 0.1)";
                                      return {
                                        ...provided,
                                        backgroundColor: color,
                                      };
                                    },
                                  }}
                                  closeMenuOnSelect={false}
                                  value={
                                    activeBox === 4
                                      ? selectedPermissionsMR.bookings
                                      : []
                                  }
                                  getOptionLabel={(permissionOptions) =>
                                    permissionOptions.label
                                  }
                                  getOptionValue={(permissionOptions) =>
                                    permissionOptions.value
                                  }
                                  options={
                                    selectedPermissionsMR?.bookings?.some(
                                      (option) => option.value === "none"
                                    )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "none"
                                        )
                                      : selectedPermissionsMR?.bookings?.some(
                                          (option) => option.value === "all"
                                        )
                                      ? permissionOptions?.filter(
                                          (option) => option.value === "all"
                                        )
                                      : permissionOptions
                                  }
                                  onChange={(e) =>
                                    handlePermissionSelectChange(
                                      e,
                                      activeBox,
                                      "bookings"
                                    )
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {activeBox === 5 && (
                          <div className="col-12">
                            <div className="mb-3 col-12 d-flex justify-content-center align-items-center">
                              <label className="me-3 col-sm-12 col-md-6">
                                Update Settings
                              </label>
                              <div className="col-sm-12 col-md-6">
                                <select
                                  className="col-12 form-control"
                                  name=""
                                  id=""
                                  value={selectedPermissionsSettings.update}
                                  onChange={(e) =>
                                    setSelectedPermissionsSettings({
                                      ...selectedPermissionsSettings,
                                      update: e.target.value,
                                    })
                                  }
                                >
                                  <option value="0">Not Allowed</option>
                                  <option value="1">Allowed</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <button
          className="btn btn-primary ml-5"
          disabled={apiSpinner}
          onClick={backToRole}
        >
          Back
        </button>
        <button
          className="btn btn-primary ml-5"
          disabled={apiSpinner}
          onClick={postRoles}
        >
          Save{" "}
          {apiSpinner ? (
            <div
              className="spinner-border text-primary"
              role="status"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "5px",
              }}
            ></div>
          ) : (
            ""
          )}
        </button>
      </div>
    </div>
  );
}

export default AddRole;
