import React, { useContext, useEffect, useRef, useState } from 'react'
import Loader from '../../Loader/Loader'
import * as Icon from 'react-bootstrap-icons'
// import { formatDate } from "../../utils/displayDateTime";
import {
  Button,
  Col,
  Dropdown,
  InputGroup,
  Pagination,
  Row
} from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import {
  useAllSpacesQuery,
  useBookingAvgRevenueReportQuery,
  useBookingByWeekDayReportQuery,
  useOwnerLocationQuery,
  useSpaceGetQuery,
  useVisitReportQuery,
  useVisitorReportQuery
} from '../../../Slices/spaceApi'
// import AppContext from "../../../context/AppContext";
// import {
//   copyTableContent,
//   downloadPDF,
//   printContent,
//   tableToCSV,
// } from "../../utils/exports";
// import {
//   handleChange,
//   handleDates,
//   handlePagesize,
// } from "../../utils/sharedReports";
import Select from 'react-select'
import TabularReport from '../TabularReports'
import { useSelector } from 'react-redux'
import { formatDateTime } from '../../../utils/formateDateTime'
import { formatDate } from '../../../utils/displayDateTime'
const VisitList = () => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  const tableRef = useRef(null)
  // const { mySpace, ownerLocation, allSpaces } = useContext(AppContext);
  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId
  })
  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId })
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId
  })
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')

  const [page_size, setPage_size] = useState(10)
  const [location, setLocation] = useState(mySpace?.spaceId || null)
  const [page, setPage] = useState(1)
  const [list, setList] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [options, setOptions] = useState([])
  const { data, isLoading, refetch, isFetching } = useVisitReportQuery({
    spaceId: spaceId || currentSpaceId,
    start,
    end,
    page_size,
    page: page,
    location: location
  })
  useEffect(() => {
    if ((data, !isLoading)) {
      setList(data.visits)
    }
  }, [data])
  useEffect(() => {}, [data, isLoading, list])

  // useEffect(() => {
  //   refetch()
  // }, [selectedValue])
  const body = isFetching ? (
    <Loader />
  ) : (
    <div className='table-responsive'>
      <table ref={tableRef} className='table'>
        <thead>
          <tr>
            <th>#</th>
            <th>Visitor Name</th>
            <th>Prev. Visit Count</th>
            <th>Phone Number</th>
            <th> Expected Date</th>
            <th>Purpose</th>
          </tr>
        </thead>
        <tbody className='table-border-bottom-0'>
          {!isLoading && data && list.length > 0 ? (
            list.map((item, index) => (
              <React.Fragment key={index}>
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span className='emp_name text-truncate'>
                        {item.visitor.name}
                      </span>
                      <small className='emp_post text-truncate text-muted'>
                        {item.visitor.email}
                      </small>
                    </div>
                  </td>
                  <td>{item.visitor.visitCount || '-'}</td>
                  <td>{item.visitor.phone_number || '-'}</td>
                  <td>{formatDate(item.expected_visit_datetime) || '-'}</td>
                  <td>
                    <div className='truncate-text'>
                      {item.purpose_of_visit || '-'}
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))
          ) : list.length === 0 ? (
            <tr>
              <td>No Data</td>
            </tr>
          ) : (
            <tr>
              <td>Data is Loading</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
  return (
    <>
      {data && !isLoading && list ? (
        <TabularReport
          key={'bookingByWeekDays'}
          Child={() => body}
          data={data}
          isLoading={isLoading}
          mySpace={mySpace}
          allSpaces={allSpaces}
          tableRef={tableRef}
          list={list}
          setList={setList}
          ownerLocation={ownerLocation}
          page={page}
          setPage={setPage}
          page_size={page_size}
          setPage_size={setPage_size}
          location={location}
          setLocation={setLocation}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          refetch={refetch}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          options={options}
          setOptions={setOptions}
        />
      ) : (
        <Loader></Loader>
      )}
    </>
  )
}

export default VisitList
