import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
  useDurationQuery,
  useExpenseCategoryListQuery,
  useExpenseCreateMutation,
  useExpenseUpdateMutation,
  useGetSpaceDurationsQuery,
} from "../../Slices/spaceApi";

import Select from "react-select";
import axios from "axios";
import Loader from "../Loader/Loader";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { isFirstCharacterDigit, isValidInteger } from "../../utils/validation";
import { Info, InfoCircle } from "react-bootstrap-icons";
import { useGlobalContext } from "../../Context/GlobalContext";

// import ExpenseContext from "../../context/ExpenseContext";
const url = process.env.REACT_APP_API_URL;

const ExpenseAdd = ({ handleClose, operateId, operateObject }) => {
  const fileRef = useRef(null);
  const { currency } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: data1 } = useGetSpaceDurationsQuery({
    spaceId: spaceId || currentSpaceId,
  });

  console.log("Data 1 is", data1);

  // const { categoryList: data, duration: data1 } = useContext(ExpenseContext);
  // const { data, isLoading, isError, error, refetch } =
  //   useExpenseCategoryListQuery({
  //     spaceId:spaceId|| currentSpaceId
  //   })
  const { data: data } = useExpenseCategoryListQuery({
    spaceId: spaceId || currentSpaceId,
  });
  // const { data: data1 } = useGetSpaceDurationsQuery();
  // console.log("Data 1 is", data1);
  const [categoryMissing, setCategoryMissing] = useState(false);
  // const {
  //   data: data1,
  //   isLoading: isLoading1,
  //   isError: isError1,
  //   error: error1,
  //   refetch: refetch1
  // } = useDurationQuery()
  const [category, setCategory] = useState([]);
  const [period, setPeriod] = useState([]);
  const [file, setFile] = useState("");
  //.log({ data: data })
  const [input, setInput] = useState({
    date: "",
    amount: 0,
    category: 0,
    description: "",
    receipt: "",
    recurring: false,
    recurringPeriod: 0,
  });
  useEffect(() => {
    if (operateId && operateObject) {
      setInput(operateObject);
    }
  }, [operateId, operateObject]);
  useEffect(() => {
    if (data) {
      const updatedArray = data?.categories?.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setCategory(updatedArray);
    }
  }, [data]);
  useEffect(() => {
    if (data1) {
      const updatedArray = data1?.spaceDurations?.map(
        ({ id, spaceDuration }) => ({
          value: id,
          label: spaceDuration,
        })
      );

      setPeriod(updatedArray);
    }
  }, [data1]);
  useEffect(() => {
    setInput((prevInput) => ({
      ...prevInput,
      date: new Date().toISOString().split("T")[0],
    }));
  }, []);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [addExpense, result] = useExpenseCreateMutation();
  const [updateExpense, updated] = useExpenseUpdateMutation();
  const handleInput = (e) => {
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCategoryChange = (selectedOption) => {
    setInput((prevInput) => ({
      ...prevInput,
      category: selectedOption.value || null,
    }));
    //.log(input)
  };
  const handlePeriodChange = (selectedOption) => {
    //.log({ hi: selectedOption.target })
    setInput((prevInput) => ({
      ...prevInput,
      recurringPeriod: selectedOption.value,
    }));
    //.log(input)
  };
  useEffect(() => {
    if (result?.isSuccess) {
      handleClose();

      toast.success("Expense Added Successfully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
    if (updated?.isSuccess) {
      handleClose();

      toast.success("Expense Updated Successfully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [result, updated]);
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
    if (file !== null && file !== "") {
      setIsUploading(true);
      if (file.size >= 1e7) {
        //.log('File is too big!')
        setFile(null);
        setIsUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("file", file);
        axios
          .post(`${url}/uploadImage/`, fd, {
            // headers: {
            //   credentials: credentials
            // }
          })
          .then((resp) => {
            setInput({ ...input, receipt: resp.data?.[0] });
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
      } else {
        setFile(null);
        //.log('Please Select Image File')
        setIsUploading(false);
      }
    }
  }, [file]);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleAdd = (event) => {
    setIsSubmit(true);
    const form = event.currentTarget;
    //.log(form.checkValidity())
    event.preventDefault();
    if (form.checkValidity() === false || !input.category || !input.receipt) {
      event.stopPropagation();
      setValidated(true);
      setCategoryMissing(true);
    } else {
      setValidated(false);
      !operateId
        ? addExpense({
            spaceId: spaceId || currentSpaceId,
            input,
          })
        : updateExpense({
            spaceId: spaceId || currentSpaceId,
            id: operateId,
            input,
          });
    }
    // else
  };
  const handleCheckBox = () => {
    setInput((prevState) => {
      return { ...prevState, recurring: !prevState.recurring };
    });
  };
  useEffect(() => {
    if (result.isError) {
      const firstError = result.error.data.error;
      Swal.fire({
        title: "Required!",
        icon: "warning",
        html: firstError,
        confirmButtonText: "OK",
      });
    }
    if (updated.isError) {
      const firstError = updated.error.data.error;
      Swal.fire({
        title: "Required!",
        icon: "warning",
        html: firstError,
        confirmButtonText: "OK",
      });
    }
  }, [result, updated]);
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="w-10"
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">
            {!operateId ? "Add Expense" : "Update Expense"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAdd}>
            <Row className="mb-2">
              <Form.Group md="5" controlId="Name" as={Col}>
                <Form.Label>
                  <h6>Expense Name</h6>
                </Form.Label>
                <Form.Control
                  className="viewonly"
                  required
                  pattern="[A-Za-z].*"
                  type="text"
                  placeholder="Electicity"
                  value={input?.name || ""}
                  name="name"
                  onChange={handleInput}
                />
                {validated && (
                  <Form.Control.Feedback type="invalid">
                    {!input.name ? (
                      "This field is Required"
                    ) : isFirstCharacterDigit(input.name) ? (
                      "Must start with an alphabet"
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group md="5" controlId="category" as={Col}>
                <Form.Label>
                  <h6>Category</h6>
                </Form.Label>
                <Select
                  isRequired
                  onChange={handleCategoryChange}
                  placeholder={
                    input.category
                      ? (
                          category.find(
                            (item) => item.value === input.category
                          ) || { label: "Add Category" }
                        ).label
                      : "Add Category"
                  }
                  options={category}
                  className={
                    validated && !input.category
                      ? `border border-danger rounded`
                      : ``
                  }
                />
                {validated && !input.category ? (
                  <p className="text-danger fs-7">This field is Required*</p>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group controlId="date" md={5} as={Col}>
                <Form.Label>
                  <h6>Expense Date</h6>
                </Form.Label>
                <Form.Control
                  required
                  type="date"
                  placeholder="Expense date"
                  value={input?.date}
                  name="date"
                  onChange={handleInput}
                />
                {validated && !input?.date && (
                  <Form.Control.Feedback type="invalid">
                    This field is Required*
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group as={Col} md="5" controlId="amount">
                <Form.Label>
                  <h6>Amount</h6>
                </Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{currency}</span>
                  </div>
                  <Form.Control
                    min={0.01}
                    step={0.01}
                    required
                    type="number"
                    placeholder="100"
                    value={input?.amount || ""}
                    name="amount"
                    onChange={handleInput}
                  />
                </div>{" "}
                {validated && (
                  <Form.Control.Feedback type="invalid">
                    {!input.amount ? (
                      "This field is Required"
                    ) : //  !isValidInteger(input?.amount)
                    input?.amount <= 0 ? (
                      "Must be a Positive Integer"
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group controlId="recurring">
                <Form.Check
                  type="checkbox"
                  label="Is Expense Recurring"
                  name="recurring"
                  checked={input.recurring}
                  onChange={handleCheckBox}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              {input.recurring ? (
                <>
                  {" "}
                  <Form.Group as={Col} md="5" controlId="recurringPeriod">
                    <Form.Label>
                      <h6>Recurring Period</h6>
                    </Form.Label>
                    <Form.Select
                      required
                      defaultValue={input?.recurringPeriod || 0}
                      onChange={handleInput}
                      placeholder="Select a Period"
                      name="recurringPeriod"
                    >
                      <option value="0" disabled>
                        Select a Period
                      </option>
                      {period?.map((options) => (
                        <option key={options?.value} value={options?.value}>
                          {options.label}
                        </option>
                      ))}
                    </Form.Select>
                    {validated && !input.recurringPeriod && (
                      <Form.Control.Feedback type="invalid">
                        This field is Required*
                      </Form.Control.Feedback>
                    )}{" "}
                  </Form.Group>
                </>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-3 mt-3">
              <Form.Group controlId="Account">
                <Form.Label className="mb-2">
                  <h6>Description</h6>
                </Form.Label>
                <Form.Control
                  className="viewonly"
                  required
                  as="textarea"
                  rows={3} // Set the number of rows to 3
                  placeholder="Enter Description"
                  value={input?.description || ""}
                  name="description"
                  onChange={handleInput}
                  style={{ overflowY: "scroll" }} // Add scrollbar style
                ></Form.Control>

                {validated && !input.description && (
                  <Form.Control.Feedback type="invalid">
                    This field is Required*
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>

            <Row className="mb-2"></Row>

            <Row className="mb-3">
              <Form.Group controlId="file">
                <Form.Label className="mb-2">
                  <h6>{operateId ? `Receipt (Optional)` : "Receipt"}</h6>
                </Form.Label>
                <Form.Control
                  type="file"
                  required={!operateId}
                  onChange={handleFileChange}
                  className=""
                  hidden
                  ref={fileRef}
                />

                <div className="media">
                  <div className="media-aside align-self-start">
                    <img
                      alt="failed to fetch"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://placehold.jp/90x90.png";
                      }}
                      src={
                        file
                          ? URL.createObjectURL(file)
                          : input?.receipt || "https://placehold.jp/90x90.png"
                      }
                      className="rounded"
                      style={{
                        width: "90px",
                        height: "90px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="media-body mt-2">
                    {isUploading ? (
                      <Spinner />
                    ) : (
                      <div
                        id="custom-button"
                        className="btn btn-secondary me-2 btn-sm"
                        onClick={() => fileRef?.current?.click()}
                      >
                        Upload
                      </div>
                    )}
                    <p className="card-text mt-2">
                      <InfoCircle /> Allowed Max size of 10Mb
                    </p>
                    <p className="card-text mt-2 ">{file.name}</p>
                    {validated && !input.receipt && (
                      <p className="card-text mt-2 text-danger ">
                        Upload a Receipt
                      </p>
                    )}
                  </div>
                </div>
              </Form.Group>
            </Row>

            <Row></Row>

            {result.isLoading || updated.isLoading ? (
              <>
                <Button className="btn btn-primary">
                  Loading...{" "}
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="primary"
                  />
                </Button>
              </>
            ) : (
              <Button
                className="btn btn-primary "
                type="submit"
                disabled={isUploading}
              >
                {operateId ? "Update Expense" : "Add Expense"}
              </Button>
            )}
            <Button className="btn btn-secondary  ml-3" onClick={handleClose}>
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpenseAdd;
