import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { colorStyles } from "../../utils/constant";
import {
  useCreateMeetingRoomPackageMutation,
  useGetRelatedMeetingRoomsQuery,
} from "../../Slices/spaceApi";
import { toast, Toaster } from "react-hot-toast";
import { requiredAlert } from "../../utils/Alerts";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { useGlobalContext } from "../../Context/GlobalContext";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { set } from "date-fns";

const MeetingPackageModal = ({ FetchSpaceDurations, showButton }) => {
  const { packagesPermissions, billingOption, currency } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [allDone, setAllDone] = useState(true);
  const [errorDurations, setErrorDurations] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);
  const navigate = useNavigate();
  const { isLoading: relatedLoading, data: FetchMeetingRoom } =
    useGetRelatedMeetingRoomsQuery({ id: spaceId || currentSpaceId });

  const uniqueLocations = Array.from(
    new Set(FetchMeetingRoom?.map((room) => room.space.name))
  );
  const [focused, setFocused] = useState({
    name: false,
    // hours: false,
    // price: false,

    serviceCharges: false,
    rentalFee: false,
  });
  const [meetingRoomSelect, setMeetingRoomSelect] = useState(false);

  const [createMeetingPackage, resultStats] =
    useCreateMeetingRoomPackageMutation();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSubmitted(false);
    setErrorDurations(false);
    setData({
      name: "",
      hours: 10,
      // price: "",
      serviceCharges: "",
      rentalFee: "",
      inclusiveTax: false,

      inclusiveTax: false,
      expiryDuration: "",
      meetingRoomAllowedHours: [],
    });
    setRows([
      {
        meetingRoom: [],
        hours: 0,
      },
    ]);
    setFocused({
      name: false,
      // hours: false,
      // price: false,
      serviceCharges: false,
      rentalFee: false,
      expiryDuration: false,
    });
  };

  const handleShow = () => setShow(true);
  const [data, setData] = useState({
    name: "",
    hours: 10,
    // price: "",
    serviceCharges: "",
    rentalFee: "",
    inclusiveTax: false,

    expiryDuration: "",
    meetingRoomAllowedHours: [
      {
        meetingRoom: [],
        hours: 0,
      },
    ],
  });

  const handleApprovePublicBooking = () => {
    setMeetingRoomSelect((prevValue) => {
      const newValue = !prevValue;
      return newValue;
    });
    // setMeetingRoomSelect(true);
  };
  const [rows, setRows] = useState([
    {
      selectedLocation: null,
      selectedMeetingRooms: [],
      hours: 0,
    },
  ]);

  const handleAddRow = () => {
    const newRow = {
      selectedLocation: null,
      selectedMeetingRooms: [],
      hours: 0,
    };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleLocationChange = (event, rowIndex) => {
    const selectedLocation = event.target.value;
    const updatedRows = [...rows];
    updatedRows[rowIndex].selectedLocation = selectedLocation;
    setRows(updatedRows);
  };

  const handleMeetingRoomChange = (selectedOptions, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].selectedMeetingRooms = selectedOptions;
    setRows(updatedRows);
  };
  const [meetingHoursError, setMeetingHoursError] = useState(false);

  const handleHoursChange = (e, rowIndex) => {
    const inputValue = e.target.value;

    if (
      inputValue.includes("e") ||
      inputValue.includes("-") ||
      inputValue.includes("+")
    ) {
      setMeetingHoursError("Hours must not be a negative value.");
      return;
    }
    setMeetingHoursError("");
    const updatedRows = [...rows];
    updatedRows[rowIndex].hours = e.target.value;
    setRows(updatedRows);
  };

  useEffect(() => {
    if (
      // !data.hours == "" &&
      // !data.price == "" &&
      !data.serviceCharges == "" &&
      !data.rentalFee == "" &&
      data.name.match("^(?=.{1,50}$)[A-Za-z0-9!@#$%^&* ]*$") &&
      !data.expiryDuration == ""
    ) {
      setAllDone(false);
    } else {
      setAllDone(true);
    }
  }, [data]);

  const handle = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleInclusiveTax = (event) => {
    setData({
      ...data,
      inclusiveTax: event.target.checked,
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      const errorMessage =
        resultStats &&
        resultStats?.error &&
        resultStats?.error?.data &&
        Object?.values(resultStats?.error?.data).find(
          (error) => error?.length > 0 || "An Error Occured"
        );
      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      // setTimeout(() => {
      handleClose();
      // }, 1500);
      setApiSpinner(false);

      setSubmitted(false);
      toast.success("Meeting Room Package Added Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  const postMeetingPackage = (e) => {
    e.preventDefault();

    const isDurationValid = data?.expiryDuration !== "";
    // const isHourValid = data?.hours !== "";
    const isServiceChargesValid = data?.serviceCharges !== "";
    const isRentalFeeValid = data?.rentalFee !== "";

    const isNameValid = data.name.match("^(?=.{1,50}$)[A-Za-z0-9!@#$%^&* ]*$");

    setFocused({
      ...focused,
      // hours: !isHourValid,
      name: !isNameValid,
      serviceCharges: !isServiceChargesValid,
      rentalFee: !isRentalFeeValid,
    });

    setErrorDurations(!isDurationValid);

    if (allDone) {
      return;
    }

    const meetingRoomAllowedHours = rows?.map((row) => ({
      meetingRoom: row.selectedMeetingRooms?.map((item) => item.id) || [],

      hours: row.hours,
    }));

    const updatedPackageValues = {
      ...data,
      meetingRoomAllowedHours,
    };
    setApiSpinner(true);

    createMeetingPackage({
      spaceId: spaceId || currentSpaceId,
      data: updatedPackageValues,
    });
  };

  const handleDuration = (e) => {
    setData({ ...data, expiryDuration: e.id });
  };

  const handleFocus = (e) => {
    setFocused({ ...focused, [e.target.name]: true });
  };

  const onBlurDuration = () => {
    if (data?.expiryDuration === "") {
      setErrorDurations(true);
    }
  };

  const onFocusDuration = (e) => {
    if (e.target.value === data.expiryDuration) {
      setErrorDurations(false);
    }
  };

  return (
    <div className="d-flex">
      {showButton ? (
        <Button
          variant="primary"
          onClick={packagesPermissions[0]?.create ? handleShow : null}
          className="send-invoice-btn"
        >
          {packagesPermissions[0]?.create ? (
            <span className="invoice">
              <Plus /> Add Meeting Package
            </span>
          ) : (
            <span className="invoice">
              <Plus /> Add Meeting Package
              <Lock />
            </span>
          )}
        </Button>
      ) : (
        <p
          // variant="primary"
          onClick={packagesPermissions[0]?.create ? handleShow : null}
          className="send-invoice-btn"
        >
          {packagesPermissions[0]?.create ? (
            <span className="invoice btn-link">
              <Plus /> Add Meeting Package
            </span>
          ) : (
            <span className="invoice btn-link">
              <Plus /> Add Meeting Package
              <Lock />
            </span>
          )}
        </p>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        id="meetingPopUp"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="" closeButton>
          <Modal.Title as="h5">Add Meeting Package</Modal.Title>
        </Modal.Header>
        <small className="fw-bold ml-3 mt-2">
          Make Meeting Room's Package for Your Space
        </small>
        <form onSubmit={postMeetingPackage}>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="name">Package Name</label>
              <input
                type="text"
                name="name"
                required
                id="name"
                value={data.name}
                placeholder={"Enter Meeting Package Name"}
                onChange={handle}
                className="form-control"
                onBlur={handleFocus}
                pattern={"^(?=.{1,50}$)[A-Za-z0-9!@#$%^&* ]*$"}
                focused={focused?.name.toString()}
              />
              <span className="error">{"Enter Name(Max 50 Characters)"}</span>
            </div>

            <div className="form-group">
              <label htmlFor="serviceCharges">Service Charges</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">{currency}</span>
                </div>
                <input
                  type="number"
                  required
                  name="serviceCharges"
                  id="serviceCharges"
                  placeholder="Enter Service Charges"
                  className="form-control"
                  value={data?.serviceCharges}
                  onChange={handle}
                  onBlur={handleFocus}
                  min={1}
                  focused={focused?.serviceCharges?.toString()}
                />
              </div>
              <span className="error">{"Enter Service Charges"}</span>
            </div>
            <div className="form-group">
              <label htmlFor="rentalFee">Rental Fee</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">{currency}</span>
                </div>
                <input
                  type="number"
                  required
                  name="rentalFee"
                  id="rentalFee"
                  placeholder="Enter Rental Fee"
                  className="form-control"
                  value={data?.rentalFee}
                  onChange={handle}
                  onBlur={handleFocus}
                  min={1}
                  focused={focused?.rentalFee?.toString()}
                />
              </div>
              <span className="error">{"Enter Rental Fee"}</span>
            </div>
            <div className="form-check">
              <label className="form-check-label" htmlFor="inclusiveTax">
                Is this Price Includes Tax!
              </label>
              <input
                type="checkbox"
                className="form-check-input ml-2"
                id="inclusiveTax"
                checked={data?.inclusiveTax}
                onChange={(e) => handleInclusiveTax(e)}
              />
            </div>

            {FetchSpaceDurations?.length > 0 && (
              <div className="form-group">
                <label htmlFor="expiryDuration">Expiry Duration</label>

                <Select
                  className={errorDurations ? "reactSelectActive" : ""}
                  options={FetchSpaceDurations}
                  getOptionLabel={(option) => option.spaceDuration}
                  getOptionValue={(option) => option.id}
                  styles={colorStyles}
                  value={FetchSpaceDurations?.find(
                    (obj) => obj.name === data?.expiryDuration
                  )}
                  onChange={handleDuration}
                  onBlur={onBlurDuration}
                  id="expiryDuration"
                  onFocus={onFocusDuration}
                />
                {errorDurations && (
                  <span className="e">Select The Durations</span>
                )}
              </div>
            )}

            {FetchMeetingRoom?.length > 0 && (
              <>
                {" "}
                <div className="d-flex mt-4" style={{ cursor: "pointer" }}>
                  <p htmlFor="" className="">
                    Meeting Room (Select Multiple)
                  </p>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch2"
                      label=""
                      className="ml-2"
                      value={meetingRoomSelect}
                      checked={meetingRoomSelect}
                      onChange={handleApprovePublicBooking}
                    />
                  </Form>
                </div>
                {meetingRoomSelect && (
                  <>
                    <div className="form-group">
                      <label htmlFor="package-facilities" className="mb-1">
                        Meeting Rooms (Select Multiple)*
                      </label>
                      {FetchMeetingRoom?.length > 0 &&
                        rows?.map((row, index) => (
                          <div key={index} className="repeater-wrapper my-2">
                            <div className="">
                              <p className="card-text col-title mb-md-50 mb-0 ml-3">
                                Select Location
                              </p>

                              <select
                                onChange={(e) => handleLocationChange(e, index)}
                                value={row.selectedLocation}
                                className="form-control col-10 ml-3"
                              >
                                <option value="">All Locations</option>
                                {uniqueLocations?.map((location) => (
                                  <option key={location} value={location}>
                                    {location}
                                  </option>
                                ))}
                              </select>
                              <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                                <div className="row w-100 pe-lg-0 pe-3">
                                  <div className="col-12 mb-lg-0 mb-2 mt-lg-0">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Select allowed meeting rooms
                                    </p>
                                    <Select
                                      options={
                                        !row.selectedLocation
                                          ? FetchMeetingRoom
                                          : FetchMeetingRoom?.filter(
                                              (room) =>
                                                room.space.name ===
                                                row.selectedLocation
                                            )
                                      }
                                      getOptionLabel={(option) => option.title}
                                      getOptionValue={(option) => option.id}
                                      closeMenuOnSelect={false}
                                      styles={colorStyles}
                                      value={row.selectedMeetingRooms}
                                      onChange={(selectedOptions) =>
                                        handleMeetingRoomChange(
                                          selectedOptions,
                                          index
                                        )
                                      }
                                      isMulti
                                      id={`space-package-${index}`}
                                    />
                                  </div>
                                  <div className="col-12 my-lg-0 my-2">
                                    {billingOption == 1 ? (
                                      <p className="card-text col-title mb-0">
                                        {" "}
                                        Add hours for this package
                                      </p>
                                    ) : billingOption == 2 ? (
                                      <p className="card-text col-title mb-0">
                                        Add coins for this package
                                      </p>
                                    ) : (
                                      <p className="card-text col-title mb-0 text-danger">
                                        Set Biiling Option in Settings
                                      </p>
                                    )}

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="1"
                                      min={1}
                                      name="hours"
                                      value={row.hours}
                                      onChange={(e) =>
                                        handleHoursChange(e, index)
                                      }
                                    />
                                    {meetingHoursError && (
                                      <small className="text-danger">
                                        {meetingHoursError}
                                      </small>
                                    )}
                                  </div>
                                </div>

                                {/* <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                          <button
                            className="bg-white"
                            onClick={() => handleRemoveRow(index)}
                          >
                            <CancelIcon />
                          </button>
                        </div> */}
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))}
                    </div>
                    {FetchMeetingRoom?.length <= 0 && (
                      <div>
                        <p>No Package is Available</p>

                        <div
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => navigate("/floorPlans")}
                        >
                          Please Set Meeting Rooms
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            <div className="mt-4">
              <Button
                variant="primary"
                disabled={
                  apiSpinner ||
                  !data?.name ||
                  !data?.serviceCharges ||
                  !data?.rentalFee ||
                  !data.expiryDuration
                }
                type="submit"
              >
                Add Package{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <button
                className="btn btn-secondary ml-3"
                disabled={apiSpinner}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer className="mt-auto">
         
           
          </Modal.Footer> */}
        </form>
      </Modal>
    </div>
  );
};

export default MeetingPackageModal;
