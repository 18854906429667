import React from 'react'
import { Line } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { useBookingResourceUsageReportQuery } from '../../../Slices/spaceApi'
import Loader from '../../Loader/Loader'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)
const ResourceUsageChart = () => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)
  const { data, isLoading, isError } = useBookingResourceUsageReportQuery({
    spaceId: spaceId || currentSpaceId
  })

  const meetingRooms = data?.bookingResourceUsage?.map(
    item => item?.meetingRoomName
  )
  const totalBookings = data?.bookingResourceUsage?.map(
    item => item?.totalBookings
  )
  const totalHours = data?.bookingResourceUsage?.map(item => item?.totalHours)
  const revenue = data?.bookingResourceUsage?.map(item => item?.revenue)

  // Chart data configuration
  const chartData = {
    labels: meetingRooms,
    datasets: [
      {
        label: 'Total Bookings',
        data: totalBookings,
        fill: true,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)'
      },
      {
        label: 'Total Hours',
        data: totalHours,
        fill: true,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)'
      },
      {
        label: 'Revenue',
        data: revenue,
        fill: true,
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderColor: 'rgba(255, 206, 86, 1)'
      }
    ]
  }

  // Chart options configuration
  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Meeting Rooms'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Values'
        },
        beginAtZero: true
      }
    }
  }

  if (isLoading) return <Loader />
  if (isError) return <p>Error loading data...</p>
  return (
    <div className='card card-datatable text-nowrap mt-3'>
      <div
        id='DataTables_Table_1_wrapper'
        className='dataTables_wrapper dt-bootstrap5 no-footer'
      >
        <div className='container-fluid'>
          {!isLoading && <Line data={chartData} options={options} />}
        </div>
      </div>
    </div>
  )
}

export default ResourceUsageChart
