import React, { useState, useEffect } from "react";
import { Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import { useSelector } from "react-redux";
import {
  useCreateTeamMutation,
  useUpdateTeamMutation,
} from "../../Slices/spaceApi";
import { toast } from "react-hot-toast";
import { useGlobalContext } from "../../Context/GlobalContext";
import Select from "react-select";
import * as Icon from "react-bootstrap-icons";
const TeamForm = ({
  teamData: initialData,
  onSubmit,
  title,
  submitButtonText,
  onCloseModal,
  isReadable,
  onOpenModal,
  Locations,
}) => {
  const { teamsPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [createTeam, resultStats] = useCreateTeamMutation();
  const [updateTeam, resultStats1] = useUpdateTeamMutation();
  const [file, setFile] = useState("");
  const [apiSpinner, setApiSpinner] = useState(false);

  // const [teamData, setTeamData] = useState(initialData);
  const [teamData, setTeamData] = useState({
    name: "",
    email: "",
    profileImage: "",
    more_info: "",
    location: "",
    locations: [],
    status: true,
  });

  const [uploadedImageUrls, setUploadedImageUrls] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    // email: "",
    // location: "",
    status: "",
    locations: "",
    profileImage: "",
  });

  useEffect(() => {
    if (resultStats1.isError == false && initialData?.id) {
      setTeamData(initialData);
      setUploadedImageUrls(initialData?.profileImage);
    }
  }, [initialData]);

  useEffect(() => {
    if (file !== null && file !== "") {
      setIsUploading(true);

      if (file.size >= 1e7) {
        setFile(null);

        errorAlertImage("File is too big!");

        setIsUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUploadedImageUrls(resp.data?.[0]);
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
        setValidationErrors({
          ...validationErrors,
          profileImage: "",
        });
      } else {
        setFile(null);
        requiredAlert("Wrong Formate: Please Select Image File");
        setIsUploading(false);
      }
    }
  }, [file]);
  useEffect(() => {
    if (uploadedImageUrls !== null) {
      setTeamData({
        ...teamData,
        profileImage: uploadedImageUrls,
      });
    }
  }, [uploadedImageUrls]);

  const handleClose = () => {
    setIsUploading(false);
    setUploadedImageUrls(null);

    setShow(false);
    onCloseModal();
    setErrorMessage("");
    setFile("");
    setValidationErrors({
      name: "",
      // email: "",

      // location: "",
      locations: [],
      status: "",
      profileImage: "",
    });
    setTeamData({
      name: "",
      email: "",
      profileImage: "",
      more_info: "",
      location: "",
      locations: [],
      status: true,
    });
  };
  const handleOpen = () => {
    setShow(true);
    onOpenModal();
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }

    setErrorMessage("");
  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setTeamData({
  //     ...teamData,
  //     [name]: value,
  //   });
  // };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name == "name") {
      if (value.length <= 100) {
        setTeamData({
          ...teamData,
          [name]: value,
        });
        setValidationErrors({
          ...validationErrors,
          [name]: "",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: "Max Name Limit is 100 Character",
        });
      }
    } else {
      setTeamData({
        ...teamData,
        [name]: value,
      });
      // Clear validation error when the field is edited
      setValidationErrors({
        ...validationErrors,
        [name]: "",
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      id,
      name,
      profileImage,
      email,
      location,
      locations,
      status,
      more_info,
    } = teamData;

    let formIsValid = true;

    // Validate fields
    const newValidationErrors = {
      profileImage: profileImage ? "" : "Please Select an Image",
      name: name ? "" : "Enter Team Name",
      // email: email ? "" : "Enter Email",

      locations: locations.length > 0 ? "" : "Select Location",
      status: status !== "" ? "" : "Select Status",
    };

    // Check if any validation errors are present
    Object.keys(newValidationErrors).forEach((fieldName) => {
      if (newValidationErrors[fieldName] !== "") {
        formIsValid = false;
      }
    });

    if (formIsValid) {
      let updateData = {
        id,
        name,
        profileImage,
        email,
        location,
        locations,
        status,
        more_info,
      };
      if (file) {
        onSubmit(teamData);
        setUploadedImageUrls(null);
        setFile("");
        handleClose();
      } else {
        let filename = null;
        if (profileImage) {
          const parts = profileImage.split("/");
          const lastPart = parts[parts.length - 1];
          filename = lastPart.split("?")[0];
          updateData.profileImage = filename;

          onSubmit(updateData);
          setUploadedImageUrls(null);
          setFile("");
          handleClose();
        }
      }
    } else {
      setValidationErrors(newValidationErrors);
    }
  };

  const handleSubmitAdd = (event) => {
    event.preventDefault();
    const {
      id,
      name,
      profileImage,
      email,
      location,
      locations,
      status,
      more_info,
    } = teamData;

    let formIsValid = true;

    // Validate fields
    const newValidationErrors = {
      profileImage: profileImage ? "" : "Please Select an Image",
      name: name ? "" : "Enter Team Name",
      // email: email ? "" : "Enter Email",
      locations: locations.length > 0 ? "" : "Select Location",

      // location: location ? "" : "Select Location",
      status: status !== "" ? "" : "Select Status",
    };

    // Check if any validation errors are present
    Object.keys(newValidationErrors).forEach((fieldName) => {
      if (newValidationErrors[fieldName] !== "") {
        formIsValid = false;
      }
    });

    if (formIsValid) {
      let updateData = {
        id,
        name,
        profileImage,
        email,
        location,
        locations,
        status,
        more_info,
      };
      if (file) {
        // onSubmit(teamData);
        setApiSpinner(true);
        createTeam({
          spaceId: spaceId || currentSpaceId,
          teamData: updateData,
        });
      } else {
        let filename = null;
        if (profileImage) {
          const parts = profileImage.split("/");
          const lastPart = parts[parts.length - 1];
          filename = lastPart.split("?")[0];
          updateData.profileImage = filename;
          setApiSpinner(true);

          createTeam({
            spaceId: spaceId || currentSpaceId,
            teamData: updateData,
          });
        }
      }
    } else {
      setValidationErrors(newValidationErrors);
    }
  };
  const handleUpdateTeam = (event) => {
    event.preventDefault();
    const {
      id,
      name,
      profileImage,
      email,
      location,
      locations,
      status,
      more_info,
    } = teamData;

    let formIsValid = true;

    const newValidationErrors = {
      profileImage: profileImage ? "" : "Please Select an Image",
      name: name ? "" : "Enter Team Name",
      // email: email ? "" : "Enter Email",
      locations: locations.length > 0 ? "" : "Select Location",

      // location: location ? "" : "Select Location",
      status: status !== "" ? "" : "Select Status",
    };

    Object.keys(newValidationErrors).forEach((fieldName) => {
      if (newValidationErrors[fieldName] !== "") {
        formIsValid = false;
      }
    });

    if (formIsValid) {
      let updateData = {
        id,
        name,
        profileImage,
        email,
        location,
        // status: JSON.parse(teamData?.status?.toLowerCase()),
        status,
        locations,
        more_info,
      };

      if (file) {
        setApiSpinner(true);

        updateTeam({
          spaceId: spaceId || currentSpaceId,
          id: updateData?.id,
          updateData: updateData,
        });
      } else {
        let filename = null;
        if (profileImage) {
          const parts = profileImage.split("/");
          const lastPart = parts[parts.length - 1];
          filename = lastPart.split("?")[0];
          if (filename.includes("%20")) {
            filename = filename.replace(/%20/g, " ");
          }
          updateData.profileImage = filename;
          setApiSpinner(true);

          updateTeam({
            spaceId: spaceId || currentSpaceId,
            id: updateData?.id,
            updateData: updateData,
          });
        }
      }
    } else {
      setValidationErrors(newValidationErrors);
    }
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.error);
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Team Created SuccessFully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setUploadedImageUrls(null);
      setFile("");
      handleClose();
    }
  }, [resultStats]);
  useEffect(() => {
    if (resultStats1?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats1?.error?.data?.error);
    }
    if (resultStats1?.isSuccess) {
      setApiSpinner(false);

      toast.success("Team Updated SuccessFully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setUploadedImageUrls(null);
      setFile("");
      handleClose();
    }
  }, [resultStats1]);

  const uploadFile = () => {
    document.getElementById("real-file").click();
  };

  return (
    <>
      {title === "Add Team" ? (
        <Button
          variant="primary"
          onClick={teamsPermissions[0]?.create ? handleOpen : null}
          className="send-invoice-btn"
        >
          {teamsPermissions[0]?.create ? (
            <span className="invoice">
              <Plus /> {title}
            </span>
          ) : (
            <span className="invoice">
              <Plus /> {title}
              <Lock />
            </span>
          )}
        </Button>
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              {teamsPermissions[0]?.update ? (
                "Update Team"
              ) : (
                <span>
                  Update
                  <Lock />
                </span>
              )}
            </Tooltip>
          }
        >
          <Button
            variant="white"
            className="mx-2 p-0"
            onClick={teamsPermissions[0]?.update ? handleOpen : null}
          >
            <EditIcon />
          </Button>
        </OverlayTrigger>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">{title}</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={title === "Add Team" ? handleSubmitAdd : handleUpdateTeam}
        >
          <Modal.Body>
            <section>
              <div>
                <Form.Group>
                  <label htmlFor="name">Profile Picture</label>

                  <div className="media">
                    <div className="media-aside align-self-start">
                      <img
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : uploadedImageUrls ||
                              "https://placehold.jp/90x90.png"
                        }
                        style={{
                          width: "90px",
                          height: "90px",
                          objectFit: "cover",
                        }}
                        className="rounded"
                      />
                    </div>
                    <div>
                      <input
                        id="real-file"
                        name="file"
                        type="file"
                        hidden
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="media-body mt-2">
                      {isUploading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Uploading...</span>
                        </div>
                      ) : (
                        <Button
                          variant="secondary"
                          onClick={uploadFile}
                          className="btn-sm"
                        >
                          Upload
                        </Button>
                      )}
                      <p className="card-text mt-2">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "}
                        &nbsp;Allowed Max size of 10Mb
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              Allowed Max size of 10Mb
                            </Tooltip>
                          }
                        >
                          <Button variant="white" className="mx-2 p-0">
                            <Icon.InfoCircle
                              style={{ color: "currentcolor" }}
                            />
                          </Button>
                        </OverlayTrigger> */}
                      </p>
                    </div>
                  </div>
                  {validationErrors.profileImage && (
                    <small style={{ color: "red", marginLeft: "10px" }}>
                      *{validationErrors.profileImage}
                    </small>
                  )}
                </Form.Group>

                <Form.Group>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Team Alpha"
                    value={teamData?.name}
                    onChange={handleInputChange}
                  />
                  {validationErrors.name && (
                    <small style={{ color: "red", marginLeft: "10px" }}>
                      *{validationErrors.name}
                    </small>
                  )}
                </Form.Group>

                {/* <Form.Group className="mb-2">
                  <label htmlFor="location">Location*</label>
                  <select
                    name="location"
                    className="form-control"
                    value={teamData?.location}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Location...</option>
                    {Locations?.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.location && (
                    <small style={{ color: "red", marginLeft: "10px" }}>
                      *{validationErrors.location}
                    </small>
                  )}
                </Form.Group> */}

                <Form.Group className="mb-2">
                  <label htmlFor="locations">Locations</label>

                  <Select
                    name="locations"
                    options={Locations}
                    getOptionLabel={(locations) => locations.name}
                    getOptionValue={(locations) => locations.id}
                    closeMenuOnSelect={false}
                    value={Locations?.filter((location) =>
                      teamData?.locations?.includes(location.id)
                    )}
                    onChange={(selectedLocations) => {
                      const selectedLocationIds = selectedLocations.map(
                        (location) => location.id
                      );
                      setTeamData({
                        ...teamData,
                        locations: selectedLocationIds, // Corrected key: "locations"
                      });
                      setValidationErrors({
                        ...validationErrors,
                        locations: "",
                      });
                    }}
                    isMulti
                    id="package-locations"
                  />

                  {validationErrors?.locations != "" && (
                    <small style={{ color: "red", marginLeft: "5px" }}>
                      *{validationErrors.locations}
                    </small>
                  )}
                </Form.Group>

                <Form.Group>
                  <label htmlFor="location">Status</label>
                  <select
                    name="status"
                    className="form-control"
                    value={teamData?.status}
                    onChange={handleInputChange}
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </Form.Group>

                <Form.Group>
                  <label htmlFor="email">
                    Email <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    // required
                    className="form-control"
                    placeholder="abc@gmail.com"
                    value={teamData?.email}
                    onChange={handleInputChange}
                    // readOnly={isReadable}
                  />
                  {validationErrors?.email && (
                    <small style={{ color: "red", marginLeft: "10px" }}>
                      *{validationErrors.email}
                    </small>
                  )}
                </Form.Group>

                <Form.Group>
                  <label htmlFor="moreInfo">
                    More Info <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="This team is . . ."
                    name="more_info"
                    value={teamData?.more_info}
                    onChange={handleInputChange}
                    // required
                  />
                </Form.Group>
              </div>
              <div className="mt-4">
                <Button
                  variant="primary"
                  disabled={
                    isUploading ||
                    apiSpinner ||
                    !teamData?.name ||
                    !teamData?.locations?.length > 0
                  }
                  type="submit"
                >
                  {submitButtonText}{" "}
                  {isUploading || apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>
                <button
                  className="btn btn-secondary ml-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </section>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Form>
      </Modal>
    </>
  );
};

export default TeamForm;
