import React from "react";
import * as Icon from "react-bootstrap-icons";

const SearchNotFound = () => {
  return (
    <div className="container mt-4 d-flex justify-content-center align-items-center">
      {/* Add a wrapper div to center the content */}
      <div className="d-flex flex-column align-items-center">
        <div className="mt-4 mb-4 w-50 h-50 text-center bg-light p-5 rounded-circle">
          <Icon.FileBreak
            size={200}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              padding: "10%",
            }}
            className="text-center"
          />
        </div>
        <h3 className="text-center mt-4">No record Found</h3>
        <p className="text-center mt-1">
          Try adjusting your search or filter to find what you are looking for.
        </p>
      </div>
    </div>
  );
};

export default SearchNotFound;
