import React from "react";

const SalesPerson = ({ name }) => {
  return (
    <>
      <div className="d-flex align-items-center mb-1 sales-person">
        <label htmlFor="salesperson" className="form-label mb-0 me-2">
          Salesperson:
        </label>
        <small style={{ fontWeight: "bold" }}>{name}</small>
      </div>
    </>
  );
};

export default SalesPerson;
