import React from "react";
import ViewUser from "./ViewUser";
import UpdateUser from "./UpdateUser";
import DeleteUser from "./DeleteUser";
import ViewIndividualMiddleware from "./ViewIndividualMiddleWare";
import { Badge } from "react-bootstrap";
import { getRandomColorDark } from "../../utils/getRandomColorDark";
import { getInitials } from "../../utils/getInitials";
import ViewCompanyEmployeeMiddlWare from "./ViewCompanyEmployeeMiddlwWare";

const Users = ({ items }) => {
  const {
    name,
    profileImg,
    email,
    id,
    address,
    phoneNo,
    cnicImage,
    cnicNo,
    description,
    experienceYears,
    companyDetail,
    companyId,
  } = items;

  return (
    <tr>
      <td>
        <div className="media">
          <div className="media-aside logo-list mr-2">
            {/* <img
              src={
                profileImg ||
                "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              }
              alt=""
              style={{
                // marginRight: "8px",
                borderRadius: "50%",
                width: "38px",
                height: "38px",
                objectFit: "cover",
              }}
            /> */}
            {profileImg ? (
              <img
                // style={{
                //   // marginRight: "8px",
                //   borderRadius: "50%",
                //   width: "48px",
                //   height: "48px",
                //   objectFit: "cover",
                // }}
                src={
                  profileImg ||
                  "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                }
                alt={name}
              />
            ) : (
              <div
                className="rounded-circle"
                style={{
                  width: "48px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: getRandomColorDark(),
                  color: "#fff",
                  fontSize: "12px",
                }}
              >
                {getInitials(name)}
              </div>
            )}
          </div>
          <div className="media-body my-auto">
            <span className="mb-0 table-avatar">
              <b>{name}</b>
            </span>
          </div>
        </div>
      </td>
      <td>{email}</td>
      <td>
        {companyDetail?.name || (
          <Badge bg="badge bg-label-success">Individual</Badge>
        )}
      </td>
      <td>
        <div className="d-flex align-items-center">
          {companyId ? (
            <ViewCompanyEmployeeMiddlWare id={id} />
          ) : (
            <ViewIndividualMiddleware id={id} />
          )}

          {/* <ViewUser
            userId={id}
            userName={name}
            userEmail={email}
            profileImg={profileImg}
            address={address}
            phoneNo={phoneNo}
          />
          <UpdateUser
            userId={id}
            userName={name}
            userEmail={email}
            phoneNo={phoneNo}
            address={address}
            profileImg={profileImg}
            cnicImage={cnicImage}
            cnicNo={cnicNo}
            experienceYears={experienceYears}
            description={description}
          /> */}
          <DeleteUser userId={id} name={name} />
        </div>
      </td>
    </tr>
  );
};

export default Users;
