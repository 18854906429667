import React from "react";

import { Lock } from "../../Assets/MemberSvgs/Icon";
import * as Icon from "react-bootstrap-icons";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";

import { useSelector } from "react-redux";

import { useGlobalContext } from "../../Context/GlobalContext";
import PermissionPage from "../MainShared/PermissionPage";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { useSortableData } from "../../utils/SortingFunction";

import DashboardBookingModal from "../Dashboard/DashboardBookingModal";
import BookingMeetingModal from "./BookingMeetingModal";
import {
  ConfirmationAlert,
  NewconfirmationAlert,
  bookingConfirmationAlert,
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import {
  useDeleteMeetingBookingMutation,
  useGetAllCompaniesQuery,
  useGetAllUpComingBookingQuery,
  useUpdateMeetingBookingMutation,
} from "../../Slices/spaceApi";
import { useEffect } from "react";
import Loader from "../Loader/Loader";
import AddHotDeskBooking from "./AddHotDeskBooking";
// import { convertUTCTimestampToLocal } from "../../utils/convertUTCTimestampToLocal";

function BookingTableView({
  companies,
  meetingData,
  // FetchUpComingBooking,
  Users,
}) {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { InvoicesPermissions, bookingsPermissions, setInvoiceStatus } =
    useGlobalContext();

  const [status, setStatus] = useState("all");

  const { isLoading: bookingLoading, data: FetchUpComingBooking } =
    useGetAllUpComingBookingQuery({
      spaceId: spaceId || currentSpaceId,
      past: "False",
      companyId: status,
      type: 2,
    });

  const { data: FetchCompanies, isLoading: CompaniesLoading } =
    useGetAllCompaniesQuery({
      spaceId: spaceId || currentSpaceId,
    });

  const companiesArray = Array.isArray(FetchCompanies?.users)
    ? FetchCompanies?.users
    : [FetchCompanies];

  const { items, requestSort, sortConfig } = useSortableData(
    FetchUpComingBooking?.bookingss || []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  const [selectedTime, setSelectedTime] = useState([]);
  const [bookingDate, setBookingDate] = useState(getCurrentDate());
  const [showModal, setShowModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const handleBookingButtonClick = () => {
    setShowBookingModal(true);
  };

  const handleCloseBookingModal = () => {
    setShowBookingModal(false);
  };
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [dataFinalized, setDataFinalized] = useState(false);

  function timeStampToTime(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);
    date = date.toLocaleTimeString();
    let split = date.split(":");
    let set = split[2].split(" ");
    let convert = split[0] + ":" + split[1] + " " + set[1];
    return convert;
  }
  function timeStampToDate(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formatter = new Intl.DateTimeFormat("en-GB", options);

    let formattedDate = formatter.format(date);

    return formattedDate;
  }

  const navigate = useNavigate();
  const [updateBooking, resultStats2] = useUpdateMeetingBookingMutation();
  const [deleteMeetingBooking, resultStats] = useDeleteMeetingBookingMutation();

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
  };
  const optionsStatus = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Teams",
      value: 0,
    },
    {
      label: "Individuals",
      value: 0,
    },
  ];

  const handleStatus = (e) => {
    setStatus(e.id);
    // setLoading(true);
  };

  const handleApproveBooking = (item) => {
    bookingConfirmationAlert("Do You Want to Approve This Booking?").then(
      (approve) => {
        if (approve) {
          updateBooking({
            spaceId: spaceId || currentSpaceId,
            id: item.id,
            updateData: { approved: 1 },
          });
        } else if (approve == false) {
          updateBooking({
            spaceId: spaceId || currentSpaceId,
            id: item.id,
            updateData: { approved: 2 },
          });
        }
      }
    );
  };

  const handleDeleteBooking = (id) => {
    NewconfirmationAlert(
      "Want to Cancel This Booking?",
      "",
      "Yes, cancel it!",
      "No, Don't Cancel"
    ).then((willDelete) => {
      if (willDelete) {
        deleteMeetingBooking({
          spaceId: spaceId || currentSpaceId,
          id: id,
        });
      }
    });
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Booking Cancelled successfully.");
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);
  useEffect(() => {
    if (resultStats2?.isSuccess) {
      userDeleteAlert("Booking updated successfully.");
    }
    if (resultStats2?.isError) {
      requiredAlert(resultStats2?.error?.data?.message);
    }
  }, [resultStats2]);

  return (
    <>
      <div className="container-fluid" style={{ padding: "0px 16px" }}>
        <div className="row match-height">
          <div className="col-md-12">
            {bookingsPermissions[0]?.read ? (
              <div className="col-12">
                <h4 className="mb-2">Hot Desk Bookings</h4>

                <div
                  className="card "
                  // style={{ overflow: "scroll", minHeight: "15rem" }}
                >
                  <div className=" d-flex justify-content-between">
                    <div className="col-12 col-md-6  col-lg-4">
                      <input
                        type="search"
                        className="form-control ms-1 mt-3"
                        value={search}
                        onChange={handleSearch}
                        placeholder="Search Bookings..."
                      />
                    </div>
                    <div className="col-12 text-end col-md-6 col-lg-4">
                      <div className="ms-3 mt-3">
                        <button
                          onClick={handleBookingButtonClick}
                          className="btn btn-primary"
                        >
                          Add Booking
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 justify-content-between">
                    <div className="col-12 col-md-3 ms-4">
                      {FetchCompanies && (
                        <Select
                          options={[
                            { id: "all", name: "All" },
                            { id: "0", name: "Individuals" },
                            ...companiesArray,
                          ]}
                          defaultValue={{ id: "all", name: "All" }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          styles={colorStyles}
                          value={companiesArray?.find(
                            (obj) => obj.id === status
                          )}
                          onChange={handleStatus}
                          id="package-facilities"
                        />
                      )}
                    </div>
                  </div>
                  <div className="card-body px-0 pb-0">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col"> Client</th>
                          {/* <th scope="col">Date</th> */}
                          <th>
                            {" "}
                            <div
                              className="d-flex align-items-center sort"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div>Date</div>
                              <div
                                className="d-flex"
                                style={{ flexDirection: "column" }}
                              >
                                <Icon.ChevronUp
                                  onClick={() => requestSort("startTime")}
                                  className={getClassNamesFor("startTime")}
                                  style={{ fontSize: "10px" }}
                                />

                                <Icon.ChevronDown
                                  onClick={() => requestSort("startTime")}
                                  className={getClassNamesFor("startTime")}
                                  style={{ fontSize: "10px" }}
                                />
                              </div>
                            </div>
                          </th>

                          {/* <th scope="col">Time</th> */}
                          <th scope="col">Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingLoading && (
                          <tr>
                            <td colSpan={5} className="fs-5">
                              <Loader />
                            </td>
                          </tr>
                        )}
                        {items?.length > 0 ? (
                          items
                            ?.slice()

                            ?.filter((val) => {
                              if (search == "") {
                                return val;
                              } else if (
                                // val?.userDetails?.[0]?.name
                                val?.member?.name
                                  .toLowerCase()
                                  .includes(search.toLocaleLowerCase())
                              ) {
                                return val;
                              }
                            })
                            ?.map((item, index) => {
                              const {
                                meetingRoomTitle = item.meetingRoom?.title,
                                price = item.meetingRoom?.price,
                                bookingCapacity = item.meetingRoom?.capacity,
                                meetingRoomImagesUrls = item.meetingRoom
                                  ?.imageUrls,
                                startTime,
                                endTime,
                                id,
                                meetingRoomId = item.meetingRoom?.id,
                                memberId = item.member?.id,
                                status = item.approved,
                                cancel = item.cancel,
                              } = item;
                              if (cancel == false) {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="media">
                                        <div className="media-aside mr-2">
                                          <img
                                            src={meetingRoomImagesUrls}
                                            alt=""
                                            className="rounded-circle"
                                            style={{
                                              width: "38px",
                                              height: "38px",
                                            }}
                                          />
                                        </div>
                                        <div
                                          className="mb-0 table-avatar align-self-center"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span>
                                            <b> {item.meetingRoom.title}</b>
                                          </span>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      {item.member?.name ||
                                        item.publicUser.name}
                                    </td>

                                    <td>{timeStampToDate(startTime)}</td>
                                    {/* <td>
                                      {timeStampToTime(startTime)}-
                                      {timeStampToTime(endTime)}
                                    </td> */}
                                    <td>
                                      <Badge
                                        bg={
                                          status == 1
                                            ? "badge bg-label-success"
                                            : status == 2
                                            ? "badge bg-label-danger"
                                            : "badge bg-label-primary"
                                        }
                                      >
                                        {status == 1
                                          ? "Approved"
                                          : status == 2
                                          ? "Not Approved"
                                          : "Pending"}
                                      </Badge>
                                    </td>
                                    <td className="d-flex flex-nowrap">
                                      {/* <ViewVisitor {...item} /> */}
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            {/* {" "}
                                        {bookingsPermissions[0]?.update ? (
                                          "Approve"
                                        ) : (
                                          <span>
                                            Approve <Lock />
                                          </span>
                                        )} */}
                                            Approve
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="white"
                                          className="p-0"
                                          onClick={() =>
                                            bookingsPermissions[0]?.update
                                              ? handleApproveBooking(item)
                                              : null
                                          }
                                        >
                                          <Icon.BookmarkCheck />
                                        </Button>
                                      </OverlayTrigger>
                                      {/* <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip">
                                         
                                            Update
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="white"
                                          className="p-0 ml-1"
                                          onClick={() => {
                                            setSelectedBooking(item);
                                            setShowModal(true);
                                          }}
                                        >
                                          <EditIcon />
                                        </Button>
                                      </OverlayTrigger> */}
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            {bookingsPermissions[0]?.delete ? (
                                              "Cancel"
                                            ) : (
                                              <span>
                                                Cancel <Lock />
                                              </span>
                                            )}
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="white"
                                          className="p-0 ml-1 mt-1"
                                          onClick={() =>
                                            bookingsPermissions[0]?.delete
                                              ? handleDeleteBooking(item.id)
                                              : null
                                          }
                                        >
                                          {/* <DeleteIcon /> */}X
                                        </Button>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                );
                              }
                            })
                        ) : items?.length <= 0 ? (
                          <tr>
                            <td colSpan={5} className="text-left fs-5">
                              No Bookings !
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>

                  {selectedBooking?.id && (
                    <DashboardBookingModal
                      {...selectedBooking}
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                  )}
                </div>
              </div>
            ) : (
              <PermissionPage />
            )}
          </div>
        </div>
      </div>

      {showBookingModal && (
        <AddHotDeskBooking
          handleClose={handleCloseBookingModal}
          companies={companies}
          meetingData={meetingData}
          Users={Users}
          bookingDate={bookingDate}
        />
      )}
    </>
  );
}

export default BookingTableView;
