import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  useGetMeetingRoomPackageQuery,
  useGetPackageQuery,
  useGetSpaceDataQuery,
  useProductListQuery,
  useUpdateQuotationDataMutation,
  useUpdateQuotationsQuery,
} from "../../Slices/spaceApi";
//
import { useState, useEffect } from "react";

import Flatpickr from "react-flatpickr";
import Select from "react-select";
import "./AddQuotation.css";
import { Toaster, toast } from "react-hot-toast";

import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import { ReactComponent as Email } from "./Email.svg";
import { ReactComponent as Phone } from "./Phone.svg";

import { LogoDetails, SubTotal } from "../AddInvoices";
import { useCreateQuotationsMutation } from "../../Slices/spaceApi";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";

import { useNavigate } from "react-router-dom";
import {
  useGetCitiesQuery,
  useGetCountiresQuery,
  useGetStatesQuery,
} from "../../Slices/constantApi";
import Loader from "../Loader/Loader";
import QuoteSubTotal from "./QuoteSubTotal";
import { useGlobalContext } from "../../Context/GlobalContext";
import { Breadcrumb } from "react-bootstrap";
import PackageCalculations from "../AddInvoices/PackageCalculations";
import ProductCalculations from "../AddInvoices/ProductCalculations";
//
function QuotationUpdate() {
  const { rentalFee, serviceCharges, isOverAllTax, geoLocation } =
    useGlobalContext();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Update Quotation");
  }, []);
  const { id } = useParams();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: quotation, isLoading: loading } = useUpdateQuotationsQuery({
    spaceId: spaceId || currentSpaceId,
    id: id,
  });
  const [change, setChange] = useState(true);
  const { data: spaceData, isLoading: spaceLoading } = useGetSpaceDataQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: cities, isLoading: loadingCities } = useGetCitiesQuery();
  const { data: countries, isLoading: loadingCountries } =
    useGetCountiresQuery();
  const { isLoading: productLoading, data: productData } = useProductListQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: fetchPackages, isLoading: packageLoading } = useGetPackageQuery(
    spaceId || currentSpaceId
  );
  const { data: fetchMeetingPackages, isLoading: meetingPackageLoading } =
    useGetMeetingRoomPackageQuery(spaceId || currentSpaceId);

  const [rows, setRows] = useState([]);
  const [meetingRows, setMeetingRows] = useState([]);
  const [productList, setProductList] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState();
  const [apiSpinner, setApiSpinner] = useState(false);

  const [matchingRows, setMatchingRows] = useState(true);
  const [packageCheck, setPackageCheck] = useState([]);
  const [packageData, setPackageData] = useState([]);

  const [matchingMeetingRows, setMatchingMeetingRows] = useState(false);
  const [meetingPackageCheck, setMeetingPackageCheck] = useState([]);
  const [meetingPackageData, setMeetingPackageData] = useState([]);

  const [matchingProducts, setMatchingProducts] = useState(true);
  const [productCheck, setProductCheck] = useState([]);
  // const [productData, setProductData] = useState([]);

  const [formData, setFormData] = useState({});
  const [initialApiCall, setInititalApiCall] = useState(true);

  const { data: states, isLoading: statesLoading } = useGetStatesQuery(
    {
      countryName: formData?.customer?.countryName,
    },
    {
      skip: formData?.customer?.countryName == null || !change,
      refetchOnMountOrArgChange: true,
      // query: {
      //   isOccupies: "False",
      // },
    }
  );
  const { data: Cities, isLoading: citiesLoading } = useGetCitiesQuery(
    {
      stateName: formData?.customer?.stateName,
    },
    {
      skip: formData?.customer?.stateName == null || !change,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (quotation) {
      setFormData(quotation);
    }
  }, [quotation]);

  useEffect(() => {
    if (fetchPackages?.length > 0) {
      setRows(
        quotation?.packages?.map((item) => ({
          packageId: item.package?.id,
          quantity: item.quantity,
          name: item?.package?.name,
          serviceCharges: fetchPackages?.find(
            (item1) => item1.id === item.package?.id
          )?.serviceCharges,
          rentalFee: fetchPackages?.find(
            (item1) => item1.id === item.package?.id
          )?.rentalFee,
          price:
            (fetchPackages?.find((item1) => item1.id === item.package?.id)
              ?.serviceCharges +
              fetchPackages?.find((item1) => item1.id === item.package?.id)
                ?.rentalFee) *
            item.quantity,
          taxRateObj: fetchPackages?.find(
            (item1) => item1.id === item.package?.id
          )?.taxRateObj,
        }))
      );
    }

    setDiscountPercentage(quotation?.discount);
    setMeetingRows(
      quotation?.meetingPackages?.map((item) => ({
        packageId: item.package?.id,
        quantity: item.quantity,
        name: item?.package?.name,
        serviceCharges: fetchMeetingPackages?.find(
          (item1) => item1.id === item.package?.id
        )?.serviceCharges,
        rentalFee: fetchMeetingPackages?.find(
          (item1) => item1.id === item.package?.id
        )?.rentalFee,
        price:
          (fetchMeetingPackages?.find((item1) => item1.id === item.package?.id)
            ?.serviceCharges +
            fetchMeetingPackages?.find((item1) => item1.id === item.package?.id)
              ?.rentalFee) *
          item.quantity,
        taxRateObj: fetchMeetingPackages?.find(
          (item1) => item1.id === item.package?.id
        )?.taxRateObj,
      }))
    );
    setProductList(
      quotation?.purchase?.items?.map((item) => ({
        productId: item.product?.id,
        quantity: item.quantity,
        name: item?.product?.name,
        manageStock: item?.product?.manageStock,
        productQuantity: item?.product?.quantity,
        price: item?.product?.price * item.quantity,
        taxRateObj: productData?.products?.find(
          (item1) => item1.id === item.package?.id
        )?.taxRateObj,
      }))
    );

    setPackageCheck(rows);
    setPackageData(fetchPackages);
    setMeetingPackageCheck(meetingRows);
    setMeetingPackageData(fetchMeetingPackages);
  }, [quotation, fetchPackages, fetchMeetingPackages, productData]);

  useEffect(() => {
    const filteredRows = rows?.filter((row) =>
      fetchPackages?.some((item) => item?.id === row?.packageId)
    );
    if (filteredRows?.length > 0) {
      setMatchingRows(true);
    } else {
      setMatchingRows(false);
      // setRows([
      //   {
      //     packageId: fetchPackages?.[0]?.id,
      //     quantity: 1,
      //     price: fetchPackages?.[0]?.price,
      //     amount: fetchPackages?.[0]?.price,
      //   },
      // ]);
    }
  }, [packageCheck, packageData]);

  useEffect(() => {
    const filteredRows = meetingRows?.filter((row) =>
      fetchMeetingPackages?.some((item) => item?.id === row?.packageId)
    );
    if (filteredRows?.length > 0) {
      setMatchingMeetingRows(true);
    } else {
      setMatchingMeetingRows(false);
    }
  }, [meetingPackageCheck, meetingPackageData]);

  const handleInputChange = (event, index) => {
    let data = [...rows];
    data[index][event.target.name] = event.target.value;

    let findPackage = fetchPackages?.find(
      (item) => item.id == rows?.[index]?.packageId
    );

    let quantity = rows?.[index]?.quantity;

    // data[index]["cost"] = findPackage?.price;
    data[index]["serviceCharges"] = findPackage?.serviceCharges;
    data[index]["rentalFee"] = findPackage?.rentalFee;

    // data[index]["price"] = (findPackage?.price * +quantity).toFixed(2);
    data[index]["price"] = (
      (findPackage?.serviceCharges + findPackage?.rentalFee) *
      +quantity
    ).toFixed(2);
    data[index]["taxRateObj"] = findPackage?.taxRateObj;
    setRows(data);
    setMatchingRows(true);
  };

  const handleMeetingInputChange = (event, index) => {
    let data = [...meetingRows];
    data[index][event.target.name] = event.target.value;

    let findPackage = fetchMeetingPackages?.find(
      (item) => item.id == meetingRows?.[index]?.packageId
    );

    let quantity = meetingRows?.[index]?.quantity;

    data[index]["serviceCharges"] = findPackage?.serviceCharges;
    data[index]["rentalFee"] = findPackage?.rentalFee;

    data[index]["price"] = (
      (findPackage?.serviceCharges + findPackage?.rentalFee) *
      +quantity
    ).toFixed(2);
    data[index]["taxRateObj"] = findPackage?.taxRateObj;

    setMeetingRows(data);
    setMatchingMeetingRows(true);
  };

  const handleAddRow = () => {
    if (fetchPackages.length > 0) {
      const newRow = {
        packageId: fetchPackages?.[0].id,
        quantity: 1,
        serviceCharges: fetchPackages?.[0]?.serviceCharges,
        rentalFee: fetchPackages?.[0]?.rentalFee,
        price:
          fetchPackages?.[0]?.serviceCharges + fetchPackages?.[0]?.rentalFee,
        taxRateObj: fetchPackages?.[0]?.taxRateObj,
      };
      setRows([...rows, newRow]);
    }
  };
  const handleAddMeetingRow = () => {
    if (fetchMeetingPackages.length > 0) {
      const newRow = {
        packageId: fetchMeetingPackages?.[0].id,
        quantity: 1,
        serviceCharges: fetchMeetingPackages?.[0]?.serviceCharges,
        rentalFee: fetchMeetingPackages?.[0]?.rentalFee,
        price:
          fetchMeetingPackages?.[0]?.serviceCharges +
          fetchMeetingPackages?.[0]?.rentalFee,
        taxRateObj: fetchMeetingPackages?.[0]?.taxRateObj,
      };
      setMeetingRows([...meetingRows, newRow]);
    }
  };
  const addProduct = () => {
    if (productData?.products?.length > 0) {
      const newProduct = {
        productId: productData?.products?.[0]?.id,
        productQuantity: productData?.products?.[0]?.quantity,
        manageStock: productData?.products?.[0]?.manageStock,
        quantity: 1,
        price: productData?.products?.[0]?.price,
        taxRateObj: productData?.products?.[0]?.taxRateObj,
      };
      setProductList([...productList, newProduct]);
    }
  };
  const removeProduct = (index) => {
    const list = [...productList];
    list.splice(index, 1);
    setProductList(list);
  };
  const handleProductChange = (index, event) => {
    let data = [...productList];
    data[index][event.target.name] = event.target.value;

    let findProduct = productData?.products?.find(
      (item) => item.id == productList?.[index]?.productId
    );

    let quantity = productList?.[index]?.quantity;

    // data[index]["cost"] = findPackage?.price;
    data[index]["manageStock"] = findProduct?.manageStock;
    data[index]["productQuantity"] = findProduct?.quantity;

    // data[index]["price"] = (findProduct?.price * +quantity).toFixed(2);
    data[index]["price"] = (findProduct?.price * +quantity).toFixed(2);
    data[index]["taxRateObj"] = findProduct?.taxRateObj;

    setProductList(data);
  };
  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows?.splice(index, 1);
    setRows(updatedRows);
  };

  const handleRemoveMeetingRow = (index) => {
    const updatedRows = [...meetingRows];
    updatedRows.splice(index, 1);
    setMeetingRows(updatedRows);
  };
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    setDiscount(quotation?.discount);
  }, [quotation]);

  const handleDiscountChange = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      discount: value,
    }));
  };
  const handleGstChange = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      gst: value,
    }));
  };
  const [total, setTotal] = useState(1);
  const getAfterDiscount = () => {
    if (rows?.length === 0 || discountPercentage === "") {
      return;
    }
    let total = 0;
    rows?.forEach((row) => {
      const { quantity, price } = row;
      if (quantity && price) {
        const amount = quantity * price;
        total += amount;
      }
    });

    meetingRows?.forEach((row) => {
      const { quantity, price } = row;
      if (quantity && price) {
        const amount = quantity * price;
        total += amount;
      }
    });

    const discountAmount = (total * formData?.discount) / 100;
    total -= discountAmount;
    return total;
  };
  const getTotalAmount = () => {
    if (rows?.length === 0 || discountPercentage === "") {
      return;
    }
    let total = 0;
    rows?.forEach((row) => {
      const { quantity, price } = row;
      if (quantity && price) {
        const amount = quantity * price;
        total += amount;
      }
    });

    meetingRows?.forEach((row) => {
      const { quantity, price } = row;
      if (quantity && price) {
        const amount = quantity * price;
        total += amount;
      }
    });

    const discountAmount = (total * formData?.discount) / 100;
    total -= discountAmount;
    let gstPercentage = formData?.gst;
    const gstAmount = (total * gstPercentage) / 100;
    total += gstAmount;

    return total;
  };

  const [toEmail, setToEmail] = useState("");
  const [toPhone, setToPhone] = useState("");
  const handleDate = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      quotationDate: value,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const [attribute, property] = name.split(".");
    setFormData((prevState) => ({
      ...prevState,
      [attribute]: {
        ...prevState[attribute],
        [property]: value,
      },
    }));
  };
  const getSubTotal = () => {
    let subTotal =
      rows?.length !== 0 &&
      rows?.reduce((total, item) => total + parseInt(item.amount, 10) || 0, 0) +
        meetingRows?.reduce(
          (total, item) => total + parseInt(item.amount, 10) || 0,
          0
        );
    return subTotal;
  };
  const [updateQuotationeApi, resultStats] = useUpdateQuotationDataMutation();
  const quotationSubmit = (e) => {
    e.preventDefault();

    const totalAmount = getTotalAmount();

    setFormData((prevState) => ({
      ...prevState,
      total: totalAmount,
    }));
    const updateSpacePackageIds = rows?.map((item) => ({
      quantity: item.quantity,
      package: item.packageId,
    }));

    const updateMeetingPackageIds = meetingRows?.map((item) => ({
      quantity: item.quantity,
      package: item.packageId,
    }));
    // const countryIds = countries?.map((item) => ({
    //   id: item.id,
    // }));

    // const countriesIds = countries?.map((item) => item.id);
    // const countryId = countriesIds.length > 0 ? countriesIds[0] : "";
    // const citiesIds = cities?.map((item) => item.id);
    // const cityId = citiesIds.length > 0 ? citiesIds[0] : "";
    const updateProductIds = productList?.map((item) => ({
      quantity: parseInt(item.quantity),
      product: parseInt(item.productId),
    }));

    const updateData = {
      packages: updateSpacePackageIds,
      meetingPackages: updateMeetingPackageIds,
      quotationDate: new Date(formData?.quotationDate).toISOString(),
      // subTotal: getSubTotal(),
      // gst: formData.gst,
      purchase: {
        items: updateProductIds,
        // member: invoiceData?.member,
        member: null,
      },
      discount: discount,
      terms: formData.terms,
      attachments: formData.attachments,
      space: spaceId || currentSpaceId,
      customer: {
        isCompany: formData.customer.isCompany,
        email: formData.customer.email,
        address: formData.customer.address,
        phone_number: formData.customer.phone_number,
        postalcode: formData.customer.postalcode,
        name: formData.customer.name,
        cityName: formData.customer.cityName,
        countryName: formData.customer.countryName,
        stateName: formData.customer.stateName,
      },
      // total: totalAmount,
    };

    setApiSpinner(true);

    updateQuotationeApi({
      spaceId: spaceId || currentSpaceId,
      id: id,
      updateData: updateData,
    });
  };

  useEffect(() => {
    if (states?.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          stateName: initialApiCall ? formData.customer.stateName : states?.[0],
        },
      }));
    }
  }, [states]);
  useEffect(() => {
    if (Cities?.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          cityName: initialApiCall ? formData.customer.cityName : Cities?.[0],
        },
      }));
    }
  }, [Cities]);

  function handleCountryChange(event) {
    setInititalApiCall(false);
    setFormData((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        countryName: event.target.value,
      },
    }));
    setChange(true);
  }
  function handleStateChange(event) {
    // const selectedCountryId = parseInt(event.target.value, 10);
    setInititalApiCall(false);

    setFormData((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        stateName: event.target.value,
      },
    }));
  }

  function handleCityChange(event) {
    // const selectedCountryId = parseInt(event.target.value, 10);

    setFormData((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        cityName: event.target.value,
      },
    }));
  }

  const handleMemberChange = (isCompany) => {
    setFormData((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        isCompany: isCompany,
      },
    }));
  };

  let navigate = useNavigate();
  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      const errorResponse = resultStats?.error;

      if (errorResponse && errorResponse?.data) {
        if (
          errorResponse.data.email &&
          errorResponse?.data?.email[0] === "This field may not be blank."
        ) {
          requiredAlert("Email Must Not Be Blank");
        }

        if (
          errorResponse.data.name &&
          errorResponse?.data?.name[0] === "This field may not be blank."
        ) {
          requiredAlert("Name Must Not Be Blank");
        }
        if (
          errorResponse.data.phone_number &&
          errorResponse?.data?.phone_number[0] ===
            "This field may not be blank."
        ) {
          requiredAlert("Phone Number Must Not Be Blank");
        }
        if (
          errorResponse.data.postalcode &&
          errorResponse?.data?.postalcode[0] === "This field may not be blank."
        ) {
          requiredAlert("Zip Code Must Not Be Blank");
        }
        if (errorResponse?.data?.message) {
          requiredAlert(errorResponse?.data?.message);
        }
      } else {
        requiredAlert("An error occurred.");
      }

      // requiredAlert(resultStats?.error?.data?.message);
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Quotation Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      navigate("/quotation/list");
    }
  }, [resultStats]);

  // setMatchingRows = rows?.filter((row) =>
  // fetchPackages?.some((item) => item.id === row.packageId)
  // );

  if (
    loading ||
    spaceLoading ||
    loadingCities ||
    loadingCountries ||
    packageLoading ||
    meetingPackageLoading ||
    productLoading
  ) {
    return <Loader height={6.6} />;
  }
  console.log("Product list is", productList);
  return (
    <>
      <section
        className="container-fluid addQuotation mt-3"
        style={{ padding: "0px 16px" }}
      >
        <Breadcrumb className="custom-breadcrumb ml-auto">
          <Breadcrumb.Item>
            <Link to="/quotation/list">Quotations List</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Update Quotation</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row">
          <div className="col-xl-9 col-12">
            <div className="card d-flex">
              <div className="card-body invoice-padding">
                <div className="row invoice-spacing">
                  <div className="col-lg-6">
                    <LogoDetails {...spaceData} />
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex align-items-center mb-1">
                      <label
                        htmlFor="quotationNo"
                        className="d-flex align-items-center mb-0"
                        style={{ minWidth: "150px" }}
                      >
                        Quotation No
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="quotationNo"
                        disabled
                        className="form-control"
                        value={"#00" + quotation?.quotationId || "1"}
                      />
                    </div>
                    <div className="d-flex mt-2 align-items-center">
                      <label
                        htmlFor="quotationNo"
                        style={{ minWidth: "150px" }}
                        className="d-flex mb-0 align-items-center"
                      >
                        Quotation Date
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <Flatpickr
                        className="form-control"
                        value={quotation.quotationDate}
                        data-test="my-custom-value"
                        onChange={handleDate}
                      />
                    </div>
                  </div>
                </div>

                <div className="quotations-main my-3">
                  <div className="row">
                    <div className="quotation-to col-12 col-md-12 mt-3 mt-md-0">
                      <div className="d-flex">
                        <h4>Quotation To &nbsp;</h4>
                        <h6>(Your Client)</h6>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <select
                            name="customer.country"
                            value={formData?.customer?.countryName}
                            onChange={(event) => handleCountryChange(event)}
                            className="form-control"
                          >
                            {countries?.map((item, index) => (
                              <option
                                key={index}
                                value={item}
                                selected={
                                  item == quotation?.customer?.countryName
                                }
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-12">
                          <select
                            name="customer.stateName"
                            id="stateName"
                            className="form-control"
                            placeholder="State (Optional)"
                            value={formData?.customer?.stateName}
                            onChange={(event) => handleStateChange(event)}
                          >
                            {/* <option>{formData?.customer?.stateName}</option> */}
                            {states?.map((item, index) => (
                              <option
                                key={index}
                                value={item}
                                selected={
                                  item == quotation?.customer?.stateName
                                }
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="row d-flex">
                        <div className="col-6">
                          <select
                            name="customer.city"
                            value={formData?.customer?.cityName}
                            onChange={(event) => handleCityChange(event)}
                            className="form-control"
                          >
                            {Cities?.map((item, index) => (
                              <option
                                key={index}
                                value={item}
                                selected={item == quotation?.customer?.cityName}
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            name="customer.postalcode"
                            id="postalCode"
                            className="form-control"
                            placeholder="Postal Code/ZIP Code*"
                            value={formData?.customer?.postalcode}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <input
                            type="text"
                            className="form-control my-2"
                            name="customer.name"
                            id="businessName"
                            placeholder="Name*"
                            value={formData?.customer?.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <input
                            type="text"
                            name="customer.address"
                            className="form-control my-2"
                            id="address"
                            placeholder="Address*"
                            value={formData?.customer?.address}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      {toEmail === "Email" && (
                        <div className="row">
                          <div className="col-12">
                            <input
                              type="email"
                              name="customer.email"
                              className="form-control my-2"
                              id="email"
                              placeholder="Email*"
                              value={formData?.customer?.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      )}
                      {toPhone === "Phone" && (
                        <div className="row">
                          <div className="col-12">
                            <input
                              type="number"
                              name="customer.phone_number"
                              className="form-control my-2"
                              id="phone"
                              placeholder="Phone Number*"
                              value={formData?.customer?.phone_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row flex-row my-3">
                        <div
                          className="col d-flex ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          <Email style={{ width: 25, height: 25 }} />
                          <p
                            onClick={() => {
                              if (toEmail === "") {
                                setToEmail("Email");
                              } else {
                                setToEmail("");
                              }
                            }}
                          >
                            &nbsp;Update Email
                          </p>
                        </div>
                        <div
                          className="col d-flex"
                          style={{ cursor: "pointer" }}
                        >
                          <Phone style={{ width: 25, height: 25 }} />
                          <p
                            onClick={() => {
                              if (toPhone === "") {
                                setToPhone("Phone");
                              } else {
                                setToPhone("");
                              }
                            }}
                          >
                            &nbsp;Update Phone
                          </p>
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="form-check ml-3 ">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="customer.isCompany"
                            id="individualRadio"
                            value={false}
                            checked={formData?.customer?.isCompany === false}
                            onChange={() => handleMemberChange(false)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="individualRadio"
                          >
                            Individual
                          </label>
                        </div>

                        <div className="form-check ml-3 mb-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="customer.isCompany"
                            id="companyRadio"
                            value={true}
                            checked={formData?.customer?.isCompany === true}
                            onChange={() => handleMemberChange(true)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="companyRadio"
                          >
                            Company
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body invoice-padding invoice-product-details border">
                <h6>Packages</h6>
                <form className="source-item">
                  <div>
                    {matchingRows === false &&
                      rows?.map((item, index) => (
                        <p key={index}>
                          Previously Selected <b>{item.name}</b> Is Not
                          Available.
                        </p>
                      ))}
                    {fetchPackages?.length > 0 ? (
                      // rows?.map((input, index) => (
                      //   <div key={index} className="repeater-wrapper my-2">
                      //     <div className="">
                      //       <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                      //         <div className="row w-100 pe-lg-0 pe-3 py-4">
                      //           <div className="col-lg-5 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                      //             <p className="card-text col-title mb-md-50 mb-0">
                      //               Item
                      //             </p>

                      //             <select
                      //               name="packageId"
                      //               value={input.packageId}
                      //               onChange={(e) =>
                      //                 handleInputChange(e, index)
                      //               }
                      //               onClick={(e) => handleInputChange(e, index)}
                      //               className="form-control"
                      //             >
                      //               {fetchPackages?.map((item, index) => {
                      //                 return (
                      //                   <option key={index} value={item.id}>
                      //                     {item.name}
                      //                   </option>
                      //                 );
                      //               })}
                      //             </select>
                      //           </div>

                      //           <div className="col-lg-2 col-12 my-lg-0 my-2">
                      //             <p className="card-text col-title mb-0">
                      //               Quantity
                      //             </p>
                      //             <input
                      //               type="number"
                      //               className="form-control"
                      //               placeholder="1"
                      //               min={1}
                      //               name="quantity"
                      //               value={input.quantity}
                      //               onChange={(e) =>
                      //                 handleInputChange(e, index)
                      //               }
                      //             />
                      //           </div>

                      //           <div className="row w-100 mt-3">
                      //             <div className="col-lg-4 col-12 my-lg-0 my-2">
                      //               <p className="card-text col-title mb-0">
                      //                 Service Charges
                      //               </p>
                      //               <input
                      //                 type="number"
                      //                 className="form-control"
                      //                 placeholder="0"
                      //                 name="serviceCharges"
                      //                 value={input.serviceCharges}
                      //                 disabled
                      //               />
                      //             </div>
                      //             <div className="col-lg-4 col-12 my-lg-0 my-2">
                      //               <p className="card-text col-title mb-0">
                      //                 Rental Fee
                      //               </p>
                      //               <input
                      //                 type="number"
                      //                 className="form-control"
                      //                 placeholder="0"
                      //                 name="rentalFee"
                      //                 value={input.rentalFee}
                      //                 disabled
                      //               />
                      //             </div>

                      //             <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                      //               <p className="card-text col-title mb-md-50 mb-0">
                      //                 Price
                      //               </p>
                      //               <p className="card-text mb-0">
                      //                 {+rows?.[index]?.price}
                      //               </p>
                      //             </div>
                      //           </div>
                      //         </div>
                      //         <div className="d-flex flex-column align-items-center justify-content-between border-left invoice-product-actions py-2 px-2">
                      //           <p
                      //             className="bg-white"
                      //             onClick={(e) => handleRemoveRow(e, index)}
                      //           >
                      //             <CancelIcon />
                      //           </p>
                      //         </div>
                      //       </div>
                      //     </div>
                      //   </div>
                      // ))
                      rows?.map((input, index) => (
                        <div
                          key={index}
                          className={`my-2 ${
                            index % 2 == 0 ? "bg-white" : "bg-light"
                          }`}
                        >
                          <div className="">
                            <div
                              className="col-12 d-flex  position-relative pe-0 pl-3 fs-6"
                              // product-details-border
                            >
                              <div className="row w-100 pe-lg-0 pe-3 py-4">
                                <div className="col-12">
                                  <div className="row w-100">
                                    <div className="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Item
                                      </p>
                                      <select
                                        name="packageId"
                                        value={input.packageId}
                                        onChange={(event) =>
                                          handleInputChange(event, index)
                                        }
                                        className="form-control"
                                      >
                                        {fetchPackages?.map((item, index) => {
                                          return (
                                            <option key={index} value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        quantity
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="1"
                                        min={1}
                                        name="quantity"
                                        value={input?.quantity}
                                        onChange={(event) =>
                                          handleInputChange(event, index)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="row w-100 mt-3">
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Service Charges
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="0"
                                        name="serviceCharges"
                                        value={input.serviceCharges}
                                        disabled
                                      />
                                    </div>
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Rental Fee
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="0"
                                        name="rentalFee"
                                        value={input.rentalFee}
                                        disabled
                                      />
                                    </div>

                                    <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Price
                                      </p>
                                      <p className="card-text mb-0">
                                        {+rows?.[index]?.price}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <hr className="mt-4" />
                                <PackageCalculations
                                  input={input}
                                  isOverAllTax={isOverAllTax}
                                  serviceCharges={serviceCharges}
                                  rentalFee={rentalFee}
                                />
                              </div>

                              <div
                                className="d-flex flex-column align-items-center justify-content-between invoice-product-actions py-2 px-2"
                                // border-left
                              >
                                <CancelIcon
                                  index={() => handleRemoveRow(index)}
                                  // onClick={(e) => handleRemoveRow(e, index)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        <p>No Package is Available</p>
                      </div>
                    )}
                  </div>
                  <div className="">
                    <div className="px-0 mt-3">
                      <button
                        type="button"
                        className="btn btn-primary send-invoice-btn"
                        data-repeater-create=""
                        onClick={handleAddRow}
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="card-body invoice-padding invoice-product-details border">
                <h6>Meeting Packages</h6>
                <form className="source-item">
                  <div>
                    {matchingMeetingRows === false &&
                      meetingRows?.map((item, index) => (
                        <p key={index}>
                          Previously Selected <b>{item.name}</b> Is Not
                          Available.
                        </p>
                      ))}

                    {fetchMeetingPackages.length > 0 ? (
                      meetingRows?.map((input, index) => (
                        // <div key={index} className="repeater-wrapper my-2">
                        //   <div className="">
                        //     <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                        //       <div className="row w-100 pe-lg-0 pe-3 py-4">
                        //         <div className="col-lg-5 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                        //           <p className="card-text col-title mb-md-50 mb-0">
                        //             Item
                        //           </p>
                        //           <select
                        //             name="packageId"
                        //             value={input.packageId}
                        //             onChange={(event) =>
                        //               handleMeetingInputChange(event, index)
                        //             }
                        //             onClick={(event) =>
                        //               handleMeetingInputChange(event, index)
                        //             }
                        //             className="form-control"
                        //           >
                        //             {fetchMeetingPackages?.map(
                        //               (item, index) => {
                        //                 return (
                        //                   <option key={index} value={item.id}>
                        //                     {item.name}
                        //                   </option>
                        //                 );
                        //               }
                        //             )}
                        //           </select>
                        //         </div>

                        //         <div className="col-lg-2 col-12 my-lg-0 my-2">
                        //           <p className="card-text col-title mb-0">
                        //             Quantity
                        //           </p>
                        //           <input
                        //             type="number"
                        //             className="form-control"
                        //             placeholder="1"
                        //             min={1}
                        //             name="quantity"
                        //             value={input.quantity}
                        //             onChange={(event) =>
                        //               handleMeetingInputChange(event, index)
                        //             }
                        //           />
                        //         </div>
                        //         <div className="row w-100 mt-3">
                        //           <div className="col-lg-4 col-12 my-lg-0 my-2">
                        //             <p className="card-text col-title mb-0">
                        //               Service Charges
                        //             </p>
                        //             <input
                        //               type="number"
                        //               className="form-control"
                        //               placeholder="0"
                        //               name="serviceCharges"
                        //               value={input.serviceCharges}
                        //               disabled
                        //             />
                        //           </div>
                        //           <div className="col-lg-4 col-12 my-lg-0 my-2">
                        //             <p className="card-text col-title mb-0">
                        //               Rental Fee
                        //             </p>
                        //             <input
                        //               type="number"
                        //               className="form-control"
                        //               placeholder="0"
                        //               name="rentalFee"
                        //               value={input.rentalFee}
                        //               disabled
                        //             />
                        //           </div>

                        //           <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                        //             <p className="card-text col-title mb-md-50 mb-0">
                        //               Price
                        //             </p>
                        //             <p className="card-text mb-0">
                        //               {+meetingRows?.[index]?.price}
                        //             </p>
                        //           </div>
                        //         </div>
                        //       </div>
                        //       <div className="d-flex flex-column align-items-center justify-content-between border-left invoice-product-actions py-2 px-2">
                        //         <p
                        //           className="bg-white"
                        //           onClick={(e) =>
                        //             handleRemoveMeetingRow(e, index)
                        //           }
                        //         >
                        //           <CancelIcon />
                        //         </p>
                        //       </div>
                        //     </div>
                        //   </div>
                        // </div>
                        <div
                          key={index}
                          className={`my-2 ${
                            index % 2 == 0 ? "bg-white" : "bg-light"
                          }`}
                        >
                          {/* className="repeater-wrapper my-2" */}

                          <div className="">
                            <div className="col-12 d-flex  position-relative pe-0 pl-3 fs-6">
                              <div className="row w-100 pe-lg-0  pe-3  py-4">
                                <div className="row w-100">
                                  <div className="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Item
                                    </p>
                                    <select
                                      name="packageId"
                                      value={input.packageId}
                                      onChange={(event) =>
                                        handleMeetingInputChange(event, index)
                                      }
                                      className="form-control"
                                    >
                                      {fetchMeetingPackages?.map(
                                        (item, index) => {
                                          return (
                                            <option key={index} value={item.id}>
                                              {item?.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                  <div className="col-lg-4 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      quantity
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="1"
                                      min={1}
                                      name="quantity"
                                      value={input?.quantity}
                                      onChange={(event) =>
                                        handleMeetingInputChange(event, index)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row w-100 mt-3">
                                  <div className="col-lg-4 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      Service Charges
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="24"
                                      name="cost"
                                      value={input?.serviceCharges}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-lg-4 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      Rental Fee
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="24"
                                      name="cost"
                                      value={input?.rentalFee}
                                      disabled
                                    />
                                  </div>

                                  <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Price
                                    </p>
                                    <p className="card-text mb-0">
                                      {+meetingRows?.[index]?.price}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-2 px-2">
                                <CancelIcon
                                  index={() => handleRemoveMeetingRow(index)}
                                />
                              </div>
                            </div>
                            <hr className="mt-4" />
                            <PackageCalculations
                              input={input}
                              isOverAllTax={isOverAllTax}
                              serviceCharges={serviceCharges}
                              rentalFee={rentalFee}
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        <p>No Meeting Package is Available</p>
                      </div>
                    )}
                  </div>
                  <div className="">
                    <div className="px-0 mt-3">
                      <button
                        type="button"
                        className="btn btn-primary send-invoice-btn"
                        data-repeater-create=""
                        onClick={handleAddMeetingRow}
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {productData?.products?.length > 0 && (
                <div className="card-body invoice-padding invoice-product-details">
                  <h6>Products</h6>
                  <form className="source-item">
                    <div>
                      {productList?.map((input, index) => (
                        <div
                          key={index}
                          className={`my-2 ${
                            index % 2 == 0 ? "bg-white" : "bg-light"
                          }`}
                          // className="repeater-wrapper my-2"
                        >
                          <div className="">
                            <div className="col-12 d-flex  position-relative pe-0 pl-3 fs-6">
                              <div className="row w-100 pe-lg-0  pe-3  py-4">
                                <div className="row w-100">
                                  <div className="col-lg-6 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Product
                                    </p>
                                    <select
                                      name="productId"
                                      value={input.productId}
                                      onChange={(event) =>
                                        handleProductChange(index, event)
                                      }
                                      className="form-control"
                                    >
                                      {productData?.products?.map(
                                        (item, index) => {
                                          return (
                                            <option key={index} value={item.id}>
                                              {item?.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                  <div className="col-lg-3 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      quantity
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="1"
                                      min={1}
                                      name="quantity"
                                      value={input?.quantity}
                                      onChange={(event) =>
                                        handleProductChange(index, event)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-3 col-12 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Price
                                    </p>
                                    <p className="card-text mb-0">
                                      {+productList?.[index]?.price}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-2 px-2">
                                <CancelIcon
                                  index={() => removeProduct(index)}
                                />
                              </div>
                            </div>
                            <hr className="mt-4" />
                            <ProductCalculations
                              input={input}
                              isOverAllTax={isOverAllTax}
                              serviceCharges={serviceCharges}
                              rentalFee={rentalFee}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="">
                      <div className="px-0 mt-3">
                        <button
                          type="button"
                          className="btn btn-primary send-invoice-btn"
                          data-repeater-create=""
                          onClick={addProduct}
                        >
                          Add Product
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {/* <div className="row invoice-padding py-5 align-items-center">
                <div className="col-md-3">Total Discount:</div>
                <div className="col-md-9">
                  <input
                    type="number"
                    name="discount"
                    className="form-control"
                    min={0}
                    max={100}
                    placeholder="0 %"
                    value={formData?.discount}
                    onChange={handleDiscountChange || 0}
                  />
                </div>
              </div>
              <hr /> */}

              {/* <div className="col-lg-4  mt-5 mt-lg-0 ml-auto">
                <SubTotal
                  price={getSubTotal()}
                  tax={quotation?.serviceCharges}
                  rentalFee={quotation?.rentalFee}
                  discount={formData?.discount}
                  isOverAllTax={quotation?.setOveralTax}
                />
              </div> */}

              <div className="col-lg-4  mt-5 mt-lg-0 ml-auto">
                {/* <QuoteSubTotal
                  serviceList={rows}
                  serviceMeeting={meetingRows}
                  tax={serviceCharges}
                  rentalFee={rentalFee}
                  discount={discount}
                  setDiscount={setDiscount}
                  isOverAllTax={isOverAllTax}
                /> */}

                <SubTotal
                  serviceList={rows}
                  serviceMeeting={meetingRows}
                  productList={productList}
                  tax={serviceCharges}
                  rentalFee={rentalFee}
                  discount={discount}
                  setDiscount={setDiscount}
                  isOverAllTax={isOverAllTax}
                />
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-12">
            <div
              className="card"
              style={{
                position: window.innerWidth > 1201 ? "fixed" : "static",
                minWidth:
                  window.innerWidth > 1201
                    ? window.innerWidth < 1440
                      ? "18%"
                      : "20%"
                    : "auto",
              }}
            >
              <div className="card-body">
                <button
                  className="btn btn-outline-primary mb-2 w-100"
                  onClick={quotationSubmit}
                  disabled={apiSpinner}
                >
                  Update & Continue
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default QuotationUpdate;
