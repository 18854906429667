import React, { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Line, Bar } from 'react-chartjs-2'

const LineBarChart = ({ data }) => {
  // Extracting labels (months) and datasets (total bookings, total hours, revenue)
  //   const Modalref = useRef(null)
  const roomLabels = data.months.map(month => month.month_year)
  const totalBookings = data.months.map(month => month.totalBooking)
  const totalHours = data.months.map(month => month.totalHours)
  const revenue = data.months.map(month => month.revenue)

  // Chart data configuration
  const chartData = {
    labels: roomLabels,
    datasets: [
      {
        type: 'line',
        label: 'Total Bookings',
        data: totalBookings,
        fill: false,
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        yAxisID: 'y1'
      },
      {
        type: 'bar',
        label: 'Total Hours',
        data: totalHours,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        yAxisID: 'y2'
      }
    ]
  }

  // Chart options configuration
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Values'
        }
      }
    }
  }
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }
  const body = (
    <>
      <div
        id='DataTables_Table_1_wrapper'
        className='dataTables_wrapper dt-bootstrap5 no-footer pb-5'
      >
        <div className='container-fluid '>
          <Line data={chartData} options={options} />
          {/* <Bar data={chartData} options={options} /> */}
        </div>
      </div>
    </>
  )
  return (
    <>
      <div className='col-12 col-md-6 col-lg-4 mb-4 ' onClick={openModal}>
        <div className='card card-datatable text-nowrap '>
          {' '}
          <div className='card-header'>{data?.meetingRoomName}</div> {body}
        </div>
      </div>

      <Modal
        show={isModalOpen}
        onHide={closeModal}
        backdrop='static'
        keyboard={false}
        className='w-10'
      >
        <Modal.Header closeButton className='h-0'>
          {data?.meetingRoomName}
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
      </Modal>
    </>
  )
}

export default LineBarChart
