import React from "react";
import { useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import {
  useDeleteInvoiceV2Mutation,
  useDeleteSpaceInvoiceMutation,
} from "../../Slices/spaceApi";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import {
  NewconfirmationAlert,
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useState } from "react";

const DeleteInvoicePopup = ({ id, paid }) => {
  const { InvoicesPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [deleteInvoice, resultStats] = useDeleteInvoiceV2Mutation();
  const [apiSpinner, setApiSpinner] = useState(false);
  const handleInvoice = (e) => {
    e.preventDefault();
    if (paid) {
      NewconfirmationAlert(
        "Want to Delete This Invoice?",
        "You won't be able to revert this!",
        "Yes! Delete it.",
        "No! Cancel."
      ).then((willDelete) => {
        if (willDelete) {
          setApiSpinner(true);
          deleteInvoice({
            spaceId: spaceId || currentSpaceId,
            id: id,
          });
        }
      });
    } else {
      NewconfirmationAlert(
        "Want to Delete This Invoice?",
        "You won't be able to revert this! This will delete associated Package in pending.",
        "Yes! Delete it.",
        "No! Cancel."
      ).then((willDelete) => {
        if (willDelete) {
          setApiSpinner(true);
          deleteInvoice({
            spaceId: spaceId || currentSpaceId,
            id: id,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Invoice Deleted Successfully.");
      setTimeout(() => {
        setApiSpinner(false);
      }, 1000);
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
      setTimeout(() => {
        setApiSpinner(false);
      }, 500);
    }
  }, [resultStats]);

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {InvoicesPermissions[0]?.delete ? (
              "Delete Invoice"
            ) : (
              <span>
                Delete <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          onClick={InvoicesPermissions[0]?.delete ? handleInvoice : null}
          disabled={apiSpinner}
        >
          <DeleteIcon />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default DeleteInvoicePopup;
