import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useUpdatePostMutation } from "../Slices/spaceApi";
import { maxFileSize } from "../utils/maxFileSize";
import Modal from "react-bootstrap/Modal";
import { errorAlertImage, requiredAlert } from "../utils/Alerts";

export default function ShopFilterSidebar({
  openFilter,
  onCloseFilter,
  image,
  content,
  id,
}) {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [type, setType] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageAdd, setMessageAdd] = useState("");
  const [hovered, setHovered] = useState(false);
  const [typeImage, setTypeImage] = useState("");
  const [openImage, setOpenImage] = useState(false);

  const [updatePost, resultStats] = useUpdatePostMutation();
  const [selectedImage, setSelectedImage] = useState(null);

  const [postData, setPostData] = useState({
    image: image,
    content: content,
  });
  const [file, setFile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // useEffect(() => {
  //   if (selectedImage) {
  //     alert("I am here inide if condition");
  //     setLoading(true);
  //     if (
  //       selectedImage.type &&
  //       (selectedImage.type.includes("image/jpeg") ||
  //         selectedImage.type.includes("image/jpg") ||
  //         selectedImage.type.includes("image/png"))
  //     ) {
  //       const fd = new FormData();
  //       fd.append("image", selectedImage);
  //       axios
  //         .post("/uploadImage/", fd)
  //         .then((resp) => {
  //           setLoading(false);
  //           setOpenImage(true);
  //           setTypeImage(true);
  //           setPostData({ ...postData, image: resp.data?.[0] });
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } else {
  //       setSelectedImage("");
  //       setOpenImage(true);
  //       setLoading(false);
  //       setTypeImage(false);
  //     }
  //   }
  // }, [selectedImage]);
  const fileInputRef = useRef(null);
  const handleFileUpload = () => {
    fileInputRef.current.click(); // Trigger the file input when the "Upload" button is clicked
  };

  const [uploadedImageUrls, setUploadedImageUrls] = useState(null);

  useEffect(() => {
    if (!file == "" && file !== null) {
      setIsUploading(true);

      if (file.size >= 1e7) {
        errorAlertImage("File is too Big");
        setFile("");
        setIsUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        setPostData({ ...postData, image: [] });
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setPostData({ ...postData, image: resp.data?.[0] });
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
      } else {
        setFile("");
        requiredAlert("Please Select Image File");
        setIsUploading(false);
      }
    }
  }, [file]);
  console.log("Post data is", postData);
  console.log("Selected Image is", selectedImage);
  console.log("File is", file);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("event is triggred", selectedFile);
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };
  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   alert("Hnadle file change is done");
  //   if (selectedFile) {
  //     if (selectedFile.size > maxFileSize) {
  //       console.log("File size exceeds the limit (3.1 MB).");
  //     } else {
  //       setSelectedImage(selectedFile);
  //     }
  //   }
  // };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const save = () => {
    if (!file) {
      updatePost({
        spaceId: spaceId || currentSpaceId,
        postId: id,
        data: { content: postData?.content },
      });
    } else {
      updatePost({
        spaceId: spaceId || currentSpaceId,
        postId: id,
        data: postData,
      });
    }
  };
  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setMessageAdd("Successfully Update!");
      setType(true);
      setLoading(false);
      setOpen(true);
      onCloseFilter();
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );
      setMessageAdd(errorMessage);
      setLoading(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats]);

  const openInput = (e) => {
    e.preventDefault();

    document.getElementById("real-file").click(); // Trigger the file input when the image container is clicked
  };
  const upload = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  /* {openImage && (
        <CustomAlert
          type={typeImage ? "success" : "error"}
          message={typeImage ? "Sucessfully Upload!" : "Failed to Upload!"}
          open={openImage}
          setOpen={setOpenImage}
        />
      )}

      {open && (
        <CustomAlert
          type={type ? "success" : "error"}
          message={messageAdd}
          open={open}
          setOpen={setOpen}
        />
      )} */

  return (
    <Modal
      show={openFilter}
      onHide={onCloseFilter}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="bg-light">
        <Modal.Title as="h5">Update Post</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          <div
            className="justify-content-center"
            style={{ padding: "1rem 0 0 3rem" }}
          >
            <div className="form-group">
              <label className="fs-6 mb-2">
                Picture <span className="optional">(Optional)</span>
              </label>
              <div className="media">
                <div className="media-aside uploadgn-self-start">
                  <img
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : image
                        ? image
                        : "https://placehold.jp/90x90.png"
                    }
                    style={{
                      width: "90px",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    className="rounded"
                  />
                </div>
                <div>
                  <input
                    id="real-file"
                    name="file"
                    type="file"
                    onChange={handleFileChange}
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "0",
                      width: "100%",
                      height: "100%",
                      opacity: "0",
                      cursor: "pointer",
                    }}
                  />
                  {/* <button
                      onClick={handleFileUpload}
                      className="btn btn-secondary me-2 btn-sm"
                    >
                      Upload
                    </button> */}
                </div>
                <div className="media-body mt-2">
                  {isUploading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Uploading...</span>
                    </div>
                  ) : (
                    <button
                      id="custom-button"
                      onClick={upload}
                      className="btn btn-secondary me-2 btn-sm"
                    >
                      Upload
                    </button>
                  )}
                  <p className="card-text mt-2">
                    {/* <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "} */}
                    Allowed Max size of 10Mb
                    {/* <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              Allowed Max size of 10Mb
                            </Tooltip>
                          }
                        >
                          <Button variant="white" className="mx-2 p-0">
                            <Icon.InfoCircle
                              style={{ color: "currentcolor" }}
                            />
                          </Button>
                        </OverlayTrigger> */}
                  </p>
                  {/* {!data?.profileImg ? (
                        <div className="invalid-feedback">
                          Please select an image.
                        </div>
                      ) : null} */}
                </div>
              </div>
            </div>
          </div>
          <div
            // className="scrollbar"
            style={{ overflowY: "auto", height: "calc(100% - 64px)" }}
          >
            <div className="card" style={{ padding: "3rem" }}>
              {/* <div className="mb-3" style={{ textAlign: "center" }}>
                <div
                  className="mb-1"
                  style={{
                    padding: "8px",
                    border: "1px dashed rgba(145, 158, 171, 0.2)",
                    borderRadius: "50%",
                    overflow: "hidden",
                    width: "144px",
                    height: "144px",
                    cursor: "pointer",
                    margin: "0 auto",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                      width: "100%",
                      height: "100%",
                      position: "relative",
                    }}
                    onClick={openInput}
                  >
                    <img
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : postData?.image || "/assets/placeholder.svg"
                      }
                      alt="Uploaded Avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <div className="mb-1">
                     
                      <input
                        type="file"
                        id="real-file"
                        style={{ display: "none" }}
                        // onChange={handleFileChange}
                        onChange={(event) =>
                          setSelectedImage(event.target.files[0])
                        }
                      />
                      <label htmlFor="real-file">
                        <div>Upload Image</div>
                      </label>
                    </div>
                    {!postData?.image && !selectedImage ? (
                      <div
                        style={{
                          top: "0",
                          position: "absolute",
                          left: "0",
                          backgroundColor: "rgb(0 0 0 / 50%)",
                          color: "white",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            top: "50%",
                            position: "absolute",
                            left: "50%",
                            transform: "translate(-50% , -50%)",
                          }}
                        >
                          <i className="bxs:camera-plus" />
                          <span style={{ textWrap: "nowrap" }}>
                            Update photo
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "0.5rem",
                    fontSize: "0.75rem",
                    color: "rgba(145, 158, 171, 0.8)",
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png
                  <br /> max size of 3.1 MB
                </div>
              </div> */}

              <textarea
                value={postData?.content}
                onChange={(e) =>
                  setPostData({ ...postData, content: e.target.value })
                }
                placeholder="Share what are you thinking here..."
                style={{ width: "100%", height: "100px" }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={save}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <i
              className="line-md:confirm-circle"
              style={{ marginRight: "5px" }}
            />
            Save changes
          </div>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
