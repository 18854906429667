import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_API_URL;

const createRequest = (url) => ({ url });

export const constantApis = createApi({
  reducerPath: "constantApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const states = getState();
      headers.set(
        "credentials",
        states.spaceReducer.spaceCrede || states.spaceReducer.currentCredentials
      );
    },
  }),
  endpoints: (builder) => ({
    getFacilities: builder.query({
      query: () => createRequest(`/facilities-list/`),
    }),
    getSpaceTypes: builder.query({
      query: () => createRequest(`/spaceTypes/`),
    }),
    getSpaceDurations: builder.query({
      query: () => createRequest(`/spaceDurations/`),
    }),
    getCities: builder.query({
      query: ({ stateName }) => createRequest(`/cities/?state=${stateName}`),
    }),
    getCountires: builder.query({
      query: () => createRequest(`/countries/`),
    }),
    // getStates: builder.query({
    //   query: (countryId) => createRequest(`/country/${countryId}/states/`),
    // }),
    getStates: builder.query({
      query: ({ countryName }) =>
        createRequest(`states/?country=${countryName}`),
    }),
    getSkills: builder.query({
      query: () => createRequest(`/skills-list/`),
    }),
    getCategories: builder.query({
      query: () => createRequest(`/partnerCategories/`),
    }),
    getSupportCategories: builder.query({
      query: () => createRequest(`/supportCategories/`),
    }),
    getNotificationTypes: builder.query({
      query: () => createRequest(`/notificationTypes/`),
    }),
    getPaymentMethods: builder.query({
      query: () => createRequest(`/list-payment-methods/`),
    }),
  }),
});
export const {
  useGetNotificationTypesQuery,
  useGetPaymentMethodsQuery,
  useGetSupportCategoriesQuery,
  useGetStatesQuery,
  useGetFacilitiesQuery,
  useGetSpaceTypesQuery,
  useGetSpaceDurationsQuery,
  useGetCitiesQuery,
  useGetSkillsQuery,
  useGetCountiresQuery,
  useGetCategoriesQuery,
} = constantApis;
