import React, { useState } from "react";
import { useEffect } from "react";
import { EyeOff, EyeOn } from "../../Assets/SettingSvgs/Icon";
import { useChangePswMutation } from "../../Slices/spaceApi";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { SPACECREDS } from "../../Slices/spaceSlice";
import axios from "axios";
import { useGlobalContext } from "../../Context/GlobalContext";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";

const TabPassword = () => {
  const { settingsPermissions } = useGlobalContext();

  const [postData, resultStats] = useChangePswMutation();
  const [type0, setType0] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [valNewPsw, setValNewPsw] = useState("");
  const [valRetypePsw, setValRetypePsw] = useState("");

  const [oldPswValid, setOldPswValid] = useState(true);

  const [type, setType] = useState(true);
  const [match, setMatch] = useState(false);
  const [oldIcon, setOldIcon] = useState(false);
  const [newIcon, setNewIcon] = useState(false);
  const [retypeIcon, setRetypeIcon] = useState(false);
  const [type1, setType1] = useState(true);
  const Dispatch = useDispatch();

  const oldPsw = () => {
    setType0(!type0);
    setOldIcon(!oldIcon);
  };

  const newPsw = () => {
    setType(!type);
    setNewIcon(!newIcon);
  };

  const retypePsw = () => {
    setType1(!type1);
    setRetypeIcon(!retypeIcon);
  };

  useEffect(() => {
    if (valNewPsw.trim() === valRetypePsw.trim()) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }, [valNewPsw, valRetypePsw]);

  const handleOldPsw = (e) => {
    setOldPassword(e.target.value);
    setOldPswValid(e.target.validity.valid);
  };

  const handleNewPsw = (e) => {
    const { value } = e.target;
    setValNewPsw(value);
  };

  const handleRetypePsw = (e) => {
    const { value } = e.target;
    setValRetypePsw(value);
  };

  const reset = () => {
    setOldPassword("");
    setValNewPsw("");
    setValRetypePsw("");
  };

  useEffect(() => {
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.error);
    }
    if (resultStats?.isSuccess) {
      axios.defaults.headers.common["Credentials"] =
        resultStats?.data?.Credentials;

      toast.success("Password Changed!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setTimeout(() => {
        Dispatch(SPACECREDS(resultStats?.data?.Credentials));
      }, 1500);
    }
  }, [resultStats]);

  const changePsw = (event) => {
    event.preventDefault();
    // Validation check
    if (!match) {
      return;
    }

    postData({ data: { oldPassword, newPassword: valNewPsw } });
  };

  return (
    <>
      <div className="card">
        <div className="p-4">
          <form
            className="psw"
            onSubmit={settingsPermissions[0]?.update ? changePsw : null}
          >
            <div className="row">
              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1870">
                  <label
                    htmlFor="account-old-password"
                    className="d-block mb-1"
                    id="__BVID__1870__BV_label_"
                  >
                    Old Password
                  </label>
                  <div>
                    <div role="group" className="input-group">
                      <input
                        id="account-old-password"
                        name="old-password"
                        type={type0 ? "password" : "text"}
                        placeholder="Old Password"
                        value={oldPassword}
                        required
                        onChange={handleOldPsw}
                        className={`form-control-merge form-control ${
                          oldPswValid ? "" : "is-invalid"
                        }`}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text" onClick={oldPsw}>
                          {oldIcon ? EyeOn : EyeOff}
                        </div>
                      </div>
                    </div>
                    {!oldPswValid && (
                      <div className="invalid-feedback">
                        Please enter old password
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1872">
                  <label
                    htmlFor="account-new-password"
                    className="d-block mb-1"
                    id="__BVID__1872__BV_label_"
                  >
                    New Password
                  </label>
                  <div>
                    <div role="group" className="input-group">
                      <input
                        id="account-new-password"
                        required
                        name="new-password"
                        type={type ? "password" : "text"}
                        placeholder="New Password"
                        value={valNewPsw}
                        className={`form-control form-control-merge ${
                          match ? "" : "is-invalid"
                        }`}
                        onChange={handleNewPsw}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text" onClick={newPsw}>
                          {newIcon ? EyeOn : EyeOff}
                        </div>
                      </div>
                    </div>
                    {!match && (
                      <div className="invalid-feedback">
                        Passwords do not match
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1874">
                  <label
                    htmlFor="account-retype-new-password"
                    className="d-block mb-1"
                    id="__BVID__1874__BV_label_"
                  >
                    Retype New Password
                  </label>
                  <div>
                    <div role="group" className="input-group">
                      <input
                        id="account-retype-new-password"
                        name="retype-password"
                        required
                        type={type1 ? "password" : "text"}
                        placeholder="Retype New Password"
                        className={`form-control form-control-merge ${
                          match ? "" : "is-invalid"
                        }`}
                        value={valRetypePsw}
                        onChange={handleRetypePsw}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text" onClick={retypePsw}>
                          {retypeIcon ? EyeOn : EyeOff}
                        </div>
                      </div>
                    </div>
                    {!match && (
                      <div className="invalid-feedback">
                        Passwords do not match
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2">
                <button
                  type="submit"
                  className="btn me-3 btn-primary"
                  disabled={!settingsPermissions[0]?.update}
                >
                  {settingsPermissions[0]?.update ? (
                    "Save changes"
                  ) : (
                    <span>Save changes{Lock2}</span>
                  )}
                </button>
                <button
                  onClick={settingsPermissions[0]?.update ? reset : null}
                  className="btn btn-outline-secondary"
                  disabled={!settingsPermissions[0]?.update}
                >
                  {settingsPermissions[0]?.update ? (
                    "Reset"
                  ) : (
                    <span>Reset{Lock2}</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TabPassword;
