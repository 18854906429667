import React, { useState } from "react";
import PolicyModal from "./PolicyModal";
import { useSortableData } from "../../utils/SortingFunction";
import DeletePolicy from "./DeletePolicy";

const CancelationPolicy = ({ cancellationPolicies, type }) => {
  const [showModal, setShowModal] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [search, setSearch] = useState("");

  const handleOpenUpdateModal = (policy) => {
    setSelectedPolicy(policy);
    setUpdateModalVisible(true);
  };
  const { items, requestSort, sortConfig } = useSortableData(
    cancellationPolicies?.policies || []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const filteredItems = items
    // ?.slice()
    // .reverse()
    ?.filter((val) => {
      if (search == "") {
        return val;
      } else if (val?.name?.toLowerCase()?.includes(search.toLowerCase())) {
        return val;
      }
    });
  console.log("Cancellation policies in child is", cancellationPolicies);
  return (
    <div className="card">
      <div className="col-12 d-flex p-4 justify-content-between">
        <h4>
          {type == 1 ? "Meeting Room " : "Hot Desk "}Booking Cancellation Policy
        </h4>
        <PolicyModal
          onCloseModal={() => setShowModal(false)} // Close modal from here
          onOpenModal={() => setShowModal(true)}
          showModal={showModal}
          title="Add Policy"
          submitButtonText="Add Policy"
          type={type}
        />
      </div>
      {/* {cancellationPolicies?.policies?.length == 0 && (
        <h6 className="ml-4 mb-4">No policies have been added yet.</h6>
      )} */}
      {!cancellationPolicies?.policies?.some(
        (policy) => policy.type === type
      ) && <h6 className="ml-4 mb-4">No policies have been added yet.</h6>}
      {cancellationPolicies?.policies?.some(
        (policy) => policy.type === type
      ) && (
        <div className="table-responsive mt-3">
          <table className="table">
            <thead>
              <tr>
                {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Name</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("employees:name")}
                      className={getClassNamesFor("employees:name")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("employees:name")}
                      className={getClassNamesFor("employees:name")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th> */}
                {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Hours</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("team")}
                      className={getClassNamesFor("team")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("team")}
                      className={getClassNamesFor("team")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th> */}
                {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Last Login</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("lastlogin")}
                      className={getClassNamesFor("lastlogin")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("lastlogin")}
                      className={getClassNamesFor("lastlogin")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th> */}
                {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Status</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("status")}
                      className={getClassNamesFor("status")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("status")}
                      className={getClassNamesFor("status")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th> */}
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Name</div>
                    <div
                      className="d-flex"
                      style={{ flexDirection: "column" }}
                    ></div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Hours</div>
                    <div
                      className="d-flex"
                      style={{ flexDirection: "column" }}
                    ></div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Deduction Rate</div>
                    <div
                      className="d-flex"
                      style={{ flexDirection: "column" }}
                    ></div>
                  </div>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems?.length > 0 ? (
                filteredItems?.map((item, index) => {
                  if (item.type == type) {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="media-body my-auto d-flex flex-column">
                            {/* <span className="mb-0 table-avatar">{item.role}</span> */}
                            {item.name}
                          </div>
                        </td>
                        <td>
                          <div className="media-body my-auto d-flex flex-column">
                            {/* <span className="mb-0 table-avatar">{item.role}</span> */}
                            {item.hours}
                          </div>
                        </td>
                        <td>
                          <div className="media-body my-auto d-flex flex-column">
                            {/* <span className="mb-0 table-avatar">{item.role}</span> */}
                            {item.deductionRate} %
                          </div>
                        </td>
                        <td className="d-flex flex-nowrap">
                          <PolicyModal
                            policyData={{
                              id: selectedPolicy?.id,
                              name: selectedPolicy?.name,
                              hours: selectedPolicy?.hours,
                              deductionRate: selectedPolicy?.deductionRate,
                            }}
                            onCloseModal={() => setUpdateModalVisible(false)}
                            onOpenModal={() => handleOpenUpdateModal(item)}
                            showModal={updateModalVisible}
                            title="Update Policy"
                            submitButtonText="Update Policy"
                            type={type}
                          />
                          <DeletePolicy {...item} />
                        </td>
                      </tr>
                    );
                  }
                })
              ) : filteredItems?.length == 0 ? (
                <tr>
                  <td colSpan={6} className="text-left fs-5">
                    {search == "" ? "No Policy !" : "No Policy found"}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CancelationPolicy;
