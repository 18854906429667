import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useGetCurrentUserRoleQuery } from "../Slices/spaceApi";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [userPermissionApiCall, setUserPermissionApiCall] = useState(false);
  const [Permissions, setPermissions] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [updateLocation, setUpdateLocation] = useState(false);
  const [dashBoardTitle, setDashBoardTitle] = useState("Dashboard");
  const [currency, setCurrency] = useState("USD");
  const [slack, setSlack] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState({
    label: "Status",
    value: 4,
  });
  const [bannerView, setBannerView] = useState(true);
  const [settingsKey, setSettingsKey] = useState("General");
  const { data: UserRole, isLoading: loading } = useGetCurrentUserRoleQuery(
    {
      spaceId: spaceId || currentSpaceId,
    },
    {
      skip: !userPermissionApiCall || !isLoggedIn,
    }
  );

  const [geoLocation, setGeoLocation] = useState({
    country: "Pakistan",
    state: "Punjab",
    city: "Lahore",
    components: [],
  });
  const [locationCall, setLocationCall] = useState(false);
  useEffect(() => {
    if (locationCall == true) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            fetch(
              `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=32b08dd3de364424b55c5817a4ea008e`
            )
              .then((response) => response.json())
              .then((data) => {
                const components = data.results[0].components;
                const country = components.country;
                const state = components.state;
                const city = components.city;

                setGeoLocation({ country, state, city, components });
              })
              .catch((error) => {
                console.error("Error fetching location information:", error);
              });
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser");
      }
    }
    setLocationCall(false);
  }, [locationCall]);

  const Permissions1 = [
    {
      id: 179,
      code: "WPM-F",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 180,
      code: "WPM-L",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 181,
      code: "WPM-R",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 182,
      code: "WPM-C",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 183,
      code: "WPM-A",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 184,
      code: "PM-T",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 185,
      code: "PM-E",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 186,
      code: "PM-R",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 187,
      code: "PM-V",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 188,
      code: "AF-I",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 189,
      code: "AF-M",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 190,
      code: "AF-P",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 191,
      code: "AF-Q",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 192,
      code: "AF-PO",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 193,
      code: "AF-T",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 194,
      code: "AF-TI",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 195,
      code: "MRM-B",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    {
      id: 196,
      code: "S-S",
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  ];

  const [InvoicesPermissions, setInvoicesPermissions] = useState([]);

  const [membersPermissions, setMemberPermissions] = useState([]);
  const [resourcesPermissions, setResourcesPermissions] = useState([]);

  const [packagesPermissions, setPackagesPermissions] = useState([]);
  const [quotationsPermission, setQuottionsPermissions] = useState([]);
  const [floorsPermissions, setFloorsPermissions] = useState([]);
  const [partnerAndOffersPermissions, setPartnerAndOffersPermissions] =
    useState([]);
  const [toursPermissions, setToursPermissions] = useState([]);
  const [ticketsPermissions, setTicketsPermission] = useState([]);
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [locationsPermissions, setLocationsPermissions] = useState([]);
  const [teamsPermissions, setTeamsPermissions] = useState([]);
  const [employessPermissions, setEmployeesPermissions] = useState([]);
  const [visitsPermissions, setVisitsPermissions] = useState([]);
  const [bookingsPermissions, setBookingsPermissions] = useState([]);
  const [settingsPermissions, setSettingsPermissions] = useState([]);
  const [communityPermissions, setCommunityPermissions] = useState([]);
  const [announcementPermissions, setAnnouncementPermissions] = useState([]);
  //////
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  //////

  useEffect(() => {
    if (UserRole && !loading) {
      setPermissions(UserRole?.permissions);
      setUserPermissionApiCall(false);
    }
  }, [UserRole]);

  useEffect(() => {
    if (Permissions?.length > 0) {
      var desiredPermission = Permissions?.find(
        (permission) => permission.code === "AF-I"
      );
      if (desiredPermission) {
        setInvoicesPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setInvoicesPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }
      desiredPermission = Permissions?.find(
        (permission) => permission.code === "AF-M"
      );

      if (desiredPermission) {
        setMemberPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      }
      desiredPermission = Permissions?.find(
        (permission) => permission.code === "AF-P"
      );

      if (desiredPermission) {
        setPackagesPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setPackagesPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }
      desiredPermission = Permissions?.find(
        (permission) => permission.code === "AF-Q"
      );

      if (desiredPermission) {
        setQuottionsPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setQuottionsPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }
      desiredPermission = Permissions?.find(
        (permission) => permission.code === "AF-PO"
      );

      if (desiredPermission) {
        setPartnerAndOffersPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setPartnerAndOffersPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }

      desiredPermission = Permissions?.find(
        (permission) => permission.code === "AF-T"
      );

      if (desiredPermission) {
        setToursPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setToursPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }

      desiredPermission = Permissions?.find(
        (permission) => permission.code === "AF-TI"
      );

      if (desiredPermission) {
        setTicketsPermission([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setTicketsPermission([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }

      desiredPermission = Permissions?.find(
        (permission) => permission.code === "WPM-F"
      );

      if (desiredPermission) {
        setFloorsPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setFloorsPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }

      desiredPermission = Permissions?.find(
        (permission) => permission.code === "WPM-R"
      );

      if (desiredPermission) {
        setResourcesPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setResourcesPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }

      desiredPermission = Permissions?.find(
        (permission) => permission.code === "WPM-L"
      );

      if (desiredPermission) {
        setLocationsPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setLocationsPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }

      desiredPermission = Permissions?.find(
        (permission) => permission.code === "WPM-A"
      );

      if (desiredPermission) {
        setAnnouncementPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setAnnouncementPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }

      desiredPermission = Permissions?.find(
        (permission) => permission.code === "WPM-C"
      );

      if (desiredPermission) {
        setCommunityPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setCommunityPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }

      desiredPermission = Permissions?.find(
        (permission) => permission.code === "PM-T"
      );

      if (desiredPermission) {
        setTeamsPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setTeamsPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }

      desiredPermission = Permissions?.find(
        (permission) => permission.code === "PM-R"
      );

      if (desiredPermission) {
        setRolesPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setRolesPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }

      desiredPermission = Permissions?.find(
        (permission) => permission.code === "PM-E"
      );

      if (desiredPermission) {
        setEmployeesPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setEmployeesPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }
      desiredPermission = Permissions?.find(
        (permission) => permission.code === "PM-V"
      );

      if (desiredPermission) {
        setVisitsPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setVisitsPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }
      desiredPermission = Permissions?.find(
        (permission) => permission.code === "MRM-B"
      );

      if (desiredPermission) {
        setBookingsPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setBookingsPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }
      desiredPermission = Permissions?.find(
        (permission) => permission.code === "S-S"
      );

      if (desiredPermission) {
        setSettingsPermissions([
          {
            id: desiredPermission?.id,
            code: desiredPermission?.code,
            create: desiredPermission?.create,
            read: desiredPermission?.read,
            update: desiredPermission?.update,
            delete: desiredPermission?.delete,
          },
        ]);
      } else {
        setSettingsPermissions([
          {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        ]);
      }
    } else {
      setPermissions(Permissions1);
    }
  }, [Permissions]);

  useEffect(() => {
    setUserPermissionApiCall(true);
  }, []);

  const [toggleSidebar, setToggleSidebar] = useState(true);
  const [addpayment, setAddPayment] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedValue, setSelectedValue] = useState(3);
  // const [billingOption, setBillingOption] = useState(spaceData?.billing_option);
  const [billingOption, setBillingOption] = useState(1);
  const [openDays, setOpenDays] = useState([]);
  const handleClosePayment = () => setAddPayment(false);
  const handleShowPayment = () => setAddPayment(true);
  const [isOverAllTax, setIsOverallTax] = useState(false);
  const [serviceCharges, setServiceCharges] = useState(0);
  const [rentalFee, setRentalFee] = useState(0);
  const handleCloseCustomer = () => setCustomer(false);
  const handleShowCustomer = () => setCustomer(true);
  const [key, setKey] = useState("Account");
  const [memberKey, setMemberKey] = useState("Employee");
  // if (spaceDataLoading || loading) {
  //   return <Loader height={6.6} />;
  // }

  return (
    <AppContext.Provider
      value={{
        slack,
        setSlack,
        bannerView,
        setBannerView,
        currency,
        setCurrency,
        breadcrumbs,
        setBreadcrumbs,
        dashBoardTitle,
        setDashBoardTitle,
        geoLocation,
        rentalFee,
        setRentalFee,
        serviceCharges,
        setServiceCharges,
        isOverAllTax,
        setIsOverallTax,
        isLoggedIn,
        setIsLoggedIn,
        billingOption,
        partnerAndOffersPermissions,
        toursPermissions,
        ticketsPermissions,
        announcementPermissions,
        communityPermissions,
        InvoicesPermissions,
        rolesPermissions,
        resourcesPermissions,
        floorsPermissions,
        locationsPermissions,
        teamsPermissions,
        employessPermissions,
        visitsPermissions,
        membersPermissions,
        packagesPermissions,
        quotationsPermission,
        bookingsPermissions,
        settingsPermissions,
        key,
        setKey,
        toggleSidebar,
        setToggleSidebar,
        addpayment,
        setAddPayment,
        handleClosePayment,
        handleShowPayment,
        customer,
        email,
        setEmail,
        setGeoLocation,
        handleShowCustomer,
        handleCloseCustomer,
        selectedValue,
        setSelectedValue,
        memberKey,
        setMemberKey,
        invoiceStatus,
        setInvoiceStatus,
        settingsKey,
        setSettingsKey,
        setLocationCall,
        updateLocation,
        setUpdateLocation,
        setBillingOption,
        openDays,
        setOpenDays,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
