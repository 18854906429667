import React, { useState } from "react";
import { Modal, Button } from ".";
import { InvoiceAttach } from "../../Assets/InvoiceSvgs/Icons";

function SendInvoiceBtn(props) {
  const [show, setShow] = useState(false);

  const [from, setFrom] = useState("shelbyComapny@email.com");
  const [to, setTo] = useState("qConsolidated@email.com");
  const [subject, setSubject] = useState(
    "Invoice of purchased Admin Templates"
  );
  const [message, setMessage] = useState("ali");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const functionHandle = () => {};

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="btn btn-primary mb-2 w-100"
        disabled={props.disabled}
      >
        Send Invoice
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title>Send Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="invoice-from" className="form-label">
                From
              </label>
              <input
                type="text"
                className="form-control"
                id="invoice-from"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                placeholder="company@email.com"
              />
            </div>
            <div className="form-group">
              <label for="invoice-to" className="form-label">
                To
              </label>
              <input
                type="text"
                className="form-control"
                id="invoice-to"
                value={to}
                onChange={(e) => setTo(e.target.val)}
                placeholder="company@email.com"
              />
            </div>
            <div className="form-group">
              <label for="invoice-subject" className="form-label">
                Subject
              </label>
              <input
                type="text"
                className="form-control"
                id="invoice-subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Invoice regarding goods"
              />
            </div>
            <div className="form-group">
              <label for="invoice-message" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                name="invoice-message"
                id="invoice-message"
                cols="3"
                rows="11"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              >
                Dear Queen Consolidated, Thank you for your business, always a
                pleasure to work with you! We have generated a new invoice in
                the amount of $95.59 We would appreciate payment of this invoice
                by 05/11/2019
              </textarea>
            </div>
            <div className="form-group">
              <span className="badge badge-light-primary">
                {InvoiceAttach}
                <span className="align-middle">Invoice Attached</span>
              </span>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={functionHandle}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendInvoiceBtn;
