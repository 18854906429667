import React, { useEffect } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useTaxRatesGetQuery } from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";

const TaxRatesView = ({ handleClose, operateId }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data, isLoading, refetch } = useTaxRatesGetQuery({
    spaceId: spaceId || currentSpaceId,
    id: operateId,
  });
  useEffect(() => {
    refetch({ spaceId: spaceId || currentSpaceId, id: operateId });
  }, []);
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="w-10 "
      >
        <Modal.Header closeButton className="">
          <Modal.Title>
            <h5>Category</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isLoading && data ? (
            <>
              <Row className="mb-3 mt-3">
                <Form.Group controlId="TaxRates">
                  <Form.Label>TaxRates Name</Form.Label>
                  <Form.Control
                    className="viewonly"
                    id="TaxRatesName"
                    required
                    disabled
                    type="text"
                    placeholder="TaxRates Name"
                    defaultValue=""
                    value={data.name}
                    name="name"
                    // onChange={handleInput}
                  ></Form.Control>

                  <Form.Control.Feedback type="invalid">
                    Please provide a valid input.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 mt-3">
                <Form.Group controlId="TaxRates">
                  <Form.Label>TaxRates Code </Form.Label>
                  <Form.Control
                    className="viewonly"
                    id="TaxRatesCode"
                    required
                    disabled
                    type="text"
                    placeholder="TaxRates Code"
                    defaultValue=""
                    value={data.TaxRatesCode}
                    name="TaxRatesCode"
                    // onChange={handleInput}
                  ></Form.Control>

                  <Form.Control.Feedback type="invalid">
                    Please provide a valid input.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 mt-3">
                <Form.Group controlId="TaxRates">
                  <Form.Label>Description </Form.Label>
                  <Form.Control
                    className="viewonly"
                    id="description"
                    disabled
                    type="text"
                    placeholder="Description"
                    defaultValue=""
                    value={data.description}
                    name="description"
                    // onChange={handleInput}
                  ></Form.Control>

                  <Form.Control.Feedback type="invalid">
                    Please provide a valid input.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3 mt-3">
                <Form.Group controlId="TaxRatesType">
                  <Form.Label>TaxRatesType </Form.Label>
                  <Form.Control
                    className="viewonly"
                    id="TaxRatesType"
                    required
                    disabled
                    type="text"
                    placeholder="TaxRatesType"
                    defaultValue=""
                    value={
                      data.TaxRatesType == 1
                        ? "Sales"
                        : data.TaxRatesType == 2
                        ? "Payment"
                        : data.TaxRatesType == 3
                        ? "Deposit"
                        : "No type"
                    }
                    name="TaxRatesType"
                    // onChange={handleInput}
                  ></Form.Control>

                  <Form.Control.Feedback type="invalid">
                    Please provide a valid input.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Button
                className="btn btn-light border border-secondary"
                onClick={handleClose}
              >
                CLose
              </Button>
            </>
          ) : (
            <Loader />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TaxRatesView;
