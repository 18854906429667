import React, { useState } from "react";
import "./AddVisitor.css";

import { useCreateVisitorsMutation } from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import getCurrentDate from "../../utils/getCurrentDate";
import { useGlobalContext } from "../../Context/GlobalContext";
import { Breadcrumb } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
function AddVisitor() {
  const navigate = useNavigate();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Add Visitor");
  }, []);
  const [createVisitor, resultStats] = useCreateVisitorsMutation();
  const [apiSpinner, setApiSpinner] = useState(false);
  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDateTime;
  };
  const [visitorData, setVisitorData] = useState({
    name: "",
    companyName: "",
    email: "",
    phone_number: "",
    image: "1.jpg",
    visit: {
      expected_visit_datetime: formatDateTime(
        new Date(new Date().getTime() + 30 * 60000)
      ),
      purpose_of_visit: "",
      location: "",
      person_visiting: "",
    },
  });
  const [errors, setErrors] = useState({
    name: "",
    // email: "",
    // phone_number: "",
    location: "",
  });
  const [dateError, setDateError] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;

    setVisitorData((prevVisitorData) => ({
      ...prevVisitorData,
      [name]: value,
      visit: {
        ...prevVisitorData.visit,
        [name]: value,
      },
    }));

    if (name === "expected_visit_datetime") {
      const selectedDateTime = new Date(value);
      const currentDateTime = new Date();

      if (selectedDateTime < currentDateTime) {
        setDateError("Date or time cannot be in the past");
        return;
      } else {
        setDateError("");
      }
    }

    if (name === "name") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: value.trim() === "" ? "Name is required" : "",
      }));
    } else if (name === "location") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        location: value.trim() === "" ? "Spot Reserved is required" : "",
      }));
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^\d{11,15}$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  const handleSubmit = () => {
    const updatedVisitorData = {
      name: visitorData?.name,
      companyName: visitorData?.company,
      email: visitorData?.email,
      phone_number: visitorData?.phone_number,
      image: "",
      visit: {
        expected_visit_datetime: visitorData?.visit?.expected_visit_datetime,
        purpose_of_visit: visitorData?.visit?.purpose_of_visit,
        location: visitorData?.visit?.location,
        person_visiting: visitorData?.visit?.person_visiting,
      },
    };

    const hasErrors =
      updatedVisitorData.name.trim() === "" ||
      // updatedVisitorData.email.trim() === "" ||
      // updatedVisitorData.phone_number.trim() === "" ||
      updatedVisitorData.visit.location.trim() === "";

    if (hasErrors) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: updatedVisitorData.name.trim() === "" ? "Name is required" : "",

        // email:
        //   updatedVisitorData.email.trim() === "" ? "Email is required" : "",
        // phone_number:
        //   updatedVisitorData.phone_number.trim() === ""
        //     ? "Contact number is required"
        //     : "",
        location:
          updatedVisitorData.visit.location.trim() === ""
            ? "Location is required"
            : "",
      }));
      return;
    }
    if (dateError) {
      return;
    }
    setApiSpinner(true);
    createVisitor({
      spaceId: spaceId || currentSpaceId,
      visitorData: updatedVisitorData,
    });
  };

  useEffect(() => {
    if (resultStats.isError) {
      setApiSpinner(false);
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }
    if (resultStats.isSuccess) {
      setApiSpinner(false);

      toast.success("Visitor Added Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      navigate("/visitors/list");
    }
  }, [resultStats]);
  const calculateMinDateTime = () => {
    const currentDateTime = new Date();
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const hours = String(currentDateTime.getHours()).padStart(2, "0");
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");

    const minDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    return minDateTime;
  };

  return (
    <div
      className="registration container-fluid"
      style={{ padding: "0px 16px" }}
    >
      <Breadcrumb className="custom-breadcrumb ml-auto">
        <Breadcrumb.Item>
          <Link to="/visitors/list">Visitors List</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Add Visititor</Breadcrumb.Item>
      </Breadcrumb>
      <div className={"card  mx-auto"}>
        <div className="card-body">
          <form className="col-md-10">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">Guest</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Guest's Name"
                    value={visitorData?.name}
                    className={`form-control ${
                      errors.name !== "" ? "is-invalid" : ""
                    }`}
                    onChange={handleChange}
                  />
                  {errors.name !== "" && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="company">
                    Company <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="companyName"
                    placeholder="Enter Guest's Company"
                    value={visitorData?.companyName}
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">
                    Email <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Guest's Email"
                    value={visitorData?.email}
                    className="form-control"
                    // className={`form-control ${
                    //   errors.email !== "" ? "is-invalid" : ""
                    // }`}
                    onChange={handleChange}
                  />
                  {/* {errors.email !== "" && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )} */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="contactNumber">
                    Contact Number <span className="optional">(Optional)</span>
                  </label>
                  <PhoneInput
                    placeholder="Enter Guest's Phone No"
                    // name="phoneNo"
                    value={visitorData?.phone_number}
                    // onChange={handleInfo}
                    // min={0}
                    required
                    // pattern="\d{11,15}"
                    onChange={(e) => {
                      setVisitorData({
                        ...visitorData,
                        phone_number: e,
                      });
                    }}
                  />
                  {/* <input
                    type="number"
                    id="contactNumber"
                    // className={`form-control ${
                    //   errors.phone_number !== "" ? "is-invalid" : ""
                    // }`}
                    className="form-control"
                    name="phone_number"
                    placeholder="Enter Guest's Phone No"
                    min={0}
                    value={visitorData?.phone_number}
                    onChange={handleChange}
                  /> */}
                  {/* {errors.phone_number !== "" && (
                    <div className="invalid-feedback">
                      {errors.phone_number}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="personVisiting">
                    Host <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    id="personVisiting"
                    className="form-control"
                    placeholder="Host.."
                    name="person_visiting"
                    value={visitorData?.visit?.person_visiting}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="reason">
                    Information <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    id="reason"
                    name="purpose_of_visit"
                    placeholder="Purpose of Visit"
                    value={visitorData?.visit?.purpose_of_visit}
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </form>

          <div className="col-md-10">
            <div className="row">
              <div className="col-md-6 ">
                <div className="form-group">
                  <label htmlFor="expected_visit_datetime">Date and Time</label>
                  <div className="input-group ">
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        style={{
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                        }}
                      >
                        <Icon.Calendar2Check />
                      </span>
                    </div>
                    <input
                      type="datetime-local"
                      className="form-control"
                      name="expected_visit_datetime"
                      value={formatDateTime(
                        visitorData?.visit?.expected_visit_datetime
                      )}
                      min={calculateMinDateTime()}
                      onChange={handleChange}
                    />
                  </div>
                  {dateError && (
                    <span className="text-danger ml-2">*{dateError}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="location">Spot Reserved</label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Enter Spot Reserved"
                    value={visitorData?.visit?.location}
                    className={`form-control ${
                      errors.location !== "" ? "is-invalid" : ""
                    }`}
                    onChange={handleChange}
                  />
                  {errors.location !== "" && (
                    <div className="invalid-feedback">{errors.location}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <div className="me-2">
              <Link to="/visitors/list">
                <button
                  className="btn btn-secondary flex-fill"
                  disabled={apiSpinner}
                >
                  Cancel
                </button>
              </Link>
            </div>
            <div>
              <button
                className="btn btn-primary flex-fill"
                onClick={handleSubmit}
                disabled={
                  apiSpinner || !visitorData?.name || !visitorData?.location
                }
              >
                Add Visitor{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddVisitor;
