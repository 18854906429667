import React from "react";
import FloorList from "./FloorList";
import MeetingTable from "./MeetingTable";
import PrivateOfficeTable from "./PrivateOfficeTable";
import { useSelector } from "react-redux";
import { useGetSpaceDashboardQuery } from "../../Slices/spaceApi";
import ProgressCard from "../Dashboard/ProgressCard";
import { Tab, Tabs } from "react-bootstrap";

const FloorTable = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: dashboardData, isLoading } = useGetSpaceDashboardQuery(
    spaceId || currentSpaceId
  );

  return (
    <>
      <section
        className="container-fluid app-user-list with-bottom-padding"
        style={{ padding: "0px 16px" }}
      >
        {!isLoading &&
        dashboardData &&
        (!dashboardData?.amenities > 0 ||
          !dashboardData?.meetingRooms > 0 ||
          !dashboardData?.spacePackages > 1 ||
          !dashboardData?.users > 0) ? (
          <div className="card">
            <ProgressCard dashboardData={dashboardData} currentStep={2} />
          </div>
        ) : null}

        {/* <Tabs
          defaultActiveKey="resources"
          id="justify-tab-example"
          className="mb-3"
          variant="underline"
          justify
        >
          <Tab
            style={{ marginRight: "5px" }}
            eventKey="resources"
            title={
              <span className="d-flex align-items-center">
             
                Resources
              </span>
            }
          >
            <div className="card">
              <MeetingTable />
            </div>
          </Tab>
          <Tab
            style={{ marginRight: "5px" }}
            eventKey="privateOffices"
            title={
              <span className="d-flex align-items-center">
             
                Private Offices
              </span>
            }
          >
            <div className="card">
              <PrivateOfficeTable />
            </div>
          </Tab>
        </Tabs> */}

        <h6 className="ml-2 mb-2">Resources</h6>
        <div className="card">
          <MeetingTable />
        </div>
        {/* <h6 className="ml-2">Private Offices</h6>
        <div className="card">
          <PrivateOfficeTable />
        </div> */}
      </section>
    </>
  );
};

export default FloorTable;
