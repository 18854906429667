import React, { useEffect, useState } from "react";
import Select from "react-select";

const AssignOccupancy = ({
  packageId,
  packageQuantity,
  AllSpacePackages,
  Resources,
  SpaceTypes,
  updateRowResources,
}) => {
  const selectedPackage = AllSpacePackages?.find((pkg) => pkg.id === packageId);

  const colorStyles = {
    // Add your custom styles here if needed
  };

  const [selectedResources, setSelectedResources] = useState([]);
  useEffect(() => {
    setSelectedResources([]);
  }, [packageId]);

  const getFilteredResources = (spaceType) => {
    return Resources?.filter((resource) => resource.spaceType === spaceType);
  };
  const getSpaceTypeName = (spaceTypeId) => {
    const spaceType = SpaceTypes?.find((type) => type.id === spaceTypeId);
    return spaceType ? spaceType.name : "Resource";
  };

  const handleResourceChange = (selectedOption, spaceType) => {
    let updatedSelectedResources = [...selectedResources];

    // Remove previously selected resources of the same space type
    updatedSelectedResources = updatedSelectedResources.filter(
      (resource) => resource.spaceType !== spaceType
    );

    // Add newly selected resources
    if (selectedOption) {
      updatedSelectedResources = [
        ...updatedSelectedResources,
        ...selectedOption,
      ];
    }

    setSelectedResources(updatedSelectedResources);
    updateRowResources(updatedSelectedResources);
  };

  //   const handleResourceChange = (selectedOption, spaceType) => {
  //     console.log(
  //       "Selected Option is",
  //       selectedOption,
  //       "And space type is",
  //       spaceType
  //     );
  //     const updatedSelectedResources = selectedResources.filter(
  //       (resource) =>
  //         resource.spaceType !== spaceType ||
  //         selectedOption?.some((option) => option.id === resource.id)
  //     );

  //     if (selectedOption) {
  //       setSelectedResources([...updatedSelectedResources, ...selectedOption]);
  //     } else {
  //       setSelectedResources([...updatedSelectedResources]);
  //     }

  //     updateRowResources(selectedOption);
  //   };

  return (
    <div>
      {selectedPackage &&
        selectedPackage?.spaceTypes?.map((spaceTypeObj) => (
          <div key={spaceTypeObj.id}>
            {/* <label htmlFor={`react-select-${spaceTypeObj.id}`}>
              Space Type {spaceTypeObj.id}
            </label> */}
            <label htmlFor={`react-select-${spaceTypeObj.id}`}>
              {getSpaceTypeName(spaceTypeObj.type)} (Max:{" "}
              {spaceTypeObj.quantity * packageQuantity})
            </label>
            <Select
              id={`react-select-${spaceTypeObj.id}`}
              options={getFilteredResources(spaceTypeObj.type)}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.id}
              styles={colorStyles}
              onChange={(selectedOption) =>
                handleResourceChange(selectedOption, spaceTypeObj.type)
              }
              closeMenuOnSelect={false}
              isMulti
              placeholder={"Select Resources"}
              isOptionDisabled={(option) => {
                const selectedResourcesOfType = selectedResources?.filter(
                  (resource) => resource.spaceType === spaceTypeObj.type
                );
                return (
                  selectedResourcesOfType?.length >=
                  spaceTypeObj?.quantity * packageQuantity
                );
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default AssignOccupancy;
