import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context/GlobalContext";
import loginImg from "../../Assets/loginImg.svg";
import "./index.css";
import axios from "axios";
import { useGlobalAuthContext } from "../../AuthContext/AuthContext";
import { EyeOff, EyeOn } from "../../Assets/SettingSvgs/Icon";
import Spinner from "react-bootstrap/Spinner";
import { Button, Form } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import logo1 from "../../Assets/nDesk/LogoFull.png";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import toast from "react-hot-toast";
import Divider from "./Divider";

const SignUpPage = () => {
  const [password, setPassword] = useState("");
  const [pswIcon, setPswIcon] = useState(false);
  const [type0, setType0] = useState(true);
  const [load, setLoad] = useState(false);
  const [userName, setUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [usernameValid, setUserNameValid] = useState(true);
  const [companyNameValid, setCompanyNameValid] = useState(true);

  const { email, setEmail } = useGlobalContext();
  const navigate = useNavigate();
  const oldPsw = () => {
    setType0(!type0);
    setPswIcon(!pswIcon);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!userName) {
      setUserNameValid(false);
      return;
    }

    if (!email) {
      setEmailValid(false);
      return;
    }
    if (!companyName) {
      setCompanyNameValid(false);
      return;
    }
    try {
      const response = await axios.post("/register-trial/", {
        name: userName,
        companyName,
        email,
        address: "",
      });
      if (response) {
        userDeleteAlert(response?.data?.message);
        navigate("/");
      }
    } catch (error) {
      requiredAlert(error?.response?.data?.error);
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setEmailValid(e.target.validity.valid);
  };
  const handleCompanyName = (e) => {
    const { value } = e.target;
    setCompanyName(value);
    setCompanyNameValid(e.target.validity.valid);
  };
  const handleUserName = (e) => {
    const { value } = e.target;
    setUserName(value);
    setUserNameValid(e.target.validity.valid);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setPasswordValid(e.target.validity.valid);
  };

  return (
    <section id="login" className="vh-100">
      <div className="row w-100 m-0 row-length">
        <Link to="/" className="login-logo d-md-block d-none">
          <img src={logo1} alt="login-logo" />
        </Link>
        <div
          className="col-md-8 p-5 d-none d-md-flex justify-content-center"
          //   style={{ height: "100vh", overflow: "hidden" }}
        >
          <div className="px-5">
            <img src={loginImg} alt="login-img" />
          </div>
        </div>
        <div
          className="col-md-4 p-5 bg-white relative"
          //   style={{ height: "100vh" }}
        >
          <div className="center-form" style={{ padding: "3rem" }}>
            <Link to="/" className="d-block d-md-none text-center">
              <img style={{ width: "145px" }} src={logo1} alt="login-logo" />
            </Link>
            <h3 className="card-title mb-2 text-center text-md-start">
              Welcome to nDesk ! 👋🏻
            </h3>

            <p>Sign up to start your adventure 🚀 </p>
            <form onSubmit={submit} className="auth-login-form mt-2">
              <div role="group" className="form-group">
                <label htmlFor="name" className="d-block mb-0">
                  Name
                </label>
                <div>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    // required
                    value={userName}
                    onChange={handleUserName}
                    placeholder="Johndoe"
                    className={`form-control mt-2 ${
                      usernameValid ? "" : "is-invalid"
                    }`}
                  />
                </div>
                {!usernameValid && (
                  <div className="invalid-feedback">Please enter Username</div>
                )}
              </div>

              <div role="group" className="form-group">
                <label htmlFor="companyName" className="d-block mb-0">
                  Company Name
                </label>
                <div>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    // required
                    value={companyName}
                    onChange={handleCompanyName}
                    placeholder="Johndoe"
                    className={`form-control mt-2 ${
                      companyNameValid ? "" : "is-invalid"
                    }`}
                  />
                </div>
                {!companyNameValid && (
                  <div className="invalid-feedback">
                    Please enter company name
                  </div>
                )}
              </div>

              <div role="group" className="form-group">
                <label htmlFor="login-email" className="d-block mb-0">
                  Email
                </label>
                <div>
                  <input
                    id="login-email"
                    name="email"
                    type="email"
                    // required
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="john@example.com"
                    className={`form-control mt-2 ${
                      emailValid ? "" : "is-invalid"
                    }`}
                  />
                </div>
                {!emailValid && (
                  <div className="invalid-feedback">
                    Please enter correct email
                  </div>
                )}
              </div>
              {/* <fieldset className="form-group" id="__BVID__53">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="login-password" className="mb-0">
                      Password
                    </label>
                  </div>
                  <div className="input-group">
                    <input
                      id="login-password"
                      name="password"
                      type={type0 ? "password" : "text"}
                      //   required
                      value={password}
                      onChange={handlePasswordChange}
                      className={`form-control-merge form-control ${
                        passwordValid ? "" : "is-invalid"
                      }`}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text" onClick={oldPsw}>
                        {pswIcon ? EyeOn : EyeOff}
                      </div>
                    </div>
                  </div>
                </div>
                {!passwordValid && (
                  <div className="invalid-feedback">Please enter password</div>
                )}
              </fieldset> */}

              <Button
                type="submit"
                className="btn btn-primary btn-block mt-2"
                disabled={load}
              >
                {load && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </>
                )}
                {!load ? "Sign Up" : null}
              </Button>
            </form>
            <div className="mt-3 text-center">
              Already have an account?{" "}
              <span
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/login")}
              >
                Sign in instead
              </span>
            </div>
            {/* <Divider />

            <div className="icons text-center d-flex justify-content-center">
              <div className="google-icon btn border p-2">
                <Icon.Google className="text-danger" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUpPage;
