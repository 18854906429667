import React, { useEffect, useState } from "react";
import { Select } from "../AddInvoices";
import { colorStyles } from "../../utils/constant";
import {
  useCreateRoleMutation,
  useGetModulesQuery,
} from "../../Slices/spaceApi";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context/GlobalContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import ReactQuill from "react-quill";
import { Accordion } from "react-bootstrap";
const PermissionCheckboxGroup = ({ label, handlePermissionChange }) => {
  const [permissions, setPermissions] = useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });

  const handleCheckboxChange = (type) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = {
        ...prevPermissions,
        [type]: !prevPermissions[type],
      };
      notifyChanges(updatedPermissions);
      return updatedPermissions;
    });
  };

  const notifyChanges = (updatedPermissions) => {
    handlePermissionChange(label, updatedPermissions);
  };

  return (
    // <div className="d-flex justify-content-around">
    //   <h6>{label}</h6>
    //   <div className="d-flex ">
    //     {Object?.keys(permissions)?.map((type) => (
    //       <div className="form-check ml-3" key={`${label}-${type}`}>
    //         <input
    //           className="form-check-input"
    //           type="checkbox"
    //           id={`${label}${type}Checkbox`}
    //           checked={permissions[type]}
    //           onChange={() => handleCheckboxChange(type)}
    //         />
    //         <label
    //           className="form-check-label"
    //           htmlFor={`${label}${type}Checkbox`}
    //         >
    //           {type.charAt(0).toUpperCase() + type.slice(1)}
    //         </label>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h6>{label}</h6>
      <div className="d-flex">
        {Object?.keys(permissions)?.map((type) => (
          <div className="form-check ml-3" key={`${label}-${type}`}>
            <input
              className="form-check-input"
              type="checkbox"
              id={`${label}${type}Checkbox`}
              checked={permissions[type]}
              onChange={() => handleCheckboxChange(type)}
            />
            <label
              className="form-check-label"
              htmlFor={`${label}${type}Checkbox`}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const AddRoleModal = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: Modules, isLoading: loading } = useGetModulesQuery();
  const [createRole, resultStats] = useCreateRoleMutation();
  const { setKey, rolesPermissions } = useGlobalContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [activeBox, setActiveBox] = useState(1);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [selectedPermissionsWPM, setSelectedPermissionsWPM] = useState({
    floors: [],
    locations: [],
    resources: [],
    community: [],
    announcements: [],
  });

  const [selectedPermissionsPM, setSelectedPermissionsPM] = useState({
    team: [],
    employees: [],
    roles: [],
    visit: [],
  });

  const [selectedPermissionsAF, setSelectedPermissionsAF] = useState({
    invoices: [],
    members: [],
    packages: [],
    quotations: [],
    partnersAndOffers: [],
    tours: [],
    tickets: [],
  });
  const [selectedPermissionsMR, setSelectedPermissionsMR] = useState({
    bookings: [],
  });
  const [selectedPermissionsSettings, setSelectedPermissionsSettings] =
    useState({
      update: 0,
      read: 1,
    });

  const handlePermissionChange = (moduleLabel, permissions) => {
    switch (moduleLabel) {
      case "Floors":
        setSelectedPermissionsWPM((prevPermissions) => ({
          ...prevPermissions,
          floors: permissions,
        }));
        break;
      case "Locations":
        setSelectedPermissionsWPM((prevPermissions) => ({
          ...prevPermissions,
          locations: permissions,
        }));
        break;
      case "Resources":
        setSelectedPermissionsWPM((prevPermissions) => ({
          ...prevPermissions,
          resources: permissions,
        }));
        break;
      case "Community":
        setSelectedPermissionsWPM((prevPermissions) => ({
          ...prevPermissions,
          community: permissions,
        }));
        break;
      case "Announcements":
        setSelectedPermissionsWPM((prevPermissions) => ({
          ...prevPermissions,
          announcements: permissions,
        }));
        break;
      case "Team":
        setSelectedPermissionsPM((prevPermissions) => ({
          ...prevPermissions,
          team: permissions,
        }));
        break;
      case "Employees":
        setSelectedPermissionsPM((prevPermissions) => ({
          ...prevPermissions,
          employees: permissions,
        }));
        break;
      case "Roles":
        setSelectedPermissionsPM((prevPermissions) => ({
          ...prevPermissions,
          roles: permissions,
        }));
        break;
      case "Visit":
        setSelectedPermissionsPM((prevPermissions) => ({
          ...prevPermissions,
          visit: permissions,
        }));
        break;
      case "Invoices":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          invoices: permissions,
        }));
        break;
      case "Members":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          members: permissions,
        }));
        break;
      case "Packages":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          packages: permissions,
        }));
        break;
      case "Quotations":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          quotations: permissions,
        }));
        break;
      case "PartnersAndOffers":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          partnersAndOffers: permissions,
        }));
        break;
      case "Tours":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          tours: permissions,
        }));
        break;
      case "Tickets":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          tickets: permissions,
        }));
        break;
      case "Bookings":
        setSelectedPermissionsMR((prevPermissions) => ({
          ...prevPermissions,
          bookings: permissions,
        }));
        break;
      default:
        break;
    }
  };
  const generatePermissionObject = (permissionsData) => {
    if (permissionsData?.length == 0) {
      return {
        create: 0,
        read: 0,
        update: 0,
        delete: 0,
      };
    }

    if (Array.isArray(permissionsData)) {
      const permissionsObject = {};
      permissionsData.forEach((permission) => {
        permissionsObject[permission.code] = processPermission(permission);
      });
      return permissionsObject;
    } else {
      return {
        [permissionsData.code]: processPermission(permissionsData),
      };
    }
  };

  const processPermission = (permission) => {
    if (
      !(
        permission.create ||
        permission.read ||
        permission.update ||
        permission.delete
      )
    ) {
      return {
        create: 0,
        read: 0,
        update: 0,
        delete: 0,
      };
    }

    let permissionsValue = {
      create: permission.create ? 1 : 0,
      read: permission.read ? 1 : 0,
      update: permission.update ? 1 : 0,
      delete: permission.delete ? 1 : 0,
    };

    return permissionsValue;
  };

  const postRoles = (e) => {
    e.preventDefault();

    // const permissions = generatePermissionObject([
    //   { code: "WPM-F", ...selectedPermissionsWPM.floors },
    //   { code: "WPM-L", ...selectedPermissionsWPM.locations },
    //   { code: "WPM-R", ...selectedPermissionsWPM.resources },
    //   { code: "WPM-C", ...selectedPermissionsWPM.community },
    //   { code: "WPM-A", ...selectedPermissionsWPM.announcements },
    //   { code: "PM-T", ...selectedPermissionsPM.team },
    //   { code: "PM-E", ...selectedPermissionsPM.employees },
    //   { code: "PM-R", ...selectedPermissionsPM.roles },
    //   { code: "PM-V", ...selectedPermissionsPM.visit },
    //   { code: "AF-I", ...selectedPermissionsAF.invoices },
    //   { code: "AF-M", ...selectedPermissionsAF.members },
    //   { code: "AF-P", ...selectedPermissionsAF.packages },
    //   { code: "AF-Q", ...selectedPermissionsAF.quotations },
    //   { code: "AF-PO", ...selectedPermissionsAF.partnersAndOffers },
    //   { code: "AF-T", ...selectedPermissionsAF.tours },
    //   { code: "AF-TI", ...selectedPermissionsAF.tickets },
    //   { code: "MRM-B", ...selectedPermissionsMR.bookings },
    //   {
    //     code: "S-S",
    //     update: parseInt(selectedPermissionsSettings.update),
    //     read: 1,
    //   },
    // ]);
    const permissions = [];

    permissions?.push(
      {
        code: "WPM-F",
        ...generatePermissionObject(selectedPermissionsWPM.floors),
      },
      {
        code: "WPM-L",
        ...generatePermissionObject(selectedPermissionsWPM.locations),
      },
      {
        code: "WPM-R",
        ...generatePermissionObject(selectedPermissionsWPM.resources),
      },
      {
        code: "WPM-C",
        ...generatePermissionObject(selectedPermissionsWPM.community),
      },
      {
        code: "WPM-A",
        ...generatePermissionObject(selectedPermissionsWPM.announcements),
      },
      {
        code: "PM-T",
        ...generatePermissionObject(selectedPermissionsPM.team),
      },
      {
        code: "PM-E",
        ...generatePermissionObject(selectedPermissionsPM.employees),
      },
      {
        code: "PM-R",
        ...generatePermissionObject(selectedPermissionsPM.roles),
      },
      {
        code: "PM-V",
        ...generatePermissionObject(selectedPermissionsPM.visit),
      },
      {
        code: "AF-I",
        ...generatePermissionObject(selectedPermissionsAF.invoices),
      },
      {
        code: "AF-M",
        ...generatePermissionObject(selectedPermissionsAF.members),
      },
      {
        code: "AF-P",
        ...generatePermissionObject(selectedPermissionsAF.packages),
      },
      {
        code: "AF-Q",
        ...generatePermissionObject(selectedPermissionsAF.quotations),
      },
      {
        code: "AF-PO",
        ...generatePermissionObject(selectedPermissionsAF.partnersAndOffers),
      },
      {
        code: "AF-T",
        ...generatePermissionObject(selectedPermissionsAF.tours),
      },
      {
        code: "AF-TI",
        ...generatePermissionObject(selectedPermissionsAF.tickets),
      },
      {
        code: "MRM-B",
        ...generatePermissionObject(selectedPermissionsMR.bookings),
      },
      {
        code: "S-S",
        // update: parseInt(selectedPermissionsSettings.update),
        update: 1,

        read: 1,
      }
    );

    const updatedData = {
      name: name,
      description: description,
      permissions: permissions,
    };
    setApiSpinner(true);

    createRole({
      spaceId: spaceId || currentSpaceId,
      roleData: updatedData,
    });
  };

  const navigate = useNavigate();
  const backToRole = () => {
    setKey("Social");
    navigate("/workplace/Management");
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      if (resultStats?.error?.data?.error) {
        requiredAlert(`Role ${resultStats?.error?.data?.error}`);
      } else {
        requiredAlert(`Role Name Must Not Be Empty`);
      }
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Role Created SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      handleClose();
      setName("");
      setDescription("");
      setSelectedPermissionsWPM({
        floors: [],
        locations: [],
        resources: [],
        community: [],
        announcements: [],
      });
      setSelectedPermissionsPM({
        team: [],
        employees: [],
        roles: [],
        visit: [],
      });
      setSelectedPermissionsAF({
        invoices: [],
        members: [],
        packages: [],
        quotations: [],
        partnersAndOffers: [],
        tours: [],
        tickets: [],
      });
      setSelectedPermissionsMR({
        bookings: [],
      });
      setSelectedPermissionsSettings({
        update: 0,
        read: 1,
      });
    }
  }, [resultStats]);

  return (
    <>
      <Button
        variant="primary"
        onClick={rolesPermissions[0]?.create ? handleShow : null}
        className="send-invoice-btn"
      >
        {rolesPermissions[0]?.create ? (
          <span className="invoice">
            <Plus /> Add Role
          </span>
        ) : (
          <span className="invoice">
            <Plus /> Add Role
            <Lock />
          </span>
        )}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        fade={true}
      >
        <Modal.Header closeButton className="">
          <Modal.Title className="mt-3" as="h3">
            Add New Role
            <p className="text-muted">Set role permissions</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row p-4">
            <div className="col-md-6 ">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter a role name"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="quill-description mb-3">
                <label htmlFor="description" className="form-label">
                  Description <span className="optional">(Optional)</span>
                </label>
                {/* <input
                  type="text-area"
                  className="form-control"
                  id="description"
                  placeholder="Enter role's description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                /> */}
                <textarea
                  rows={2}
                  className="form-control"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {/* <ReactQuill
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  // style={{ height: "20rem" }}
                  className=""
                  placeholder="Write Your Description Here....."
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                /> */}
              </div>
            </div>
          </div>
          <div className="p-4">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>WorkPlace Management</Accordion.Header>
                <Accordion.Body>
                  <div className="col-12">
                    <PermissionCheckboxGroup
                      label="Floors"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Locations"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Resources"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Community"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Announcements"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Peoples' Management</Accordion.Header>
                <Accordion.Body>
                  <div className="col-12 mt-2">
                    <PermissionCheckboxGroup
                      label="Team"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Employees"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Roles"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Visit"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Administrative Functions</Accordion.Header>
                <Accordion.Body>
                  <div className="col-12 mt-2">
                    <PermissionCheckboxGroup
                      label="Invoices"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Members"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Packages"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Quotations"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="PartnersAndOffers"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Tours"
                      handlePermissionChange={handlePermissionChange}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Tickets"
                      handlePermissionChange={handlePermissionChange}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Meeting Room Management</Accordion.Header>
                <Accordion.Body>
                  <div className="col-12 mt-3">
                    <PermissionCheckboxGroup
                      label="Bookings"
                      handlePermissionChange={handlePermissionChange}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Settings
            <div className="col-12">
              <PermissionCheckboxGroup
                label="Settings"
                handlePermissionChange={handlePermissionChange}
              />
            </div> */}
          </div>
          <button
            className="btn btn-primary ml-5"
            disabled={apiSpinner || !name}
            onClick={postRoles}
          >
            Add Role{" "}
            {apiSpinner ? (
              <div
                className="spinner-border text-primary"
                role="status"
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                }}
              ></div>
            ) : (
              ""
            )}
          </button>
          <button
            className="btn btn-secondary ml-2"
            disabled={apiSpinner}
            onClick={handleClose}
          >
            Cancel
          </button>
        </Modal.Body>

        {/* <Modal.Footer> */}
      </Modal>
    </>
  );
};

export default AddRoleModal;
