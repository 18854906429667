import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { useGlobalContext } from "../../Context/GlobalContext";

function UpdateVisit({ id }) {
  const { visitsPermissions } = useGlobalContext();

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {visitsPermissions[0]?.update ? (
              "Update Visit"
            ) : (
              <span>
                Update <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        {visitsPermissions[0]?.update ? (
          <Button
            variant="white"
            className="mx-2 p-0"
            as={Link}
            to={`/visitUpdate/${id}`}
          >
            <EditIcon />
          </Button>
        ) : (
          <Button variant="white" className="mx-2 p-0">
            <EditIcon />
          </Button>
        )}
      </OverlayTrigger>
    </div>
  );
}

export default UpdateVisit;
