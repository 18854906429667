import React from "react";
import "./Loader.css";
import logo1 from "../../Assets/nDesk/Logo.png";

const Loader = ({ height }) => {
  const loaderHeight = height ? `calc(100vh - ${height}rem)` : "100vh";

  return (
    <div className="Loader" style={{ height: loaderHeight }}>
      {/* <img src={logo1} className="Loader-logo" alt="logo" /> */}
      <div className="Loader-spinner"></div>
    </div>
  );
};

export default Loader;
