import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PasswordImg from "../../Assets/dummyImages/passwordset.png";
import "./index.css";
import { EyeOff, EyeOn } from "../../Assets/SettingSvgs/Icon";
import Spinner from "react-bootstrap/Spinner";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";

import logo1 from "../../Assets/nDesk/LogoFull.png";

import RemoveCookie from "../../utils/removeCookie";

const ConfirmDeactivation = () => {
  const Dispatch = useDispatch();

  const [uuid, setUUID] = useState(null);

  useEffect(() => {
    const currentUrl = window.location.href;

    const uuidRegex = /\/([^/]+)\/?$/;
    const match = currentUrl.match(uuidRegex);

    if (match) {
      const extractedUuid = match[1];

      setUUID(extractedUuid);
    }
  }, []);
  const handleManageAccount = () => {
    try {
      const url = `https://connect.ndesks.com/confirmDelete/${uuid}`;
      fetch(url)
        .then((response) => {
          if (response.ok) {
            RemoveCookie("spaces");
            RemoveCookie("token");
            RemoveCookie("creds");
            RemoveCookie("session-token");
            RemoveCookie("ids");

            sessionStorage.removeItem("token");
            sessionStorage.removeItem("spaces");
            sessionStorage.removeItem("creds");
            window.location.href = "/login";
          } else {
            requiredAlert("Details Not Found");
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    } catch (err) {
      console.error("Error Is", err);
    }
  };

  return (
    <section id="login" className="vh-100">
      <div className="row w-100 m-0 row-length">
        <Link to="/" className="login-logo d-md-block d-none">
          <img src={logo1} alt="login-logo" />
        </Link>
        <div
          className="col-md-8 p-5 d-none d-md-flex justify-content-center"
          style={{ height: "100vh" }}
        >
          <div className="px-5">
            <img
              src={PasswordImg}
              alt="login-img"
              style={{ maxHeight: "100%" }}
            />
          </div>
        </div>
        <div
          className="col-md-4 p-4 bg-white relative"
          style={{ height: "100vh" }}
        >
          <div className="center-form mt-4">
            <Link to="/" className="d-block d-md-none text-center">
              <img style={{ width: "145px" }} src={logo1} alt="login-logo" />
            </Link>
            <h2 className="card-title mb-2 text-center text-md-start">
              Confirm!
            </h2>
            <label htmlFor="login-password" className="mb-0">
              Are you sure to perform this action. This action is irreversible!
            </label>

            <button
              className="btn btn-primary mt-4"
              onClick={handleManageAccount}
            >
              Confirm!
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmDeactivation;
