import React, { useEffect, useRef } from "react";
import "./index.css";
import { useGlobalContext } from "../../Context/GlobalContext";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import {
  LogoDetails,
  InvoiceToDetails,
  SendInvoiceBtn,
  SubTotal,
  SalesPerson,
} from ".";
import {
  useGetSingleInvoiceQuery,
  useSentInvoiceMutation,
  useUpdateInvoiceMutation,
} from "../../Slices/spaceApi";
import { useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import "jspdf-autotable";
import domtoimage from "dom-to-image";
import { useSelector } from "react-redux";
import {
  NewconfirmationAlert,
  paidConfirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import * as Icon from "react-bootstrap-icons";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import AddPaymentModal from "./AddPaymentModal";
import { useGetPaymentMethodsQuery } from "../../Slices/constantApi";

const ViewMember = ({ data }) => {
  const {
    setToggleSidebar,
    InvoicesPermissions,
    setSettingsKey,
    currency,
    rentalFee,

    serviceCharges,

    isOverAllTax,
  } = useGlobalContext();

  const params = useParams();
  const { id } = params;
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [updateInvoiceApi, resultStats] = useUpdateInvoiceMutation();
  const [sentInvoice, resultStats1] = useSentInvoiceMutation();

  const handlePaid = (e) => {
    e.preventDefault();
    paidConfirmationAlert().then((willDelete) => {
      if (willDelete) {
        updateInvoiceApi({
          spaceId: spaceId || currentSpaceId,
          id: id,
          updateData: { paid: true },
        });
      }
    });
  };

  const { isLoading: loading, data: FetchSpaceInvoice } =
    useGetSingleInvoiceQuery({
      spaceId: spaceId || currentSpaceId,
      id: id,
    });
  console.log("Invpice data is", FetchSpaceInvoice);
  const { isLoading: paymentMethodLoading, data: FetchPaymentMethod } =
    useGetPaymentMethodsQuery();

  function timeStampToDate(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);
    return date.toLocaleDateString();
  }

  let name = FetchSpaceInvoice?.member?.name;
  const pdfRef = useRef();
  // const downloadFile = () => {
  //   const doc = new jsPDF();
  //   html2canvas(document.querySelector("#invoice-pdf")).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");

  //     const imgProps = doc.getImageProperties(imgData);
  //     const pdfWidth = doc.internal.pageSize.getWidth() - 20;
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //     doc.addImage(imgData, "PNG", 10, 30, pdfWidth, pdfHeight);
  //     doc.save(`${name}.pdf`);
  //   });
  // };
  // const downloadFile = () => {
  //   const input = pdfRef.current;
  //   html2canvas(input, {
  //     useCORS: true,
  //     allowTaint: true,
  //   }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4", true);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = canvas.width;
  //     const imgHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
  //     const imgY = 30;
  //     pdf.addImage(
  //       imgData,
  //       "PNG",
  //       imgX,
  //       imgY,
  //       imgWidth * ratio,
  //       imgHeight * ratio
  //     );
  //     pdf.save(`${name}.pdf`);
  //   });
  // };
  const downloadFile = async () => {
    const input = document.getElementById("invoice-pdf"); // Assuming 'invoice-pdf' is the correct ID
    const canvas = await html2canvas(input, {
      useCORS: true,
      allowTaint: true,
    });

    const imgData = canvas.toDataURL("image/png");

    const response = await fetch(imgData);
    const blob = await response.blob();

    // Create an Object URL for the blob
    const imgBlobUrl = URL.createObjectURL(blob);

    // Create an Image object and set its src to trigger the onload event
    const img = new Image();
    // img.src = imgData;
    img.src = imgBlobUrl;

    img.onload = () => {
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = img.width;
      const imgHeight = img.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;

      pdf.addImage(img, imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save(`${name}.pdf`);
    };
  };

  const print = async () => {
    // await setToggleSidebar(false);
    window.print();
  };
  const sentInvoiceAsEmail = (e) => {
    e.preventDefault();
    NewconfirmationAlert(
      "Are you sure to sent this invoice as Email!",
      "",
      "Yes! Sent.",
      "No! Cancel."
    ).then((sent) => {
      if (sent) {
        sentInvoice({
          spaceId: spaceId || currentSpaceId,
          invoiceId: id,
        });
      }
    });
  };
  const navigate = useNavigate();

  const addBankDetails = () => {
    setSettingsKey("Information");
    navigate("/setting/accountsetting");
  };
  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Invoice is paid successfully.");
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.error || "An Error Occured!");
    }
  }, [resultStats]);
  useEffect(() => {
    if (resultStats1?.isSuccess) {
      userDeleteAlert("Invoice has been sent as Email.");
    }
    if (resultStats1?.isError) {
      requiredAlert(resultStats1?.error?.data?.error || "An Error Occured!");
    }
  }, [resultStats1]);

  function hexToRgba(hex, alpha) {
    const hexValue = hex.replace("#", "");
    const r = parseInt(hexValue.substring(0, 2), 16);
    const g = parseInt(hexValue.substring(2, 4), 16);
    const b = parseInt(hexValue.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  if (loading || !data) {
    return <Loader height={6.6} />;
  }
  return (
    <>
      <section
        className="container-fluid addMember "
        style={{ padding: "0px 16px" }}
      >
        <Breadcrumb className="custom-breadcrumb ml-auto">
          <Breadcrumb.Item>
            <Link to="/invoice/list">Invoice List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/invoice/${id}/view`} active>
              Invoice View
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="">
          <div className="row">
            <div className="col-xl-9 viewInvoice" id="invoice-pdf" ref={pdfRef}>
              <div className="card d-flex">
                <div
                  className="card-body invoice-padding pb-0"
                  // style={{
                  //   backgroundColor: hexToRgba(data?.brandColors[0], 1),
                  // }}
                >
                  <div
                    className="d-flex justify-content-between flex-lg-row flex-sm-row flex-column invoice-spacing invoice-header mt-0 "
                    style={{ alignItems: "start" }}
                  >
                    <LogoDetails {...FetchSpaceInvoice} {...data} />
                    <div
                      className="invoice-number-date mt-md-0 align-items-lg-end"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "end",
                        margin: "0px",
                      }}
                    >
                      <h4 className="fw-medium mb-2">
                        INVOICE {/* <span className="fw-bold"> */}#
                        {FetchSpaceInvoice?.invoiceId}
                        {/* </span> */}
                        {/* <span className="logo-img me-2">
                          <img
                            src={data?.imageUrls[0]}
                            style={{ width: "50px", borderRadius: "50%" }}
                            alt="logo-img"
                          />
                        </span> */}
                      </h4>

                      <div className="d-flex align-items-center mb-1 voiceFix">
                        <p className="mb-0 invoice-date-title">
                          {FetchSpaceInvoice?.paid
                            ? "Paid Date"
                            : "Published Date:"}
                          &nbsp;&nbsp;
                        </p>
                        <p className="mb-0 fw-bold text-end">
                          {FetchSpaceInvoice?.paid
                            ? new Date(
                                FetchSpaceInvoice?.paid_date
                              ).toLocaleDateString()
                            : new Date(
                                FetchSpaceInvoice?.issued_date
                              ).toLocaleDateString()}
                        </p>
                      </div>
                      {FetchSpaceInvoice?.paid ? (
                        ""
                      ) : (
                        <div className="d-flex align-items-center mb-1 voiceFix">
                          <p className="mb-0 invoice-date-title">
                            Due Date:&nbsp;&nbsp;
                          </p>
                          <p className="mb-0 fw-bold text-end">
                            {new Date(
                              FetchSpaceInvoice?.due_date
                            ).toLocaleDateString()}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* HORIZONTAL ROW */}
                <hr className="invoice-spacing" />

                <div className="card-body invoice-padding pt-0">
                  <div className="row row-bill-to  invoice-spacing">
                    <div className="col-md-6">
                      <InvoiceToDetails FetchSpaceInvoice={FetchSpaceInvoice} />
                    </div>
                    <div className="col-md-6 bill-to d-flex justify-content-md-end mt-md-0 mt-4">
                      <div>
                        <h6 className="invoice-to-title">
                          Pay Bill at Company Account:
                        </h6>
                        <table>
                          <tbody>
                            <tr>
                              <td className="pe-3">Total Due:</td>
                              <td>
                                <strong>
                                  {FetchSpaceInvoice?.total?.toFixed(2)}
                                </strong>{" "}
                                <small className="fw-bold">
                                  {currency && currency}
                                </small>
                              </td>
                            </tr>
                            {data?.city && (
                              <tr>
                                <td className="pe-3">City:</td>
                                <td>{data?.city ? data?.city : ""}</td>
                              </tr>
                            )}

                            <tr>
                              <td className="pe-3">Bank Name:</td>
                              <td>
                                {data?.bankName ? (
                                  data?.bankName
                                ) : (
                                  <div
                                    style={{
                                      textDecoration: "underline",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                    onClick={addBankDetails}
                                  >
                                    Add Bank Details
                                  </div>
                                )}
                              </td>
                            </tr>
                            {data?.branchName && (
                              <tr>
                                <td className="pe-3">Branch Name:</td>
                                <td>
                                  {data?.branchName ? data?.branchName : ""}
                                </td>
                              </tr>
                            )}
                            {data?.country && (
                              <tr>
                                <td className="pe-3">Country:</td>
                                <td>{data?.country ? data?.country : ""}</td>
                              </tr>
                            )}
                            {data?.iban && (
                              <tr>
                                <td className="pe-3">IBAN:</td>
                                <td>{data?.iban ? data?.iban : ""}</td>
                              </tr>
                            )}
                            {data?.swiftCode && (
                              <tr>
                                <td className="pe-3">SWIFT Code:</td>
                                <td>
                                  {data?.swiftCode ? data?.swiftCode : ""}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="">
                          {FetchSpaceInvoice?.type == 1
                            ? "Packages"
                            : FetchSpaceInvoice?.type == 2
                            ? "Services"
                            : FetchSpaceInvoice?.type == 3
                            ? "Security Deposit"
                            : FetchSpaceInvoice?.type == 4
                            ? "Public Meeting Room"
                            : FetchSpaceInvoice?.type == 5
                            ? "Public Hot Desk"
                            : "Memeber Booking"}
                        </th>

                        {FetchSpaceInvoice?.type == 1 ? (
                          <>
                            {" "}
                            <th className="">Service Charges</th>
                            <th className="">Rental Fee</th>
                          </>
                        ) : FetchSpaceInvoice?.type == 2 ? (
                          <>
                            {" "}
                            <th className="">Service Charges</th>
                            <th className="">Rental Fee</th>
                          </>
                        ) : (
                          <th className="">Amount</th>
                        )}

                        <th className="">Quantity</th>
                        {FetchSpaceInvoice?.type == 6 ||
                        FetchSpaceInvoice?.type == 4 ? (
                          <th className="">Tax Amount</th>
                        ) : (
                          <th className="">Tax Rate</th>
                        )}

                        <th className="">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {FetchSpaceInvoice?.type == 2 ? (
                        <tr>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              Hot Desk
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {FetchSpaceInvoice?.subtotal}
                            </p>
                          </td>
                          <td className="">
                            <span className="">1</span>
                          </td>
                          <td className="">
                            <span className="">
                              {FetchSpaceInvoice?.subtotal}
                            </span>
                          </td>
                        </tr>
                      ) : FetchSpaceInvoice?.type == 3 ? (
                        <tr>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              SecurityDeposit
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {FetchSpaceInvoice?.subtotal}
                            </p>
                          </td>
                          <td className="">
                            <span className="">1</span>
                          </td>
                          <td className="">
                            <span className="">N/A</span>
                          </td>
                          <td className="">
                            <span className="">
                              {FetchSpaceInvoice?.subtotal}
                            </span>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {FetchSpaceInvoice?.type == 4 && (
                        <tr>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              Public Meeting Booking
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {FetchSpaceInvoice?.subtotal}
                            </p>
                          </td>
                          <td className="">
                            <span className="">1</span>
                          </td>
                          <td className="">
                            <span className="">
                              {FetchSpaceInvoice?.totalTax}
                            </span>
                          </td>
                          <td className="">
                            <span className="">
                              {" "}
                              {FetchSpaceInvoice?.subtotal +
                                FetchSpaceInvoice?.totalTax}
                            </span>
                          </td>
                        </tr>
                      )}
                      {FetchSpaceInvoice?.type == 5 && (
                        <tr>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              Hot Desk
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {FetchSpaceInvoice?.subtotal}
                            </p>
                          </td>
                          <td className="">
                            <span className="">1</span>
                          </td>
                          <td className="">
                            <span className="">
                              {FetchSpaceInvoice?.totalTax}
                            </span>
                          </td>
                          <td className="">
                            <span className="">
                              {FetchSpaceInvoice?.subtotal +
                                FetchSpaceInvoice?.totalTax}
                            </span>
                          </td>
                        </tr>
                      )}

                      {FetchSpaceInvoice?.type == 6 && (
                        <tr>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              Member Booking
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {FetchSpaceInvoice?.subtotal}
                            </p>
                          </td>
                          <td className="">
                            <span className="">1</span>
                          </td>
                          <td className="">
                            <span className="">
                              {FetchSpaceInvoice?.totalTax}
                            </span>
                          </td>
                          <td className="">
                            <span className="">{FetchSpaceInvoice?.total}</span>
                          </td>
                        </tr>
                      )}

                      {FetchSpaceInvoice?.assignedPackage?.meeting_packages?.map(
                        (item, idx) => {
                          return (
                            <tr key={idx}>
                              <td className="">
                                <p className="card-text font-weight-bold mb-1">
                                  {item.package.name}
                                </p>
                              </td>
                              <td className="">
                                <p className="card-text font-weight-bold mb-1">
                                  {item.package.serviceCharges}
                                </p>
                              </td>
                              <td className="">
                                <p className="card-text font-weight-bold mb-1">
                                  {item.package.rentalFee}
                                </p>
                              </td>
                              <td className="">
                                <span className="">{item.quantity || 1}</span>
                              </td>
                              {/* <td className="">
                                <span className="">Tax Rate</span>
                              </td>
                              <td className="">
                                <span className="">
                                  {(item.quantity || 1) *
                                    (item.package.serviceCharges +
                                      item.package.rentalFee)}{" "}
                                  {currency}
                                </span>
                              </td> */}
                              <td className="">
                                {item?.package?.taxRate && (
                                  <span className="">
                                    {item?.package?.taxRate?.name}
                                    <br />
                                  </span>
                                )}

                                {item?.package?.taxRate ? (
                                  item?.package?.taxRate?.setOveralTax ? (
                                    <small>
                                      Overall Tax:{" "}
                                      {item?.package?.taxRate?.serviceCharges}
                                    </small>
                                  ) : (
                                    <>
                                      <small className="text-nowrap">
                                        Service Charges:{" "}
                                        {item?.package?.taxRate?.serviceCharges}{" "}
                                        %
                                      </small>
                                      <br />
                                      <small className="text-nowrap">
                                        Rental Fee:{" "}
                                        {item?.package?.taxRate?.rentalFee} %
                                      </small>
                                    </>
                                  )
                                ) : isOverAllTax ? (
                                  <small>Overall Tax: {serviceCharges}</small>
                                ) : (
                                  <>
                                    <small className="text-nowrap">
                                      Service Charges: {serviceCharges} %
                                    </small>
                                    <br />
                                    <small className="text-nowrap">
                                      Rental Fee: {rentalFee} %
                                    </small>
                                  </>
                                )}
                              </td>
                              <td className="">
                                <span className="">
                                  {item?.package?.taxRate
                                    ? item?.package?.taxRate?.setOveralTax
                                      ? (item.quantity || 1) *
                                        (item.package.serviceCharges +
                                          (item.package.serviceCharges *
                                            item?.package?.taxRate
                                              ?.serviceCharges) /
                                            100 +
                                          item.package.rentalFee +
                                          (item.package.rentalFee *
                                            item?.package?.taxRate
                                              ?.serviceCharges) /
                                            100)
                                      : (item.quantity || 1) *
                                        (item.package.serviceCharges +
                                          (item.package.serviceCharges *
                                            item?.package?.taxRate
                                              ?.serviceCharges) /
                                            100 +
                                          item.package.rentalFee +
                                          (item.package.rentalFee *
                                            item?.package?.taxRate?.rentalFee) /
                                            100)
                                    : isOverAllTax
                                    ? (item.quantity || 1) *
                                      (item.package.serviceCharges +
                                        (item.package.serviceCharges *
                                          serviceCharges) /
                                          100 +
                                        item.package.rentalFee +
                                        (item.package.rentalFee *
                                          serviceCharges) /
                                          100)
                                    : (item.quantity || 1) *
                                      (item.package.serviceCharges +
                                        (item.package.serviceCharges *
                                          serviceCharges) /
                                          100 +
                                        item.package.rentalFee +
                                        (item.package.rentalFee * rentalFee) /
                                          100)}{" "}
                                  {currency}
                                </span>
                              </td>
                            </tr>
                          );
                        }
                      )}

                      {FetchSpaceInvoice?.assignedPackage?.packages?.map(
                        (item, idx) => {
                          return (
                            <tr key={idx}>
                              <td className="">
                                <p className="card-text font-weight-bold mb-1">
                                  {item.package.name}
                                </p>
                              </td>
                              <td className="">
                                <p className="card-text font-weight-bold mb-1">
                                  {item.package.serviceCharges}
                                </p>
                              </td>
                              <td className="">
                                <p className="card-text font-weight-bold mb-1">
                                  {item.package.rentalFee}
                                </p>
                              </td>
                              <td className="">
                                <span className="">{item.quantity || 1}</span>
                              </td>
                              <td className="">
                                <span className="">
                                  {item?.package?.taxRate
                                    ? item?.package?.taxRate?.name
                                    : ""}
                                  <br />
                                  {item?.package?.taxRate &&
                                  item?.package?.taxRate?.setOveralTax ? (
                                    <small>
                                      Overall Tax:{" "}
                                      {item?.package?.taxRate?.serviceCharges}
                                    </small>
                                  ) : (
                                    <>
                                      <small className="text-nowrap">
                                        Service Charges:{" "}
                                        {item?.package?.taxRate?.serviceCharges}{" "}
                                        %
                                      </small>
                                      <br />
                                      <small className="text-nowrap">
                                        Rental Fee:{" "}
                                        {item?.package?.taxRate?.rentalFee} %
                                      </small>
                                    </>
                                  )}
                                </span>
                              </td>
                              <td className="">
                                <span className="">
                                  {item?.package?.taxRate
                                    ? item?.package?.taxRate?.setOveralTax
                                      ? (item.quantity || 1) *
                                        (item.package.serviceCharges +
                                          (item.package.serviceCharges *
                                            item?.package?.taxRate
                                              ?.serviceCharges) /
                                            100 +
                                          item.package.rentalFee +
                                          (item.package.rentalFee *
                                            item?.package?.taxRate
                                              ?.serviceCharges) /
                                            100)
                                      : (item.quantity || 1) *
                                        (item.package.serviceCharges +
                                          (item.package.serviceCharges *
                                            item?.package?.taxRate
                                              ?.serviceCharges) /
                                            100 +
                                          item.package.rentalFee +
                                          (item.package.rentalFee *
                                            item?.package?.taxRate?.rentalFee) /
                                            100)
                                    : isOverAllTax
                                    ? (item.quantity || 1) *
                                      (item.package.serviceCharges +
                                        (item.package.serviceCharges *
                                          serviceCharges) /
                                          100 +
                                        item.package.rentalFee +
                                        (item.package.rentalFee *
                                          serviceCharges) /
                                          100)
                                    : (item.quantity || 1) *
                                      (item.package.serviceCharges +
                                        (item.package.serviceCharges *
                                          serviceCharges) /
                                          100 +
                                        item.package.rentalFee +
                                        (item.package.rentalFee * rentalFee) /
                                          100)}{" "}
                                  {currency}
                                </span>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>

                {FetchSpaceInvoice?.assignedPackage?.purchase && (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="">Product</th>

                          <th className="">Price</th>

                          <th className="">Quantity</th>
                          <th className="">Tax Rate</th>

                          <th className="">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {FetchSpaceInvoice?.assignedPackage?.purchase?.items?.map(
                          (item, idx) => {
                            return (
                              <tr key={idx}>
                                <td className="">
                                  <p className="card-text font-weight-bold mb-1">
                                    {item.product.name}
                                  </p>
                                </td>
                                <td className="">
                                  <p className="card-text font-weight-bold mb-1">
                                    {item.product.price}
                                  </p>
                                </td>

                                <td className="">
                                  <span className="">{item.quantity || 1}</span>
                                </td>
                                {/* <td>
                                  <span className="">Tax Rate</span>
                                </td> */}
                                <td className="">
                                  {item?.product?.taxRateObj && (
                                    <span className="">
                                      {item?.product?.taxRateObj?.name}
                                      <br />
                                    </span>
                                  )}

                                  {item?.product?.taxRateObj ? (
                                    item?.product?.taxRateObj?.setOveralTax ? (
                                      <small>
                                        Overall Tax:{" "}
                                        {
                                          item?.product?.taxRateObj
                                            ?.serviceCharges
                                        }
                                      </small>
                                    ) : (
                                      <>
                                        <small className="text-nowrap">
                                          Service Charges:{" "}
                                          {
                                            item?.product?.taxRateObj
                                              ?.serviceCharges
                                          }{" "}
                                          %
                                        </small>
                                        <br />
                                        <small className="text-nowrap">
                                          Rental Fee:{" "}
                                          {item?.product?.taxRateObj?.rentalFee}{" "}
                                          %
                                        </small>
                                      </>
                                    )
                                  ) : isOverAllTax ? (
                                    <small>Overall Tax: {serviceCharges}</small>
                                  ) : (
                                    <>
                                      <small className="text-nowrap">
                                        Service Charges: {serviceCharges} %
                                      </small>
                                      <br />
                                      <small className="text-nowrap">
                                        Rental Fee: {rentalFee} %
                                      </small>
                                    </>
                                  )}
                                </td>
                                {/* <td className="">
                                  <span className="">
                                    {(item.quantity || 1) * item.product.price}{" "}
                                    {currency}
                                  </span>
                                </td> */}
                                <td className="">
                                  <span className="">
                                    {item?.product?.taxRateObj
                                      ? item?.product?.taxRateObj?.setOveralTax
                                        ? (item.quantity || 1) *
                                          (item.product.price +
                                            (item.product.price *
                                              item?.product?.taxRateObj
                                                ?.serviceCharges) /
                                              100)
                                        : (item.quantity || 1) *
                                            (item.product.price +
                                              (item.product.price *
                                                item?.product?.taxRateObj
                                                  ?.serviceCharges) /
                                                100) +
                                          (item.product.price *
                                            item?.product?.taxRateObj
                                              ?.rentalFee) /
                                            100
                                      : isOverAllTax
                                      ? (item.quantity || 1) *
                                        (item.product.price +
                                          (item.product.price *
                                            serviceCharges) /
                                            100)
                                      : (item.quantity || 1) *
                                        (item.product.price +
                                          (item.product.price *
                                            serviceCharges) /
                                            100 +
                                          (item.product.price * rentalFee) /
                                            100)}{" "}
                                    {currency}
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                )}

                <hr className="invoice-spacing mt-0" />

                <div className="card-body invoice-padding">
                  <div className="row invoice-sales-total-wrapper ">
                    <div className="col-md-7">
                      <SalesPerson name={FetchSpaceInvoice?.space?.name} />
                      {FetchSpaceInvoice?.discountCode && (
                        <div className="d-flex align-items-center mb-1 sales-person">
                          <label
                            htmlFor="salesperson"
                            className="form-label mb-0 me-2"
                          >
                            Discount Code:
                          </label>
                          <small style={{ fontWeight: "bold" }}>
                            {FetchSpaceInvoice?.discountCode?.code}
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="total col-md-4 offset-md-1 d-flex justify-content-end  mt-5 mt-md-0">
                      <>
                        <div className="invoice-total-wrapper">
                          <div className="invoice-total-item">
                            <p className="invoice-total-title">Subtotal:</p>
                            <p className="invoice-total-amount">
                              {isNaN(FetchSpaceInvoice?.subtotal)
                                ? 0
                                : FetchSpaceInvoice?.subtotal}{" "}
                              <small className="fw-bold">{currency}</small>
                            </p>
                          </div>
                          {/* {FetchSpaceInvoice?.setOveralTax && (
                            <div className="invoice-total-item">
                              <p className="invoice-total-title">
                                Overall Tax:
                              </p>
                              <p className="invoice-total-amount">
                                {FetchSpaceInvoice?.serviceCharges}%
                              </p>
                            </div>
                          )}
                          {!FetchSpaceInvoice?.setOveralTax && (
                            <>
                              <div className="invoice-total-item">
                                <p className="invoice-total-title">
                                  Service Charges:
                                </p>
                                <p className="invoice-total-amount">
                                  {FetchSpaceInvoice?.serviceCharges} %
                                </p>
                              </div>
                              <div className="invoice-total-item">
                                <p className="invoice-total-title">
                                  Rental Fee:
                                </p>
                                <p className="invoice-total-amount">
                                  {FetchSpaceInvoice?.rentalFee} %
                                </p>
                              </div>
                            </>
                          )}
                          <hr />
                          <div className="invoice-total-item p-1">
                          
                            <p className="invoice-total-amount ml-auto">
                             
                              {FetchSpaceInvoice?.subtotal +
                                FetchSpaceInvoice?.totalTax}{" "}
                              <small className="fw-bold">{currency}</small>
                            </p>
                           
                          </div> */}

                          <div className="invoice-total-item">
                            <p className="invoice-total-title">Tax Amount:</p>
                            <p className="invoice-total-amount">
                              {FetchSpaceInvoice?.totalTax?.toFixed(0) || 0}{" "}
                              <small className="fw-bold">{currency}</small>
                            </p>
                          </div>
                          <hr />
                          <div className="invoice-total-item p-1">
                            <p className="invoice-total-amount ml-auto">
                              {FetchSpaceInvoice?.subtotal +
                                FetchSpaceInvoice?.totalTax}{" "}
                              <small className="fw-bold">{currency}</small>
                            </p>
                          </div>

                          <div className="invoice-total-item">
                            <p className="invoice-total-title">Discount:</p>
                            <p className="invoice-total-amount">
                              {FetchSpaceInvoice?.discount_percentage > 100
                                ? 100
                                : FetchSpaceInvoice?.discount_percentage?.toFixed(
                                    2
                                  ) || 0}{" "}
                              %
                            </p>
                          </div>
                          {FetchSpaceInvoice?.discountCode && (
                            <div className="invoice-total-item">
                              <p className="invoice-total-title">
                                Discount Code:
                              </p>
                              <p className="invoice-total-amount">
                                {FetchSpaceInvoice?.discountCode?.amount}{" "}
                                {FetchSpaceInvoice?.discountCode
                                  ?.fixedAmount ? (
                                  <>{currency}</>
                                ) : (
                                  "%"
                                )}
                              </p>
                            </div>
                          )}

                          <div className="invoice-total-item">
                            <p className="invoice-total-title">Adjustment:</p>
                            <p className="invoice-total-amount">
                              {FetchSpaceInvoice?.adjustment || 0}
                            </p>
                          </div>
                          <div className="invoice-total-item"></div>
                          <hr className="my-2" />
                          <div className="invoice-total-item p-1">
                            <p className="invoice-total-title">Total:</p>
                            <p className="invoice-total-amount">
                              {isNaN(
                                FetchSpaceInvoice?.total.toFixed(2) < 0
                                  ? "N/A"
                                  : FetchSpaceInvoice?.total.toFixed(2)
                              )
                                ? 0
                                : FetchSpaceInvoice?.total.toFixed(2) < 0
                                ? "N/A"
                                : FetchSpaceInvoice?.total.toFixed(2)}{" "}
                              <small className="fw-bold">{currency}</small>
                            </p>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>

                {FetchSpaceInvoice?.remarks && (
                  <>
                    <hr className="invoice-spacing mt-0" />
                    <div className="card-body invoice-padding pt-0">
                      <div className="row">
                        <div className="col-12">
                          <span className="font-weight-bold">Note: </span>
                          <span>
                            {FetchSpaceInvoice?.remarks ||
                              "You haven't added note while creating invoice"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="col-xl-3 hidePrint">
              <div className="card">
                <div className="card-body">
                  <div className="text-end mb-3">
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        variant="none"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <Icon.ThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "black",
                          }}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            downloadFile(
                              FetchSpaceInvoice?.member.name,
                              FetchSpaceInvoice?.id
                            )
                          }
                        >
                          Download
                        </Dropdown.Item>
                        <Dropdown.Item
                          variant="secondary"
                          onClick={() => print()}
                        >
                          {" "}
                          Print
                        </Dropdown.Item>
                        <Dropdown.Item variant="secondary" onClick={handlePaid}>
                          {" "}
                          Paid
                        </Dropdown.Item>
                        <Dropdown.Item>
                          {!FetchSpaceInvoice?.paid ? (
                            <Link to={`/invoice/${id}/edit`}>Update</Link>
                          ) : (
                            <div
                              onClick={() =>
                                requiredAlert("Paid invoice cannot be edited.")
                              }
                            >
                              Update
                            </div>
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <button
                    className="send-invoice-btn btn btn-primary   mb-2 w-100"
                    onClick={sentInvoiceAsEmail}
                  >
                    <Icon.Send /> Send via Email
                  </button>
                  {InvoicesPermissions[0]?.update ? (
                    <AddPaymentModal
                      totalAmount={FetchSpaceInvoice?.total}
                      paymentMethods={FetchPaymentMethod}
                      invoiceId={id}
                      refund={false}
                    />
                  ) : (
                    <div className="btn btn-primary mb-2 w-100">
                      Add Payment
                      <Lock />
                    </div>
                  )}
                  {InvoicesPermissions[0]?.update ? (
                    <div className="mt-2">
                      <AddPaymentModal
                        totalAmount={FetchSpaceInvoice?.total}
                        paymentMethods={FetchPaymentMethod}
                        invoiceId={id}
                        refund={true}
                      />
                    </div>
                  ) : (
                    <div className="btn btn-primary mb-2 w-100">
                      Issue Refund
                      <Lock />
                    </div>
                  )}

                  {/* <button
                    className="btn btn-outline-secondary mb-2 w-100"
                    onClick={() =>
                      downloadFile(
                        FetchSpaceInvoice?.member.name,
                        FetchSpaceInvoice?.id
                      )
                    }
                  >
                    Download
                  </button>
                  <button
                    className="btn btn-outline-secondary mb-2 w-100"
                    onClick={() => print()}
                  >
                    Print
                  </button>

                  {!FetchSpaceInvoice?.paid && (
                    <>
                      {" "}
                      {InvoicesPermissions[0]?.update ? (
                        <div
                          onClick={handlePaid}
                          className="btn btn-success btn-outline-secondary mb-2 w-100"
                        >
                          Paid
                        </div>
                      ) : (
                        <div className="btn btn-outline-secondary mb-2 w-100">
                          Paid
                          <Lock />
                        </div>
                      )}
                      {InvoicesPermissions[0]?.update ? (
                        !FetchSpaceInvoice?.paid ? (
                          <Link
                            to={`/invoice/${id}/edit`}
                            className="btn btn-outline-secondary mb-2 w-100"
                          >
                            Update
                          </Link>
                        ) : (
                          <div
                            onClick={() =>
                              requiredAlert("Paid invoice cannot be edited.")
                            }
                            className="btn btn-outline-secondary mb-2 w-100"
                          >
                            Update
                          </div>
                        )
                      ) : (
                        <div className="btn btn-outline-secondary mb-2 w-100">
                          Update
                          <Lock />
                        </div>
                      )}{" "}
                      {InvoicesPermissions[0]?.update ? (
                        <div className="mt-2">
                          <AddPaymentModal
                            totalAmount={FetchSpaceInvoice?.total}
                            paymentMethods={FetchPaymentMethod}
                            invoiceId={id}
                            refund={true}
                          />
                        </div>
                      ) : (
                        <div className="btn btn-primary mb-2 w-100">
                          Issue Refund
                          <Lock />
                        </div>
                      )}
                    </>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewMember;
