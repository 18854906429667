import React, { useState } from "react";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";
import { Form, Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const ViewPackagePopup = ({ items, index, SpaceTypes }) => {
  var {
    facilities,
    name,
    price,
    person,
    durationString,
    spaceTypes,
    spaceTypeString,
  } = items[index];
  console.log("Items are", items[index]);
  console.log("Space Types are", SpaceTypes);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getDisplayName = (typeId, quantity) => {
    const spaceType = SpaceTypes?.find((type) => type.id === typeId);
    const spaceTypeName = spaceType ? spaceType.name : "Unknown Space Type";
    return `${spaceTypeName} x ${quantity}`;
  };
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">View Package</Tooltip>}
      >
        <Button
          variant="white"
          className="p-0"
          id="viewPackageOpen"
          onClick={handleShow}
        >
          <Preview
          // style={{
          //   maxWidth: "100%",
          //   maxHeight: "100%",
          //   width: "auto",
          //   height: "auto",
          // }}
          />
        </Button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Space Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 form-group">
              <h5>
                Name: <span className="text-muted">{name}</span>
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 form-group">
              <h5>
                Price: <span className="text-muted">{price}</span>
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 form-group">
              <h5>
                Persons: <span className="text-muted">{person}</span>
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 form-group">
              <h5>
                Duration: <span className="text-muted">{durationString}</span>
              </h5>
            </div>
          </div>
          {/* 
          <div className="row">
            <div className="col-md-12  form-group">
              <h5>
                Space Types:{" "}
                <span className="text-muted">
                  {" "}
                  {spaceTypes?.map((obj) => (
                    <span key={obj.id}>
                      {getDisplayName(obj.type, obj.quantity)}
                      {spaceTypes.indexOf(obj) !== spaceTypes.length - 1 &&
                        ", "}
                    </span>
                  ))}
                </span>
              </h5>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-12 form-group">
              <h5>Space Types:</h5>
              <ul className="list-unstyled">
                {spaceTypes?.map((obj) => (
                  <li key={obj.id}>
                    <span className="text-muted">
                      {getDisplayName(obj.type, obj.quantity)}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h5>Facilities :</h5>
              {facilities?.map((item, index) => {
                return (
                  <div className="col-md-12" key={item.id}>
                    <Form.Check
                      type="checkbox"
                      id={item.id}
                      label={item.name}
                      checked
                      readOnly
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ViewPackagePopup;
