import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  useCreateEmployeeMutation,
  useGetAllLocationsQuery,
  useGetAllTeamsQuery,
  useGetCompanyUsersQuery,
  useGetRolesQuery,
  useGetSingleTeamQuery,
  useGetSpecificCompanyQuery,
  useUpdateEmployeeMutation,
} from "../../Slices/spaceApi";
import { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import UserForCompany from "../AddMember/UserForCompany";
import Loader from "../Loader/Loader";
import { useSortableData } from "../../utils/SortingFunction";

import ViewComapanyUser from "../AddMember/ViewComapanyUser";
import EditCompanyUser from "../AddMember/EditCompanyUser";
import DeleteCompanyUser from "../AddMember/DeleteCompanyUser";
import { useEffect } from "react";
import TeamForm from "./TeamForm";
import EmployeeForm from "./EmployeeForm";
import DeleteEmployee from "./DeleteEmployee";
import ViewEmployee from "./ViewEmployee";
import { Badge } from "react-bootstrap";
import TeamDataView from "./TeamDataView";
import { useGlobalContext } from "../../Context/GlobalContext";

function TeamView() {
  const { id } = useParams();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Team Details");
  }, []);
  const { memberKey, setMemberKey } = useGlobalContext();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const { data: Roles, isLoading: rolesLoading } = useGetRolesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: Locations, isLoading: locationLoading } =
    useGetAllLocationsQuery({
      spaceId: spaceId || currentSpaceId,
    });

  const handleOpenUpdateModal = (team) => {
    setSelectedTeam(team);
    setUpdateModalVisible(true);
  };
  const { data: getTeams, isLoading: teamsLoading } = useGetAllTeamsQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [createEmployee, resultStats] = useCreateEmployeeMutation();
  const [updateEmployee, resultStats1] = useUpdateEmployeeMutation();

  const { data: companyData, isLoading: loading } = useGetSpecificCompanyQuery({
    spaceId: spaceId || currentSpaceId,
    id: id,
  });
  const { data: teamData, isLoading: teamLoading } = useGetSingleTeamQuery({
    spaceId: spaceId || currentSpaceId,
    teamId: id,
  });
  useEffect(() => {
    setMemberKey("Team");
  }, []);
  const { items, requestSort, sortConfig } = useSortableData(
    Array.isArray(teamData?.members) ? teamData?.members : []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const filteredItems = items
    ?.slice()
    .reverse()
    ?.filter((val) => {
      if (search == "") {
        return val;
      } else if (
        val?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
        val?.name?.toLowerCase()?.includes(search.toLowerCase())
      ) {
        return val;
      }
    });

  const handleSubmitAdd = (newEmployee) => {
    createEmployee({
      spaceId: spaceId || currentSpaceId,
      employeeData: newEmployee,
    });
  };
  const updateEmployeeData = (updateData) => {
    updateEmployee({
      spaceId: spaceId || currentSpaceId,
      id: updateData?.id,
      updateData: updateData,
    });
  };

  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate}, ${formattedTime}`;
  }
  if (
    loading ||
    teamLoading ||
    teamsLoading ||
    rolesLoading ||
    locationLoading
  ) {
    <Loader height={6.6} />;
  }

  return (
    <>
      <section className="app-user-list">
        <div className="card">
          <div className="row table-header align-items-center">
            <div className="col-xl-6 col-12">
              <div className="media ">
                <div className="media-aside mr-2">
                  <img
                    src={teamData?.profileImage}
                    alt=""
                    className="rounded-circle"
                    style={{ width: "38px", height: "38px" }}
                  />
                </div>
                <div className="media-body my-auto d-flex flex-column">
                  <span className="mb-0 table-avatar">{teamData?.name}</span>
                  <small className="mb-0 text-muted">{teamData?.email}</small>
                  <div className="">
                    <TeamDataView {...teamData} Locations={Locations?.spaces} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-12 mt-2 mt-xl-0">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Here"
                className="form-control"
              />
            </div>
            <div className="col-xl-2 col-12 mt-2 offset-xl-1 mt-xl-0 d-flex align-items-center justify-content-end">
              <div className="ms-3">
                <EmployeeForm
                  employeeData={{
                    name: "",
                    email: "",
                    profileImage: "",
                    more_info: "",
                    location: "",
                    role: 1,
                    team: id,
                    status: 1,
                  }}
                  Teams={getTeams}
                  isReadable={false}
                  onSubmit={handleSubmitAdd}
                  Locations={Locations?.spaces}
                  Roles={Roles?.roles}
                  onCloseModal={() => setShowModal(false)} // Close modal from here
                  onOpenModal={() => setShowModal(true)}
                  showModal={showModal}
                  teamCheck={true}
                  title="Add Employee"
                  submitButtonText="Add Team Member"
                />
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Name</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("employees:name")}
                          className={getClassNamesFor("employees:name")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("employees:name")}
                          className={getClassNamesFor("employees:name")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Team</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("team")}
                          className={getClassNamesFor("team")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("team")}
                          className={getClassNamesFor("team")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Last Login</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("lastlogin")}
                          className={getClassNamesFor("lastlogin")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("lastlogin")}
                          className={getClassNamesFor("lastlogin")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Status</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("status")}
                          className={getClassNamesFor("status")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("status")}
                          className={getClassNamesFor("status")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Role</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      ></div>
                    </div>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={8} className="fs-5">
                      <Loader />
                    </td>
                  </tr>
                )}
                {!loading &&
                  (filteredItems?.length > 0 ? (
                    filteredItems?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="media">
                              <div className="media">
                                <div className="media-aside mr-2">
                                  <img
                                    src={item?.profileImage}
                                    alt=""
                                    className="rounded-circle"
                                    style={{ width: "38px", height: "38px" }}
                                  />
                                </div>
                              </div>
                              <div className="media-body my-auto d-flex flex-column">
                                <span className="mb-0 table-avatar">
                                  {item?.name}
                                </span>
                                <small className="mb-0 text-muted">
                                  {item?.email || (
                                    <Badge bg={"danger"}>
                                      {"No E-mail Provided"}
                                    </Badge>
                                  )}
                                </small>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className="media-body my-auto d-flex flex-column">
                              {getTeams?.teams?.map((team) => (
                                <span
                                  key={team.id}
                                  className="mb-0 table-avatar"
                                >
                                  {item.team === team.id ? (
                                    <span>{team.name}</span>
                                  ) : null}
                                </span>
                              ))}
                            </div>
                          </td>
                          {/* <td>
                            <div className="media-body my-auto d-flex flex-column">
                              <span className="mb-0 table-avatar">
                                Login Date
                              </span>
                            </div>
                          </td> */}
                          <td>
                            <div className="media-body my-auto d-flex flex-column">
                              <span className="mb-0 table-avatar">
                                {item.lastLogin ? (
                                  formatDateTime(item.lastLogin)
                                ) : (
                                  <Badge bg="danger">Not Login Yet</Badge>
                                )}
                              </span>
                            </div>
                          </td>

                          <td>
                            <Badge
                              bg={item?.status === 1 ? "success" : "danger"}
                            >
                              {item.status === 1 ? "Active" : "Inactive"}
                            </Badge>
                          </td>
                          <td>
                            <div className="media-body my-auto d-flex flex-column">
                              {/* <span className="mb-0 table-avatar">{item.role}</span> */}
                              {Roles?.roles?.map((role) => {
                                if (role.id === item.role) {
                                  return role.name;
                                }
                                return null;
                              })}
                            </div>
                          </td>

                          <td className="d-flex flex-nowrap">
                            <ViewEmployee
                              {...item}
                              Teams={getTeams}
                              Locations={Locations?.spaces}
                              Roles={Roles?.roles}
                            />

                            <EmployeeForm
                              employeeData={{
                                id: item?.id,
                                name: item?.name,
                                email: item?.email,
                                profileImage: item?.profileImage,
                                role: item?.role,
                                locations: item?.locations,
                                team: item?.team,
                                status: item?.status,
                              }}
                              isReadable={true}
                              Teams={getTeams}
                              Locations={Locations?.spaces}
                              Roles={Roles?.roles}
                              teamCheck={true}
                              onSubmit={updateEmployeeData}
                              onCloseModal={() => setUpdateModalVisible(false)}
                              onOpenModal={() => handleOpenUpdateModal(item)}
                              showModal={updateModalVisible}
                              title="Update Team Member"
                              submitButtonText="Update Team Member"
                            />
                            <DeleteEmployee {...item} />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-left fs-5">
                        {search === "" ? "No Record !" : "No Employee found"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default TeamView;
