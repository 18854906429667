const moment = require("moment-timezone");

export function convertUTCTimestampToLocal(localTimestamp) {
  // Detect the user's time zone automatically
  const userTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  // Get the current UTC offset for the user's time zone
  const utcOffset = moment.tz(localTimestamp * 1000, userTimeZone).utcOffset();

  // Add the UTC offset to the local timestamp to get the UTC timestamp
  const utcTimestamp = moment
    .unix(localTimestamp)
    .add(utcOffset, "minutes") // Add the offset in minutes
    .unix();

  return utcTimestamp;
}
