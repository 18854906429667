import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context/GlobalContext";

const Reports = () => {
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Reports");
  }, []);
  const navigate = useNavigate();
  const handleReportRoute = (route) => {
    navigate(`/${route}`);
  };

  return (
    <>
      <section className="invoice-list-wrapper with-bottom-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div
                className="card"
                style={{ cursor: "pointer" }}
                onClick={() => handleReportRoute("bookingkpi-report")}
              >
                <div className="card-body">
                  <h3 className="mb-1">KPI Report</h3>
                  <p className="fw-normal mb-2">
                    This report is all about Booking KPI and charts etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div
                className="card"
                style={{ cursor: "pointer" }}
                onClick={() => handleReportRoute("visit-report")}
              >
                <div className="card-body">
                  <h3 className="mb-1">Visit Report</h3>
                  <p className="fw-normal mb-2">
                    This report is all about visits and visitors.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div
                className="card"
                style={{ cursor: "pointer" }}
                onClick={() => handleReportRoute("product-report")}
              >
                <div className="card-body">
                  <h3 className="mb-1">Product Report</h3>
                  <p className="fw-normal mb-2">
                    This report is all about products and charts.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div
                className="card"
                style={{ cursor: "pointer" }}
                onClick={() => handleReportRoute("discount-report")}
              >
                <div className="card-body">
                  <h3 className="mb-1">Discount Report</h3>
                  <p className="fw-normal mb-2">
                    This report is all about discounts and charts etc.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div
                className="card"
                style={{ cursor: "pointer" }}
                onClick={() => handleReportRoute("revenue-report")}
              >
                <div className="card-body">
                  <h3 className="mb-1">Revenue Report</h3>
                  <p className="fw-normal mb-2">
                    This report is all about revenues and charts etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div
                className="card"
                style={{ cursor: "pointer" }}
                onClick={() => handleReportRoute("booking-report")}
              >
                <div className="card-body">
                  <h3 className="mb-1">Booking Report</h3>
                  <p className="fw-normal mb-2">
                    This report is all about Bookings and charts etc.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div
                className="card"
                style={{ cursor: "pointer" }}
                onClick={() => handleReportRoute("finance-report")}
              >
                <div className="card-body">
                  <h3 className="mb-1">Finance Report</h3>
                  <p className="fw-normal mb-2">
                    This report is all about Finance and charts etc.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div
                className="card"
                style={{ cursor: "pointer" }}
                onClick={() => handleReportRoute("desks-report")}
              >
                <div className="card-body">
                  <h3 className="mb-1">Desk Report</h3>
                  <p className="fw-normal mb-2">
                    This report is all about Desks and charts etc.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <div
                className="card"
                style={{ cursor: "pointer" }}
                onClick={() => handleReportRoute("invoice-report")}
              >
                <div className="card-body">
                  <h3 className="mb-1">Invoice Report</h3>
                  <p className="fw-normal mb-2">
                    This report is all about invoices of members etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Reports;
