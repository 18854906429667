// import React from "react";

// const HeaderCard = ({ data }) => {
//   return (
//     <div className="container">
//       <div className="row card ">
//         {data?.map((item, index) => (
//           <div key={index} className=" d-flex ">
//             <div className="svg-container">{item.svg}</div>
//             <div className="">
//               <h6 className="title">{item.title}</h6>
//               <span className="value fw-bold text-muted">{item.value}</span>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default HeaderCard;
import React from "react";

const HeaderCard = ({ data }) => {
  return (
    <div style={{ padding: "0px 16px" }}>
      <div className="card p-4">
        <div className="row">
          {data?.map((item, index) => (
            <div
              key={index}
              className={`card-item col-12 col-md-3 d-flex align-items-center justify-content-between ${
                index == data?.length - 1 ? "" : "custom-card-item"
              }`}
            >
              <div className="ml-3" style={{ lineHeight: 0 }}>
                <h3 className="value">
                  {item.value} {item.currency && item.currency}
                </h3>
                <h6 className="title">{item.title}</h6>
              </div>
              <div className="svg-container p-1 bg-label-secondary rounded">
                {item.svg}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeaderCard;
