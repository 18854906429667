import React from "react";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import { Modal, Button, Form } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";

const ViewEmployee = ({
  profileImage,
  name,
  email,
  team,
  status,
  role,
  locations,
  Teams,
  Locations,
  Roles,
}) => {
  const { employessPermissions } = useGlobalContext();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {employessPermissions[0]?.read ? (
              "View Employee"
            ) : (
              <span>
                View
                <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          id="viewMeetingOpen"
          onClick={employessPermissions[0]?.read ? handleShow : null}
        >
          <Preview />
        </Button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {profileImage && (
            <div className="media justify-content-center">
              <div className="media-aside">
                <img
                  src={
                    profileImage ||
                    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                  }
                  alt=""
                  className="rounded-circle border"
                  style={{ width: "20rem", height: "20rem" }}
                />
              </div>
            </div>
          )}
          {name && (
            <div className="row mt-4">
              <div className="col-md-12 form-group">
                <h5>
                  <b>Name:</b>{" "}
                  <span className="text-muted">
                    {name.length > 20 ? name.substring(0, 20) + "..." : name}
                  </span>
                </h5>
              </div>
            </div>
          )}
          {email && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  <b> Email:</b> <span className="text-muted">{email}</span>
                </h5>
              </div>
            </div>
          )}

          {team && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  <b>Team:</b>{" "}
                  <span className="text-muted">
                    {Teams?.teams?.map((item, index) => {
                      if (item?.id === team) {
                        return <span key={index}>{item?.name}</span>;
                      }
                    })}
                  </span>
                </h5>
              </div>
            </div>
          )}
          {status && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  <b> Status:</b>{" "}
                  <span className="text-muted">
                    {status === 1 ? "Active" : "Inactive"}
                  </span>
                </h5>
              </div>
            </div>
          )}
          {role && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  <b> Role:</b>{" "}
                  <span className="text-muted">
                    {Roles?.map((item) => {
                      if (item.id === role) {
                        return item.name;
                      }
                      return null;
                    })}
                  </span>
                </h5>
              </div>
            </div>
          )}
          {locations && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  <b> Locations :</b>
                </h5>
                <div className="location-list">
                  {locations?.map((locationId) => {
                    const location = Locations?.find(
                      (loc) => loc.id === locationId
                    );
                    if (location) {
                      return (
                        <div className="col-md-12" key={locationId}>
                          <Form.Check
                            type="checkbox"
                            id={locationId}
                            label={location.name}
                            checked
                            readOnly
                            className="pe-none"
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ViewEmployee;
