import swal from "sweetalert";
import Swal from "sweetalert2";

export const successAlert = (text) =>
  swal("Good job!", text, "success", {
    button: "Okay!",
  });

export const errorAlertImage = () =>
  Swal.fire("Bad Move!", "The image size is greater than 10Mb!", "error", {
    button: "Cancel!",
  });

export const errorAlertPermission = (msg) =>
  Swal.fire("", msg, "error", {
    button: "Cancel!",
  });

export const paidConfirmationAlert = () => {
  return new Promise((resolve) => {
    Swal.fire({
      title: "Confirm Invoice Payment",
      text: "Are you sure you want to mark this invoice as paid?",
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",

      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      }
      {
        resolve(null);
      }
    });
  });
};

export const ConfirmationAlert = (text) => {
  return new Promise((resolve) => {
    Swal.fire({
      title: text,
      // text: "Are you sure you want to mark this invoice as paid?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      } else {
        resolve(null);
      }
    });
  });
};

export const bookingConfirmationAlert = (text) => {
  return new Promise((resolve) => {
    Swal.fire({
      title: text,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Approve",
      cancelButtonText: "Not Approve",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        resolve(false);
      } else {
        resolve(null);
      }
    });
  });
};

export const confirmationAlert = (text) => {
  return new Promise((resolve) => {
    Swal.fire({
      title: text,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",

      cancelButtonText: "No, cancel!",

      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      } else {
        resolve(null);
      }
    });
  });
};
export const NewconfirmationAlert = (title, text, btn1, btn2) => {
  return new Promise((resolve) => {
    Swal.fire({
      title: title,
      text: text,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      // confirmButtonText: "Yes, delete it!",
      confirmButtonText: btn1,

      // cancelButtonText: "No, cancel!",
      cancelButtonText: btn2,

      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      } else {
        resolve(null);
      }
    });
  });
};
export const confirmationCancelAlert = (text) => {
  return new Promise((resolve) => {
    Swal.fire({
      title: text,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Cancel it!",
      cancelButtonText: "No!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      } else {
        resolve(null);
      }
    });
  });
};

export const confirmationStripeAlert = (text) => {
  return new Promise((resolve) => {
    Swal.fire({
      title: text,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, revoke it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      } else {
        resolve(null);
      }
    });
  });
};

export const userDeleteAlert = (text) =>
  Swal.fire({
    title: "Done!",
    text,
    icon: "success",
    timer: 2500,
    showConfirmButton: false,
  });
// swal({
//   title: "Done!",
//   text,
//   icon: "success",
//   timer: 2000,
//   button: false,
// });

export const requiredAlert = (msg) =>
  Swal.fire({
    // text: msg
    title: "Required!",
    text: msg,
    icon: "warning",
    timer: 2500,
    showConfirmButton: false,
  });
// swal({
//   // text: msg
//   title: "Required!",
//   text: msg,
//   icon: "warning",
//   timer: 2000,
//   button: false,
// });
