import React from "react";
import { useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import {
  useDeleteQuotationMutation,
  useDeleteSpaceInvoiceMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useState } from "react";

const DeleteInvoicePopup = ({ id }) => {
  const { quotationsPermission } = useGlobalContext();
  const [apiSpinner, setApiSpinner] = useState(false);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [deleteQuotation, resultStats] = useDeleteQuotationMutation();

  const handleInvoice = (e) => {
    e.preventDefault();
    confirmationAlert("Do You Want to Delete This Quotation?").then(
      (willDelete) => {
        if (willDelete) {
          setApiSpinner(true);
          deleteQuotation({
            spaceId: spaceId || currentSpaceId,
            id: id,
          });
        }
      }
    );
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Quotation Deleted Successfully.");
      setTimeout(() => {
        setApiSpinner(false);
      }, 1000);
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
      setTimeout(() => {
        setApiSpinner(false);
      }, 500);
    }
  }, [resultStats]);

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {quotationsPermission[0]?.delete ? (
              "Delete Quotation"
            ) : (
              <span>
                Delete <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          onClick={quotationsPermission[0]?.delete ? handleInvoice : null}
          disabled={apiSpinner}
        >
          <DeleteIcon />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default DeleteInvoicePopup;
