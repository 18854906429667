import React, { useEffect, useState } from "react";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";

import {
  Button,
  Form,
  OverlayTrigger,
  Pagination,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import {
  useAccountDeleteMutation,
  useAccountListQuery,
} from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import AccountView from "./AccountView";

import Swal from "sweetalert2";
import AccountAdd from "./AccountAdd";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useGlobalContext } from "../../Context/GlobalContext";

const Account = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Accounts");
  }, []);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sidePage, setSidePage] = useState(true);
  const [sideName, setName] = useState("");
  const [list, setList] = useState([]);
  const [operateId, setOperateId] = useState(null);
  const [operateObject, setOperateObject] = useState(null);
  const { data, isLoading, isError, error, refetch, isFetching } =
    useAccountListQuery({
      spaceId: spaceId || currentSpaceId,
      page_size: pageSize,
      page: pageNumber,
      name: searchValue,
    });

  const [deleteAccount, dltResults] = useAccountDeleteMutation();

  const handleClose = () => {
    setSidePage((state) => !state);
  };
  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  useEffect(() => {
    refetch();
  }, [searchValue]);
  const handlePageSize = (e) => {
    setPageSize(e.target.value);
    setPageNumber(1);
  };
  useEffect(() => {
    if (data) {
      setList(data.accounts);
    }
  }, [data, isLoading, isError, error]);

  const sort = (sortIn, sortTo) => {
    if (sortTo === "id") {
      if (sortIn === "asc") {
        setList((state) => [...state].sort((a, b) => a.id - b.id));
      } else if (sortIn === "dsc") {
        setList((state) => [...state].sort((b, a) => a.id - b.id));
      }
    } else if (sortTo === "type") {
      if (sortIn === "asc") {
        setList((state) =>
          [...state].sort((a, b) => a.accountType - b.accountType)
        );
      } else if (sortIn === "dsc") {
        setList((state) =>
          [...state].sort((b, a) => a.accountType - b.accountType)
        );
      }
    } else if (sortTo === "name") {
      if (sortIn === "asc") {
        setList((state) =>
          [...state].sort((a, b) => a.name.localeCompare(b.name))
        );
      } else if (sortIn === "dsc") {
        setList((state) =>
          [...state].sort((b, a) => a.name.localeCompare(b.name))
        );
      }
    } else if (sortTo === "description") {
      if (sortIn === "asc") {
        setList((state) =>
          [...state].sort((a, b) => a.description.localeCompare(b.description))
        );
      } else if (sortIn === "dsc") {
        setList((state) =>
          [...state].sort((b, a) => a.description.localeCompare(b.description))
        );
      }
    } else if (sortTo === "code") {
      if (sortIn === "asc") {
        setList((state) =>
          [...state].sort((a, b) => a.accountCode.localeCompare(b.accountCode))
        );
      } else if (sortIn === "code") {
        setList((state) =>
          [...state].sort((b, a) => a.accountCode.localeCompare(b.accountCode))
        );
      }
    }
  };
  const AccountDelete = (id) => {
    confirmationAlert("want to delete Account?").then((willDelete) => {
      if (willDelete) {
        deleteAccount({ spaceId: spaceId || currentSpaceId, id });
      }
    });
  };
  useEffect(() => {
    if (dltResults?.isSuccess) {
      userDeleteAlert("Account Deleted Successfully.");
    }
    if (dltResults?.isError) {
      requiredAlert(dltResults?.error?.data?.message);
    }
  }, [dltResults]);
  const handlePageChange = (e) => {
    //.log('e', e)
    setPageNumber(e);
  };
  const totalPages = data ? Math.ceil(data?.totalCount / pageSize) : 0;
  const startIndex = (pageNumber - 1) * pageSize + 1;
  const endIndex =
    pageNumber * pageSize > data?.totalCount
      ? data?.totalCount
      : pageNumber * pageSize;

  // Generating dynamic pagination items
  let paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === pageNumber}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  if (isLoading) return <Loader />;
  return (
    <>
      {/* <Toaster></Toaster> */}
      <section className="container-fluid  with-bottom-padding pt-0 p-3 pt-0 mt-0">
        <div className="container-fluid card  m-0  p-0 w-100 col-12">
          <div className="col-12">
            <div className="row mt-4  ">
              {/* <div className='d-flex justify-content-between '> */}{" "}
              <div className="col-12  col-md-6 ">
                <Form.Control
                  type="text"
                  placeholder="Search... "
                  className="form-control  "
                  value={searchValue}
                  onChange={handleSearchInputChange}
                />
              </div>
              <div className=" col-12 col-md-6 d-flex justify-content-end mt-2 mt-md-0">
                <div className=" app-email-compose">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">Add Account</Tooltip>}
                  >
                    <Button
                      className="send-invoice-btn btn btn-primary"
                      onClick={() => {
                        setSidePage(true);
                        setName("addAccount");
                      }}
                    >
                      Add Account
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            {/* </div> */}
          </div>
          <hr></hr>
          <div className="col-12">
            <div>
              <div className="col-12 d-flex justify-content-end">
                <label
                  style={{
                    display: "flex",
                    whiteSpace: "nowrap",
                    fontWeight: "normal",
                  }}
                >
                  <small className="mr-2 mt-2 title"> Show</small>
                  <Form.Select
                    aria-label="Change Page size"
                    onChange={handlePageSize}
                    defaultValue={10}
                    className=""
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </Form.Select>
                  <small className="ml-2 mt-2"> entries</small>
                </label>
              </div>
            </div>
          </div>

          <div className=" table-responsive">
            {" "}
            {isFetching ? (
              <Loader />
            ) : (
              <table className="table mt-4">
                <thead>
                  <tr>
                    <th>
                      <div
                        className="d-flex align-items-center sort heading"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div>#</div>
                        <div
                          className=" d-flex"
                          style={{ flexDirection: "column" }}
                        >
                          <Icon.ChevronUp
                            onClick={() => sort("asc", "id")}
                            style={{ fontSize: "10px" }}
                          />

                          <Icon.ChevronDown
                            onClick={() => sort("dsc", "id")}
                            style={{ fontSize: "10px" }}
                          />
                        </div>
                      </div>
                    </th>
                    <th>
                      <div
                        className="d-flex align-items-center sort heading"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div>Account Name</div>
                        <div
                          className=" d-flex"
                          style={{ flexDirection: "column" }}
                        >
                          <Icon.ChevronUp
                            onClick={() => sort("asc", "name")}
                            style={{ fontSize: "10px" }}
                          />

                          <Icon.ChevronDown
                            onClick={() => sort("dsc", "name")}
                            style={{ fontSize: "10px" }}
                          />
                        </div>
                      </div>
                    </th>
                    <th>
                      <div
                        className="d-flex align-items-center sort heading"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div>Account Code</div>
                        <div
                          className=" d-flex"
                          style={{ flexDirection: "column" }}
                        >
                          <Icon.ChevronUp
                            onClick={() => sort("asc", "code")}
                            style={{ fontSize: "10px" }}
                          />

                          <Icon.ChevronDown
                            onClick={() => sort("dsc", "code")}
                            style={{ fontSize: "10px" }}
                          />
                        </div>
                      </div>
                    </th>
                    <th>
                      <div
                        className="d-flex align-items-center sort heading"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div>Description</div>
                        <div
                          className=" d-flex"
                          style={{ flexDirection: "column" }}
                        ></div>
                      </div>
                    </th>
                    <th>
                      <div
                        className="d-flex align-items-center sort heading"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div>Account Type</div>
                        <div
                          className=" d-flex"
                          style={{ flexDirection: "column" }}
                        >
                          <Icon.ChevronUp
                            onClick={() => sort("asc", "type")}
                            style={{ fontSize: "10px" }}
                          />

                          <Icon.ChevronDown
                            onClick={() => sort("dsc", "type")}
                            style={{ fontSize: "10px" }}
                          />
                        </div>
                      </div>
                    </th>

                    <th>
                      <div
                        className="d-flex align-items-center sort heading"
                        style={{ justifyContent: "space-between" }}
                      >
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <>
                      <Loader />
                    </>
                  ) : isError ? (
                    <>
                      <div>${error}</div>
                    </>
                  ) : (
                    <>
                      {!list ? (
                        <>
                          <div>No data</div>
                        </>
                      ) : (
                        <>
                          {list.map((data, index) => (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td className="serial bold">#{data.id}</td>
                                <td className="table-avatar bold">
                                  <div className="d-flex flex-column">
                                    {" "}
                                    <h6 className=" table-avatar bold  text-nowrap mb-0">
                                      {data.name}
                                    </h6>
                                  </div>
                                </td>
                                <td className="table-avatar ">
                                  {data.accountCode}
                                </td>
                                <td className="table-avatar maxw-200">
                                  <div className="truncate-text">
                                    {data.description}
                                  </div>
                                </td>
                                <td className="table-avatar ">
                                  {data.accountType == 1
                                    ? "Sales"
                                    : data.accountType == 2
                                    ? "Payment"
                                    : data.accountType == 3
                                    ? "Deposit"
                                    : "No type"}
                                </td>

                                <td className="d-flex flex-nowrap">
                                  <div>
                                    {" "}
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          View Account
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="white"
                                        className="p-0"
                                        onClick={() => {
                                          setName("viewAccount");
                                          setOperateId(data.id);
                                          setOperateObject(data);
                                          setSidePage(true);
                                        }}
                                      >
                                        <Preview />
                                      </Button>
                                    </OverlayTrigger>
                                  </div>
                                  <div>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          Edit Account
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="white"
                                        className="p-0 mx-2"
                                        onClick={() => {
                                          setName("updateAccount");
                                          setOperateId(data.id);
                                          setOperateObject(data);
                                          setSidePage(true);
                                        }}
                                      >
                                        <EditIcon />
                                      </Button>
                                    </OverlayTrigger>
                                  </div>
                                  <div>
                                    {" "}
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          Delete Account
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="white"
                                        className="p-0"
                                        onClick={() => AccountDelete(data.id)}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    </OverlayTrigger>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            )}
          </div>

          <div className="m-0 border-bottom"></div>
          <div className=" pagination-custom flex-column flex-lg-row">
            <p className="text-muted mb-0">
              {" "}
              Showing {data?.accounts?.length} of {data?.totalCount} entries
            </p>
            {data?.totalCount > pageSize && (
              <Pagination className="mt-lg-0 mt-2">
                <Pagination.Prev
                  className="previous"
                  onClick={() => handlePageChange(pageNumber - 1)}
                  disabled={pageNumber === 1}
                >
                  Prev.
                </Pagination.Prev>
                {paginationItems}
                <Pagination.Next
                  className="next"
                  onClick={() => handlePageChange(pageNumber + 1)}
                  disabled={pageNumber === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            )}
          </div>
        </div>
      </section>

      {sidePage && sideName === "viewAccount" && operateId ? (
        <AccountView
          handleClose={handleClose}
          operateId={operateId}
          data={operateObject}
        ></AccountView>
      ) : sidePage && sideName === "updateAccount" && operateId ? (
        <>
          {/* <AccountUpdate
                handleClose={handleClose}
                operateId={operateId}
                data={operateObject}
              ></AccountUpdate> */}
          <AccountAdd
            handleClose={handleClose}
            operateId={operateId}
            operateObject={operateObject}
          />
        </>
      ) : sidePage && sideName === "addAccount" ? (
        <AccountAdd handleClose={handleClose} />
      ) : (
        <></>
      )}
    </>
  );
};

export default Account;
