import { Link, Outlet } from "react-router-dom";
import "./styles.css";
// import ExpenseContext from "../../context/ExpenseContext";
import {
  useDurationQuery,
  useExpenseCategoryListQuery,
} from "../../Slices/spaceApi";
import * as Icon from "react-bootstrap-icons";
import ExpenseCategory from "./ExpenseCategory";
import ExpenseList from "./ExpenseList";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
const Expense = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: categoryList } = useExpenseCategoryListQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: data1 } = useDurationQuery();
  return (
    <>
      {/* <ExpenseContext.Provider value={{ categoryList, duration: data1 }}> */}
      <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
        {/* <div className="card mt-5">
            <div className="container mt-5">
              <div className="row ">
                <Link to="/expense" className="col-md-2 ">
                  <button className="btn btn-primary btn-md w-100 mb-3">
                    Expense
                  </button>
                </Link>

                <Link to="/expense/category" className="col-md-2">
                  <button className="btn btn-primary btn-md w-100 mb-3">
                    Expense Category
                  </button>
                </Link>
              </div>
            </div>

            <Outlet />
          </div> */}

        <Tabs
          defaultActiveKey="Account"
          id="justify-tab-example"
          className="mb-3"
          variant="underline"
          justify
        >
          <Tab
            eventKey="Account"
            title={
              <span className="d-flex align-items-center">
                <Icon.CashCoin /> &nbsp;Expenses
              </span>
            }
          >
            {/* <CompanyTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <ExpenseList />
          </Tab>
          <Tab
            eventKey="Social"
            title={
              <span className="d-flex align-items-center">
                <Icon.Tag /> &nbsp; Expense Category
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <ExpenseCategory />
          </Tab>
        </Tabs>
      </section>
      {/* </ExpenseContext.Provider> */}
    </>
  );
};

export default Expense;
