import React from "react";
import DeleteCompany from "./DeleteCompany";
import UpdateCompanyDetails from "./UpdateCompanyDetails";
import ViewCompany from "./ViewCompany";
import { useSelector } from "react-redux";

import CompanyAndUsers from "./CompanyAndUsers";
import { Link } from "react-bootstrap-icons";
import ViewCompanyMiddleware from "./ViewCompanyMiddleware";
import { getInitials } from "../../utils/getInitials";
import { getRandomColorDark } from "../../utils/getRandomColorDark";

const Companies = ({ items, FetchPackageData, FetchMeetingPackageData }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { name, profileImg, email, id, address, phoneNo, memberCount } = items;

  return (
    <>
      <tr>
        <td>
          <div className="media">
            <div className="media-aside logo-list mr-2">
              {/* <img
                src={
                  profileImg ||
                  "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                }
                alt=""
                style={{
                  // marginRight: "8px",
                  borderRadius: "50%",
                  width: "38px",
                  height: "38px",
                  objectFit: "cover",
                }}
              /> */}
              {profileImg ? (
                <img
                  // style={{
                  //   // marginRight: "8px",
                  //   borderRadius: "50%",
                  //   width: "38px",
                  //   height: "38px",
                  //   objectFit: "cover",
                  // }}
                  src={
                    profileImg ||
                    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                  }
                  alt={name}
                />
              ) : (
                <div
                  className="rounded-circle"
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: getRandomColorDark(),
                    color: "#fff",
                    fontSize: "12px",
                  }}
                >
                  {getInitials(name)}
                </div>
              )}
            </div>
            <div className="media-body my-auto">
              <span className="mb-0 table-avatar">
                <b>{name}</b>
              </span>
            </div>
          </div>
        </td>
        <td>{email}</td>
        <td>{memberCount} Team Members</td>
        <td>
          <div className="d-flex align-items-center">
            <ViewCompanyMiddleware id={id} />
            {/* <ViewCompany
              companyId={id}
              companyName={name}
              companyEmail={email}
              profileImg={profileImg}
              address={address}
              phoneNo={phoneNo}
            />

            <UpdateCompanyDetails
              companyId={id}
              companyName={name}
              companyEmail={email}
              profileImg={profileImg}
              address={address}
              phoneNo={phoneNo}
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <DeleteCompany
              companyId={id}
              name={name}
              // packageAssignId={packageAssignId}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

export default Companies;
