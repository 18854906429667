import { useNavigate, useParams } from "react-router-dom";
import {
  ZoomMaximise,
  WheelZoom,
  ZoomMinimize,
  ArrowBack,
  ArrowSelect,
  View,
} from "../../Assets/FloorPlanSvgs/Icon";
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import {
  useGetFloorPlansQuery,
  useGetFloorRoomsQuery,
  useGetSpecificFloorPlansQuery,
} from "../../Slices/spaceApi";

import ImageArea from "./ImageArea";
import { useGlobalContext } from "../../Context/GlobalContext";
import Loader from "../Loader/Loader";
import { Badge } from "react-bootstrap";

function FloorUpdate() {
  const { toggleSidebar, setToggleSidebar } = useGlobalContext();
  let { id } = useParams();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: floorPlan, isLoading: loading } = useGetSpecificFloorPlansQuery(
    {
      spaceId: spaceId || currentSpaceId,
      floorId: id,
    }
  );

  const { data: fetchFloorPlans, isLoading } = useGetFloorPlansQuery(
    spaceId || currentSpaceId
  );

  const { data: roomsData, isLoading: roomdataLoading } =
    useGetFloorRoomsQuery(id);
  const navigate = useNavigate();
  const handleFloorSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedFloorId(selectedId);
    const updateUrl = `/floorPlans/${selectedId}/update`;
    navigate(updateUrl);
  };
  const handleFloorView = () => {
    const updateUrl = `/floorPlans/${id}/view`;
    navigate(updateUrl);
  };
  const handleBackToFloorList = () => {
    navigate("/floorPlans");
  };
  const [activeTab, setActiveTab] = useState("Desk");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const [dragSelectMode, setDragSelectMode] = useState(false);

  const [selectedFloorId, setSelectedFloorId] = useState(id);

  const dragAndSelectHandle = () => {
    setDragSelectMode(!dragSelectMode);
  };

  const [selectedShapeType, setSelectedShapeType] = useState(null);

  const [dragEnabled, setDragEnabled] = useState(false);

  const toggleDrag = () => {
    setDragEnabled(!dragEnabled);
  };
  const handleShapeClick = (shapeType) => {
    setSelectedShapeType(shapeType);
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionDragStart = (event, type) => {
    event.dataTransfer.setData("text/plain", type);
    setSelectedOption(type);
  };
  const handleDragStart = (event, type) => {
    event.dataTransfer.setData("text/plain", type);
  };

  const [activeShape, setActiveShape] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const updateCursorPosition = (e) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    setToggleSidebar(false);

    document.addEventListener("mousemove", updateCursorPosition);

    return () => {
      document.removeEventListener("mousemove", updateCursorPosition);
    };
  }, []);

  const [isTouchDevice, setIsTouchDevice] = useState(
    window.matchMedia("(pointer: coarse)").matches
  );

  useEffect(() => {
    // Update the state when the match result changes
    const mediaQueryList = window.matchMedia("(pointer: coarse)");
    const handleMatchChange = (e) => {
      setIsTouchDevice(e.matches);
    };

    mediaQueryList.addListener(handleMatchChange);

    return () => {
      mediaQueryList.removeListener(handleMatchChange);
    };
  }, []);
  const handleButton1Click = () => {
    setActiveShape("shape1");
  };

  const handleButton2Click = () => {
    setActiveShape("shape2");
  };
  const handleStopDrag = () => {
    setActiveShape(null);
    setSelectedShapeType(null);
  };
  const shapeOffset = {
    x: toggleSidebar ? -290 : -105,
    y: toggleSidebar ? -90 : -90,
  };
  if (window.innerWidth < 767) {
    shapeOffset.x = -25;
    // shapeOffset.y = 0;
  }

  const cursorStyle = {
    left: `${cursorPosition.x + shapeOffset.x}px`,
    top: `${cursorPosition.y + shapeOffset.y}px`,
  };

  const [mouseWheelZoomActive, setMouseWheelZoomActive] = useState(false);
  const [selectedZoomLevel, setSelectedZoomLevel] = useState(1);

  const handleZoomLevelChange = (event) => {
    const newZoomLevel = parseFloat(event.target.value);
    setSelectedZoomLevel(newZoomLevel);
  };
  const handleMouseWheelZoomToggle = () => {
    setMouseWheelZoomActive(!mouseWheelZoomActive);
  };

  useEffect(() => {
    const handleMouseWheelZoom = (event) => {
      if (mouseWheelZoomActive) {
        // event.preventDefault();
        const zoomStep = 0.1;
        const newZoomLevel =
          selectedZoomLevel + (event.deltaY > 0 ? -zoomStep : zoomStep);

        const minZoom = 0.1;
        const maxZoom = 2.0;
        const clampedZoomLevel = Math.min(
          Math.max(newZoomLevel, minZoom),
          maxZoom
        );
        setSelectedZoomLevel(clampedZoomLevel);
      }
    };

    document.addEventListener("wheel", handleMouseWheelZoom);

    return () => {
      document.removeEventListener("wheel", handleMouseWheelZoom);
    };
  }, [mouseWheelZoomActive, selectedZoomLevel]);
  if (roomdataLoading || isLoading || loading) {
    return <Loader />;
  }
  return (
    <div className="container-fluid">
      <div className="container-fluid">
        <div className="row">
          {/* <div className="card floorCard"> */}{" "}
          <div className="">
            <h4>{floorPlan?.name}'s Map</h4>
          </div>
          <div
            className="col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center p-3"
            style={{
              flexWrap: "wrap",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
            }}
            onClick={handleStopDrag}
          >
            <div className="col-sm-12 col-md-4 d-flex justify-content-between mt-2">
              <div
                className=""
                title="Back"
                onClick={handleBackToFloorList}
                style={{
                  backgroundColor: "rgb(126, 126, 225,0.5)",
                  cursor: "pointer",
                  alignItems: "center", // Add align-items: center
                  justifyContent: "center",
                  height: "50px", // You can adjust the height as needed
                  width: "50px", // You can adjust the width as needed
                  borderRadius: "5px",
                }}
              >
                <div className="mt-2 ml-2"> {ArrowBack}</div>
              </div>
              <div className="mt-2 " title="Select Floor">
                <select
                  name="floorPlan"
                  id="floorPlan"
                  className="form-control"
                  onChange={handleFloorSelectChange}
                  style={{
                    cursor: "pointer",
                    WebkitAppearance: "menulist",
                    MozAppearance: "menulist",
                    appearance: "menulist",
                  }}
                  value={selectedFloorId}
                >
                  {fetchFloorPlans?.map((floorPlan) => (
                    <option key={floorPlan?.id} value={floorPlan.id}>
                      {floorPlan.name}
                    </option>
                  ))}
                </select>
              </div>
              <div
                title="Zoom your plan to fit the screen."
                onClick={() => {
                  setSelectedZoomLevel(1);
                }}
                style={{
                  backgroundColor: "rgb(126, 126, 225,0.5)",
                  cursor: "pointer",
                  alignItems: "center", // Add align-items: center
                  justifyContent: "center",
                  height: "50px", // You can adjust the height as needed
                  width: "50px", // You can adjust the width as needed
                  borderRadius: "5px",
                }}
              >
                <div className="mt-2 ml-2">
                  {selectedZoomLevel < 1 ? ZoomMaximise : ZoomMinimize}
                </div>
              </div>
              <div
                onClick={handleMouseWheelZoomToggle}
                title={
                  mouseWheelZoomActive
                    ? "Wheel Zoom Inactive"
                    : "Wheel Zoom Inactive"
                }
                style={{
                  backgroundColor: "rgb(126, 126, 225,0.5)",
                  cursor: "pointer",
                  alignItems: "center", // Add align-items: center
                  justifyContent: "center",
                  height: "50px", // You can adjust the height as needed
                  width: "50px", // You can adjust the width as needed
                  borderRadius: "5px",
                }}
              >
                <div className="mt-2 ml-2">{WheelZoom}</div>
              </div>
            </div>
            <div>
              {!isTouchDevice && activeShape === "shape1" && (
                <div className="cursor-shape shape1" style={cursorStyle}></div>
              )}

              {!isTouchDevice && activeShape === "shape2" && (
                <div className="cursor-shape shape2" style={cursorStyle}></div>
              )}
            </div>
            <div className="col-sm-12 col-md-5 d-flex justify-content-between mt-2">
              <div
                className=""
                title="Selection"
                onClick={dragAndSelectHandle}
                style={{
                  backgroundColor: "rgb(126, 126, 225,0.5)",
                  cursor: "pointer",
                  alignItems: "center", // Add align-items: center
                  justifyContent: "center",
                  height: "50px", // You can adjust the height as needed
                  width: "50px", // You can adjust the width as needed
                  borderRadius: "5px",
                }}
              >
                <div className="mt-2 ml-2">{ArrowSelect}</div>
              </div>

              <div className="dropdown mt-2" title="Click on Desk to add">
                <button className="dropdown-button form-control border">
                  {selectedOption ? selectedOption : "Add Desk"}
                </button>

                <div className="dropdown-content justify-content-center ">
                  <div
                    className="shape "
                    onDragStart={(event) => handleDragStart(event, "1")}
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleDrag();
                      setSelectedShapeType("1");
                      handleButton1Click();
                    }}
                  >
                    Dedicated Desk &nbsp;&nbsp;
                  </div>
                  <hr />
                  <div
                    className="shape"
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleDrag();
                      setSelectedShapeType("2");
                      handleButton1Click();
                    }}
                  >
                    Hot Desk&nbsp;&nbsp;
                  </div>
                </div>
              </div>
              <div className="dropdown mt-2" title="Click on Space to Add">
                <button className="dropdown-button form-control border">
                  {selectedOption ? selectedOption : "Add Space"}
                </button>
                <div className="dropdown-content">
                  <div
                    className="shape"
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleDrag();
                      setSelectedShapeType("3");
                      handleButton2Click();
                    }}
                  >
                    Private Office
                  </div>
                  <hr />
                  <div
                    className="shape"
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleDrag();
                      setSelectedShapeType("4");
                      handleButton2Click();
                    }}
                  >
                    Meeting Room
                  </div>
                </div>
              </div>
              <div className="dropdown mt-2" title="Select Zoom Level">
                <button
                  className="dropdown-button  form-control border"
                  style={{ width: "80px" }}
                >
                  {parseInt(selectedZoomLevel * 100)}%
                </button>
                <select
                  className="dropdown-content"
                  onChange={handleZoomLevelChange}
                  value={selectedZoomLevel}
                >
                  <option value={2}>200%</option>

                  <option value={1.5}>150%</option>
                  <option value={1.25}>125%</option>
                  <option value={1}>100%</option>
                  <option value={0.75}>75%</option>
                  <option value={0.5}>50%</option>
                  <option value={0.25}>25%</option>
                </select>
              </div>
            </div>
            <div
              className="col-sm-12 col-md-3 d-flex justify-content-between mt-2"
              style={{ justifyContent: "space-between" }}
            >
              <div
                title="Floor View Mode"
                onClick={handleFloorView}
                style={{
                  backgroundColor: "rgb(126, 126, 225,0.5)",
                  cursor: "pointer",
                  // alignItems: "center", // Add align-items: center
                  // justifyContent: "center",
                  height: "50px", // You can adjust the height as needed
                  width: "50px", // You can adjust the width as needed
                  borderRadius: "5px",
                }}
              >
                <div className="mt-2 ml-2">{View}</div>
              </div>
              <div className="">
                <div className="">
                  <div className="d-flex align-items-center">
                    <div
                      className="color-circle ml-1"
                      style={{
                        backgroundColor: "rgba(40,199,111)",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <small className="ml-1" style={{ fontSize: "10px" }}>
                      Meeting Room
                    </small>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className="color-circle ml-1"
                      style={{
                        backgroundColor: "rgba(115,103,240)",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <small className="ml-1" style={{ fontSize: "10px" }}>
                      Private Office
                    </small>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className="color-circle ml-1"
                      style={{
                        backgroundColor: "rgba(31,213,235)",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <small className="ml-1" style={{ fontSize: "10px" }}>
                      Dedicated Desk
                    </small>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="d-flex align-items-center">
                    <div
                      className="color-circle ml-1"
                      style={{
                        backgroundColor: "rgba(255,159,67)",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <small className="ml-1" style={{ fontSize: "10px" }}>
                      Hot Desk
                    </small>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className="color-circle ml-1"
                      style={{
                        backgroundColor: "red",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <small className="ml-1" style={{ fontSize: "10px" }}>
                      Occupied
                    </small>
                  </div>
                </div>
              </div>

              {/* <div className=" mt-2">
                <Badge bg={floorPlan?.isOpen == true ? "success" : "danger"}>
                  {floorPlan?.isOpen == true ? "Open" : "Closed"}
                </Badge>
              </div> */}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <ImageArea
        floorImage={floorPlan?.image}
        id={id}
        selectedShapeTyp={selectedShapeType}
        dragEnabled={dragEnabled}
        zoomLevel={selectedZoomLevel}
        dragSelectMode={dragSelectMode}
      />
    </div>
  );
}

export default FloorUpdate;
