import React, { useContext, useEffect, useState } from 'react'
import Loader from '../../Loader/Loader'

import {
  useAllSpacesQuery,
  useOwnerLocationQuery,
  usePaymentByIncomeTypeReportQuery,
  useSpaceGetQuery
} from '../../../Slices/spaceApi'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const PaymentByIncomeType = () => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId
  })

  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId })
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId
  })

  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [page, setPage] = useState(1)
  const [page_size, setPage_size] = useState(10)
  const [location, setLocation] = useState(mySpace?.spaceId || null)

  const handleLocation = event => {
    setLocation(event.target.value)
  }
  const { data, isLoading, isError, refetch } =
    usePaymentByIncomeTypeReportQuery({
      spaceId: mySpace?.spaceId || 1, // Adjusted to use mySpace?.spaceId with a fallback
      start,
      end,
      page_size,
      page
    })

  useEffect(() => {
    refetch()
  }, [page, page_size, refetch, start, end]) // Added 'start' and 'end' to dependencies

  // This useEffect can be used for debugging or executing side effects related to `allSpaces`

  const handlePageChange = pageNumber => {
    setPage(pageNumber)
  }

  const handleDates = event => {
    event.preventDefault()
    refetch()
  }

  // Chart data preparation
  const chartData = {
    labels:
      data?.payments.map(payment => payment.paymentMethodName || 'No Name') ||
      [],
    datasets: [
      {
        label: 'Total Payments',
        data: data?.payments.map(payment => payment.totalAmount) || [],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1
      }
    ]
  }

  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top'
      },
      title: {
        display: true,
        text: 'Monthly Bookings'
      }
    }
  }
  if (isLoading) return <Loader />
  if (isError) return <p>Error loading data...</p>
  return (
    <>
      <div className='card card-datatable text-nowrap mt-3'>
        <div
          id='DataTables_Table_1_wrapper'
          className='dataTables_wrapper dt-bootstrap5 no-footer'
        >
          <div className='container-fluid'>
            {/* <div className='row'>
              <div className='col-12'> */}
            {/* {'.'} */}
            {!isLoading && <Bar data={chartData} options={options} />}
            {/* </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentByIncomeType
