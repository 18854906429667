import React, { useEffect, useState } from "react";

import { Select } from "./";

import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  useGetCurrencyQuery,
  useUpdateSpaceMutation,
} from "../../Slices/spaceApi";

import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../../Context/GlobalContext";

function Currency({ spaceData }) {
  const { settingsPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();

  const [currencyChanged, setCurrencyChanged] = useState(false);

  const { isLoading: currencyLoading, data: FetchCurrency } =
    useGetCurrencyQuery();

  const [selectedCurrencyId, setSelectedCurrencyId] = useState(
    spaceData?.currency?.id
  );

  const handleCurrency = (selectedOption) => {
    setSelectedCurrencyId(selectedOption.id);
    setCurrencyChanged(true);
  };
  const handleCurrencyChange = () => {
    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: { currency: selectedCurrencyId },
    });
  };

  const handleClose = () => {
    setCurrencyChanged(false);
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Currency Updated Successfully!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 1000);

      handleClose();
    }
  }, [resultStats]);
  return (
    <div>
      <div className="card">
        <div className="row p-4">
          <h4>Select Currency</h4>

          {FetchCurrency?.length > 0 && (
            <div className="mb-2 col-sm-12 col-lg-3">
              <label htmlFor="durations" className="mb-1">
                Currency
              </label>
              <Select
                options={FetchCurrency}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={FetchCurrency.find(
                  (obj) => obj.id === selectedCurrencyId
                )}
                onChange={(selectedOption) => handleCurrency(selectedOption)}
              />
            </div>
          )}

          {/* <div className="row mt-5"> */}
          <div className="col-md-12">
            <button
              className="btn btn-primary mt-2 "
              disabled={!currencyChanged || !settingsPermissions[0]?.update}
              onClick={
                settingsPermissions[0]?.update ? handleCurrencyChange : null
              }
            >
              {settingsPermissions[0]?.update ? (
                "Save Changes"
              ) : (
                <span>Save Changes{Lock2}</span>
              )}
            </button>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Currency;
