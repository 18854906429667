import React, { useState } from "react";
import "./index.css";
import { useGlobalContext } from "../../Context/GlobalContext";
import {
  MemberHeader,
  ToogleSwitch,
  SubTotal,
  AddItem,
  AddNote,
  SalesPerson,
  PaymentDetails,
  InvoiceToDetails,
  LogoDetails,
  Flatpickr,
  Select,
} from ".";
import { hash } from "../../Assets/InvoiceSvgs/Icons";
import { format } from "date-fns-tz";
import {
  useDeleteSpaceInvoiceMutation,
  useGetSingleInvoiceQuery,
  useGetSpaceInvoiceByIdQuery,
  useMultiplePackagesCompanyMutation,
  useUpdateBookingInvoiceMutation,
  useUpdateInvoiceMutation,
  useUpdateSpaceInvoiceMutation,
} from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useEffect } from "react";
import { colorStyles } from "../../utils/constant";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import { toast } from "react-hot-toast";
import { requiredAlert } from "../../utils/Alerts";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

const EditMember = ({ data }) => {
  const { rentalFee, serviceCharges, isOverAllTax, currency } =
    useGlobalContext();

  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();

  const { setSettingsKey } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [deleteInvoice, results] = useDeleteSpaceInvoiceMutation();
  const [apiSpinner, setApiSpinner] = useState(false);

  const [updateBookingInvoice, resultStats] = useUpdateInvoiceMutation();

  const { isLoading: loading, data: FetchSpaceInvoice } =
    useGetSingleInvoiceQuery({
      spaceId: spaceId || currentSpaceId,
      id: id,
    });
  const [dueDate, setDueDate] = useState(
    // new Date(FetchSpaceInvoice?.due_date).toLocaleDateString()
    FetchSpaceInvoice?.due_date
  );

  const [updateInvoiceData, setupdateInvoiceData] = useState({
    userId: FetchSpaceInvoice?.member?.id,
    remarks: FetchSpaceInvoice?.remarks,
  });

  // useEffect(() => {
  //   setupdateInvoiceData({
  //     userId: FetchSpaceInvoice?.userId || FetchSpaceInvoice?.companyId,
  //     spacePackageIds: FetchSpaceInvoice?.multiplePackages?.spacePackage?.map(
  //       (item) => ({
  //         packageId: item.id,
  //         quantity: item.quantity || 1,
  //         cost: item.price,
  //         price: item.price,
  //       })
  //     ),
  //     meetingPackageIds:
  //       FetchSpaceInvoice?.multiplePackages?.meetingPackage?.map((item) => ({
  //         meetingRoomId: item.meetingPackageId,
  //         quantity: item.quantity || 1,
  //         cost: item.meetingRoomPackagePrice,
  //         price: item.meetingRoomPackagePrice,
  //       })),
  //     remarks: FetchSpaceInvoice?.remarks,
  //   });
  //   setDiscount(FetchSpaceInvoice?.discountPercentage || 0);
  //   setDueDate(new Date(FetchSpaceInvoice?.dueDate * 1000));
  // }, [FetchSpaceInvoice]);

  const [discount, setDiscount] = useState(
    FetchSpaceInvoice?.discount_percentage || 0
  );
  const [tax, setTax] = useState(serviceCharges);

  const handle = (e) => {
    setupdateInvoiceData({ ...updateInvoiceData, remarks: e.target.value });
  };

  const updateInvoice = (e) => {
    e.preventDefault();

    // if (FetchSpaceInvoice?.invoiceTypeInteger !== 3) {
    //   deleteInvoice({
    //     spaceId: spaceId || currentSpaceId,
    //     id: id,
    //   });
    // }

    const { userId, remarks } = updateInvoiceData;
    // let updateSpacePackageIds = updateInvoiceData?.spacePackageIds?.map(
    //   (item, index) => parseInt(item.packageId)
    // );

    // const updateSpacePackageIds = updateInvoiceData?.spacePackageIds?.map(
    //   (item) => ({
    //     packageId: parseInt(item.packageId),
    //     quantity: parseInt(item.quantity),
    //   })
    // );

    // const updateMeetingRoomIds = updateInvoiceData?.meetingPackageIds?.map(
    //   (item) => ({
    //     quantity: parseInt(item.quantity),
    //     packageId: parseInt(item.meetingRoomId),
    //   })
    // );
    // let updateMeetingRoomIds = updateInvoiceData?.meetingPackageIds?.map(
    //   (item, index) => parseInt(item.meetingRoomId)
    // );

    // const timestamp = Date.parse(dueDate) / 1000;

    // if (FetchSpaceInvoice?.invoiceTypeInteger !== 3) {
    // createManuallyInvoice({
    //   spaceId: data?.spaceId,
    //   data: updateData,
    //   userId,
    // });
    // }

    const inputDate = new Date(dueDate);

    const timeZoneOffsetMinutes = 300; // GMT+5 in minutes

    // Calculate the adjusted date and time
    const adjustedDate = new Date(
      inputDate.getTime() + timeZoneOffsetMinutes * 60 * 1000
    );

    // Format the adjusted date in ISO 8601 format
    const iso8601Date = adjustedDate.toISOString();
    const updateData = {
      // due_date: new Date(dueDate).toISOString(),
      due_date: iso8601Date,

      discount_percentage: parseFloat(discount),
      tax: parseFloat(tax),
      remarks,
    };
    setApiSpinner(true);
    updateBookingInvoice({
      spaceId: spaceId || currentSpaceId,
      id: id,
      updateData,
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      if (resultStats?.error?.data?.error == "Invoice already Paid") {
        requiredAlert("Paid Invoice! Cannot be Edited.");
      } else {
        requiredAlert(resultStats?.error?.data?.error);
      }
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Invoice Updated SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      navigate(`/invoice/${id}/view`);
    }
  }, [resultStats]);

  // useEffect(() => {
  //   if (resultStatsBooking?.isError) {
  //     requiredAlert(resultStatsBooking?.error?.data?.message);
  //   }
  //   if (resultStatsBooking?.isSuccess) {
  //     toast.success("SuccessFully Added!", {
  //       style: {
  //         borderRadius: "10px",
  //         background: "#333",
  //         color: "#fff",
  //       },
  //     });
  //     navigate(`/invoice/${resultStatsBooking?.data?.id}/view`);
  //   }
  // }, [resultStatsBooking]);

  // const handleChange = (e) => {
  //   setupdateInvoiceData({ ...updateInvoiceData, userId: e.userId });
  // };

  // const addItem = () => {
  //   const newSpacePackage = {
  //     packageId: packageData?.[0]?.id,
  //     quantity: 1,
  //     cost: packageData?.[0]?.price,
  //     price: packageData?.[0]?.price,
  //   };

  //   setupdateInvoiceData((prevState) => ({
  //     ...prevState,
  //     spacePackageIds: [...prevState.spacePackageIds, newSpacePackage],
  //   }));
  // };

  // const removeItem = (index) => {
  //   setupdateInvoiceData((prevState) => {
  //     const updatedSpacePackageIds = [...prevState.spacePackageIds];
  //     updatedSpacePackageIds.splice(index, 1);
  //     return { ...prevState, spacePackageIds: updatedSpacePackageIds };
  //   });
  // };

  // const handleFormChange = (index, event) => {
  //   setupdateInvoiceData((prevState) => {
  //     const updatedSpacePackageIds = [...prevState.spacePackageIds];
  //     updatedSpacePackageIds[index][event.target.name] = event.target.value;

  //     const findPackage = packageData?.find(
  //       (item) => item.id == updatedSpacePackageIds[index]?.packageId
  //     );

  //     const quantity = updatedSpacePackageIds[index]?.quantity;

  //     updatedSpacePackageIds[index]["cost"] = findPackage?.price;
  //     updatedSpacePackageIds[index]["price"] = (
  //       findPackage?.price * +quantity
  //     ).toFixed(2);

  //     return { ...prevState, spacePackageIds: updatedSpacePackageIds };
  //   });
  // };

  // const meetingAddItem = () => {
  //   setupdateInvoiceData((prevState) => {
  //     const updatedMeetingPackageIds = [
  //       ...prevState.meetingPackageIds,
  //       {
  //         meetingRoomId: meetingData?.[0]?.id,
  //         quantity: 1,
  //         cost: meetingData?.[0]?.price,
  //         price: meetingData?.[0]?.price,
  //       },
  //     ];
  //     return { ...prevState, meetingPackageIds: updatedMeetingPackageIds };
  //   });
  // };

  // const meetingRemoveItem = (index) => {
  //   setupdateInvoiceData((prevState) => {
  //     const updatedMeetingPackageIds = [...prevState.meetingPackageIds];
  //     updatedMeetingPackageIds.splice(index, 1);
  //     return { ...prevState, meetingPackageIds: updatedMeetingPackageIds };
  //   });
  // };

  // const handleMeetingFormChange = (index, event) => {
  //   setupdateInvoiceData((prevState) => {
  //     const updatedMeetingPackageIds = [...prevState.meetingPackageIds];
  //     updatedMeetingPackageIds[index][event.target.name] = event.target.value;

  //     const findPackage = meetingData.find(
  //       (item) => item.id == updatedMeetingPackageIds[index]?.meetingRoomId
  //     );

  //     const quantity = updatedMeetingPackageIds[index]?.quantity;

  //     updatedMeetingPackageIds[index]["cost"] = findPackage?.price;
  //     updatedMeetingPackageIds[index]["price"] = (
  //       findPackage?.price * +quantity
  //     ).toFixed(2);

  //     return { ...prevState, meetingPackageIds: updatedMeetingPackageIds };
  //   });
  // };

  const addBankDetails = () => {
    setSettingsKey("Information");
    navigate("/setting/accountsetting");
  };

  if (loading || !data) {
    return <Loader height={6.6} />;
  }

  return (
    <section
      className="container-fluid addMember"
      style={{ padding: "0px 16px" }}
    >
      <Breadcrumb className="custom-breadcrumb ml-auto">
        <Breadcrumb.Item>
          <Link to="/invoice/list">Invoice List</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/invoice/${id}/view`}>Invoice View</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/invoice/${id}/edit`}>Invoice Edit</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-xl-9 viewInvoice" id="content">
          <div className="card d-flex">
            <div className="card-body invoice-padding pb-0">
              <div
                className="d-flex justify-content-between flex-lg-row flex-sm-row flex-md-column flex-column invoice-spacing mt-0"
                style={{ alignItems: "start" }}
              >
                <LogoDetails {...FetchSpaceInvoice} {...data} />
                <div className="invoice-number-date mt-md-0">
                  <div className="d-flex align-items-center justify-content-md-end mb-3">
                    <h4 className="invoice-title mb-0">Invoice</h4>
                    <div className="input-group input-group-merge">
                      <div className="input-group-prepend">
                        <div className="input-group-text border-radius">
                          {hash}
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={"00" + FetchSpaceInvoice?.invoiceId}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-3 voiceFix">
                    <p className="title mb-0">Date Issued:</p>
                    <Flatpickr
                      className="form-control"
                      // value={new Date(
                      //   FetchSpaceInvoice?.issued_date
                      // ).toLocaleDateString()}
                      value={
                        // new Date(
                        FetchSpaceInvoice?.issued_date
                        // ).toLocaleDateString("en-US")
                      }
                      disabled={true}
                    />
                  </div>
                  <div className="d-flex align-items-center voiceFix">
                    <p className="title mb-0" style={{ width: "100px" }}>
                      Due Date:
                    </p>
                    <Flatpickr
                      className="form-control"
                      value={dueDate}
                      setreadonly
                      data-test="my-custom-value"
                      onChange={(duedate) => setDueDate(duedate)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* HORIZONTAL ROW */}
            <hr className="invoice-spacing" />
            <div className="card-body invoice-padding pt-0">
              <div className="row row-bill-to invoice-spacing">
                <div className="col-lg-6">
                  <h6 className="invoice-to-title mb-4">Invoice To:</h6>

                  <select name="" className="form-control" disabled>
                    <option value={FetchSpaceInvoice?.member?.name}>
                      {FetchSpaceInvoice?.member?.name}
                    </option>
                  </select>

                  <div className="mt-3">
                    <p className="card-text my-1">
                      {FetchSpaceInvoice?.member?.address}
                    </p>
                    <p className="card-text my-1">
                      {FetchSpaceInvoice?.member?.phoneNo}
                    </p>
                    <p className="card-text my-1">
                      {FetchSpaceInvoice?.member?.email}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-lg-end mt-lg-0 mt-4">
                  {/* {FetchSpaceInvoice?.invoiceTypeInteger !== 3 && ( */}
                  {/* <PaymentDetails
                      data={data}
                      discount={discount}
                      serviceList={updateInvoiceData?.spacePackageIds}
                      serviceMeeting={updateInvoiceData?.meetingPackageIds}
                    /> */}
                  {/* // )} */}

                  <div>
                    <h6 className="invoice-to-title">
                      Pay Bill at Company Account:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td className="pe-3">Total Due:</td>
                          <td>
                            {/* <strong>{FetchSpaceInvoice?.total}</strong>
                             */}
                            <strong>
                              {/* {isNaN(
                                FetchSpaceInvoice?.total -
                                  FetchSpaceInvoice?.adjustment -
                                  (discount / 100) * FetchSpaceInvoice?.total +
                                  (tax / 100) * FetchSpaceInvoice?.total
                              )
                                ? "N/A"
                                : (
                                    FetchSpaceInvoice?.total -
                                    FetchSpaceInvoice?.adjustment -
                                    (discount / 100) *
                                      FetchSpaceInvoice?.total +
                                    (tax / 100) * FetchSpaceInvoice?.total
                                  ).toFixed(2)} */}
                              {(
                                FetchSpaceInvoice?.subtotal -
                                (discount / 100) * FetchSpaceInvoice?.subtotal +
                                FetchSpaceInvoice?.totalTax
                              )
                                //  +
                                // (tax / 100) * FetchSpaceInvoice?.subtotal +
                                // (rentalFee / 100) * FetchSpaceInvoice?.subtotal
                                .toFixed(2) < 0
                                ? 0
                                : (
                                    FetchSpaceInvoice?.subtotal -
                                    (discount / 100) *
                                      FetchSpaceInvoice?.subtotal +
                                    FetchSpaceInvoice?.totalTax
                                  )
                                    //   +
                                    // (tax / 100) * FetchSpaceInvoice?.subtotal +
                                    // (rentalFee / 100) *
                                    //   FetchSpaceInvoice?.subtotal
                                    .toFixed(2)}
                            </strong>{" "}
                            <small className="fw-bold">{currency}</small>
                          </td>
                        </tr>
                        {data?.city && (
                          <tr>
                            <td className="pe-3">City:</td>
                            <td>{data?.city ? data?.city : ""}</td>
                          </tr>
                        )}

                        {/* <tr>
                            <td className="pe-3">Bank Name:</td>
                            <td>
                              {data?.bankName ? data?.bankName : "Allied Bank"}
                            </td>
                          </tr> */}
                        <tr>
                          <td className="pe-3">Bank Name:</td>
                          <td>
                            {data?.bankName ? (
                              data?.bankName
                            ) : (
                              <div
                                style={{
                                  textDecoration: "underline",
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={addBankDetails}
                              >
                                Add Bank Details
                              </div>
                            )}
                          </td>
                        </tr>
                        {data?.branchName && (
                          <tr>
                            <td className="pe-3">Branch Name:</td>
                            <td>{data?.branchName ? data?.branchName : ""}</td>
                          </tr>
                        )}
                        {data?.country && (
                          <tr>
                            <td className="pe-3">Country:</td>
                            <td>{data?.country ? data?.country : ""}</td>
                          </tr>
                        )}
                        {data?.iban && (
                          <tr>
                            <td className="pe-3">IBAN:</td>
                            <td>{data?.iban ? data?.iban : ""}</td>
                          </tr>
                        )}
                        {data?.swiftCode && (
                          <tr>
                            <td className="pe-3">SWIFT Code:</td>
                            <td>{data?.swiftCode ? data?.swiftCode : ""}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* {FetchSpaceInvoice?.multiplePackages?.spacePackage?.length >= 0 &&
                FetchSpaceInvoice?.invoiceTypeInteger !== 3 && (
                  <div className="card-body invoice-padding invoice-product-details border">
                    <h6>Packages</h6>
                    <form className="source-item">
                      <div>
                        {updateInvoiceData?.spacePackageIds?.map(
                          (input, index) => (
                            <div key={index} className="repeater-wrapper my-2">
                              <div className="">
                                <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                                  <div className="row w-100 pe-lg-0 pe-3 py-4">
                                    <div className="col-lg-5 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Item
                                      </p>
                                      <select
                                        name="packageId"
                                        value={input.packageId}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                        className="form-control"
                                      >
                                        {packageData?.map((item, index) => {
                                          return (
                                            <option key={index} value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-lg-3 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Cost
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="24"
                                        name="cost"
                                        value={input.cost}
                                        disabled
                                      />
                                    </div>
                                    <div className="col-lg-2 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Qty
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="1"
                                        min={1}
                                        name="quantity"
                                        value={input.quantity}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                      />
                                    </div>
                                    <div className="col-lg-2 col-12 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Price
                                      </p>
                                      <p className="card-text mb-0">
                                        {
                                          +updateInvoiceData?.spacePackageIds?.[
                                            index
                                          ]?.price
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center justify-content-between border-left invoice-product-actions py-2 px-2">
                                    <CancelIcon
                                      index={() => removeItem(index)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      <div className="">
                        <div className="px-0 mt-3">
                          <button
                            type="button"
                            className="btn btn-primary send-invoice-btn"
                            data-repeater-create=""
                            onClick={addItem}
                          >
                            Add Item
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

              {FetchSpaceInvoice?.multiplePackages?.meetingPackage?.length >=
                0 &&
                FetchSpaceInvoice?.invoiceTypeInteger !== 3 && (
                  <div className="card-body invoice-padding invoice-product-details">
                    <h6>Meeting Packages</h6>
                    <form className="source-item">
                      <div>
                        {updateInvoiceData?.meetingPackageIds?.map(
                          (input, index) => (
                            <div key={index} className="repeater-wrapper my-2">
                              <div className="">
                                <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                                  <div className="row w-100 pe-lg-0  pe-3  py-4">
                                    <div className="col-lg-5 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Item
                                      </p>
                                      <select
                                        name="meetingRoomId"
                                        value={input.meetingRoomId}
                                        onChange={(event) =>
                                          handleMeetingFormChange(index, event)
                                        }
                                        className="form-control"
                                      >
                                        {meetingData?.map((item, index) => {
                                          return (
                                            <option key={index} value={item.id}>
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-lg-3 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Cost
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="24"
                                        name="cost"
                                        value={input?.cost}
                                        disabled
                                      />
                                    </div>
                                    <div className="col-lg-2 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Qty
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="1"
                                        min={1}
                                        name="quantity"
                                        value={input?.quantity}
                                        onChange={(event) =>
                                          handleMeetingFormChange(index, event)
                                        }
                                      />
                                    </div>
                                    <div className="col-lg-2 col-12 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Price
                                      </p>
                                      <p className="card-text mb-0">
                                        {
                                          +updateInvoiceData
                                            ?.meetingPackageIds?.[index]?.price
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center justify-content-between border-left invoice-product-actions py-2 px-2">
                                    <CancelIcon
                                      index={() => meetingRemoveItem(index)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      <div className="">
                        <div className="px-0 mt-3">
                          <button
                            type="button"
                            className="btn btn-primary send-invoice-btn"
                            data-repeater-create=""
                            onClick={meetingAddItem}
                          >
                            Add Item
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )} */}

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {/* <th className="">
                      {FetchSpaceInvoice?.assignedPackage
                        ? "Packages"
                        : "Services"}
                    </th> */}
                    <th className="">
                      {/* {FetchSpaceInvoice?.type == 1
                        ? "Packages"
                        : FetchSpaceInvoice?.type == 2
                        ? "Services"
                        : "Security Deposit"} */}
                      {FetchSpaceInvoice?.type == 1
                        ? "Packages"
                        : FetchSpaceInvoice?.type == 2
                        ? "Services"
                        : FetchSpaceInvoice?.type == 3
                        ? "Security Deposit"
                        : FetchSpaceInvoice?.type == 4
                        ? "Public Meeting Room"
                        : FetchSpaceInvoice?.type == 5
                        ? "Public Hot Desk"
                        : "Memeber Booking"}
                    </th>
                    {FetchSpaceInvoice?.type == 1 ? (
                      <>
                        {" "}
                        <th className="">Service Charges</th>
                        <th className="">Rental Fee</th>
                      </>
                    ) : FetchSpaceInvoice?.type == 2 ? (
                      <>
                        {" "}
                        <th className="">Service Charges</th>
                        <th className="">Rental Fee</th>
                      </>
                    ) : (
                      <th className="">Amount</th>
                    )}

                    {/* <th className="">Service Charges</th>
                    <th className="">Rental Fee</th> */}
                    <th className="">Quantity</th>

                    <th className="">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {FetchSpaceInvoice?.invoiceTypeInteger === 3 && (
                        <tr>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {FetchSpaceInvoice.invoiceType}
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {FetchSpaceInvoice.price}
                            </p>
                          </td>
                          <td className="">
                            <span className="">
                              {FetchSpaceInvoice.qty || 1}
                            </span>
                          </td>
                          <td className="">
                            <span className="">{FetchSpaceInvoice.price}</span>
                          </td>
                        </tr>
                      )} */}
                  {FetchSpaceInvoice?.type == 5 ? (
                    <tr>
                      <td className="">
                        <p className="card-text font-weight-bold mb-1">
                          Hot Desk
                        </p>
                      </td>
                      <td className="">
                        <p className="card-text font-weight-bold mb-1">
                          {FetchSpaceInvoice?.subtotal}
                        </p>
                      </td>
                      <td className="">
                        <span className="">1</span>
                      </td>
                      <td className="">
                        <span className="">{FetchSpaceInvoice?.subtotal}</span>
                      </td>
                    </tr>
                  ) : FetchSpaceInvoice?.type == 3 ? (
                    <tr>
                      <td className="">
                        <p className="card-text font-weight-bold mb-1">
                          SecurityDeposit
                        </p>
                      </td>
                      <td className="">
                        <p className="card-text font-weight-bold mb-1">
                          {FetchSpaceInvoice?.subtotal}
                        </p>
                      </td>
                      <td className="">
                        <span className="">1</span>
                      </td>
                      <td className="">
                        <span className="">{FetchSpaceInvoice?.subtotal}</span>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/* {FetchSpaceInvoice?.assignedPackage?.meeting_packages?.map(
                    (item, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.name}
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.serviceCharges}
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.rentalFee}
                            </p>
                          </td>
                          <td className="">
                            <span className="">{item.quantity || 1}</span>
                          </td>
                          <td className="">
                            <span className="">
                              {(item.quantity || 1) *
                                (item.package.serviceCharges +
                                  item.package.rentalFee)}
                            </span>
                          </td>
                        </tr>
                      );
                    }
                  )}

                  {FetchSpaceInvoice?.assignedPackage?.packages?.map(
                    (item, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.name}
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.serviceCharges}
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.rentalFee}
                            </p>
                          </td>
                          <td className="">
                            <span className="">{item.quantity || 1}</span>
                          </td>
                          <td className="">
                            <span className="">
                              {(item.quantity || 1) *
                                (item.package.serviceCharges +
                                  item.package.rentalFee)}
                            </span>
                          </td>
                        </tr>
                      );
                    }
                  )} */}
                  {FetchSpaceInvoice?.assignedPackage?.meeting_packages?.map(
                    (item, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.name}
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.serviceCharges}
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.rentalFee}
                            </p>
                          </td>
                          <td className="">
                            <span className="">{item.quantity || 1}</span>
                          </td>
                          {/* <td className="">
                                <span className="">Tax Rate</span>
                              </td>
                              <td className="">
                                <span className="">
                                  {(item.quantity || 1) *
                                    (item.package.serviceCharges +
                                      item.package.rentalFee)}{" "}
                                  {currency}
                                </span>
                              </td> */}
                          <td className="">
                            {item?.package?.taxRate && (
                              <span className="">
                                {item?.package?.taxRate?.name}
                                <br />
                              </span>
                            )}

                            {item?.package?.taxRate ? (
                              item?.package?.taxRate?.setOveralTax ? (
                                <small>
                                  Overall Tax:{" "}
                                  {item?.package?.taxRate?.serviceCharges}
                                </small>
                              ) : (
                                <>
                                  <small className="text-nowrap">
                                    Service Charges:{" "}
                                    {item?.package?.taxRate?.serviceCharges} %
                                  </small>
                                  <br />
                                  <small className="text-nowrap">
                                    Rental Fee:{" "}
                                    {item?.package?.taxRate?.rentalFee} %
                                  </small>
                                </>
                              )
                            ) : isOverAllTax ? (
                              <small>Overall Tax: {serviceCharges}</small>
                            ) : (
                              <>
                                <small className="text-nowrap">
                                  Service Charges: {serviceCharges} %
                                </small>
                                <br />
                                <small className="text-nowrap">
                                  Rental Fee: {rentalFee} %
                                </small>
                              </>
                            )}
                          </td>
                          <td className="">
                            <span className="">
                              {item?.package?.taxRate
                                ? item?.package?.taxRate?.setOveralTax
                                  ? (item.quantity || 1) *
                                    (item.package.serviceCharges +
                                      (item.package.serviceCharges *
                                        item?.package?.taxRate
                                          ?.serviceCharges) /
                                        100 +
                                      item.package.rentalFee +
                                      (item.package.rentalFee *
                                        item?.package?.taxRate
                                          ?.serviceCharges) /
                                        100)
                                  : (item.quantity || 1) *
                                    (item.package.serviceCharges +
                                      (item.package.serviceCharges *
                                        item?.package?.taxRate
                                          ?.serviceCharges) /
                                        100 +
                                      item.package.rentalFee +
                                      (item.package.rentalFee *
                                        item?.package?.taxRate?.rentalFee) /
                                        100)
                                : isOverAllTax
                                ? (item.quantity || 1) *
                                  (item.package.serviceCharges +
                                    (item.package.serviceCharges *
                                      serviceCharges) /
                                      100 +
                                    item.package.rentalFee +
                                    (item.package.rentalFee * serviceCharges) /
                                      100)
                                : (item.quantity || 1) *
                                  (item.package.serviceCharges +
                                    (item.package.serviceCharges *
                                      serviceCharges) /
                                      100 +
                                    item.package.rentalFee +
                                    (item.package.rentalFee * rentalFee) /
                                      100)}{" "}
                              {currency}
                            </span>
                          </td>
                        </tr>
                      );
                    }
                  )}

                  {FetchSpaceInvoice?.assignedPackage?.packages?.map(
                    (item, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.name}
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.serviceCharges}
                            </p>
                          </td>
                          <td className="">
                            <p className="card-text font-weight-bold mb-1">
                              {item.package.rentalFee}
                            </p>
                          </td>
                          <td className="">
                            <span className="">{item.quantity || 1}</span>
                          </td>
                          <td className="">
                            <span className="">
                              {item?.package?.taxRate
                                ? item?.package?.taxRate?.name
                                : ""}
                              <br />
                              {item?.package?.taxRate &&
                              item?.package?.taxRate?.setOveralTax ? (
                                <small>
                                  Overall Tax:{" "}
                                  {item?.package?.taxRate?.serviceCharges}
                                </small>
                              ) : (
                                <>
                                  <small className="text-nowrap">
                                    Service Charges:{" "}
                                    {item?.package?.taxRate?.serviceCharges} %
                                  </small>
                                  <br />
                                  <small className="text-nowrap">
                                    Rental Fee:{" "}
                                    {item?.package?.taxRate?.rentalFee} %
                                  </small>
                                </>
                              )}
                            </span>
                          </td>
                          <td className="">
                            <span className="">
                              {item?.package?.taxRate
                                ? item?.package?.taxRate?.setOveralTax
                                  ? (item.quantity || 1) *
                                    (item.package.serviceCharges +
                                      (item.package.serviceCharges *
                                        item?.package?.taxRate
                                          ?.serviceCharges) /
                                        100 +
                                      item.package.rentalFee +
                                      (item.package.rentalFee *
                                        item?.package?.taxRate
                                          ?.serviceCharges) /
                                        100)
                                  : (item.quantity || 1) *
                                    (item.package.serviceCharges +
                                      (item.package.serviceCharges *
                                        item?.package?.taxRate
                                          ?.serviceCharges) /
                                        100 +
                                      item.package.rentalFee +
                                      (item.package.rentalFee *
                                        item?.package?.taxRate?.rentalFee) /
                                        100)
                                : isOverAllTax
                                ? (item.quantity || 1) *
                                  (item.package.serviceCharges +
                                    (item.package.serviceCharges *
                                      serviceCharges) /
                                      100 +
                                    item.package.rentalFee +
                                    (item.package.rentalFee * serviceCharges) /
                                      100)
                                : (item.quantity || 1) *
                                  (item.package.serviceCharges +
                                    (item.package.serviceCharges *
                                      serviceCharges) /
                                      100 +
                                    item.package.rentalFee +
                                    (item.package.rentalFee * rentalFee) /
                                      100)}{" "}
                              {currency}
                            </span>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
            {FetchSpaceInvoice?.type != 3 && (
              <div className="row invoice-padding py-3 align-items-center">
                <div className="col-md-3">Total Discount:</div>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    min={0}
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
              </div>
            )}

            {/* <div className="row invoice-padding py-2 align-items-center">
              <div className="col-md-3">Tax:</div>
              <div className="col-md-9">
                <input
                  type="number"
                  className="form-control"
                  value={tax}
                  min={0}
                  onChange={(e) => setTax(e.target.value)}
                />
              </div>
            </div> */}
            <hr className="invoice-spacing mt-0" />
            <div className="card-body invoice-padding">
              <div className="row invoice-sales-total-wrapper ">
                <div className="col-lg-7">
                  <SalesPerson name={FetchSpaceInvoice?.space?.name} />
                </div>
                {/* <div className="col-lg-4 offset-lg-1 d-flex justify-content-lg-end justify-content-center mt-5 mt-lg-0">
                  <>
                    <SubTotal
                      // serviceList={serviceList}
                      // serviceMeeting={serviceMeeting}
                      price={FetchSpaceInvoice?.subtotal}
                      tax={serviceCharges}
                      rentalFee={rentalFee}
                      discount={discount}
                      isOverAllTax={isOverAllTax}
                    />
                  </>
                 
                </div> */}
                <div className="total col-md-4 offset-md-1 d-flex justify-content-end  mt-5 mt-md-0">
                  <>
                    <div className="invoice-total-wrapper">
                      <div className="invoice-total-item">
                        <p className="invoice-total-title">Subtotal:</p>
                        <p className="invoice-total-amount">
                          {isNaN(FetchSpaceInvoice?.subtotal)
                            ? 0
                            : FetchSpaceInvoice?.subtotal}{" "}
                          <small className="fw-bold">{currency}</small>
                        </p>
                      </div>
                      {/* {FetchSpaceInvoice?.setOveralTax && (
                        <div className="invoice-total-item">
                          <p className="invoice-total-title">Overall Tax:</p>
                          <p className="invoice-total-amount">
                            {FetchSpaceInvoice?.serviceCharges}%
                          </p>
                        </div>
                      )}
                      {!FetchSpaceInvoice?.setOveralTax && (
                        <>
                          <div className="invoice-total-item">
                            <p className="invoice-total-title">
                              Service Charges:
                            </p>
                            <p className="invoice-total-amount">
                              {FetchSpaceInvoice?.serviceCharges} %
                            </p>
                          </div>
                          <div className="invoice-total-item">
                            <p className="invoice-total-title">Rental Fee:</p>
                            <p className="invoice-total-amount">
                              {FetchSpaceInvoice?.rentalFee} %
                            </p>
                          </div>
                        </>
                      )} */}
                      <>
                        <div className="invoice-total-item">
                          <p className="invoice-total-title">Tax Amount:</p>
                          <p className="invoice-total-amount">
                            {FetchSpaceInvoice?.totalTax}
                          </p>
                        </div>
                      </>
                      <hr />
                      <div className="invoice-total-item p-1">
                        {/* {FetchSpaceInvoice?.setOveralTax ? (
                              <p className="invoice-total-amount ml-auto">
                                {FetchSpaceInvoice?.subtotal +
                                  (FetchSpaceInvoice?.serviceCharges / 100) *
                                    FetchSpaceInvoice?.subtotal}
                              </p>
                            ) : ( */}
                        <p className="invoice-total-amount ml-auto">
                          {/* {FetchSpaceInvoice?.subtotal +
                                  (FetchSpaceInvoice?.serviceCharges / 100) *
                                    FetchSpaceInvoice?.subtotal +
                                  (FetchSpaceInvoice?.rentalFee / 100) *
                                    FetchSpaceInvoice?.subtotal} */}
                          {FetchSpaceInvoice?.subtotal +
                            FetchSpaceInvoice?.totalTax}{" "}
                          <small className="fw-bold">{currency}</small>
                        </p>
                        {/* )} */}
                      </div>

                      <div className="invoice-total-item">
                        <p className="invoice-total-title">Discount:</p>
                        <p className="invoice-total-amount">
                          {discount > 100 ? 100 : discount || 0} %
                        </p>
                      </div>

                      {/* <div className="invoice-total-item">
                        <p className="invoice-total-title">Adjustment:</p>
                        <p className="invoice-total-amount">
                          {FetchSpaceInvoice?.adjustment || 0}
                        </p>
                      </div> */}
                      <div className="invoice-total-item"></div>
                      <hr className="my-2" />
                      <div className="invoice-total-item p-1">
                        <p className="invoice-total-title">Total:</p>
                        <p className="invoice-total-amount">
                          {/* {isNaN(
                            FetchSpaceInvoice?.total.toFixed(2) < 0
                              ? "N/A"
                              : FetchSpaceInvoice?.total.toFixed(2)
                          )
                            ? 0
                            : FetchSpaceInvoice?.total.toFixed(2) < 0
                            ? "N/A"
                            : FetchSpaceInvoice?.total.toFixed(2)} */}
                          {(
                            FetchSpaceInvoice?.subtotal -
                            (discount / 100) * FetchSpaceInvoice?.subtotal +
                            FetchSpaceInvoice?.totalTax
                          ).toFixed(2)}{" "}
                          <small className="fw-bold">{currency}</small>
                        </p>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div className="form-group mb-4 invoice-padding">
              <label
                htmlFor="note"
                className="form-label font-weight-bold mb-0"
              >
                Note:
              </label>
              <textarea
                className="form-control"
                rows="2"
                name="remarks"
                id="remarks"
                value={updateInvoiceData?.remarks}
                onChange={handle}
                placeholder={"Write a note here"}
              ></textarea>
            </div>
          </div>
        </div>

        <div className="col-xl-3 hidePrint">
          <div
            className="card"
            style={{
              position: window.innerWidth > 1201 ? "fixed" : "static",
              width:
                window.innerWidth > 1201
                  ? window.innerWidth < 1440
                    ? "16%"
                    : "20%"
                  : "auto",
            }}
          >
            <div className="card-body">
              <button
                className="btn btn-outline-secondary mb-2 w-100"
                disabled={apiSpinner}
                onClick={updateInvoice}
              >
                Save{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditMember;
