import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../Context/GlobalContext";
import {
  useGetAllCompaniesQuery,
  useGetAllIndividualsQuery,
  useGetAllUpComingBookingQuery,
  useGetMeetingRoomQuery,
} from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import BookingTableView from "./BookingTableView";

const HotDeskBookingList = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Bookings");
  }, []);

  const { data: FetchCompanies, isLoading: loading } = useGetAllCompaniesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: FetchUsers, isLoading: userLoading } =
    useGetAllIndividualsQuery({
      spaceId: spaceId || currentSpaceId,
    });
  const { data: FetchMeetingData, isLoading } = useGetMeetingRoomQuery({
    id: spaceId || currentSpaceId,
    room_type: 1,
  });
  console.log("Meeting data is", FetchMeetingData);

  // const { isLoading: bookingLoading, data: FetchUpComingBooking } =
  //   useGetAllUpComingBookingQuery({
  //     spaceId: spaceId || currentSpaceId,
  //     past: "False",
  //     type: 2,
  //   });
  // console.log("Hot Desk booking are", FetchUpComingBooking);
  if (loading || isLoading || userLoading) {
    return <Loader height={6.6} />;
  }

  return (
    <div>
      {" "}
      <BookingTableView
        companies={FetchCompanies?.users}
        meetingData={FetchMeetingData?.meetingRooms}
        // FetchUpComingBooking={FetchUpComingBooking?.bookingss}
        Users={FetchUsers?.users}
      />
    </div>
  );
};

export default HotDeskBookingList;
