import React from "react";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import { ReactComponent as CancelIcon } from "../../Assets/CancelIcon.svg";

import { Modal, Button, Badge } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useCancelMemberPackageMutation,
  useDeleteQuotationMutation,
  useDeleterCompanyUserMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  confirmationCancelAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useEffect } from "react";
function CancelMemberPackage({ id, itemId, packageType }) {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [cancelPackage, resultStats] = useCancelMemberPackageMutation();

  const handleDelete = (e) => {
    e.preventDefault();

    confirmationCancelAlert("Want to Cancel This Package?").then(
      (willDelete) => {
        if (willDelete) {
          cancelPackage({
            spaceId: spaceId || currentSpaceId,
            itemId: itemId,
            packageId: id,
            packageType: packageType,
          });
        }
      }
    );
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Package Cancelled Successfully.");
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);
  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip" style={{ transition: "none" }}>
            Cancel Package
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          id="viewMeetingOpen"
          onClick={handleDelete}
          style={{ transition: "none" }}
        >
          <CancelIcon />
          {/* <Badge bg="danger"> */}
          {/* </Badge> */}
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export default CancelMemberPackage;
