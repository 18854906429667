import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";

const ViewInvoicePopup = ({ id }) => {
  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">View Invoice</Tooltip>}
      >
        <Button
          variant="white"
          className="p-0"
          as={Link}
          to={`/invoice/${id}/view`}
        >
          <Preview />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default ViewInvoicePopup;
