import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import {
  useMeetingPackageGetQuery,
  useMeetingPackageUpdateMutation
  //   useMeetingPackageUpdateMutation
} from '../../Slices/spaceApi'
import Loader from '../Loader/Loader'
import Swal from 'sweetalert2'
import CreatableSelect from 'react-select/creatable'
import { clippingParents } from '@popperjs/core'
import { current } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { isFirstCharacterDigit } from '../../utils/validation'

const MeetingPackageUpdate = ({
  handleClose,
  operateId,
  account,
  taxRate,
  MeetingPackageData: data
}) => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  const [defaultAccount, setDefaultAccount] = useState()
  const [defaultTaxRate, setDefaultTaxRate] = useState()
  const [accounts, setAccounts] = useState([])
  const [taxRates, setTaxRates] = useState([])
  const [input, setInput] = useState({ name: '' })
  const [validated, setValidated] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  //   const { data, isLoading, refetch } = useMeetingPackageGetQuery({
  //     spaceId:spaceId|| currentSpaceId,
  //     id: operateId
  //   })
  const [updateMeetingPackage, result] = useMeetingPackageUpdateMutation()
  useEffect(() => {
    if (!account?.isLoading && !account?.isError) {
      //.log({ yehaaccount: account })
      const updatedArray = account?.data?.accounts?.map(({ id, name }) => ({
        value: id,
        label: name
      }))

      setAccounts(updatedArray)
      //.log({ accounts })
    }
  }, [account])

  useEffect(() => {
    if (!taxRate?.isLoading && !taxRate?.isError) {
      //.log({ taxRate })
      const updatedArray = taxRate?.data?.taxRates?.map(({ id, name }) => ({
        value: id,
        label: name
      }))

      setTaxRates(updatedArray)
    }
  }, [taxRate])

  useEffect(() => {
    if (result?.isSuccess) {
      handleClose()
      toast.success('Meeting Package updated Successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      })
    }
  }, [result])
  //   useEffect(() => {
  //     refetch({ spaceId:spaceId|| currentSpaceId, id: operateId })
  //   }, [])
  //.log(isSubmit)
  const handleInput = e => {
    setInput(prevInput => ({
      ...prevInput,
      [e.target.name]: e.target.value
    }))
  }

  const handleUpdate = event => {
    setIsSubmit(true)
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
      setValidated(true)
    } else {
      setValidated(false)

      updateMeetingPackage({
        spaceId: spaceId || currentSpaceId,
        id: operateId,
        input
      })
    }
  }
  const handleAccountChange = selectedOption => {
    setInput(prevInput => ({
      ...prevInput,
      account: selectedOption.value || null
    }))
    //.log(input)
  }
  const handleTaxRateChange = selectedOption => {
    setInput(prevInput => ({
      ...prevInput,
      taxRate: selectedOption.value || null
    }))
    //.log(input)
  }
  useEffect(() => {
    if (data) {
      const inputObject = {
        name: data?.name
      }

      if (data?.account) {
        inputObject.account = data?.account
      }

      if (data?.taxRate) {
        inputObject.taxRate = data?.taxRate
      }
      // setDefaultAccount({ value: input.account })
      // setDefaultTaxRate({ value: input.taxRate })
      setInput(inputObject)
      //.log({ input })
    }
  }, [data])
  useEffect(() => {
    if (result.isError) {
      const firstError = result.error.data.error
      Swal.fire({
        title: 'Required!',
        icon: 'warning',
        html: firstError,
        confirmButtonText: 'OK'
      })
    }
  }, [result])
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        className='w-10'
      >
        <Modal.Header closeButton className=''>
          <Modal.Title as='h5'>Update Meeting Package</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {data ? (
            <>
              <Form noValidate validated={validated} onSubmit={handleUpdate}>
                <Row className='mb-3 mt-3'>
                  <Form.Group>
                    <Form.Label>
                      <h6>Meeting Package Name</h6>
                    </Form.Label>
                    <Form.Control
                      className='viewonly'
                      id='AssetCatName'
                      required
                      disabled
                      type='text'
                      placeholder='Meeting Package Name'
                      value={input.name}
                      name='name'
                      onChange={handleInput}
                    ></Form.Control>

                    {validated && (
                      <Form.Control.Feedback type='invalid'>
                        {!input.name ? (
                          'This field is Required'
                        ) : isFirstCharacterDigit(input.name) ? (
                          'Must start with an alphabet'
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className='mb-2'>
                  <Form.Group md='5' controlId='account' as={Col}>
                    <Form.Label>
                      <h6>Account</h6>
                    </Form.Label>
                    <CreatableSelect
                      placeholder={
                        input.account && accounts
                          ? accounts
                              .map(item => {
                                if (item.value === input.account) {
                                  return item.label
                                }
                              })
                              .filter(Boolean)[0] || '-'
                          : '-'
                      }
                      onChange={handleAccountChange}
                      options={accounts}
                    />
                  </Form.Group>

                  <Form.Group md='5' controlId='category' as={Col}>
                    <Form.Label>
                      <h6>Tax Rate</h6>
                    </Form.Label>
                    <CreatableSelect
                      onChange={handleTaxRateChange}
                      placeholder={
                        input.taxRate && taxRates
                          ? taxRates
                              .map(item => {
                                if (item.value === input.taxRate) {
                                  return item.label
                                }
                              })
                              .filter(Boolean)[0] || '-'
                          : '-'
                      }
                      options={taxRates}
                    />
                  </Form.Group>
                </Row>
                {result.isLoading ? (
                  <>
                    <Button className='btn btn-primary mr-3'>
                      Loading...
                      <Spinner
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                        variant='primary'
                      />
                    </Button>
                  </>
                ) : (
                  <Button className='btn btn-primary mr-3' type='submit'>
                    Update
                  </Button>
                )}{' '}
                <Button className='btn btn-secondary' onClick={handleClose}>
                  Cancel
                </Button>
              </Form>
            </>
          ) : (
            <Loader />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MeetingPackageUpdate
