import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useEffect } from "react";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import axios from "axios";
import MeetingInputs from "./MeetingInputs";
import MeetingFields from "./MeetingFields";
import { toast, Toaster } from "react-hot-toast";
import { useCreateMeetingRoomMutation } from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { useGlobalContext } from "../../Context/GlobalContext";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import Select from "react-select";

import * as Icon from "react-bootstrap-icons";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { colorStyles } from "../../utils/constant";
const MeetingModal = ({ FetchSpaceTypes }) => {
  const { resourcesPermissions, billingOption } = useGlobalContext();

  const { currentSpaceId, spaceId } = useSelector((item) => item.spaceReducer);
  const [createMeetingRoom, resultStats] = useCreateMeetingRoomMutation();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState();
  const [allDone, setAllDone] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [errorSpaceType, setErrorSpaceType] = useState(false);

  const [apiSpinner, setApiSpinner] = useState(false);

  const [meetingValues, setMeetingValues] = useState({
    title: "",
    price: 0,
    capacity: "",
    imageUrls: null,
    inclusiveTax: true,
    bookable: false,
    advancedPayment: false,
    room_type: 1,
    spaceType: "",
  });
  const handleInclusiveTax = (event) => {
    setMeetingValues({
      ...meetingValues,
      inclusiveTax: event.target.checked,
    });
  };
  const handleBookable = (event) => {
    setMeetingValues({
      ...meetingValues,
      bookable: event.target.checked,
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      // requiredAlert(resultStats?.error);
      requiredAlert(resultStats?.error?.data?.error);
    }

    if (resultStats?.isSuccess) {
      // setTimeout(() => {
      handleClose();
      // setMeetingValues({
      //   ...meetingValues,
      //   price: "",
      //   capacity: "",
      //   title: "",
      // });
      // setFile("");
      // }, 1500);
      setApiSpinner(false);

      toast.success("Meeting Room Created Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      setSubmitted(false);
      setError(false);
      setIsUploading(false);
      setFile(null);
      setMeetingValues({
        title: "",
        price: 0,
        capacity: "",
        inclusiveTax: true,
        bookable: false,

        imageUrls: null,
        advancedPayment: false,
        room_type: 1,
        spaceType: "",
      });
      setErrorSpaceType(false);
    }, 500);
  };

  const handleShow = () => setShow(true);
  const ali = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  const postMeetingRoom = async (e) => {
    e.preventDefault();

    if (!meetingValues?.spaceType && !file) {
      setErrorSpaceType(true);
      setError(true);

      return;
    }

    setSubmitted(true);
    if (!file) {
      setError(true);

      return;
    }
    if (!meetingValues?.spaceType) {
      setErrorSpaceType(true);
      return;
    }

    const isFileValid =
      file && meetingValues?.imageUrls?.length > 0 && !allDone;

    if (!isFileValid) {
      return;
    }
    if (meetingValues?.title?.length > 25) {
      return;
    }

    let capacity = Number(meetingValues?.capacity);
    let price = Number(meetingValues?.price);
    let title = meetingValues?.title;
    let imageUrls = meetingValues?.imageUrls;
    let advancedPayment = meetingValues?.advancedPayment;
    let room_type = meetingValues?.room_type;
    let spaceType = meetingValues?.spaceType;
    let inclusiveTax = meetingValues?.inclusiveTax;
    let bookable = meetingValues?.bookable;

    let data = {
      capacity,
      title,
      price,
      imageUrls,
      inclusiveTax,
      bookable,
      advancedPayment,
      room_type,
      spaceType,
    };
    setApiSpinner(true);

    createMeetingRoom({ spaceId: spaceId || currentSpaceId, data: data });
  };
  const onBlurSpaceType = () => {
    if (meetingValues.spaceType === "") {
      setErrorSpaceType(true);
    }
  };
  const onFocusSpaceType = (e) => {
    if (e.target.value == meetingValues.spaceType) {
      setErrorSpaceType(false);
    }
  };
  const handleType = (e) => {
    setMeetingValues({ ...meetingValues, spaceType: e.id });
  };

  const onchange = (e) => {
    setMeetingValues({ ...meetingValues, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }

    setError(false);
  };

  useEffect(() => {
    if (
      !meetingValues.capacity == "" &&
      meetingValues.capacity.length >= 0 &&
      // !meetingValues.price == "" &&
      // meetingValues.price.length > 0 &&
      !meetingValues.spaceType == ""
      // &&
      // meetingValues.title.match("^(?=.*[A-Za-z0-9])[A-Za-z0-9!@#$%^&* ]{6,}$")
    ) {
      setAllDone(false);
    } else {
      setAllDone(true);
    }
  }, [meetingValues]);
  const [uploadedImageUrls, setUploadedImageUrls] = useState(null);

  useEffect(() => {
    if (!file == "" && file !== null) {
      setIsUploading(true);

      if (file.size >= 1e7) {
        errorAlertImage("File is too big!");
        setFile("");
        setIsUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUploadedImageUrls(resp.data);
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
      } else {
        setFile("");
        requiredAlert("Please Select Image File");

        setIsUploading(false);
      }
    }
    setError(false);
  }, [file]);

  useEffect(() => {
    setMeetingValues({ ...meetingValues, imageUrls: uploadedImageUrls });
  }, [uploadedImageUrls]);

  // useEffect(() => {
  //   if (!file == "") {
  //     if (file.size >= 1e7) {
  //       errorAlertImage("File is too big!");
  //       setFile("");
  //     } else {
  //     }
  //   }
  //   const fd = new FormData();
  //   fd.append("image", file);
  //   axios.post("/uploadImage/", fd).then((resp) => {
  //     setMeetingValues({ ...meetingValues, imageUrls: resp.data });
  //   });

  //   setError(false);
  // }, [file]);

  return (
    <div className="d-flex justify-content-end">
      <Button
        variant="primary"
        onClick={resourcesPermissions[0]?.create ? handleShow : null}
        className="send-invoice-btn"
      >
        {resourcesPermissions[0]?.create ? (
          <span className="invoice">
            <Plus /> Add Resource
          </span>
        ) : (
          <span className="invoice">
            <Plus /> Add Resource
            <Lock />
          </span>
        )}
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        id="meetingPopUp"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="" closeButton>
          <Modal.Title as="h5">Add Resource</Modal.Title>
        </Modal.Header>
        <form className="meetingForm">
          <Modal.Body>
            <div className="mb-2">
              <label htmlFor="name">Image</label>

              <div className="media">
                <div className="media-aside align-self-start">
                  <img
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : "https://placehold.jp/90x90.png"
                    }
                    style={{
                      width: "90px",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    className="rounded"
                  />
                </div>
                <div>
                  <input
                    id="real-file"
                    type="file"
                    hidden="hidden"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="media-body mt-2">
                  {/* <button onClick={ali} className="btn btn-primary me-2 btn-sm">
                    Upload
                  </button> */}
                  {isUploading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Uploading...</span>
                    </div>
                  ) : (
                    <button
                      id="custom-button"
                      onClick={ali}
                      className="btn btn-secondary me-2 btn-sm"
                    >
                      Upload
                    </button>
                  )}
                  <p className="card-text mt-2">
                    <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "}
                    &nbsp;Allowed Max size of 10Mb
                    {/* <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">Allowed Max size of 10Mb</Tooltip>
                      }
                    >
                      <Button variant="white" className="mx-2 p-0">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />
                      </Button>
                    </OverlayTrigger> */}
                  </p>
                  {/* {!file || !meetingValues?.imageUrls?.length > 0 ? (
                    <div className="invalid-feedback">
                      Please select an image.
                    </div>
                  ) : null} */}
                  {error ? (
                    <div className="invalid-feedback">
                      Please select an image.
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {MeetingInputs()?.map((input) => {
              if (input.id === 1 || input.id === 2) {
                return (
                  <MeetingFields
                    submitted={submitted}
                    key={input.id}
                    {...input}
                    value={meetingValues[input.name]}
                    onChange={onchange}
                  />
                );
              }
            })}
            {meetingValues?.bookable && (
              <>
                {MeetingInputs()?.map((input) => {
                  if (input.id === 3) {
                    return (
                      <MeetingFields
                        submitted={submitted}
                        key={input.id}
                        {...input}
                        value={meetingValues[input.name]}
                        onChange={onchange}
                      />
                    );
                  }
                })}
              </>
            )}
            {billingOption == 1 && meetingValues?.bookable && (
              <div className="custom-formcheck form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inclusiveTax"
                  checked={meetingValues?.inclusiveTax}
                  onChange={(e) => handleInclusiveTax(e)}
                />
                <label className="form-check-label" htmlFor="inclusiveTax">
                  Price includes state Taxes
                </label>
              </div>
            )}

            <div className="custom-formcheck form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="bookable"
                checked={meetingValues?.bookable}
                onChange={(e) => handleBookable(e)}
              />
              <label className="form-check-label" htmlFor="bookAble">
                Slot Based Booking
              </label>
            </div>

            {FetchSpaceTypes?.length > 0 && (
              <div className="mb-2">
                <label htmlFor="space-type" className="mb-1">
                  Space Type
                </label>
                <Select
                  className={errorSpaceType ? "reactSelectActive" : ""}
                  options={FetchSpaceTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  styles={colorStyles}
                  value={FetchSpaceTypes?.find(
                    (obj) => obj.id === meetingValues?.spaceType
                  )}
                  onBlur={onBlurSpaceType}
                  onChange={handleType}
                  onFocus={onFocusSpaceType}
                />
                {errorSpaceType && (
                  <span className="e">Select The SpaceType</span>
                )}
              </div>
            )}
            <div className="mt-4">
              {" "}
              <Button
                variant="primary"
                type="submit"
                disabled={
                  apiSpinner ||
                  isUploading ||
                  !meetingValues?.title ||
                  // !meetingValues?.price ||
                  !meetingValues?.capacity ||
                  !meetingValues?.spaceType
                }
                onClick={
                  resourcesPermissions[0]?.create ? postMeetingRoom : null
                }
              >
                Add Resource{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <button
                className="btn btn-secondary ml-3"
                disabled={apiSpinner}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer className="mt-auto">
           
          
          </Modal.Footer> */}
        </form>
      </Modal>
    </div>
  );
};

export default MeetingModal;
