import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import {
  useTaxRatesCreateMutation,
  useTaxRatesUpdateMutation
} from '../../Slices/spaceApi'
import Swal from 'sweetalert2'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { isFirstCharacterDigit, isValidInteger } from '../../utils/validation'

const TaxRatesAdd = ({ handleClose, operateId, operateObject }) => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  const [input, setInput] = useState({
    name: '',
    serviceCharges: null,
    rentalFee: null,
    setOveralTax: false
  })
  const [validated, setValidated] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [addTaxRates, result] = useTaxRatesCreateMutation()
  const [updateTaxRates, updated] = useTaxRatesUpdateMutation()
  useEffect(() => {
    if (operateId && operateObject) {
      setInput(operateObject)
    }
  }, [operateId, operateObject])
  const handleInput = e => {
    setInput(prevInput => ({
      ...prevInput,
      [e.target.name]: e.target.value
    }))
  }
  useEffect(() => {
    if (result?.isSuccess) {
      handleClose()
      toast.success('Tax Rate Added Successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      })
    }
    if (updated?.isSuccess) {
      handleClose()
      toast.success('Tax Rate Updated Successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      })
    }
  }, [result, updated])
  const handleAdd = event => {
    setIsSubmit(true)
    const form = event.currentTarget
    //.log(form.checkValidity())
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
      setValidated(true)
    } else {
      setValidated(false)
      !operateId
        ? addTaxRates({
            spaceId: spaceId || currentSpaceId,
            input
          })
        : updateTaxRates({
            spaceId: spaceId || currentSpaceId,
            id: operateId,
            input
          })
    }
  }
  const handleCheckBox = () => {
    setInput(prevState => {
      return { ...prevState, setOveralTax: !prevState.setOveralTax }
    })
  }
  useEffect(() => {
    if (input.setOveralTax === true) {
      setInput(prevState => {
        return { ...prevState, rentalFee: 0 }
      })
    }
  }, [input.setOveralTax])
  useEffect(() => {
    if (result.isError) {
      const firstError = result.error.data.error
      Swal.fire({
        title: 'Required!',
        icon: 'warning',
        html: firstError,
        confirmButtonText: 'OK'
      })
    }
    if (updated.isError) {
      const firstError = updated.error.data.error
      Swal.fire({
        title: 'Required!',
        icon: 'warning',
        html: firstError,
        confirmButtonText: 'OK'
      })
    }
  }, [result, updated])
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        className='w-10'
      >
        <Modal.Header closeButton className=''>
          <Modal.Title as='h5'>
            {operateId ? 'Update Tax Rates' : 'Add Tax Rates'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAdd}>
            <Row className='mb-3 mt-3'>
              <Form.Group controlId='TaxRates'>
                <Form.Label className='mb-2'>
                  <h6>Tax Rate Name</h6>
                </Form.Label>
                <Form.Control
                  className='viewonly'
                  required
                  pattern='[A-Za-z].*'
                  type='text'
                  placeholder='Tax Rate A'
                  value={input.name}
                  name='name'
                  onChange={handleInput}
                ></Form.Control>

                {validated && (
                  <Form.Control.Feedback type='invalid'>
                    {!input.name ? (
                      'This field is Required'
                    ) : isFirstCharacterDigit(input.name) ? (
                      'Must start with an alphabet'
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row className='mb-2'>
              <Form.Group as={Col} md='5' controlId='TaxRates'>
                {!input.setOveralTax ? (
                  <Form.Label>
                    <h6>Service Charges %</h6>{' '}
                  </Form.Label>
                ) : (
                  <Form.Label>
                    <h6>Overall Tax %</h6>{' '}
                  </Form.Label>
                )}

                <Form.Control
                  className='viewonly form-control-no-arrow'
                  required
                  min={0}
                  max={100}
                  type='number'
                  placeholder='3%'
                  value={input.serviceCharges || ''}
                  name='serviceCharges'
                  onChange={handleInput}
                ></Form.Control>

                {validated && (
                  <Form.Control.Feedback type='invalid'>
                    {!input.serviceCharges ? (
                      'This field is Required'
                    ) : !isValidInteger(input.serviceCharges) ||
                      input.serviceCharges > 100 ? (
                      'Must be a valid percentage'
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {!input.setOveralTax ? (
                <Form.Group as={Col} md='5' controlId='TaxRates'>
                  <Form.Label>
                    <h6>Rental Fee %</h6>{' '}
                  </Form.Label>
                  <Form.Control
                    className='viewonly'
                    required
                    disabled={input.setOveralTax}
                    type='number'
                    min={0}
                    max={100}
                    placeholder='1%'
                    value={input.rentalFee || ''}
                    name='rentalFee'
                    onChange={handleInput}
                  ></Form.Control>

                  {validated && (
                    <Form.Control.Feedback type='invalid'>
                      {!input.rentalFee ? (
                        'This field is Required'
                      ) : !isValidInteger(input.rentalFee) ||
                        input.rentalFee > 100 ? (
                        'Must be a valid percentage'
                      ) : (
                        <></>
                      )}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ) : (
                <></>
              )}
            </Row>

            <Row className='mb-3 mt-3'>
              <Form.Group controlId='setOveralTax'>
                <Form.Check
                  type='checkbox'
                  label='Set Overall Tax'
                  name='setOveralTax'
                  checked={input.setOveralTax}
                  onChange={handleCheckBox}
                />
              </Form.Group>
            </Row>

            {result.isLoading || updated.isLoading ? (
              <>
                <Button className='btn btn-primary'>
                  Loading...{' '}
                  <Spinner
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                    variant='primary'
                  />
                </Button>
              </>
            ) : (
              <Button className='btn btn-primary' type='submit'>
                {operateId ? 'Update Tax Rate' : 'Add Tax Rate'}
              </Button>
            )}
            <Button className='btn btn-secondary ml-3' onClick={handleClose}>
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TaxRatesAdd
