import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUpdateNotificationsSettingsMutation } from "../../Slices/spaceApi";
import { requiredAlert, userDeleteAlert } from "../../utils/Alerts";
import { useGlobalContext } from "../../Context/GlobalContext";

const NotificationSettings = ({ notificationSettings, notificationTypes }) => {
  const { slack } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [settings, setSettings] = useState(notificationSettings);

  useEffect(() => {
    if (notificationSettings?.length === 0) {
      setSettings(notificationTypes);
    } else {
      const mergedSettings = notificationTypes?.map((existingCategory) => {
        const newCategory = notificationSettings?.find(
          ({ category: { id } }) => id === existingCategory.category.id
        );
        if (newCategory) {
          const mergedTypes = existingCategory?.types?.map((newType) => {
            const existingType = newCategory?.types?.find(
              (type) => type.id === newType.id
            );
            return existingType
              ? existingType
              : { ...newType, email: false, portal: false, slack: false };
          });
          return { ...newCategory, types: mergedTypes };
        } else {
          return {
            ...existingCategory,
            types: existingCategory?.types?.map((type) => ({
              ...type,
              email: false,
              portal: false,
              slack: false,
            })),
          };
        }
      });
      setSettings(mergedSettings);
    }
  }, [notificationSettings, notificationTypes]);

  const [updateNotificationsSettings, resultStats] =
    useUpdateNotificationsSettingsMutation();
  const handleCheckboxChange = (categoryId, typeId, field) => {
    const updatedSettings = settings?.map((category) => {
      if (category?.category?.id === categoryId) {
        return {
          ...category,
          types: category?.types?.map((type) => {
            if (type.id === typeId) {
              return { ...type, [field]: !type[field] };
            }
            return type;
          }),
        };
      }
      return category;
    });
    setSettings(updatedSettings);
  };

  const saveChanges = () => {
    updateNotificationsSettings({
      spaceId: spaceId || currentSpaceId,

      data: settings,
    });
  };
  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Notification settings updated successfully.");
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.error || "An Error Occured!");
    }
  }, [resultStats]);
  return (
    // <div className="card mb-4 col-12">
    //   <div className="card-body">
    //     {settings?.map((category) => (
    //       <div key={category?.category?.id}>
    //         <h5 className="card-title mb-2">{category?.category?.name}</h5>

    //         <div class="card ">
    //           <div className="table-responsive rounded">
    //             <table className="table">
    //               <thead>
    //                 <tr>
    //                   <th>Type</th>
    //                   <th>Email</th>
    //                   <th>Portal</th>
    //                   {slack && <th>Slack</th>}
    //                 </tr>
    //               </thead>

    //               {category?.types?.map((type) => (
    //                 <tbody key={type?.id}>
    //                   <tr>
    //                     <td className="text-nowrap">{type?.name}</td>

    //                     <td>
    //                       <div className="form-check d-flex">
    //                         <input
    //                           type="checkbox"
    //                           checked={type?.email}
    //                           onChange={() =>
    //                             handleCheckboxChange(
    //                               category.category?.id,
    //                               type.id,
    //                               "email"
    //                             )
    //                           }
    //                         />
    //                       </div>
    //                     </td>
    //                     <td>
    //                       <div className="form-check d-flex justify-content-start">
    //                         <input
    //                           type="checkbox"
    //                           checked={type?.portal}
    //                           onChange={() =>
    //                             handleCheckboxChange(
    //                               category.category?.id,
    //                               type.id,
    //                               "portal"
    //                             )
    //                           }
    //                         />
    //                       </div>
    //                     </td>
    //                     {slack && (
    //                       <td>
    //                         <div className="form-check d-flex ">
    //                           <input
    //                             type="checkbox"
    //                             checked={type.slack}
    //                             onChange={() =>
    //                               handleCheckboxChange(
    //                                 category?.category?.id,
    //                                 type?.id,
    //                                 "slack"
    //                               )
    //                             }
    //                           />
    //                         </div>
    //                       </td>
    //                     )}
    //                   </tr>
    //                 </tbody>
    //               ))}
    //             </table>
    //           </div>
    //         </div>
    //       </div>
    //     ))}

    //     <button className="btn btn-primary" onClick={saveChanges}>
    //       Save Changes
    //     </button>
    //   </div>
    // </div>
    <div
      className="tab-pane fade show active"
      id="notifications"
      role="tabpanel"
    >
      <div className="card mb-4">
        <div className="card-body">
          {settings?.map((category) => (
            <div key={category?.category?.id}>
              <h5 className="card-title mb-2">{category?.category?.name}</h5>

              <div class="card shadow-none mb-4 border">
                <div className="table-responsive rounded">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="text-nowrap w-50">Type</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Portal</th>
                        {slack && <th className="text-center">Slack</th>}
                      </tr>
                    </thead>

                    <tbody>
                      {category?.types?.map((type) => (
                        <tr key={type?.id}>
                          <td className="text-nowrap">{type?.name}</td>

                          <td>
                            <div className="custom-formcheck form-check text-center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={type?.email}
                                onChange={() =>
                                  handleCheckboxChange(
                                    category.category?.id,
                                    type.id,
                                    "email"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className=" custom-formcheck form-check text-center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={type?.portal}
                                onChange={() =>
                                  handleCheckboxChange(
                                    category.category?.id,
                                    type.id,
                                    "portal"
                                  )
                                }
                              />
                            </div>
                          </td>
                          {slack && (
                            <td>
                              <div className="custom-formcheck form-check text-center">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={type.slack}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      category?.category?.id,
                                      type?.id,
                                      "slack"
                                    )
                                  }
                                />
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}

          <button className="btn btn-primary" onClick={saveChanges}>
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
