import React, { useContext, useEffect, useRef, useState } from 'react'

import { Form } from 'react-bootstrap'

import TabularReport from '../TabularReports'
import { useSelector } from 'react-redux'
import {
  useAllSpacesQuery,
  useOwnerLocationQuery,
  useBookingByWeekDayReportQuery,
  useSpaceGetQuery
} from '../../../Slices/spaceApi'
import Loader from '../../Loader/Loader'
import { Pie } from 'react-chartjs-2'
import CardForMeeting from './CardForMeeting'

const BookingByWeekDays = () => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)
  const { data, isLoading, isError } = useBookingByWeekDayReportQuery({
    spaceId: spaceId || currentSpaceId
  })

  return (
    <div className='container-fluid with-bottom-padding p_16 mt-3'>
      <div className='row'>
        {!isLoading &&
          data?.map((item, index) => (
            <CardForMeeting key={index} data={item}></CardForMeeting>
          ))}
      </div>
    </div>
  )
}

export default BookingByWeekDays
// {!isLoading && <Pie data={chartData} options={options} />}
