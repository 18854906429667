import React from "react";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";
import { Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
function ViewComapanyUser({ name, phoneNo, email, cnicNo, address }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip" style={{ transition: "none" }}>
            View Member
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          id="viewMeetingOpen"
          onClick={handleShow}
          style={{ transition: "none" }}
        >
          <Preview />
        </Button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {name && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Name: <span className="text-muted">{name}</span>
                </h5>
              </div>
            </div>
          )}
          {email && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Email: <span className="text-muted">{email}</span>
                </h5>
              </div>
            </div>
          )}
          {cnicNo && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                ID Card Number: <span className="text-muted">{cnicNo}</span>
                </h5>
              </div>
            </div>
          )}
          {phoneNo && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Phone No: <span className="text-muted">{phoneNo}</span>
                </h5>
              </div>
            </div>
          )}
          {address && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Address: <span className="text-muted">{address}</span>
                </h5>
              </div>
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}

export default ViewComapanyUser;
