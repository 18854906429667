import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useEffect } from "react";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import {
  useGetFacilitiesQuery,
  useGetSpaceTypesQuery,
} from "../../Slices/constantApi";
import Loader from "../Loader/Loader";
import {
  useCreateFloorPlanMutation,
  useGetSpaceDataQuery,
  useUpdateFloorRoomMutation,
  useUpdateSpaceMutation,
} from "../../Slices/spaceApi";

const EditDesk = ({
  shape,
  deskOpen,
  setDeskOpen,
  setShowDelete,
  setSelectedShape,
  FloorPlans,
  AssignedUsers,
  Amenities,
}) => {
  const [updateFloorRooms, resultStats] = useUpdateFloorRoomMutation();

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setDeskOpen(false);
    setShowDelete(false);
    setSelectedShape(null);
  };
  const handleShow = () => setShow(true);

  const [roomValues, setRoomValues] = useState({
    id: "",
    name: "", // Initialize with empty values
    floor: "",
    neighbourhood: "",
    availableFrom: null,
    availableTo: null,
    amenities: [],
    assignedTo: "",
    capacity: 0,
    description: "",
    image: "",
  });

  useEffect(() => {
    const parseDate = (isoDateString) => {
      const date = new Date(isoDateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    setRoomValues({
      id: shape?.id || null,
      name: shape?.name || "",
      floor: shape?.floor || "",
      neighbourhood: shape?.neighbourhood || "No Neighbourhood",
      availableFrom: shape?.availableFrom
        ? parseDate(shape.availableFrom)
        : getCurrentDateTime(),
      availableTo: shape?.availableTo,
      // ? parseDate(shape.availableTo)
      // : getCurrentDateTime(),
      amenities: shape?.amenities || [],
      assignedTo: shape?.assignedTo || null,
      capacity: shape?.capacity || 0,
      description: shape?.description || "",
      image: shape?.image || null,
    });
  }, [shape]);

  const [file, setFile] = useState("");
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };
  const upload = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  useEffect(() => {
    if (!file == "" && file !== null) {
      if (file.size >= 1e7) {
        errorAlertImage("File is too Big");
        setFile("");
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        setRoomValues({ ...roomValues, image: "" });
        fd.append("image", file);
        axios.post("/uploadImage/", fd).then((resp) => {
          setRoomValues({ ...roomValues, image: resp.data?.[0] });
        });
      } else {
        setFile("");
        requiredAlert("Please Select Image File");
      }
    }
  }, [file]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "assignedTo") {
      setRoomValues({
        ...roomValues,
        [name]: parseInt(value),
      });
    } else if (name === "capacity") {
      setRoomValues({
        ...roomValues,
        [name]: parseInt(value),
      });
    } else {
      setRoomValues({
        ...roomValues,
        [name]: value,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    updateFloorRooms({
      id: roomValues?.id,
      updateData: roomValues,
    });
  };
  const handleAmenities = (e) => {
    setRoomValues({
      ...roomValues,
      amenities: Array.isArray(e) ? e.map((x) => x.id) : [],
    });
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      toast.success("The Room has been Updated.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      handleClose();
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);

  function getCurrentDateTime() {
    const currentDate = new Date();

    const formattedDate = currentDate.toISOString().slice(0, 16);
    return formattedDate;
  }

  return (
    <div>
      <Modal
        show={deskOpen}
        onHide={handleClose}
        id="meetingPopUp"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title as="h5">
            {shape?.type == 1
              ? "Edit Dedicated Desk"
              : shape?.type == 2
              ? "Edit Hot Desk"
              : shape?.type == 3
              ? "Edit Private Office"
              : shape?.type == 4
              ? "Edit Meeting Room"
              : ""}
          </Modal.Title>
        </Modal.Header>
        <form className="meetingForm" onSubmit={handleSubmit}>
          <Modal.Body>
            {shape.type == 4 ? (
              <div className="form-group">
                <div className="media">
                  <div className="media-aside uploadgn-self-start">
                    <img
                      src={
                        file
                          ? URL.createObjectURL(file)
                          : shape?.image.length > 0
                          ? shape?.image
                          : "https://placehold.jp/90x90.png"
                      }
                      style={{
                        width: "90px",
                        height: "90px",
                        objectFit: "cover",
                      }}
                      className="rounded"
                    />
                  </div>
                  <div>
                    <input
                      id="real-file"
                      name="file"
                      type="file"
                      hidden="hidden"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="media-body mt-2">
                    <button
                      id="custom-button"
                      onClick={upload}
                      className="btn btn-secondary me-2 btn-sm"
                    >
                      Upload
                    </button>
                    <p className="card-text mt-2 mb-0">
                      Allowed Max size of 10Mb
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                required
                value={roomValues.name}
                onChange={handleInputChange}
                className="form-control"
                // pattern={"^[A-Za-z ][A-Za-z0-9!@#$%^&* ]*$"}
                placeholder="Enter Desk's Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="floor">Floor</label>
              <input
                type="text"
                name="floor"
                id="floor"
                value={
                  FloorPlans?.find((item) => item.id === roomValues.floor)?.name
                }
                // onChange={handleInputChange}
                className="form-control"
                placeholder="Floor"
                readOnly
              />
            </div>
            {shape.type == 3 || shape.type == 4 ? (
              <div className="form-group">
                <label htmlFor="capacity">Capacity</label>
                <input
                  type="number"
                  name="capacity"
                  id="capacity"
                  min={0}
                  value={roomValues.capacity}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Capacity"
                />
              </div>
            ) : null}
            {shape.type == 4 ? (
              <div className="form-group">
                <label htmlFor="name">Description</label>
                <textarea
                  name="description"
                  id="description"
                  required
                  value={roomValues.description}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Description..."
                />
              </div>
            ) : null}

            {shape.type == 1 || shape.type == 2 ? (
              <div className="form-group">
                <label htmlFor="neighbourhood">Neighbourhood</label>
                <input
                  type="text"
                  name="neighbourhood"
                  id="neighbourhood"
                  value={roomValues.neighbourhood}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Neighbourhood"
                />
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="availableFrom">Available From</label>
              <input
                type="datetime-local"
                name="availableFrom"
                id="availableFrom"
                value={roomValues?.availableFrom}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="availableTo">Available To:</label>
              <input
                type="datetime-local"
                name="availableTo"
                id="availableTo"
                value={roomValues?.availableTo}
                min={getCurrentDateTime()}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="mb-2">
              <label htmlFor="space-type" className="mb-1">
                Amenities
              </label>
              <Select
                options={Amenities}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                styles={colorStyles}
                value={Amenities?.filter((obj) =>
                  roomValues.amenities?.includes(obj.id)
                )}
                onChange={handleAmenities}
                isMulti
                id="package-facilities"
              />
            </div>
            {shape.type == 1 || shape.type == 3 ? (
              <div className="form-group">
                <label htmlFor="assignedTo">Assign To:</label>
                <select
                  name="assignedTo"
                  id="assignedTo"
                  value={roomValues?.assignedTo}
                  onChange={handleInputChange}
                  className="form-control"
                >
                  <option value="">Select User</option>
                  {AssignedUsers?.map((user) => (
                    <option key={user?.id} value={user?.id}>
                      {user?.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer className="mt-auto">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditDesk;
