import React from "react";
import { Flatpickr } from "../AddInvoices";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useCreateVisitMutation } from "../../Slices/spaceApi";
import { toast } from "react-hot-toast";
import { requiredAlert } from "../../utils/Alerts";
import { useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
function AddVisit({ visitorData }) {
  const navigate = useNavigate();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [createVisit, resultStats] = useCreateVisitMutation();
  const [selectedVisitorId, setSelectedVisitorId] = useState(
    visitorData?.visitors?.[0]?.id
  );

  const [locationValid, setLocationValid] = useState(true);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [dateError, setDateError] = useState("");

  const handleVisitorChange = (event) => {
    const selectedId = event.target.value;
    setSelectedVisitorId(selectedId);
  };

  const [visitData, setVisitData] = useState({
    visitor: selectedVisitorId,
    expected_visit_datetime: new Date(new Date().getTime() + 30 * 60000),
    purpose_of_visit: "",
    location: "",
    person_visiting: "",
  });

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDateTime;
  };
  const calculateMinDateTime = () => {
    const currentDateTime = new Date();
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const hours = String(currentDateTime.getHours()).padStart(2, "0");
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");

    const minDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    return minDateTime;
  };

  useEffect(() => {
    setVisitData((prevState) => ({
      ...prevState,
      expected_visit_datetime: formatDateTime(
        new Date(Date.now() + 30 * 60000)
      ),
    }));
  }, []);

  const handleDate = (event) => {
    const { name, value } = event.target;
    setVisitData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "expected_visit_datetime") {
      const selectedDateTime = new Date(value);
      const currentDateTime = new Date();

      if (selectedDateTime < currentDateTime) {
        setDateError("Date or time cannot be in the past");
        return;
      } else {
        setDateError("");
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVisitData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "location") {
      setLocationValid(value.trim() !== "");
    }
  };

  const handleSubmit = () => {
    if (visitData.location.trim() === "") {
      setLocationValid(false);
      return;
    }

    const updatedData = {
      visitor: selectedVisitorId,
      expected_visit_datetime: visitData?.expected_visit_datetime,
      purpose_of_visit: visitData?.purpose_of_visit,
      location: visitData?.location,
      person_visiting: visitData?.person_visiting,
    };

    if (dateError) {
      return;
    }
    setApiSpinner(true);
    createVisit({
      spaceId: spaceId || currentSpaceId,
      visitData: updatedData,
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Visit Created SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      navigate("/visit/list");
    }
  }, [resultStats]);
  return (
    <div
      className="container-fluid registration"
      style={{ padding: "0px 16px" }}
    >
      <Breadcrumb className="custom-breadcrumb ml-auto">
        <Breadcrumb.Item>
          <Link to="/visit/list">Visits List</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Add Visit</Breadcrumb.Item>
      </Breadcrumb>
      <div className={`card  mx-auto`}>
        <div className="card-body">
          <div className="col-md-10 mb-3">
            <label htmlFor="visitor">Visitor</label>
            <select
              id="selectVisitor"
              name="visitor"
              className="form-control"
              onChange={handleVisitorChange}
              value={selectedVisitorId}
            >
              {/* <option disabled value="">
                Select Visitor
              </option> */}
              {visitorData?.visitors?.map((visitor) => (
                <option key={visitor?.id} value={visitor?.id}>
                  {visitor?.name}
                </option>
              ))}
            </select>
            <Link to="/visitors/add">
              <p
                className="text-primary mt-1 fw-bold ml-2"
                style={{ cursor: "pointer" }}
              >
                Add New Visitor
              </p>
            </Link>

            <div className="col-md-10">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="form-group">
                    <label htmlFor="expected_visit_datetime">
                      Date and time
                    </label>
                    <div className="input-group ">
                      <div className="input-group-append">
                        <span
                          className="input-group-text"
                          style={{
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                          }}
                        >
                          <Icon.Calendar2Check />
                        </span>
                      </div>
                      <input
                        type="datetime-local"
                        className="form-control"
                        name="expected_visit_datetime"
                        value={formatDateTime(
                          visitData?.expected_visit_datetime
                        )}
                        min={calculateMinDateTime()}
                        onChange={handleDate}
                      />
                    </div>

                    {dateError && (
                      <span className="text-danger ml-2">*{dateError}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="location">Spot Reserved</label>
                    <input
                      type="text"
                      id="location"
                      name="location"
                      placeholder="Enter Spot Reserved"
                      value={visitData?.location}
                      className={`form-control ${
                        !locationValid ? "is-invalid" : ""
                      }`}
                      onChange={handleChange}
                    />
                    {!locationValid && (
                      <div className="invalid-feedback">
                        *Enter spot reserved
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-10">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="personVisiting">
                      Person You Are Visiting
                    </label>
                    <input
                      type="text"
                      id="personVisiting"
                      className="form-control"
                      name="person_visiting"
                      value={visitData?.person_visiting}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="reason">Reason of Visiting</label>
                    <input
                      type="text"
                      id="reason"
                      name="purpose_of_visit"
                      value={visitData?.purpose_of_visit}
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div>
          <div className="row mt-lg-5 mt-md-4 mt-sm-3 mt-2"></div>
          <div className="row mt-lg-5 mt-md-4 mt-sm-3 mt-2"></div>
        </div>

        <div className="text-right mb-4 mr-4">
          <button
            className="btn btn-primary"
            disabled={apiSpinner || !visitData?.location}
            onClick={handleSubmit}
          >
            Add Visit{" "}
            {apiSpinner ? (
              <div
                className="spinner-border text-primary"
                role="status"
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                }}
              ></div>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddVisit;
