// const moment = require("moment");
// const momentTimeZone = require("moment-timezone");

const moment = require("moment");

export function localDateTimeToUTC(localDateTimeStrings) {
  const utcDateTimes = localDateTimeStrings?.map((localDateTimeString) => {
    const localDateTime = moment(localDateTimeString);

    // If the input date string is not in a recognized format, moment might return an invalid date
    if (!localDateTime.isValid()) {
      console.error("Invalid date format");
      return null; // or handle the error in a way that makes sense for your application
    }

    // Convert to UTC using the utc method directly
    return localDateTime.utc().toISOString();
  });

  return utcDateTimes;
}
