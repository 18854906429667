import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  useAccountListQuery,
  useDurationQuery,
  useGetSpaceDurationsQuery,
  useProductCategoryListQuery,
  useProductCreateMutation,
  useProductUpdateMutation,
  useTaxRatesListQuery,
} from "../../Slices/spaceApi";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import axios from "axios";
import { PlusCircle } from "react-bootstrap-icons";
import Benefit from "./Benefit";
import CreatableSelect from "react-select/creatable";
import { useSelector } from "react-redux";
import { isFirstCharacterDigit, isValidInteger } from "../../utils/validation";
import Select from "react-select";
import { requiredAlert } from "../../utils/Alerts";
import { useGlobalContext } from "../../Context/GlobalContext";
// import { type } from "@testing-library/user-event/dist/type";
const url = process.env.REACT_APP_API_URL;

const ProductAdd = ({ handleClose, operateId, operateObject }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { currency } = useGlobalContext();

  const upload = useRef(null);
  // //.log({ operateObject })

  // //.log(input, ' input')

  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [addProduct, result] = useProductCreateMutation();
  const [updateProduct, updated] = useProductUpdateMutation();
  const [file, setFile] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [period, setPeriod] = useState([]);
  const { data: spaceDurations } = useGetSpaceDurationsQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [category, setCategory] = useState([]);
  const { data: cat, isLoading: catIsLoading } = useProductCategoryListQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [input, setInput] = useState({
    duration: spaceDurations?.spaceDurations?.[0]?.id,
    benefits: { dayPass: { quantity: 0 }, meetingRoomAllowedHours: [] },
  });
  useEffect(() => {
    if (spaceDurations?.spaceDurations) {
      const updatedArray = spaceDurations?.spaceDurations?.map(
        ({ id, spaceDuration }) => ({
          value: id,
          label: spaceDuration,
        })
      );

      setPeriod(updatedArray);
    }
  }, [spaceDurations]);
  useEffect(() => {
    if (cat) {
      const updatedArray = cat.categories.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setCategory(updatedArray);
    }
  }, [cat]);
  useEffect(() => {
    if (file !== null && file !== "") {
      setIsUploading(true);
      if (file.size >= 1e7) {
        //.log('File is too big!')
        setFile(null);
        setIsUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("file", file);
        axios
          .post(`${url}/uploadImage/`, fd, {
            // headers: {
            //   credentials: credentials
            // }
          })
          .then((resp) => {
            setInput({ ...input, image: resp.data?.[0] });
            //.log('file')
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
      } else {
        setFile(null);
        //.log('Please Select Image File')
        setIsUploading(false);
      }
    }
  }, [file]);

  const handleInput = (e) => {
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    if (result?.isSuccess) {
      handleClose();
      toast.success("Product Added SuccessFully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
    if (updated?.isSuccess) {
      handleClose();
      toast.success("Product updated SuccessFully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [result, updated]);
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      //.log('file ', event.target.files[0])
      setFile(event.target.files[0]);
    }
  };
  const handleCheckBox = (event) => {
    setInput((prevState) => {
      let name = event.target.name;
      return { ...prevState, [name]: !prevState[name] };
    });
  };

  useEffect(() => {
    if (operateId && operateObject) {
      //.log('operate object', operateObject)
      setInput({
        ...operateObject, // Copy existing properties from operateObject
        benefits: {
          ...operateObject.benefits, // Copy existing benefits
          dayPass: {
            quantity: operateObject.benefits?.dayPass?.quantity || 0, // Copy dayPass quantity or set to 0 if undefined
          },
          meetingRoomAllowedHours: [
            ...(operateObject.benefits?.meetingRoomAllowedHours || []),
          ], // Copy meetingRoomAllowedHours or create empty array if undefined
        },
      });
    }
  }, [operateId, operateObject]);

  //.log('input ', input)

  const handleAdd = (event) => {
    setIsSubmit(true);
    const form = event.currentTarget;
    //.log(form.checkValidity())
    event.preventDefault();
    if (form.checkValidity() === false || !input.category) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      const updatedInput = { ...input }; // Create a copy of the input object
      updatedInput.benefits.meetingRoomAllowedHours =
        updatedInput.benefits.meetingRoomAllowedHours.filter(
          (item) => item.meetingRoom.length > 0
        );

      setInput(updatedInput);

      !operateId
        ? addProduct({
            spaceId: spaceId || currentSpaceId,
            input,
          })
        : updateProduct({
            spaceId: spaceId || currentSpaceId,
            id: operateId,
            input,
          });
    }
  };
  const handleCategoryChange = (selectedOption) => {
    setInput((prevInput) => ({
      ...prevInput,
      category: selectedOption.value || null,
    }));
    //.log(input)
  };
  useEffect(() => {
    if (result.isError) {
      // const firstField = Object.keys(result.error.data)[0];
      // const firstError = result.error.data[firstField][0];
      // Swal.fire({
      //   title: "Validation Errors",
      //   icon: "error",
      //   html: firstError,
      //   confirmButtonText: "OK",
      // });
      const errorMessage =
        result &&
        result?.error &&
        result?.error?.data &&
        Object?.values(result?.error?.data).find(
          (error) => error?.length > 0 || "An Error Occured"
        );

      requiredAlert(errorMessage || "An Error Occured");
    }
    if (updated.isError) {
      // const firstField = Object.keys(updated.error.data)[0];
      // const firstError = updated.error.data[firstField][0];
      // Swal.fire({
      //   title: "Validation Errors",
      //   icon: "error",
      //   html: firstError,
      //   confirmButtonText: "OK",
      // });
      const errorMessage =
        updated &&
        updated?.error &&
        updated?.error?.data &&
        Object?.values(updated?.error?.data).find(
          (error) => error?.length > 0 || "An Error Occured"
        );

      requiredAlert(errorMessage || "An Error Occured");
    }
  }, [result, updated]);

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="w-10"
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">
            {!operateId ? "Add  Product" : "Update Product"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAdd}>
            <Row className="mb-3">
              <Form.Group>
                <Form.Label className="mb-2">
                  <h6>Image (Optional)</h6>{" "}
                </Form.Label>
                <Form.Control
                  ref={upload}
                  type="file"
                  onChange={handleFileChange}
                  hidden
                />

                {/* <Form.Control
                  type='file'
                  required={!operateId ? true : false}
                  onChange={handleFileChange}
                  className=''
                /> */}
                <div className="row">
                  {" "}
                  <div className="media">
                    <div className="media-aside align-self-start">
                      <img
                        alt="failed to fetch"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "https://placehold.jp/90x90.png";
                        }}
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : input?.image || "https://placehold.jp/90x90.png"
                        }
                        className="rounded"
                        style={{
                          width: "90px",
                          height: "90px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="media-body mt-2">
                      {isUploading ? (
                        <Spinner />
                      ) : (
                        <div
                          id="custom-button"
                          className="btn btn-secondary me-2 btn-sm"
                          onClick={() => upload?.current?.click()}
                        >
                          Upload
                        </div>
                      )}
                      <p className="card-text mt-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          style={{ color: " currentcolor" }}
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                        </svg>{" "}
                        Allowed Max size of 10Mb
                      </p>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-3 ">
              <Form.Group controlId="Product">
                <Form.Label className="mb-2">
                  <h6>Product Name</h6>
                </Form.Label>
                <Form.Control
                  className="viewonly"
                  required
                  pattern="[A-Za-z].*"
                  type="text"
                  placeholder="Chair"
                  value={input.name}
                  name="name"
                  onChange={handleInput}
                ></Form.Control>

                {validated && (
                  <Form.Control.Feedback type="invalid">
                    {!input.name ? (
                      "This field is Required"
                    ) : isFirstCharacterDigit(input.name) ? (
                      "Must start with an alphabet"
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row className="mb-3 ">
              <Form.Group controlId="Product">
                <Form.Label className="mb-2">Price</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{currency}</span>
                  </div>
                  <Form.Control
                    className="viewonly"
                    required
                    min={0.01}
                    step={0.01}
                    type="number"
                    placeholder="10"
                    value={input.price}
                    name="price"
                    onChange={handleInput}
                  ></Form.Control>
                </div>
                {validated && (
                  <Form.Control.Feedback type="invalid">
                    {!input.price ? (
                      "This field is Required"
                    ) : //  !isValidInteger(input.price)
                    input.price <= 0 ? (
                      "Must be a Positive Value"
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            {/* <Row className='mb-3 '>
              <Form.Group controlId='duation'>
                <Form.Label>
                  <h6>Duration</h6>
                </Form.Label>
                <Form.Control
                  className='viewonly'
                  required
                  min={0}
                  type='number'
                  placeholder='10'
                  defaultValue=''
                  value={input.duration}
                  name='duration'
                  onChange={handleInput}
                ></Form.Control>

                {validated && (
                  <Form.Control.Feedback type='invalid'>
                    {!input.duration ? (
                      'This field is Required'
                    ) : !isValidInteger(input.duration) ? (
                      'Must be a Positive Integer'
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row> */}
            <Row className="mb-3">
              <Form.Group as={Col} md="5" controlId="duration">
                <Form.Label className="mb-2">
                  <h6>Duration</h6>
                </Form.Label>
                <Form.Select
                  required
                  onChange={handleInput}
                  placeholder="Select a Period"
                  value={input.duration}
                  name="duration"
                  options={period}
                >
                  <option value=" " disabled>
                    Select a Period
                  </option>
                  {period.map((options) => (
                    <option
                      selected={input?.duration === options?.value}
                      key={options.value}
                      value={options.value}
                    >
                      {options.label}
                    </option>
                  ))}
                </Form.Select>
                {validated && !input.duration && (
                  <Form.Control.Feedback type="invalid">
                    This field is Required*
                  </Form.Control.Feedback>
                )}{" "}
              </Form.Group>

              <Form.Group md="5" controlId="category" as={Col}>
                <Form.Label>
                  <h6>Category</h6>
                </Form.Label>
                <Select
                  isRequired
                  onChange={handleCategoryChange}
                  placeholder={
                    input.category
                      ? (
                          category.find(
                            (item) => item.value === input.category
                          ) || { label: "Add Category" }
                        ).label
                      : "Add Category"
                  }
                  options={category}
                  className={
                    validated && !input.category
                      ? `border border-danger rounded`
                      : ``
                  }
                />
                {validated && !input.category ? (
                  <p className="text-danger fs-7">This field is Required*</p>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group md="5" as={Col}>
                <Form.Check
                  type="checkbox"
                  id="custom-checkbox1"
                  className="fs-7"
                  label="Add On Purchase?"
                  name="addOnPurchase"
                  checked={input.addOnPurchase}
                  onChange={handleCheckBox}
                />
              </Form.Group>
              <Form.Group md="5" as={Col}>
                <Form.Check
                  type="checkbox"
                  id="custom-checkbox2"
                  className="fs-7"
                  label="One Off Purchase?"
                  name="oneOffPurchase"
                  checked={input.oneOffPurchase}
                  onChange={handleCheckBox}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group className=" " md="5" as={Col}>
                <Form.Check
                  // className='p-0'
                  type="switch"
                  id="custom-checkbox4"
                  label="Is Product Active?"
                  name="active"
                  checked={input.active}
                  onChange={handleCheckBox}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group className=" " md="5" as={Col}>
                <Form.Check
                  // className='p-0'
                  type="switch"
                  id="custom-checkbox3"
                  label="Manage Stock?"
                  name="manageStock"
                  checked={input.manageStock}
                  onChange={handleCheckBox}
                />
              </Form.Group>
            </Row>
            {input.manageStock && (
              <Row className="mb-2">
                <Form.Group as={Col} md="5" controlId="manageStock">
                  <Form.Label className="mb-2">
                    <h6>Quantity</h6>
                  </Form.Label>
                  <Form.Control
                    required={input.manageStock}
                    type="number"
                    min={0}
                    onChange={handleInput}
                    placeholder="100"
                    value={input?.quantity}
                    name="quantity"
                  ></Form.Control>
                  {validated && (
                    <Form.Control.Feedback type="invalid">
                      {!input.quantity && !isValidInteger(input.quantity) ? (
                        "This field is Required"
                      ) : !isValidInteger(input.quantity) ? (
                        "Must be a Positive Integer"
                      ) : (
                        <></>
                      )}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
            )}
            {/* <Row className='mb-2'>
              <Form.Group md='5' controlId='Price' as={Col}>
               
              </Form.Group>
              {input.recurring ? (
                <>
                  {' '}
                  <Form.Group as={Col} md='5' controlId='Quantity'>
                    <Form.Label>
                      <h6>Quantity</h6>
                    </Form.Label>
              
                    <Form.Control.Feedback type='invalid'>
                      This field is Required*
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : (
                <></>
              )}
            </Row> */}
            <Row className="mb-4 mt-3">
              <Form.Group controlId="Account">
                <Form.Label className="mb-2">
                  <h6>Description </h6>
                </Form.Label>
                <Form.Control
                  className="viewonly"
                  as="textarea"
                  required
                  rows={3} // Set the number of rows to 3
                  placeholder="Enter Description"
                  value={input.description}
                  name="description"
                  onChange={handleInput}
                  style={{ overflowY: "scroll" }} // Add scrollbar style
                ></Form.Control>
                {validated && !input.description && (
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <hr className="mt-1" />
            {(input.benefits.meetingRoomAllowedHours.length !== 0 ||
              input?.benefits?.dayPass?.quantity > 0) && (
              <Row className="mb-3 ">
                <Form.Group controlId="dayPass">
                  <Form.Label className="mb-2">
                    <h6>Day Pass Quantity</h6>
                  </Form.Label>
                  <Form.Control
                    className="viewonly"
                    required
                    min={0}
                    type="number"
                    placeholder="25"
                    value={input.benefits?.dayPass?.quantity}
                    name="price"
                    onChange={(e) =>
                      setInput((prev) => ({
                        ...prev,
                        benefits: {
                          ...prev.benefits,
                          dayPass: {
                            ...prev.benefits?.dayPass,
                            quantity: e.target.value,
                          },
                        },
                      }))
                    }
                  ></Form.Control>

                  {validated && (
                    <Form.Control.Feedback type="invalid">
                      {!input.price ? (
                        "This field is Required"
                      ) : !isValidInteger(input.price) ? (
                        "Must be a Positive Integer"
                      ) : (
                        <></>
                      )}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
            )}
            <div className="">
              {input.benefits?.meetingRoomAllowedHours &&
              input?.benefits?.meetingRoomAllowedHours?.length > 0 ? (
                input.benefits?.meetingRoomAllowedHours?.map(
                  (benefit, index) => {
                    {
                      //.log({ mapping: input })
                    }
                    return (
                      <React.Fragment key={index}>
                        <Benefit
                          validated={validated}
                          input={input}
                          setInput={setInput}
                          key={index} // Assuming each benefit has a unique id
                          index={index} // Pass index as a separate prop
                        ></Benefit>
                        <hr className="mt-4" />
                      </React.Fragment>
                    );
                  }
                )
              ) : (
                <div></div>
              )}
            </div>
            <Row className="mb-3 mt-3 align-items-center">
              <Col
                xs="auto"
                className="my-auto"
                onClick={() => {
                  setInput((prevInput) => ({
                    ...prevInput,
                    benefits: {
                      ...prevInput.benefits,
                      meetingRoomAllowedHours: [
                        ...prevInput.benefits?.meetingRoomAllowedHours,
                        { hours: 0, meetingRoom: [] },
                      ],
                    },
                  }));
                }}
              >
                <h6 className="me-2 text text-primary cursor-pointer">
                  Associate More Benefits
                </h6>
              </Col>
            </Row>
            {result.isLoading || updated.isLoading ? (
              <>
                <Button className="btn btn-primary mr-3  ">
                  Loading...{" "}
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="primary"
                  />
                </Button>
              </>
            ) : (
              <Button
                className="btn btn-primary mr-3 "
                type="submit"
                disabled={isUploading}
              >
                {!operateId ? "Add Product" : "Update Product"}
              </Button>
            )}{" "}
            <Button className="btn btn-secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductAdd;
