import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetCompanyUsersQuery,
  useGetSpecificCompanyQuery,
  useProductListQuery,
} from "../../Slices/spaceApi";
import { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import UserForCompany from "./UserForCompany";
import Loader from "../Loader/Loader";
import { useSortableData } from "../../utils/SortingFunction";
import UpdateCompanyDetails from "./UpdateCompanyDetails";
import ViewCompany from "./ViewCompany";
import DeleteCompany from "./DeleteCompany";
import ViewComapanyUser from "./ViewComapanyUser";
import EditCompanyUser from "./EditCompanyUser";
import DeleteCompanyUser from "./DeleteCompanyUser";
import CompanyDetailsView from "./CompanyDetailsView";
import { useEffect } from "react";
import { getRandomColorDark } from "../../utils/getRandomColorDark";
import { getInitials } from "../../utils/getInitials";
import ViewCompanyEmployeeMiddlWare from "./ViewCompanyEmployeeMiddlwWare";
import PurchasesModal from "./PurchasesModal";
import ViewPurchase from "./ViewPurchase";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";

const MemberPurchase = ({ id, FetchPurchases }) => {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(true);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { isLoading: productLoading, data: productData } = useProductListQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { items, requestSort, sortConfig } = useSortableData(
    FetchPurchases || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const filteredItems = items
    // ?.slice()
    // .reverse()
    ?.filter((val) => {
      if (search == "") {
        return val;
      } else if (val.name.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
    });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <section className="app-user-list"> */}
      <div className="card">
        <div className="mt-3 ml-4">
          <h4>Purchases</h4>
        </div>
        <div className="row table-header align-items-center justify-content-between">
          <div className="col-12 col-md-9">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Here"
              className="form-control"
            />
          </div>
          <div className="col-12 col-md-3">
            <PurchasesModal
              id={id}
              FetchPackageData={productData?.products}
              isEdit={false}
            />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Name</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("userDetails:name")}
                        className={getClassNamesFor("userDetails:name")}
                        style={{ fontSize: "10px" }}
                      />
                      <Icon.ChevronDown
                        onClick={() => requestSort("userDetails:name")}
                        className={getClassNamesFor("userDetails:name")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Price</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("userDetails:email")}
                        className={getClassNamesFor("userDetails:email")}
                        style={{ fontSize: "10px" }}
                      />
                      <Icon.ChevronDown
                        onClick={() => requestSort("userDetails:email")}
                        className={getClassNamesFor("userDetails:email")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>Quantity</th>
                <th>Description</th>
                <th>Action</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>

            <tbody>
              {/* {loading ? (
                    <tr>
                      <td colSpan={3} className="fs-5">
                        Loading...
                      </td>
                    </tr>
                  ) : */}
              {filteredItems?.length > 0 ? (
                filteredItems
                  // ?.slice()
                  // .reverse()
                  ?.filter((val) => {
                    if (search === "") {
                      return val;
                    } else if (
                      val?.items.some((item) =>
                        item?.product?.name
                          .toLowerCase()
                          .includes(search.toLocaleLowerCase())
                      )
                    ) {
                      return val;
                    }
                  })
                  ?.map((item, idx) => (
                    <React.Fragment key={idx}>
                      {item?.items?.map((purchaseItem, index) => (
                        <tr key={`${idx}-${index}`}>
                          <td>{purchaseItem?.product?.name}</td>
                          <td>{purchaseItem?.product?.price}</td>
                          <td>{purchaseItem?.quantity}</td>
                          <td>{purchaseItem?.product?.description}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <ViewPurchase {...purchaseItem} {...item} />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-left fs-5">
                    No Record!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* </section> */}
    </>
  );
};

export default MemberPurchase;
