import React from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tabs, Tab, Badge, Breadcrumb } from "react-bootstrap";

import {
  useGetBookingsOfSingleMemberQuery,
  useGetMeetingRoomPackageQuery,
  useGetPackageDetailsForMemberQuery,
  useGetPackageQuery,
  useGetSingleIndividualQuery,
  useUpdateUserMutation,
} from "../../Slices/spaceApi";

import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import Loader from "../Loader/Loader";

import AddPackageForMember from "./AddPackageForMember";
import { useEffect } from "react";
import MemberPackageTable from "./MemberPackageTable";

import UpdateUser from "./UpdateUser";
import MemberInvoiceList from "./MemberInvoiceList";
import { requiredAlert } from "../../utils/Alerts";
import toast from "react-hot-toast";
import { useGlobalContext } from "../../Context/GlobalContext";
import { getRandomColorDark } from "../../utils/getRandomColorDark";
import { getInitials } from "../../utils/getInitials";
import EditCompanyUser from "./EditCompanyUser";

const DetailedCompanyEmployee = () => {
  const { id } = useParams();
  const { billingOption } = useGlobalContext();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Employee Details");
  }, []);
  const navigate = useNavigate();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: individualData, isLoading: loading } =
    useGetSingleIndividualQuery({
      spaceId: spaceId || currentSpaceId,
      memberId: id,
    });

  const { isLoading: spacePackageLoading, data: FetchPackageData } =
    useGetPackageQuery(spaceId || currentSpaceId);
  const { isLoading: meetingPackageLoading, data: FetchMeetingPackageData } =
    useGetMeetingRoomPackageQuery(spaceId || currentSpaceId);

  const {
    isLoading: assignedPackageLoading,
    data: FetchMemberAssignedPackage,
  } = useGetPackageDetailsForMemberQuery({
    spaceId: spaceId || currentSpaceId,
    memberId: id,
  });
  const { isLoading: bookingsLoading, data: FetchBookings } =
    useGetBookingsOfSingleMemberQuery({
      spaceId: spaceId || currentSpaceId,
      memberId: id,
    });
  const [updateSpaceUser, resultStats] = useUpdateUserMutation();

  const [dailyPackages, setDailyPackages] = useState([]);
  const [weeklyPackages, setWeeklyPackages] = useState([]);

  const [monthlyPackages, setMonthlyPackages] = useState([]);
  const [search, setSearch] = useState("");
  const [apiSpinner, setApiSpinner] = useState(false);

  const [hideFromPortal, setHideFromPortal] = useState(
    individualData?.hideFromPortal
  );
  const [portalAccess, setPortalAccess] = useState(
    individualData?.portalAccess
  );
  const [communityAccess, setCommunityAccess] = useState(
    individualData?.allow_community_posting
  );
  useEffect(() => {
    setPortalAccess(individualData?.portalAccess);
    setHideFromPortal(individualData?.hideFromPortal);
    setCommunityAccess(individualData?.allow_community_posting);
  }, [individualData]);
  const handleHideFromPortal = () => {
    setApiSpinner(true);
    setHideFromPortal((prevHideFromPortal) => {
      const newHideFromPortal = !prevHideFromPortal;
      updateSpaceUser({
        spaceId: spaceId || currentSpaceId,
        userId: id,
        updateData: { hideFromPortal: newHideFromPortal },
      });
      return newHideFromPortal;
    });
  };

  const handlePortalAccess = () => {
    setApiSpinner(true);
    setPortalAccess((prevPortalAccess) => {
      const newPortalAccess = !prevPortalAccess;
      updateSpaceUser({
        spaceId: spaceId || currentSpaceId,
        userId: id,
        updateData: { portalAccess: newPortalAccess },
      });
      return newPortalAccess;
    });
  };

  const handleCommunityPostingAccess = () => {
    setApiSpinner(true);
    setCommunityAccess((preCommunityAccess) => {
      const newPortalAccess = !preCommunityAccess;
      updateSpaceUser({
        spaceId: spaceId || currentSpaceId,
        userId: id,
        updateData: { allow_community_posting: newPortalAccess },
      });
      return newPortalAccess;
    });
  };

  function timeStampToTime(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);
    date = date.toLocaleTimeString();
    let split = date.split(":");
    let set = split[2].split(" ");
    let convert = split[0] + ":" + split[1] + " " + set[1];
    return convert;
  }
  function timeStampToDate(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);

    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    let formattedDate = date.toLocaleDateString(undefined, options);

    return formattedDate;
  }
  useEffect(() => {
    if (FetchMemberAssignedPackage?.assignedPackages) {
      const monthlyData = FetchMemberAssignedPackage?.assignedPackages?.filter(
        (item) => item.expiry_duration == 3
      );
      const weeklyData = FetchMemberAssignedPackage?.assignedPackages?.filter(
        (item) => item.expiry_duration == 2
      );
      const dailyData = FetchMemberAssignedPackage?.assignedPackages?.filter(
        (item) => item.expiry_duration == 1
      );
      setDailyPackages(dailyData);
      setWeeklyPackages(weeklyData);
      setMonthlyPackages(monthlyData);
    }
  }, [FetchMemberAssignedPackage]);
  const [expiry, setExpiry] = useState(
    {
      label: "Select All",
      value: 0,
    },
    {
      label: "Monthly",
      value: 1,
    },
    {
      label: "Weekly",
      value: 2,
    },
    {
      label: "Daily",
      value: 3,
    }
  );

  const rightTabs = ["Overview", "Packages", "Bookings"];

  const [activeTab, setActiveTab] = useState(0);
  const [totalRemainingHours, setTotalRemainingHours] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);

    const tabContent = document.getElementById(`tab${index + 1}`);
    if (tabContent) {
      tabContent.scrollIntoView({ behavior: "smooth" });
    }
  };
  const filteredItems = FetchBookings?.bookingss
    ?.slice()
    .reverse()
    ?.filter((val) => {
      if (search == "") {
        return val;
      } else if (
        val.meetingRoom?.title.toLowerCase().includes(search.toLowerCase())
      ) {
        return val;
      }
    });

  const [packageEventKey, setPackageEventKey] = useState("");
  const [isAnyPackage, setIsAnyPackage] = useState(false);
  useEffect(() => {
    if (
      monthlyPackages?.length > 0 ||
      weeklyPackages?.length > 0 ||
      dailyPackages?.length > 0
    ) {
      setIsAnyPackage(true);
      if (monthlyPackages?.length > 0) {
        setPackageEventKey(
          monthlyPackages?.some((item) => item.status == 1)
            ? "active"
            : monthlyPackages?.some((item) => item.status == 2)
            ? "pending"
            : "history"
        );
        return;
      } else if (weeklyPackages?.length > 0) {
        setPackageEventKey(
          weeklyPackages?.some((item) => item.status == 1)
            ? "active"
            : weeklyPackages?.some((item) => item.status == 2)
            ? "pending"
            : "history"
        );
        return;
      } else if (dailyPackages?.length > 0) {
        setPackageEventKey(
          dailyPackages?.some((item) => item.status == 1)
            ? "active"
            : dailyPackages?.some((item) => item.status == 2)
            ? "pending"
            : "history"
        );
        return;
      }
    }
  }, [monthlyPackages, weeklyPackages, dailyPackages]);

  useEffect(() => {
    const allPackages = [
      ...monthlyPackages,
      ...weeklyPackages,
      ...dailyPackages,
    ];

    const filteredPackages = allPackages.filter(
      (pkg) => pkg.status === 1 && pkg.remaining_hours.length > 0
    );

    const totalHoursSum = filteredPackages.reduce(
      (sum, pkg) => sum + pkg.remaining_hours[0].hours,
      0
    );

    setTotalRemainingHours(totalHoursSum);
  }, [monthlyPackages, weeklyPackages, dailyPackages]);

  useEffect(() => {
    if (resultStats?.error) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.message || "An Error Occured");
    }

    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Updated Successfully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  if (
    loading ||
    spacePackageLoading ||
    meetingPackageLoading ||
    assignedPackageLoading
  ) {
    return <Loader height={6.6} />;
  }
  return (
    <div className="container-fluid " style={{ padding: "0px 28px" }}>
      <Breadcrumb className="custom-breadcrumb ml-auto">
        <Breadcrumb.Item>
          <Link to="/member/list">All Members</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/company/${individualData?.companyDetail?.id}/view1`}>
            {individualData?.companyDetail?.name}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{individualData?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="card col-12 col-lg-3 small-div" style={leftDivStyle}>
          <div className="mt-2 p-3">
            <div className="d-flex justify-content-between">
              <h4>Employee</h4>
              <h5>
                {" "}
                <EditCompanyUser
                  userId={id}
                  userName={individualData?.name}
                  userEmail={individualData?.email}
                  phoneNo={individualData?.phoneNo}
                  address={individualData?.address}
                  profileImg={individualData?.profileImg}
                  cnicImage={individualData?.cnicImage}
                  cnicNo={individualData?.cnicNo}
                  experienceYears={individualData?.experienceYears || 0}
                  description={individualData?.description}
                  //   securityDeposit={individualData?.securityDeposit}
                />
              </h5>
            </div>
            {/* <img
              src={
                individualData?.profileImg
                  ? individualData.profileImg
                  : "https://placehold.jp/90x90.png"
              }
              style={{
                width: "90px",
                height: "90px",
                objectFit: "cover",
              }}
              className="rounded mt-2"
            /> */}
            <div className="text-center mt-2 d-flex justify-content-center align-items-center">
              {individualData?.profileImg ? (
                <img
                  style={{
                    borderRadius: "50%",
                    width: "90px",
                    height: "90px",
                    objectFit: "cover",
                  }}
                  src={
                    individualData?.profileImg ||
                    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                  }
                  alt={individualData?.name}
                />
              ) : (
                <div
                  className="rounded"
                  style={{
                    width: "90px",
                    height: "90px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: getRandomColorDark(),
                    color: "#fff",
                    fontSize: "28px",
                  }}
                >
                  {getInitials(individualData?.name)}
                </div>
              )}
            </div>
            <div className="media-body my-auto d-flex flex-column mt-4">
              <span className="mb-0 table-avatar">
                <b>{individualData?.name}</b>
              </span>
              <small className="mb-0 text-muted">{individualData?.email}</small>
            </div>
            <hr />
            <div className="general d-flex flex-column">
              {/* <div className="">
                <h4 className="float-left">General</h4>
                <h6 className="float-right">
                  <EditCompanyUser
                    userId={id}
                    userName={individualData?.name}
                    userEmail={individualData?.email}
                    phoneNo={individualData?.phoneNo}
                    address={individualData?.address}
                    profileImg={individualData?.profileImg}
                    cnicImage={individualData?.cnicImage}
                    cnicNo={individualData?.cnicNo}
                    experienceYears={individualData?.experienceYears}
                    description={individualData?.description}
                    // securityDeposit={individualData?.securityDeposit}
                  />
                </h6>
              </div> */}

              <div className="">
                <h6 className="float-left">Company:</h6>
                <p
                  className="float-right btn-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const url = `/company/${individualData?.companyDetail?.id}/view1`;
                    navigate(url);
                  }}
                >
                  {individualData?.companyDetail?.name}
                </p>
              </div>

              {individualData?.phoneNo && (
                <div className="">
                  <h6 className="float-left">Phone No:</h6>
                  <p className="float-right">{individualData?.phoneNo}</p>
                </div>
              )}
              {individualData?.cnicNo && (
                <div className="">
                  <h6 className="float-left">ID Card Number: </h6>
                  <p className="float-right">{individualData?.cnicNo}</p>
                </div>
              )}
              {individualData?.address && (
                <div className="">
                  <h6 className="float-left">Address: </h6>
                  <p className="float-right">{individualData?.address}</p>
                </div>
              )}

              {individualData?.experienceYears > 0 && (
                <div className="">
                  <h6 className="float-left">Experience Years:</h6>
                  <p className="float-right">
                    {individualData?.experienceYears}
                  </p>
                </div>
              )}
              {individualData?.description && (
                <div className="">
                  <h6 className="float-left">About:</h6>
                  <p className="float-right">{individualData?.description}</p>
                </div>
              )}

              <hr />
            </div>
            {/* <div className="memberApp"> */}
            <h4>Member Apps</h4>
            <div className="row">
              <div className="d-flex align-items-center justify-content-between">
                <small>HIDE FROM MEMBER PORTAL</small>
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={hideFromPortal}
                    onChange={handleHideFromPortal}
                    disabled={apiSpinner}
                  />
                </Form>
              </div>
            </div>
            {/* </div> */}
            <div className="row">
              <div className="d-flex align-items-center justify-content-between">
                <small>MEMBER PORTAL ACCESS</small>
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={portalAccess}
                    onChange={handlePortalAccess}
                    disabled={apiSpinner}
                  />
                </Form>
              </div>
            </div>

            <div className="row">
              <div className="d-flex align-items-center justify-content-between">
                <small>COMMUNITY POSTING ACCESS</small>
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    // value={portalAccess}
                    checked={communityAccess}
                    onChange={handleCommunityPostingAccess}
                    disabled={apiSpinner}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-9">
          {/* <ul className="nav nav-tabs" style={{ cursor: "pointer" }}>
            {rightTabs?.map((tab, index) => (
              <li className="nav-item" key={index}>
                <a
                  className={`nav-link ${
                    index === activeTab ? "active" : ""
                  } ml-2`}
                  onClick={() => handleTabClick(index)}
                >
                  {tab}
                </a>
              </li>
            ))}
          </ul> */}

          <div className="tab-content mt-3" style={rightDivStyle}>
            {/* <div
              className={` ${activeTab == 0 ? "active" : ""}`}
              id={`tab${1}`}
              onMouseEnter={() => setActiveTab(0)}

             
            >
              <div className="card mt-3 p-4">
                <div className="justify-content-around d-flex">
                  <div>
                    <small className="p-4">
                      {individualData?.upcommingBookingCount || 0}
                    </small>
                    <p className="mt-2">Bookings</p>
                  </div>
                  <div>
                    <small className="p-4">
                      {individualData?.activePackageCount || 0}
                    </small>
                    <p className="mt-2">Packages</p>
                  </div>
                 
                </div>
              </div>
            </div> */}

            {/* <div
              className={` ${activeTab == 1 ? "active" : ""}`}
              id={`tab${2}`}
              onMouseEnter={() => setActiveTab(1)}
            >
              <div className="card">
                <div className="mt-4 d-flex">
                  <div className="d-flex">
                    <h4 className="mt-2 ml-3">Packages</h4>
                    <AddPackageForMember
                      id={id}
                      FetchPackageData={FetchPackageData}
                      FetchMeetingPackageData={FetchMeetingPackageData}
                      memberName={individualData?.name}
                    />
                  </div>
                  <p className="float-right mr-2">
                    {billingOption == 1 ? "Hours" : "Coins"} Left{" "}
                    <Badge bg="success" className="mb-1">
                      {totalRemainingHours || 0}
                    </Badge>
                  </p>
                </div>
                {isAnyPackage ? (
                  <Tabs
                    activeKey={packageEventKey}
                    onSelect={(key) => setPackageEventKey(key)}
                    id="uncontrolled-tab-example"
                    className="mb-4 ml-3 mt-3"
                  >
                    <Tab eventKey="active" title="Active">
                      <>
                        <div className="" style={{ display: "grid" }}>
                          <h4 className="float-left ml-3">Monthly Packages:</h4>

                          {monthlyPackages?.length > 0 &&
                          monthlyPackages?.some((item) => item.status === 1) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={monthlyPackages}
                                    status={1}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={true}
                                    memberId={id}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Monthly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />

                          <h4 className="float-left ml-3">Weekly Packages:</h4>

                          {weeklyPackages?.length > 0 &&
                          weeklyPackages?.some((item) => item.status === 1) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={weeklyPackages}
                                    status={1}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={true}
                                    memberId={id}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Weekly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />
                          <h4 className="float-left ml-3">Daily Packages:</h4>

                          {dailyPackages?.length > 0 &&
                          dailyPackages?.some((item) => item.status === 1) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={dailyPackages}
                                    status={1}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={true}
                                    memberId={id}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Daily Package Assigned</p>
                          )}
                        </div>
                      </>
                    </Tab>
                    <Tab eventKey="pending" title="Pending">
                      <>
                        {" "}
                        <div className="" style={{ display: "grid" }}>
                          <h4 className="float-left ml-3">Monthly Packages:</h4>

                          {monthlyPackages?.length > 0 &&
                          monthlyPackages?.some((item) => item.status === 2) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={monthlyPackages}
                                    status={2}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={true}
                                    memberId={id}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Monthly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />

                          <h4 className="float-left ml-3">Weekly Packages:</h4>

                          {weeklyPackages?.length > 0 &&
                          weeklyPackages?.some((item) => item.status === 2) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={weeklyPackages}
                                    status={2}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={true}
                                    memberId={id}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Weekly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />
                          <h4 className="float-left ml-3">Daily Packages:</h4>

                          {dailyPackages?.length > 0 &&
                          dailyPackages?.some((item) => item.status === 2) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={dailyPackages}
                                    status={2}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={true}
                                    memberId={id}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Daily Package Assigned</p>
                          )}
                        </div>
                      </>
                    </Tab>
                    <Tab eventKey="history" title="History">
                      <>
                        {" "}
                        <div className="" style={{ display: "grid" }}>
                          <h4 className="float-left ml-3">Monthly Packages:</h4>

                          {monthlyPackages?.length > 0 &&
                          monthlyPackages?.some((item) => item.status === 3) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={monthlyPackages}
                                    status={3}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={false}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Monthly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />

                          <h4 className="float-left ml-3">Weekly Packages:</h4>

                          {weeklyPackages?.length > 0 &&
                          weeklyPackages?.some((item) => item.status === 3) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                             
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={weeklyPackages}
                                    status={3}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={false}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Weekly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />
                          <h4 className="float-left ml-3">Daily Packages:</h4>

                          {dailyPackages?.length > 0 &&
                          dailyPackages?.some((item) => item.status === 3) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={dailyPackages}
                                    status={3}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={false}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Daily Package Assigned</p>
                          )}
                        </div>
                      </>
                    </Tab>
                  </Tabs>
                ) : (
                  <div className="card" style={{ minHeight: "80px" }}>
                    <h6 className="ml-3 mt-4">No package record is found.</h6>
                  </div>
                )}
              </div>
            </div> */}

            <div
              className={` ${activeTab == 2 ? "active" : ""}`}
              id={`tab${3}`}
              onMouseEnter={() => setActiveTab(2)}
            >
              <div className="booking">
                <div className="card">
                  <div className="d-flex justify-content-between">
                    <h4 className="ml-4 mt-4">Bookings</h4>
                    <div className="col-xl-5 col-7 mt-3 ">
                      <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search Bookings"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <table className="table mt-2 mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date</th>
                          <th scope="col">Time</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!bookingsLoading && filteredItems?.length > 0 ? (
                          filteredItems
                            ?.slice()
                            .reverse()
                            ?.map((item, index) => {
                              const {
                                meetingRoomTitle = item.meetingRoom?.title,
                                price = item.meetingRoom?.price,
                                bookingCapacity = item.meetingRoom?.capacity,
                                meetingRoomImagesUrls = item.meetingRoom
                                  ?.imageUrls,
                                startTime,
                                endTime,
                                cancel = item.cancel,
                              } = item;
                              return (
                                <tr key={index}>
                                  <td>
                                    <div className="media">
                                      <div className="media-aside mr-2">
                                        <img
                                          src={meetingRoomImagesUrls}
                                          alt=""
                                          className="rounded-circle"
                                          style={{
                                            width: "32px",
                                            height: "32px",
                                          }}
                                        />
                                      </div>
                                      <div className="media-body my-auto">
                                        <span className="mb-0 table-avatar">
                                          {meetingRoomTitle}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{timeStampToDate(startTime)}</td>

                                  <td>
                                    {timeStampToTime(startTime)}-
                                    {timeStampToTime(endTime)}
                                  </td>
                                  <td>
                                    <Badge bg={!cancel ? "success" : "danger"}>
                                      {!cancel ? "Active" : "Cancelled"}
                                    </Badge>
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-left fs-5">
                              No Bookings !
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="spacing" style={{ height: "60vh" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedCompanyEmployee;
const leftDivStyle = {
  overflowY: "scroll",
  maxHeight: "calc(100vh - 90px)",
  scrollBehaviour: "smooth",
  marginBottom: "0px",
};

const rightDivStyle = {
  //   border: "1px solid #ccc",
  overflowY: "scroll",
  maxHeight: "calc(100vh - 155px)",
};
