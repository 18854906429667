import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Purchase from "./Purchase";
import Product from "./Product";
import * as Icon from "react-bootstrap-icons";
import ProductCategory from "./ProductCategory";
import { useGlobalContext } from "../../Context/GlobalContext";
const ProductShared = () => {
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Products");
  }, []);
  return (
    <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
      <Tabs
        defaultActiveKey="product"
        id="justify-tab-example"
        className=""
        variant="underline"
        justify
      >
        <Tab
          eventKey="product"
          title={
            <span className="d-flex align-items-center">
              <Icon.BoxSeamFill /> &nbsp;Product
            </span>
          }
        >
          <Product />
        </Tab>
        <Tab
          eventKey="category"
          title={
            <span className="d-flex align-items-center">
              <Icon.Tag /> &nbsp; Product Category
            </span>
          }
        >
          <ProductCategory />
        </Tab>
        <Tab
          eventKey="Purchases"
          title={
            <span className="d-flex align-items-center">
              <Icon.Basket3 /> &nbsp; Purchases
            </span>
          }
        >
          <Purchase />
        </Tab>
      </Tabs>
    </section>
  );
};

export default ProductShared;
