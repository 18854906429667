import React, { useState } from "react";

const InvoiceToDetails = ({ FetchSpaceInvoice }) => {
  return (
    <div className="invoice-to">
      <h6 className="invoice-to-title">Invoice To:</h6>
      <div className="mt-3" style={{ width: "300px" }}>
        <p className="card-text my-1 fw-bold">
          {FetchSpaceInvoice?.member?.name}
        </p>
        <p className="card-text my-1">{FetchSpaceInvoice?.member?.address}</p>
        <p className="card-text my-1">{FetchSpaceInvoice?.member?.phoneNo}</p>
        <p className="card-text my-1">{FetchSpaceInvoice?.member?.email}</p>
      </div>
    </div>
  );
};

export default InvoiceToDetails;
