import React, { useEffect } from "react";
import QuotationList from "../Components/Quotations/QuotationList";
import AddQuotation from "../Components/Quotations/AddQuotation";
import { useSelector } from "react-redux";
import {
  useGetMeetingRoomPackageQuery,
  useGetPackageQuery,
  useGetQuotationsQuery,
  useGetSpaceDataQuery,
  useProductListQuery,
} from "../Slices/spaceApi";
import Loader from "../Components/Loader/Loader";
import { useGetCountiresQuery } from "../Slices/constantApi";
import { useGlobalContext } from "../Context/GlobalContext";

export const AddQuotations = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Add Quotation");
  }, []);
  const { data: fetchPackages, isLoading } = useGetPackageQuery(
    spaceId || currentSpaceId
  );

  const { data: fetchMeetingPackages } = useGetMeetingRoomPackageQuery(
    spaceId || currentSpaceId
  );
  const { isLoading: productLoading, data: productData } = useProductListQuery({
    spaceId: spaceId || currentSpaceId,
  });
  // const { data: cities, isLoading: loadingCities } = useGetCitiesQuery();
  const { data: countries, isLoading: loadingCountries } =
    useGetCountiresQuery();

  const { data: spaceData, isLoading: spaceLoading } = useGetSpaceDataQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: quotationData, isLoading: loading } = useGetQuotationsQuery({
    spaceId: spaceId || currentSpaceId,
    customer_name: "",
    page_size: 10,
    page: 1,
    isCompany: null,
  });

  if (
    isLoading ||
    loadingCountries ||
    spaceLoading ||
    loading ||
    productLoading
  ) {
    return <Loader height={6.6} />;
  }
  return (
    <>
      <div className="reports">
        <AddQuotation
          fetchPackages={fetchPackages}
          fetchMeetingPackages={fetchMeetingPackages}
          // cities={cities}
          countries={countries}
          spaceData={spaceData}
          quotationId={quotationData?.quotations[0]?.id + 1}
          productData={productData?.products}
          // currentSpaceId={ currentSpaceId }
        />
      </div>
    </>
  );
};
export const QuotationLists = () => {
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Quotations List");
  }, []);
  return (
    <>
      <div className="reports">
        <QuotationList />
      </div>
    </>
  );
};
