import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import ListVisitors from "../Components/Visitors/ListVisitors";

import { useGetVisitorsWithoutQueryQuery } from "../Slices/spaceApi";
import Loader from "../Components/Loader/Loader";
import AddVisit from "../Components/Visitors/AddVisit";
import { useGlobalContext } from "../Context/GlobalContext";

export const VisitorsRegistration = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: visitorData, isLoading: loading } =
    useGetVisitorsWithoutQueryQuery({
      spaceId: spaceId || currentSpaceId,
    });
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Add Visit");
  }, []);
  if (loading) {
    return <Loader height={6.6} />;
  }
  return (
    <>
      <div className="reports">
        <AddVisit visitorData={visitorData} />
      </div>
    </>
  );
};
export const VisitorsList = () => {
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Visits List");
  }, []);
  return (
    <>
      <div className="reports">
        <ListVisitors />
      </div>
    </>
  );
};
