export const AuthReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN": {
            return {
                currentUserCookie: action.payload,
            };
        }
        case "LOGIN2": {
            return {
                currentUserSessionStorage: action.payload,
            };
        }
        case "LOGIN3": {
            return {
                currentSessionCookie: action.payload,
            };
        }
        case "LOGOUT": {
            return {
                currentUserSessionStorage: action.payload,
            };
        }

        default:
            return state;
    }
}