import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetSpaceDataQuery } from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import { Navigate, useNavigate } from "react-router-dom";

function NewUserCheck() {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: updatedFacilities, isLoading: loadingUpdatedFacilities } =
    useGetSpaceDataQuery({ spaceId: spaceId || currentSpaceId });

  const navigate = useNavigate();

  const newUserCheck = () => {
    if (updatedFacilities?.facilities?.length > 0) {
      navigate("/");
    } else {
      navigate("/setting/accountsetting");
    }
  };

  useEffect(() => {
    if (!loadingUpdatedFacilities) {
      newUserCheck();
    }
  }, [loadingUpdatedFacilities, newUserCheck]);

  if (loadingUpdatedFacilities) {
    return <Loader height={6.6} />;
  } else {
    return null;
  }
}

export default NewUserCheck;
