const moment = require("moment-timezone");

export function UtcToLocalDateTime(utcDateTimeString) {
  // console.log("Utc string is ", utcDateTimeString);
  // Assuming the user's time zone is detected automatically
  const userTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  // Create a moment object with the UTC date string
  const utcDateTime = moment?.utc(utcDateTimeString);

  // Convert to the user's local time zone
  const localDateTime = utcDateTime?.clone()?.tz(userTimeZone);

  return localDateTime?.format(); // You can adjust the format as needed
}
