import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "flatpickr/dist/themes/material_green.css";
import "bootstrap/dist/css/bootstrap.css";
import { AppProvider } from "./Context/GlobalContext";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./AuthContext/AuthContext";
import { Provider } from "react-redux";
import store from "./App/store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <Provider store={store}>
        <AppProvider>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </AppProvider>
      </Provider>
    </AuthProvider>
  </BrowserRouter>
);
