import React, { useEffect, useState } from "react";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  useCreateTourQuestionMutation,
  useDeleteTourQuestionMutation,
  useGetTourQuestionsQuery,
  useUpdateTourQuestionMutation,
} from "../../Slices/spaceApi";
import * as Icon from "react-bootstrap-icons";
import { confirmationAlert, requiredAlert } from "../../utils/Alerts";
import toast from "react-hot-toast";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";

import { useGlobalContext } from "../../Context/GlobalContext";
const QuestionCard = ({ question, onUpdate, onClose }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [deleteQuestion, resultStats] = useDeleteTourQuestionMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState(question.question);
  const [editedIsRequired, setEditedIsRequired] = useState(question.isRequired);
  const [editedQuestionType, setEditedQuestionType] = useState(
    question.questionType
  );

  const handleUpdate = () => {
    onUpdate(question.id, {
      question: editedQuestion,
      isRequired: editedIsRequired,
      questionType: editedQuestionType,
    });
    setIsEditing(false);
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      toast.success("Question Deleted Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (resultStats?.isError) {
      const errorMessage = resultStats?.error?.data?.message;
      requiredAlert(errorMessage || "An Error Occured");
    }
  }, [resultStats]);

  return (
    <div className={`card mb-3 p-3 ${isEditing ? "border-primary" : ""}`}>
      {isEditing ? (
        <div>
          <div className="mb-2">
            <textarea
              value={editedQuestion}
              onChange={(e) => setEditedQuestion(e.target.value)}
              className="form-control"
              placeholder="Hello?"
              rows={4}
            />
          </div>

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <label>Question Type</label>
              <Select
                options={[
                  { label: "Text", value: 1 },
                  { label: "Numeric", value: 2 },
                ]}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={(selectedOption) =>
                  setEditedQuestionType(selectedOption.value)
                }
                value={{
                  label: editedQuestionType === 1 ? "Text" : "Numeric",
                  value: editedQuestionType,
                }}
                id="questionType-select"
              />
            </div>

            <div style={{ marginLeft: "auto" }}>
              <input
                type="checkbox"
                id="isRequiredCheckbox"
                checked={editedIsRequired}
                onChange={(e) => setEditedIsRequired(e.target.checked)}
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: editedIsRequired ? "green" : "transparent",
                  border: "1px solid #000",
                }}
              />
              <label htmlFor="isRequiredCheckbox" className="ml-2">
                Is Required
              </label>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={handleUpdate}
            >
              Update
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setIsEditing(false)}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-between"
          onClick={() => setIsEditing(true)}
          style={{ cursor: "pointer" }}
        >
          <div>
            <h5>{question.question}</h5>
            <p>
              Question Type: {question.questionType === 1 ? "Text" : "Numeric"}
            </p>
            <p>Is Required: {question.isRequired.toString()}</p>
          </div>
          <div className="d-flex">
            <div className="mr-1" title="Edit">
              <EditIcon />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();

                confirmationAlert("Do You Want to Delete Question?").then(
                  (willDelete) => {
                    if (willDelete) {
                      deleteQuestion({
                        spaceId: spaceId || currentSpaceId,
                        id: question?.id,
                      });
                    }
                  }
                );
              }}
              className="ml-1"
              title="delete"
            >
              <DeleteIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const QuestionsList = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Tour Questions");
  }, []);
  const { data: questionsData, isLoading: questionsLoading } =
    useGetTourQuestionsQuery({ spaceId: spaceId || currentSpaceId });
  const [createQuestion, resultStats] = useCreateTourQuestionMutation();
  const [updateQuestion, resultStats1] = useUpdateTourQuestionMutation();

  const [postQuestion, setPostQuestion] = useState({
    question: "",
    questionType: 1,
    isRequired: true,
  });

  const options = [
    {
      label: "Text",
      value: 1,
    },
    {
      label: "Numeric",
      value: 2,
    },
  ];
  const handleTourQuestion = (e) => {
    e.preventDefault();
    createQuestion({
      spaceId: spaceId || currentSpaceId,
      data: postQuestion,
    });
  };

  const handleUpdateQuestion = (questionId, updatedQuestion) => {
    updateQuestion({
      spaceId: spaceId || currentSpaceId,
      id: questionId,
      data: updatedQuestion,
    });
  };

  const handleCloseQuestion = (questionId) => {
    // Implement your logic to close the question
    // console.log(`Close question with id ${questionId}`);
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      setPostQuestion({
        question: "",
        questionType: 1,
        isRequired: true,
      });
      toast.success("Question Added Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (resultStats?.isError) {
      const errorMessage = resultStats?.error?.data?.message;
      requiredAlert(errorMessage || "An Error Occured");
    }
  }, [resultStats]);
  useEffect(() => {
    if (resultStats1?.isSuccess) {
      setPostQuestion({
        question: "",
        questionType: 1,
        isRequired: true,
      });
      toast.success("Question Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (resultStats1?.isError) {
      const errorMessage = resultStats1?.error?.data?.message;
      requiredAlert(errorMessage || "An Error Occured");
    }
  }, [resultStats1]);

  return (
    <div className="container-fluid" style={{ padding: "0px 16px" }}>
      <div className="row">
        <div className="col-12">
          <div className="card p-3">
            <h3 className="col-12">
              Write a custom tour question.{" "}
              <span className="optional">
                (These questions will be part of your tour registration form)
              </span>
            </h3>

            <div className="col-12 mb-2">
              <textarea
                value={postQuestion?.question}
                onChange={(e) =>
                  setPostQuestion({ ...postQuestion, question: e.target.value })
                }
                className="form-control"
                placeholder="Hello?"
                rows={4}
              />
            </div>

            <div
              className="col-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="">
                <label className="mr-1 align-self-center">Question Type</label>
                <Select
                  options={options}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(selectedOption) =>
                    setPostQuestion({
                      ...postQuestion,
                      questionType: selectedOption.value,
                    })
                  }
                  value={options.find(
                    (option) => option.value === postQuestion.questionType
                  )}
                  id="pages-select"
                  styles={colorStyles}
                />
              </div>

              <div style={{ marginLeft: "auto", display: "inherit" }}>
                <input
                  type="checkbox"
                  id="isRequiredCheckbox"
                  checked={postQuestion.isRequired}
                  onChange={(e) =>
                    setPostQuestion({
                      ...postQuestion,
                      isRequired: e.target.checked,
                    })
                  }
                  style={{
                    width: "20px",
                    height: "20px",
                    // backgroundColor: postQuestion.isRequired
                    //   ? "green"
                    //   : "transparent",
                    // border: "1px solid #000",
                  }}
                />
                <label htmlFor="isRequiredCheckbox" className="ml-2 ">
                  Is Required
                </label>
              </div>
            </div>

            <div className="col-12 d-flex align-items-center justify-content-end ">
              <button
                type="button"
                className="btn btn-primary"
                disabled={postQuestion?.question?.length <= 0}
                // onClick={communityPermissions[0]?.create ? handlePost : ""}
                onClick={handleTourQuestion}
              >
                <Plus />
                Add Question
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {questionsData?.tourQuestions
          ?.slice()
          ?.reverse()
          ?.map((question) => (
            <QuestionCard
              key={question.id}
              question={question}
              onUpdate={handleUpdateQuestion}
              onClose={handleCloseQuestion}
            />
          ))}
      </div>
    </div>
  );
};

export default QuestionsList;
