import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useSelector } from "react-redux";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import Select from "react-select";
import { colorStyles } from "../../utils/constant";
import { useGetSkillsQuery } from "../../Slices/constantApi";
import {
  useUpdateCompanyMutation,
  useUpdateUserMutation,
} from "../../Slices/spaceApi";
import { toast } from "react-hot-toast";
import { useGlobalContext } from "../../Context/GlobalContext";
const UserForCompany = ({ data, isCompany }) => {
  // const { data: skills } = useGetSkillsQuery();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateSpaceCompany, resultStats] = useUpdateCompanyMutation();
  const [updateSpaceUser, resultStats1] = useUpdateUserMutation();
  const { currency } = useGlobalContext();
  const [apiSpinner, setApiSpinner] = useState(false);
  const [deposit, setDeposit] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDeposit("");
  };
  const handleShow = () => setShow(true);
  const [error, setError] = useState(false);
  const submitForm = (e) => {
    e.preventDefault();
    if (deposit > 0) {
      if (isCompany) {
        setApiSpinner(true);

        updateSpaceCompany({
          spaceId: spaceId || currentSpaceId,
          companyId: data?.id,
          updateData: {
            securityDeposit: deposit,
          },
        });
      } else {
        setApiSpinner(true);
        updateSpaceUser({
          spaceId: spaceId || currentSpaceId,
          userId: data.id,
          updateData: {
            securityDeposit: deposit,
          },
        });
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (resultStats?.error) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.message);
    }

    if (resultStats?.isSuccess) {
      setApiSpinner(false);
      toast.success("Deposit Added Successfully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      handleClose();
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats1?.error) {
      setApiSpinner(false);

      requiredAlert(resultStats1?.error?.data?.message);
    }

    if (resultStats1?.isSuccess) {
      setTimeout(() => {
        handleClose();
      }, 1500);
      setApiSpinner(false);

      toast.success("Deposit Added SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats1]);

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="send-invoice-btn "
      >
        + Add Deposit
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        // className={previousForm ? null : "largeForm"}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">
            Add Deposit for <b>{data.name}</b>
          </Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="media justify-content-center">
              <div className="media-aside">
                <img
                  src={
                    data?.profileImg ||
                    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                  }
                  alt=""
                  className="rounded-circle"
                  style={{ width: "20rem", height: "20rem" }}
                />
              </div>
            </div>

            {data.name && (
              <div className="row mt-4">
                <div className="col-md-12 form-group">
                  <h5>
                    <b>Name:</b> <span className="text-muted">{data.name}</span>
                  </h5>
                </div>
              </div>
            )}
            {data.email && (
              <div className="row">
                <div className="col-md-12 form-group">
                  <h5>
                    <b> Email:</b>{" "}
                    <span className="text-muted">{data.email}</span>
                  </h5>
                </div>
              </div>
            )}
            {data.phoneNo && (
              <div className="row">
                <div className="col-md-12 form-group">
                  <h5>
                    <b> Phone No:</b>{" "}
                    <span className="text-muted">{data.phoneNo}</span>
                  </h5>
                </div>
              </div>
            )}
            {data.address && (
              <div className="row">
                <div className="col-md-12 form-group">
                  <h5>
                    <b> Address:</b>{" "}
                    <span className="text-muted">{data.address}</span>
                  </h5>
                </div>
              </div>
            )}

            <div className="form-group mt-3">
              <label htmlFor="phoneNo">Security Deposit</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">{currency}</span>
                </div>
                <input
                  type="number"
                  name="securityDeposit"
                  placeholder="Enter Amount"
                  id="securityDeposit"
                  value={deposit}
                  min={0}
                  className="form-control"
                  onChange={(e) => {
                    setDeposit(e.target.value);
                    setError(false);
                  }}
                />
                {error && (
                  <small className="text-danger">
                    Please add Security Deposit(Value must be greater than 0)
                  </small>
                )}
              </div>
            </div>
            <div>
              <Button
                variant="primary"
                onClick={submitForm}
                disabled={apiSpinner}
              >
                Add Deposit{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <Button
                variant="secondary"
                disabled={apiSpinner}
                onClick={handleClose}
                className="ml-2"
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
};

export default UserForCompany;
