import React from "react";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";
import { Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

const ViewPurchase = ({ product, quantity, dayPassCount }) => {
  const { membersPermissions } = useGlobalContext();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {membersPermissions[0]?.read ? (
              "View Purchase"
            ) : (
              <span>
                View <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          id="viewMeetingOpen"
          onClick={membersPermissions[0]?.read ? handleShow : null}
        >
          <Preview />
        </Button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="row">
            
          </div> */}
          {product?.name && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Name: <span className="text-muted">{product?.name}</span>
                </h5>
              </div>
            </div>
          )}
          {product?.description && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Description:{" "}
                  <span className="text-muted">{product?.description}</span>
                </h5>
              </div>
            </div>
          )}
          {product?.price && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Price: <span className="text-muted">{product?.price}</span>
                </h5>
              </div>
            </div>
          )}
          {quantity && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Quantity: <span className="text-muted">{quantity}</span>
                </h5>
              </div>
            </div>
          )}
          {dayPassCount && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Day Pass Count:{" "}
                  <span className="text-muted">{dayPassCount}</span>
                </h5>
              </div>
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ViewPurchase;
