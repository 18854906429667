import React from "react";

const ProductCalculations = ({
  input,
  isOverAllTax,
  serviceCharges,
  rentalFee,
}) => {
  console.log("Input is", input);

  return (
    <div>
      {input?.taxRateObj && !input?.taxRateObj?.invalid ? (
        <div>
          {input?.taxRateObj?.setOveralTax ? (
            <div className="d-flex justify-content-between">
              <p>Over-All Tax: {input?.taxRateObj?.serviceCharges} %</p>

              <p>
                Total Price:{" "}
                {
                  // parseInt(input?.quantity) *
                  (
                    parseInt(input?.price) +
                    parseInt(input?.price) *
                      (input?.taxRateObj?.serviceCharges / 100) +
                    parseInt(input?.price) *
                      (input?.taxRateObj?.serviceCharges / 100)
                  )?.toFixed(2)
                }
              </p>
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <p>Service Tax: {input?.taxRateObj?.serviceCharges} %</p>
              <p>Rental Tax: {input?.taxRateObj?.rentalFee} %</p>
              <p>
                Total Price:{" "}
                {
                  // parseInt(input?.quantity) *
                  (
                    parseInt(input?.price) +
                    parseInt(input?.price) *
                      (input?.taxRateObj?.serviceCharges / 100) +
                    parseInt(input?.price) *
                      (input?.taxRateObj?.rentalFee / 100)
                  )?.toFixed(2)
                }
              </p>
            </div>
          )}
        </div>
      ) : (
        <div>
          {isOverAllTax ? (
            <div className="d-flex justify-content-between">
              <p>Over-All Tax: {serviceCharges} %</p>

              <p>
                Total Price:{" "}
                {
                  // parseInt(input?.quantity) *
                  parseInt(input?.price) +
                    parseInt(input?.price) * (serviceCharges / 100)
                }
              </p>
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <p>Service Tax: {serviceCharges} %</p>
              <p>Rental Tax: {rentalFee} %</p>
              <p>
                Total Price:{" "}
                {
                  // parseInt(input?.quantity) *
                  parseInt(input?.price) +
                    parseInt(input?.price) * (serviceCharges / 100) +
                    parseInt(input?.price) * (rentalFee / 100)
                }
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductCalculations;
