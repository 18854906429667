import React, { useContext, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  useDurationQuery,
  useExpenseCategoryGetQuery,
  useExpenseCategoryListQuery,
  useExpenseGetQuery,
} from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import { Download } from "react-bootstrap-icons";

// import ExpenseContext from "../../context/ExpenseContext";

const ExpenseView = ({ handleClose, operateId, data }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  // const {react-select/dist/declarations/src/utils
  //   data,
  //   isLoading,
  //   refetch: refetch1
  // } = useExpenseGetQuery({
  //   spaceId:spaceId|| currentSpaceId,
  //   id: operateId
  // })

  // const { data: durationData, isLoading: durationIsLoading } =
  //   useDurationQuery()
  // const {
  //   data: categoryData,
  //   isLoading: categoryIsLoading,
  //   refetch
  // } = useExpenseCategoryGetQuery({
  //   spaceId:spaceId|| currentSpaceId,
  //   id: data?.category
  // })
  // const { categoryList: categoryData, duration: durationData } =
  //   useContext(ExpenseContext);
  const { data: categoryData } = useExpenseCategoryListQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: durationData } = useDurationQuery();
  const download = () => {
    const link = document.createElement("a");
    link.href = data?.receipt || "https://placehold.jp/90x90.png"; // URL of the image to download
    link.download = "receipt_image.png"; // Set the downloaded file's name
    link.click();
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="w-10 "
      >
        <Modal.Header closeButton className="">
          <Modal.Title>
            <h5>View Expense</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data ? (
            <>
              <Row className="mb-3">
                <Form.Group controlId="file">
                  <Form.Label>
                    <h6>{operateId ? `Recipt ` : "Recipt"}</h6>{" "}
                  </Form.Label>

                  <div className="media">
                    <div className="media-aside align-self-start">
                      <img
                        src={data?.receipt || "https://placehold.jp/90x90.png"}
                        className="rounded"
                        style={{
                          width: "90px",
                          height: "90px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    {data?.receipt && (
                      <div
                        className="media-body mt-2"
                        style={{ cursor: "pointer" }}
                      >
                        <Download onClick={download}></Download>
                      </div>
                    )}
                  </div>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Col md="">
                  <h6 className="d-inline me-2">Expense Name:</h6>
                  <p className="d-inline">{data?.name}</p>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md="">
                  <h6 className="d-inline me-2">Category:</h6>
                  <p className="d-inline">
                    {data?.category && categoryData?.categories
                      ? (
                          categoryData?.categories?.find(
                            (item) => item.id === data?.category
                          ) || { name: "-" }
                        ).name
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md="">
                  <h6 className="d-inline me-2">Expense Date:</h6>
                  <p className="d-inline">{data?.date}</p>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md="">
                  <h6 className="d-inline me-2">Amount:</h6>
                  <p className="d-inline">{data?.amount}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md="">
                  <h6 className="d-inline me-2">Recurring Duration:</h6>
                  <p className="d-inline">
                    {durationData && data?.recurringPeriod
                      ? durationData?.find(
                          (item) => item.id === data?.recurringPeriod
                        )?.name || ""
                      : ""}
                  </p>
                </Col>
              </Row>

              <Row className="mb-3 mt-3">
                <Form.Group controlId="Account">
                  <Form.Label>
                    <h6>Description</h6>
                  </Form.Label>

                  <p>{data?.description}</p>
                </Form.Group>
              </Row>
            </>
          ) : (
            <Loader />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpenseView;
