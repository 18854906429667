import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
  useVendorCategoryGetQuery,
  useVendorCategoryListQuery,
  useVendorCreateMutation,
  useVendorUpdateMutation,
} from "../../Slices/spaceApi";
import Select from "react-select";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import VendorContext from "../../Context/VendorContext";
import { useSelector } from "react-redux";
import {
  emailMaxLength,
  isFirstCharacterDigit,
  isValidAccountNumber,
  isValidEmail,
  isValidInteger,
  validPhoneNumber,
} from "../../utils/validation";

const VendorAdd = ({ handleClose, operateId, operateObject }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  // const { categoryList: data } = useContext(VendorContext);

  const { data, isLoading, isError, error, refetch } =
    useVendorCategoryListQuery({
      spaceId: spaceId || currentSpaceId,
      // page_size: pageSize,
      // page: pageNumber,
      // name: searchValue,
    });
  const [updateVendor, updated] = useVendorUpdateMutation();
  const [category, setCategory] = useState([]);
  //.log({ category: data })
  //.log({ data: data })
  const [input, setInput] = useState({
    contact_person_name: "", //
    email: "", //
    phone_number: "", //
    physical_address: "", //
    vendor_name: "", //
    vendor_type: 1,
    tax_identification_number: "", //
    payment_terms: "",
    bank_name: "", //
    account_number: "", //
    routing_number: "", //
    vendor_account_number: "", //
    business_registration_number: "", //
    date_of_registration: "", //
    legal_business_name: "", //
    preferred_communication_method: "Email",
    contract_information: "",
    insurance_information: "",
    notes_comments: "",
    payment_method_preferences: 1,
    performance_metrics: "",
    terms_and_conditions: "",
    currency: 1,
    vendor_category: null,
    space: 1,
  });
  const [step, setStep] = useState(1);
  useEffect(() => {
    const updatedArray = data?.categories?.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    setCategory(updatedArray);
  }, [data]);
  useEffect(() => {
    setInput((prevInput) => ({
      ...prevInput,
      date_of_registration: new Date().toISOString().split("T")[0],
    }));
  }, []);
  useEffect(() => {
    if (operateId) {
      setInput(operateObject);
    }
  }, [operateId]);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [addVendor, result] = useVendorCreateMutation();
  const handleInput = (e) => {
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: e.target.value,
    }));
  };
  const [isValidCategory, setIsValidCategory] = useState(true);
  const handleCategoryChange = (newValue, actionMeta) => {
    setInput((prevInput) => ({
      ...prevInput,
      vendor_category: newValue.value || null,
    }));
    if (actionMeta.action === "select-option" && newValue && newValue.value) {
      setIsValidCategory(true);
    } else {
      setIsValidCategory(false);
    }
  };
  // const handleCategoryChange = selectedOption => {
  //   setInput(prevInput => ({
  //     ...prevInput,
  //     vendor_category: selectedOption.value || null
  //   }))
  //   //.log(input)
  // }
  useEffect(() => {
    if (result.isSuccess || updated.isSuccess) {
      handleClose();
      !operateId
        ? toast.success("Vendor Added Successfully", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          })
        : toast.success("Vendor Updated Successfully", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
    } else if (result.isError || updated.isError) {
      const errorMessages = Object.values(data).flat();
      Swal.fire({
        icon: "error",
        title: "Warning",
        text: errorMessages
          .map((message) => `<p>There was a problem Submitting the Form</p>`)
          .join(""),
      });
    }
  }, [result, updated]);

  useEffect(() => {
    if (result.isError) {
      const firstError = result.error.data.error;
      Swal.fire({
        title: "Required!",
        icon: "warning",
        html: firstError,
        confirmButtonText: "OK",
      });
    }
    if (updated.isError) {
      const firstError = updated.error.data.error;
      Swal.fire({
        title: "Required!",
        icon: "warning",
        html: firstError,
        confirmButtonText: "OK",
      });
    }
  }, [result, updated]);
  const handleAdd = (event) => {
    setIsSubmit(true);
    const form = event.currentTarget;
    //.log(form.checkValidity())
    event.preventDefault();

    if (form.checkValidity() === false || !input.vendor_category) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (step === 4) {
        if (!operateId) {
          addVendor({
            spaceId: spaceId || currentSpaceId,
            input,
          });
        } else {
          updateVendor({
            spaceId: spaceId || currentSpaceId,
            id: operateId,
            input,
          });
        }
      } else {
        setStep((prevStep) => {
          setValidated(false);
          return prevStep + 1;
        });
      }
    }
  };

  const handlePrev = () => {
    setStep((prevState) => {
      if (prevState === 2) return 1;
      return prevState - 1;
    });
  };
  useEffect(() => {}, [step]);

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="w-10"
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">
            {!operateId ? "Add Vendor" : "Update Vendor"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAdd}>
            {step === 1 ? (
              <>
                <h5>Personal Information</h5>
                <Row className="mb-3">
                  <Form.Group as={Col} md="5" controlId="vendor_name">
                    <Form.Label className="mb-2">Vendor Name</Form.Label>
                    <Form.Control
                      required
                      pattern="[A-Za-z].*"
                      type="text"
                      placeholder="John Doe"
                      value={input.vendor_name}
                      name="vendor_name"
                      onChange={handleInput}
                      checked={false}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.vendor_name ? (
                          "This field is Required"
                        ) : isFirstCharacterDigit(input.vendor_name) ? (
                          "Must start with an alphabet"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="5" controlId="contact_person_name">
                    <Form.Label className="mb-2">
                      Contact Person Name
                    </Form.Label>
                    <Form.Control
                      required
                      pattern="[A-Za-z].*"
                      type="text"
                      placeholder="John Doe "
                      value={input.contact_person_name}
                      name="contact_person_name"
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.contact_person_name ? (
                          "This field is Required"
                        ) : isFirstCharacterDigit(input.contact_person_name) ? (
                          "Must start with an alphabet"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="email" as={Col}>
                    <Form.Label className="mb-2">Email</Form.Label>
                    <Form.Control
                      required
                      maxLength={50}
                      type="email"
                      placeholder="abc@gmail.com"
                      value={input.email}
                      name="email"
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.email ? (
                          "This field is Required"
                        ) : !isValidEmail(input.email) ? (
                          "Give a Valid Email!"
                        ) : !emailMaxLength(input.email) ? (
                          "Must be Less than 50 Characters"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="email" as={Col}>
                    <Form.Label className="mb-2">Phone Number</Form.Label>
                    <Form.Control
                      required
                      type="tel"
                      pattern="(\+?\d{11,15})"
                      placeholder="Enter 11 - 15 Digit Phone Number"
                      value={input.phone_number}
                      name="phone_number"
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.phone_number ? (
                          "This field is Required"
                        ) : !validPhoneNumber(input.phone_number) ? (
                          "Must be 11 Digits Number!"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="physical_address" as={Col}>
                    <Form.Label className="mb-2">Physical Address </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ABC Street, XYZ Country"
                      //=''
                      required
                      value={input.physical_address}
                      name="physical_address"
                      onChange={handleInput}
                    />
                    {validated && !input.physical_address && (
                      <Form.Control.Feedback type="invalid">
                        This field is Required
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group md="5" controlId="category" as={Col}>
                    <Form.Label className="mb-2">Category </Form.Label>

                    <Select
                      onChange={handleCategoryChange}
                      // style={{
                      //   WebkitAppearance: 'menulist',
                      //   MozAppearance: 'menulist',
                      //   appearance: 'menulist'
                      //   // Add any other custom styles you need here
                      // }}
                      className={
                        validated && !input?.vendor_category
                          ? "border border-danger rounded "
                          : ""
                      }
                      placeholder={
                        input.vendor_category
                          ? category?.find(
                              (item) => item.value === input.vendor_category
                            )?.label || "Add Category"
                          : "Add Category"
                      }
                      options={category}
                    />
                    {!input.vendor_category && validated && (
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>

                <Button type="submit" className="btn btn-primary">
                  Next
                </Button>
                <Button
                  className="btn btn-secondary  ml-3"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </>
            ) : step === 2 ? (
              <>
                <h5>Financial Information </h5>
                <Row className="mb-2">
                  <Form.Group
                    as={Col}
                    md="5"
                    controlId="tax_identification_number"
                  >
                    <Form.Label className="mb-2">
                      Tax Identification{" "}
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      pattern="[0-9]*"
                      placeholder="Enter Tax Identification Number"
                      //=''
                      value={input.tax_identification_number}
                      name="tax_identification_number"
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.tax_identification_number
                          ? "This field is Required"
                          : !isValidInteger(input.tax_identification_number)
                          ? "Not Valid Tax Number"
                          : ""}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="5" controlId="contact_person_name">
                    <Form.Label className="mb-2">Account Number</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      pattern="[0-9]{8,24}"
                      placeholder="Enter 8 - 24 Digit Account Number"
                      //=''
                      value={input.account_number}
                      name="account_number"
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.account_number
                          ? "This field is Required"
                          : !isValidAccountNumber(input.account_number)
                          ? "Not Valid: Enter 8 - 24 Digit Account"
                          : ""}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group
                    as={Col}
                    md="5"
                    controlId="business_registration_number"
                  >
                    <Form.Label className="mb-2">
                      Business Registration{" "}
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      pattern="[0-9]*"
                      placeholder="Enter Registeration Number"
                      //=''
                      value={input.business_registration_number}
                      name="business_registration_number"
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.business_registration_number
                          ? "This field is Required"
                          : !isValidInteger(input.business_registration_number)
                          ? "Not Valid Registeration"
                          : ""}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="5" controlId="date_of_registration">
                    <Form.Label className="mb-2">Registration Date </Form.Label>
                    <Form.Control
                      required
                      type="date"
                      placeholder="Date to Register"
                      //=''
                      value={input.date_of_registration}
                      name="date_of_registration"
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.date_of_registration ? (
                          "This field is Required"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} md="5" controlId="routing_number">
                    <Form.Label className="mb-2">Routing Number</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      pattern="[0-9]*"
                      placeholder="Enter Routing Number"
                      //=''
                      value={input.routing_number}
                      name="routing_number"
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.routing_number
                          ? "This field is Required"
                          : !isValidInteger(input.routing_number)
                          ? "Not Valid Routing Number"
                          : ""}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="5" controlId="vendor_account_number">
                    <Form.Label className="mb-2">Vendor Account</Form.Label>
                    <Form.Control
                      required
                      // min={0}
                      type="text"
                      pattern="[0-9]{8,24}"
                      placeholder="Enter 8 - 24 Digit Account Number"
                      //=''
                      value={input?.vendor_account_number}
                      name="vendor_account_number"
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.vendor_account_number
                          ? "This field is Required"
                          : !isValidAccountNumber(input?.vendor_account_number)
                          ? "Not Valid: Enter 8 - 24 Digit Account"
                          : ""}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="bank_name" as={Col}>
                    <Form.Label className="mb-2">Bank Name</Form.Label>
                    <Form.Control
                      required
                      pattern="[A-Za-z].*"
                      type="text"
                      placeholder="XYZ Bank"
                      //=''
                      value={input.bank_name}
                      name="bank_name"
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.bank_name ? (
                          "This field is Required"
                        ) : isFirstCharacterDigit(input.bank_name) ? (
                          "Must start with an alphabet"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="legal_business_name" as={Col}>
                    <Form.Label className="mb-2">Business Name</Form.Label>
                    <Form.Control
                      required
                      pattern="[A-Za-z].*"
                      type="text"
                      placeholder="XYZ Corporation"
                      //=''
                      value={input.legal_business_name}
                      name="legal_business_name"
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.legal_business_name ? (
                          "This field is Required"
                        ) : isFirstCharacterDigit(input.legal_business_name) ? (
                          "Must start with an alphabet"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="payment_terms" as={Col}>
                    <Form.Label className="mb-2">Payment Terms</Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      //
                      className="form-control dt-uname textarea"
                      placeholder="Enter Payment Terms"
                      name="payment_terms"
                      value={input.payment_terms}
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.payment_terms ? (
                          "This field is Required"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <div onClick={handlePrev} className="btn btn-primary">
                  Prev
                </div>

                <Button
                  className="btn btn-secondary ml-3 mr-3"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button type="submit">Next</Button>
              </>
            ) : step === 3 ? (
              <>
                {" "}
                <h5>Additional Information</h5>
                <Row className="mb-3">
                  <Form.Group
                    controlId="preferred_communication_method"
                    as={Col}
                  >
                    <Form.Label className="mb-2">
                      Preferred Communication Method
                    </Form.Label>
                    <Form.Select
                      name="preferred_communication_method"
                      value={input.preferred_communication_method}
                      //={'Email'}
                      onChange={handleInput}
                    >
                      <option value="Email">Email</option>
                      <option value="Phone">Phone</option>
                      <option value="Fax">Fax</option>
                      <option value="In-Person Meetings">
                        In-Person Meetings
                      </option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="contract_information" as={Col}>
                    <Form.Label className="mb-2">
                      Contract Information
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      className="form-control dt-uname textarea"
                      placeholder="Enter Contract Information"
                      name="contract_information"
                      value={input.contract_information}
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.contract_information ? (
                          "This field is Required"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="insurance_information" as={Col}>
                    <Form.Label className="mb-2">
                      Insurance Information
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      //
                      className="form-control dt-uname textarea"
                      placeholder="Enter Insurance Information"
                      name="insurance_information"
                      value={input.insurance_information}
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.insurance_information ? (
                          "This field is Required"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="notes_comments" as={Col}>
                    <Form.Label className="mb-2">Notes </Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      //
                      className="form-control dt-uname textarea"
                      placeholder="Enter Notes "
                      name="notes_comments"
                      value={input.notes_comments}
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.notes_comments ? (
                          "This field is Required"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <div className="btn btn-primary" onClick={handlePrev}>
                  Prev
                </div>
                <Button
                  className="btn btn-secondary ml-3 mr-3"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button type="submit" className="btn btn-primary ">
                  Next
                </Button>
              </>
            ) : step === 4 ? (
              <>
                <h5>Compliance and Legal</h5>
                <Row className="mb-3">
                  <Form.Group controlId="performance_metrics" as={Col}>
                    <Form.Label className="mb-2">
                      Performance Metrics
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      //
                      className="form-control dt-uname textarea"
                      placeholder="Enter Performance Metrics"
                      name="performance_metrics"
                      value={input.performance_metrics}
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.performance_metrics ? (
                          "This field is Required"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="terms_and_conditions" as={Col}>
                    <Form.Label className="mb-2">
                      Terms and Conditions
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      //
                      className="form-control dt-uname textarea"
                      placeholder="Enter Terms and Conditions"
                      name="terms_and_conditions"
                      value={input.terms_and_conditions}
                      onChange={handleInput}
                    />
                    {validated && (
                      <Form.Control.Feedback type="invalid">
                        {!input.terms_and_conditions ? (
                          "This field is Required"
                        ) : (
                          <></>
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <div className="btn btn-primary" onClick={handlePrev}>
                  Prev
                </div>

                <Button
                  className="btn btn-secondary ml-3 mr-3"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {result.isLoading || updated.isLoading ? (
                  <>
                    <Button className="btn btn-primary ">
                      Loading...{" "}
                      <Spinner
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="primary"
                      />
                    </Button>
                  </>
                ) : (
                  <Button className="btn btn-primary " type="submit">
                    {operateId ? "Update Vendor" : "Add Vendor"}
                  </Button>
                )}
              </>
            ) : (
              <>{step}</>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VendorAdd;
