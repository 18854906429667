import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useBookingResourceUsageAndRevReportQuery } from '../../../Slices/spaceApi'
import LineBarChart from './LineBarChart'
import { Modal } from 'react-bootstrap'
import Loader from '../../Loader/Loader'

const ResourceAndRev = () => {
  const ref = useRef(null)
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)
  const { data, isLoading, isError } = useBookingResourceUsageAndRevReportQuery(
    {
      spaceId: spaceId || currentSpaceId
    }
  )

  if (isLoading) return <Loader />
  if (isError) return <p>Error loading data...</p>
  return (
    <>
      <div className='container-fluid with-bottom-padding p_16 mt-3'>
        <div className='row'>
          {!isLoading &&
            data?.map((item, index) => (
              <LineBarChart key={index} data={item}></LineBarChart>
            ))}
        </div>
      </div>
    </>
  )
}

export default ResourceAndRev
