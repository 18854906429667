import React, { useEffect } from "react";
import {
  Link,
  NavLink,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./styles.css";
import { useAccountListQuery } from "../../Slices/spaceApi";
import { useTaxRatesListQuery } from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import PackageList from "./PackageList";
import MeetingPackageList from "./MeetingPackageList";
import { Nav, Row, Tab, Tabs } from "react-bootstrap";
// import "../../App.css";
import ProductList from "./ProductList";
import * as Icon from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../Context/GlobalContext";
const Chart = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Chart Of Accounts");
  }, []);
  // data:accountData, isLoading:accountIsLoading, isError:accountIsError, error:accountError, refetch:accountRefetch
  const accountObject = useAccountListQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const taxRateObject = useTaxRatesListQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const location = useLocation();
  //.log(location.path)
  return (
    <>
      <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
        <Tabs
          defaultActiveKey="Account"
          id="justify-tab-example"
          className="mb-3"
          variant="underline"
          justify
        >
          <Tab
            eventKey="Account"
            title={
              <span className="d-flex align-items-center">
                <Icon.Box /> &nbsp;Packages
              </span>
            }
          >
            {/* <AssetList /> */}
            <PackageList account={accountObject} taxRate={taxRateObject} />
          </Tab>
          <Tab
            eventKey="Social"
            title={
              <span className="d-flex align-items-center">
                <Icon.PersonVideo2 /> &nbsp; Meeting Packages
              </span>
            }
          >
            {/* <AssetCategory /> */}
            <MeetingPackageList
              account={accountObject}
              taxRate={taxRateObject}
            />
          </Tab>
          <Tab
            eventKey="Products"
            title={
              <span className="d-flex align-items-center">
                <Icon.BoxSeam /> &nbsp; Products
              </span>
            }
          >
            <ProductList account={accountObject} taxRate={taxRateObject} />
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default Chart;
