import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Form,
  Nav,
  Pagination,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons'

import VisitList from './VisitList'
import VisitorList from './VisitorList'

const Visitor = () => {
  const [page, setPage] = useState(0)

  return (
    <section className='container-fluid  with-bottom-padding pt-0 p-3  mt-0'>
      <Tabs
        defaultActiveKey='visitor'
        id='justify-tab-example'
        className=''
        variant='underline'
        justify
      >
        <Tab
          eventKey='visitor'
          title={
            <span className='d-flex align-items-center'>
              <Icon.Building /> &nbsp;Visitor
            </span>
          }
        >
          <VisitorList />
        </Tab>

        <Tab
          eventKey='visit'
          title={
            <span className='d-flex align-items-center'>
              <Icon.Building /> &nbsp;Visits
            </span>
          }
        >
          <VisitList />
        </Tab>
      </Tabs>
    </section>
  )
}

export default Visitor
