import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import {
  useRelatedMeetingListMutation,
  useRelatedMeetingListQuery,
} from "../../Slices/spaceApi";
import Select from "react-select";
import { isValidInteger } from "../../utils/validation";
import { useSelector } from "react-redux";
const Benefit = ({ input, setInput, index, validated }) => {
  //.log({ benefits_ma: input })

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [meetingRoom, setMeetingRoom] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleMultiChange = (selected) => {
    setSelectedOptions(selected);
    setInput((prevInput) => {
      const updatedBenefits = prevInput?.benefits?.meetingRoomAllowedHours?.map(
        (benefit, index1) => {
          if (index1 === index) {
            return {
              ...benefit,
              meetingRoom: selected?.map((selected) => selected?.value),
            };
          }
          return benefit;
        }
      );

      return {
        ...prevInput,
        benefits: {
          ...prevInput.benefits,
          meetingRoomAllowedHours: updatedBenefits,
        },
      };
    });
  };

  const {
    data: meeting,
    isLoading: meetingIsLoad,
    isError: meetingIsError,
    error,
  } = useRelatedMeetingListQuery({ spaceId: spaceId || currentSpaceId });

  useEffect(() => {
    // Check if meetings are loaded and there is no error

    if (!meetingIsLoad && !meetingIsError) {
      // Map the meetings array to the format required by react-select
      const mappedOptions = meeting?.map(({ id, title }) => ({
        value: id,
        label: title,
      }));

      setMeetingRoom(mappedOptions);
    } else if (meetingIsError) {
    }
  }, [meeting, meetingIsLoad, meetingIsError]);

  useEffect(() => {
    setSelectedOptions(
      meetingRoom?.filter((option) =>
        input?.benefits?.meetingRoomAllowedHours?.[
          index
        ]?.meetingRoom?.includes(option.value)
      )
    );
  }, [meetingRoom]);

  const handleSubInput = (e) => {
    //.log('subInput')
    setInput((prevInput) => {
      const updatedBenefits = prevInput.benefits?.meetingRoomAllowedHours?.map(
        (benefit, index1) => {
          if (index1 === index) {
            return { ...benefit, hours: e.target.value };
          }
          return { ...benefit };
        }
      );

      return {
        ...prevInput,
        benefits: {
          ...prevInput.benefits,
          meetingRoomAllowedHours: updatedBenefits,
        },
      };
    });
  };

  const deleteAtIndex = () => {
    setInput((prevState) => {
      // Create a copy of the benefits array
      const newBenefits = [...prevState?.benefits?.meetingRoomAllowedHours];

      newBenefits.splice(index, 1);

      return {
        ...prevState,

        benefits: {
          ...prevState,
          meetingRoomAllowedHours: newBenefits,
        },
      };
    });
  };

  return (
    <>
      <div className="">
        <h5> {!index ? "Benifits" : ""} </h5>

        <Row className="justify-content-center">
          <Col md={10}>
            <Row index={index} className="">
              <Form.Group>
                <Form.Label>
                  <h6>Hours</h6>
                </Form.Label>
                <Form.Control
                  className="viewonly"
                  id="hours"
                  type="number"
                  min={0}
                  required
                  placeholder="Enter Hours"
                  value={
                    input.benefits?.meetingRoomAllowedHours?.[index]?.hours
                  }
                  name="hours"
                  onChange={(e) => handleSubInput(e)}
                ></Form.Control>
                {validated && (
                  <Form.Control.Feedback type="invalid">
                    {!input.benefits?.meetingRoomAllowedHours?.[index]
                      ?.hours ? (
                      "This field is Required"
                    ) : !isValidInteger(
                        input.benefits?.meetingRoomAllowedHours?.[index]?.hours
                      ) ? (
                      "Must be a Positive Integer"
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row className="mt-3">
              <Form.Label>
                <h6>Meeting Rooms</h6>
              </Form.Label>
              <Select
                value={selectedOptions}
                // value={meetingRoom?.filter(obj =>
                //   selectedOptions?.includes(obj?.id)
                // )}\
                onChange={(selected) => handleMultiChange(selected)}
                options={meetingRoom || [{ value: "0", label: "loading" }]}
                isMulti
              />
            </Row>
          </Col>
          <Col md={1}>
            <X width={20} height={20} onClick={deleteAtIndex}></X>{" "}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Benefit;
