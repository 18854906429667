import React, { useEffect, useState } from "react";
import {
  useCreateMessageMutation,
  useCreateNotesMutation,
  useDeleteMessageMutation,
  useDeleteNoteMutation,
  useGetMessagesQuery,
  useGetSpecficTicketQuery,
  useGetTourNotesQuery,
  useGetTourQuery,
  useUpdateMessageMutation,
  useUpdateTicketMutation,
  useUpdateTourNoteMutation,
} from "../Slices/spaceApi";
import * as Icon from "react-bootstrap-icons";

import { requiredAlert, userDeleteAlert } from "../utils/Alerts";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../Components/Loader/Loader";
import {
  Accordion,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Form,
} from "react-bootstrap";
import UserIcon from "../Assets/UserIcon.svg";
// import Accordion from "react-bootstrap/Accordion";

import "react-quill/dist/quill.snow.css";

import ReactQuill from "react-quill";
import { TimeAgo } from "../utils/timeAgo";
import { useGlobalContext } from "../Context/GlobalContext";

const ViewTour = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Tour View");
  }, []);
  const { id } = useParams();

  const { data: tour, isLoading: fetchingData } = useGetTourQuery({
    spaceId: spaceId || currentSpaceId,
    tourId: id,
  });

  //   const { data, isLoading } = useGetSpecficTicketQuery({
  //     spaceId: spaceId || currentSpaceId,
  //     ticketId: id,
  //   });

  const { data: notesData, isLoading: notesLoading } = useGetTourNotesQuery({
    spaceId: spaceId || currentSpaceId,
    tourId: id,
  });

  const [createNotesByApi, resultStats3] = useCreateNotesMutation();
  const [deleteNoteByApi, resultStats4] = useDeleteNoteMutation();
  const [updateNoteByApi, resultStats5] = useUpdateTourNoteMutation();

  const [apiSpinner, setApiSpinner] = useState(false);
  const [editApiSpinner, setEditApiSpinner] = useState(false);

  const [text, setText] = useState("");
  const [editReply, setEditReply] = useState("");
  const [editId, setEditId] = useState();

  const [checked, setChecked] = useState(false);
  const [statusAction, setStatusAction] = useState(false);
  //   const [statusValue, setStatusValue] = useState(data?.status);

  //   useEffect(() => {
  //     setStatusValue(data?.status);
  //   }, [data]);

  const handleStatusAction = (value) => {
    // setStatusValue(value);
    setStatusAction(true);
  };
  const handleTextChange = (e) => {
    setText(e.target.value);
  };
  const changeStatusByApi = (e) => {
    e.preventDefault();
    setStatusAction(false);
    // updateTicketByAPi({
    //   spaceId: spaceId || currentSpaceId,
    //   data: { status: statusValue },
    //   ticketId: id,
    // });
  };

  const postRelpyComment = () => {
    setApiSpinner(true);

    createNotesByApi({
      spaceId: spaceId || currentSpaceId,
      tourId: id,
      data: { note: text },
    });
  };

  useEffect(() => {
    if (resultStats3?.isError) {
      setApiSpinner(false);
      const errorMessage = Object?.values(resultStats3?.error?.data).find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }

    if (resultStats3?.isSuccess) {
      setApiSpinner(false);

      toast.success("Note Added SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setText("");

      // updateTicketByAPi({
      //   spaceId: spaceId || currentSpaceId,
      //   data: { status: 3 },
      //   ticketId: id,
      // });
    }
  }, [resultStats3]);

  const handleEditReply = () => {
    setEditApiSpinner(true);
    updateNoteByApi({
      spaceId: spaceId || currentSpaceId,
      tourId: id,
      noteId: editId,
      data: { note: editReply },
    });
  };

  const handleEditReplyCheck = (item) => {
    setEditReply(item.note);
    setEditId(item.id);
  };

  const handleDeleteReply = (item) => {
    deleteNoteByApi({
      spaceId: spaceId || currentSpaceId,
      tourId: id,
      noteId: item.id,
    });
  };

  useEffect(() => {
    if (resultStats4?.isSuccess) {
      userDeleteAlert("Note Deleted Successfully.");
    }
    if (resultStats4?.isError) {
      const errorMessage = Object.values(resultStats4?.error?.data).find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }
  }, [resultStats4]);

  useEffect(() => {
    if (resultStats5?.isError) {
      setEditApiSpinner(false);
      const errorMessage = Object.values(resultStats5?.error?.data).find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }

    if (resultStats5?.isSuccess) {
      setEditApiSpinner(false);

      toast.success("Reply Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setEditReply("");
      setEditId("");
    }
  }, [resultStats5]);
  // function formatDateTime(dateTimeString) {
  //   let date = new Date(dateTimeString);

  //   const dateFormatter = new Intl.DateTimeFormat("en-GB", {
  //     day: "2-digit",
  //     month: "short",
  //     year: "numeric",
  //   });

  //   const timeFormatter = new Intl.DateTimeFormat("en-US", {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     hour12: true,
  //   });

  //   const formattedDate = dateFormatter.format(date);
  //   const formattedTime = timeFormatter.format(date);

  //   return `${formattedDate}, ${formattedTime}`;
  // }
  function formatDateTime(dateTimeString) {
    try {
      if (!dateTimeString) {
        throw new Error("Invalid date string");
      }

      const date = new Date(dateTimeString);

      if (isNaN(date.getTime())) {
        throw new Error("Invalid date string");
      }

      const dateFormatter = new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });

      const timeFormatter = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });

      const formattedDate = dateFormatter.format(date);
      const formattedTime = timeFormatter.format(date);

      return `${formattedDate}, ${formattedTime}`;
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return "Invalid date";
    }
  }

  if (notesLoading || fetchingData) {
    return <Loader height={6.6} />;
  }
  return (
    <div className="container-fluid" style={{ padding: "0px 18px" }}>
      <Breadcrumb className="custom-breadcrumb ml-auto">
        <Breadcrumb.Item>
          <Link to="/tour/list">Tour List</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{tour?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-4">Tour #{id}</h4>

      <div>
        <div className="row">
          <h6>Tour Information</h6>
          <div className="col-md-5">
            {/* <Card sx={{ display: "flex", flexDirection: "column", mt: 1 }}> */}
            <div className="card">
              <div className="d-flex flex-column">
                <div style={{ padding: "16px 16px" }}>
                  <p className="mb-2 fw-bold">Guest</p>
                  <div className="d-flex align-items-center">
                    <p className="mb-0" style={{ fontWeight: "500" }}>
                      {tour?.name}
                    </p>
                  </div>
                </div>
                <hr className="my-0" />
                <div style={{ padding: "16px 16px" }}>
                  <p className="mb-2 fw-bold">Contact Information</p>
                  <div className="d-flex align-items-center">
                    <p className="mb-0" style={{ fontWeight: "500" }}>
                      {tour?.email}
                      <br />
                      {tour?.phone_number}
                    </p>
                  </div>
                </div>
                <hr className="my-0" />

                <div style={{ padding: "16px 16px" }}>
                  <p className="mb-2 fw-bold">Time</p>
                  <div>
                    <p className="mb-0" style={{ fontWeight: "500" }}>
                      {tour?.tour_datetime &&
                        formatDateTime(tour?.tour_datetime)}
                      {/* {tour?.tour_datetime} */}
                    </p>
                  </div>
                </div>
                <hr className="my-0" />

                <hr className="my-0" />
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "16px 16px" }}
                >
                  <p className="mb-2 mt-1 fw-bold">Status</p>

                  {tour?.status === 1 && (
                    <Badge bg="danger">Not Approved</Badge>
                  )}
                  {tour?.status === 2 && <Badge bg="success">Approved</Badge>}
                  {tour?.status === 3 && (
                    <Badge bg="info">Waiting Approval</Badge>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <Card>
              <Accordion>
                <Accordion.Header>Make Notes</Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <textarea
                      value={text}
                      onChange={handleTextChange}
                      className="form-control"
                      placeholder="Write your note here...."
                      rows={5}
                    />

                    <div className="d-flex justify-content-end">
                      <Button
                        variant="primary"
                        disabled={apiSpinner || text.length <= 0}
                        className="mt-3"
                        //   style={{ float: "right" }}
                        onClick={postRelpyComment}
                      >
                        Add Note
                        {apiSpinner ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "5px",
                            }}
                          ></div>
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </Card.Body>
                </Accordion.Body>
              </Accordion>
            </Card>

            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              {notesLoading ? (
                <div style={{ padding: "24px 24px" }}>Loading...</div>
              ) : notesData?.notes?.length > 0 ? (
                notesData?.notes?.map((item, idx) => (
                  <>
                    <Card.Header className="p-3 pb-0">
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div>
                          <p className="mb-0 fw-bold">
                            <img
                              src={UserIcon}
                              alt=""
                              width={22}
                              style={{ marginRight: "10px" }}
                            />
                            {item.owner && item.owner.name}
                            <Badge
                              style={{
                                marginLeft: "10px",
                                fontWeight: "normal",
                                fontSize: "13px",
                              }}
                            >
                              <TimeAgo date={item.timestamp} />
                            </Badge>
                          </p>
                        </div>
                        {item.owner && (
                          <div className="align-items-end d-flex flex-column">
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                variant="none"
                                // className="rounded-circle bg-danger"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <Icon.ThreeDotsVertical
                                  style={{ cursor: "pointer" }}
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleEditReplyCheck(item)}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleDeleteReply(item)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div
                        style={{
                          overflow: "auto",
                          wordWrap: "break-word",
                          // lineHeight: "16px",
                        }}
                      />
                      <p>{item.note}</p>
                    </Card.Body>

                    {editReply && editId == item.id && (
                      <div style={{ padding: "10px 24px" }}>
                        <textarea
                          value={editReply}
                          onChange={(e) => setEditReply(e.target.value)}
                          className="form-control"
                          placeholder="Write your note here...."
                          rows={3}
                        />

                        <div className="d-flex justify-content-end">
                          <Button
                            variant="primary"
                            disabled={editApiSpinner || editReply?.length <= 0}
                            className="mt-3"
                            onClick={handleEditReply}
                          >
                            Edit Reply
                            {editApiSpinner ? (
                              <div
                                className="spinner-border text-primary"
                                role="status"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "5px",
                                }}
                              ></div>
                            ) : (
                              ""
                            )}
                          </Button>
                        </div>
                      </div>
                    )}

                    <hr className="my-0" />
                  </>
                ))
              ) : (
                <div style={{ padding: "24px 24px" }}>No Reply Yet.</div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTour;
