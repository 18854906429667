import React from "react";
import "./index.css";
import MeetingPackageTable from "./MeetingPackageTable";
import PackageTable from "./PackageTable";
import ProgressCard from "../Dashboard/ProgressCard";
import { useSelector } from "react-redux";
import { useGetSpaceDashboardQuery } from "../../Slices/spaceApi";

const AddSpacePackage = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: dashboardData, isLoading } = useGetSpaceDashboardQuery(
    spaceId || currentSpaceId
  );

  return (
    <>
      <section
        className="app-user-list with-bottom-padding"
        style={{ padding: "0px 16px" }}
      >
        {!isLoading &&
        dashboardData &&
        (!dashboardData?.amenities > 0 ||
          !dashboardData?.meetingRooms > 0 ||
          !dashboardData?.spacePackages > 1 ||
          !dashboardData?.users > 0) ? (
          <div className="card">
            <ProgressCard dashboardData={dashboardData} currentStep={3} />
          </div>
        ) : null}
        <h6 className="ml-2">Space Packages</h6>
        <div className="card">
          <PackageTable />
        </div>
        <h6 className="ml-2">Meeting Packages</h6>
        <div className="card bottom-card">
          <MeetingPackageTable />
        </div>
      </section>
    </>
  );
};

export default AddSpacePackage;
