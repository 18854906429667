// const MeetingInputs = [
//   {
//     id: 1,
//     name: "title",
//     type: "text",
//     placeholder: "Enter Meeting Room Name(1-25 Characters)",
//     label: "Name*",
//     errorMessage: "Name Must Have A Letter or Digit!(1-25 Characters)",
//     required: true,
//     // pattern: "^(?=[A-Za-z0-9!@#$%^&* -]{1,25}$)[A-Za-z][A-Za-z0-9!@#$%^&* -]*$",
//     pattern: "^(?=.*[A-Za-z0-9]).{1,25}$",
//   },
//   {
//     id: 2,
//     name: "price",
//     type: "number",
//     placeholder: "Enter Price",
//     label:
//       billingOption == 1 ? "Price*[Half Hour Slot]" : "Coins*[Half Hour Slot]",
//     min: 1,
//     errorMessage: "Add Valid Price",
//     required: true,
//   },
//   {
//     id: 3,
//     name: "capacity",
//     type: "number",
//     placeholder: "Enter Capacity",
//     label: "Capacity*",
//     min: 1,
//     // max: 25,
//     errorMessage: "Add Valid Capacity!",
//     required: true,
//   },
// ];

// export default MeetingInputs;
import React from "react";
import { useGlobalContext } from "../../Context/GlobalContext";

const MeetingInputs = () => {
  const { billingOption } = useGlobalContext();

  return [
    {
      id: 1,
      name: "title",
      type: "text",
      placeholder: "Enter Resource Name (1-25 Characters)",
      label: "Name",
      errorMessage: "Name Must Have a Letter or Digit! (1-25 Characters)",
      required: true,
      pattern: "^(?=.*[A-Za-z0-9]).{1,25}$",
    },

    {
      id: 2,
      name: "capacity",
      type: "number",
      placeholder: "150",
      label: "Capacity",
      min: 1,
      errorMessage: "Add Valid Capacity!",
      required: true,
    },
    {
      id: 3,
      name: "price",
      type: "number",
      placeholder: "1000",
      label:
        billingOption === 1
          ? "Price [Half Hour Slot]"
          : "Coins [Half Hour Slot]",
      min: 0,
      // errorMessage: "Add Valid Price",
      // required: true,
    },
  ];
};

export default MeetingInputs;
