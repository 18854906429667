import React, { useState, useEffect } from "react";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { colorStyles } from "../../utils/constant";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import imagePlaceHolder from "../../Assets/imagePlaceHolder.png";
import { PackageInputs, PackageFields } from "./";
import axios from "axios";
import {
  useGetMeetingRoomQuery,
  useGetRelatedMeetingRoomsQuery,
  useUpdatePackageMutation,
} from "../../Slices/spaceApi";
import { toast, Toaster } from "react-hot-toast";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../Context/GlobalContext";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const UpdatePackagePopUp = ({
  item,
  index,
  items,
  FetchFacilities,
  FetchSpaceTypes,
  FetchSpaceDurations,
  // FetchMeetingRoom,
}) => {
  const { packagesPermissions, billingOption } = useGlobalContext();

  const {
    facilities,
    name,
    // price,
    spaceTypeString,
    durationString,
    person,
    id,
    duration,
    spaceType,
    spaceTypes,
    images,
    inclusiveTax,
    serviceCharges,
    rentalFee,
    meetingRoomAllowedHours,
  } = items[index];
  console.log("Space Type is", spaceType);
  console.log("Space Types are", spaceTypes);

  const meetingRoomData = meetingRoomAllowedHours;
  const [show, setShow] = useState(false);

  const facilitiesArray = Array.isArray(FetchFacilities)
    ? FetchFacilities
    : [FetchFacilities];

  const [facility, setFacility] = useState([]);

  useEffect(() => {
    // if (show) {
    if (FetchFacilities && FetchFacilities.length > 0) {
      const filteredFacilities = facilities?.filter((item) => {
        return FetchFacilities?.some(
          (fetchFacility) => fetchFacility.id === item.id
        );
      });

      const filteredFacilityIds = filteredFacilities.map((item) => item.id);

      setFacility(filteredFacilityIds);
    }
    // }
  }, [facilities, FetchFacilities, show]);
  // facilities, FetchFacilities
  // let facility = facilities?.map((item, idx) => item.id);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { isLoading: relatedLoading, data: FetchMeetingRoom } =
    useGetRelatedMeetingRoomsQuery({ id: spaceId || currentSpaceId });
  const [updatePackage, resultStats] = useUpdatePackageMutation();
  const [allDone, setAllDone] = useState(true);
  const [apiSpinner, setApiSpinner] = useState(false);
  const navigate = useNavigate();
  const [errorDurations, setErrorDurations] = useState(false);
  const [errorSpaceType, setErrorSpaceType] = useState(false);
  const [errorFacilities, setErrorFacilties] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [meetingRoomSelect, setMeetingRoomSelect] = useState(false);
  const [rows, setRows] = useState([
    {
      selectedLocation: null,
      selectedMeetingRooms: [],
      hours: 0,
    },
  ]);

  const uniqueLocations = Array.from(
    new Set(FetchMeetingRoom?.map((room) => room.space.name))
  );
  useEffect(() => {
    if (FetchMeetingRoom?.length > 0 && meetingRoomData?.length > 0) {
      setRows(
        meetingRoomData?.map((item) => {
          const selectedMeetingRooms = item.meetingRoom?.map((roomId) =>
            FetchMeetingRoom?.find((room) => room.id === roomId)
          );

          return {
            selectedLocation: null,
            selectedMeetingRooms,
            hours: item.hours,
          };
        })
      );
    } else {
      return;
    }
  }, [FetchMeetingRoom, show]);

  const handleApprovePublicBooking = () => {
    setMeetingRoomSelect((prevValue) => {
      const newValue = !prevValue;
      return newValue;
    });
    // setMeetingRoomSelect(true);
  };

  const handleAddRow = () => {
    const newRow = {
      selectedLocation: null,
      selectedMeetingRooms: [],
      hours: 0,
    };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleLocationChange = (event, rowIndex) => {
    const selectedLocation = event.target.value;
    const updatedRows = [...rows];
    updatedRows[rowIndex].selectedLocation = selectedLocation;
    setRows(updatedRows);
  };

  const handleMeetingRoomChange = (selectedOptions, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].selectedMeetingRooms = selectedOptions;
    setRows(updatedRows);
  };

  const handleHoursChange = (e, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].hours = e.target.value;
    setRows(updatedRows);
  };
  const handleShow = () => setShow(true);
  const [file, setFile] = useState(false);

  const [updateData, setUpdateData] = useState({
    name: name,
    duration: duration,
    spaceType: spaceType,
    // price: price,
    serviceCharges: serviceCharges,
    rentalFee: rentalFee,
    person: person,
    facilities: facility,
    images: images,
    inclusiveTax: inclusiveTax,
  });

  const handleUpdateMeeting = (item) => {
    setUpdateData({
      name: item.name,
      duration: item.duration,
      spaceType: item.spaceType,
      // price: item.price,
      serviceCharges: item.serviceCharges,
      rentalFee: item.rentalFee,
      person: item.person,
      facilities: item.facility,
      images: item.images,
      inclusiveTax: item.inclusiveTax,
      meetingRoomAllowedHours: item.meetingRoomAllowedHours,
    });
  };

  useEffect(() => {
    handleUpdateMeeting({
      facility,
      name,
      // price,
      spaceTypeString,
      durationString,
      person,
      id,
      duration,
      serviceCharges,
      rentalFee,
      spaceType,
      inclusiveTax,
      images,
      meetingRoomAllowedHours,
    });
  }, [
    facilities,
    facility,
    name,
    // price,
    spaceTypeString,
    durationString,
    person,
    id,
    serviceCharges,
    rentalFee,
    inclusiveTax,
    duration,
    spaceType,
    images,
    meetingRoomAllowedHours,
  ]);

  const handleClose = () => {
    setShow(false);

    handleUpdateMeeting({
      facility,
      name,

      spaceTypeString,
      durationString,
      person,
      id,
      serviceCharges,
      rentalFee,
      duration,
      spaceType,
      images,
      inclusiveTax,
      meetingRoomAllowedHours,
    });
    setErrorDurations(false);
    setErrorSpaceType(false);
    setErrorFacilties(false);
    setFile(false);
  };

  const ali = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  const onchange = (e) => {
    setUpdateData({ ...updateData, [e.target.name]: e.target.value });
  };

  const handleDuration = (e) => {
    setUpdateData({ ...updateData, duration: e.id });
  };

  const handleType = (e) => {
    setUpdateData({ ...updateData, spaceType: e.id });
  };

  const onBlurDuration = (e) => {
    if (!updateData.duration) {
      setErrorDurations(true);
    }
  };

  const onFocusDuration = (e) => {
    if (updateData.duration) {
      setErrorDurations(false);
    }
  };

  const onBlurSpaceType = (e) => {
    if (!updateData.spaceType) {
      setErrorSpaceType(true);
    }
  };

  const onFocusSpaceType = (e) => {
    if (updateData.spaceType) {
      setErrorSpaceType(false);
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (!file == "" && file !== null) {
      setIsUploading(true);

      if (file.size >= 1e7) {
        errorAlertImage("File is too Big");
        setFile("");
        setIsUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUpdateData({ ...updateData, images: resp.data });
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
      } else {
        setFile("");
        requiredAlert("Please Select Image File");
        setIsUploading(false);
      }
    }
  }, [file]);

  const updateSpace = (e) => {
    e.preventDefault();
    const {
      name,
      duration,
      spaceType,
      person,
      serviceCharges,
      rentalFee,
      inclusiveTax,
      facilities,
      images,
    } = updateData;
    const meetingRoomAllowedHours = rows?.map((row) => ({
      meetingRoom: row.selectedMeetingRooms?.map((item) => item.id),
      hours: row.hours || 0,
    }));
    const SpaceTypes = spaceTypeRows
      ?.map((item) => ({
        quantity: parseInt(item.quantity),
        type: parseInt(item.type),
      }))
      .filter((item) => item.quantity !== 0 && item.package !== 0);

    let personstoFloat = parseFloat(person);

    let data = {
      name,
      duration,
      spaceType,
      // price,
      serviceCharges,
      rentalFee,
      inclusiveTax,
      persons: personstoFloat,
      facilities,
      images,
      spaceTypes: SpaceTypes,

      meetingRoomAllowedHours: meetingRoomAllowedHours,
    };

    onBlurDuration();
    onBlurSpaceType();
    onBlurFacilities();
    // if (allDone) {
    //   return;
    // }

    if (!file && images) {
      let filename = null;
      if (
        Array.isArray(images) &&
        images.length > 0 &&
        typeof images[0] === "string"
      ) {
        const parts = images[0].split("/");
        const lastPart = parts[parts.length - 1];
        filename = lastPart.split("?")[0];
      }
      if (filename?.includes("%20")) {
        filename = filename.replace(/%20/g, " ");
      }
      setApiSpinner(true);
      updatePackage({
        spaceId: spaceId || currentSpaceId,
        id,
        data: {
          name,
          duration,
          spaceType,
          // price,
          serviceCharges,
          inclusiveTax,
          rentalFee,
          spaceTypes: SpaceTypes,

          persons: personstoFloat,
          facilities,
          images: [filename || ""],
          meetingRoomAllowedHours,
        },
      });
    } else {
      setApiSpinner(true);

      updatePackage({
        spaceId: spaceId || currentSpaceId,
        id,
        data,
      });
    }
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      const errorMessage =
        resultStats &&
        resultStats?.error &&
        resultStats?.error?.data &&
        Object?.values(resultStats?.error?.data).find(
          (error) => error?.length > 0 || "An Error Occured"
        );
      requiredAlert(errorMessage);

      // requiredAlert(resultStats?.error?.data?.error);
    }

    if (resultStats?.isSuccess) {
      // setTimeout(() => {
      handleClose();
      // }, 1500);
      setApiSpinner(false);

      toast.success("Package Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  // const handle = (e) => {
  //   setUpdateData({
  //     ...updateData,
  //     facilities: Array.isArray(e) ? e.map((x) => x.id) : [],
  //   });
  // };

  const handle = (e) => {
    if (e.some((option) => option.id === "all")) {
      // If "All" is selected, set the facilities to all available facilities
      setUpdateData({
        ...updateData,
        facilities: facilitiesArray?.map((facility) => facility.id),
      });
    } else {
      // Otherwise, update the selected facilities
      setUpdateData({
        ...updateData,
        facilities: e.map((x) => x?.id),
      });
    }
  };
  const handleInclusiveTax = (event) => {
    setUpdateData({
      ...updateData,
      inclusiveTax: event.target.checked,
    });
  };
  const onFocusFacilities = (e) => {
    if (e.target.value == updateData.facilities) {
      setErrorFacilties(false);
    }
  };

  const onBlurFacilities = () => {
    if (updateData.facilities.length < 1) {
      setErrorFacilties(true);
    }
  };

  const [spaceTypeRows, setSpaceTypeRows] = useState([]);

  useEffect(() => {
    setSpaceTypeRows([]);

    spaceTypes?.map((item) => {
      // if (item.id == packageId) {
      setSpaceTypeRows((prevRows) => [
        ...prevRows,
        {
          type: item.type,
          quantity: item.quantity,
        },
      ]);
      // }
    });
  }, [show]);
  console.log("Space Types rows are ", spaceTypeRows);
  const handleSpaceTypeAdd = () => {
    const newRow = {
      type: FetchSpaceTypes?.[0]?.id,
      quantity: 1,
    };
    setSpaceTypeRows([...spaceTypeRows, newRow]);
  };
  const handleRemoveSpaceTypeRow = (index) => {
    const updatedRows = [...spaceTypeRows];
    updatedRows.splice(index, 1);
    setSpaceTypeRows(updatedRows);
  };

  const handleSpaceTypeInputChange = (event, index) => {
    let data = [...spaceTypeRows];
    data[index].type = event.id;
    setSpaceTypeRows(data);
  };
  const handleSpaceTypeQuantity = (event, index) => {
    let data = [...spaceTypeRows];
    data[index].quantity = event.target.value;
    setSpaceTypeRows(data);
  };
  useEffect(() => {
    if (
      !updateData.duration == "" &&
      // updateData.price > 0 &&
      !updateData.spaceType == "" &&
      updateData.person > 0 &&
      updateData.name.match("^(?=.*[A-Za-z0-9]).{1,25}$") &&
      updateData?.facilities?.length > 0
    ) {
      setAllDone(false);
    } else {
      setAllDone(true);
    }
  }, [updateData, file]);
  // if (relatedLoading) {
  //   return <Loader height={6.6} />;
  // }
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {packagesPermissions[0]?.update ? (
              "Update Package"
            ) : (
              <span>
                Update <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="mx-2 p-0"
          onClick={packagesPermissions[0]?.update ? handleShow : null}
        >
          <EditIcon />
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Space Package</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            {/* {PackageInputs?.map((input) => {
              return (
                <PackageFields
                  key={input.id}
                  {...input}
                  value={updateData[input.name]}
                  onChange={onchange}
                />
              );
            })}

            <div className="mb-2">
              <label htmlFor="durations" className="mb-1">
                Durations
              </label>
              <Select
                className={errorDurations ? "reactSelectActive" : ""}
                defaultValue={{ name: durationString, id: duration }}
                options={FetchSpaceDurations}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                styles={colorStyles}
                value={FetchSpaceDurations?.find(
                  (obj) => obj.value === updateData.duration
                )}
                onBlur={onBlurDuration}
                id="durations"
                onChange={handleDuration}
                onFocus={onFocusDuration}
              />
              {errorDurations && (
                <span className="e">Select The Durations</span>
              )}
            </div> */}
            <div className="row">
              <div className="col-12">
                {PackageInputs?.map((input, idx) => {
                  if (input.id === 1) {
                    return (
                      <PackageFields
                        submitted={submitted}
                        key={input.id}
                        {...input}
                        value={updateData[input.name]}
                        onChange={onchange}
                        index={idx}
                      />
                    );
                  }
                })}
              </div>
            </div>

            <div className="row">
              {PackageInputs?.map((input, idx) => {
                if (input.id === 2 || input.id === 3) {
                  return (
                    <div className="col-12 col-md-6">
                      <PackageFields
                        submitted={submitted}
                        key={input.id}
                        {...input}
                        value={updateData[input.name]}
                        onChange={onchange}
                        index={idx}
                      />
                    </div>
                  );
                }
              })}
            </div>
            <div className="form-check">
              <label className="form-check-label" htmlFor="inclusiveTax">
                Is this Price Includes Tax!
              </label>
              <input
                type="checkbox"
                className="form-check-input ml-2"
                id="inclusiveTax"
                checked={updateData?.inclusiveTax}
                onChange={(e) => handleInclusiveTax(e)}
              />
            </div>

            <div className="row">
              {PackageInputs?.map((input, idx) => {
                if (input.id === 4) {
                  return (
                    <div className="col-12 col-md-6">
                      <PackageFields
                        submitted={submitted}
                        key={input.id}
                        {...input}
                        value={updateData[input.name]}
                        onChange={onchange}
                        index={idx}
                      />
                    </div>
                  );
                }
              })}
              {FetchSpaceDurations?.length > 0 && (
                <div className="col-12 col-md-6 mb-2">
                  <label htmlFor="durations" className="mb-1">
                    Durations
                  </label>
                  <Select
                    className={errorDurations ? "reactSelectActive" : ""}
                    defaultValue={{
                      spaceDuration: durationString,
                      id: duration,
                    }}
                    options={FetchSpaceDurations}
                    getOptionLabel={(option) => option.spaceDuration}
                    getOptionValue={(option) => option.id}
                    styles={colorStyles}
                    value={FetchSpaceDurations?.find(
                      (obj) => obj.value === updateData.duration
                    )}
                    onBlur={onBlurDuration}
                    id="durations"
                    onChange={handleDuration}
                    onFocus={onFocusDuration}
                  />
                  {errorDurations && (
                    <span className="e">Select The Durations</span>
                  )}
                </div>
              )}
            </div>

            {/* <div className="mb-2">
              <label htmlFor="space-type" className="mb-1">
                Space Type
              </label>
              <Select
                className={errorSpaceType ? "reactSelectActive" : ""}
                options={FetchSpaceTypes}
                defaultValue={{ name: spaceTypeString, id: spaceType }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                styles={colorStyles}
                value={FetchSpaceTypes?.find(
                  (obj) => obj.value === updateData.spaceType
                )}
                onBlur={onBlurSpaceType}
                onChange={handleType}
                onFocus={onFocusSpaceType}
              />
              {errorSpaceType && (
                <span className="e">Select The SpaceType</span>
              )}
            </div> */}

            {FetchSpaceTypes?.length > 0 && (
              <div className="mb-2">
                <label htmlFor="space-type" className="mb-1">
                  Space Type
                </label>
                {/* <Select
                  className={errorSpaceType ? "reactSelectActive" : ""}
                  options={FetchSpaceTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  styles={colorStyles}
                  value={FetchSpaceTypes?.find(
                    (obj) => obj.id === packageValues?.spaceType
                  )}
                  onBlur={onBlurSpaceType}
                  onChange={handleType}
                  onFocus={onFocusSpaceType}
                  placeholder={"Select Space Type"}
               
                /> */}
                {spaceTypeRows?.map((input, index) => (
                  <div key={index} className=" my-2">
                    <div className="">
                      <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                        <div className="row w-100 pe-lg-0 pe-3">
                          <div className="col-12 col-md-6 mb-lg-0 mb-2 mt-lg-0">
                            <p className="card-text col-title mb-md-50 mb-0">
                              Select Space Type
                            </p>
                            {/* <select
                                  name="packageId"
                                  value={input?.packageId}
                                  onChange={(e) => handleInputChange(e, index)}
                                  className="form-control"
                                >
                                  {FetchPackageData?.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select> */}
                            <Select
                              name="packageId"
                              options={FetchSpaceTypes}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              styles={colorStyles}
                              value={FetchSpaceTypes?.find(
                                (obj) => obj.id === spaceTypeRows?.[index]?.type
                              )}
                              onChange={(selectedOption) =>
                                handleSpaceTypeInputChange(
                                  selectedOption,
                                  index
                                )
                              }
                            />
                          </div>

                          <div className="col-12 col-md-6 mb-lg-0 mb-2 mt-lg-0">
                            <p className="card-text col-title mb-0">Quantity</p>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="1"
                              min={1}
                              name="quantity"
                              value={input?.quantity}
                              onChange={(e) =>
                                handleSpaceTypeQuantity(e, index)
                              }
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                          {index != 0 && (
                            <p
                              className="bg-white"
                              onClick={() => handleRemoveSpaceTypeRow(index)}
                            >
                              <CancelIcon />
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-link "
                  onClick={handleSpaceTypeAdd}
                >
                  Associate More Type
                </button>
                {/* {errorSpaceType && (
                  <span className="e">Select The SpaceType</span>
                )} */}
              </div>
            )}

            <div className="mb-2">
              <label htmlFor="package-facilities" className="mb-1">
                Facilities (Select Multiple)
              </label>
              {FetchFacilities ? (
                <Select
                  className={errorFacilities ? "reactSelectActive" : ""}
                  // options={FetchFacilities}
                  options={[
                    // Add the "All" option
                    { id: "all", name: "All" },
                    ...facilitiesArray,
                  ]}
                  // defaultValue={facilities?.map((ele) => ele)}
                  value={facilitiesArray?.filter((obj) =>
                    updateData?.facilities?.includes(obj?.id)
                  )}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  styles={colorStyles}
                  onChange={handle}
                  closeMenuOnSelect={false}
                  onBlur={onBlurFacilities}
                  isMulti
                  id="package-facilities"
                  onFocus={onFocusFacilities}
                />
              ) : (
                <div
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => navigate("/setting/accountsetting")}
                >
                  Please Set Facilities in Settings
                </div>
              )}
              {errorFacilities && (
                <span className="e">Select The Facility</span>
              )}
            </div>

            <div className="mb-2">
              <label htmlFor="name">
                Image <span className="optional">(Optional)</span>
              </label>

              <div className="media">
                <div className="media-aside align-self-start">
                  <img
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : images?.[0]
                        ? images
                        : imagePlaceHolder
                    }
                    style={{
                      width: "90px",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    className="rounded"
                    alt="img-1"
                  />
                </div>

                <div>
                  <input
                    id="real-file"
                    name="file"
                    type="file"
                    hidden="hidden"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="media-body mt-2">
                  {isUploading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Uploading...</span>
                    </div>
                  ) : (
                    <button
                      id="custom-button"
                      onClick={ali}
                      className="btn btn-secondary me-2 btn-sm"
                    >
                      Upload
                    </button>
                  )}
                  <p className="card-text mt-2">
                    <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "}
                    Allowed Max size of 10Mb
                    {/* <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">Allowed Max size of 10Mb</Tooltip>
                      }
                    >
                      <Button variant="white" className="mx-2 p-0">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />
                      </Button>
                    </OverlayTrigger> */}
                  </p>
                  {!file && !updateData?.images ? (
                    <div className="invalid-feedback">
                      Please select an image.
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex mt-4" style={{ cursor: "pointer" }}>
              <p htmlFor="" className="">
                Meeting Room (Select Multiple)
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch2"
                  label=""
                  className="ml-2"
                  value={meetingRoomSelect}
                  checked={meetingRoomSelect}
                  onChange={handleApprovePublicBooking}
                />
              </Form>
            </div>

            {meetingRoomSelect && (
              <>
                <div className="form-group">
                  <label htmlFor="package-facilities" className="mb-1">
                    Meeting Room (Select Multiple)
                  </label>
                  {FetchMeetingRoom?.length > 0 &&
                    rows?.map((row, index) => (
                      <div key={index} className="repeater-wrapper my-2">
                        <div className="">
                          {/* <label>Select a Location:</label> */}
                          <p className="card-text col-title mb-md-50 mb-0 ml-3">
                            Select Location
                          </p>
                          <select
                            onChange={(e) => handleLocationChange(e, index)}
                            value={row.selectedLocation}
                            className="form-control col-10 ml-3"
                          >
                            <option value="">All Locations</option>
                            {uniqueLocations?.map((location) => (
                              <option key={location} value={location}>
                                {location}
                              </option>
                            ))}
                          </select>
                          <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                            <div className="row w-100 pe-lg-0 pe-3">
                              <div className=" col-12 mb-lg-0 mb-2 mt-lg-0">
                                <p className="card-text col-title mb-md-50 mb-0">
                                  Select allowed meeting rooms
                                </p>
                                <Select
                                  options={
                                    !row.selectedLocation
                                      ? FetchMeetingRoom
                                      : FetchMeetingRoom?.filter(
                                          (room) =>
                                            room.space.name ===
                                            row.selectedLocation
                                        )
                                  }
                                  getOptionLabel={(option) => option.title}
                                  getOptionValue={(option) => option.id}
                                  closeMenuOnSelect={false}
                                  styles={colorStyles}
                                  value={row.selectedMeetingRooms}
                                  onChange={(selectedOptions) =>
                                    handleMeetingRoomChange(
                                      selectedOptions,
                                      index
                                    )
                                  }
                                  isMulti
                                  id={`space-package-${index}`}
                                />
                              </div>

                              <div className=" col-12 my-lg-0 my-2">
                                {billingOption == 1 ? (
                                  <p className="card-text col-title mb-0">
                                    Add hours for this package
                                  </p>
                                ) : billingOption == 2 ? (
                                  <p className="card-text col-title mb-0">
                                    {" "}
                                    Add coins for this package
                                  </p>
                                ) : (
                                  <p className="card-text col-title mb-0 text-danger">
                                    Set Biiling Option in Settings
                                  </p>
                                )}

                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="1"
                                  min={1}
                                  name="hours"
                                  value={row.hours}
                                  onChange={(e) => handleHoursChange(e, index)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}
                </div>
                {FetchMeetingRoom?.length <= 0 && (
                  <div>
                    <p>No Meeting Room is Available</p>

                    <div
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => navigate("/floorPlans")}
                    >
                      Please Set Meeting Rooms
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="mt-4">
              <Button
                variant="primary"
                disabled={isUploading || apiSpinner}
                onClick={updateSpace}
              >
                Save Changes{" "}
                {isUploading || apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <button
                className="btn btn-secondary ml-3"
                disabled={apiSpinner}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
          
          </Modal.Footer> */}
        </form>
      </Modal>
    </>
  );
};

export default UpdatePackagePopUp;
