import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  NewconfirmationAlert,
  errorAlertImage,
  requiredAlert,
} from "../../utils/Alerts";
import * as Icon from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import Select from "react-select";
import { colorStyles } from "../../utils/constant";
import { useGetSkillsQuery } from "../../Slices/constantApi";
import { useCreateUserForCompanyMutation } from "../../Slices/spaceApi";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
const UserForCompany = ({
  companyId,
  companyName,
  memberCount,
  packageCount,
}) => {
  // const { data: skills } = useGetSkillsQuery();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [createUserForCompany, resultStats] = useCreateUserForCompanyMutation();
  const [isProfileImageUploading, setIsProfileImageUploading] = useState(false);
  const [isCnicUploading, setIsCnicUploading] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [userData, setUserData] = useState({
    id: companyId,
    email: "",
    name: "",
    phoneNo: "",
    cnicNo: "",

    description: "",
    profileImg: "",
    cnicImage: "",

    experienceYears: null,
    address: "",
  });
  const [allDone, setAllDone] = useState(false);

  const [validation, setValidation] = useState({
    name: false,
    email: false,
    phoneNo: false,
    // cnicNo: false,
    // experienceYears: false,
    // description: false,
    // address: false,
    // profileImg: false,
    // cnicImage: false,
  });
  const [show, setShow] = useState(false);

  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);

  const [previousForm, setPreviousForm] = useState(true);
  const handleClose = () => {
    setIsProfileImageUploading(false);
    setIsCnicUploading(false);
    setShow(false);
    resetForm();
  };
  const handleShow = () => {
    if (memberCount >= packageCount) {
      NewconfirmationAlert(
        "Your memebers count is exceeding the quantity of packages you have!",
        "Do you want to add new employee?",
        "Yes! Add",
        "No! Cancel"
      ).then((yes) => {
        if (yes) {
          setShow(true);
        }
      });
    } else {
      setShow(true);
    }
  };

  const handle = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const addItem = (e) => {
    e.preventDefault();
    setUserData({
      ...userData,
      educations: [
        ...userData.educations,
        {
          degree: "",
          institute: "",
          startYear: "",
          endYear: "",
          description: "",
        },
      ],
    });
  };

  const handleFocus = (e) => {
    setValidation({ ...validation, [e.target.name]: true });
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      !userData?.name?.length ||
      !userData?.phoneNo?.length ||
      !userData?.email ||
      !emailRegex?.test(userData?.email)
      // ||
      // !userData?.experienceYears ||
      // !userData?.address
    ) {
      setAllDone(true);
    } else {
      setAllDone(false);
    }
  }, [userData]);

  // const addCertificaions = (e) => {
  //   e.preventDefault();
  //   setUserData({
  //     ...userData,
  //     certifications: [
  //       ...userData.certifications,
  //       { image: "", name: "", year: "" },
  //     ],
  //   });
  // };

  // const handleFormChange = (index, event) => {
  //   let edu = [...userData.educations];
  //   edu[index][event.target.name] = event.target.value;
  //   setUserData({ ...userData, educations: edu });
  // };

  // const handleFormCertifications = (index, event) => {
  //   let cert = [...userData.certifications];
  //   cert[index][event.target.name] = event.target.value;
  //   setUserData({ ...userData, certifications: cert });
  // };

  // const uploadImg = (index, e) => {
  //   let cert = [...userData.certifications];
  //   const files = e.target.files;
  //   cert[index].image = URL.createObjectURL(files[0]);

  //   setUserData({
  //     ...userData,
  //     certifications: cert,
  //   });

  //   const formData = new FormData();
  //   formData.append("img", files[0]);
  //   axios.post("/uploadImage/", formData).then((resp) => {
  //     cert[index].image = resp.data;
  //     setUserData({
  //       ...userData,
  //       certifications: cert,
  //     });
  //   });
  // };

  // const removeItem = (index) => {
  //   const list = [...userData.educations];
  //   list.splice(index, 1);
  //   setUserData({ ...userData, educations: list });
  // };

  // const removeCertifications = (index) => {
  //   const list = [...userData.certifications];
  //   list.splice(index, 1);
  //   setUserData({ ...userData, certifications: list });
  // };

  // const profileUrls = (e) => {
  //   setUserData({
  //     ...userData,
  //     profileUrls: [
  //       {
  //         ...userData.profileUrls[0],
  //         [e.target.name]: {
  //           url: e.target.value,
  //           public: "",
  //         },
  //       },
  //     ],
  //   });
  // };

  const ali = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  const ali2 = (e) => {
    e.preventDefault();
    document.getElementById("real-file2").click();
  };

  const ali3 = (e) => {
    e.preventDefault();
    document.getElementById("real-file3").click();
  };
  const [uploadedImageUrls, setUploadedImageUrls] = useState(null);
  const [uploadedImageUrls2, setUploadedImageUrls2] = useState(null);

  useEffect(() => {
    if (file !== null && file !== "") {
      setIsProfileImageUploading(true);

      if (file.size >= 1e7) {
        errorAlertImage("File is too big!");
        setFile(null);
        setIsProfileImageUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUploadedImageUrls(resp.data?.[0]);
            setIsProfileImageUploading(false);
          })
          .catch((error) => {
            setIsProfileImageUploading(false);

            setFile(null);
          });
      } else {
        setFile(null);
        requiredAlert("Please Select Image File");
        setIsProfileImageUploading(false);
      }
    }
  }, [file]);
  useEffect(() => {
    setUserData({ ...userData, profileImg: uploadedImageUrls });
  }, [uploadedImageUrls]);

  useEffect(() => {
    if (file2 !== null && file2 !== "") {
      setIsCnicUploading(true);
      if (file2.size >= 1e7) {
        errorAlertImage("File is too big!");
        setFile2(null);
        setIsCnicUploading(false);
      } else if (file2.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file2);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUploadedImageUrls2(resp.data);
            setIsCnicUploading(false);
          })
          .catch((error) => {
            setIsCnicUploading(false);

            setFile2(null);
          });
      } else {
        setFile2(null);
        requiredAlert("Please Select Image File");
        setIsCnicUploading(false);
      }
    }
  }, [file2]);
  useEffect(() => {
    setUserData({ ...userData, cnicImage: uploadedImageUrls2 });
  }, [uploadedImageUrls2]);

  // useEffect(() => {
  //   if (!file3 == "") {
  //     if (file3.size >= 1e7) {
  //       errorAlertImage("File is too big!");
  //       setFile3("");
  //     } else {
  //     }
  //   }
  //   const fd = new FormData();
  //   fd.append("image", file3);
  //   axios.post("/uploadImage/", fd).then((resp) => {
  //     setUserData({ ...userData, domicileImage: resp.data });
  //   });
  // }, [file3]);

  // const handleSkills = (e) => {
  //   setUserData({
  //     ...userData,
  //     skills: Array.isArray(e) ? e.map((x) => x.id) : [],
  //   });
  // };

  const submitForm = (e) => {
    e.preventDefault();
    const updatedData = {
      companyId: parseInt(userData?.id),
      email: userData.email,
      name: userData.name,
      phoneNo: userData?.phoneNo,
      cnicNo: parseInt(userData?.cnicNo),

      description: userData.description,
      profileImg: userData.profileImg,
      cnicImage: userData?.cnicImage?.[0],

      experienceYears: parseInt(userData.experienceYears),
      address: userData.address,
      company: false,
    };
    setApiSpinner(true);
    createUserForCompany({
      spaceId: spaceId || currentSpaceId,
      data: updatedData,
    });
  };

  // const upload = (index, e) => {
  //   e.preventDefault();
  //   document.getElementById(`file${index}`).click();
  // };
  const resetForm = () => {
    setUserData({
      id: companyId,
      email: "",
      name: "",
      phoneNo: "",
      cnicNo: "",
      description: "",
      profileImg: "",
      cnicImage: "",
      experienceYears: "",
      address: "",
    });
    setValidation({
      name: false,
      email: false,
      phoneNo: false,
      // address: false,
      // profileImg: false,
      // experienceYears: false,
    });
    setFile("");
    setFile2("");
    setFile3("");
  };
  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );
      if (resultStats?.error?.data?.phoneNo) {
        requiredAlert(`Phone No: ${errorMessage}`);
      } else {
        requiredAlert(errorMessage);
      }

      // requiredAlert(
      //   `Phone No: ${resultStats?.error?.data?.phoneNo?.[0]}` ||
      //     resultStats?.error?.data?.message
      // );
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("User Created SuccessFully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      resetForm();
      handleClose();

      // navigate("/quotation/list");
    }
  }, [resultStats]);

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="send-invoice-btn"
      >
        + Add Employee
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        className={previousForm ? null : "largeForm"}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">
            Add Employee for <b>{companyName}</b>
          </Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <section style={{ display: previousForm ? null : "none" }}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  value={userData?.name}
                  onChange={handle}
                  required
                  onBlur={handleFocus}
                  focused={validation.name.toString()}
                />
                <span className="error">Name Must Have Alphabets!</span>
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email"
                  name="email"
                  value={userData.email}
                  onChange={handle}
                  required
                  onBlur={handleFocus}
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  focused={validation.email.toString()}
                />
                <span className="error">Write a correct email!</span>
              </div>

              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <PhoneInput
                  placeholder="Enter 11-15 Digits Phone Number"
                  // name="phoneNo"
                  value={userData?.phoneNo}
                  // onChange={handleInfo}
                  // min={0}
                  required
                  // min={0}
                  // focused={validation?.phoneNo?.toString()}
                  // onBlur={handleFocus}
                  // pattern="\d{11,15}"
                  onChange={(e) => {
                    setUserData({ ...userData, phoneNo: e });
                  }}
                />
                {/* <input
                  type="number"
                  className="form-control"
                  placeholder="Enter 11-15 Digits Phone Number"
                  name="phoneNo"
                  value={userData?.phoneNo}
                  onChange={handle}
                  min={0}
                  required
                  onBlur={handleFocus}
       
                  pattern="\d{11,15}"
                  onKeyDown={(e) => {
                    const allowedKeys = ["Backspace", "Delete"];
                    if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  focused={validation.phoneNo.toString()}
                /> */}
                <span className="error">Write 11-15 digits phone number!</span>
              </div>

              <div className="form-group">
                <label htmlFor="address">
                  Address <span className="optional">(Optional)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  name="address"
                  value={userData?.address}
                  onChange={handle}
                />
              </div>

              <div className="form-group">
                <label htmlFor="cnicNo">
                  ID Card Number <span className="optional">(Optional)</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="xxxxx-xxxxxxx-x"
                  name="cnicNo"
                  value={userData?.cnicNo}
                  onChange={handle}
                />
              </div>

              <div className="form-group">
                <label htmlFor="description">
                  About <span className="optional">(Optional)</span>
                </label>
                <textarea
                  rows={5}
                  placeholder="About . . ."
                  className="form-control"
                  name="description"
                  value={userData?.description}
                  onChange={handle}
                />
              </div>
              <div className="form-group">
                <label htmlFor="experienceYears">
                  Experience Years <span className="optional">(Optional)</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Experience Years"
                  name="experienceYears"
                  value={userData?.experienceYears}
                  onChange={handle}
                />
              </div>
              <div className="form-group">
                <label className="fs-6 mb-2">
                  Profile Picture <span className="optional">(Optional)</span>
                </label>
                <div className="media">
                  <div className="media-aside align-self-start">
                    <img
                      src={
                        file
                          ? URL.createObjectURL(file)
                          : "https://placehold.jp/90x90.png"
                      }
                      style={{
                        width: "90px",
                        height: "90px",
                        objectFit: "cover",
                      }}
                      className="rounded"
                    />
                  </div>
                  <div>
                    <input
                      id="real-file"
                      name="file"
                      type="file"
                      hidden="hidden"
                      onChange={(event) => setFile(event.target.files[0])}
                    />
                  </div>
                  <div className="media-body mt-2">
                    {isProfileImageUploading ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Uploading...</span>
                      </div>
                    ) : (
                      <button
                        id="custom-button"
                        onClick={ali}
                        className="btn btn-secondary me-2 btn-sm"
                      >
                        Upload
                      </button>
                    )}
                    <p className="card-text mt-2">
                      {/* Allowed Max size of 10Mb */}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">
                            Allowed Max size of 10Mb
                          </Tooltip>
                        }
                      >
                        <Button variant="white" className="mx-2 p-0">
                          <Icon.InfoCircle style={{ color: "currentcolor" }} />
                        </Button>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="fs-6 mb-2">
                  Cnic Picture <span className="optional">(Optional)</span>
                </label>
                <div className="media">
                  <div className="media-aside align-self-start">
                    <img
                      src={
                        file2
                          ? URL.createObjectURL(file2)
                          : "https://placehold.jp/90x90.png"
                      }
                      style={{
                        width: "90px",
                        height: "90px",
                        objectFit: "cover",
                      }}
                      className="rounded"
                    />
                  </div>
                  <div>
                    <input
                      id="real-file2"
                      name="file"
                      type="file"
                      hidden="hidden"
                      onChange={(event) => setFile2(event.target.files[0])}
                    />
                  </div>
                  <div className="media-body mt-2">
                    {isCnicUploading ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Uploading...</span>
                      </div>
                    ) : (
                      <button
                        id="custom-button"
                        onClick={ali2}
                        className="btn btn-secondary me-2 btn-sm"
                      >
                        Upload
                      </button>
                    )}
                    <p className="card-text mt-2">
                      {/* Allowed Max size of 10Mb */}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">
                            Allowed Max size of 10Mb
                          </Tooltip>
                        }
                      >
                        <Button variant="white" className="mx-2 p-0">
                          <Icon.InfoCircle style={{ color: "currentcolor" }} />
                        </Button>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button
              variant="secondary"
              disabled={apiSpinner}
              onClick={handleClose}
            >
              Close
            </Button> */}
            {/* {previousForm && ( */}
            <Button
              variant="primary"
              onClick={submitForm}
              disabled={
                isProfileImageUploading ||
                isCnicUploading ||
                apiSpinner ||
                allDone
              }
            >
              Add Employee{" "}
              {apiSpinner ? (
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "5px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UserForCompany;
