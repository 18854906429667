import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { PackageInputs, PackageFields } from "./";
import { toast, Toaster } from "react-hot-toast";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import axios from "axios";
import {
  useCreatePackageMutation,
  useGetMeetingRoomQuery,
  useGetRelatedMeetingRoomsQuery,
} from "../../Slices/spaceApi";
import makeAnimated from "react-select/animated";
import { useSelector } from "react-redux";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";

const PackageModal = ({
  showButton,
  FetchFacilities,
  FetchSpaceTypes,
  FetchSpaceDurations,
}) => {
  const { packagesPermissions, billingOption } = useGlobalContext();
  const [selectedSpaceTypes, setSelectedSpaceTypes] = useState([]);
  const navigate = useNavigate();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const animatedComponents = makeAnimated();
  const [createPackage, resultStats] = useCreatePackageMutation();
  const { isLoading: loading, data: FetchPackageData } = useGetMeetingRoomQuery(
    { id: spaceId || currentSpaceId, room_type: 1 }
  );
  const facilitiesArray = Array.isArray(FetchFacilities)
    ? FetchFacilities
    : [FetchFacilities];

  const { isLoading: relatedLoading, data: FetchMeetingRoom } =
    useGetRelatedMeetingRoomsQuery({ id: spaceId || currentSpaceId });

  const [show, setShow] = useState(null);
  const [file, setFile] = useState(null);
  const [imgError, setImgError] = useState(false);
  const [allDone, setAllDone] = useState(true);
  const [errorDurations, setErrorDurations] = useState(false);
  const [errorSpaceType, setErrorSpaceType] = useState(false);
  const [errorFacilities, setErrorFacilties] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [meetingRoomSelect, setMeetingRoomSelect] = useState(false);

  const uniqueLocations = Array.from(
    new Set(FetchMeetingRoom?.map((room) => room.space.name))
  );

  const [packageValues, setPackageValues] = useState({
    name: "",
    facilities: [],
    price: "",
    serviceCharges: "",
    rentalFee: "",
    person: "",
    hours: "",
    // spaceType: "",

    duration: "",
    inclusiveTax: true,
    images: null,
    meetingRoomAllowedHours: [
      {
        meetingRoom: [],
        hours: 0,
      },
    ],
  });

  const handleClose = () => {
    setPackageValues({
      ...packageValues,
      name: "",
      facilities: [],
      price: "",
      serviceCharges: "",
      rentalFee: "",
      person: "",
      images: "",
      inclusiveTax: true,
      // spaceType: "",
      duration: "",

      meetingRoomAllowedHours: [],
    });
    setRows([
      {
        meetingRoom: [],
        hours: 0,
      },
    ]);
    setFile("");
    setIsUploading(false);
    setShow(false);
    setSubmitted(false);
    setErrorDurations(false);
    setErrorFacilties(false);
    setErrorSpaceType(false);
    // setImgError(false);
  };
  const handleApprovePublicBooking = () => {
    setMeetingRoomSelect((prevValue) => {
      const newValue = !prevValue;
      return newValue;
    });
    // setMeetingRoomSelect(true);
  };
  const [rows, setRows] = useState([
    {
      selectedLocation: null,
      selectedMeetingRooms: [],
      hours: 0,
    },
  ]);

  const handleInclusiveTax = (event) => {
    setPackageValues({
      ...packageValues,
      inclusiveTax: event.target.checked,
    });
  };

  const handleAddRow = () => {
    const newRow = {
      selectedLocation: null,
      selectedMeetingRooms: [],
      hours: 0,
    };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleLocationChange = (event, rowIndex) => {
    const selectedLocation = event.target.value;
    const updatedRows = [...rows];
    updatedRows[rowIndex].selectedLocation = selectedLocation;
    setRows(updatedRows);
  };

  const handleMeetingRoomChange = (selectedOptions, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].selectedMeetingRooms = selectedOptions;
    setRows(updatedRows);
  };

  const handleHoursChange = (e, rowIndex) => {
    const inputValue = e.target.value;

    if (
      inputValue.includes("e") ||
      inputValue.includes("-") ||
      inputValue.includes("+")
    ) {
      return;
    }

    const updatedRows = [...rows];
    updatedRows[rowIndex].hours = e.target.value;
    setRows(updatedRows);
  };
  const handleShow = () => setShow(true);

  const uploadFile = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  const onchange = (e) => {
    // if (
    //   e.target.name == "serviceCharges" ||
    //   e.target.name == "rentalFee" ||
    //   e.target.name == "person"
    // ) {
    //   console.log("First condition meet");
    //   if (
    //     e.target.value == "e" ||
    //     e.target.value == "-" ||
    //     e.target.value == "+"
    //   ) {
    //     console.log("second condition meet");
    //     return;
    //   }
    // }

    setPackageValues({ ...packageValues, [e.target.name]: e.target.value });
  };

  // const handle = (e) => {
  //   setPackageValues({
  //     ...packageValues,
  //     facilities: Array.isArray(e) ? e.map((x) => x.id) : [],
  //   });
  // };
  const handle = (e) => {
    if (e.some((option) => option.id === "all")) {
      // If "All" is selected, set the facilities to all available facilities
      setPackageValues({
        ...packageValues,
        facilities: facilitiesArray?.map((facility) => facility.id),
      });
    } else {
      // Otherwise, update the selected facilities
      setPackageValues({
        ...packageValues,
        facilities: e.map((x) => x?.id),
      });
    }
  };

  const handleDuration = (e) => {
    setPackageValues({ ...packageValues, duration: e.id });
  };

  // const handleType = (e) => {
  //   setPackageValues({ ...packageValues, spaceType: e.id });
  // };

  const onBlurDuration = () => {
    if (packageValues.duration === "") {
      setErrorDurations(true);
    }
  };

  const onBlurSpaceType = () => {
    if (packageValues.spaceType === "") {
      setErrorSpaceType(true);
    }
  };

  const onBlurFacilities = () => {
    if (!packageValues.facilities.length) {
      setErrorFacilties(true);
    }
    setIsOpen(false);
  };

  const onFocusSpaceType = (e) => {
    if (e.target.value == packageValues.spaceType) {
      setErrorSpaceType(false);
    }
  };

  const onFocusDuration = (e) => {
    if (e.target.value == packageValues.duration) {
      setErrorDurations(false);
    }
  };

  const onFocusFacilities = (e) => {
    if (e.target.value == packageValues.facilities) {
      setErrorFacilties(false);
    }
    setIsOpen(true);
  };

  useEffect(() => {
    if (
      !packageValues.duration == "" &&
      // packageValues.price.match("[1-9]+") &&
      !packageValues.spaceType == "" &&
      packageValues.person.match("[1-9]+") &&
      packageValues.facilities.length &&
      packageValues.name.match("[A-Za-z ][A-Za-z0-9!@#$%^&* ]*$")
    ) {
      setAllDone(false);
    } else {
      setAllDone(true);
    }
  }, [packageValues]);
  const [uploadedImageUrls, setUploadedImageUrls] = useState(null);

  useEffect(() => {
    if (file !== null && file !== "") {
      setIsUploading(true);
      if (file.size >= 1e7) {
        errorAlertImage("File is too big!");
        setFile(null);
        setIsUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUploadedImageUrls(resp.data);
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
      } else {
        setFile(null);
        requiredAlert("Please Select Image File");
        setIsUploading(false);
      }
    }
    // setImgError(false);
  }, [file]);

  useEffect(() => {
    setPackageValues({ ...packageValues, images: uploadedImageUrls });
  }, [uploadedImageUrls]);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
    // setImgError(false);
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      const errorMessage =
        resultStats &&
        resultStats?.error &&
        resultStats?.error?.data &&
        Object?.values(resultStats?.error?.data).find(
          (error) => error?.length > 0 || "An Error Occured"
        );

      requiredAlert(errorMessage || "An Error Occured");
      // const errorMessage =
      //   Object &&
      //   Object?.values(resultStats?.error?.data)?.find(
      //     (error) => error?.length > 0 || "An Error Occured"
      //   );
      // requiredAlert(errorMessage || "An Error Occured");
    }
    if (resultStats?.isSuccess) {
      // setTimeout(() => {
      handleClose();
      // }, 1500);
      setApiSpinner(false);

      toast.success("Package Added SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  const postPackageRoom = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    const isDurationValid = packageValues?.duration !== "";
    const isSpaceTypeValid = packageValues?.spaceType !== "";
    const isFacilitiesValid = packageValues?.facilities.length > 0;
    const isFileValid = file && packageValues?.images;

    setErrorDurations(!isDurationValid);
    setErrorSpaceType(!isSpaceTypeValid);
    setErrorFacilties(!isFacilitiesValid);
    if (
      packageValues?.serviceCharges < 0 ||
      packageValues?.rentalFee < 0 ||
      packageValues?.person < 0
    ) {
      return;
    }
    const meetingRoomAllowedHours = rows?.map((row) => ({
      meetingRoom: row?.selectedMeetingRooms?.map((item) => item.id),

      hours: row?.hours || 0,
    }));
    const SpaceTypes = spaceTypeRows
      ?.map((item) => ({
        quantity: parseInt(item.quantity),
        type: parseInt(item.type),
      }))
      .filter((item) => item.quantity !== 0 && item.package !== 0);

    const updatedPackageValues = {
      ...packageValues,
      spaceTypes: SpaceTypes,
      meetingRoomAllowedHours,
    };
    setApiSpinner(true);

    createPackage({
      spaceId: spaceId || currentSpaceId,
      data: updatedPackageValues,
    });
  };

  const [spaceTypeRows, setSpaceTypeRows] = useState([
    {
      type: FetchSpaceTypes?.[0]?.id,
      quantity: 1,
    },
  ]);
  console.log("Space Types are ", spaceTypeRows);
  const handleSpaceTypeAdd = () => {
    const newRow = {
      type: FetchSpaceTypes?.[0]?.id,
      quantity: 1,
    };
    setSpaceTypeRows([...spaceTypeRows, newRow]);
  };
  const handleRemoveSpaceTypeRow = (index) => {
    const updatedRows = [...spaceTypeRows];
    updatedRows.splice(index, 1);
    setSpaceTypeRows(updatedRows);
  };

  const handleSpaceTypeInputChange = (event, index) => {
    let data = [...spaceTypeRows];
    data[index].type = event.id;
    setSpaceTypeRows(data);
  };
  const handleSpaceTypeQuantity = (event, index) => {
    let data = [...spaceTypeRows];
    data[index].quantity = event.target.value;
    setSpaceTypeRows(data);
  };
  console.log("Selected space type is", selectedSpaceTypes);
  return (
    <div className="d-flex">
      {showButton ? (
        <Button
          variant="primary"
          onClick={packagesPermissions[0]?.create ? handleShow : null}
          className="send-invoice-btn"
        >
          {packagesPermissions[0]?.create ? (
            <span className="invoice">
              <Plus /> Add Space Package
            </span>
          ) : (
            <span className="invoice">
              <Plus /> Add Space Package
              <Lock />
            </span>
          )}
        </Button>
      ) : (
        <p
          onClick={packagesPermissions[0]?.create ? handleShow : null}
          className="send-invoice-btn"
        >
          {packagesPermissions[0]?.create ? (
            <span className="invoice btn-link">
              <Plus /> Add Space Package
            </span>
          ) : (
            <span className="invoice">
              <Plus /> Add Space Package
              <Lock />
            </span>
          )}
        </p>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        id="packagePopUp"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="" closeButton>
          <Modal.Title as="h5">Add Space Package</Modal.Title>
        </Modal.Header>
        <small className="fw-bold ml-3 mt-2">Make Package for Your Space</small>
        <form>
          <Modal.Body>
            {/* {PackageInputs?.map((input, idx) => {
              return (
                <PackageFields
                  submitted={submitted}
                  key={input.id}
                  {...input}
                  value={packageValues[input.name]}
                  onChange={onchange}
                  index={idx}
                />
              );
            })} */}
            <div className="row">
              <div className="col-12">
                {PackageInputs?.map((input, idx) => {
                  if (input.id === 1) {
                    return (
                      <PackageFields
                        submitted={submitted}
                        key={input.id}
                        {...input}
                        value={packageValues[input.name]}
                        onChange={onchange}
                        index={idx}
                      />
                    );
                  }
                })}
              </div>
            </div>

            <div className="row">
              {PackageInputs?.map((input, idx) => {
                if (input.id === 2 || input.id === 3) {
                  return (
                    <div className="col-12 col-md-6">
                      <PackageFields
                        submitted={submitted}
                        key={input.id}
                        {...input}
                        value={packageValues[input.name]}
                        onChange={onchange}
                        index={idx}
                      />
                    </div>
                  );
                }
              })}
            </div>

            <div className="custom-formcheck form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="inclusiveTax"
                checked={packageValues?.inclusiveTax}
                onChange={(e) => handleInclusiveTax(e)}
              />
              <label className="form-check-label" htmlFor="inclusiveTax">
                Price includes state Taxes
              </label>
            </div>

            <div className="row">
              {PackageInputs?.map((input, idx) => {
                if (input.id === 4) {
                  return (
                    <div className="col-12 col-md-6">
                      <PackageFields
                        submitted={submitted}
                        key={input.id}
                        {...input}
                        value={packageValues[input.name]}
                        onChange={onchange}
                        index={idx}
                      />
                    </div>
                  );
                }
              })}

              {FetchSpaceDurations?.length > 0 && (
                <div className="col-12 col-md-6 mb-2">
                  <label htmlFor="durations" className="mb-1">
                    Durations
                  </label>
                  <Select
                    className={errorDurations ? "reactSelectActive" : ""}
                    options={FetchSpaceDurations}
                    getOptionLabel={(option) => option.spaceDuration}
                    getOptionValue={(option) => option.id}
                    styles={colorStyles}
                    value={FetchSpaceDurations?.find(
                      (obj) => obj.id === packageValues.duration
                    )}
                    onBlur={onBlurDuration}
                    placeholder={"Select Duration"}
                    id="durations"
                    onChange={handleDuration}
                    onFocus={onFocusDuration}
                  />
                  {errorDurations && (
                    <span className="e">Select The Durations</span>
                  )}
                </div>
              )}
            </div>

            {/* {FetchSpaceDurations?.length > 0 && (
              <div className="mb-2">
                <label htmlFor="durations" className="mb-1">
                  Durations
                </label>
                <Select
                  className={errorDurations ? "reactSelectActive" : ""}
                  options={FetchSpaceDurations}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  styles={colorStyles}
                  value={FetchSpaceDurations?.find(
                    (obj) => obj.id === packageValues.duration
                  )}
                  onBlur={onBlurDuration}
                  placeholder={"Select Duration"}
                  id="durations"
                  onChange={handleDuration}
                  onFocus={onFocusDuration}
                />
                {errorDurations && (
                  <span className="e">Select The Durations</span>
                )}
              </div>
            )} */}

            {FetchSpaceTypes?.length > 0 && (
              <div className="mb-2">
                <label htmlFor="space-type" className="mb-1">
                  Space Type
                </label>
                {/* <Select
                  className={errorSpaceType ? "reactSelectActive" : ""}
                  options={FetchSpaceTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  styles={colorStyles}
                  value={FetchSpaceTypes?.find(
                    (obj) => obj.id === packageValues?.spaceType
                  )}
                  onBlur={onBlurSpaceType}
                  onChange={handleType}
                  onFocus={onFocusSpaceType}
                  placeholder={"Select Space Type"}
               
                /> */}
                {spaceTypeRows?.map((input, index) => (
                  <div key={index} className=" my-2">
                    <div className="">
                      <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                        <div className="row w-100 pe-lg-0 pe-3">
                          <div className="col-12 col-md-6 mb-lg-0 mb-2 mt-lg-0">
                            <p className="card-text col-title mb-md-50 mb-0">
                              Select Space Type
                            </p>
                            {/* <select
                                  name="packageId"
                                  value={input?.packageId}
                                  onChange={(e) => handleInputChange(e, index)}
                                  className="form-control"
                                >
                                  {FetchPackageData?.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select> */}
                            <Select
                              name="packageId"
                              options={FetchSpaceTypes}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              styles={colorStyles}
                              value={FetchSpaceTypes?.find(
                                (obj) => obj.id === spaceTypeRows?.[index]?.type
                              )}
                              onChange={(selectedOption) =>
                                handleSpaceTypeInputChange(
                                  selectedOption,
                                  index
                                )
                              }
                            />
                          </div>

                          <div className="col-12 col-md-6 mb-lg-0 mb-2 mt-lg-0">
                            <p className="card-text col-title mb-0">Quantity</p>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="1"
                              min={1}
                              name="quantity"
                              value={input?.quantity}
                              onChange={(e) =>
                                handleSpaceTypeQuantity(e, index)
                              }
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                          {index != 0 && (
                            <p
                              className="bg-white"
                              onClick={() => handleRemoveSpaceTypeRow(index)}
                            >
                              <CancelIcon />
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-link "
                  onClick={handleSpaceTypeAdd}
                >
                  Associate More Type
                </button>
                {/* {errorSpaceType && (
                  <span className="e">Select The SpaceType</span>
                )} */}
              </div>
            )}

            <div className="mb-2">
              <label htmlFor="package-facilities" className="mb-1">
                Facilities (Select Multiple)
              </label>
              {FetchFacilities ? (
                <Select
                  className={errorFacilities ? "reactSelectActive" : ""}
                  options={[
                    // Add the "All" option
                    { id: "all", name: "All" },
                    ...facilitiesArray,
                  ]}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  styles={colorStyles}
                  value={facilitiesArray?.filter((obj) =>
                    packageValues?.facilities?.includes(obj?.id)
                  )}
                  menuIsOpen={isOpen}
                  onChange={handle}
                  onBlur={onBlurFacilities}
                  isMulti
                  id="package-facilities"
                  onFocus={onFocusFacilities}
                  placeholder={"Select Facilities"}
                />
              ) : (
                <div
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => navigate("/setting/accountsetting")}
                >
                  Please Set Facilities in Settings
                </div>
              )}

              {errorFacilities && (
                <span className="e">Select The Facility</span>
              )}
            </div>
            <div className="mb-2">
              <label htmlFor="name">
                Image <span className="optional">(Optional)</span>
              </label>

              <div className="media">
                <div className="media-aside align-self-start">
                  <img
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : "https://placehold.jp/90x90.png"
                    }
                    style={{
                      width: "90px",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    className="rounded"
                  />
                </div>
                <div>
                  <input
                    id="real-file"
                    name="file"
                    type="file"
                    hidden="hidden"
                    onChange={handleFileChange}
                  />
                </div>

                <div className="media-body mt-2">
                  {isUploading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Uploading...</span>
                    </div>
                  ) : (
                    <button
                      id="custom-button"
                      onClick={uploadFile}
                      className="btn btn-secondary me-2 btn-sm"
                    >
                      Upload
                    </button>
                  )}
                  <p className="card-text mt-2">
                    <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "}
                    Allowed Max size of 10Mb
                    {/* <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">Allowed Max size of 10Mb</Tooltip>
                      }
                    >
                      <Button variant="white" className="mx-2 p-0">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />
                      </Button>
                    </OverlayTrigger> */}
                  </p>
                  {imgError ? (
                    <div className="invalid-feedback">
                      Please select an image.
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <hr />

            {FetchMeetingRoom?.length > 0 && (
              <>
                <div className="d-flex mt-4" style={{ cursor: "pointer" }}>
                  <p htmlFor="" className="">
                    Add Meeting Rooms
                  </p>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch2"
                      label=""
                      className="ml-2"
                      value={meetingRoomSelect}
                      checked={meetingRoomSelect}
                      onChange={handleApprovePublicBooking}
                    />
                  </Form>
                </div>
                {meetingRoomSelect && (
                  <>
                    <div className="form-group">
                      <label htmlFor="package-facilities" className="mb-1">
                        Meeting Room (Select Multiple)*
                      </label>
                      {FetchMeetingRoom?.length > 0 &&
                        rows?.map((row, index) => (
                          <div key={index} className="repeater-wrapper my-2">
                            <div className="">
                              <p className="card-text col-title mb-md-50 mb-0 ml-3">
                                Select Location
                              </p>

                              <select
                                onChange={(e) => handleLocationChange(e, index)}
                                value={row.selectedLocation}
                                className="form-control col-10 ml-3"
                              >
                                <option value="">All Locations</option>
                                {uniqueLocations?.map((location) => (
                                  <option key={location} value={location}>
                                    {location}
                                  </option>
                                ))}
                              </select>
                              <div className="col-12 d-flex mt-2 product-details-border position-relative pe-0 pl-3 fs-6">
                                <div className="row w-100 pe-lg-0 pe-3">
                                  <div className=" col-12 mb-lg-0 mb-2 mt-lg-0">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Select allowed meeting rooms
                                    </p>
                                    <Select
                                      options={
                                        !row.selectedLocation
                                          ? FetchMeetingRoom
                                          : FetchMeetingRoom?.filter(
                                              (room) =>
                                                room.space.name ===
                                                row.selectedLocation
                                            )
                                      }
                                      getOptionLabel={(option) => option.title}
                                      getOptionValue={(option) => option.id}
                                      closeMenuOnSelect={false}
                                      styles={colorStyles}
                                      value={row.selectedMeetingRooms}
                                      onChange={(selectedOptions) =>
                                        handleMeetingRoomChange(
                                          selectedOptions,
                                          index
                                        )
                                      }
                                      isMulti
                                      id={`space-package-${index}`}
                                    />
                                  </div>

                                  <div className="col-12  my-lg-0 my-2">
                                    {billingOption == 1 ? (
                                      <p className="card-text col-title mb-0">
                                        Add hours for this package
                                      </p>
                                    ) : billingOption == 2 ? (
                                      <p className="card-text col-title mb-0">
                                        Add coins for this package
                                      </p>
                                    ) : (
                                      <p className="card-text col-title mb-0 text-danger">
                                        Set Biiling Option in Settings
                                      </p>
                                    )}

                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="1"
                                      min={1}
                                      name="hours"
                                      value={row.hours}
                                      onChange={(e) =>
                                        handleHoursChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))}
                    </div>
                    {FetchMeetingRoom?.length <= 0 && (
                      <div>
                        <p>No Meeting Room is Available</p>

                        <div
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => navigate("/floorPlans")}
                        >
                          Please Set Meeting Rooms
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            <div className="mt-4">
              <Button
                variant="primary"
                disabled={
                  isUploading ||
                  apiSpinner ||
                  !packageValues?.name ||
                  !packageValues?.serviceCharges ||
                  !packageValues?.rentalFee ||
                  !packageValues?.person ||
                  !packageValues?.duration ||
                  // !packageValues?.spaceType ||
                  !packageValues?.facilities
                }
                onClick={postPackageRoom}
              >
                Add Package
                {isUploading || apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>

              <button
                className="btn btn-secondary ml-3"
                disabled={apiSpinner}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
          
          </Modal.Footer> */}
        </form>
      </Modal>
    </div>
  );
};

export default PackageModal;
