import React, { useState, useEffect } from "react";
import { Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useSelector } from "react-redux";
import {
  useCreateLocationMutation,
  useUpdateLocationMutation,
} from "../../Slices/spaceApi";
import { toast } from "react-hot-toast";
import * as Icon from "react-bootstrap-icons";
const LocationForm = ({
  teamData: initialData,

  title,
  submitButtonText,
  onCloseModal,
  onOpenModal,
}) => {
  const { locationsPermissions, setUpdateLocation } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [locationAdd, resultStats] = useCreateLocationMutation();
  const [locationUpdate, resultStats1] = useUpdateLocationMutation();

  const [file, setFile] = useState("");
  // const [teamData, setTeamData] = useState(initialData);
  const [teamData, setTeamData] = useState({
    name: "",
    address: "",
    imageUrls: "",
    description: "",
    capacity: 0,
  });

  const [uploadedImageUrls, setUploadedImageUrls] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    address: "",
    imageUrls: "",
    description: "",
    capacity: "",
  });

  useEffect(() => {
    if (resultStats1.isError == false && initialData?.id) {
      setTeamData(initialData);
      setUploadedImageUrls(initialData?.imageUrls);
    }
  }, [initialData]);

  useEffect(() => {
    if (file !== null && file !== "") {
      setIsUploading(true);

      if (file.size >= 1e7) {
        errorAlertImage("File is too big!");

        setIsUploading(false);

        setFile(null);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUploadedImageUrls(resp.data?.[0]);
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
        setValidationErrors({
          ...validationErrors,
          imageUrls: "",
        });
      } else {
        setFile(null);
        setIsUploading(false);

        requiredAlert("Wrong Formate: Please Select Image File");
      }
    }
  }, [file]);
  useEffect(() => {
    if (uploadedImageUrls !== null) {
      setTeamData({
        ...teamData,
        imageUrls: uploadedImageUrls,
      });
    }
  }, [uploadedImageUrls]);

  const handleClose = () => {
    setShow(false);
    onCloseModal();
    setErrorMessage("");
    setFile("");
    setUploadedImageUrls("");
    setValidationErrors({
      name: "",
      address: "",
      imageUrls: "",
      description: "",
      capacity: "",
    });
    setTeamData({
      name: "",
      address: "",
      imageUrls: "",
      description: "",
      capacity: 0,
    });
  };
  const handleOpen = () => {
    setShow(true);
    onOpenModal();
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }

    setErrorMessage("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const consecutiveSpacesPattern = / {3,}/;
    if (name === "name") {
      // Define the regular expression pattern for allowed characters
      const allowedCharactersPattern = /^[A-Za-z0-9.'#@$%&\/\s-]+$/;

      if (
        (value.length <= 50 &&
          allowedCharactersPattern.test(value) &&
          !consecutiveSpacesPattern.test(value)) ||
        value === ""
      ) {
        setTeamData({
          ...teamData,
          [name]: value,
        });
        setValidationErrors({
          ...validationErrors,
          [name]: "",
        });
      } else {
        if (consecutiveSpacesPattern.test(value)) {
          setValidationErrors({
            ...validationErrors,
            [name]: "Please avoid more than 2 consecutive spaces",
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            [name]:
              "Invalid characters or exceeds 50 characters in the name field",
          });
        }
      }
    } else if (name === "address") {
      if (
        (value.length <= 150 && !consecutiveSpacesPattern.test(value)) ||
        value === ""
      ) {
        setTeamData({
          ...teamData,
          [name]: value,
        });
        setValidationErrors({
          ...validationErrors,
          [name]: "",
        });
      } else {
        if (consecutiveSpacesPattern.test(value)) {
          setValidationErrors({
            ...validationErrors,
            [name]: "Please avoid more than 2 consecutive spaces",
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            [name]: "Exceeds Character Limit:(150 Maximum)",
          });
        }
      }
    } else if (name === "description") {
      if (
        (value.length <= 250 && !consecutiveSpacesPattern.test(value)) ||
        value === ""
      ) {
        setTeamData({
          ...teamData,
          [name]: value,
        });
        setValidationErrors({
          ...validationErrors,
          [name]: "",
        });
      } else {
        if (consecutiveSpacesPattern.test(value)) {
          setValidationErrors({
            ...validationErrors,
            [name]: "Please avoid more than 2 consecutive spaces",
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            [name]: "Exceeds Character Limit:(250 Maximum)",
          });
        }
      }
    } else if (name === "capacity") {
      if (value <= 5000 && value >= 0) {
        setTeamData({
          ...teamData,
          [name]: value,
        });
        setValidationErrors({
          ...validationErrors,
          [name]: "",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: "Capacity Must Be 0-5000",
        });
      }
    } else {
      setTeamData({
        ...teamData,
        [name]: value,
      });
      setValidationErrors({
        ...validationErrors,
        [name]: "",
      });
    }
  };

  const handleSubmitAdd = (event) => {
    event.preventDefault();

    const { name, imageUrls, address, description, capacity } = teamData;

    let formIsValid = true;

    const newValidationErrors = {
      imageUrls: imageUrls ? "" : "Please Select an Image",
      name: name ? "" : "Please Enter Location's Name",
      address: address ? "" : "Please Enter Location's Address",
    };

    Object.keys(newValidationErrors).forEach((fieldName) => {
      if (newValidationErrors[fieldName] !== "") {
        formIsValid = false;
      }
    });
    if (formIsValid) {
      setApiSpinner(true);

      locationAdd({
        spaceId: spaceId || currentSpaceId,
        locationData: teamData,
      });
    } else {
      setValidationErrors(newValidationErrors);
    }
  };

  const updateLocation = (event) => {
    event.preventDefault();

    const { id, name, imageUrls, address, description, capacity } = teamData;

    let formIsValid = true;

    const newValidationErrors = {
      imageUrls: imageUrls ? "" : "Please Select an Image",
      name: name ? "" : "Please Enter Location's Name",
      address: address ? "" : "Please Enter Location's Address",
    };

    Object.keys(newValidationErrors).forEach((fieldName) => {
      if (newValidationErrors[fieldName] !== "") {
        formIsValid = false;
      }
    });
    if (formIsValid) {
      let updateData = {
        id,
        name,
        address,
        imageUrls,
        address,
        description,
        capacity,
      };
      if (file) {
        setApiSpinner(true);

        locationUpdate({
          spaceId: spaceId || currentSpaceId,
          id: updateData?.id,
          updateData: updateData,
        });
      } else {
        let filename = null;
        if (imageUrls) {
          const parts = imageUrls.split("/");
          const lastPart = parts[parts.length - 1];
          filename = lastPart.split("?")[0];
          if (filename.includes("%20")) {
            filename = filename.replace(/%20/g, " ");
          }
          updateData.imageUrls = filename;
          setApiSpinner(true);

          locationUpdate({
            spaceId: spaceId || currentSpaceId,
            id: updateData?.id,
            updateData: updateData,
          });
        }
      }
    } else {
      setValidationErrors(newValidationErrors);
    }
  };

  const uploadFile = () => {
    document.getElementById("real-file").click();
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      setUpdateLocation(false);
      requiredAlert(`Location ${resultStats?.error?.data?.error}`);
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Location Created SuccessFully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setUploadedImageUrls(null);
      setFile("");
      handleClose();
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats1?.isError) {
      setApiSpinner(false);

      requiredAlert(`Location ${resultStats1?.error?.data?.error}`);
    }
    if (resultStats1?.isSuccess) {
      setApiSpinner(false);
      setUpdateLocation(true);
      toast.success("Location Updated SuccessFully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setUploadedImageUrls(null);
      setFile("");
      handleClose();
    }
  }, [resultStats1]);

  return (
    <>
      {title === "Add Location" ? (
        <Button
          variant="primary"
          onClick={locationsPermissions[0]?.create ? handleOpen : null}
          className="send-invoice-btn"
        >
          {locationsPermissions[0]?.create ? (
            <span className="invoice">
              <Plus /> {title}
            </span>
          ) : (
            <span className="invoice">
              <Plus /> {title}
              <Lock />
            </span>
          )}
        </Button>
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              {locationsPermissions[0]?.update ? (
                "Update Location"
              ) : (
                <span>
                  Update <Lock />
                </span>
              )}
            </Tooltip>
          }
        >
          <Button
            variant="white"
            className="mx-2 p-0"
            onClick={locationsPermissions[0]?.update ? handleOpen : null}
          >
            <EditIcon />
          </Button>
        </OverlayTrigger>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">{title}</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={title === "Add Location" ? handleSubmitAdd : updateLocation}
        >
          <Modal.Body>
            <section>
              <div>
                <Form.Group className="mb-2">
                  <label htmlFor="name">Profile Picture</label>

                  <div className="media">
                    <div className="media-aside align-self-start">
                      <img
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : uploadedImageUrls ||
                              "https://placehold.jp/90x90.png"
                        }
                        style={{
                          width: "90px",
                          height: "90px",
                          objectFit: "cover",
                        }}
                        className="rounded"
                      />
                    </div>
                    <div>
                      <input
                        id="real-file"
                        name="file"
                        type="file"
                        hidden
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="media-body mt-2">
                      {isUploading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Uploading...</span>
                        </div>
                      ) : (
                        <Button
                          variant="secondary"
                          onClick={uploadFile}
                          className="btn-sm"
                        >
                          Upload
                        </Button>
                      )}
                      <p className="card-text mt-2">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "}
                        &nbsp;Allowed Max size of 10Mb
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              Allowed Max size of 10Mb
                            </Tooltip>
                          }
                        >
                          <Button variant="white" className="mx-2 p-0">
                            <Icon.InfoCircle
                              style={{ color: "currentcolor" }}
                            />
                          </Button>
                        </OverlayTrigger> */}
                      </p>
                    </div>
                  </div>
                  {validationErrors.imageUrls && (
                    <small style={{ color: "red", marginLeft: "10px" }}>
                      *{validationErrors.imageUrls}
                    </small>
                  )}
                </Form.Group>

                <Form.Group className="mb-2">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Location XYZ"
                    value={teamData?.name}
                    onChange={handleInputChange}
                  />
                  {validationErrors.name && (
                    <small style={{ color: "red", marginLeft: "10px" }}>
                      *{validationErrors.name}
                    </small>
                  )}
                </Form.Group>

                <Form.Group className="mb-2">
                  <label htmlFor="email">Address</label>
                  <input
                    type="text"
                    name="address"
                    // required
                    className="form-control"
                    placeholder="ABC Street, XYZ Country"
                    value={teamData?.address}
                    onChange={handleInputChange}
                    // readOnly={isReadable}
                  />
                  {validationErrors.address && (
                    <small style={{ color: "red", marginLeft: "10px" }}>
                      *{validationErrors.address}
                    </small>
                  )}
                </Form.Group>

                <Form.Group className="mb-2">
                  <label htmlFor="moreInfo">
                    Description <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="This Location is..."
                    name="description"
                    value={teamData?.description}
                    onChange={handleInputChange}
                    // required
                  />
                  {validationErrors.description && (
                    <small style={{ color: "red", marginLeft: "10px" }}>
                      *{validationErrors.description}
                    </small>
                  )}
                </Form.Group>

                <Form.Group className="mb-2">
                  <label htmlFor="location">
                    Capacity <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="number"
                    // required
                    name="capacity"
                    className="form-control"
                    placeholder="Capacity"
                    min={0}
                    value={teamData?.capacity}
                    onChange={handleInputChange}
                  />
                  {validationErrors.capacity && (
                    <small style={{ color: "red", marginLeft: "10px" }}>
                      *{validationErrors.capacity}
                    </small>
                  )}
                </Form.Group>
              </div>
              <div className="mt-4">
                <Button
                  variant="primary"
                  disabled={
                    isUploading ||
                    apiSpinner ||
                    !teamData?.name ||
                    !teamData?.address
                  }
                  type="submit"
                >
                  {submitButtonText}{" "}
                  {isUploading || apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>
                <button
                  className="btn btn-secondary ml-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </section>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Form>
      </Modal>
    </>
  );
};

export default LocationForm;
