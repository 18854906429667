import React from "react";
import { Button, Col, Form, Modal, ModalBody, Row } from "react-bootstrap";
import { Check2, X } from "react-bootstrap-icons";

const ProductView = ({ handleClose, operateId, operateObject: data }) => {
  return (
    <div>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="w-10"
      >
        <Modal.Header closeButton>
          <Modal.Title> View Product </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3 mt-3">
            <div className="d-flex align-items-center">
              <h6 className="me-3 mb-0">Product Name:</h6>
              <p className="mb-0" id="Produvt Name" name="name">
                {data.name}
              </p>
            </div>
          </Row>
          <Row className="mb-3 mt-3">
            <div className="d-flex align-items-center">
              <h6 className="me-3 mb-0">Price:</h6>
              <p className="mb-0" id="Price" name="name">
                {data.price}
              </p>
            </div>
          </Row>
          <Row className="mb-3 mt-3">
            <div className="d-flex align-items-center">
              <h6 className="me-3 mb-0">Active:</h6>
              <p className="mb-0" id="Price" name="name">
                {data.active ? "yes" : "No"}
              </p>
            </div>
          </Row>
          <Row className="mb-3 mt-3">
            <div className="d-flex align-items-center">
              <h6 className="me-3 mb-0">Add On Purchase:</h6>
              <p className="mb-0" id="Price" name="name">
                {data.addOnPurchase ? "yes" : "No"}
              </p>
            </div>
          </Row>
          <Row className="mb-3 mt-3">
            <div className="d-flex align-items-center">
              <h6 className="me-3 mb-0">One Off Purchase:</h6>
              <p className="mb-0" id="Price" name="name">
                {data.oneOffPurchase ? "yes" : "No"}
              </p>
            </div>
          </Row>
          <Row className="mb-3 mt-3">
            <div className="d-flex align-items-center">
              <h6 className="me-3 mb-0"> Manage Stock:</h6>
              <p className="mb-0" id="Price" name="name">
                {data.manageStock ? "yes" : "No"}
              </p>
            </div>
          </Row>
          <Row className="mb-3 mt-3">
            <div className="d-flex align-items-center">
              <h6 className="me-3 mb-0">Quantity:</h6>
              <p className="mb-0" id="AssettName" name="name">
                {data.quantity || 0}
              </p>
            </div>
          </Row>
          <Row className="mb-3 mt-3">
            <Form.Group controlId="Account">
              <Form.Label>
                <h6>Description</h6>
              </Form.Label>
              <p>{data?.description}</p>
            </Form.Group>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductView;
