import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetAllSpaceUsersQuery,
  useGetCompaniesQuery,
  useGetFloorPlansQuery,
} from "../../Slices/spaceApi";
import { Pagination } from "react-bootstrap";
import Select from "react-select";

import { colorStyles } from "../../utils/constant";
import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import Companies from "./Companies";
import AddCompanyPopup from "./AddCompanyPopup";
import ViewCompany from "./ViewCompany";
import UpdateCompanyDetails from "./UpdateCompanyDetails";
import DeleteCompany from "./DeleteCompany";
import Loader from "../Loader/Loader";

const CompanyTab = ({
  FetchPackageData,
  FetchMeetingPackageData,
  SpaceTypes,
}) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const { data: FetchCompanies, isLoading: companiesLoading } =
    useGetCompaniesQuery({
      spaceId: spaceId || currentSpaceId,
      page_size: itemsPerPage.value,
      page: activePage,
    });
  //********************Following id for floor plan's room assign in future use************************** */
  // const { data: fetchFloorPlans, isLoading } = useGetFloorPlansQuery(
  //   spaceId || currentSpaceId
  // );
  const { items, requestSort, sortConfig } = useSortableData(
    FetchCompanies?.users || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);

  const handlePage = (e) => {
    setitemsPerPage(e);
    setActivePage(1);

    setLoading(true);
  };
  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];

  useEffect(() => {
    setLoading(false);
  }, [FetchCompanies]);
  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };
  const handle = (e) => {
    if (e.target.checked) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  return (
    // <section className="app-user-list">
    <div className="card">
      {/* <div className="row table-header align-items-center"> */}
      <div className="col-12 mt-4">
        <div className="d-flex justify-content-between ">
          <div className="col-12  col-md-4 col-lg-6">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Company"
              className="form-control"
            />
          </div>

          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
            <div className="ms-3">
              <AddCompanyPopup
                FetchPackageData={FetchPackageData}
                FetchMeetingPackageData={FetchMeetingPackageData}
                // FloorPlans={fetchFloorPlans}
                FloorPlans={[]}
                SpaceTypes={SpaceTypes}
              />
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div className="col-12 ">
        <div className="">
          <div className="col-12 d-flex justify-content-end">
            <label
              style={{
                display: "flex",
                whiteSpace: "nowrap",
                fontWeight: "normal",
              }}
            >
              <small className="mr-2 mt-2 title"> Show</small>
              <Select
                options={options}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={handlePage}
                value={itemsPerPage}
                id="pages-select"
                styles={colorStyles}
              />
              <small className="ml-2 mt-2"> entries</small>
            </label>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Name</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("userDetails:name")}
                      className={getClassNamesFor("userDetails:name")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("userDetails:name")}
                      className={getClassNamesFor("userDetails:name")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th>
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Email</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("userDetails:email")}
                      className={getClassNamesFor("userDetails:email")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("userDetails:email")}
                      className={getClassNamesFor("userDetails:email")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th>
              <th>Details</th>

              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {loading || companiesLoading ? (
              <tr>
                <td colSpan={8} className="fs-5">
                  <Loader height={6.6} />
                </td>
              </tr>
            ) : (
              ""
            )}
            {!loading && !companiesLoading && items?.length > 0 ? (
              items
                ?.slice()

                ?.filter((val) => {
                  if (search == "") {
                    return val;
                  } else if (
                    // val?.userDetails?.[0]?.name
                    val?.name.toLowerCase().includes(search.toLocaleLowerCase())
                  ) {
                    return val;
                  }
                })
                ?.map((item, idx) => {
                  let originalIndex = items.length - idx - 1;
                  return (
                    <Companies
                      key={idx}
                      items={item}
                      idx={originalIndex}
                      // status={status}
                      FetchPackageData={FetchPackageData}
                      FetchMeetingPackageData={FetchMeetingPackageData}
                    />
                  );
                })
            ) : !companiesLoading && items?.length == 0 ? (
              <tr>
                <td colSpan={5} className="text-left fs-5">
                  No Record !
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>

      {FetchCompanies?.totalPages > 1 && (
        <>
          <div className="m-0 border-bottom" />
          <div className="pagination-custom flex-column flex-lg-row">
            <p className="text-muted mb-0">
              Showing {FetchCompanies?.users.length} of{" "}
              {FetchCompanies?.totalCount} entires
            </p>
            <Pagination className="mt-lg-0 mt-2">
              <Pagination.Prev
                className="previous"
                onClick={() => {
                  setActivePage(activePage - 1);
                  setLoading(true);
                }}
                disabled={activePage === 1}
              >
                Previous
              </Pagination.Prev>
              {Array.from({ length: FetchCompanies?.totalPages }).map(
                (_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i + 1 === activePage}
                    onClick={
                      i + 1 === activePage
                        ? null
                        : () => handlePageSelect(i + 1)
                    }
                  >
                    {i + 1}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next
                className="next"
                onClick={() => {
                  setActivePage(activePage + 1);
                  setLoading(true);
                }}
                disabled={activePage === FetchCompanies?.totalPages}
              >
                Next
              </Pagination.Next>
            </Pagination>
          </div>
        </>
      )}
    </div>
    // </section>
  );
};

export default CompanyTab;
