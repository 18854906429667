import { useNavigate, useParams } from "react-router-dom";
import {
  ZoomMaximise,
  WheelZoom,
  ZoomMinimize,
  ArrowBack,
  Next,
  Previous,
  Edit,
} from "../../Assets/FloorPlanSvgs/Icon";
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import Loader from "../Loader/Loader";
import {
  useCreateFloorRoomsMutation,
  useDeleteFloorRoomMutation,
  useGetAllSpaceUsersQuery,
  useGetCompanyUsersQuery,
  useGetFloorPlansQuery,
  useGetFloorRoomsQuery,
  useGetSpecificFloorPlansQuery,
  useUpdateFloorRoomMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  errorAlertPermission,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { format, addDays, subDays } from "date-fns";
import DatePicker from "react-flatpickr";
import { useGlobalContext } from "../../Context/GlobalContext";
const handleDragStart = (event, type) => {
  event.dataTransfer.setData("text/plain", type);
};

const Shape = ({ type, handleDragStart }) => {
  return (
    <div
      draggable
      onDragStart={handleDragStart}
      style={{
        width: "30px",
        height: "30px",
        backgroundColor:
          type === "Dedicated Desk"
            ? "red"
            : type === "Hot Desk"
            ? "blue"
            : type === "square"
            ? "green"
            : "",
        margin: "10px",
        cursor: "move",
      }}
    ></div>
  );
};

const ImageArea = ({ floorImage, id, zoomLevel, dragSelectMode }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [status, setStatus] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const { data: FetchUsers, isLoading: loading } = useGetAllSpaceUsersQuery({
    spaceId: spaceId || currentSpaceId,
    statusVal: status
      ? { company: false, status: 2 }
      : { company: false, status: 3 },
  });
  const { data: fetchFloorPlans, isLoading } = useGetFloorPlansQuery(
    spaceId || currentSpaceId
  );
  // const { data: fetchFloorPlans, isLoading } = useGetFloorPlansQuery(
  //   spaceId || currentSpaceId
  // );
  const { data: FetchCompanies, isLoading: Companyloading } =
    useGetAllSpaceUsersQuery({
      spaceId: spaceId || currentSpaceId,
      statusVal: status
        ? { company: true, status: 2 }
        : { company: true, status: 3 },
    });

  const { data: companyUsers, isLoading: usersLoading } =
    useGetCompanyUsersQuery(
      {
        spaceId: spaceId || currentSpaceId,
        companyId: selectedCompany,
      },
      {
        skip: selectedCompany === null,
        refetchOnMountOrArgChange: true,
        query: {
          selectedCompany: selectedCompany,
        },
      }
    );

  const [createFloorRooms, resultStats] = useCreateFloorRoomsMutation();
  const [updateFloorRooms, resultStats2] = useUpdateFloorRoomMutation();
  const [deleteFloorRoom, resultStats1] = useDeleteFloorRoomMutation();
  const [showRoomEdit, setShowRoomEdit] = useState(false);
  const [shapes, setShapes] = useState([]);
  const imageAreaRef = useRef(null);
  const [selectedShape, setSelectedShape] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [imageWidth, setImageWidth] = useState(750);
  const [imageHeight, setImageHeight] = useState(360);

  const [check, setCheck] = useState(null);
  const [isOccupied, setIsOccupied] = useState(false);
  const [assignToIndividual, setAssignToIndividual] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [cursorOffset, setCursorOffset] = useState({ x: 0, y: 0 });
  const shapeSize = Math.min(imageWidth, imageHeight) * 0.1 * zoomLevel;
  const [changedShape, setChangedShape] = useState(null);
  const [deskOpen, setDeskOpen] = useState(false);
  const [assignDeskOpen, setAssignDeskOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [activeTab, setActiveTab] = useState("Desk");
  const [isDraggingInProgress, setIsDraggingInProgress] = useState(false);
  const [isShapeDragged, setIsShapeDragged] = useState(false);

  const handleShapeTouchStart = (event, index) => {
    event.stopPropagation();
    if (deskOpen || assignDeskOpen) {
      return;
    }
    setSelectedShape(index);
    const touch = event.touches[0]; // Get the first touch point
    const { left, top } = imageAreaRef.current.getBoundingClientRect();
    const x = (touch.clientX - left) / zoomLevel;
    const y = (touch.clientY - top) / zoomLevel;
    setCursorOffset({ x: x - shapes[index]?.x, y: y - shapes[index]?.y });
    setIsDragging(true);

    // Disable touchmove on the image area
    setIsDraggingInProgress(true);
    imageAreaRef.current.addEventListener("touchmove", disableTouchMove, {
      passive: false,
    });

    // Set isShapeDragged to true
    setIsShapeDragged(true);
  };

  const disableTouchMove = (event) => {
    if (isDraggingInProgress) {
      event.preventDefault();
    }
  };

  const handleShapeTouchMove = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    if (deskOpen || assignDeskOpen) {
      return;
    }
    if (selectedShape !== null) {
      const touch = event.touches[0]; // Get the first touch point
      const { left, top } = imageAreaRef.current.getBoundingClientRect();
      const x = (touch.clientX - left) / zoomLevel;
      const y = (touch.clientY - top) / zoomLevel;
      setShapes((prevShapes) =>
        prevShapes?.map((shape, idx) =>
          idx === selectedShape
            ? {
                ...shape,
                x: x - cursorOffset?.x,
                y: y - cursorOffset?.y,
                isChanged: true,
              }
            : shape
        )
      );
      setChangedShape(shapes[selectedShape]);
    }
  };

  const handleShapeTouchEnd = (index) => {
    handleImageAreaClick();

    setIsDraggingInProgress(false);
    // imageAreaRef.current.removeEventListener("touchmove", disableTouchMove);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleClose = () => {
    setModalShow(false);
  };
  const handleShow = () => {
    setModalShow(true);
  };

  const { data: roomsData, isLoading: spaceLoading } =
    useGetFloorRoomsQuery(id);

  const [postRoom, setPostRoom] = useState(false);

  useEffect(() => {
    if (roomsData) {
      const newShapes = roomsData.map((room) => ({
        ...room,
        type: room.room_type.id.toString(),
        x: room.x_axis,
        y: room.y_axis,
        isChanged: false,
      }));

      setShapes(newShapes);
    }
  }, [roomsData]);
  const handleCompanyAssign = (e) => {
    const selectedCompanyId = e.target.value;

    setSelectedCompany(selectedCompanyId);
  };
  const currentDate = new Date();

  const formattedDate = currentDate.toISOString();
  const floorRoomSubmit = (shape) => {
    const currentDate = new Date();

    const formattedDate = currentDate.toISOString();

    const newShapeData = {
      floor: parseInt(id),
      name:
        shape.type == 1
          ? "Dedicated Desk"
          : shape.type == 2
          ? "Hot Desk"
          : shape.type == 3
          ? "Private Office"
          : shape.type == 4
          ? "Meeting Room"
          : "",
      room_type: shape.type,
      x_axis: shape?.x,
      y_axis: shape?.y,
      availableFrom: formattedDate,
    };
  };
  const handleUpdateRoom = (changedShape) => {
    const updatedData = {
      x_axis: changedShape?.x,
      y_axis: changedShape?.y,
    };
    updateFloorRooms({
      id: changedShape?.id,
      updateData: updatedData,
    });
  };
  const [existingRooms, setExistingRooms] = useState([]);

  //Neighbourhood Starts
  const [selectedShapes, setSelectedShapes] = useState([]);
  const [selectionArea, setSelectionArea] = useState(null);

  const handleMouseDown = (event) => {
    if (!dragSelectMode) return;

    const containerRect = imageAreaRef.current.getBoundingClientRect();
    const startX = event.clientX - containerRect.left;
    const startY = event.clientY - containerRect.top;

    setSelectionArea({
      startX,
      startY,
      endX: startX,
      endY: startY,
    });
    setIsDragging(true);
  };

  const handleMouseMove = (event) => {
    if (!dragSelectMode || !selectionArea) return;

    const containerRect = imageAreaRef.current.getBoundingClientRect();
    const endX = event.clientX - containerRect.left;
    const endY = event.clientY - containerRect.top;

    setSelectionArea((prevSelection) => ({
      ...prevSelection,
      endX,
      endY,
    }));

    const selected = shapes.filter((shape) => {
      const { x, y } = shape;
      return (
        x >= Math.min(selectionArea?.startX, endX) &&
        x <= Math.max(selectionArea?.startX, endX) &&
        y >= Math.min(selectionArea?.startY, endY) &&
        y <= Math.max(selectionArea?.startY, endY)
      );
    });

    setSelectedShapes(selected);
  };

  const handleMouseUp = () => {
    if (!dragSelectMode) return;

    setSelectionArea(null);
    setIsDragging(false);
  };

  //Neighbourhood Ends
  const assignIndividual = () => {
    setAssignToIndividual(true);
  };
  const assignCompany = () => {
    setAssignToIndividual(false);
  };
  useEffect(() => {
    const handleResize = () => {
      if (imageAreaRef.current) {
        const { width, height } = imageAreaRef.current.getBoundingClientRect();
        setImageWidth(width);
        setImageHeight(height);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrop = (event) => {
    event.preventDefault();
    const shapeType = event.dataTransfer.getData("text/plain");
    if (
      shapeType === "1" ||
      shapeType === "2" ||
      shapeType === "3" ||
      shapeType === "4"
    ) {
      const { left, top } = imageAreaRef.current.getBoundingClientRect();
      const shapeSize = Math.min(imageWidth, imageHeight) * 0.1;
      const containerX = (event.clientX - left) / zoomLevel;
      const containerY = (event.clientY - top) / zoomLevel;
      const x = containerX - shapeSize / 2;
      const y = containerY - shapeSize / 2;

      const shape = { type: shapeType, x, y, isChanged: true };

      setShapes([...shapes, shape]);
      floorRoomSubmit(shape);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (deskOpen) {
      return;
    }
  };

  const handleShapeMouseDown = (event, index) => {
    event.stopPropagation();
    if (deskOpen || assignDeskOpen) {
      return;
    }
    setSelectedShape(index);
    const { left, top } = imageAreaRef.current.getBoundingClientRect();
    const x = (event.clientX - left) / zoomLevel;
    const y = (event.clientY - top) / zoomLevel;
    setCursorOffset({ x: x - shapes[index]?.x, y: y - shapes[index]?.y });
    setIsDragging(true);
  };

  const handleImageAreaClick = (event) => {
    // if (deskOpen || assignDeskOpen) {
    //   return;
    // }
    // if (selectedShapeTyp !== null && selectedShape == null) {
    //   const { left, top } = imageAreaRef.current.getBoundingClientRect();
    //   const shapeSize = Math.min(imageWidth, imageHeight) * 0.1;
    //   const containerX = (event.clientX - left) / zoomLevel;
    //   const containerY = (event.clientY - top) / zoomLevel;
    //   const x = containerX - shapeSize / 2;
    //   const y = containerY - shapeSize / 2;
    //   const shape = { type: selectedShapeTyp, x, y, isChanged: false };
    //   setShapes([...shapes, shape]);
    //   floorRoomSubmit(shape);
    // } else {
    //   setSelectedShape(null);
    //   if (changedShape?.isChanged == true) {
    //     handleUpdateRoom(changedShape);
    //   }
    // }
  };

  const handleImageAreaMouseMove = (event) => {
    if (deskOpen || assignDeskOpen) {
      return;
    }
    if (selectedShape !== null) {
      const { left, top } = imageAreaRef.current.getBoundingClientRect();
      const x = (event.clientX - left) / zoomLevel;
      const y = (event.clientY - top) / zoomLevel;
      setShapes((prevShapes) =>
        prevShapes?.map((shape, index) =>
          index === selectedShape
            ? {
                ...shape,
                x: x - cursorOffset?.x,
                y: y - cursorOffset?.y,
                isChanged: true,
              }
            : shape
        )
      );
      setChangedShape(shapes[selectedShape]);
    } else if (!dragSelectMode || !selectionArea) return;

    const containerRect = imageAreaRef.current.getBoundingClientRect();
    const endX = event.clientX - containerRect.left;
    const endY = event.clientY - containerRect.top;

    setSelectionArea((prevSelection) => ({
      ...prevSelection,
      endX,
      endY,
    }));

    const selected = shapes.filter((shape) => {
      const { x, y } = shape;
      return (
        x >= Math.min(selectionArea?.startX, endX) &&
        x <= Math.max(selectionArea?.startX, endX) &&
        y >= Math.min(selectionArea?.startY, endY) &&
        y <= Math.max(selectionArea?.startY, endY)
      );
    });

    setSelectedShapes(selected);
  };

  const handleShapeEdit = (event, index) => {
    event.preventDefault();
    if (selectedShape === index) {
      setIsOccupied(true);
    }
  };

  const handleDeleteShape = (event, index) => {
    event.stopPropagation();

    if (selectedShape === index) {
      confirmationAlert("Want to delete this Room?").then((willDelete) => {
        if (willDelete) {
          const updatedShapes = [...shapes];
          updatedShapes.splice(index, 1);
          setShapes(updatedShapes);
          setSelectedShape(null);

          userDeleteAlert("Room deleted successfully.");
        }
      });
    }
  };
  const handleExistingShapeDelete = (event, id) => {
    event.preventDefault();
    const shapeId = shapes[selectedShape]?.id;
    confirmationAlert("Want to delete this Room?").then((willDelete) => {
      if (willDelete) {
        deleteFloorRoom({ id: shapeId });
      }
    });
  };

  useEffect(() => {
    if (resultStats1?.isSuccess) {
      userDeleteAlert("Room deleted successfully.");
      setShowDelete(false);
      setSelectedShape(null);
    }
    if (resultStats1?.isError) {
      requiredAlert(resultStats1?.error?.data?.message);
      setShowDelete(false);
      setSelectedShape(null);
    }
  }, [resultStats1]);
  useEffect(() => {
    if (resultStats?.isSuccess) {
      toast.success("Room created successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);
  useEffect(() => {
    if (resultStats2?.isSuccess) {
      toast.success("Room updated successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setChangedShape(null);
    }
    if (resultStats2?.isError) {
      // requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats2]);
  const handleEditButtonClick = (index) => {
    setDeskOpen(true);
  };
  const handleAssignClick = () => {
    setAssignDeskOpen(true);
  };

  if (loading || isLoading || Companyloading || usersLoading) {
    return <Loader />;
  }

  return (
    <div className="container-fluid card">
      <div className="row">
        <div
          className="col-sm-12 col-md-9 "
          style={{
            touchAction: isDraggingInProgress ? "none" : "auto",
            overflow: isDraggingInProgress ? "hidden" : "auto",
          }}
        >
          <div
            ref={imageAreaRef}
            onClick={handleImageAreaClick}
            style={{
              width: `${imageWidth}px`,
              height: `${imageHeight}px`,
              marginTop: "20px",
              margin: "10px",
              position: "relative",
              overflow: "hidden",
              transform: `scale(${zoomLevel})`,
              transformOrigin: "top left",
            }}
          >
            <img
              src={floorImage}
              alt="Floor Image"
              draggable="false"
              style={{
                width: "100%",
                height: "100%",
                userSelect: "none",
              }}
            />

            {shapes?.map((shape, index) => {
              const shapeSize =
                Math.min(imageWidth - 50, imageHeight - 50) * 0.1;

              return (
                <>
                  <div
                    key={index}
                    title={
                      shape.type == 1
                        ? "Dedicated Desk"
                        : shape.type == 2
                        ? "Hot Desk"
                        : ""
                    }
                    style={{
                      width: shapeSize,
                      height: shapeSize,
                      // backgroundColor: shape.isOccupied ? "red" : "green",
                      backgroundColor: shape.assignedTo
                        ? "red"
                        : shape.type === "1"
                        ? "rgba(31,213,235)"
                        : shape.type === "2"
                        ? "rgba(255,159,67)"
                        : shape.type === "3"
                        ? "rgba(115,103,240)"
                        : "rgba(40,199,111)",

                      border: "5px solid rgb(194, 231, 234)",

                      borderRadius:
                        shape.type === "1" || shape.type === "2"
                          ? "50%"
                          : shape.type === "3" || shape.type === "4"
                          ? "20%"
                          : "",
                      position: "absolute",
                      left: `${shape.x}px`,
                      top: `${shape.y}px`,
                      cursor: "move",
                      zIndex: shape.isChanged ? 1 : "auto",
                    }}
                    onClick={() => {
                      if (showDelete == false) {
                        setShowDelete(true);
                        setShowRoomEdit(false);
                        setCheck(index);
                      } else {
                        setShowDelete(false);
                        setCheck(null);
                      }
                    }}
                  >
                    {shape.type === "4" ? (
                      <p
                        style={{
                          display: "inline-block",
                          color: "white",
                          backgroundColor: "rgb(24, 23, 23,0.8)",
                          marginTop: 35,
                          padding: "1px",
                          borderRadius: "5px",
                          whiteSpace: "nowrap",
                          marginLeft: "-50px",
                          userSelect: "none",
                        }}
                      >
                        Meeting Room
                      </p>
                    ) : shape.type === "3" ? (
                      <p
                        style={{
                          display: "inline-block",
                          color: "white",
                          backgroundColor: "rgb(24, 23, 23,0.8)",
                          marginTop: 35,
                          padding: "1px",
                          borderRadius: "5px",
                          whiteSpace: "nowrap",
                          marginLeft: "-40px",
                          userSelect: "none",
                        }}
                      >
                        Private Office
                      </p>
                    ) : (
                      ""
                    )}
                    {showDelete && check === index && (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className="d-flex"
                        style={{
                          position: "absolute",
                          bottom: -7,
                          right: -7,
                          width: shapeSize + 6,
                          height: shapeSize + 6,
                          border: "2px solid green",
                          borderRadius: "10%",
                          cursor: "pointer",

                          zIndex: 9999,
                        }}
                      ></div>
                    )}
                    {showDelete && check === index && (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className="card"
                        style={{
                          position: "absolute",
                          width: 200,
                          height: 150,
                          border: "2px solid green",
                          borderRadius: "10%",
                          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
                          cursor: "pointer",
                          zIndex: 9999,
                          left: shape.x <= imageWidth / 2 ? shapeSize : null,
                          right: shape.x > imageWidth / 2 ? shapeSize : null,
                          top: shape.y <= imageHeight / 2 ? shapeSize : null,
                          bottom:
                            shape.y > imageHeight / 2 ? shapeSize - 50 : null,
                        }}
                      >
                        <div className="text-center mt-1">
                          <h5>{shape.name}</h5>
                          <hr />
                        </div>
                        <div className="">
                          {shape.assignedTo == null ? (
                            shape.type == 2 || shape.type == 4 ? (
                              ""
                            ) : (
                              <h5>Assigned To: None</h5>
                            )
                          ) : (
                            FetchUsers?.map((item) => {
                              if (
                                item.userDetails[0]?.userId == shape.assignedTo
                              ) {
                                return (
                                  <div
                                    key={item.userDetails[0].userId}
                                    className="ml-2 "
                                  >
                                    <h5>Assigned To:</h5>
                                    <div className="d-flex">
                                      <img
                                        src={item.userDetails[0]?.profileImg}
                                        alt=""
                                        className="rounded-circle"
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      />
                                      <h5 className="mt-1 ml-1">
                                        {item.userDetails[0]?.name}
                                      </h5>
                                    </div>
                                  </div>
                                );
                              } else {
                                return <h5>Not Found</h5>;
                              }
                            })
                          )}
                        </div>
                      </div>
                    )}

                    {/* {showDelete && check === index && (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className="card d-flex"
                        style={{
                          position: "absolute",
                          width: 200,
                          height: 150,
                          border: "2px solid green",
                          borderRadius: "10%",
                          cursor: "pointer",

                          zIndex: 9999,
                        }}
                      >
                        <h5>{shape.name}</h5>
                        {shape.assignedTo && <div>{shape.assignedTo}</div>}
                      </div>
                    )} */}
                  </div>
                </>
              );
            })}

            {/* {selectionArea && (
              <div
                className="selection-area"
                style={{
                  left: Math.min(selectionArea?.startX, selectionArea?.endX),
                  top: Math.min(selectionArea?.startY, selectionArea?.endY),
                  width: Math.abs(selectionArea?.endX - selectionArea?.startX),
                  height: Math.abs(selectionArea?.endY - selectionArea?.startY),
                }}
              ></div>
            )} */}
          </div>
        </div>
        <div
          className="col-sm-12 col-md-3 floorsidebar mt-2"
          style={{ borderLeft: "1px solid black" }}
        >
          <div className="tabs d-flex justify-content-around border-bottom ">
            <div
              className={`tab ${activeTab === "Desk" ? "active" : ""}`}
              onClick={() => handleTabClick("Desk")}
              style={{
                cursor: "pointer",
                borderBottom: activeTab === "Desk" ? "2px solid blue" : "",
              }}
            >
              Desk
            </div>
            <div
              className={`tab ${activeTab === "Spaces" ? "active" : ""}`}
              onClick={() => handleTabClick("Spaces")}
              style={{
                cursor: "pointer",
                borderBottom: activeTab === "Desk" ? "" : "2px solid blue",
              }}
            >
              Spaces
            </div>
          </div>
          <div className="justify-content-center mt-2">
            {activeTab === "Desk" && (
              <div className="room-list form-control">
                {roomsData?.map((item, index) => {
                  if (item?.room_type?.id === 1 || item?.room_type?.id === 2) {
                    return (
                      <div
                        className="ml-3"
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            showDelete && check === index
                              ? "rgb(195, 222, 230)"
                              : "",
                          padding: 5,
                          borderRadius: "10px",
                          userSelect: "none",
                        }}
                        key={index}
                        onClick={() => {
                          if (showDelete == false) {
                            setShowDelete(true);
                            setShowRoomEdit(false);
                            setCheck(index);
                          } else {
                            setShowDelete(false);
                            setCheck(null);
                          }
                        }}
                      >
                        <p className="mt-2">{item.name}</p>
                      </div>
                    );
                  } else return null;
                })}
              </div>
            )}
            {activeTab === "Spaces" && (
              <div className="room-list form-control">
                {roomsData?.map((item, index) => {
                  if (item?.room_type?.id === 3 || item?.room_type?.id === 4) {
                    return (
                      <div
                        className="ml-3"
                        style={{
                          cursor: "pointer",
                          padding: 5,
                          borderRadius: 10,
                          backgroundColor:
                            showDelete && check === index
                              ? "rgb(195, 222, 230)"
                              : "",
                          userSelect: "none",
                        }}
                        key={index}
                        onClick={() => {
                          if (showDelete == false) {
                            setShowDelete(true);
                            setShowRoomEdit(false);
                            setCheck(index);
                          } else {
                            setShowDelete(false);
                            setCheck(null);
                          }
                        }}
                      >
                        <p>{item.name}</p>
                      </div>
                    );
                  } else return null;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ViewFloorPlan = () => {
  const { floorsPermissions } = useGlobalContext();
  let { id } = useParams();
  const navigate = useNavigate();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: floorPlan, isLoading: loading } = useGetSpecificFloorPlansQuery(
    {
      spaceId: spaceId || currentSpaceId,
      floorId: id,
    }
  );
  const { data: fetchFloorPlans, isLoading } = useGetFloorPlansQuery(
    spaceId || currentSpaceId
  );

  const [selectedFloorId, setSelectedFloorId] = useState(id);
  const [mouseWheelZoomActive, setMouseWheelZoomActive] = useState(false);
  const [selectedZoomLevel, setSelectedZoomLevel] = useState(1);
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem("selectedDate") || new Date()
  );

  const handlePrevClick = () => {
    setSelectedDate((prevDate) => subDays(prevDate, 1));
  };

  const handleNextClick = () => {
    setSelectedDate((prevDate) => addDays(prevDate, 1));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date[0]);
  };
  const handleZoomLevelChange = (event) => {
    const newZoomLevel = parseFloat(event.target.value);
    setSelectedZoomLevel(newZoomLevel);
  };
  const handleMouseWheelZoomToggle = () => {
    setMouseWheelZoomActive(!mouseWheelZoomActive);
  };

  useEffect(() => {
    const handleMouseWheelZoom = (event) => {
      if (mouseWheelZoomActive) {
        // event.preventDefault();
        const zoomStep = 0.1;
        const newZoomLevel =
          selectedZoomLevel + (event.deltaY > 0 ? -zoomStep : zoomStep);

        const minZoom = 0.1;
        const maxZoom = 2.0;
        const clampedZoomLevel = Math.min(
          Math.max(newZoomLevel, minZoom),
          maxZoom
        );
        setSelectedZoomLevel(clampedZoomLevel);
      }
    };

    document.addEventListener("wheel", handleMouseWheelZoom);

    return () => {
      document.removeEventListener("wheel", handleMouseWheelZoom);
    };
  }, [mouseWheelZoomActive, selectedZoomLevel]);

  const handleFloorSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedFloorId(selectedId);
    const updateUrl = `/floorPlans/${selectedId}/view`;
    navigate(updateUrl);
  };
  const handleBackToFloorList = () => {
    navigate("/floorPlans");
  };
  const handleFloorEditClick = () => {
    const updateUrl = `/floorPlans/${id}/update`;
    navigate(updateUrl);
  };
  const floorEditPermission = () => {
    errorAlertPermission("You are not allowed to edit floor plan.");
  };
  if (loading || isLoading) {
    return <Loader height={6.6} />;
  }
  return (
    <div className="container-fluid" style={{ padding: "0px 16px" }}>
      <div className="container-fluid">
        <div className="">
          <h4>{floorPlan?.name}'s Map</h4>
        </div>
        <div className="row">
          <div
            className="col-sm-12 col-md-12 col-xl-12 d-flex  justify-content-center align-items-center p-3"
            style={{
              // backgroundImage:
              //   "linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)",
              // backgroundImage:
              //   "linear-gradient(to right, rgb(195, 222, 230),rgb(195, 222, 230),rgb(195, 222, 230),rgb(195, 222, 230),rgb(195, 222, 230),rgb(195, 222, 230),rgb(195, 222, 230))",
              flexWrap: "wrap",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
            }}
          >
            <div className="col-sm-12 col-md-5 d-flex justify-content-between mt-2">
              <div
                className=""
                onClick={handleBackToFloorList}
                title="Back"
                style={{
                  backgroundColor: "rgb(126, 126, 225,0.5)",
                  cursor: "pointer",
                  alignItems: "center", // Add align-items: center
                  justifyContent: "center",
                  height: "50px", // You can adjust the height as needed
                  width: "50px", // You can adjust the width as needed
                  borderRadius: "5px",
                }}
              >
                <div className="mt-2 ml-2">{ArrowBack}</div>
              </div>
              {/* <div
                style={{
                  backgroundColor: "rgb(126, 126, 225,0.5)",
                  padding: "5px",
                  alignItems: "center",
                  borderRadius: "5px",
                  height: "50px", // You can adjust the height as needed
                  // width: "50px",
                }}
              >
                <h4 className="mt-1">{floorPlan?.name}</h4>
              </div> */}

              <div className="">
                <div className="">
                  <div className="d-flex align-items-center">
                    <div
                      className="color-circle ml-1"
                      style={{
                        backgroundColor: "rgba(40,199,111,0.85)",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <small className="ml-1" style={{ fontSize: "10px" }}>
                      Meeting Room
                    </small>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className="color-circle ml-1"
                      style={{
                        backgroundColor: "rgba(115,103,240,0.85)",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <small className="ml-1" style={{ fontSize: "10px" }}>
                      Private Office
                    </small>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className="color-circle ml-1"
                      style={{
                        backgroundColor: "rgba(31,213,235,0.85)",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <small className="ml-1" style={{ fontSize: "10px" }}>
                      Dedicated Desk
                    </small>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="d-flex align-items-center">
                    <div
                      className="color-circle ml-1"
                      style={{
                        backgroundColor: "rgba(255,159,67,0.85)",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <small className="ml-1" style={{ fontSize: "10px" }}>
                      Hot Desk
                    </small>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className="color-circle ml-1"
                      style={{
                        backgroundColor: "red",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <small className="ml-1" style={{ fontSize: "10px" }}>
                      Occupied
                    </small>
                  </div>
                </div>
              </div>
              <div className="" title="Select Floor">
                <select
                  name="floorPlan"
                  id="floorPlan"
                  className="form-control"
                  style={{
                    cursor: "pointer",
                    WebkitAppearance: "menulist",
                    MozAppearance: "menulist",
                    appearance: "menulist",
                  }}
                  onChange={handleFloorSelectChange}
                  value={selectedFloorId}
                >
                  {fetchFloorPlans?.map((floorPlan) => (
                    <option key={floorPlan?.id} value={floorPlan.id}>
                      {floorPlan.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 d-flex justify-content-between mt-2">
              <div
                onClick={() => {
                  setSelectedZoomLevel(1);
                }}
                title="Zoom your plan to fit the screen."
                style={{
                  backgroundColor: "rgb(126, 126, 225,0.5)",
                  cursor: "pointer",
                  alignItems: "center", // Add align-items: center
                  justifyContent: "center",
                  height: "50px", // You can adjust the height as needed
                  width: "50px", // You can adjust the width as needed
                  borderRadius: "5px",
                }}
              >
                <div className="mt-2 ml-2">
                  {selectedZoomLevel < 1 ? ZoomMaximise : ZoomMinimize}
                </div>
              </div>
              <div
                // style={{ backgroundColor: mouseWheelZoomActive ? "grey" : "" }}
                onClick={handleMouseWheelZoomToggle}
                title={
                  mouseWheelZoomActive
                    ? "Wheel Zoom Active"
                    : "Wheel Zoom Inactive"
                }
                style={{
                  backgroundColor: "rgb(126, 126, 225,0.5)",
                  cursor: "pointer",
                  alignItems: "center", // Add align-items: center
                  justifyContent: "center",
                  height: "50px", // You can adjust the height as needed
                  width: "50px", // You can adjust the width as needed
                  borderRadius: "5px",
                }}
              >
                <div className="mt-2 ml-2">{WheelZoom}</div>
              </div>
              <div
                className="dropdown mt-2"
                title="Zoom Level"
                style={{ width: "50px" }}
              >
                <button
                  style={{ borderRadius: "5px" }}
                  className="dropdown-button  border"
                >
                  {parseInt(selectedZoomLevel * 100)}%
                </button>
                <select
                  className="dropdown-content"
                  style={{ borderRadius: "5px" }}
                  onChange={handleZoomLevelChange}
                  value={selectedZoomLevel}
                >
                  <option value={2}>200%</option>

                  <option value={1.5}>150%</option>
                  <option value={1.25}>125%</option>
                  <option value={1}>100%</option>
                  <option value={0.75}>75%</option>
                  <option value={0.5}>50%</option>
                  <option value={0.25}>25%</option>
                </select>
              </div>
              <div
                title="Edit Floor Plan"
                onClick={
                  floorsPermissions[0]?.update
                    ? handleFloorEditClick
                    : floorEditPermission
                }
                style={{
                  backgroundColor: "rgb(126, 126, 225,0.5)",
                  cursor: "pointer",
                  alignItems: "center", // Add align-items: center
                  justifyContent: "center",
                  height: "50px", // You can adjust the height as needed
                  width: "50px", // You can adjust the width as needed
                  borderRadius: "5px",
                }}
              >
                <div className="mt-2 ml-2">{Edit}</div>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 mt-2" title="Floor History">
              <div className="d-flex">
                <button
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "rgb(126, 126, 225,0.5)",
                    height: "50px",
                  }}
                  onClick={handlePrevClick}
                >
                  {Previous}
                </button>
                <DatePicker
                  value={[selectedDate]} // Use 'value' instead of 'selected'
                  onChange={handleDateChange}
                  options={{ dateFormat: "F j, Y" }}
                  className="form-control bg-light"
                />
                <button
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "rgb(126, 126, 225,0.5)",
                    height: "50px",
                  }}
                  onClick={handleNextClick}
                >
                  {Next}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ImageArea
        floorImage={floorPlan?.image}
        id={id}
        zoomLevel={selectedZoomLevel}
      />
    </div>
  );
};
