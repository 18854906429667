import React, { useState } from 'react'
import {
  Button,
  Dropdown,
  Form,
  Nav,
  Pagination,
  Row,
  Tab,
  Table,
  Tabs
} from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons'

import ProductByMember from './ProductByMember'
import InvoicedProduct from './InvoicedProduct'

const ProductReport = () => {
  const [page, setPage] = useState(0)
  return (
    <>
      <section className='container-fluid  with-bottom-padding pt-0 p-3  mt-0'>
        <Tabs
          defaultActiveKey='InvoicedProduct'
          id='justify-tab-example'
          // className='mb-3'
          variant='underline'
          justify
        >
          <Tab
            // style={{ marginRight: '5px' }}
            eventKey='InvoicedProduct'
            title={
              <span className='d-flex align-items-center'>
                <Icon.Building /> &nbsp;Invoiced Product
              </span>
            }
          >
            {/* <CompanyTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <InvoicedProduct />
          </Tab>
          <Tab
            eventKey='proByMember'
            title={
              <span className='d-flex align-items-center'>
                <Icon.Person /> &nbsp; Product By Member
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <ProductByMember />
          </Tab>
        </Tabs>
      </section>
    </>
  )
}

export default ProductReport
